import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AdminLayout from "./AdminLayout";
import BasicLayout from "./BasicLayout";
import renderRoutes from "../utils/renderRoutes";
import {
  mockupRoutes,
  blankLayoutRoutes,
  basicLayoutRoutes,
  authorizedRoutes
} from "../router/routerData";
import { withRouter } from "react-router-dom";
import BlankLayout from "./BlankLayout";

class CommonLayout extends Component {
  componentDidUpdate(nextProps) {
    if (this.props.user && this.props.user.type !== nextProps.user.type) {
    }
  }

  renderRouter() {
    const mockupRouter = mockupRoutes;
    const adminLayoutRouter = authorizedRoutes;
    const basicLayoutRouter = basicLayoutRoutes;
    const blankLayoutRouter = blankLayoutRoutes;

    mockupRouter.map(route => {
      route.layout = BasicLayout;
    });
    adminLayoutRouter.map(route => {
      route.layout = AdminLayout;
    });
    basicLayoutRouter.map(route => {
      route.layout = BasicLayout;
    });
    blankLayoutRouter.map(route => {
      route.layout = BlankLayout;
    });

    const routes = [
      ...mockupRouter,
      ...adminLayoutRouter,
      ...basicLayoutRouter,
      ...blankLayoutRouter
    ];

    console.log("routes: ", routes);
    const router = renderRoutes(routes);
    return router;
  }

  render() {
    const { user, logged, type, children } = this.props;
    console.log("user: ", user);
    console.log("common layout: ", logged);

    // if (!user || !user.type) {
    //   return null;
    // }

    return <>{this.renderRouter()}</>;
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      logged: auth.logged,
      user: auth.user,
      type: auth.user.type
    }),
    null
  )(CommonLayout)
);
