import React, { Component } from "react";
import { Quiz } from "../../../components/Quiz";
import HometrainingApis from "../../../utils/hometrainingApis";
import { getHost } from "../../../utils/APIUtils";
import SockJsClient from "react-stomp";
import { Skeleton } from "antd";

class EndQuizSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      quizListData: null,
      solvedQuizData: null,
      currentQuiz: null,
      currentIndex: 0,
      completed: false,
      step: false,
      status: [],
      connected: false,
    }
  }

  sendValue = (data) => {
    const _data = {
      type: "sendValue",
      value: data
    }
    if (this.props.viewType === "guide") {
      this.props.onSendMessage(_data);
    }
  }

  solveWithGuide = (data) => {
    const _data = {
      type: "solveWithGuide",
      value: data
    }
    this.props.onSendMessage(_data);
  }

  init = () => {
    const { quiz, userId } = this.props.data;
    console.log("init()")
    let _curIndex = 0;
    const _status = [];
    let _solvedQuizData = [];

    quiz.map((q) => {
      console.log(q.solvedMap[userId]);
      if (q.solvedMap[userId]) {
        _curIndex++;
        _status.push(q.solvedMap[userId].correct);
        _solvedQuizData.push(q.solvedMap[userId]);
      }
    });
    console.log("solve", _solvedQuizData)
    console.log("_curIndex", _curIndex);
    console.log("quizList", quiz);
    this.setState(
      {
        quizListData: quiz,
        status: _status,
        currentIndex: _curIndex < quiz.length ? _curIndex : 0,
        solvedQuizData: _solvedQuizData,
        step: _curIndex === quiz.length ? true : false,
        // completed: _curIndex === quizList.length ? true : false
      },
      () => {
        if (_curIndex === quiz.length) {
          this.load(quiz[0].quizId);
        } else {
          this.load(quiz[_curIndex].quizId);
        }
      }
    );
  }

  componentDidMount() {
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.addEventListener("message", this.handleQuizBroadcast);
    }
    this.init();
  }

  componentWillUnmount() {
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.removeEventListener("message", this.handleQuizBroadcast);
    }
    if (this.state.connected) {
      this.onDisconnect();
    }
  }

  onMessageReceive = (msg, topic) => {
    console.log("receive msg: ", msg);
    if (msg.type === "remote") {
      this.handleQuizBroadcast(msg);
    }
  };

  load = (quizId, data) => {
    if (this.state.receiveValue != null) {
      this.setState({
        receiveValue: null
      })
    }
    this.setState({
      isLoading: true,
    });


    let randomSeed = Math.floor(Math.floor(this.props.classroomId /(this.props.seq+1) + this.props.seq + this.props.lectureId / (this.props.seq+1)))
    HometrainingApis.loadQuiz(quizId,data,randomSeed).then((response) => {
      this.setState({
        isLoading: false,
        currentQuiz: response.data,
      });
    });
  }

  onConnect = () => {
    this.setState({
      connected: true,
    });
  };

  onDisconnect = () => {
    this.setState({
      connected: false,
    });
  };

  handleQuizBroadcast = (res) => {
    console.log("handle quiz broadcast");
    const type = res.data && res.data.type;
    const value = res.data && res.data.value;
    console.log("quiz type: ", type);
    console.log("quiz value: ", value);
    if (type === "quizData") {
      this.setState({
        currentQuiz: value,
      });
    } else if (type === "loadQuiz") {
      this.load(value.quizId,value.vars);
    } else if (type === "toggleNote") {
      if (this.props.viewType !== "guide") {
        this.setState({
          noteOpen: value.noteOpen,
        });
      }
    } else if (type === "drawNote") {
      if (this.props.viewType !== "guide") {
        this.setState({
          remoteNote: value,
        });
      }
    } else if (type === "removeLine") {
      if (this.props.viewType !== "guide") {
        this.setState({
          removeLineId: value.removeId,
        });
      }
    } else if (type === "solveWithGuide") {
      if (this.props.viewType !== "guide") {
      }
    } else if (type === "sendValue") {
      if (this.props.viewType !== "guide") {
        this.setState({ receiveValue: value })
      }
    }
  };

  render() {
    const { currentQuiz, quizListData, isLoading, solvedQuizData, currentIndex, status, step, completed } = this.state;
    const { onSolve, viewType, receiveValue, classroomId } = this.props;
    console.log(solvedQuizData, "currentindex")
    if (quizListData) {
      const checkLength = quizListData.length - 1;
      console.log(status, " : ", step, " : ", checkLength)
    }

    return (<Skeleton loading={isLoading} active>
      {currentQuiz && (
        <div style={{ width: 1024, position: "relative", marginLeft: "auto" }}>
          <Quiz
            solveQuiz={this.props.solveQuiz}
            receiveValue={receiveValue}
            viewType={viewType}
            data={currentQuiz}
            quizType={"challenge"}
            solver={onSolve}
            status={[]}
            classroomId={classroomId}
            solveWithGuide={this.solveWithGuide}
            sendValue={this.sendValue}
            isPreview={true}
          />

        </div>)}
      <SockJsClient
        // ref={e => (this.clientRef = e)}
        url={`${getHost()}/academy/controller`}
        topics={[
          `/topic/${classroomId}/all`,
          // `/topic/${classroomId}/users/${user.userId}`,
          // `/topic/${classroomId}/${user.type}`
        ]}
        onConnect={this.onConnect}
        onDisconnect={this.onDisconnect}
        onMessage={this.onMessageReceive}
        debug={false}
      />
    </Skeleton>)
  }
}

export default EndQuizSection;