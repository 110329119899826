import Axios from "./request";
import { message } from "antd";

const apiPrefix = "/academy/v2/posts";

const getPostList = (page, count) => {
  return Axios.get(`${apiPrefix}?page=${page}&count=${count}`);
};

const addPost = data => {
  return Axios.post(`${apiPrefix}`, data);
};

const editPost = (postId, data) => {
  return Axios.put(`${apiPrefix}/${postId}`, data);
};

const getPost = postId => {
  return Axios.get(`${apiPrefix}/${postId}`);
};
const deletePost = postId => {
  return Axios.delete(`${apiPrefix}/${postId}`);
};
const postReply = (postId, data) => {
  return Axios.post(`${apiPrefix}/${postId}/replies`, data);
};

const PostApis = {
  getPostList,
  addPost,
  editPost,
  getPost,
  deletePost,
  postReply
};

export default PostApis;
