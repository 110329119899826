import React from "react";
import update from "immutability-helper";
import PairedAnswer from "./PairedAnswer";
import classNames from "classnames";
import debounce from "lodash.debounce";
import { isAndroid, isIOS } from "react-device-detect";
import { isIPadSafari } from "utils/utils";

const grid = false;
const isMobileDevice = isAndroid || isIOS || isIPadSafari();

class PairingTest extends React.Component {
  state = {
    x: 0,
    y: 0,
    positions: {
      cx: 0,
      cy: 0,
      px: 0,
      py: 0,
      scrollTop: 0,
      height: 0,
      offsetLefts: [],
      offsetTops: [],
      bounds: [0, 0],
    },
    // debugMessage: '',

    drawing: false,

    pointer: {
      start: null,
      current: null,
    },

    isMobileSize: false,
    mobileSelectLeft: null,
  };

  width = 0;
  height = 0;

  leftAnswers = [];
  rightAnswers = [];

  bounds = [0, 0];

  leftDots = [];
  rightDots = [];

  answersMap = [];

  constructor(props) {
    super(props);
    this.onResize = debounce(this.onResize, 200);
    this.startScroll = debounce(this.startScroll, 10);
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.processAnswers(this.props.answers);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentWillReceiveProps(nextProps) {
    const { answers } = this.props;

    this.processAnswers(nextProps.answers);
  }

  processAnswers = (answers) => {
    const { right, solvedData } = this.props;

    this.answersMap = [];
    answers.map((a, i) => {
      const leftIndex = i;
      const rightIndex = a ? right.findIndex((r) => r.key === a.key) : null;
      const answer = a;
      const correct =
        solvedData && solvedData.correctAnswers
          ? answer && solvedData.correctAnswers[i] === answer.key
            ? "o"
            : "x"
          : null;
      const realRight =
        solvedData && solvedData.correctAnswers
          ? solvedData.correctAnswers[i]
          : null;

      this.answersMap.push({
        left: leftIndex,
        right: rightIndex,
        answer: answer,
        correct: correct,
        realRight: realRight,
      });
    });

    if (this.canvas) {
      this._drawLine();
    }

    return this.answersMap;
  };

  _getRealPosition = (e) => {
    var x;
    var y;
    if (e.touches) {
      x = e.touches[0].pageX;
      y = e.touches[0].pageY;
    } else {
      x = e.pageX;
      y = e.pageY;
    }

    var offsetLeft = 0;
    var offsetTop = 0;

    var c = this.container;
    while (c != null) {
      const className = c.className;

      if (
        !className ||
        className === "content__wrapping" ||
        className.includes("not-drag")
      ) {
        c = c.parentElement;
        continue;
      }

      offsetLeft += c.offsetLeft;
      offsetTop += c.offsetTop;

      if (c.className === "quiz-content") {
        break;
      }

      c = c.parentElement;
    }

    x -= offsetLeft;
    y -= offsetTop;

    return {
      x: x,
      y: y,
    };
  };

  getRealPosition = (e) => {
    let currTargetRect = this.container.getBoundingClientRect();

    const _e = e.touches ? e.touches[0] : e;

    const x = _e.clientX - currTargetRect.left;
    // const y = e.clientY - currTargetRect.top;
    // const y = e.clientY - currTargetRect.top - (isMobileDevice ? this.state.scrollTopPos : 0);
    const y = _e.clientY - currTargetRect.top;
    //  - (isMobileDevice ? window.pageYOffset : 0);

    return {
      x: x,
      y: y,
    };
  };

  getElemPosition = (pos, index) => {
    const elem = (pos === "left" ? this.leftDots : this.rightDots)[index];

    const rect = elem.getBoundingClientRect();

    console.log(pos, index, rect);

    return this.getRealPosition({
      clientX: rect.left + rect.width / 2,
      clientY: rect.top + rect.height / 2,
    });

    // return {
    //   x: elem.offsetLeft + (elem.offsetWidth / 2),
    //   y: elem.offsetTop + (elem.offsetHeight / 2)
    // }
  };

  isInElement = (e, elem) => {
    const rect = (elem || e.target).getBoundingClientRect();
    const _e = e.touches ? e.touches[0] : e;

    const x = _e.clientX;
    const y = _e.clientY;

    return (
      x >= rect.left &&
      x <= rect.left + rect.width &&
      y >= rect.top &&
      y <= rect.top + rect.height
    );
  };

  findIntersect = (evt, startPos) => {
    const targetPos = startPos === "left" ? "right" : "left";
    const target = startPos === "left" ? this.rightAnswers : this.leftAnswers;

    const idx = target.findIndex((t) => this.isInElement(evt, t));
    if (idx < 0) {
      return null;
    }

    return {
      pos: targetPos,
      index: idx,
    };
  };

  onResize = () => {
    const rect = this.canvas.getBoundingClientRect();

    this.width = rect.width;
    this.height = rect.height;

    this.canvas.width = this.width;
    this.canvas.height = this.height;

    const checkIsMobile = window.innerWidth <= 768;
    if (checkIsMobile !== this.state.isMobileSize) {
      this.setState({
        isMobileSize: checkIsMobile,
      });
    }

    if (this.leftContainer && this.rightContainer) {
      this.bounds = [
        this.leftContainer.offsetLeft + this.leftContainer.offsetWidth,
        this.rightContainer.offsetLeft,
      ];
    }

    this.setState({
      positions: update(this.state.positions, {
        bounds: {
          $set: this.bounds,
        },
      }),
    });

    this.clear();
    this._drawLine();
  };

  onDrawStart = (e, pos, index) => {
    const { onLineCanceled, answers, right } = this.props;

    if (this.props.solvedData && this.props.solvedData.solved) {
      return;
    }

    if (this.state.isMobileSize) {
      return;
    }
    e.preventDefault();

    const coord = this.getElemPosition(pos, index);

    if (pos === "left") {
      onLineCanceled(index);
    } else {
      const idx = answers.findIndex(
        (s) => s != null && s.key === right[index].key
      );
      if (idx >= 0) {
        onLineCanceled(idx);
      }
    }

    this.setState({
      drawing: true,
      pointer: {
        start: {
          ...coord,
          pos: pos,
          index: index,
        },
        current: coord,
      },
      // debugMessage: 'draw started'
    });
  };

  onOverAnswer = (e, pos, index) => {
    const { pointer, drawing } = this.state;

    if (this.props.solvedData && this.props.solvedData.solved) {
      return;
    }

    if (this.state.isMobileSize) {
      return;
    }

    if (!drawing) {
      return;
    }

    e.preventDefault();

    this.checkScroll(e);

    let coord = {};

    if (pos !== pointer.start.pos) {
      e.stopPropagation();
      coord = {
        ...this.getElemPosition(pos, index),
        index: index,
      };
    } else {
      if (isMobileDevice) {
        if (this.isInElement(e)) {
          e.stopPropagation();
        }
      } else {
        e.stopPropagation();
      }
      coord = {
        x: pointer.start.x,
        y: pointer.start.y,
        index: null,
      };
    }

    this.setState(
      {
        pointer: update(pointer, {
          current: {
            $set: coord,
          },
        }),
        // debugMessage: 'over answer'
      },
      () => this._drawLine(pointer.start.x, pointer.start.y, coord.x, coord.y)
    );
  };

  onDrawEnd = (e) => {
    const { answers, onLineCompleted } = this.props;
    const { drawing, pointer } = this.state;

    if (this.props.solvedData && this.props.solvedData.solved) {
      return;
    }

    if (this.state.isMobileSize) {
      return;
    }

    e.preventDefault();

    if (!drawing) {
      return;
    }

    this.setState({
      drawing: false,
    });

    if (pointer.start && pointer.current && pointer.current.index != null) {
      let fromIdx;
      let toIdx;

      if (pointer.start.pos === "left") {
        fromIdx = pointer.start.index;
        toIdx = pointer.current.index;
      } else {
        toIdx = pointer.start.index;
        fromIdx = pointer.current.index;
      }

      onLineCompleted(fromIdx, toIdx);

      this.setState({
        pointer: {
          start: null,
          current: null,
        },
        // debugMessage: 'completed'
      });
    }

    this._drawLine();
  };

  drawLine = (e) => {
    const { drawing, pointer } = this.state;

    if (this.props.solvedData && this.props.solvedData.solved) {
      return;
    }

    if (this.state.isMobileSize) {
      return;
    }

    e.preventDefault();

    const pos = this.getRealPosition(e);

    //TEMP: for debugging
    // const t = e.touches ? e.touches[0] : e;
    // this.setState({
    //   x: pos.x,
    //   y: pos.y,
    //   positions: {
    //     ...this.state.positions,
    //     cx: t.clientX,
    //     cy: t.clientY,
    //     px: t.pageX,
    //     py: t.pageY,
    //     scrollTop: document.documentElement.scrollTop || document.body.scrollTop,
    //     height: window.innerHeight
    //   }
    // })

    if (!drawing) {
      return;
    }

    if (isMobileDevice) {
      if (
        this.bounds.length == 0 ||
        pos.x <= this.bounds[0] ||
        pos.x >= this.bounds[1]
      ) {
        const ans = this.findIntersect(e, pointer.start.pos);
        if (ans != null) {
          this.onOverAnswer(e, ans.pos, ans.index);
          return;
        }
      }
    }

    this.checkScroll(e);

    this.setState(
      {
        pointer: update(pointer, {
          current: {
            $set: pos,
          },
        }),
        // debugMessage: 'move and drawing'
      },
      () => this._drawLine(pointer.start.x, pointer.start.y, pos.x, pos.y)
    );
  };

  _drawLine(fx, fy, tx, ty) {
    const { answers, left, right, solvedData, showReallyTrue } = this.props;
    const { drawing } = this.state;

    const ctx = this.canvas.getContext("2d");

    this.clear(ctx);

    answers.forEach((a, i) => {
      if (a == null) {
        return;
      }

      const toIdx = right.findIndex((r) => r.key === a.key);

      let strokeColor = "#00d3ff";

      if (solvedData && solvedData.solved) {
        if (this.answersMap[i].correct === "o") {
          strokeColor = "#04c17a";
        } else {
          strokeColor = "#ff5a5a";
        }
      }

      ctx.strokeStyle = strokeColor;
      ctx.lineWidth = 5;

      const from = this.getElemPosition("left", i);
      const to = this.getElemPosition("right", toIdx);

      ctx.beginPath();
      ctx.moveTo(from.x, from.y);
      ctx.lineTo(to.x, to.y);
      ctx.closePath();
      ctx.stroke();
    });

    if (showReallyTrue) {
      answers.forEach((a, i) => {
        const toIdx = right.findIndex(
          (r) => r.key === this.answersMap[i].realRight
        );

        const strokeColor = "#04c17a";

        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = 5;

        const from = this.getElemPosition("left", i);
        const to = this.getElemPosition("right", toIdx);

        ctx.beginPath();
        ctx.moveTo(from.x, from.y);
        ctx.lineTo(to.x, to.y);
        ctx.closePath();
        ctx.stroke();
      });
    }

    if (drawing) {
      ctx.strokeStyle = "black";
      ctx.lineWidth = 5;

      ctx.beginPath();
      ctx.moveTo(fx, fy);
      ctx.lineTo(tx, ty);
      ctx.closePath();
      ctx.stroke();
    }
  }

  checkScroll = (_e) => {
    const e = _e.touches ? _e.touches[0] : _e;

    const scrollPos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollPos > 0 && e.clientY <= 120) {
      this.startScroll(-10, 10);
    } else if (e.clientY >= window.innerHeight - 50) {
      this.startScroll(10, 10);
    } else {
      if (this.scrollTimeout) {
        this.endScroll();
      }
    }
  };

  startScroll = (diff, timeout) => {
    const { isMobileSize, drawing } = this.state;
    if (isMobileSize || !drawing) {
      this.scrollTimeout = null;
      return;
    }

    if (this.scrollTimeout) {
      return;
    }

    this.scrollTimeout = setTimeout(() => {
      console.log("scroll", diff);

      const scrollPos =
        document.documentElement.scrollTop || document.body.scrollTop;
      let nextPos = scrollPos + diff;
      if (nextPos < 0) {
        nextPos = 0;
      }
      window.scroll(0, nextPos);

      this.scrollTimeout = null;
      this.startScroll(diff);
    }, timeout);
  };

  endScroll = () => {
    if (!this.scrollTimeout) {
      return;
    }

    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = null;
  };

  clear = (ctx) => {
    if (!ctx) {
      if (!this.canvas) {
        return;
      }
      ctx = this.canvas.getContext("2d");
    }
    this.canvas.width = this.canvas.width;
    // ctx.fillStyle = 'rgb(100,255,255)'
    // ctx.fillRect(0, 0, this.width, this.height);
    if (grid) {
      ctx.strokeStyle = "gray";
      ctx.lineWidth = 0.1;

      for (let x = 0; x <= this.canvas.width; x += 10) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, this.canvas.height);
        ctx.closePath();
        ctx.stroke();
      }

      for (let y = 0; y <= this.canvas.height; y += 10) {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(this.canvas.width, y);
        ctx.closePath();
        ctx.stroke();
      }
    }
  };

  onMobilePairing = (position, index) => {
    if (position === "left") {
      this.setState({
        mobileSelectLeft: index,
      });
    } else {
      const { onLineCompleted } = this.props;

      const src = this.state.mobileSelectLeft;
      const dest = index;

      onLineCompleted(src, dest);
      this.setState({
        mobileSelectLeft: null,
      });
    }
  };

  cancelSelect = (id, e) => {
    const { onLineCanceled } = this.props;
    onLineCanceled(id);
  };

  render() {
    const {
      parentId,
      left,
      right,
      answers,
      solvedData,
      showReallyTrue,
      forWorkbook,
    } = this.props;

    const { pointer: p, drawing, isMobileSize } = this.state;

    const answersMap = this.processAnswers(answers);
    // const isMobileSize = false;
    const isSolved = solvedData && solvedData.solved;

    return (
      <>
        <div
          className="t-answer-paring"
          ref={(e) => (this.container = e)}
          style={{ position: "relative" }}
          onTouchMove={this.drawLine}
          onMouseMove={this.drawLine}
          onMouseUp={this.onDrawEnd}
          onTouchEnd={this.onDrawEnd}
        >
          <canvas
            ref={(e) => (this.canvas = e)}
            style={{
              display: isMobileSize ? "none" : "block",
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
            }}
          />

          {answersMap.length > 0 &&
            answersMap.map((a, i) => {
              if (a.answer) {
                let realRight = null;
                if (showReallyTrue) {
                  realRight = right.find((value, i) => {
                    return value.key === a.realRight;
                  });
                }
                return (
                  <div
                    key={i}
                    className={classNames("t-answer-paring__pair", {
                      "is-chk": !solvedData.solved,
                      "is-true": !showReallyTrue && a.correct === "o",
                      "is-false": !showReallyTrue && a.correct === "x",
                      "is-reallytrue": showReallyTrue,
                      "none-event": solvedData && solvedData.solved,
                    })}
                    onClick={() => this.cancelSelect(i)}
                  >
                    <PairedAnswer
                      key={parentId + "_l_a_mobile" + i}
                      id={
                        parentId +
                        "_l_a_mobile" +
                        i +
                        (showReallyTrue ? "-reallytrue" : "")
                      }
                      data={left[a.left]}
                      index={i}
                    />

                    <PairedAnswer
                      key={parentId + "_r_a_mobile" + i}
                      id={
                        parentId +
                        "_r_a_mobile" +
                        i +
                        (showReallyTrue ? "-reallytrue" : "")
                      }
                      data={showReallyTrue ? realRight : right[a.right]}
                      index={i}
                    />
                  </div>
                );
              }
            })}

          {/* LEFT */}
          <div
            ref={(e) => {
              if (e) {
                this.leftContainer = e;
                this.bounds[0] = e.offsetLeft + e.offsetWidth;
              }
            }}
            className="t-answer-paring__left box-wrap left"
            style={{ touchAction: isSolved || isMobileSize ? "auto" : "none" }}
          >
            {left.map((s, i) => (
              <div
                key={`left-${i}`}
                ref={(e) => this.leftAnswers.push(e)}
                onMouseMove={(e) => this.onOverAnswer(e, "left", i)}
                onTouchMove={(e) => this.onOverAnswer(e, "left", i)}
                onTouchStart={(e) => this.onDrawStart(e, "left", i)}
                onMouseDown={(e) => this.onDrawStart(e, "left", i)}
              >
                <PairedAnswer
                  key={parentId + "_l_" + i}
                  id={parentId + "_l_" + i}
                  height={this.state.leftClientHeight}
                  data={s}
                  index={i}
                  start={p.start}
                  drawing={drawing}
                  position={"left"}
                  answersMap={answersMap}
                  solvedData={solvedData}
                  showReallyTrue={showReallyTrue}
                  onMobilePairing={this.onMobilePairing}
                  isMobileOnly={isMobileSize}
                  mobileSelectLeft={this.state.mobileSelectLeft}
                  onDown={this.onDrawStart}
                  onMove={this.onOverAnswer}
                  onEnd={this.onDrawEnd}
                  onLoadImage={this.onResize}
                />

                {/* dot */}
                <div
                  className="dot-wrap"
                  ref={(e) => this.leftDots.push(e)}
                  style={{ display: isMobileSize ? "none" : "inline-table" }}
                >
                  <div className="dot"></div>
                </div>
              </div>
            ))}
          </div>

          <div className="pairingLinesArea" />

          {/* RIGHT */}
          <div
            ref={(e) => {
              if (e) {
                this.rightContainer = e;
                this.bounds[1] = e.offsetLeft;
              }
            }}
            className="t-answer-paring__right box-wrap right"
            style={{ touchAction: isSolved || isMobileSize ? "auto" : "none" }}
          >
            {right.map((s, i) => (
              <div
                key={`right-${i}`}
                ref={(e) => this.rightAnswers.push(e)}
                onMouseMove={(e) => this.onOverAnswer(e, "right", i)}
                onTouchMove={(e) => this.onOverAnswer(e, "right", i)}
                onTouchStart={(e) => this.onDrawStart(e, "right", i)}
                onMouseDown={(e) => this.onDrawStart(e, "right", i)}
              >
                {/* dot */}
                <div
                  className="dot-wrap"
                  ref={(e) => this.rightDots.push(e)}
                  style={{ display: isMobileSize ? "none" : "inline-table" }}
                >
                  <div className="dot" />
                </div>

                <PairedAnswer
                  key={parentId + "_l_" + i}
                  id={parentId + "_l_" + i}
                  height={this.state.leftClientHeight}
                  data={s}
                  index={i}
                  start={p.start}
                  drawing={drawing}
                  position={"right"}
                  answersMap={answersMap}
                  solvedData={solvedData}
                  showReallyTrue={showReallyTrue}
                  onMobilePairing={this.onMobilePairing}
                  isMobileOnly={isMobileSize}
                  mobileSelectLeft={this.state.mobileSelectLeft}
                  onDown={this.onDrawStart}
                  onMove={(a, b, c) => this.onOverAnswer(a, b, c)}
                  onLoadImage={this.onResize}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div style={{ marginTop: '5px', padding: '3px', background: 'black', color: 'green', }}>
          <p>{this.state.debugMessage}</p>
        </div>
        <div style={{ marginTop: '5px', padding: '3px', fontSize: '11px', background: 'black', color: 'green', }}>
          <p>{`${this.state.x}, ${this.state.y}`}</p>
          <p>{`client: ${p.cx}, ${p.cy}`}</p>
          <p>{`page: ${p.px}, ${p.py}`}</p>

          <p>{`scroll: ${p.scrollTop}`}</p>
          <p>{`height: ${p.height}`}</p>

          <p>{`bounds: ${p.bounds[0]}, ${p.bounds[1]}`}</p>
        </div> */}
      </>
    );
  }
}

export default PairingTest;
