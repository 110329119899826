import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SelectableImage } from './modules';
import { formatText } from './utils/Formatter';
import { actionLog } from 'utils/LogUtils';

class ImageSelectQuiz extends Component {

  state = {
    answers: null
  }

  init = (data) => {
    const { stepQuizAnswers } = this.props;
    const { quiz, quizId } = data;
    const { problem, choices } = quiz;
    const { answers } = this.state;

    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      const stepAnswersMap = [];
      choices.forEach(c => {
        if (stepQuizAnswers.find(s => s === c.key)) {
          stepAnswersMap.push(true);
        } else {
          stepAnswersMap.push(false);
        }
      });

      this.setState({
        answers: stepAnswersMap
      })
    } else {
      let answerCount = quiz.choices.length;
      this.setState({
        answers: new Array(answerCount).fill(false)
      }, () => {
        actionLog("quiz", "init", {
          quizId: quizId,
          type: quiz.type,
          answers: this.state.answers
        });
      });
    }
  }

  componentWillMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
  }

  componentWillReceiveProps(props) {
    const { data: old } = this.props;
    const { data } = props;

    if (data != null && (old == null || old.quizId !== data.quizId || old.timestamp !== data.timestamp)) {
      this.init(data);
    }
  }

  handleSelected = (index, selected) => {
    const { onAnswerUpdated, data } = this.props;
    const { choices } = data.quiz;

    this.setState((s) => ({
      answers: update(s.answers, {
        [index]: {
          $set: selected
        }
      })
    }), () => {
      const conv = [];
      this.state.answers.forEach((selected, i) => { if (selected) { conv.push(choices[i].key) } });
      onAnswerUpdated(conv);
    });
  }

  render() {

    const { data, isMobileSize, play, solved, solvedData, showReallyTrue } = this.props;
    const { quizId, quiz } = data;
    const { image, imageMobile, anim, choices, textsOnImage } = quiz;

    let _answers = this.state.answers;
    if(this.props.viewType === "guide") {
      if(this.props.sendValue) {
        this.props.sendValue(this.state.answers)
      }
    } else if( (this.props.viewType === "student"||this.props.viewType === "preview") && this.props.receiveValue)
    {
      _answers = this.props.receiveValue;
    }
    return (
      <div className="quiz-content__answer t-answer-imgpick">
        <SelectableImage
          image={image}
          isMobileSize={isMobileSize}
          imageMobile={imageMobile}
          rects={choices}
          frames={anim}
          answers={_answers}
          texts={textsOnImage}
          solvedData={solvedData}
          showReallyTrue={showReallyTrue}
          onSelected={this.handleSelected}
        />
      </div>
    )
  }

}

ImageSelectQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
}

export default ImageSelectQuiz;
