import { createAction, handleActions } from "redux-actions";
import axios from "axios";
import {
  SESSION_KEY,
  getHost,
  getDefaultConfig,
  parseAxiosError
} from "utils/APIUtils";
import update from "immutability-helper";
import firebase from "firebase";

const INIT = "auth/INIT";

const LOGIN = "auth/LOGIN";
const LOGIN_LOADING = "auth/LOGIN_LOADING";
const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
const LOGIN_ERROR = "auth/LOGIN_ERROR";

const LOGOUT = "auth/LOGOUT";

const SET_AUTH = "auth/SET_AUTH";

const CHECK_USER = "auth/CHECK_USER";
const CHECK_USER_LOADING = "auth/CHECK_USER_LOADING";
const CHECK_USER_SUCCESS = "auth/CHECK_USER_SUCCESS";
const CHECK_USER_ERROR = "auth/CHECK_USER_ERROR";

const REFRESH = "auth/REFRESH";
const REFRESH_LOADING = "auth/REFRESH_LOADING";
const REFRESH_SUCCESS = "auth/REFRESH_SUCCESS";
const REFRESH_ERROR = "auth/REFRESH_ERROR";

const REGISTER = "auth/REGISTER";
const REGISTER_LOADING = "auth/REGISTER_LOADING";
const REGISTER_SUCCESS = "auth/REGISTER_SUCCESS";
const REGISTER_ERROR = "auth/REGISTER_ERROR";

const MIGRATE = "auth/MIGRATE";
const MIGRATE_LOADING = "auth/MIGRATE_LOADING";
const MIGRATE_SUCCESS = "auth/MIGRATE_SUCCESS";
const MIGRATE_ERROR = "auth/MIGRATE_ERROR";

const REFRESH_POINT = "auth/REFRESH_POINT";

const CLEAR_ERROR = "auth/CLEAR_ERROR";

const SET_REFRESH_TIMEOUT = "auth/SET_REFRESH_TIMEOUT";

const UPDATE_CURRENT_CHILD_INFO = "auth/UPDATE_CURRENT_CHILD_INFO";

const UPDATE_USER_INFO_LOADING = "auth/UPDATE_USER_INFO_LOADING";
const UPDATE_USER_INFO_SUCCESS = "auth/UPDATE_USER_INFO_SUCCESS";
const UPDATE_USER_INFO_ERROR = "auth/UPDATE_USER_INFO_ERROR";

const UPDATE_STUDENT_ACCOUNT = "auth/UPDATE_STUDENT_ACCOUNT";
const UPDATE_STUDENT_ACCOUNT_LOADING = "auth/UPDATE_STUDENT_ACCOUNT_LOADING";
const UPDATE_STUDENT_ACCOUNT_SUCCESS = "auth/UPDATE_STUDENT_ACCOUNT_SUCCESS";
const UPDATE_STUDENT_ACCOUNT_ERROR = "auth/UPDATE_STUDENT_ACCOUNT_ERROR";

const PUSH_SETTING = "auth/PUSH_SETTING";
const PUSH_SETTING_LOADING = "auth/PUSH_SETTING_LOADING";
const PUSH_SETTING_SUCCESS = "auth/PUSH_SETTING_SUCCESS";
const PUSH_SETTING_ERROR = "auth/PUSH_SETTING_ERROR";

const DUPLICATED_LOGIN = "auth/DUPLICATED_LOGIN";

const SET_INTERACTION = "auth/SET_INTERACTION";

export const init = createAction(INIT);

function registerFCMToken() {
  try {
    return firebase
      .messaging()
      .getToken()
      .then(token =>
        axios.put(
          `${getHost()}/notification/v2/pushes?token=${token}`,
          {},
          {
            ...getDefaultConfig()
          }
        )
      )
      .catch(e => {
        console.log("failed to register fcm", e);
      });
  } catch (e) {
    console.log("failed to register fcm", e);
  }
}

function deregisterFCMToken(userId) {
  try {
    return firebase
      .messaging()
      .getToken()
      .then(token =>
        axios.delete(
          `${getHost()}/notification/v2/pushes?userId=${userId}&token=${token}`,
          {},
          {
            ...getDefaultConfig()
          }
        )
      )
      .catch(e => {
        console.log("failed to deregister fcm", e);
      });
  } catch (e) {
    console.log("failed to deregister fcm", e);
  }
}

function requestFCM() {
  try {
    const messaging = firebase.messaging();

    return messaging
      .requestPermission()
      .then(() => {
        console.log("FCM confirmed");
        return registerFCMToken().then(response => {
          console.log("token registered.");
        });
      })
      .then(() => {
        messaging.onMessage(payload => {
          console.log("Push received", payload);
        });

        messaging.onTokenRefresh(() => {
          registerFCMToken()
            .then(response => {
              console.log("token updated.");
            })
            .catch(e => { });
        });
      })
      .catch(err => {
        console.log("FCM error", err);
      });
  } catch (e) {
    console.log("failed to request fcm");
  }
}

function _getUserInfo(id, type, orgId) {
  const host = getHost();

  let url = "/academy/v2/accounts/";

  if (type === "org") {
    url += `${id}`;
  } else if (type === "guide") {
    url += `${orgId}/guides/${id}`;
  } else if (type === "academy_student") {
    url += `${orgId}/students/${id}`;
  }

  const config = getDefaultConfig();

  return axios.get(`${host}${url}`, config);
}

export const checkCurrentUser = (id, type, orgId) => ({
  type: CHECK_USER,
  payload: _getUserInfo(id, type, orgId || id)
});

export const updateCurrChildInfo = child => (dispatch, getState) => {
  console.log("update user info: ", child);
  dispatch({
    type: UPDATE_CURRENT_CHILD_INFO,
    payload: {
      child
    }
  });
};

function doLogin(payload) {
  const { account, password } = payload;

  const host = getHost();

  return axios.post(
    host + "/academy/v2/auth/new",
    {
      account: account,
      password: password
    },
    {
      ...getDefaultConfig()
    }
  );
}

export const _login = payload => ({
  type: LOGIN,
  payload: doLogin(payload)
});

export const login = payload => (dispatch, getState) => {
  return dispatch(_login(payload)).then(response => {
    const { type: userType, active, free } = response.value.data;

    const { user, expireAt } = getState().auth;
    const { userId, type, orgId } = user;
    console.log("auth user: ", user);
    dispatch(setRefreshTimeout(expireAt));
    return dispatch(checkCurrentUser(userId, type, orgId));
  });
};

// export const logout = createAction(LOGOUT);
export const logout = (redirectUrl = null) => (dispatch, getState) => {
  dispatch({
    type: LOGOUT
  });
};

export const refresh = () => (dispatch, getState) => {
  const stored = localStorage.getItem(SESSION_KEY);
  if (stored == null) {
    console.log("token is empty");
    return dispatch({
      type: LOGIN_ERROR
    });
  }

  const host = getHost();
  const { token, refreshToken, orgId: storedOrgId } = JSON.parse(stored);

  if (refreshToken == null) {
    console.log("token is empty");
    return dispatch({
      type: LOGIN_ERROR
    });
  }

  return axios
    .post(
      `${host}/academy/v2/auth/refresh`,
      {
        token: token,
        refreshToken: refreshToken
      },
      {
        ...getDefaultConfig()
      }
    )
    .then(response => {
      const { userId, orgId, type } = response.data;
      console.log("refresh res: ", response);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { ...response, hasInteracted: false }
      });

      dispatch(checkCurrentUser(userId, type, orgId || storedOrgId));

      dispatch(setRefreshTimeout(response.data.expireAt));

      return Promise.resolve(response);
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem(SESSION_KEY);
        window.location.replace('/');
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      }
    });
};

export const register = payload => dispatch => {
  dispatch({
    type: REGISTER_LOADING
  });

  return axios
    .post(`${getHost()}/user/v2/users`, payload, {
      ...getDefaultConfig()
    })
    .then(response => {
      dispatch({
        type: REGISTER_SUCCESS
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: REGISTER_ERROR,
        payload: {
          error
        }
      });
      return false;
    });
};

export const setRefreshTimeout = expireAt => dispatch => {
  const timeout = expireAt - new Date().getTime() - 600000;
  if (timeout > 0) {
    console.log("refresh after " + timeout);

    dispatch({
      type: SET_REFRESH_TIMEOUT,
      payload: setTimeout(() => {
        dispatch(refresh());
      }, timeout)
    });
  }
};

export const refreshPoint = () => (dispatch, getState) => {
  const user = getState().auth.user;
  const { type, userId } = user;

  const host = getHost();
  const uri =
    type === "parent" ? "coins" : type === "student" ? "points" : null;
  if (uri == null) {
    return;
  }

  return axios
    .get(`${host}/user/v2/${uri}/0`, {
      ...getDefaultConfig()
    })
    .then(response => {
      dispatch({
        type: REFRESH_POINT,
        payload: response.data
      });
    });
};

export const clearError = createAction(CLEAR_ERROR);

export const updateUserInfo = userInfo => (dispatch, getState) => {
  const userId = getState().auth.user.userId;
  const type = getState().auth.user.type;
  const orgId = getState().auth.user.orgId;
  dispatch({
    type: UPDATE_USER_INFO_LOADING
  });

  const host = getHost();

  let url = `${host}/user/v2/users/${userId}`;
  return axios
    .patch(url, userInfo, {
      ...getDefaultConfig()
    })
    .then(response => {
      dispatch({
        type: UPDATE_USER_INFO_SUCCESS
      });
      dispatch(checkCurrentUser(userId, type, orgId));
    })
    .catch(error => {
      dispatch({
        type: UPDATE_USER_INFO_ERROR,
        payload: {
          error
        }
      });
    });
};

export const changePassword = passwordForm => (dispatch, getState) => {
  const userId = getState().auth.user.userId;
  const type = getState().auth.user.type;
  const orgId = getState().auth.user.orgId;
  dispatch({
    type: UPDATE_USER_INFO_LOADING
  });

  const host = getHost();
  let url = `${host}/user/v2/users/${userId}/password`;
  return axios
    .put(url, passwordForm, {
      ...getDefaultConfig()
    })
    .then(response => {
      dispatch({
        type: UPDATE_USER_INFO_SUCCESS
      });
      dispatch(checkCurrentUser(userId, type, orgId));
      return true;
    })
    .catch(error => {
      let message = "";
      const e = parseAxiosError(error);

      const param = e.parameter;

      if (param === "oldPassword") {
        message = " 기존 비밀번호를 확인해 주세요.";
      }

      dispatch({
        type: UPDATE_USER_INFO_ERROR
      });
      return false;
    });
};

export const loadPushSettings = () => ({
  type: PUSH_SETTING,
  payload: axios.get(`${getHost()}/notification/v2/pushes/settings`, {
    ...getDefaultConfig()
  })
});

export const updatePush = data => ({
  type: PUSH_SETTING,
  payload: axios.patch(`${getHost()}/notification/v2/pushes/settings`, data, {
    ...getDefaultConfig()
  })
});

export const setInteraction = data => ({
  type: SET_INTERACTION,
  payload: data
});

export const updateStudentAccount = (studentId, account, password) => (
  dispatch,
  getState
) => {
  dispatch({
    type: UPDATE_STUDENT_ACCOUNT_LOADING,
    payload: {
      studentId: studentId
    }
  });

  return axios
    .put(
      `${getHost()}/user/v2/users/${
      getState().auth.user.userId
      }/students/${studentId}/account`,
      {
        account: account,
        password: password
      },
      {
        ...getDefaultConfig()
      }
    )
    .then(response => {
      console.log(response);
      return dispatch({
        type: UPDATE_STUDENT_ACCOUNT_SUCCESS,
        payload: response
      });
    })
    .catch(e => {
      return dispatch({
        type: UPDATE_STUDENT_ACCOUNT_ERROR,
        payload: e
      });
    });
};

export const duplicatedLoginDetected = value => dispatch => {
  dispatch({
    type: LOGOUT
  });

  dispatch({
    type: DUPLICATED_LOGIN,
    payload: value != null ? value : true
  });
};

const initialState = {
  init: false,
  pending: false,
  logged: false,
  duplicated: false,
  user: {
    loaded: false,
    type: null, // parent, student
    active: false,
    children: []
  },
  pushSetting: {
    pending: true
  },
  childSubscriptionList: {
    pending: false,
    list: []
  },
  error: {
    triggered: false,
    code: null,
    message: null
  }
};

export default handleActions(
  {
    [INIT]: state => {
      return {
        ...state,
        init: true,
        pending: false,
        logged: false,
        migrateUser: null,
        migrateToken: null,
        user: {},
        error: {
          triggered: false
        }
      };
    },
    [LOGIN_LOADING]: (state, { payload }) => {
      return {
        ...state,
        pending: true
      };
    },
    [LOGIN_SUCCESS]: (state, { payload }) => {
      if (payload.data.needToMigrate) {
        return {
          ...state,
          pending: false,
          logged: false,
          migrateUser: payload.data.migrateUser,
          migrateToken: payload.data.migrateToken,
          userId: payload.data.userId
        };
      }

      const auth = {
        userId: payload.data.userId,
        orgId: payload.data.orgId || payload.data.userId,
        type: payload.data.type,
        token: payload.data.token,
        refreshToken: payload.data.refreshToken,
        expireAt: payload.data.expireAt
      };

      localStorage.setItem(SESSION_KEY, JSON.stringify(auth));

      if (typeof window.Android !== "undefined" && window.Android !== null) {
        window.Android.onLogin(auth.userId, auth.type, auth.token);
      } else if (
        window.webkit != null &&
        window.webkit.messageHandlers != null
      ) {
        try {
          window.webkit.messageHandlers.onLogin.postMessage({
            userId: auth.userId,
            type: auth.type,
            token: auth.token
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("Can't find android");
        requestFCM();
      }

      return {
        ...state,
        pending: false,
        expireAt: auth.expireAt,
        hasInteracted: payload.hasInteracted === false ? false : true,
        user: {
          ...state.user,
          userId: payload.data.userId,
          orgId: payload.data.orgId || payload.data.userId,
          type: payload.data.type,
          active: payload.data.active,
          free: payload.data.free,
          loaded: false,
          productType: payload.data.productType || null
        },
        permissions:
          payload.data.roles && payload.data.roles.length > 0
            ? payload.data.roles
              .map(r => r.permissions)
              .reduce((a, b) => a.concat(b))
            : [],
        error: {
          triggered: false,
          code: null,
          message: null
        }
      };
    },
    [LOGIN_ERROR]: (state, { payload }) => {
      localStorage.removeItem(SESSION_KEY);
      return {
        ...state,
        pending: false,
        logged: false,
        user: {
          type: null,
          active: false
        },
        error: parseAxiosError(payload)
      };
    },

    [LOGOUT]: (state, { payload }) => {
      localStorage.removeItem(SESSION_KEY);

      if (state.refreshTimeout != null) {
        clearTimeout(state.refreshTimeout);
      }

      // const promise = deregisterFCMToken(state.user.userId);
      // if (promise != null) {
      //   promise.then(response => {
      //     console.log("token deregistered.");
      //   });
      // }

      return {
        ...state,
        pending: false,
        logged: false,
        refreshTimeout: null,
        auth: {},
        user: {
          type: null,
          active: false
        }
      };
    },

    [CHECK_USER_LOADING]: (state, { payload }) => {
      // return {
      //   ...state,
      //   pending: true
      // }
      return state;
    },
    [CHECK_USER_SUCCESS]: (state, { payload }) => {
      console.log("check user success: ", payload);
      console.log("check user success: ", state);

      let orgInfo = {};
      if (state.user.type === "org") {
        orgInfo["orgType"] = payload.data.type;
        orgInfo["orgName"] = payload.data.name;
        orgInfo["orgSubName"] = payload.data.subname || "";
        orgInfo["orgQuizSetName"] = payload.data.quizSetName;
        orgInfo["orgQuizSetOrgId"] = payload.data.quizSetOrgId;
      } else {
        const orgData = payload.data.organization;
        orgInfo["orgType"] = orgData.type;
        orgInfo["orgName"] = orgData.name;
        orgInfo["orgSubName"] = orgData.subname || "";
        orgInfo["orgQuizSetName"] = orgData.quizSetName;
        orgInfo["orgQuizSetOrgId"] = orgData.quizSetOrgId;
      }

      return {
        ...state,
        init: true,
        pending: false,
        logged: true,
        user: {
          ...state.user,
          ...payload.data,
          // active: active,
          loaded: true,
          type: state.user.type,
          name: payload.data.name,
          // orgType: payload.data.type,
          // orgName: payload.data.name,
          // orgSubName: payload.data.subname || ""
          ...orgInfo
        },
        error: {
          triggered: false,
          code: null,
          message: null
        }
      };
    },
    [CHECK_USER_ERROR]: (state, { payload }) => {
      localStorage.removeItem(SESSION_KEY);

      if (state.refreshTimeout != null) {
        clearTimeout(state.refreshTimeout);
      }

      return {
        ...state,
        pending: false,
        logged: false,
        init: true,
        user: {
          loaded: false
        },
        error: {
          triggered: true,
          status: payload.response.status,
          code: payload.response.data.code,
          message: payload.response.data.message
        }
      };
    },
    [REGISTER_LOADING]: state => {
      return {
        ...state,
        pending: true
      };
    },
    [REGISTER_SUCCESS]: state => {
      return {
        ...state,
        pending: false
      };
    },
    [REGISTER_ERROR]: (state, { payload }) => {
      // const error = parseAxiosError(payload)
      return {
        ...state,
        pending: false
        // error: error
      };
    },

    [CLEAR_ERROR]: state => {
      return {
        ...state,
        error: {
          ...state.error,
          triggered: false
        }
      };
    },

    [SET_REFRESH_TIMEOUT]: (state, { payload }) => {
      return {
        ...state,
        refreshTimeout: payload
      };
    },

    [REFRESH_POINT]: (state, { payload }) => {
      const type = state.user.type;
      const key =
        type === "parent" ? "coin" : type === "student" ? "point" : null;
      if (key == null) {
        return state;
      }

      return update(state, {
        user: {
          [key]: {
            $set: parseInt(payload)
          }
        }
      });
    },

    [UPDATE_CURRENT_CHILD_INFO]: (state, { payload }) => {
      const index = state.user.children.findIndex(
        c => c.studentId === payload.child.studentId
      );

      return update(state, {
        user: {
          children: {
            [index]: {
              $set: payload.child
            }
          }
        }
      });
    },

    [UPDATE_USER_INFO_LOADING]: state => {
      return {
        ...state,
        pending: true
      };
    },
    [UPDATE_USER_INFO_SUCCESS]: state => {
      return {
        ...state,
        pending: false
      };
    },
    [UPDATE_USER_INFO_ERROR]: (state, { payload }) => {
      const error = parseAxiosError(payload);
      return {
        ...state,
        pending: false,
        error: error
      };
    },

    [PUSH_SETTING_LOADING]: state => {
      return update(state, {
        pushSetting: {
          pending: {
            $set: true
          }
        }
      });
    },
    [PUSH_SETTING_SUCCESS]: (state, { payload }) => {
      return update(state, {
        pushSetting: {
          pending: {
            $set: false
          },
          data: {
            $set: payload.data
          }
        }
      });
    },
    [PUSH_SETTING_ERROR]: (state, { payload }) => {
      const error = parseAxiosError(payload);
      return update(state, {
        pushSetting: {
          pending: {
            $set: false
          },
          error: {
            $set: error
          }
        }
      });
    },

    [SET_INTERACTION]: (state, { payload }) => {
      console.log("set interaction: ", payload);
      return {
        ...state,
        hasInteracted: update(state.hasInteracted, { $set: payload })
      };
    },

    [UPDATE_STUDENT_ACCOUNT_LOADING]: (state, { payload }) => {
      const idx = state.user.children.findIndex(
        c => c.studentId === payload.studentId
      );
      if (idx < 0) {
        return state;
      }

      return update(state, {
        user: {
          children: {
            [idx]: {
              pending: {
                $set: true
              }
            }
          }
        }
      });
    },
    [UPDATE_STUDENT_ACCOUNT_SUCCESS]: (state, { payload }) => {
      console.log(payload.data);

      const idx = state.user.children.findIndex(
        c => c.studentId === payload.data.studentId
      );
      if (idx < 0) {
        return state;
      }

      return update(state, {
        user: {
          children: {
            [idx]: {
              $set: payload.data
            }
          }
        }
      });
    },
    [UPDATE_STUDENT_ACCOUNT_ERROR]: (state, { payload }) => {
      const idx = state.user.children.findIndex(
        c => c.studentId === payload.data.studentId
      );
      if (idx < 0) {
        return state;
      }

      return update(state, {
        user: {
          children: {
            [idx]: {
              error: {
                $set: parseAxiosError(payload, "student")
              }
            }
          }
        }
      });
    },

    [DUPLICATED_LOGIN]: (state, { payload }) => {
      return update(state, {
        duplicated: {
          $set: payload
        }
      });
    }
  },
  initialState
);
