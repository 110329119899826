import React, { useState, useEffect } from "react";
import Axios from "../../../utils/request";
import Queturepoint from "./Queturepoint";
import QueturePlayer from "../../../components/QueturePlayer/QueturePlayer";
import QuetureView from "./QuetureView";
import StorytellingAnimation from "../storytelling/StorytellingAnimation/StorytellingAnimation";

const QuetureMain = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [quetureData, setQuetureData] = useState(null);
  const { seq, quetureId, isKids, viewType, startQueture, curPage } = props;

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `/content/v2/quetures/${quetureId}?withQuetureTeachingGuide=${false}&withQuetureCampusNote=${true}`
    )
      .then((response) => {
        setLoading(false);
        setQuetureData(response);
        console.log("queture data: ", response);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [quetureId]);

  if (curPage === 0 && isKids && viewType === "preview") {
    return <StorytellingAnimation isQueture={true} title={quetureData?.title || ""} />;
  }
  return (
    <div>
      {curPage === 0 ? (
        <Queturepoint quetureData={quetureData} {...props} />
      ) : (
        <QuetureView quetureData={quetureData} {...props} />
      )}
    </div>
  );
};

export default QuetureMain;
