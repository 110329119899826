import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter,
  withRouter,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import * as authActions from "store/modules/auth";
// import { renderRoutes } from "react-router-config";`
import routerData from "../../router/routerData";
import CommonLayout from "../../layouts/CommonLayout";
import { Provider } from "react-redux";
import store from "../../store";
import AuthMonitor from "../../components/AuthMonitor";
import Router from "./router";
import { createBrowserHistory } from "history";
// import AdminLayout from "../../layouts/AdminLayout";
import { AdminLayout } from "pages/index.async.js";
import BasicLayout from "../../layouts/BasicLayout";
import renderRoutes from "../../utils/renderRoutes";
import {
  mockupRoutes,
  blankLayoutRoutes,
  basicLayoutRoutes,
  authorizedRoutes,
  userLayoutRoutes,
} from "../../router/routerData";
import BlankLayout from "../../layouts/BlankLayout";
import UserLayout from "../../layouts/UserLayout";
import { Skeleton } from "antd";
import { ConstantsContext } from "utils/ConstantsContext";
import { constants } from "utils/constants";

const RouterConfig = (props) => {
  const [type, setType] = useState("campus");

  useEffect(() => {
    if (props.orgType === "school" || props.orgType === "campus") {
      console.log("setType: ", props.orgType);
      setType(props.orgType);
    }
    return () => {};
  }, [props.logged]);

  function renderRouter() {
    const { init } = props;
    if (!init) {
      return <Skeleton loading={!init} active />;
    }

    const mockupRouter = mockupRoutes;
    const adminLayoutRouter = authorizedRoutes;
    const basicLayoutRouter = basicLayoutRoutes;
    const blankLayoutRouter = blankLayoutRoutes;

    mockupRouter.map((route) => {
      route.layout = BasicLayout;
    });
    adminLayoutRouter.map((route) => {
      route.layout = AdminLayout;
      route.requiresAuth = true;
      route.authed = true;
      return route;
    });
    userLayoutRoutes.map((route) => {
      route.layout = UserLayout;
      route.authed = true;
      route.requiresAuth = true;
      return route;
    });
    basicLayoutRouter.map((route) => {
      route.layout = BasicLayout;
    });
    blankLayoutRouter.map((route) => {
      route.layout = BlankLayout;
    });

    const routes = [
      {
        path: "/admin",
        component: AdminLayout,
      },
      ...mockupRouter,
      // ...adminLayoutRouter,
      ...userLayoutRoutes,
      ...basicLayoutRouter,
      ...blankLayoutRouter,
    ];

    console.log("routes: ", routes);
    // return <renderRoutes routes={routes} extraProps={{ ...props }} />;
    // const router = renderRoutes(routes, { ...props });
    const router = renderRoutes(
      routes,
      {
        onChangeLanguage: props.onChangeLanguage,
      },
      props.logged
    );

    return router;
  }

  console.log("contantstype: ", constants[type], props);

  // if(!props?.user?.userId && (props.location.pathname !== '/login')) {
  //   props.history.push("/login")
  // }

  return (
    <ConstantsContext.Provider value={constants[type]}>
      <div>{renderRouter()}</div>
    </ConstantsContext.Provider>
  );
};

export default withRouter(
  connect(
    ({ auth }) => ({
      init: auth.init,
      user: auth.user,
      orgType: auth.user.orgType,
      logged: auth.logged,
    }),
    (dispatch) => ({
      AuthActions: bindActionCreators(authActions, dispatch),
    })
  )(RouterConfig)
);
