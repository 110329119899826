import React, { useState, useEffect } from "react";
import AudioPlayer from "components/Audio/AudioPlayer";
import BodyMovin from "components/BodyMovin/BodyMovin";
import charactorAnimation from "./animations/game-character.json";
import speechAnimation from "./animations/game-speech.json";
import bgm from "./sounds/game-bgm.mp3";
import characterSound from "./sounds/game-character-sound_x3.mp3";
import speechSound from "./sounds/game-speech-sound.mp3";

import "./game-animation.scss";

const GameAnimation = () => {
  const [isPlay, setPlay] = useState(false);

  const charactorOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: charactorAnimation,
  };

  const speechOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: speechAnimation,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPlay(true);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [isPlay]);

  return (
    <div id="gameAnimation">
      <div className="img-title"></div>
      {isPlay && (
        <div className="charactor-anim-wrap">
          <BodyMovin className="speech-anim" options={speechOptions} />
          <BodyMovin className="character-anim" options={charactorOptions} />
          <AudioPlayer src={bgm} autoPlay={true} loop={false} />
          <AudioPlayer src={characterSound} autoPlay={true} />
          <AudioPlayer src={speechSound} autoPlay={true} />
        </div>
      )}
    </div>
  );
};
export default GameAnimation;
