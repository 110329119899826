import React, { Component } from "react";
import {
  Modal,
  Row,
  Typography,
  Col,
  Card,
  Tag,
  message,
  List,
  Table,
  Button,
  Icon
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { filter } from "lodash";
import { getKoreanDate, formateKoreanTime } from "../../../../utils/utils";

const { Title, Text } = Typography;

class ConfirmHomeworkPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incompleteStudentList: [],
      homeworkList: []
    };
  }

  componentDidMount() {
    const { lectureData } = this.props;

    if (lectureData && lectureData.students) {
      const _studentList = {};
      const _students = lectureData.students;
      _students.map(student => {
        _studentList[student.studentId] = {
          studentId: student.studentId,
          name: student.name,
          file: null
        };
      });
      const _homeworkItems = lectureData.homework && lectureData.homework.items;
      if (_homeworkItems && _homeworkItems.length > 0) {
        const _uploadItems = filter(_homeworkItems, function(i) {
          return i.type === "upload";
        });
        console.log(_uploadItems);
        if (_uploadItems && _uploadItems.length > 0) {
          const _studentHomeworks = _uploadItems[0].studentHomeworks;
          console.log("studentHomworks: ", _studentHomeworks);
          if (_studentHomeworks && _studentHomeworks.length > 0) {
            _studentHomeworks.map(homework => {
              const _studentId = homework.studentId;
              const _homework = {
                fileName: null,
                lastModifiedTime: null,
                downloadLink: null
              };
              if (homework.files) {
                _homework["fileName"] = homework.files.items["file"]["name"];
                _homework["lastModifiedTime"] =
                  homework.files.lastModifiedTime || homework.files.createdTime;
                _homework["downloadLink"] =
                  homework.files.items["file"]["signedUrl"];
              }

              _studentList[_studentId]["file"] = _homework;
            });

            this.setState({
              homeworkList: filter(_studentList, function(o) {
                return o.file !== null;
              }),
              incompleteStudentList: filter(_studentList, function(o) {
                return o.file === null;
              })
            });
          } else {
            this.setState({
              incompleteStudentList: filter(_studentList, function(o) {
                return o.file === null;
              })
            });
          }
        }
      }
    }
  }

  render() {
    console.log("lectureData: ", this.props.lectureData);
    console.log("homeworkList: ", this.state.homeworkList);
    console.log("incompleteStudentList: ", this.state.incompleteStudentList);
    return (
      <Modal {...this.props}>
        {this.state.incompleteStudentList.length > 0 && (
          <Row type="flex" justify="space-between" style={{ marginBottom: 24 }}>
            <Col span={16}>
              <Title level={4}>과제 미제출 학생 목록</Title>
              {this.state.incompleteStudentList.map((s, i) => {
                return (
                  <Text
                    key={"incomplete-student-" + i}
                    style={{ marginRight: 8 }}
                  >
                    {s.name}
                  </Text>
                );
              })}
            </Col>
            <Col>
              {/* <Button type="primary">파일 전체 다운로드</Button> */}
            </Col>
          </Row>
        )}
        <Table
          columns={[
            {
              title: "이름",
              dataIndex: "name",
              key: "name",
              render: text => <span>{text}</span>
            },
            {
              title: "파일이름",
              dataIndex: "file.fileName",
              key: "fileName",
              render: text => <span>{text}</span>
            },
            {
              title: "등록시간",
              dataIndex: "file.lastModifiedTime",
              key: "lastModifiedTime",
              render: text => (
                <span>{`${getKoreanDate(text)} ${formateKoreanTime(
                  text,
                  true
                )} 등록`}</span>
              )
            },
            {
              title: "다운로드",
              dataIndex: "file.downloadLink",
              key: "downloadLink",
              render: link => (
                <a href={link} target="_blank">
                  <Icon type="download" style={{ fontSize: 24 }} />
                </a>
              )
            }
          ]}
          dataSource={this.state.homeworkList}
          rowKey="studentId"
        />
      </Modal>
    );
  }
}

export default ConfirmHomeworkPopup;
