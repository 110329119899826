import React from 'react'
import { useAudio } from 'react-use';

const AudioPlayer = (props) => {
  const [audio, state, controls, ref] = useAudio({
    ...props
  });
  
  return (
    <div>{audio}</div>
  )
}
export default AudioPlayer