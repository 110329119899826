import React, { Component } from "react";
import { isMobileOnly } from "react-device-detect";
// import '../css/Quiz.scss';

class Image extends Component {
  state = {
    animating: false,
    timeout: null,
    frame: 0
  };

  startAnimation = () => {
    const { frames } = this.props;
    const { timeout } = this.state;
    if (timeout != null) {
      return;
    }

    if (frames == null || frames.length === 0) {
      return;
    }

    this.setState({
      animating: true,
      timeout: this.requestNextFrame()
    });
  };

  requestNextFrame = () => {
    const { frames } = this.props;
    const { frame } = this.state;

    const nextFrame = frame + 1 >= frames.length ? 0 : frame + 1;
    const { millis } = frames[frame];

    return setTimeout(() => {
      this.setState({
        frame: nextFrame,
        timeout: this.requestNextFrame()
      });
    }, millis);
  };

  stopAnimation = () => {
    const { timeout } = this.state;
    if (timeout == null) {
      return;
    }

    clearTimeout(timeout);

    this.setState({
      animating: false,
      timeout: null,
      frame: 0
    });
  };

  render() {
    const {
      image,
      imageMobile,
      texts,
      frames,
      play,
      isMobileSize
    } = this.props;
    const { animating, timeout, frame } = this.state;

    if (image == null) {
      return null;
    }

    return (
      <div
        className="imageContainer"
        // onMouseOver={this.startAnimation} onMouseLeave={this.stopAnimation}
      >
        {!animating ? (
          isMobileSize && imageMobile ? (
            <img src={imageMobile.signedUrl} alt={imageMobile.name} />
          ) : (
            <img src={image.signedUrl} alt={image.name} />
          )
        ) : (
          <img src={frames[frame].image.signedUrl} alt={image.name} />
        )}
        {!animating && texts != null
          ? texts.map((t, i) => {
              const style = {
                left: t.x + "%",
                top: t.y + "%"
              };

              return (
                <div
                  key={image.contentId + "_txt_" + i}
                  className="textContainer"
                  style={style}
                >
                  <span>{t.text}</span>
                </div>
              );
            })
          : null}
      </div>
    );
  }
}

export default Image;
