export default {
  OPENED: {
    guide: "attendance",
    student: "standby",
    preview: "attendance"
  },
  ATTENDANCE: {
    guide: "attendance",
    student: "pause",
    preview: "attendance"
  },
  QUETURE: {
    guide: "queture",
    student: "pause",
    preview: "queture"
  },
  SUMMARY: {
    guide: "summary",
    student: "summary",
    preview: "summary"
  },
  GROUP: {
    guide: "group",
    student: "group",
    preview: "group"
  },
  QUIZ: {
    guide: "ranking",
    student: "quiz",
    preview: "ranking"
  },
  GAME: {
    guide: "game",
    student: "game",
    preview: "game"
  },
  ACTIVITY: {
    guide: "activity",
    student: "pause",
    preview: "activity"
  },
  MVP: {
    guide: "mvp",
    student: "pause",
    preview: "mvp"
  },
  CLOSED: {
    guide: "sendmsg",
    student: "completed",
    preview: "completed"
  },
  CHALLENGE: {
    guide: "challenge",
    student: "challenge",
    preview: "challenge"
  },
  ENDQUIZ: {
    guide: "endQuiz",
    student: "endQuiz",
    preview: "endQuiz"
  },
  STORYTELLING: {
    guide: "storytelling",
    student: "storytelling",
    preview: "storytelling",
  },
  PICTUREQUIZ: {
    guide: "pictureQuiz",
    student: "pictureQuiz",
    preview: "pictureQuiz",
  },
  QUEBONSONG: {
    guide: "quebonsong",
    student: "quebonsong",
    preview: "quebonsong"
  }
};
