import React, { Component } from "react";
import classNames from "classnames";
import Toast from "components/Toast/Toast";
import { FormattedMessage } from "react-intl";
import "./style/_quiztoolbar.scss";
import Alert from "components/Popup/Alert";
import Popup from "components/Popup/Popup";


const NEED_CONFIRM_QUIZ_TYPES = ["fill_blank", "drag_text", "select_text", "pairing"];
const QUIZ_CONFIRM_MESSAGE = {
  fill_blank: "아직 채우지 않은 빈칸이 있어요!",
  drag_text: "아직 채우지 않은 빈칸이 있어요!",
  select_text: "아직 채우지 않은 빈칸이 있어요!",
  pairing: "아직 짝을 짓지 않은 보기가 있어요!",
}
class QuizToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      solving: false,
      showConfirmPopup: null,
    };
  }

  componentDidUpdate(nextProps) {
    if (!this.props.solved && nextProps.solved) {
      this.setState({
        solving: false,
      });
    }
  }

  handleChangeStep = (i) => {
    console.log(
      "handle change step: ",
      i,
      this.props.solved,
      this.props.solvedResult
    );
    if (this.props.solved && this.props.solvedResult) {
      console.log("on step: 1");
      this.props.onStep(i, this.props.solvedResult);
    } else {
      console.log("on step: 2");
      this.props.onStep(i);
    }
  };
  render() {
    const {
      currentIndex,
      expectedCount,
      status,
      data,
      quizType,
      step,
      quizStep,
      solved,
      solvedResult,
      correct,
      onNext,
      onPrev,
      onStep,
      isHomework,
      solvedQuizData,
      onFinish,
      answers,
      onSolve,
      onAppendResult,
      readOnly,
    } = this.props;

    let statusList = [];
    if (status) {
      console.log(status);
      statusList = statusList.concat(
        status.map((s, i) => {
          const key = data.quizId + "_ox_" + i;
          const statusClass = classNames("item", {
            "none-event": i >= status.length || isHomework,
            // "is-true": s === true && ((step && quizStep !== i) || !step),
            // "is-false": s === false && ((step && quizStep !== i) || !step),
            // "is-true-bg": s === true && (step && quizStep === i),
            // "is-false-bg": s === false && (step && quizStep === i),
            "is-true": s === true,
            "is-false": s === false,
            "is-this none-event": i === currentIndex,
          });
          return (
            <li
              key={key}
              className={statusClass}
              onClick={() => {
                if (isHomework) {
                  return;
                }
                this.handleChangeStep(i);
              }}
            >
              {i + 1}
            </li>
          );
        })
      );
    }
    if (solved && correct !== null && !step) {
      if (isHomework) {
        console.log("isHomework");
        statusList.splice(
          currentIndex,
          1,
          <li
            key={data.quizId + "_curr"}
            className={
              "is-this none-event " + (correct ? "is-true" : "is-false")
            }
          >
            {currentIndex + 1}
          </li>
        );
      } else {
        statusList.push(
          <li
            key={data.quizId + "_curr"}
            className={
              "is-this none-event " + (correct ? "is-true" : "is-false")
            }
          >
            {statusList.length + 1}
          </li>
        );
      }
    }

    if (expectedCount != null && expectedCount > statusList.length) {
      let initLength = statusList.length;
      for (var i = initLength; i < expectedCount; i++) {
        statusList.push(
          <li
            key={data.quizId + "_exp_" + i}
            className={classNames("none-event", {
              "is-this": !solved && initLength === i,
            })}
          >
            {i + 1}
          </li>
        );
      }
    }

    let contentStyle = null;

    if (quizType === "admin") {
      contentStyle = {
        position: "absolute",
      };
    }
    console.log("toolbar - correct: ", correct);
    console.log("toolbar - sovled: ", solved);
    if (onFinish) {
      console.log("isLast!!");
    }
    return (
      <div className="quiz-toolbar" style={contentStyle}>
        {/* 이전 문제가 있다면 is-visible */}
        {quizType !== "admin" && currentIndex > 0 && (
          <div
            className="back"
            onClick={() => {
              this.handleChangeStep(currentIndex - 1);
              // if (step) {
              // } else {
              //   onAppendResult(solvedResult);
              //   onPrev(solvedResult);
              // }
            }}
          >
            <span>
              <FormattedMessage id="classroom.prev-question" />
            </span>
          </div>
        )}
        <div className="quiz-number">
          {/*
          li에
          현재 보고있는 문제 is-this
          현재 보고있는 문제가 맞았으면 is-this유지한체 is-true,
          현재 보고있는 문제가 틀렸으면 is-this유지한체 is-false,
          지나간 문제가 맞은 문제라면 is-true,
          지나간 문제가 틀린 문제라면 is-false,
         */}
          <ul>{statusList}</ul>
        </div>
        {/*
        채점하기 is-mark
        (정답이 맞앚을 시가 기본)
        보고있는 문제에 다음 문제가 있을 시 : is-right
        그 다음 문제가 오답 일 시 : is-false
        마지막 문제에 is-end 추가
       */}
        {/* {onFinish && <span onClick={onFinish}>완료</span>} */}
        {(
          isHomework
            ? currentIndex === status.length - 1 &&
              solvedQuizData.length === status.length
            : currentIndex === status.length - 1 &&
              status.length === statusList.length
        ) ? null : (
          <div
            className={classNames("next", {
              "is-mark": !solved && answers && answers.length > 0,
              "is-right": solved && correct,
              "is-false": solved && !correct,
              "is-end": onFinish,
              none: (solved && quizType === "admin") || (readOnly && !step),
              // "is-right is-end": solved && correct && answers.length == 0,
              // "is-false is-end": solved && !correct && answers.length == 0
            })}
          >
            {solved ? (
              onFinish ? (
                <span
                  onClick={() => {
                    onAppendResult && onAppendResult(solvedResult, true);
                    // onFinish();
                  }}
                >
                  <FormattedMessage id="classroom.finish" />
                </span>
              ) : (
                <span
                  onClick={() => {
                    if (step) {
                      this.handleChangeStep(currentIndex + 1);
                    } else {
                      onAppendResult && onAppendResult(solvedResult, false);
                      // onNext(solvedResult);
                    }
                  }}
                >
                  <FormattedMessage id="classroom.next-question" />
                </span>
              )
            ) : (
              answers &&
              answers.length > 0 && (
                <span
                  onClick={() => {
                    console.log("quiz toolbar - props: ", this.props);
                    const _quizType = this.props?.data?.quiz?.type;
                    // 채점하기 컨펌 팝업
                    if (NEED_CONFIRM_QUIZ_TYPES.includes(_quizType)) {
                      let answeredCount = 0;
                      answers.forEach((a) => {
                        if (a || a === 0) {
                          answeredCount++;
                        }
                      });
                      const IS_FULLY_ANSWERED =
                        answeredCount === answers.length;

                      if (!IS_FULLY_ANSWERED) {
                        this.setState({
                          showConfirmPopup: QUIZ_CONFIRM_MESSAGE[_quizType],
                        });
                        return;
                      }
                    }
                    
                    if (answers && answers.length === 0) {
                      Toast.error("문제를 풀어주세요.");
                      return;
                    }
                    if (!this.state.solved && !this.state.solving) {
                      this.setState(
                        {
                          solving: true,
                        },
                        onSolve
                      );
                    }
                  }}
                >
                  {this.state.solving ? (
                    <FormattedMessage id="classroom.marking" />
                  ) : (
                    <FormattedMessage id="classroom.mark" />
                  )}
                </span>
              )
            )}
          </div>
        )}
        {this.state.showConfirmPopup !== null && (
          <Popup
            isOpen={this.state.showConfirmPopup !== null}
            className="confirm-answer-popup"
          >
            <div className="popup-content-wrap">
              <div
                className="popup-closebtn"
                onClick={() =>
                  this.setState({
                    showConfirmPopup: null,
                  })
                }
              ></div>
              <div className={classNames("img", {})}></div>
              <div className="popup-title">
                {/* <strong>{"문제를 끝까지 풀지 않았어요."}</strong> */}
                <strong>{this.state.showConfirmPopup}</strong>
              </div>
              {/* <p className="desc">{this.state.showConfirmPopup}</p> */}
            </div>
            <div className="btn-wrap">
              <button
                type="button"
                className="btn-cancel"
                onClick={() =>
                  this.setState({
                    showConfirmPopup: null,
                  })
                }
              >
                {"계속풀래요"}
              </button>
              <button
                type="button"
                className="btn-confirm"
                onClick={() =>
                  this.setState({
                    showConfirmPopup: null,
                    solving: true,
                  }, () => {
                    onSolve();
                  })
                }
              >
                {"채점할래요"}
              </button>
            </div>
          </Popup>
        )}
      </div>
    );
  }
}

export default QuizToolbar;
