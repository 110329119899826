import Home from "../pages/home";
import Classroom from "../pages/classMode";
import TestQuizPage from "../pages/test/quiz";
import TestQueturePage from "../pages/test/queture";
import LoginPage from "../pages/login";

export default [
  {
    path: "/",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: Home
  },
  {
    path: "/login",
    exact: true,
    requiresAuth: false,
    component: LoginPage
  },
  {
    path: "/admin-login",
    exact: true,
    requiresAuth: false,
    component: LoginPage
  },
  {
    path: "/classroom/:classroomId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: Classroom
  },
  {
    path: "/test/quiz/:quizId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: TestQuizPage
  },
  {
    path: "/test/queture",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: TestQueturePage
  }
];
