import React from 'react'
import PictureQuizCharacterAnimation from './PictureQuizCharacterAnimation/PictureQuizCharacterAnimation'
import "./picture-quiz-animation.scss"

const PictureQuizAnimation = () => {
  return (
    <div id="pirctureQuizAnimation">
      <div className="img-title"></div>
      <PictureQuizCharacterAnimation />
    </div>
  )
}
export default PictureQuizAnimation