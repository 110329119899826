import React, { Component } from "react";
import { Modal, Button } from "antd";

class SetInteractionPopup extends Component {
  render() {
    return (
      <Modal {...this.props}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button type="primary" size="large" onClick={() => this.props.onOk()}>
            수업 이어서 하기
          </Button>
        </div>
      </Modal>
    );
  }
}

export default SetInteractionPopup;
