import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb, Typography } from "antd";
import styles from "./styles.module.scss";
import RightContent from "./RightContent";
import StudentHeader from "../StudentHeader";
import { Link, withRouter } from "react-router-dom";
import { ConstantsContext } from "utils/ConstantsContext";
const { Header, Content, Footer } = Layout;

const GlobalHeader = (props) => {
  const { user, userPermission, location } = props;
  const [current, setCurrent] = useState("classroom");
  const context = React.useContext(ConstantsContext);

  useEffect(() => {
    console.log("location: ", location.pathname);
    if (location.pathname.startsWith("/admin/class")) {
      setCurrent("classroom");
    } else if (location.pathname.startsWith("/admin/student")) {
      setCurrent("student");
    } else if (location.pathname.startsWith("/admin/guide")) {
      setCurrent("guide");
    } else if (location.pathname.startsWith("/admin/newsletter")) {
      setCurrent("newsletter");
    } else if (location.pathname.startsWith("/admin/board")) {
      setCurrent("board");
    } else if (location.pathname.startsWith("/admin/exams")) {
      setCurrent("exams");
    } else if (location.pathname.startsWith("/admin/calendar")) {
      setCurrent("calendar");
    } else {
      setCurrent("classroom");
    }
    return () => {};
  }, [location]);

  console.log("userPermission: ", userPermission);
  return (
    <>
      {user && user.type === "student" ? (
        <StudentHeader />
      ) : (
        <Header>
          <div style={{ display: "flex" }}>
            <span className={styles.logo} onClick={() => {}} >
              <Link to="/admin/class">{context.type === "school" ? "깨봉 아카데미" : "깨봉 캠퍼스"}</Link>
            </span>
            <span className={styles.subLogo}>{user.orgName}</span>

            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={["/admin/class"]}
              selectedKeys={[current]}
              style={{ lineHeight: "64px", flex: "1" }}
              onClick={(e) => {
                setCurrent({
                  current: e.key,
                });
              }}
            >
              <Menu.Item key="classroom">
                <Link to="/admin/class">{context.className}관리</Link>
              </Menu.Item>
              <Menu.Item key="student">
                <Link to="/admin/student/list">학생관리</Link>
              </Menu.Item>
              {userPermission === "master" && (
                <Menu.Item key="guide">
                  <Link to="/admin/guide/list">{context.guideName}관리</Link>
                </Menu.Item>
              )}
              {context.type === "campus" && (
                <Menu.Item key="exams">
                  <Link to="/admin/exams">테스트</Link>
                </Menu.Item>
              )}
              <Menu.Item key="newsletter">
                <Link to="/admin/newsletter">뉴스레터</Link>
              </Menu.Item>
              <Menu.Item key="calendar">
                <Link to="/admin/calendar">캘린더</Link>
              </Menu.Item>
              {/*<Menu.Item key="enquiry">*/}
              {/*  <Link to="/admin/enquiry">문의게시판</Link>*/}
              {/*</Menu.Item>*/}
              {context.type === "school" && (
                <Menu.Item key="board">
                  <Link to="/admin/board">게시판</Link>
                </Menu.Item>
              )}
            </Menu>
            <RightContent user={user} />
          </div>
        </Header>
      )}
    </>
  );
};

export default withRouter(GlobalHeader);
