import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as guideActions from "store/modules/guide";
import * as classroomActions from "store/modules/classroom";
import {
  Skeleton,
  PageHeader,
  Typography,
  Button,
  Icon,
  Row,
  Radio,
  Input,
  Select,
  message
} from "antd";
import AccountApis from "../../../../utils/accountApis";
import ButtonGroup from "antd/lib/button/button-group";
import GuideList from "../../components/GuideList";
import EditableGuideList from "../../components/GuidePermissionList";
import { ConstantsContext } from "utils/ConstantsContext";

class GuidListPage extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      tab: "info"
    };
  }

  componentDidMount() {
    const { pending, user, GuideActions } = this.props;
    const { isLoading } = this.state;
    console.log("mount guide list: ", user);

    if (user && user.orgId) {
      GuideActions.getGuideList(user.orgId);
    }
  }

  componentDidUpdate(nextProps) {
    const { pending, user, ClassroomActions, GuideActions } = this.props;
    if (this.props.user && this.props.user.type !== nextProps.user.type) {
      ClassroomActions.getClassroomList(user.orgId);
      GuideActions.getGuideList(user.orgId);
    }
  }

  handleTabChange = e => {
    this.setState({ tab: e.target.value });
  };

  handleGuidePermission = (guideId, data) => {
    AccountApis.updateGuidePermissions(
      this.props.user.orgId,
      guideId,
      data
    ).then(res => {
      this.props.GuideActions.getGuideList(this.props.user.orgId);
    });
  };

  render() {
    const { pending, user, userPermission, guideList } = this.props;
    const { tab } = this.state;

    console.log("guide list: ", guideList);

    if (userPermission !== "master") {
      this.props.history.push("/");
    }

    return (
      <Skeleton loading={pending || this.state.isLoading} active>
        <PageHeader
          title={
            <Typography.Title level={2} style={{ marginBottom: 0 }}>
              {`${this.context.guideName} 관리`}
            </Typography.Title>
          }
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => this.props.history.push("/admin/guide/add")}
            >
              {`${this.context.guideName} 등록하기`}
              <Icon type="plus-circle" />
            </Button>
          ]}
        />
        <div className="admin-page-content">
          <Row type="flex" justify="space-between">
            <Typography.Title
              level={4}
            >{`전체 ${this.context.guideName} 목록 ${guideList.length}명`}</Typography.Title>
            <Radio.Group value={tab} onChange={this.handleTabChange}>
              <Radio.Button value="info">기본정보</Radio.Button>
              <Radio.Button value="permission">권한설정</Radio.Button>
            </Radio.Group>
          </Row>
          {tab === "info" ? (
            <GuideList guideList={guideList} />
          ) : (
            <EditableGuideList
              guideList={guideList}
              onUpdateGuidePermission={this.handleGuidePermission}
            />
          )}
        </div>
      </Skeleton>
    );
  }
}

export default connect(
  ({ auth, guide, classroom }) => ({
    pending: auth.pending || guide.pending || classroom.pending,
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    userPermission: auth.user.type === "org" ? "master" : auth.user.permission,
    guideList: guide.guideList,
    classroomList: classroom.classroomList
  }),
  dispatch => ({
    GuideActions: bindActionCreators(guideActions, dispatch),
    ClassroomActions: bindActionCreators(classroomActions, dispatch)
  })
)(GuidListPage);
