import { combineReducers } from "redux";
import auth from "./auth";
import classroom from "./classroom";
import course from "./course";
import courseSimple from "./courseSimple";
import guide from "./guide";
import student from "./student";
import popup from "./popup";
import scroll from "./scroll";
import header from "./header";
import game from "./game";
import hometraining from "./hometraining";

export default combineReducers({
  auth,
  classroom,
  course,
  courseSimple,
  guide,
  student,
  popup,
  scroll,
  header,
  game,
  hometraining
});
