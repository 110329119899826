import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './player-animation.scss';

const PlayAnimation = (props) => {
  const { playing } = props;
  const [timer, setTimer] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (timer != null) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        setTimer(null)
        if (playing) {
          setCount(1);
        }
      }, 500)
    );
  }, [playing])
  return (
    <div className={classNames("play-state-wrap", {
      'show fade-out': timer && count > 0
    })}>
      <div className={classNames("play-btn", {
        'pause': !playing,
      })}>
      </div>
    </div>
  )
}

export default PlayAnimation;
