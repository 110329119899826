import React, { Component } from "react";
import { connect } from "react-redux";
import NoticeApis from "../../../../utils/noticeApis";
import {
  Skeleton,
  PageHeader,
  Typography,
  Row,
  Col,
  message,
  Divider,
  Button,
  Popconfirm
} from "antd";
import { withRouter } from "react-router-dom";
import { getKoreanDate } from "../../../../utils/utils";

const { Title, Text } = Typography;
class NoticeDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: {
        title: null,
        text: null,
        writer: null
      }
    };
  }

  componentDidMount() {
    const { noticeId } = this.props.match.params;

    console.log("notice id: ", noticeId);
    NoticeApis.getNotice(noticeId)
      .then(res => {
        console.log("notice detail: ", res);
        this.setState({
          data: res
        });
      })
      .catch(e => {
        message.warning("");
      });
  }

  handleDelete = noticeId => {
    this.setState({
      isLoading: true
    });

    NoticeApis.deleteNotice(noticeId)
      .then(res => {
        message.success("삭제 완료");
        this.props.history.push("/admin/notice");
      })
      .catch(e => {
        message.warning("삭제 실패");
      });
  };

  render() {
    const { data } = this.state;
    const { user, userPermission } = this.props;

    return (
      <Skeleton loading={false} active>
        <PageHeader title={<Title level={2}>공지사항</Title>} />
        <div className="admin-page-content">
          <Title level={4}>{data.title}</Title>
          <div>
            <Text>{`작성자: ${data.writer && data.writer.name}`}</Text>
            <Divider type="vertical" />
            <Text>{`등록일: ${getKoreanDate(data.createdTime, true)}`}</Text>
          </div>
          <Divider />
          <p dangerouslySetInnerHTML={{ __html: data["text"] }}></p>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", margin: 24 }}
        >
          {(userPermission === "master" || userPermission &&  userPermission.notice) && (
            <>
              <Popconfirm
                title={`정말로 삭제 하시겠습니까?`}
                onConfirm={() => this.handleDelete(data.noticeId)}
                onCancel={() => message.error("삭제 취소")}
                okText="삭제"
                placement="topRight"
                cancelText="취소"
              >
                <Button type="danger" size="large" style={{ marginRight: 16 }}>
                  삭제하기
                </Button>
              </Popconfirm>
              <Button
                type="primary"
                size="large"
                style={{ marginRight: 16 }}
                onClick={() => {
                  this.props.history.push(
                    `/admin/notice/edit/${data.noticeId}`
                  );
                }}
              >
                수정하기
              </Button>
            </>
          )}
          <Button
            size="large"
            onClick={() => {
              this.props.history.push(`/admin/notice`);
            }}
          >
            목록으로
          </Button>
        </div>
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      pending: auth.pending,
      auth: auth,
      user: auth.user,
      logged: auth.logged,
      userPermission: auth.user.type === "org" ? "master" : auth.user.permission
    }),
    null
  )(NoticeDetailPage)
);
