import Phaser from 'phaser';

export class objects extends Phaser.GameObjects.Sprite {
	constructor(scene, x, y) {
		super(scene, x, y);
		scene.physics.add.existing(this);
		//this.body.setCollideWorldBounds();
	}

	preUpdate(time, delta) {
		super.preUpdate(time, delta);
		if (this.destination != null) {
			this.scene.physics.moveToObject(this, this.destination, this.speed);

			if (this.getDistanceBetweenDestination() < 5) {

				this.arrivedAtDestionation();
				this.isMoving = false;
				if (this.arriveCallback != null) {
					this.arriveCallback(this, this.destination);
					this.arriveCallback = null;
				}

				this.destination = null;
			}
		}
	}

	getDistanceBetweenDestination() {
		return Phaser.Math.Distance.Between(this.x, this.y, this.destination.x, this.destination.y)
	}

	setDestination(object, speed = 100, arriveCallback) {
		this.arriveCallback = arriveCallback;
		this.speed = speed;
		this.destination = object;
		this.anims.setTimeScale(1);
		this.isMoving = true;
	}

	arrivedAtDestionation() {
		this.body.reset(this.destination.x, this.destination.y);
		this.anims.stop();
	}
}

export class button extends objects {
	constructor(scene, x, y,type) {
		super(scene, x, y,type);
		
		if(type==='R')
		{
			this.setTexture('R00').setOrigin(0);
			this.type = 'R';
		}
		else if(type==='L'){
			this.setTexture('L00').setOrigin(0);
			this.type = 'L';
		}
		else{
			this.setTexture('E00').setOrigin(0);
			this.type = 'E';
		}

		this.currentPosition = { x: x, y: y };

		this.setInteractive({ useHandCursor: true});
	}
}