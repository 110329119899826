import React from "react";
import { FormattedMessage } from "react-intl";
import "./_etcpage.scss";

const PauseClass = () => {
  return (
    <div className="classpause-layout">
      <div className="big-title u-color-red">
        {/* 지금은 수업중 입니다. */}
        <FormattedMessage id="classroom.lectureTime" />
      </div>
      {/* <div className="page-content-ment">TV 화면에 집중하세요.</div> */}
    </div>
  );
};

export default PauseClass;
