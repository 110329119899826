import React, { Component } from "react";
import classNames from "classnames";
import InputPad from "./InputPad";
import { isMobile } from "react-device-detect";
const isMobileSize = window.innerWidth <= 768;

// let inputWidth = 44;
class Blank extends Component {
  state = {
    inputWidth: 44,
    value: "",
  };

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
    const { showReallyTrue, index, solvedData } = this.props;
    if (showReallyTrue) {
      const value = solvedData["correctAnswers"][index];
      this.setState({
        inputWidth: (value.length + 2) * 12,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showReallyTrue, index, solvedData } = this.props;
    if (prevProps.showReallyTrue !== showReallyTrue && showReallyTrue) {
      const value = solvedData["correctAnswers"][index];
      this.setState({
        inputWidth: (value.length + 2) * 12,
      });
    }
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  handleEdit = (e) => {
    const { value } = e.target;
    const { onChange } = this.props;
    if (value && value.length > 2) {
      this.setState({
        value: value,
      });
    } else {
      this.setState({
        value: value,
      });
    }
    onChange(value);
  };

  // handleInput = (value) => {
  //   console.log('input value: ', value);
  //   const { onChange } = this.props;
  //   console.log(value);
  //   if (value && value.length > 2) {
  //     this.setState({
  //       inputWidth: (value.length + 2) * 12
  //     })
  //   }
  //   onChange(value);
  //   console.log(value);
  // }

  render() {
    const {
      value,
      blankWidth,
      selectedIndex,
      index,
      placeholder,
      onChange,
      solvedData,
      showReallyTrue,
      onClick,
      onBlur,
    } = this.props;
    const { inputWidth } = this.state;
    return (
      // is-chk, is-true, is-false
      // fill-blank 추가
      // 정답확인 후에는 input 에 none-event추가
      <>
        <div className="t-answer-ts-input">
          <div
            className={classNames("t-answer-ts-input-wrap", {
              "is-chk": selectedIndex === index,
              "is-true":
                !showReallyTrue &&
                solvedData.solved &&
                solvedData.corrects[index],
              "is-false":
                !showReallyTrue &&
                solvedData.solved &&
                !solvedData.corrects[index],
              "is-reallytrue": showReallyTrue && solvedData.solved,
              "none-event": solvedData && solvedData.solved,
            })}
          >
            <input
              type="text"
              className="blank"
              style={{
                width: (showReallyTrue ? inputWidth : blankWidth) + "px",
              }}
              value={
                showReallyTrue
                  ? solvedData["correctAnswers"][index]
                  : this.state.value
              }
              // placeholder={placeholder}
              // onClick={onClick}
              onBlur={onBlur}
              // onInput={() => this.handleInput(this.value)}
              onChange={(e) => this.handleEdit(e)}
              // maxLength="10"
              // readOnly={isMobileSize}
              // pattern=".*\S.*"
              placeholder="　"
              required
            />
          </div>
        </div>
        {
          // 임시로 숨기
          // selectedIndex === index && !isMobile &&
          // <InputPad prevValue={value} onInputPadChange={onChange} />
        }
      </>
    );
  }
}

export default Blank;
