import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import * as studentActions from "store/modules/student";
import {
  PageHeader,
  Typography,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Button,
  message,
  Modal,
} from "antd";
import moment from "moment";
import Axios from "../../../../utils/request";
import SearchAddressPopup from "../../components/Popup/SearchAddressPopup";
import { parseAxiosError } from "../../../../utils/APIUtils";
import StudentProfile from "../../components/StudentProfile";
import { ConstantsContext } from "utils/ConstantsContext";

const { Title } = Typography;
class StudentAddPage extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      showAddressPopup: false,
      profileImageId: null,
      isLoading: false,
      confirmPopup: null,
    };
  }

  componentDidMount() {
    const { user, ClassroomActions } = this.props;

    if (user) {
      ClassroomActions.getClassroomList(user.orgId);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.props.form);
    const { profileImageId } = this.state;
    this.props.form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      const birth = moment(values.birthday).format("YYYY-MM-DD");
      const day = moment(values.birthday).date();
      const month = moment(values.birthday).month() + 1;
      console.log(birth);
      console.log(month);
      console.log(day);
      if (!err) {
        const data = {
          account: values.account,
          classroomId: values.classroomId,
          name: values.name,
          grade: values.grade,
          mobile: values.mobile,
          birthday: null,
          parentMobile: values.parentMobile,
          parentEmail: values.parentEmail,
          school: values.school,
          profileImageId: profileImageId,
          description: values.description,
          hideLecture: values.hideLecture,
          address: {
            address1: values.address1,
            address2: values.address2,
            zipCode: values.zipcode,
          },
          orgId: this.props.user.orgId,
        };

        if (values.birthday) {
          data["birthday"] = {
            day: day < 10 ? day : "0" + day,
            month: month < 10 ? month : "0" + month,
            year: moment(values.birthday).year(),
          };
        } else {
          data["password"] = "1234";
        }
        console.log("post data: ", data);
        this.setState(
          {
            isLoading: true,
          },
          () => {
            this.createStudent(data);
          }
        );
      }
    });
  };

  createStudent = (data) => {
    const { user, form } = this.props;

    Axios.post(`/academy/v2/accounts/${user.orgId}/students`, data)
      .then((res) => {
        message.success("학생 등록 완료", res);
        this.setState({
          isLoading: false,
          confirmPopup: res,
        });
        form.resetFields();
        // this.props.history.push(`/admin/student/list`);
      })
      .catch((e) => {
        console.log(e);
        const error = parseAxiosError(e);
        message.error("학생 등록 실패");
        message.error(`${error.code}: ${error.message}`);
        this.setState({
          isLoading: false,
        });
      });
  };

  handleUploadProfile = (contentId) => {
    if (contentId) {
      this.setState({
        profileImageId: contentId,
      });
    }
  };

  render() {
    const { classroomList } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <>
        <PageHeader title={<Title level={2}>학생 등록하기</Title>} />
        <div className="admin-page-content">
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col span={6}>
                {/* <Form.Item label="">
                  {getFieldDecorator("profileImage")(
                    <Upload
                      name="profile"
                      customRequest={e => console.log("customRequest: ", e)}
                    >
                      <Button>학생 사진 등록하기</Button>
                    </Upload>
                  )}
                </Form.Item> */}
                <StudentProfile onUploadProfile={this.handleUploadProfile} />
              </Col>
              <Col span={18}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item label="이름">
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: "학생 이름을 입력해주세요.",
                          },
                        ],
                      })(<Input placeholder="학생 이름을 입력해주세요." />)}
                    </Form.Item>
                    <Form.Item label="아이디">
                      {getFieldDecorator("account", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            required: true,
                            message: "아이디를 입력해주세요.",
                          },
                        ],
                      })(<Input placeholder="아이디를 입력해주세요." />)}
                    </Form.Item>
                    <Form.Item label="학교명">
                      {getFieldDecorator("school", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "학교를 입력해주세요."
                          // }
                        ],
                      })(<Input placeholder="학교를 입력해주세요." />)}
                    </Form.Item>
                    <Form.Item label="학생 연락처">
                      {getFieldDecorator("mobile", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "학생 연락처를 입력해주세요."
                          // }
                        ],
                      })(
                        <Input
                          placeholder="학생 연락처를 입력해주세요."
                          onChange={function (e) {
                            e.target.value = e.target.value.replaceAll(" ", "");
                          }}
                        />
                      )}
                    </Form.Item>
                    {this.context.type === "campus" && (
                      <Form.Item label="미수강수업 조회제한">
                        {getFieldDecorator("hideLecture", {
                          rules: [],
                        })(
                          <Select placeholder="옵션을 선택해주세요.">
                            <Select.Option value="">제한 없음</Select.Option>
                            <Select.Option value="AB">
                              A,B반 수업 조회제한
                            </Select.Option>
                            <Select.Option value="ABC">
                              A,B,C반 수업 조회제한
                            </Select.Option>
                            <Select.Option value="ABCD">
                              A,B,C,D반 수업 조회제한
                            </Select.Option>
                          </Select>
                        )}
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={12}>
                    <Form.Item label={`${this.context.className} 이름`}>
                      {getFieldDecorator("classroomId", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "클래스를 선택해주세요."
                          // }
                        ],
                      })(
                        <Select
                          placeholder={`${this.context.className}를 선택해주세요.`}
                        >
                          {classroomList.map((c) => (
                            <Select.Option
                              key={c.classroomId}
                              value={c.classroomId}
                            >
                              {c.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item label="생년월일">
                      {getFieldDecorator("birthday", {
                        rules: [
                          {
                            required: this.context.type === "campus",
                            message: "생년월일을 선택해주세요.",
                          },
                        ],
                      })(
                        <DatePicker
                          placeholder="생년월일을 선택해주세요."
                          format="YYYY-MM-DD"
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="학년">
                      {getFieldDecorator("grade", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "학년을 선택해주세요."
                          // }
                        ],
                      })(
                        <Select placeholder="학년을 선택해주세요.">
                          <Select.Option value="1">1</Select.Option>
                          <Select.Option value="2">2</Select.Option>
                          <Select.Option value="3">3</Select.Option>
                          <Select.Option value="4">4</Select.Option>
                          <Select.Option value="5">5</Select.Option>
                          <Select.Option value="6">6</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item label="학부모 연락처">
                      {getFieldDecorator("parentMobile", {
                        rules: [
                          {
                            required: this.context.type === "campus",
                            message: "학부모 연락처를 입력해주세요.",
                          },
                        ],
                      })(
                        <Input
                          type="number"
                          placeholder="학부모 연락처를 입력해주세요."
                          onChange={function (e) {
                            e.target.value = e.target.value.replaceAll(" ", "");
                            if (e.target.value.length >= 15) {
                              e.target.value = e.target.value.slice(0, 15);
                            }
                          }}
                        />
                      )}
                    </Form.Item>

                    <Form.Item label="학부모 이메일">
                      {getFieldDecorator("parentEmail", {
                        rules: [
                          {
                            required: this.context.type === "campus",
                            message: "학부모 이메일을 입력해주세요",
                          },
                        ],
                      })(<Input placeholder="학부모 이메일을 입력해주세요." />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="메모">
                      {getFieldDecorator("description")(
                        <Input.TextArea placeholder="메모를 입력해주세요.">
                          onChange=
                          {(e) => {
                            console.log(e + "qwerasdf");
                          }}
                        </Input.TextArea>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="주소">
                      {getFieldDecorator("address1", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "주소찾기를 통해 입력해주세요."
                          // }
                        ],
                      })(
                        <Input
                          placeholder="주소찾기를 통해 입력해주세요."
                          readOnly
                          disabled
                        />
                      )}
                      <Button
                        onClick={() =>
                          this.setState({
                            showAddressPopup: true,
                          })
                        }
                      >
                        주소찾기
                      </Button>
                      <SearchAddressPopup
                        title={`주소찾기`}
                        visible={this.state.showAddressPopup === true}
                        maskClosable={false}
                        onDone={(address1, zipcode) => {
                          console.log("address1: ", address1);
                          console.log("zipcode: ", zipcode);
                          this.props.form.setFieldsValue({
                            address1: address1,
                            zipcode: zipcode,
                          });
                          this.setState({
                            showAddressPopup: false,
                          });
                        }}
                        onCancel={() => {
                          this.setState({
                            showAddressPopup: false,
                          });
                        }}
                        footer={null}
                      />
                    </Form.Item>

                    <Form.Item label="zipcode" style={{ display: "none" }}>
                      {getFieldDecorator(
                        "zipcode",
                        {}
                      )(<Input placeholder="zipcode" />)}
                    </Form.Item>
                    <Form.Item label="">
                      {getFieldDecorator("address2", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "상세주소를 입력해주세요."
                          // }
                        ],
                      })(<Input placeholder="상세주소를 입력해주세요." />)}
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.isLoading}
                      >
                        등록완료
                      </Button>
                      <Button
                        onClick={() => {
                          this.props.history.push("/admin/student/list");
                        }}
                        style={{ marginLeft: 16 }}
                      >
                        취소
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
        {
          <Modal
            title={`학습자 추가 완료`}
            visible={this.state.confirmPopup !== null}
            maskClosable={false}
            closable={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  this.setState({
                    confirmPopup: null,
                  }, () => {
                    this.props.history.push("/admin/student/list");
                  });
                }}
              >
                확인
              </Button>,
            ]}
          >
            <div>{`학습자(${this.state.confirmPopup?.account}) 추가 완료 되었습니다.`} </div>
            {/* <div>{JSON.stringify(this.state.confirmPopup)}</div> */}
          </Modal>
        }
      </>
    );
  }
}

export default connect(
  ({ auth, classroom, course }) => ({
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    classroomList: classroom.classroomList,
  }),
  (dispatch) => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
    StudentActions: bindActionCreators(studentActions, dispatch),
  })
)(Form.create()(StudentAddPage));
