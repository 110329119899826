import React, { useState, useEffect, forwardRef } from 'react';
import katex from 'katex'


const Katex = ({ math }) => {
  const [node, setRef] = useState(null)

  useEffect(() => {
    if (node) {
      katex.render(math, node, {
        throwOnError: false
      })
    }
  }, [node])
  return (
    <span ref={setRef}>
    </span>
  )
}

export default Katex;
