import { Button } from "antd";
import StandBy from "pages/classMode/etcpage/StandBy";
import React from "react";

const MvpPreview = (props) => {
  const { data, isKids, viewType, onChangeSummaryIndex } = props;
  if (isKids && viewType === "preview") {
    return <StandBy isKids={isKids} />;
  }
  return (
    <div className="mvp-preview-layout">
      <div className="mvp-preview-wrap">
        <Button
          type="primary"
          onClick={() => {
            onChangeSummaryIndex(10);
          }}
          size={"large"}
          style={{ background: "#13c2c2", borderColor: "#13c2c2" }}
        >
          MVP로 이동
        </Button>
      </div>
    </div>
  );
};
export default MvpPreview;
