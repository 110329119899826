import React, { Component } from "react";
import { Skeleton, PageHeader, Typography, Row, Col, message } from "antd";
import Editor from "../../components/Editor/Editor";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NoticeApis from "../../../../utils/noticeApis";
import NewsletterApis from "../../../../utils/newsletterApis";

const { Title } = Typography;
class NewletterAddPage extends Component {
  render() {
    return (
      <Skeleton loading={false} active>
        <PageHeader title={<Title level={2}>뉴스레터 등록하기</Title>} />
        <div className="admin-page-content">
          <Row>
            <Col>
              <Editor
                // useCategory={useCategory}
                // categories={categories}
                // useThumbnail={useThumbnail}
                // useDates={useDates}
                // board={board}
                // data={}
                type={"add"}
                orgId={this.props.orgId}
                onSaved={data => {
                  this.props.history.push("/admin/newsletter");
                }}
                onSubmit={data => {
                  NewsletterApis.addNewsletter(data).then(r => {
                    message.success("저장 성공");
                    this.props.history.push("/admin/newsletter");
                  });
                }}
                onBack={() => {
                  this.props.history.push("/admin/newsletter");
                }}
              />
            </Col>
          </Row>
        </div>
      </Skeleton>
    );
  }
}
export default withRouter(
  connect(
    ({ auth }) => ({
      pending: auth.pending,
      auth: auth,
      orgId: auth.user.orgId
    }),
    null
  )(NewletterAddPage)
);
