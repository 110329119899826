import React, { useState, useEffect } from "react";
import AudioPlayer from "components/Audio/AudioPlayer";
import BodyMovin from "components/BodyMovin/BodyMovin";
import charactorAnimation from "../animations/picture-quiz-character.json";
import speechAnimation from "../animations/picture-quiz-speech.json";
import bgm from "../sounds/picture-quiz-bgm.mp3";
import characterSound from "../sounds/picture-quiz-character-sound_x3.mp3";
import speechSound from "../sounds/picture-quiz-speech-sound.mp3";

const PictureQuizCharacterAnimation = () => {
  const [isPlay, setPlay] = useState(false);

  const charactorOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: charactorAnimation,
  };

  const speechOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: speechAnimation,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPlay(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isPlay && (
        <div className="charactor-anim-wrap">
          <BodyMovin
            className="charactor-anim"
            options={charactorOptions}
          />
          <BodyMovin className="speech-anim" options={speechOptions} />
          <AudioPlayer src={bgm} autoPlay={true} loop={false} />
          <AudioPlayer src={characterSound} autoPlay={true} />
          <AudioPlayer src={speechSound} autoPlay={true} />
        </div>
      )}
    </>
  );
};
export default PictureQuizCharacterAnimation;
