import React, { Component } from "react";
import { Quiz } from "components/Quiz";
import Axios from "../../../utils/request";
import { Skeleton } from "antd";
import QuizPublishing from "../../../components/Quiz/QuizPublishing";

export default class TestQuizPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null
    };
  }

  load = quizId => {
    this.setState({
      isLoading: true
    });

    Axios.get(`/quiz/v2/quizzes/${quizId}`).then(response => {
      this.setState({
        isLoading: false,
        data: response
      });
    });
  };

  solve = (quizId, answers, vars) => {
    return Axios.post(`/quiz/v2/quizzes/${quizId}/solve`, {
      answers: answers,
      vars: vars
    }).then(response => {
      return response;
    });
  };

  componentDidMount() {
    const { quizId } = this.props.match.params;

    this.load(quizId);
  }

  render() {
    const { isLoading, data } = this.state;
    console.log("quiz: ", data);
    return (
      <Skeleton loading={isLoading} active>
        {/*
         */}
        {data && (
          <Quiz
            data={data}
            solver={this.solve}
            status={[]}
            quizType={"hometraning"}
            isHomework={true}
          />
        )}
        {/* <QuizPublishing /> */}
      </Skeleton>
    );
  }
}
