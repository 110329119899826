import React, { Component } from "react";
import classNames from "classnames";
import Popup from "../../../components/Popup/Popup";
import {
  Row,
  Col,
  Typography,
  Select,
  Menu,
  Dropdown,
  Icon,
  Button,
  Switch,
  Tag,
  List,
  Divider
} from "antd";
import QuetureName from "../../admin/components/QuetureName";
import { formateQuetureName } from "../../../utils/utils";
import RemoteVideoController from "./RemoteVideoController";
import axios from "axios";
import { getHost, getDefaultConfig } from "../../../utils/APIUtils";
import RemoteVideoList from "./RemoteVideoList";

let CHANNEL_CODE = "REVIEW_CHANNEL";
let listenChannel = null;
if (window.BroadcastChannel) {
  listenChannel = new BroadcastChannel(CHANNEL_CODE);
}

export default class StudentListPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      curLecture: "",
      curQuetureList: null,
      selectedQuetureList: [],
      curQuetureIndex: null,
      switchView: false
    };
  }

  handleChangeCurLecture = value => {
    console.log("lectureId: ", value);

    const _curLecture = this.props.lectureData.filter(
      l => l.lectureId === value
    )[0];

    this.setState({
      curLecture: _curLecture,
      curQuetureList: _curLecture["sections"].filter(s => s.type === "QUETURE")
    });
  };

  handleRemoveSelectedQueture = index => {
    let _newList = this.state.selectedQuetureList;

    _newList.splice(index, 1);

    this.setState({
      selectedQuetureList: _newList
    });
  };

  handleSwitchView = checked => {
    this.setState(
      {
        switchView: checked
      },
      () => {
        if (checked) {
          this.setQuetureData(0);
        } else {
          this.delQuetureData();
        }
      }
    );
  };

  setQuetureData = index => {
    const { selectedQuetureList } = this.state;
    const quetureId = selectedQuetureList[index].quetureId;

    axios
      .get(`${getHost()}/content/v2/quetures/${quetureId}`, {
        ...getDefaultConfig()
      })
      .then(res => {
        if (res.data) {
          this.setState({
            curQuetureIndex: index
          });
          listenChannel.postMessage({
            type: "setQueture",
            value: res.data
          });
        }
      });
  };

  delQuetureData = () => {
    listenChannel.postMessage({
      type: "delQueture"
    });
  };

  render() {
    const { isOpen, onClose, lectureData } = this.props;
    const {
      switchView,
      curLecture,
      curQuetureList,
      selectedQuetureList,
      curQuetureIndex
    } = this.state;

    console.log("curqueture list: ", curQuetureList);
    console.log("lecture data: ", lectureData);
    console.log("cur lecture: ", curLecture);
    console.log("selected list: ", selectedQuetureList);

    return (
      <Popup isOpen={isOpen} className="popup-student-list">
        <div className="popup-closebtn" onClick={() => onClose(null)}></div>
        <div className="title">지난 회차 복습하기</div>
        <div style={{ padding: 24, minHeight: 500, textAlign: "left" }}>
          {!switchView && (
            <Row>
              <Typography.Title level={4}>깨처 선택하기</Typography.Title>
              <Col span={24}>
                <Select
                  onChange={this.handleChangeCurLecture}
                  placeholder="회차 선택"
                  style={{ width: 120 }}
                >
                  {lectureData &&
                    lectureData.map((lecture, index) => (
                      <Select.Option
                        key={index + "lecture-list"}
                        value={lecture.lectureId}
                      >
                        {lecture.title}
                      </Select.Option>
                    ))}
                </Select>
                <Dropdown
                  overlay={
                    <Menu>
                      {curQuetureList &&
                        curQuetureList
                          .filter(o => !selectedQuetureList.includes(o))
                          .map(item => (
                            <Menu.Item
                              key={"filtered-list-" + item.quetureId}
                              onClick={() => {
                                this.setState({
                                  selectedQuetureList: [
                                    ...selectedQuetureList,
                                    item
                                  ]
                                });
                              }}
                            >
                              {formateQuetureName(item.quetureTitle)}
                            </Menu.Item>
                          ))}
                    </Menu>
                  }
                  disabled={!curLecture}
                >
                  <Button style={{ marginLeft: 8, width: 240 }}>
                    연관 깨처
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </Col>
              <Col span={24} style={{ marginTop: 8 }}>
                <List
                  itemLayout="horizontal"
                  dataSource={selectedQuetureList}
                  header={
                    <Typography.Title level={4}>
                      선택 된 깨처 목록
                    </Typography.Title>
                  }
                  renderItem={(item, index) => (
                    <List.Item>
                      <span>
                        <Typography.Text strong>
                          {formateQuetureName(item.quetureTitle)}
                        </Typography.Text>
                        <span
                          onClick={() =>
                            this.handleRemoveSelectedQueture(index)
                          }
                          style={{ marginLeft: 8 }}
                        >
                          <Icon type="close-circle" />
                        </span>
                      </span>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          )}

          {selectedQuetureList && selectedQuetureList.length > 0 && (
            <Row style={{ position: "absolute", bottom: 24 }}>
              <Col span={24}>
                <Typography.Text strong>TV에서 재생하기</Typography.Text>
                <Switch
                  checked={switchView}
                  onChange={this.handleSwitchView}
                  style={{ marginLeft: 8 }}
                />
              </Col>
            </Row>
          )}
          {switchView && (
            <>
              <RemoteVideoList
                title={"깨처 목록"}
                curIndex={curQuetureIndex}
                list={selectedQuetureList.map(item => ({
                  ...item,
                  title: formateQuetureName(item.quetureTitle)
                }))}
                onSelect={this.setQuetureData}
              />
              <Divider />
              <RemoteVideoController />
            </>
          )}
        </div>
      </Popup>
    );
  }
}
