import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Skeleton, PageHeader, Button, Icon, message } from "antd";
import Title from "antd/lib/typography/Title";
import ReportForm from "./ReportForm";
import ReactToPrint from "react-to-print";
import Axios from "utils/request";
import queryString from "query-string";
import { formateDate } from "utils/utils";

/* NOT USED NOW
const statusMap = {

  excellent: {
    className: "perfect",
    text: "당신은 깨봉박사님!",
    shortText: "깨봉박사님 이군요!",
  },
  veryGood: {
    className: "good",
    text: "너무 훌륭해요!",
    shortText: "",
  },
  good: {
    className: "nice",
    text: "아주 잘하고 있어요!",
    shortText: "",
  },
  average: {
    className: "normal",
    text: "잘하고 있어요!",
    shortText: "",
  },
  fair: {
    className: "sad",
    text: "조금 아쉽지만 힘내요!",
    shortText: "아쉽지만 힘내요",
  },
  poor: {
    className: "bad",
    text: "노력하면 좋아질거예요!",
    shortText: "노력하면 좋아져요",
  },
};
*/

class StudentReportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isReportLoading: false,
      reportData: null,
      studentData: null,
      isExamResultEdit: false,
      isCommentEdit: false,
      isConsultEdit: false,
      examResult: "",
      examCorrect: 0,
      examPartial: 0,
      examSolved: 0,
      examAbsent: null,
      examExecDate: 0,
      examTime: 0,
      comment: "",
      commentTime: 0,
      reportLogId: null,
      reportLogTime: 0,
      consult: "",
      consultTime: 0,
      lastExamResult: "",
      lastExamAbsent: null,
      lastExamExecDate: 0,
      lastComment: "",
      lastCommentTime: 0,
      lastConsult: "",
      lastConsultTime: 0,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { studentId, courseNo } = this.props.match.params;
    console.log("this studentId: ", studentId);
    if (user && studentId && (courseNo != null)) {
      this.handleReportData(studentId, courseNo);
      this.getStudentDetail(studentId);
    }
    window.addEventListener("beforeunload", this.handleLeave);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleLeave);
  }

  handleLeave = (e) => {
    if (this.state.isExamResult || this.state.isCommentEdit || this.state.isConsultEdit) {
      return (e.returnValue = "편집중입니다.  이대로 페이지를 벗어나시겠습니까?");
    } else {
      return;
    }
  };

  getStudentDetail(studentId) {
    const { user } = this.props;
    this.setState({
      isLoading: true,
    });
    Axios.get(`/academy/v2/accounts/${user.orgId}/students/${studentId}`)
      .then((res) => {
        console.log("student detail: ", res);
        const _studentDetail = { ...res };

        if (res.lectureStatus) {
          let attendanceCount = 0;
          let mvpCount = 0;
          Object.keys(res.lectureStatus).map((key) => {
            if (res.lectureStatus[key].attendance === true) {
              attendanceCount++;
            }
            if (res.lectureStatus[key].mvp === true) {
              mvpCount++;
            }
          });

          _studentDetail["avgAttendances"] =
            attendanceCount / Object.keys(res.lectureStatus).length;
          _studentDetail["mvpCount"] = mvpCount;
          console.log(_studentDetail);
        }

        this.setState({
          isLoading: false,
          studentData: _studentDetail,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleReportData = (studentId, courseNo) => {
    this.setState({
      isReportLoading: true,
    });
    Axios.get(`/academy/v2/students/${studentId}/reports/${courseNo}`)
      .then((res) => {
        console.log("### report res: ", res);
        // NOTE: to be synced with PublishedReportPage
        const _reportData = res;

        let _queturesPerLecture = res.lectures[0].quetures.length;
        let _lecturesQuetureCount = 0;
        res.lectures.map((l) => {
          if (l.quetures.length > _queturesPerLecture) {
            _queturesPerLecture = l.quetures.length;
          }
          _lecturesQuetureCount += l.quetures.length;
        });
        _reportData["lecturesQuetureCount"] = _lecturesQuetureCount;
        _reportData["queturesPerLecture"] = _queturesPerLecture;

        // use _reportData["lectures"] as it is
        this.setState({
          reportData: _reportData,
          examResult: (_reportData.examResult ? _reportData.examResult : ""),
          examCorrect: _reportData.examCorrect,
          examPartial: _reportData.examPartial,
          examSolved: _reportData.examSolved,
          examAbsent: _reportData.examAbsent,
          examExecDate: _reportData.examExecDate,
          examTime: _reportData.examTime,
          comment: (_reportData.comment ? _reportData.comment : ""),
          commentTime: _reportData.commentTime,
          reportLogId: _reportData.reportLogId,
          reportLogTime: _reportData.reportLogTime,
          consult: (_reportData.consult ? _reportData.consult : ""),
          consultTime: _reportData.consultTime,
          lastExamResult: _reportData.examResult,
          lastExamAbsent: _reportData.examAbsent,
          lastExamExecDate: _reportData.examExecDate,
          lastExamTime: _reportData.examTime,
          lastComment: _reportData.comment,
          lastCommentTime: _reportData.commentTime,
          lastConsult: _reportData.consult,
          lastConsultTime: _reportData.consultTime,
          isReportLoading: false,
        });
      })
      .catch((e) => {
         message.error("학습 리포트 자료 조회에 실패하였습니다: " + e);
      });
  };

/* NOT USED
  getCharacterInfoByScore(score) {
    let result = "";
    switch (true) {
    case score >= 90:
        result = statusMap["excellent"];
        break;
      case score >= 80:
        result = statusMap["veryGood"];
        break;
      case score >= 70:
        result = statusMap["good"];
        break;
      case score >= 60:
        result = statusMap["average"];
        break;
      case score >= 50:
        result = statusMap["fair"];
        break;
      case score < 50:
        result = statusMap["poor"];
        break;
      default:
        break;
    }
    return result;
  }
*/

  onChangeExamResultEdit(_examResult) {
    console.log("onChangeExamResult:", _examResult);
    if (_examResult == null) {
      _examResult = '';
    }

    const lastExamResult = (this.state.examResult)? this.state.examResult : '';
    if (lastExamResult.length >= _examResult.length + 1) {
      if (lastExamResult.substring(lastExamResult.length-1) == ' ' && _examResult.length > 0) {
        console.log("shrink examResult:", _examResult);
        // comsume spaces
        while (_examResult.length > 0 && _examResult.substring(_examResult.length - 1) == ' ') {
          _examResult = _examResult.substring(0, _examResult.length - 1);
        }
        _examResult = _examResult.substring(0, _examResult.length - 1);
      }
    }

    let solved = 0;
    let correct = 0;
    let partial = 0;
    let examResult = "";
    let resultArray = String(_examResult).split('');
    for (let i in resultArray) {
      let c = resultArray[i];
      console.log(c);
      if (c === "o" || c === 'O' || c === '1') {
         c = 'o'; 
         correct++;
         solved++;
      } else if (c === 'x' || c === 'X' ||  c === '0') {
         c = 'x'; 
         solved++;
      } else if (c === '/' || c === '5' || c === "%") {
         c = '/'
         partial++;
         solved++;
      } else if (c === '-' || c === '_' || c === '.') {
         // no count for solved;
         c = '-'
      } else {
         // ignore
         continue;
      }
      examResult = examResult + c + ' ';
      if ((examResult.length + 2) % 12 == 0) {
          examResult += '  ';
      }
    } 

    this.setState({
        examResult: examResult,
        examCorrect: correct,
        examPartial: partial,
        examSolved: solved,
        examTime: (examResult == '' && this.state.examExecDate == 0)? 0 : (new Date()).getTime()
    });
  }

  onChangeExamExecDate(_examExecDate) {
    this.setState({
      examExecDate: _examExecDate,
      examTime: (_examExecDate == 0 && this.state.examResult == '')? 0 : (new Date()).getTime()
    })
  }

  onChangeExamAbsent(_examAbsent) {
    console.log("examAbsent:", _examAbsent);
    this.setState({
      examAbsent: _examAbsent,
    })
  }

  onChangeCommentEdit(value) {
    this.setState({
      comment: value,
      commentTime: (value == '')? 0 : (new Date()).getTime()
    })
  }

  onChangeConsultEdit(value) {
    this.setState({
      consult: value,
      consultTime: (value == '')? 0 : (new Date()).getTime() 
    })
  }

  handleExamResultEdit() {
    const { studentId, courseNo } = this.props.match.params;
    const { reportData, examResult, examAbsent, examExecDate, examTime, lastExamResult, lastExamAbsent, lastExamExecDate, lastExamTime } = this.state;
    this.setState({ isExamResultEdit: !this.state.isExamResultEdit }, () => {
      if (!this.state.isExamResultEdit && (examResult != lastExamResult || examAbsent != lastExamAbsent || examExecDate != lastExamExecDate)) {
        Axios.put(
          `/academy/v2/students/${studentId}/reports/${reportData.courseNo}/exam-result`,
          {
            examResult: examResult,
            examCorrect: this.state.examCorrect,
            examPartial: this.state.examPartial,
            examSolved: this.state.examSolved,
            examAbsent: (examAbsent)? examAbsent : false,
            examExecDate: (examResult || examAbsent)? examExecDate : 0,
            examTime: (examResult || examAbsent)? examTime : 0,
          }
        )
          .then((res) => {
            console.log("done", res);
            this.setState({
              lastExamResult: examResult,
              lastExamAbsent: (examAbsent)? examAbsent : false,
              lastExamExecDate: (examResult || examAbsent)? examExecDate : 0,
              lastExamTime: (examResult || examAbsent)? examTime : 0,
            });
          })
          .catch((e) => {
            message.error("테스트 결과를 저장하지 못했습니다: " + e);
          });
      }
    });
  }

  handleExamResultCancel() {
    const { lastExamResult, lastExamAbsent, lastExamExecDate, lastExamTime } = this.state;
    this.onChangeExamResultEdit(lastExamResult);
    this.setState({
      examResult: lastExamResult,
      examAbsent: lastExamAbsent,
      examExecDate: lastExamExecDate,
      examTime: lastExamTime,
      isExamResultEdit: false,
    });
  }

  handleCommentEdit() {
    const { studentId, courseNo } = this.props.match.params;
    const { reportData, comment, commentTime, lastComment } = this.state;
    this.setState({ isCommentEdit: !this.state.isCommentEdit }, () => {
      if (!this.state.isCommentEdit && comment != lastComment) {
        Axios.put(
          `/academy/v2/students/${studentId}/reports/${reportData.courseNo}/comment`,
          {
            comment: comment,
            commentTime: commentTime,
          }
        )
          .then((res) => {
            console.log("done", res);
            this.setState({
              lastComment: comment,
              lastCommentTime: commentTime,
            });
          })
          .catch((e) => {
            message.error("선생님 메모를 저장하지 못했습니다: " + e);
          });
      }
    });
  }

  handleCommentCancel() {
    const { lastComment, lastCommentTime } = this.state;
    this.setState({
      comment: lastComment,
      commentTime: lastCommentTime,
      isCommentEdit: false,
    });
  }

  handleConsultEdit() {
    const { studentId, courseNo } = this.props.match.params;
    const { reportData, consult, consultTime, lastConsult } = this.state;
    this.setState({ isConsultEdit: !this.state.isConsultEdit }, () => {
      if (!this.state.isConsultEdit && consult != lastConsult) {
        Axios.put(
          `/academy/v2/students/${studentId}/reports/${reportData.courseNo}/consult`,
          {
            consult: consult,
            consultTime: consultTime,
          }
        )
          .then((res) => {
            console.log("done", res);
            this.setState({
              lastConsult: consult,
              lastConsultTime: consultTime
            });
          })
          .catch((e) => {
            message.error("상담내역을 저장하지 못했습니다: " + e);
          });
      }
    });
  }

  handleConsultCancel() {
    const { lastConsult, lastConsultTime } = this.state;
    this.setState({
      consult: lastConsult,
      consultTime: lastConsultTime,
      isConsultEdit: false,
    });
  }

  handlePublish = (studentId, courseNo) => {
    Axios.post(`/academy/v2/report/publish/study-report/${studentId}/${courseNo}?sendMessage=true`)
      .then((res) => {
         console.log("published: ", res);
         this.setState({
           reportLogId: res,
           reportLogTime: (new Date()).getTime(),
         });
         message.info("리포트를 발행하여 전송하였습니다.");
       })
       .catch((e) => {
         message.error("리포트 발행에 실패하였습니다: " + e);
       });
  }

  sendReportMail(studentData, reportData) {
    let titleRange = '';
    if (reportData.lectures.length > 1) {
      titleRange = String(reportData.lectures[0].title).replace('회차','').replace('강','') + '~' + reportData.lectures[reportData.lectures.length-1].title;
    } else {
      titleRange = reportData.lectures[0].title;
    }
    let reportTitle = studentData.name + ' 학습리포트: ' + titleRange;
    let imageBase64 = '';

    Axios.post(
      `/academy/v2/amdin/mail`,
      {
        from: '인공지능수학 깨봉 <no-reply@mail.quebon.tv>',
        //template: 'academy_report',
        subject: '[깨봉] '+ reportTitle + ' 를 보내드립니다.',
        text:
`
${reportTitle}
`,
        imageBase64: imageBase64,
        recipents: [ {
          name: studentData.Name,
          email: studentData.parentEmail,
          mobile: studentData.parentMobile
        }]
      }).then((res) => {
         message.info("학습 리포트 메일을 전송하였습니다.");
      }).catch((e) => {
         message.error("학습 리포트 메일을 전송하지 못했습니다: " + e);
      });
   }    

  render() {
    const { studentId, _courseNo } = this.props.match.params;
    const { pending } = this.props;
    const { isLoading, isReportLoading, isExamResultEdit, isCommentEdit, isConsultEdit, studentData, reportData, examResult, examCorrect, examPartial, examSolved, examAbsent, examExecDate, examTime, comment, commentTime, reportLogId, reportLogTime, consult, consultTime } = this.state;
    console.log("student data: ", studentData);
    console.log("session report: ", reportData);

    const courseNo = (reportData)? reportData.courseNo : _courseNo;
    const courseMax = (reportData)? reportData.courseMax : 0;

    return (
      <Skeleton loading={isLoading || pending} active>
        <PageHeader
          title={
            <Title level={2} style={{ marginBottom: 0 }}>
              학습리포트: {studentData ? studentData.name : ''}
            </Title>
          }
          extra={[
            <Button key="prev-report" onClick={() => {
                this.handleReportData(studentId, courseNo-1);
                this.props.history.push(`/admin/student/detail/${studentId}/report/${courseNo-1}`);
              }} hidden={!reportData || courseNo == 1}>
              <Icon type="left" />
              {`이전 리포트: 코스[${courseNo-1}]`}
            </Button>,
            <Button key="next-report" onClick={() => {
                this.handleReportData(studentId, courseNo+1);
                this.props.history.push(`/admin/student/detail/${studentId}/report/${courseNo+1}`);
              }} hidden={!reportData || courseNo >= courseMax}>
              {`다음 리포트: 코스[${courseNo+1}]`}
              <Icon type="right" />
            </Button>,
            <Button key="1" onClick={() => this.handleExamResultEdit()}>
              {`테스트 결과 ${this.state.isExamResultEdit ? "저장" : "수정"}`}
              <Icon type="setting" />
            </Button>,
            <Button key="2" onClick={() => this.handleCommentEdit()}>
              {`선생님 메모 ${this.state.isCommentEdit ? "저장" : "수정"}`}
              <Icon type="setting" />
            </Button>,
            <Button key="3" onClick={() => this.handleConsultEdit()}>
              {`학습상담 내역 ${this.state.isConsultEdit ? "저장" : "수정"}`}
              <Icon type="setting" />
            </Button>,
            <span key="4">
              {(isExamResultEdit || isCommentEdit || isConsultEdit) ? (
                <Button onClick={() => message.warning("편집 중인 내용을 저장해주세요")}>
                  리포트 출력<Icon type="printer" />
                </Button>
              ) : (
                <ReactToPrint
                  trigger={() => <Button>리포트 출력<Icon type="printer" /></Button>}
                  content={() => this.componentRef}
                />
              )}
            </span>,
            <span key="5">
              {/* (isExamResultEdit || isCommentEdit || isConsultEdit) ? (
                <Button onClick={() => message.warning("편집 중인 내용을 저장해주세요")}>
                  리포트 메일발송<Icon type="mail" />
                </Button>
              ) : (
                <Button onClick={() => this.sendReportMail(studentData, reportData)}>
                  리포트 메일발송<Icon type="mailr" />
                </Button>
              ) */}
            </span>,
          ]}
        ></PageHeader>
        <div className="admin-page-content">
          <div
            ref={(el) => (this.componentRef = el)}
            className="student-report-page" 
          >
            {(studentData && reportData)? (
              <ReportForm
                courseNo={reportData.courseNo}
                courseTitle={reportData.courseTitle}
                isExamResultEdit={isExamResultEdit}
                isCommentEdit={isCommentEdit}
                isConsultEdit={isConsultEdit}
                studentData={studentData}
                reportData={reportData}
                examResult={examResult}
                examCorrect={examCorrect}
                examPartial={examPartial}
                examSolved={examSolved}
                examAbsent={examAbsent}
                examExecDate={examExecDate}
                examTime={examTime}
                comment={comment}
                commentTime={commentTime}
                reportLogId={reportLogId}
                reportLogTime={reportLogTime}
                consult={consult}
                consultTime={consultTime}
                onExamResultInput={(e) => this.onChangeExamResultEdit(e.target.value)}
                onExamExecDateInput={(v) => this.onChangeExamExecDate(v)}
                onExamAbsentInput={(v) => this.onChangeExamAbsent(v)}
                onCommentInput={(e) => this.onChangeCommentEdit(e.target.value) }
                onConsultInput={(e) => this.onChangeConsultEdit(e.target.value) }
                handleExamResultEdit={(e) => this.handleExamResultEdit()}
                handleExamResultCancel={(e) => this.handleExamResultCancel()}
                handleCommentEdit={(e) => this.handleCommentEdit()}
                handleCommentCancel={(e) => this.handleCommentCancel()}
                handleConsultEdit={(e) => this.handleConsultEdit()}
                handleConsultCancel={(e) => this.handleConsultCancel()}
                handlePublish={(e) => this.handlePublish(studentId, courseNo)}
              />
            )
            : (isReportLoading) ? <center> ... 로딩중입니다 ...</center>
            : ((!isReportLoading || isLoading) || <center>학습 완료된 코스가 없어 리포트가 생성되지 않습니다.</center>)
            }
          </div>
          <div style={{ height: '60px' }}></div>
        </div>
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      pending: auth.pending,
      auth: auth,
      user: auth.user,
      logged: auth.logged,
      userPermission:
        auth.user.type === "org" ? "master" : auth.user.permission,
    }),
    null
  )(StudentReportPage)
);
