import React, { Component } from "react";
import Axios from "../../../utils/request";
import { Button, Input, Skeleton } from "antd";
import { getHost } from "../../../utils/APIUtils";
import SockJsClient from "react-stomp";
import { FormattedMessage } from "react-intl";
import { formatText } from "../../../components/Quiz/utils/Formatter";

class PreviewStorytelling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storytelling: [],
      isLoading: false,
      pageIdx: 0,
      connected: false,
    };
  }

  componentDidMount() {
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.addEventListener("message", this.handleQuizBroadcast);
    }
    this.init();
  }

  componentWillUnmount() {
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.removeEventListener("message", this.handleQuizBroadcast);
    }
    if (this.state.connected) {
      this.onDisconnect();
    }
  }

  init = () => {
    this.load();
  };

  load = () => {
    const { storytellingsId } = this.props;

    this.setState({
      isLoading: true,
    });

    Axios.get(`/content/v2/storytellings/${storytellingsId}`).then((e) => {
      this.setState({
        storytelling: e.storytellingViewList,
        isLoading: false,
      });
    });
  };

  onConnect = () => {
    this.setState({
      connected: true,
    });
  };

  onDisconnect = () => {
    this.setState({
      connected: false,
    });
  };

  handleBroadcast = (res) => {
    switch (res.type) {
      case "movePage":
        if (this.props.viewType !== "guide")
          this.setState({
            pageIdx: res.data,
          });
        break;
    }
  };

  nextPage = () => {
    const { storytelling, pageIdx } = this.state;
    if (storytelling) {
      if (storytelling.length - 1 != pageIdx) {
        this.setState(
          {
            pageIdx: pageIdx + 1,
          },
          () => {
            if (this.props.viewType === "guide") {
              this.props.onSendMessage({
                type: "movePage",
                data: this.state.pageIdx,
              });
            }
          }
        );
      }
    }
  };

  beforePage = () => {
    const { storytelling, pageIdx } = this.state;
    if (storytelling) {
      if (pageIdx != 0) {
        this.setState(
          {
            pageIdx: pageIdx - 1,
          },
          () => {
            if (this.props.viewType === "guide") {
              this.props.onSendMessage({
                type: "movePage",
                data: this.state.pageIdx,
              });
            }
          }
        );
      }
    }
  };

  render() {
    const { storytelling, isLoading, pageIdx } = this.state;

    return (
      <div
        className={"quetureranking-layout"}
        style={{
          marginBottom: "10px",
        }}
      >
        <Skeleton loading={isLoading} active>
          <div
            style={{
              width: "auto",
              height: "auto",
              display: "flex",
              boxShadow: "0px 0px 0px rgb(0 0 0 / 10%)",
              padding: "10px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ maxHeight: "300px" }}
              src={storytelling[pageIdx]?.content?.signedUrl}
              alt="storytelling"
            />
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.06)",
                marginTop: "20px",
                marginBottom: "10px",
                // minWidth: "800px",
                minHeight: "300px",
                border: "5px",
                borderRadius: "4px",
                width: "100%",
                position: "relative"
              }}
            >
              <span
                style={{
                  margin: "5px",
                  // position: "absolute",
                  fontSize: "16px",
                  display: "block",
                  padding: "24px 80px"
                }}
              >
                {formatText(storytelling[pageIdx]?.body, pageIdx, null)}
              </span>

              {pageIdx !== 0 && (
                <Button
                  style={{
                    position: "absolute",
                    top: "125px",
                    left: "4px",
                    background: "#55a3ff",
                    color: "#fff",
                  }}
                  onClick={() => {
                    this.setState({
                      pageIdx: pageIdx - 1,
                    });
                  }}
                >
                  이전
                </Button>
              )}
              {storytelling.length - 1 !== pageIdx && (
                <Button
                  style={{
                    background: "#55a3ff",
                    top: "125px",
                    color: "#fff",
                    position: "absolute",
                    right: "4px",
                  }}
                  onClick={() => {
                    this.setState({
                      pageIdx: pageIdx + 1,
                    });
                  }}
                >
                  다음
                </Button>
              )}
            </div>
          </div>
        </Skeleton>
      </div>
    );
  }
}

export default PreviewStorytelling;
