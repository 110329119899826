import React from 'react';
import classNames from 'classnames';

const QuizLayoutC = (props) => {
  const { isLong, isQuizRight, imageComponent, problemComponent, choiceComponent, titleComponent, desmosComponent } = props;
  const QuizAnswers = choiceComponent;
  const QuizProblem = problemComponent;
  const Desmos = desmosComponent;
  const QuizImage = imageComponent;
  const QuizTitle = titleComponent;
  return (
    // left( 또는 클래스 없을시 ) 가 왼쪽 2개, right 가 오른쪽 2개
    <div className={classNames("layout type-c", {
      "long": isLong,
      "right": isQuizRight
    })}>
      <div className="inline-block">
        {/* <div className="mb16">
          <QuizTitle />
        </div> */}
        {
          QuizProblem() &&
          <div className="mb16">
            <QuizProblem />
          </div>
        }
        <QuizAnswers />
      </div>

      <QuizImage />
    </div>
  );
}

export default QuizLayoutC;
