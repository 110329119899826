import React, { useState, useEffect, Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Axios from "../../../../utils/request";
import {
  Skeleton,
  Row,
  Col,
  List,
  Tag,
  Spin,
  PageHeader,
  Button,
  Icon,
  Descriptions,
  Table,
  Divider,
  Calendar,
  Tabs,
  Badge,
  Typography,
  Input,
  Radio,
  Statistic,
  message,
} from "antd";
import { filter, values } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
//import _StudentLectureStat from "../../components/StudentLectureStat";
import AccountApis from "../../../../utils/accountApis";
import { ConstantsContext } from "utils/ConstantsContext";
// import _StudentLectureStatList from "pages/admin/components/StudentLectureStatList";

const { Title, Paragraph, Text } = Typography;

const badgeList = {
  pending: {
    type: "blue",
    text: "수업 예정일",
  },
  current: {
    type: "blue",
    text: "수업중",
  },
  completed: {
    type: "cyan",
    text: "수업 완료",
  },
  incomplete: {
    type: "red",
    text: "수업 미완료",
  },
};

class ClassroomStudentList extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      studentList: [],
      student: null,
      curLecture: null,
      classroomData: null,
      isLoading: false,
      curStudentStat: null,
      lectures: null,
    };
  }

  componentDidMount() {
    const { id, studentId } = this.props.match.params;
    const { user } = this.props;
    console.log("id: ", id);
    console.log("user: ", user);
    console.log("studentId: ", studentId);
    if (user && id) {
      // this.getClassroomDetail(user, id, studentId);
      this.getStudentList(user, id, studentId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, user, studentId } = this.props.match.params;
    const { studentId: prevStudentId } = prevProps.match.params;
    if (prevProps.user !== this.props.user || studentId !== prevStudentId) {
      if (this.props.user && id) {
        console.log("get");
        // this.getClassroomDetail(this.props.user, id, studentId);
        this.getStudentList(this.props.user, id, studentId);
      }
    }
  }

  getStudentList(user, classroomId, studentId) {
    const _orgId = user.orgId || user.userId;
    AccountApis.getClassroomStudentList(_orgId, classroomId, 0, 50).then(
      (res) => {
        const _student = filter(res.list, function (o) {
          return o.studentId == studentId;
        })[0];
        console.log("cur Student: ", _student);
        this.setState(
          {
            studentList: res.list,
            student: _student,
          },
          () => {
            this.getClassroomDetail(user, classroomId, studentId);
          }
        );
      }
    );
  }

  getClassroomDetail(user, classroomId, studentId) {
    this.setState({
      isLoading: true,
    });

    Axios.get(
      `/academy/v2/accounts/${
        user.orgId || user.userId
      }/classrooms/${classroomId}`
    )
      .then((res) => {
        console.log("classroom detail: ", res);
        console.log("studentlist: ", res.students);
        const _curStudentStat = res.lectures;
        const _lectures = [];
        Object.keys(res.lectures).map((key) => {
          _lectures.push(res.lectures[key][0]);
        });
        console.log("_lectures: ", _lectures);
        this.setState({
          classroomData: res,
          lectures: _lectures,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  getStudentDetail(user, classroomId, studentId) {
    Axios.get(
      `/academy/v2/accounts/${
        user.orgId || user.userId
      }/classrooms/${classroomId}/students/${studentId}`
    )
      .then((res) => {
        console.log("classroom detail: ", res);
        console.log("studentlist: ", res.students);
      })
      .catch((e) => {});
  }

  handleUpdateScore = (score) => {
    console.log(score);
    console.log(this.state.student);
  };

  render() {
    const {
      student,
      curLecture,
      studentList,
      classroomData,
      isLoading,
      curStudentStat,
      lectures,
    } = this.state;
    const { id, studentId } = this.props.match.params;

    if (!student || !studentList.length === 0) {
      return null;
    }

    console.log("classroomData: ", classroomData);
    console.log("student list: ", studentList);
    console.log("student: ", student);
    console.log("curlecture: ", curLecture);

    return (
      <Skeleton loading={isLoading} active>
        {studentList.length > 0 && student && classroomData && (
          <>
            <PageHeader
              // onBack={() => window.history.back()}
              title={
                <Title level={2} style={{ marginBottom: 0 }}>
                  학생 출결 및 평가관리
                </Title>
              }
              extra={[
                <span key="0">
                  {this.context.type === "campus" && (
                      <Button>
                        <Link to={`/admin/student/detail/${student.studentId}/report/0`} >
                          학습 리포트
                        </Link>
                      </Button>
                    )}
                </span>,
                <Button key="1">
                  <Link to={`/admin/student/detail/${student.studentId}`}>
                    <Icon type="bar-chart" />
                    학생 상세정보 보기
                  </Link>
                </Button>,
              ]}
            >
              <Row type="flex">
                <Statistic
                  title="나이"
                  value={(student.birthday && student.birthday.age) || "미설정"}
                />
                <Statistic
                  title="평균 출석율"
                  value={`${student.attendanceRate || 0}%`}
                  style={{
                    margin: "0 32px",
                  }}
                />
                <Statistic
                  title="평균 수업점수"
                  value={`${student.avgScore}`}
                />
              </Row>
            </PageHeader>
            <div className="admin-page-content">
              <Row gutter={24}>
                <Col span={4}>
                  <List
                    header={
                      <Title level={4}>
                        {`${classroomData.name} 수강 학생 목록`}
                        <Tag color="blue">{`총${studentList.length}명`}</Tag>
                      </Title>
                    }
                    dataSource={studentList}
                    renderItem={(item) => (
                      <List.Item>
                        <Link
                          to={`/admin/class/detail/${classroomData.classroomId}/student/${item.studentId}`}
                        >
                          {item.studentId === student.studentId && (
                            <Icon type="caret-right" />
                          )}
                          <Text strong={item.studentId === student.studentId}>
                            {item.name}
                          </Text>
                        </Link>
                      </List.Item>
                    )}
                  />
                </Col>
                <Col span={20}>
                  <Row>
                    <Col span={24}>
                      {/*{lectures && (*/}
                      {/*  <_StudentLectureStatList*/}
                      {/*    list={lectures}*/}
                      {/*    studentId={studentId}*/}
                      {/*    classroomId={id}*/}
                      {/*    onUpdateScore={this.handleUpdateScore}*/}
                      {/*  />*/}
                      {/*)}*/}
                    </Col>
                    {/* <Col span={12}>
                      <List
                        grid={{ gutter: 16, column: 3 }}
                        dataSource={values(badgeList)}
                        renderItem={(item) => (
                          <List.Item style={{ float: "right" }}>
                            <Badge status={item.type} text={item.text} />
                          </List.Item>
                        )}
                      />
                      <Calendar
                        mode="month"
                        className="student-stat-calendar"
                        validRange={[
                          moment(classroomData.startDate),
                          moment(classroomData.endDate),
                        ]}
                        dateCellRender={(date) => (
                          <ul className="events" style={{ height: "100%" }}>
                            {Object.keys(classroomData.lectures).map((key) => {
                              const lectureDate = key.split(" ")[0];
                              const currentDate =
                                "" + moment(date).format("YYYY-MM-DD");
                              if (currentDate == lectureDate) {
                                const _lecture = classroomData.lectures[key][0];

                                let _state = _lecture.state;
                                if (_state === "completed") {
                                  if (
                                    _lecture.attendances &&
                                    _lecture.attendances[student.studentId] &&
                                    _lecture.attendances[student.studentId]
                                      .status === "ok"
                                  ) {
                                    _state = "completed";
                                  } else {
                                    _state = "incomplete";
                                  }
                                }
                                if (!_state) {
                                  _state = "pending";
                                }

                                return (
                                  <li
                                    key={key}
                                    onClick={() =>
                                      this.setState({
                                        curLecture: _lecture,
                                      })
                                    }
                                    style={{ height: "100%" }}
                                  >
                                    {_state && (
                                      <Badge
                                        status={badgeList[_state].type}
                                        text={badgeList[_state].text}
                                      />
                                    )}
                                  </li>
                                );
                              } else {
                                return false;
                              }
                            })}
                          </ul>
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      {curLecture ? (
                        <StudentLectureStat
                          classroomId={classroomData.classroomId}
                          lecture={curLecture}
                          student={student}
                          onUpdateScore={this.handleUpdateScore}
                        />
                      ) : (
                        <Title level={4} style={{ paddingLeft: 20 }}>
                          날짜를 선택해 주세요.
                        </Title>
                      )}
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Skeleton>
    );
  }
}

export default connect(
  ({ auth, classroom }) => ({
    user: auth.user,
    logged: auth.logged,
  }),
  null
)(ClassroomStudentList);
