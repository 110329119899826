import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { formatText } from "./utils/Formatter";
import { MultiChoiceAnswer } from "./modules";

class InlineMultiChoiceQuiz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      quizId: null,
      answers: []
    }
  }

  init = data => {
    const { stepQuizAnswers } = this.props;
    const { quiz } = data;
    const { answers } = this.state;

    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      const stepAnswersMap = [];
      const answerArr = new Array(quiz.children.children);
      quiz.children.map((child, index) => {
        answerArr[index] = new Array(child.choices.length).fill(false);
      });
      answerArr.forEach((ans, idx) => {
        console.log(ans)
        const singleAnswerMap = [];
        ans.forEach((a, i) => {
          const checkIndex = i + 1;
          if (stepQuizAnswers[idx].find(s => Number.parseInt(s) === checkIndex)) {
            singleAnswerMap.push(true);
          } else {
            singleAnswerMap.push(false);
          }
        });
        stepAnswersMap.push(singleAnswerMap);
      });

      this.setState({
        answers: stepAnswersMap
      })
    } else {
      const answerArr = new Array(quiz.children);
      quiz.children.map((child, index) => {
        answerArr[index] = new Array(child.choices.length).fill(false);
      });
      this.setState({
        answers: answerArr
      });
    }
  };

  componentDidMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      this.init(this.props.data)
    }
  }

  onAnswerToggled = (idx, i) => {
    const { onAnswerUpdated } = this.props;
    this.setState(
      {
        answers: update(this.state.answers, {
          [idx]: {
            [i]: {
              $set: !this.state.answers[idx][i]
            }
          }
        })
      },
      () => {
        const replaced = [];
        this.state.answers.forEach((x, idx) => {
          const childAns = []
          x.forEach((a, i) => {
            if (a === true) childAns.push(i + 1 + "");
          });
          replaced.push(childAns);
        });
        if (replaced.flat && replaced.flat().length === 0) {
          onAnswerUpdated([]);
        } else {
          onAnswerUpdated(replaced);
        }
      }
    );
  };

  render() {
    const { data, play, solved, solvedData, showReallyTrue } = this.props;
    const { quizId, quiz } = data;
    const { children, title } = quiz;
    const { answers } = this.state;

    let index = 0;
    answers.map(e => index = e.length);
    let _answers = Array.from(Array(answers.length), () => Array(index).fill(false));

    if (this.props.answers.length != 0) {
      for (let i = 0; i < this.props.answers.length; i++) {
        this.props.answers[i].map((e) => {
          _answers[i][e - 1] = true;
        })
      }
    }

    return (
      <div className="quiz-content__answer t-answer-inlinemc">
        {children && (answers && answers.length > 0) &&
          children.map((child, idx) => (
            <div key={quizId + "-answerArea-" + idx} className="t-answer-inlinemc__wrap">
              <span className="quiz-content__answer-subtitle-num">문제{idx + 1}</span>
              <span className="quiz-content__answer-subtitle-title">{formatText(child.problem)}</span>
              <ul className="t-answer-inlinemc__content">
                {child.choices.map((c, i) =>{
                  return (
                    <MultiChoiceAnswer
                      key={quizId + "_ans_" + idx + i}
                      index={i}
                      choice={c}
                      inlineIndex={idx}
                      isInline={true}
                      selected={this.props.answers.length != 0 ? _answers[idx][i] : answers[idx][i]}
                      solvedData={solvedData}
                      showReallyTrue={showReallyTrue}
                      onToggle={() => this.onAnswerToggled(idx, i)}
                    />
                  )
                }
                )}
              </ul>
            </div>
          ))}
      </div>
    );
  }
}

InlineMultiChoiceQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
};

export default InlineMultiChoiceQuiz;
