import React, { Component } from 'react';
import { formatText } from '../utils/Formatter';

class DraggablePreview extends Component {
    render() {
        const { choice } = this.props;

        return (
            <a className="draggable pick">
                <span>{formatText(choice.answer)}</span>
            </a>
        )
    }
}

export default DraggablePreview;
