import React, { useState, useEffect } from "react";
import { useMouse } from "react-use";
import LectureTooltip from "./LectureTooltip";
import { formateKoreanTime } from "../../../utils/utils";
import { filter, orderBy, values } from "lodash";

const LectureContent = props => {
  const { lecture, lectureStatus, user } = props;

  const [isMvp, setMvp] = useState(false);
  const [gameRanking, setGameRanking] = useState(null);

  const ref = React.useRef(null);
  const { docX, docY, posX, posY, elX, elY, elW, elH } = useMouse(ref);
  useEffect(() => {
    if (lecture && lecture.sections) {
      const game = filter(lecture.sections, function(o) {
        return o.type === "GAME";
      });
      if (game && game[0] && game[0].ranking) {
        game[0].ranking.map((r, index) => {
          if (r.student.studentId === user.studentId) {
            setGameRanking(index + 1);
          }
        });
      }
      if (lectureStatus && lectureStatus[lecture.lectureId]) {
        const curLectureStatus = lectureStatus[lecture.lectureId];
        if (curLectureStatus.mvp) {
          setMvp(true);
        }
      }
    }

    return () => {
      setMvp(false);
      setGameRanking(null);
    };
  }, []);

  return (
    <div className="wrap" ref={ref}>
      {lecture.state === "completed" ? (
        <>
          <p>
            <span>수업 완료</span>
          </p>
          <div className="time" style={{ fontSize: 12 }}>
            {formateKoreanTime(lecture.date.split(" ")[1]) + "수업"}
          </div>
          {isMvp && (
            <p>
              <span>MVP</span>선정
            </p>
          )}
          {gameRanking && (
            <p>
              게임 랭킹<span>{gameRanking}등</span>
            </p>
          )}
        </>
      ) : lecture.state === "pending" ? (
        <>
          <p>
            <span>수업 예정</span>
          </p>
          <div className="time" style={{ fontSize: 12 }}>
            {formateKoreanTime(lecture.date.split(" ")[1]) + "수업"}
          </div>
        </>
      ) : null}
      <LectureTooltip lecture={lecture} top={elY} left={elX} />
    </div>
  );
};

export default LectureContent;
