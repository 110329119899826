import React from "react";
import classNames from "classnames";

const ReportQuetureList = ({ rowSpan, list, isBreakPage }) => {
  return (
    <table
      className={classNames("published_curri", { "break-page": isBreakPage, })}
    >
      <tbody>
        <tr>
          <th className="title-queture">깨처 / 주요 개념 및 활동</th>
          <th className="title-queture-correct-rate">학습</th>
          <th className="title-queture-homework-queture">복습</th>
        </tr>
        {list.map((lecture, index) => {
          const _quetureList = lecture.quetures.filter((q, i) => q.quizzes);
          let wasHomeworkQuetureCompleted = true;

          //return lecture.quetures.map((q, i) => {
          return _quetureList.map((q, i) => {
            if (!q.quizzes) {
               return;  // skip queture without quizzes (by YJ as Sophie's req at 2022-02-04)
            }

            // NOTE: handle solved=0 case as no-data
            let countClass = (lecture.mvp)? "count_mvp" : "count_normal";
            let correctRate = (!lecture.absent && q.solved && q.quizzes) ? Math.round(100 * q.corrects / q.quizzes) : null;
            let correctState =
              correctRate >= 80 ? <font color="#2020FF">상</font> :
              correctRate >= 20 ? <font color ="#444444">중</font> :
              correctRate >= 0 ?  <font color="#FF2020">하</font> : '-';

            if (lecture.completed && q.homeworkQuetureCompleted === false && wasHomeworkQuetureCompleted) {
                wasHomeworkQuetureCompleted = false;
            }

            return (
                <>
                {(i === 0) &&
                  <tr className={'published_curri striped-rows-bright'}
                     style={{ pageBreakBefore: 'auto', pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                    <td colSpan="3" className="count"
                        style={{ pageBreakBefore: 'auto', pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                      <div className={countClass}>{lecture.title}
                        {!lecture.completed ? <span className="count_absent"><font color="grey"> 미완료</font></span> :
                         lecture.absent ?  <span className="count_absent"> 결석</span> : ''}
                        <span className="count_date">&nbsp; {String(lecture.date).replaceAll('-', '.')}</span>
                      </div>
                    </td>
                  </tr>
                }
                <tr className={'published_curri striped-rows-dark'}
                     style={{ pageBreakBefore: 'auto', pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                  <td className="queture">
                    <div>{q.title}</div>
                    <div className="activity">
                    { 
                      q.point === null || q.point === "" ? q.conceptTags.join(",") : q.point
                    }
                    </div>
                  </td>
                  <td className="queture_correct_rate">
                    {correctRate != null ? correctState : '-'}
                  </td>
                  <td className="queture_homework_queture">
                    { (!lecture.completed || !q.quizzes) ? '-' : (q.homeworkQuetureCompleted ? 'O' : 'X') }
                  </td>
                </tr>
                </>
              );
          });
        })}
      </tbody>
    </table>
  );
};

export default ReportQuetureList;
