import React, { useState, useEffect } from "react";
import { Quiz } from "components/Quiz";
import Axios from "../../../utils/request";
import { Skeleton, Row, Col, List, Button } from "antd";

const PreviewQuizList = (props) => {
  const { list, isChallenge } = props;
  const [curIndex, setCurIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [quizData, setQuizData] = useState(null);

  useEffect(() => {
    if (list && list[0]) {
      load(list[0].quizId);
    }

    return () => {
      setQuizData(null);
    };
  }, [list]);

  function load(quizId) {
    setLoading(true);
    setQuizData(null);
    Axios.get(`/quiz/v2/quizzes/${quizId}`).then((response) => {
      setLoading(false);
      setQuizData(response);
    });
  }

  function onSolve(quizId, answers, vars) {
    return Axios.post(`/quiz/v2/quizzes/${quizId}/solve`, {
      quizId: quizId,
      answers: answers,
      vars: vars,
    });
  }
  return (
    <Row>
      <Col span={3} style={{ marginTop: 40, paddingLeft: 20 }}>
        {!isChallenge && list &&
          list.map((item, index) => (
            <Button
              key={item.quizId}
              onClick={() => load(item.quizId)}
              type={
                quizData && item.quizId === quizData.quizId ? "primary" : ""
              }
              style={{
                marginBottom: 8,
              }}
            >{`${index + 1}번째 문제`}</Button>
          ))}
      </Col>
      <Col span={20}>
        <Skeleton loading={isLoading} active>
          {quizData && (
            <Quiz
              data={quizData}
              quizType={"admin"}
              solver={onSolve}
              status={[]}
            />
          )}
        </Skeleton>
      </Col>
    </Row>
  );
};

export default PreviewQuizList;
