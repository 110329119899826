import React, { useState, useEffect } from "react";
import axios from "axios";
import "./_myprofile.scss";
import HomeHeader from "../../components/HomeTraning/HomeHeader/HomeHeader";
import { getProfileUrl, isImage } from "../../utils/utils";
import { getHost, getDefaultConfig } from "../../utils/APIUtils";
import ProfileImage from "../admin/components/ProfileImage";
import Toast from "components/Toast/Toast";
import classNames from "classnames";
import AccountApis from "../../utils/accountApis";

const MyProfile = props => {
  const { userInfo, lectures, onUpdateUserInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [openResetPassword, toggleResetPassword] = useState(false);
  const [passwordData, setPasswordForm] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: ""
  });
  const [validation, setValidation] = useState({
    password: null,
    confirmPassword: null
  });

  const handlePasswordForm = () => {
    if (openResetPassword) {
      console.log(passwordData);
      console.log(validation);
      resetPassword();
    } else {
      toggleResetPassword(!openResetPassword);
    }
  };

  const handleInputChange = e => {
    const name = e.target.name;
    const value = e.target.value.trim();

    setPasswordForm({
      ...passwordData,
      [name]: value
    });

    if (name === "password") {
      setValidation({
        ...validation,
        [name]: value !== ""
      });
    } else {
      setValidation({
        ...validation,
        [name]: passwordData.password === value
      });
    }
  };

  const resetPassword = () => {
    if (passwordData.oldPassword === "") {
      Toast.warning("기본 비밀번호를 입력해주세요");
      return;
    }
    if (passwordData.password === "" || !validation.password) {
      Toast.warning("비밀번호를 정확히 입력해주세요");
      return;
    }
    if (passwordData.confirmPassword === "" || !validation.confirmPassword) {
      Toast.warning("새로운 비밀번호를 확인해주세요");
      return;
    }

    AccountApis.updateStudentPassword(userInfo.orgId, userInfo.studentId, {
      oldPassword: passwordData.oldPassword,
      password: passwordData.password
    })
      .then(res => {
        Toast.success("비밀번호 수정완료");
        console.log(res);
        toggleResetPassword(!openResetPassword);
        setPasswordForm({
          oldPassword: "",
          password: "",
          confirmPassword: ""
        });
        setValidation({
          password: null,
          confirmPassword: null
        });
        setIsLoading(true);
      })
      .catch(e => {
        console.log(e);
        Toast.warning("비밀번호 변경에 실패하였습니다. 다시 시도해주세요");
        setIsLoading(true);
      });
  };

  const onFilesAdded = files => {
    const file = files[0];
    if (file.size > 10000000) {
      Toast.warning("최대 파일 크기는 10M입니다.");
      return;
    }
    if (!isImage(file)) {
      Toast.warning("이미지만 추가할수 있습니다.");
      return;
    }
    setIsLoading(true);
    const fileData = new FormData();
    fileData.append("file", file);

    axios
      .post(`${getHost()}/blob/v2/contents`, fileData, {
        ...getDefaultConfig()
      })
      .then(fileRes => {
        const contentId = fileRes.data.contentId;
        axios
          .put(
            `${getHost()}/academy/v2/accounts/${userInfo.userId}/students/${
              userInfo.studentId
            }/profile?contentId=${contentId}`,
            null,
            {
              ...getDefaultConfig()
            }
          )
          .then(response => {
            Toast.success("프로필 수정 완료.");
            onUpdateUserInfo();
            setIsLoading(false);
          })
          .catch(error => {
            Toast.warning(
              "프로필 수정에 실패했습니다. 잠시 후 다시 시도해주세요."
            );
            setIsLoading(false);
          });
      })
      .catch(error => {
        Toast.warning("프로필 수정에 실패했습니다. 잠시 후 다시 시도해주세요.");
        setIsLoading(false);
      });
  };

  return (
    <>
      <HomeHeader lectures={lectures} />
      <div className="myprofile-layout">
        <div className="myprofile-layout-wrap">
          <div className="ht-title">나의 정보</div>
          <div className="imgarea">
            <div className="img">
              <input
                id={"profileImage"}
                type="file"
                onChange={e => onFilesAdded(e.target.files)}
              />
              <label htmlFor="profileImage"></label>
              {userInfo.profileImage && (
                <img
                  src={getProfileUrl(userInfo.profileImage.contentId)}
                  alt={userInfo.name}
                />
              )}
            </div>
            <p>
              {userInfo.name}{" "}
              <span className="u-color-blue">{`(${userInfo.account})`}</span>
            </p>
          </div>
          <div className="textarea">
            <button
              className={classNames("classmode-btn", {
                "is-active": openResetPassword
              })}
              onClick={() => {
                handlePasswordForm();
              }}
            >
              {openResetPassword ? "비밀번호 변경완료" : "비밀번호 변경하기"}
            </button>
            <table>
              <tbody>
                <tr>
                  <th>비밀번호</th>
                  <td>******</td>
                </tr>
                {/** block 클래스 , is-true, is-false*/}
                {openResetPassword && (
                  <>
                    <tr className={classNames("l-inputwrap pw-chk")}>
                      <th></th>
                      <td>
                        <div
                          className={classNames("inputwrap", {
                            // "is-true": validation.password !== "",
                            // "is-false": validation.password === false
                          })}
                        >
                          <input
                            type="password"
                            name="oldPassword"
                            value={passwordData.oldPassword}
                            onChange={handleInputChange}
                            required="required"
                            placeholder="기존 비밀번호를 입력하세요"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className={classNames("l-inputwrap pw-chk")}>
                      <th></th>
                      <td>
                        <div
                          className={classNames("inputwrap", {
                            "is-true": validation.password !== null,
                            "is-false": validation.password === false
                          })}
                        >
                          <input
                            type="password"
                            name="password"
                            value={passwordData.password}
                            onChange={handleInputChange}
                            required="required"
                            placeholder="새로운 비밀번호를 입력하세요"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className={classNames("l-inputwrap pw-chk-repeat")}>
                      <th></th>
                      <td>
                        <div
                          className={classNames("inputwrap", {
                            "is-true": validation.confirmPassword !== null,
                            "is-false": validation.confirmPassword === false
                          })}
                        >
                          <input
                            type="password"
                            name="confirmPassword"
                            required="required"
                            value={passwordData.confirmPassword}
                            onChange={handleInputChange}
                            placeholder="새로운 비밀번호를 확인해주세요"
                          />
                          <span className="false-ment">
                            새 비밀번호와 일치하지 않습니다.
                          </span>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <th>생년월일</th>
                  {userInfo.birthday && (
                    <td>{`${userInfo.birthday.year}.${userInfo.birthday.month}.${userInfo.birthday.day}`}</td>
                  )}
                </tr>
                <tr>
                  <th>주소</th>
                  <td>
                    {`${userInfo.address.address1 || ""} ${userInfo.address
                      .address2 || ""}`}
                  </td>
                </tr>
                <tr>
                  <th>부모님연락처</th>
                  <td>{userInfo.parentMobile}</td>
                </tr>
                <tr>
                  <th>학생연락처</th>
                  <td>{userInfo.mobile}</td>
                </tr>
                <tr>
                  <th>학교(학년)</th>
                  <td>{`${userInfo.grade}학년`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
