import React, { Component } from "react";
import correctAudioFile from "./assets/correct.mp3";
import failAudioFile from "./assets/fail.mp3";
import ResultToastCoreect from "../BodyMovin/Animation/ResultToastCorrect";
import ResultToastFail from "../BodyMovin/Animation/ResultToastFail";
import { FormattedMessage } from "react-intl";

const locale = process.env.REACT_APP_LOCALE || "ko";
class ResultToast extends Component {
  state = {
    visible: true,
    timeout: null,
    playAudio: true,
  };

  componentDidMount() {
    const { correct, combo } = this.props;
    // this.playAudio(correct || combo);

    this.setState({
      timeout: setTimeout(() => {
        this.setState({
          timeout: null,
          visible: false,
          // playAudio: false,
        });
      }, 3000),
    });
  }

  playAudio = (correct) => {
    const { playAudio } = this.state;
    const { correctAudio, failAudio } = this.refs;

    if (playAudio) {
      this.setState(
        {
          playAudio: false,
        },
        () => {
          this.audioRef.play();

          /*
        if (correct) {
          console.log('play correct')
          // const correctSound = new Audio(correctAudioFile);
          // correctSound.play().catch(e => {
          //   console.log('failed to play audio', e)
          // });;
          correctAudio.play().catch(e => {
            console.log('failed to play audio', e)
          });
        } else {
          console.log('play fail')
          // const failSound = new Audio(failAudioFile);
          // failSound.play().catch(e => {
          //   console.log('failed to play audio', e)
          // });;
          failAudio.play().catch(e => {
            console.log('failed to play audio', e)
          });
        }
        */
        }
      );
    }
  };

  componentWillUnmount() {
    const { timeout } = this.state;
    if (timeout != null) {
      clearTimeout(timeout);
    }
  }

  render() {
    const { correct, combo } = this.props;
    const { visible } = this.state;

    if (!visible) {
      return <div />;
    }

    // this.playAudio(correct || combo)
    // alert(correct === true ? "correct" : "fail");

    return (
      <>
        <div
          className={
            " resultToast show-from-bottom " +
            (correct === true ? "correct" : "fail")
          }
        >
          <div className="resultToast-wrap">
            <p>
              {correct === true ? (
                <FormattedMessage id="classroom.correct" />
              ) : (
                <FormattedMessage id="classroom.incorrect" />
              )}
            </p>
            <span>
              {correct === true ? (
                <FormattedMessage id="classroom.correct-desc" />
              ) : (
                <FormattedMessage id="classroom.incorrect-desc" />
              )}
            </span>
            {correct === true ? <ResultToastCoreect /> : <ResultToastFail />}
            {/*
          <span>정말 대단한걸</span>
          */}
            {/* {correct ? <audio src={correctAudioFile} ref={(i) => { this.audioRef = i }} />
          : <audio src={failAudioFile} ref={(i) => { this.audioRef = i }} />} */}
          </div>
        </div>
      </>
    );
  }
}

export default ResultToast;
