import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tag, Table, Divider, message, Popconfirm, Icon } from "antd";
import { Button } from "antd/lib/radio";
import AccountApis from "../../../utils/accountApis";
import ChangeStudentClassPopup from "./Popup/ChangeStudentClassPopup";
import ResetStudentPasswordPopup from "./Popup/ResetStudentPasswordPopup";
import { ConstantsContext } from "utils/ConstantsContext";
const isDev = process.env.REACT_APP_TEST === "true";
class StudentList extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      showChangeClassPopup: null,
      showResetStudentPasswordPopup: null,
      studentList: [],
    };
  }

  componentDidMount() {
    this.setState({
      studentList: this.props.studentList,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studentList !== this.props.studentList) {
      this.setState({
        studentList: this.props.studentList,
      });
    }
  }

  render() {
    const { hasStudentEditPermission } = this.props;
    const checkNewDays = 1000 * 60 * 60 * 24 * 1;
    const buttonStyle = { margin: '-10px 0px',
    };
    let columns = [
      {
        title: "이름",
        key: "name",
        dataIndex: "name",
        render: (text, record) => (
          <Link
            to={{
              pathname: `/admin/student/detail/${record.studentId}`,
              searchResult: this.props.searchOptions,
            }}
          >
            <span>{text}</span>
            {(record.createdTime && new Date() < new Date(record.createdTime + checkNewDays)) &&
               <> <Tag color="#f50">NEW</Tag></>}
          </Link>
        ),
      },
      {
        title: "나이",
        key: "age",
        dataIndex: "birthday",
        render: (birthday) => <span>{birthday && birthday.age}</span>,
      },
      {
        title: "학교",
        key: "school",
        dataIndex: "school",
        render: (text) => <span>{text}</span>,
      },
      // {
      //   title: "학년",
      //   key: "grade",
      //   dataIndex: "grade",
      //   render: (text) => <span>{text}학년</span>,
      // },
      {
        title: "아이디",
        key: "account",
        dataIndex: "account",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "학부모 연락처",
        key: "parentMobile",
        dataIndex: "parentMobile",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "학부모 이메일",
        key: "parentEmail",
        dataIndex: "parentEmail",
        render: (text) => <span>{text}</span>,
      },
      {
        title: `${this.context.guideName}`,
        key: "guide",
        dataIndex: "classroom",
        render: (classroom) => {
          return (
            <span>
              {classroom && classroom.guide ? classroom.guide.name : "없음"}
            </span>
          );
        },
      },
      {
        title: `${this.context.className} 정보`,
        key: "classroom",
        dataIndex: "classroom",
        render: (classroom) => {
          return classroom? <span>{classroom.name}</span> : <span style={{ color: 'red', fontWeight: 'bold' }}>**없음**</span>;
        },
      },
    ];

    if (hasStudentEditPermission) {
      columns.push({
        title: "학습관리",
        key: "action",
        render: (text, record) => (
          <span style={{ whiteSpace: 'nowrap' }}>
          {/*
            <Button
              onClick={() => {
                this.setState({
                  showChangeClassPopup: record.studentId,
                });
              }}
              style={{ ...buttonStyle }}
            >
              {`${this.context.className}변경`}
            </Button>
            {this.state.showChangeClassPopup === record.studentId && (
              <ChangeStudentClassPopup
                title={`${this.context.className} 정보 변경`}
                visible={this.state.showChangeClassPopup === record.studentId}
                maskClosable={false}
                onSelect={(classroom) => {
                  console.log(classroom);
                  if (classroom) {
                    // this.updateGuide(classroom);
                    AccountApis.changeStudentClass(
                      this.props.user.orgId,
                      record.studentId,
                      classroom.classroomId
                    )
                      .then((res) => {
                        console.log(" 수정 완료: ", res);
                        this.props.onUpdateStudentInfo();
                      })
                      .catch((e) => {
                        console.log(e.response);
                        console.log(e.response.data);
                        if (
                          e.response &&
                          e.response.data &&
                          e.response.data.code === 403000
                        ) {
                          message.error("같은 코스으로만 변경 가능합니다.");
                        } else {
                          message.error("변경 실패");
                        }
                      });
                  }
                  this.setState({
                    showChangeClassPopup: null,
                  });
                }}
                onCancel={() => {
                  this.setState({
                    showChangeClassPopup: null,
                  });
                }}
                studentData={record}
                classroomList={this.props.classroomList}
              />
            )}
            <>{' '}
            <Button
              style={{ ...buttonStyle }}
              onClick={() => {
                this.setState({
                  showResetStudentPasswordPopup: record.studentId,
                });
              }}
            >
              비번리셋
            </Button>
            </>
          */}
            {this.context.type === "campus" && record.classroom && (
              <>{' '}
              <Button style={{ ...buttonStyle }}>
                <Link
                  to={{
                    pathname: `/admin/student/detail/${record.studentId}/report/0`,
                    searchResult: this.props.searchResult,
                  }}
                >
                  학습리포트
                </Link>
              </Button>
              </>
            )}
            {this.context.type === "school" &&
              this.props.hasCreateDeletePermission && (
                <Popconfirm
                  title={`정말로 삭제 하시겠습니까?`}
                  onConfirm={() => {
                    AccountApis.deleteStudent(
                      this.props.user.orgId,
                      record.studentId
                    )
                      .then((res) => {
                        message.success("삭제 완료");
                        this.props.onUpdateStudentInfo();
                      })
                      .catch((e) => {
                        message.warning("삭제 실패");
                      });
                  }}
                  onCancel={() => message.error("삭제 취소")}
                  okText="삭제"
                  placement="topRight"
                  cancelText="취소"
                >
                  <Button style={{ ...buttonStyle }}>삭제</Button>
                </Popconfirm>
              )}
            {this.state.showResetStudentPasswordPopup === record.studentId && (
              <ResetStudentPasswordPopup
                title={`비밀번호 초기화하기`}
                visible={
                  this.state.showResetStudentPasswordPopup === record.studentId
                }
                orgType={this.context.type}
                maskClosable={false}
                handleOk={(password) => {
                  AccountApis.resetStudentPassword(
                    this.props.user.orgId,
                    record.studentId,
                    password
                  )
                    .then((res) => {
                      message.success("초기화 완료");
                      this.props.onUpdateStudentInfo();
                    })
                    .catch((e) => {
                      message.warning("초기화 실패");
                    });
                }}
                onCancel={() => {
                  this.setState({
                    showResetStudentPasswordPopup: null,
                  });
                }}
                studentData={record}
              />
            )}
          </span>
        ),
      });
    }

    const data = this.props.studentList;
    console.log("data: ", data);

    return (
      <Table
        rowKey="studentId"
        loading={this.props.loading}
        dataSource={this.state.studentList}
        columns={columns}
        onChange={this.props.onPageChange}
        pagination={this.props.pagination}
      />
    );
  }
}

export default StudentList;
