import React, { Component } from "react";
import Axios from "../../../utils/request";
import { Button, Input, Skeleton } from "antd";
import { getHost } from "../../../utils/APIUtils";
import SockJsClient from "react-stomp";
import { FormattedMessage } from "react-intl";
import { formatText } from "../../../components/Quiz/utils/Formatter";
import StorytellingAnimation from "./StorytellingAnimation/StorytellingAnimation";

class Storytelling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storytelling: [],
      isLoading: false,
      pageIdx: 0,
      connected: false,
      title: null,
      point: null
    };
  }

  componentDidMount() {
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.addEventListener("message", this.handleQuizBroadcast);
    }
    this.init();
  }

  componentWillUnmount() {
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.removeEventListener("message", this.handleQuizBroadcast);
    }
    if (this.state.connected) {
      this.onDisconnect();
    }
  }

  onMessageReceive = (msg, topic) => {
    console.log("receive msg: ", msg);
    if (msg.type === "remote") {
      this.handleBroadcast(msg.data);
    }
  };

  init = () => {
    this.load();
  };

  load = () => {
    const { data } = this.props;
    console.log(data);

    this.setState({
      isLoading: true,
    });

    Axios.get(`/content/v2/storytellings/${data.storytellingId}`).then((e) => {
      this.setState({
        title: e.title,
        point: e.point,
        storytelling: e.storytellingViewList,
        isLoading: false,
      });
    });
  };

  onConnect = () => {
    this.setState({
      connected: true,
    });
  };

  onDisconnect = () => {
    this.setState({
      connected: false,
    });
  };

  handleBroadcast = (res) => {
    switch (res.type) {
      case "movePage":
        if (this.props.viewType !== "guide")
          this.setState({
            pageIdx: res.data,
          });
        break;
    }
  };

  nextPage = () => {
    const { storytelling, pageIdx } = this.state;
    if (storytelling) {
      if (storytelling.length - 1 != pageIdx) {
        this.setState(
          {
            pageIdx: pageIdx + 1,
          },
          () => {
            if (this.props.viewType === "guide") {
              this.props.onSendMessage({
                type: "movePage",
                data: this.state.pageIdx,
              });
            }
          }
        );
      }
    }
  };

  beforePage = () => {
    const { storytelling, pageIdx } = this.state;
    if (storytelling) {
      if (pageIdx != 0) {
        this.setState(
          {
            pageIdx: pageIdx - 1,
          },
          () => {
            if (this.props.viewType === "guide") {
              this.props.onSendMessage({
                type: "movePage",
                data: this.state.pageIdx,
              });
            }
          }
        );
      }
    }
  };

  render() {
    const { viewType, classroomId, curPage } = this.props;
    const { storytelling, isLoading, pageIdx, quetureTitle } = this.state;

    console.log(storytelling);

    if (curPage === 0 && viewType === "preview") {
      return <StorytellingAnimation title={this.state.point} />;
    }

    return (
      <div className={"quetureranking-layout"} style={{}}>
        {curPage === 0 ? (
          <>
            <div
              className="small-title"
              style={{
                // position: "absolute",
                // left: "50%",
                // top: "40%",
                // transform: "translate(-50%, 0)",
              }}
            >
              {this.state.title} <FormattedMessage id={"classroom.storytellingTitle"} />
            </div>
            <div className="big-title u-color-red">
              {this.state.point}
            </div>{" "}
          </>
        ) : curPage === 1 ? (
          <Skeleton loading={isLoading} active>
            {viewType === "guide" && (
              <div>
                <div
                  className={"num"}
                  style={{
                    textAlign: "center",
                    fontSize: "1.2vw",
                    fontWeight: 400,
                  }}
                >
                  {pageIdx + 1}/{storytelling.length}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "800px",
                    height: "500px",
                    margin: "0px",
                    alignContent: "center",
                    verticalAlign: "middle",
                    display: "table-cell",
                  }}
                >
                  <img
                    style={{ maxHeight: "450px" }}
                    src={storytelling[pageIdx]?.content?.signedUrl}
                  />
                </div>
                <span>
                  <div
                    style={{
                      backgroundColor: "rgba(0,0,0,0.06)",
                      marginBottom: "10px",
                      width: "800px",
                      height: "200px",
                      border: "5px",
                      borderRadius: "4px",
                      padding: "8px",
                      overflowY: "scroll",
                    }}
                  >
                    <span
                      style={{
                        maxWidth: "1000px",
                        maxHeight: "1000px",
                        height: "400px",
                        margin: "5px",
                        fontSize: "18px",
                        fontWeight: 400,
                      }}
                    >
                      {formatText(storytelling[pageIdx]?.body, pageIdx, null)}
                    </span>
                  </div>
                </span>
                {storytelling.length - 1 !== pageIdx && (
                  <Button
                    onClick={this.nextPage}
                    style={{
                      background: "#55a3ff",
                      color: "#fff",
                      borderRadius: 4,
                      marginBottom: 8,
                      padding: 8,
                      float: "right",
                      right: "-60px",
                      bottom: "120px",
                    }}
                  >
                    다음
                  </Button>
                )}
                {pageIdx !== 0 && (
                  <Button
                    color={"success"}
                    onClick={this.beforePage}
                    style={{
                      background: "#55a3ff",
                      color: "#fff",
                      borderRadius: 4,
                      marginBottom: 8,
                      padding: 8,
                      float: "left",
                      left: "-60px",
                      bottom: "120px",
                    }}
                  >
                    이전{" "}
                  </Button>
                )}
              </div>
            )}
            {viewType === "preview" && (
              <div
                style={{
                  width: "100vw",
                  height: "100vh",
                  verticalAlign: "middle",
                  textAlign: "center",
                  background: "black",
                }}
              >
                <img
                  style={{
                    height: "720px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={storytelling[pageIdx]?.content?.signedUrl}
                />
              </div>
            )}
            <SockJsClient
              // ref={e => (this.clientRef = e)}
              url={`${getHost()}/academy/controller`}
              topics={[
                `/topic/${classroomId}/all`,
                // `/topic/${classroomId}/users/${user.userId}`,
                // `/topic/${classroomId}/${user.type}`
              ]}
              onConnect={this.onConnect}
              onDisconnect={this.onDisconnect}
              onMessage={this.onMessageReceive}
              debug={false}
            />
          </Skeleton>
        ) : null}
      </div>
    );
  }
}

export default Storytelling;
