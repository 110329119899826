import React, { Component } from 'react';

class InputPad extends Component {

  render() {
    const { index, padKey, prevValue, onInputPadChange } = this.props;

    return (
      <div className="t-calc">
        <div className="t-calc__wrap">
          <div className="t-calc__num">
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '1' : '1')} type="button">1</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '2' : '2')} type="button">2</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '3' : '3')} type="button">3</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '4' : '4')} type="button">4</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '5' : '5')} type="button">5</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '6' : '6')} type="button">6</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '7' : '7')} type="button">7</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '8' : '8')} type="button">8</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '9' : '9')} type="button">9</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '.' : '.')} type="button">.</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '0' : '0')} type="button">0</button>
            <button onClick={() => onInputPadChange(prevValue ? prevValue + '-' : '-')} type="button">-</button>
          </div>
          <div className="t-calc__submit">
            <button onClick={() => onInputPadChange('clear')} type="button" className="t-calc__submit-del">지우기</button>
            <button onClick={() => onInputPadChange('close')} type="button" className="t-calc__submit-ok">확인</button>
          </div>
        </div>
      </div>
    )
  }
}

export default InputPad;
