import React, { useState, useEffect } from "react";
import Queturepoint from "./Queturepoint";
import QueturePlayer from "../../../components/QueturePlayer/QueturePlayer";
import QuetureView from "./QuetureView";
import axios from "axios";
import { getHost, getDefaultConfig } from "../../../utils/APIUtils";
import { Skeleton } from "antd";

const SummaryQueture = props => {
  const [isLoading, setLoading] = useState(false);
  const [quetureList, setQuetureList] = useState([]);
  const { onChangeSummaryIndex, curPage, quetureIds, oldPage, setOldPage } = props;
  useEffect(() => {
    setLoading(true);
    const quetureReqList = [];
    quetureIds.map(quetureId => {
      quetureReqList.push(
        axios.get(`${getHost()}/content/v2/quetures/${quetureId}`, {
          ...getDefaultConfig()
        })
      );
    });
    axios.all(quetureReqList).then(
      axios.spread((...responses) => {
        console.log("queture responses: ", responses);
        const _quetureList = [];
        responses.map(res => {
          if (res.data) {
            _quetureList.push(res.data);
          }
        });
        setLoading(false);
        setQuetureList(_quetureList);
        console.log(_quetureList);
      })
    );
  }, [quetureIds]);
  let isReLoad = false;
  if (curPage != oldPage) {
    isReLoad = true;
    setOldPage(curPage);
  }
  return (
    <Skeleton loading={isReLoad} active>
      <QuetureView
        type={"summary"}
        quetureList={quetureList}
        quetureData={quetureList.length > 0 ? quetureList[curPage] : null}
        {...props}
      />
    </Skeleton>
  );
};

export default SummaryQueture;
