import React from 'react'
import classNames from 'classnames';
import { formatText } from '../utils/Formatter';
import { Image } from '.';

const OrderablePreview = ({ choice }) => {
  return (
    <ul className="t-answer-ordering-box">
      <li className={classNames(
        "is-chk",
        'order-' + (choice.seq + 1),
      )}>
        <span className="choice-box">
          <span>
            {
              choice.data.image ?
                <Image image={choice.data.image} />
                :
                <span>{formatText(choice.data.answer)}</span>
            }
          </span>
        </span>
      </li>
    </ul>
    // <div className={"orderable s0"} style={{ display: "inline-block", background: 'cyan' }}>
    //     <span className="answer">{choice.answer}</span>
    // </div>
  )
}

export default OrderablePreview;
