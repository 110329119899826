import React, { useState } from "react";
import {
  Select,
  Slider,
  Button,
  Icon,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";

const RemoteVideoController = (props) => {
  let CHANNEL_CODE = "REMOCON_CHANNEL";
  let listenChannel = new BroadcastChannel(CHANNEL_CODE);

  const { isRemote, onSendMessage, isQuebonSong } = props;

  const [data, setData] = useState({
    progress: 0,
    time: 0,
    volume: 80,
    mute: false,
    speed: 1,
    resolutions: [],
    autoResolution: true,
    currentResolution: null,
    resolutionName: null,
  });

  function handleRemocon(type, value = "") {
    listenChannel.postMessage({
      type,
      value,
    });
  }

  function togglePlay() {
    const _data = {
      type: "togglePlay",
      value: "",
    };
    if (isRemote) {
      onSendMessage(_data);
    }
    listenChannel.postMessage(_data);
  }

  function handleSynce() {
    const _data = {
      type: "asyncSetting",
      value: "",
    };

    if (isRemote) {
      onSendMessage(_data);
    }
    listenChannel.postMessage(_data);
  }

  function hanldeReply() {
    const _data = {
      type: "replay",
      value: "",
    };

    if (isRemote) {
      onSendMessage(_data);
    }
    listenChannel.postMessage(_data);
  }

  function handleProgress(sec) {
    const _data = {
      type: "progress",
      value: sec,
    };
    if (isRemote) {
      onSendMessage(_data);
    }
    listenChannel.postMessage(_data);
  }

  function handleChangeSpeed(value) {
    console.log("change speed", value);
    setData({
      ...data,
      speed: value,
    });
    // new BroadcastChannel("REMOCON_CHANNEL").postMessage({
    //   type: "speed",
    //   value: value
    // });
    const _data = {
      type: "speed",
      value: value,
    };
    if (isRemote) {
      onSendMessage(_data);
    }
    listenChannel.postMessage(_data);
  }

  function handleChangeProgress(value) {
    console.log("handle change progress: ", value);

    let _value = value;
    if (_value < 0) {
      _value = 0;
    }
    if (_value > 100) {
      _value = 100;
    }

    setData({
      ...data,
      volume: _value,
    });
    const _data = {
      type: "volume",
      value: _value,
    };
    if (isRemote) {
      onSendMessage(_data);
    }
    listenChannel.postMessage(_data);
  }
  function handleAfterChangeProgress(value) {
    console.log("handle after change progress: ", value);
  }
  const handleKeyDown = (e) => {
    console.log("keyCode: ", e.keyCode);
    switch (e.keyCode) {
      case 32:
        togglePlay();
        break;
      case 37:
        handleProgress(-15);
        break;
      case 39:
        handleProgress(15);
        break;
      case 38:
        // up
        handleChangeProgress(data.volume + 10);
        break;
      case 40:
        // down
        handleChangeProgress(data.volume - 10);
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{ minWidth: 600, /*paddingTop: 0,*/ paddingBottom: 24 }}
      tabIndex="1"
      onKeyDown={(e) => handleKeyDown(e)}
    >
      <Typography.Title level={4}>
        {isQuebonSong ? "깨봉송 " : isRemote ? "홈스쿨링 " : ""}리모콘
      </Typography.Title>
      {isQuebonSong ? (
        <Row>
          <Col style={{display: "flex", gap: 8}}>
            <Button type="primary" onClick={() => handleRemocon("play")}>
              Play
            </Button>
            <Button type="primary" onClick={() => handleRemocon("pause")}>
              Pause
            </Button>
            <Button type="primary" onClick={() => handleRemocon("replay")}>
              Replay
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={12} style={{ paddingBottom: 8 }}>
            <Button type="primary" onClick={togglePlay}>
              Play / Pause
            </Button>
            <Button
              type="primary"
              onClick={handleSynce}
              style={{
                marginLeft: 8,
                backgroundColor: "#ff5900",
                borderColor: "#ff5900",
              }}
            >
              Resync
            </Button>
            <Button
              type="primary"
              onClick={hanldeReply}
              style={{ marginLeft: 8 }}
            >
              Replay
            </Button>
          </Col>
          <Col span={12} style={{ paddingBottom: 8 }}>
            <Button.Group>
              <Button type="primary" onClick={() => handleProgress(-10)}>
                <Icon type="left" />
                Backward
              </Button>
              <Button type="primary" onClick={() => handleProgress(10)}>
                Forward
                <Icon type="right" />
              </Button>
            </Button.Group>
          </Col>
          <Col span={12} style={{ paddingBottom: 8 }}>
            <div>
              <span style={{ fontSize: 16, marginRight: 8 }}>
                Volume: {data.volume}
              </span>
              <Slider
                value={data.volume}
                onChange={handleChangeProgress}
                onAfterChange={handleAfterChangeProgress}
              />
            </div>
          </Col>
          <Col span={12} style={{ paddingBottom: 8 }}>
            <span style={{ fontSize: 16, marginRight: 8 }}>Speed:</span>
            <Select
              defaultValue="1.0"
              value={data.speed}
              onChange={handleChangeSpeed}
            >
              <Select.Option value="0.8">0.8</Select.Option>
              <Select.Option value="1.0">1.0</Select.Option>
              <Select.Option value="1.2">1.2</Select.Option>
              <Select.Option value="1.5">1.5</Select.Option>
              <Select.Option value="2.0">2.0</Select.Option>
            </Select>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default RemoteVideoController;
