import React, { Component } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import noScroll from "no-scroll";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as popupActions from "store/modules/popup";
Modal.setAppElement("#root");

const locale = process.env.REACT_APP_LOCALE || "ko";

class Popup extends Component {
  componentDidMount() {
    const { PopupActions } = this.props;
    if (this.props.isOpen) {
      noScroll.on();
      PopupActions.handlePopup(true);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { PopupActions } = this.props;
    if (!this.props.isOpen && nextProps.isOpen) {
      noScroll.on();
      PopupActions.handlePopup(true);
    } else if (this.props.isOpen && !nextProps.isOpen) {
      noScroll.off();
      PopupActions.handlePopup(false);
    }
  }

  componentWillUnmount() {
    const { PopupActions } = this.props;
    PopupActions.handlePopup(false);
    noScroll.off();
  }

  render() {
    const { children, className, isOpen, onClose, type ,isLower} = this.props;

    let popupClass = classNames("popup", className, {
      en: locale === "en"
    },{
      'newsletter share': type === 'newsletter share'
    });

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        overlayClassName={classNames("overlay", {
          "lower": true
        })}
        className={popupClass}
      >
        <div className="popup-wrap">
          <div className="popup-content" >
            {children}
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, dispatch => ({
  PopupActions: bindActionCreators(popupActions, dispatch)
}))(Popup);
