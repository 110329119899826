import React, { Component, PureComponent } from 'react';
import { formatText } from './utils/Formatter';
import {
  MultiChoiceQuiz,
  DragTextQuiz,
  PairingQuiz,
  TextSelectQuiz,
  FillBlankQuiz,
  ImageSelectQuiz,
  TrueFalseQuiz,
  MarkSentenceQuiz,
  OrderingQuiz,
  RangeQuiz,
  CompareQuiz,
  InlineMultiChoiceQuiz,
  DesmosQuiz,
  CountingQuiz,
  ColoringQuiz,
} from "./";
import { ReportButton } from './modules';
import classNames from 'classnames';
import QuizFrame from './components/QuizFrame';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import QuizImage from './components/QuizImage';
import QuizProblem from './components/QuizProblem';
import QuizTitle from './components/QuizTitle';
import { DragDropContext } from 'react-beautiful-dnd';
import update from "immutability-helper";
import Toast from 'components/Toast/Toast';
import { actionLog } from 'utils/LogUtils';
import Desmos from './components/Desmos';
import { Button } from "antd";

const isDev = process.env.REACT_APP_TEST === 'true';

class QuizView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      quizId: null,
      answers: null,
      isDragging: false
    }
  }

  init = data => {
    const { quizId, quiz, timestamp } = data;
    const { problem, choices } = quiz;

    const { stepQuizAnswers } = this.props;

    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      const stepAnswersMap = [];
      stepQuizAnswers.map(a => {
        if (a) {
          const answer = choices.find(c => c.key === a);
          stepAnswersMap.push(answer);
        }
      });
      this.setState({
        quizId: quizId,
        answers: stepAnswersMap
      }, () => {
        // this.updateProblem(this.props);
      });
    } else {
      // if (answers == null) {
      let answerCount = 0;
      const reg = /\{\{([^\{^}]*)}}/gm;
      let r;
      while ((r = reg.exec(problem))) {
        const val = r[1].trim();
        if (val.length === 0) {
          answerCount++;
        }
      }
      this.setState({
        quizId: quizId,
        answers: new Array(answerCount).fill(null),
      }, () => {
        actionLog("quiz", "init", {
          quizId: quizId,
          type: quiz.type,
          answers: this.state.answers
        });
      });
    }
  };

  handleDrop = (index, item) => {
    const { onAnswerUpdated, onUpdatingAnswer } = this.props;

    console.log('drop', index, item)
    this.setState({
      isDragging: false
    })

    let _answers = [];
    this.state.answers.map(a => {
      _answers.push(a)
    });
    _answers[index] = item;
    const answers = _answers.map(a => (a != null ? a.key : ""));

    onAnswerUpdated(answers);
  };

  onDragEnd = async (result) => {
    const { data, onUpdatingAnswer } = this.props;
    const { quizId, quiz } = data;
    const { title, problem, type, choices } = quiz;
    // the only one that is required
    console.log('quizType: ', type);
    console.log('drag end: ', result);
    console.log('quiz: ', quiz);
    // Toast.success('drag end')
    if (!result.destination || (result.destination && result.destination.droppableId.startsWith('droppable-from'))) {
      return;
    }
    await onUpdatingAnswer(true);
    const index = result.destination.index;
    const sourceIndex = result.source.index;
    const item = choices[sourceIndex];

    this.handleDrop(index, item)
  };

  onDragStart = (result) => {
    console.log('drag start: ', result);
    // Toast.success('drag start')
    this.setState({
      isDragging: true
    })
  }

  onDragUpdate = (result) => {
    console.log('drag update: ', result);
    // Toast.success('drag update')
    if (!result.destination || (result.destination && result.destination.droppableId.startsWith('droppable-from'))) {
      return;
    }
  }


  componentDidMount() {
    const { data } = this.props;

    if (data != null && data.quiz.type === 'drag_text') {
      this.init(data);
    }
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      if (data.quiz.type === 'drag_text') {
        if (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId) {
          this.init(data);
          console.log('3');
        }
      } else {
        this.setState({
          quizId: null,
          answers: null,
          isDragging: false
        })
      }
    }

    if (this.props.answers !== prevProps.answers && data.quiz.type === 'drag_text' && this.props.answers && this.props.answers.length > 0) {
      let _answers = [];
      this.props.answers.map(a => {
        if (a) {
          const answer = data.quiz.choices.find(c => c.key === a);
          _answers.push(answer);
        } else {
          _answers.push(null)
        }
      });
      this.setState({
        answers: _answers
      })
    }
  }

  componentWillUnmount() {
    this.setState({
      quizId: null,
      answers: null,
      isDragging: false
    })
  }


  render() {
    const { data, isMobileSize, step, answers, solved, quizType, learnType, stepQuizAnswers, forWorkbook, readOnly } = this.props;
    const { quizId, quiz } = data;
    const { title, problem, type, image, imageMobile, anim, textsOnImage } = quiz;
    const hasImage = data && data.quiz && data.quiz.image;
    const hasProblem = data && data.quiz && data.quiz.problem;
    const { layout } = queryString.parse(this.props.location.search);

    const _layout = (data.quiz && data.quiz.layout) || layout;

    let layoutType = 'basic';
    let isLong = false;

    if (_layout) {
      const layoutArr = _layout.split('-');
      layoutType = layoutArr[0];
      isLong = layoutArr.length > 1;
    }

    console.log('this.state.answers: ', this.props.answers);

    if (type === 'drag_text') {
      return (
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        // onDragUpdate={this.onDragUpdate}
        >
          <QuizFrame
            hasImage={hasImage}
            hasProblem={hasProblem}
            layout={isMobileSize ? 'basic' : layoutType}
            isLong={isLong}
            data={data}
            isMobileSize={isMobileSize}
            choiceView={this.makeChoiceView}
            imageView={this.makeImageView}
            problemView={this.makeProblemView}
            titleView={this.makeTitleView}
            forWorkbook={forWorkbook}
            readOnly={readOnly}
          />
        </DragDropContext>
      )
    } else {
      return (
        <QuizFrame
          hasImage={hasImage}
          hasProblem={hasProblem}
          layout={isMobileSize ? 'basic' : layoutType}
          isLong={isLong}
          data={data}
          isMobileSize={isMobileSize}
          choiceView={this.makeChoiceView}
          imageView={this.makeImageView}
          problemView={this.makeProblemView}
          titleView={this.makeTitleView}
          forWorkbook={forWorkbook}
          readOnly={readOnly}
        />
      )
    }
  }

  makeTitleView = () => {
    const { data, learnType, status, isEng, currentQuizIndex, forWorkbook,viewType ,quizType,solvedData,solveQuiz} = this.props;
    const { quizId, quiz, tags, correctRate } = data;
    const { title, problem, type } = quiz;

    console.log('isImportant: ', tags);
    console.log('isHard: ', correctRate);

    return <QuizTitle
      viewType={viewType}
      quizType={quizType}
      correct={solvedData.correct}
      solveQuiz={solveQuiz}
      quizId={quizId}
      title={title}
      type={type}
      isEng={isEng}
      learnType={learnType}
      status={status}
      currentQuizIndex={currentQuizIndex}
      isImportant={tags && tags.includes('중요')}
      isHard={correctRate && correctRate < 0.35}
      forWorkbook={forWorkbook}
    />
  }

  makeProblemView = () => {
    const { data, forWorkbook } = this.props;
    const { quizId, quiz } = data;
    const { title, problem, type } = quiz;

    if (problem) {
      return <QuizProblem
        onSendMessage={this.onSendMessage}
        quizId={quizId}
        isDragging={this.state.isDragging}
        dragAnswers={this.state.answers}
        title={title} problem={problem} type={type} {...this.props} />
    } else {
      return null;
    }

  }

  makeImageView = () => {
    const { data, isMobileSize, forWorkbook } = this.props
    const { quizId, quiz } = data;
    const { title, problem, vars, type, image, imageMobile, anim, textsOnImage, desmos } = quiz;

    if (type === 'image_select') {
      return null;
    }

    if (desmos && type !== 'desmos') {
      console.log('desmos')
      return <Desmos state={desmos} vars={vars} />
    }

    return <QuizImage image={image} imageMobile={imageMobile} frames={anim} texts={textsOnImage} isMobileSize={isMobileSize} forWorkbook={forWorkbook} />
  }

  makeChoiceView = () => {
    const { type, ...props } = this.props;

    console.log("quizType : " + type)
    // {...props} : data={data} solvedData={solvedData}
    // showReallyTrue={showReallyTrue} onAnswerUpdated={onAnswerUpdated}
    switch (type) {
      case "boolean":
        return (<TrueFalseQuiz {...props} />);
      case "multi_choice":
        return (<MultiChoiceQuiz {...props} />);
      case "drag_text":
        return (<DragTextQuiz {...props} />);
      case "ordering":
        return (<OrderingQuiz {...props} />);
      case "pairing":
        return (<PairingQuiz {...props} />);
      case "fill_blank":
        return null;
      // return (<FillBlankQuiz {...props} />);
      case "counting":
        return <CountingQuiz {...props} />
      case "coloring":
        return <ColoringQuiz {...props} />
      case "select_text":
        // return (<TextSelectQuiz {...props} />);
        return null
      case "image_select":
        return (<ImageSelectQuiz {...props} />);
      case "mark_sentence":
        return (<MarkSentenceQuiz {...props} />);
      case "inline":
        return (<InlineMultiChoiceQuiz {...props} />);
      case "range":
        return (<RangeQuiz {...props} />);
      case "compare":
        return (<CompareQuiz {...props} />);
      case "desmos":
        return (<DesmosQuiz {...props} />)
      default:
        break;
    }
  };
}

export default withRouter(QuizView);
