import React from 'react';
import './_selector.scss';

const ARadio = () => {
  // disable 일 경우 클래스 disable 추가, disabled 값에 disabled
  return (
    <div className="inputwrap radio disable">
      <input type="radio" name="radio" id="default-radio" disabled=""/>
      <span></span>
      <label htmlFor="default-radio">Default</label>
    </div>
  );
};

export default ARadio;