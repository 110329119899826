import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import GlobalHeader from "components/GlobalHeader";
import { Layout, Menu, Breadcrumb } from "antd";

import "./index.admin.scss";
import { authorizedRoutes } from "../router/routes";
// import renderRoutes from "../utils/renderRoutes";
import { renderRoutes } from "react-router-config";
const { Header, Content, Footer } = Layout;

const AdminLayout = props => {
  const { user, userPermission, children } = props;
  useEffect(() => {
    if (!user || !user.type) {
      props.history.replace("/login");
      return;
    }
    return () => {};
  }, [user]);

  return (
    <Layout className="layout" style={{ minHeight: "100vh" }}>
      <GlobalHeader user={user} userPermission={userPermission} />
      <Content style={{ padding: "40px 80px" }}>
        {renderRoutes(authorizedRoutes)}
      </Content>
      {/* <Footer style={{ textAlign: "center" }}>
        QUEBON Academy ©2019 Created by EQUALKEY
      </Footer> */}
    </Layout>
  );
};

export default connect(
  ({ auth }) => ({
    logged: auth.logged,
    user: auth.user,
    type: auth.user.type,
    userPermission: auth.user.type === "org" ? "master" : auth.user.permission
  }),
  null
)(AdminLayout);
