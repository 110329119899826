import React, { Component } from "react";
import { Quiz } from "components/Quiz";
import Axios from "../../../utils/request";
import { Skeleton } from "antd";
import PauseClass from "../etcpage/PauseClass";
import SockJsClient from "react-stomp";
import { getHost } from "utils/APIUtils";
import HometrainingApis from "../../../utils/hometrainingApis";
import Note from "components/Quiz/note/Note";

class PreviewQuizSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentQuiz: null,
      connected: false,
      noteOpen: false,
      remoteLines: [],
      remoteCurrentLine: [],
      remoteNote: null,
      removeLineId: null,
      receiveValue: null,
    };
  }

  componentDidMount() {
    const { quizList, userId, onQuizList } = this.props;
    const _status = [];

    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.addEventListener("message", this.handleQuizBroadcast);
    }
  }

  componentWillUnmount() {
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.removeEventListener("message", this.handleQuizBroadcast);
    }
    if (this.state.connected) {
      this.onDisconnect();
    }
  }

  onConnect = () => {
    console.log("connect from queture");
    this.setState({
      connected: true,
    });
  };

  onDisconnect = () => {
    this.setState({
      connected: false,
    });
  };

  onMessageReceive = (msg, topic) => {
    console.log("receive msg: ", msg);
    if (msg.type === "remote") {
      this.handleQuizBroadcast(msg);
    }
  };

  handleQuizBroadcast = (res) => {
    console.log("handle quiz broadca");
    const type = res.data && res.data.type;
    const value = res.data && res.data.value;
    console.log("quiz type: ", type);
    console.log("quiz value: ", value);
    if (type === "quizData") {
      this.setState({
        currentQuiz: value,
      });
    } else if (type === "loadQuiz") {
      this.load(value.quizId, value.vars);
    } else if (type === "toggleNote") {
      if (this.props.viewType !== "guide") {
        this.setState({
          noteOpen: value.noteOpen,
        });
      }
    } else if (type === "drawNote") {
      if (this.props.viewType !== "guide") {
        this.setState({
          remoteNote: value,
        });
      }
    } else if (type === "removeLine") {
      if (this.props.viewType !== "guide") {
        this.setState({
          removeLineId: value.removeId,
        });
      }
    } else if (type === "solveWithGuide") {
      if (this.props.viewType !== "guide") {
      }
    } else if (type === "sendValue") {
      if (this.props.viewType !== "guide") {
        this.setState({ receiveValue: value });
      }
    }
  };

  load = (quizId, vars) => {
    if (this.state.receiveValue != null) {
      this.setState({
        receiveValue: null,
      });
    }
    this.setState({
      isLoading: true,
    });

    console.log(
      "randomSeed" +
        (this.props.classroomId + this.props.lectureId + this.props.seq)
    );

    const _randomSeed = this.props.randomSeed || Math.floor(
      this.props.classroomId / this.props.seq +
        this.props.seq +
        this.props.lectureId / this.props.seq
    );

    HometrainingApis.loadQuiz(
      quizId,
      vars,
      _randomSeed
    ).then((response) => {
      this.setState({
        isLoading: false,
        currentQuiz: response.data,
      });
    });
  };

  solve = (quizId, answers, vars) => {
    return Axios.post(`/quiz/v2/quizzes/${quizId}/solve`, {
      answers: answers,
      vars: vars,
    }).then((response) => {
      return response;
    });
  };

  toggleNote = () => {
    this.setState({
      noteOpen: !this.state.noteOpen,
    });
    const _data = {
      type: "toggleNote",
      value: {
        noteOpen: !this.state.noteOpen,
      },
    };
    this.props.onSendMessage(_data);
  };

  solveWithGuide = (data) => {
    const _data = {
      type: "solveWithGuide",
      value: data,
    };
    this.props.onSendMessage(_data);
  };

  sendValue = (data) => {
    const _data = {
      type: "sendValue",
      value: data,
    };
    if (this.props.viewType === "guide") {
      this.props.onSendMessage(_data);
    }
  };
  render() {
    const { isLoading, currentQuiz, receiveValue } = this.state;
    console.log("preview quiz viewType: ", this.props.viewType);
    let guideStyle = {};
    if (this.props.viewType === "guide") {
      guideStyle = { marginLeft: "auto" };
    }
    return (
      <Skeleton loading={isLoading} active>
        <>
          {currentQuiz ? (
            <>
              <div style={{ width: 1024, position: "relative", ...guideStyle }}>
                <Quiz
                  receiveValue={receiveValue}
                  solveWithGuide={this.solveWithGuide}
                  data={currentQuiz}
                  solver={this.solve}
                  status={[]}
                  quizType={"admin"}
                  isPreview={true}
                  viewType={this.props.viewType}
                  classroomId={this.props.classroomId}
                  sendValue={this.sendValue}
                />
                <Note
                  isOpen={this.state.noteOpen}
                  onToggleNote={this.toggleNote}
                  onSendMessage={this.props.onSendMessage}
                  viewType={this.props.viewType}
                  remoteNote={this.state.remoteNote}
                  removeLineId={this.state.removeLineId}
                />
              </div>
            </>
          ) : (
            <PauseClass />
          )}
        </>
        <SockJsClient
          // ref={e => (this.clientRef = e)}
          url={`${getHost()}/academy/controller`}
          topics={[
            `/topic/${this.props.classroomId}/all`,
            // `/topic/${classroomId}/users/${user.userId}`,
            // `/topic/${classroomId}/${user.type}`
          ]}
          onConnect={this.onConnect}
          onDisconnect={this.onDisconnect}
          onMessage={this.onMessageReceive}
          debug={false}
        />
      </Skeleton>
    );
  }
}
export default PreviewQuizSection;
