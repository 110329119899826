import React from "react";
import "./_queture.scss";
import MediaPlayer from "../../../components/Teacher/mediaplayer/MediaPlayer";
import classNames from "classnames";
import { ConstantsContext } from "utils/ConstantsContext";

const QuetureView = props => {
  const context = React.useContext(ConstantsContext);
  return (
    <div
      className={classNames("quetureview-layout", {
        "is-full": context.type === "school"
      })}
    >
      <MediaPlayer {...props} />
    </div>
  );
};

export default QuetureView;
