import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";
import Axios from "../../utils/request";

const GUIDE_LIST = "auth/GUIDE_LIST";
const GUIDE_LIST_LOADING = "auth/GUIDE_LIST_LOADING";
const GUIDE_LIST_SUCCESS = "auth/GUIDE_LIST_SUCCESS";
const GUIDE_LIST_ERROR = "auth/GUIDE_LIST_ERROR";

function _getGuideList(orgId) {
  return Axios.get(`/academy/v2/accounts/${orgId}/guides`);
}

export const getGuideList = orgId => (dispatch, getState) => {
  console.log("get GUIDE list: ", orgId);
  dispatch({
    type: GUIDE_LIST_LOADING
  });
  return _getGuideList(orgId)
    .then(res => {
      dispatch({
        type: GUIDE_LIST_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(e => {
      dispatch({
        type: GUIDE_LIST_ERROR,
        payload: e
      });
      return e;
    });
};

const initialState = {
  pending: false,
  guideList: [],
  error: {
    triggered: false,
    code: null,
    message: null
  }
};

export default handleActions(
  {
    [GUIDE_LIST_LOADING]: (state, { payload }) => {
      return {
        ...state,
        pending: true
      };
    },
    [GUIDE_LIST_SUCCESS]: (state, { payload }) => {
      console.log("get GUIDE list success: ", payload);
      return {
        ...state,
        pending: false,
        guideList: payload
      };
    },
    [GUIDE_LIST_ERROR]: (state, { payload }) => {
      return {
        ...state,
        pending: false,
        guideList: []
      };
    }
  },
  initialState
);
