import React, { Component } from "react";
import { connect } from "react-redux";
import BoardApis from "../../../../utils/boardApis";
import {
  Skeleton,
  PageHeader,
  Typography,
  message,
  Divider,
  Button,
  Popconfirm,
  Input,
  List,
  Comment,
  Tooltip,
  Icon,
  Tag
} from "antd";
import { withRouter } from "react-router-dom";
import { getKoreanDate } from "../../../../utils/utils";
import moment from "moment";

const { Title, Text } = Typography;

const typeNameMap = {
  queture: "깨처",
  quiz: "문제",
  suggestion: "건의",
  opinion: "수업의견",
  other: "기타"
};

export class PostDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      reply: "",
      data: {
        title: null,
        text: null,
        writer: null
      }
    };
  }

  componentDidMount() {
    const { postId } = this.props.match.params;

    console.log("post id: ", postId);
    BoardApis.getPost(postId)
      .then(res => {
        console.log("post detail: ", res);
        this.setState({
          data: res
        });
      })
      .catch(e => {
        message.warning("");
      });
  }

  handleDelete = postId => {
    this.setState({
      isLoading: true
    });

    BoardApis.deletePost(postId)
      .then(res => {
        message.success("삭제 완료");
        this.props.history.push("/admin/board");
      })
      .catch(e => {
        message.warning("삭제 실패");
      });
  };

  handleReply = () => {
    const { data, reply } = this.state;
    if (!reply) {
      return;
    }

    this.setState({
      isLoading: true
    });
    BoardApis.postReply(data.postId, {
      text: reply
    })
      .then(res => {
        message.success("댓글 등록 완료");
        BoardApis.getPost(data.postId)
          .then(res => {
            console.log("post detail: ", res);
            this.setState({
              data: res,
              isLoading: false,
              reply: ""
            });
          })
          .catch(e => {
            message.warning("");
            this.setState({
              isLoading: false
            });
          });
      })
      .catch(e => {
        message.warning("댓글 등록 실패");
        this.setState({
          isLoading: false
        });
      });
  };

  render() {
    const { data, reply, isLoading } = this.state;
    const { user, userPermission } = this.props;

    return (
      <Skeleton loading={false} active>
        <PageHeader title={<Title level={2}>게시판</Title>} />
        <div
          className="admin-page-content"
          style={{ minHeight: 300, overflow: "hidden" }}
        >
          <Title level={4}>
            {data.title}
            {data.type && (
              <>
                <Divider type="vertical" />
                <Tag color="blue">{typeNameMap[data.type] || ""}</Tag>
              </>
            )}
          </Title>
          <div>
            <Text>{`작성자: ${data.writer && data.writer.name}`}</Text>
            <Divider type="vertical" />
            <Text>{`등록일: ${getKoreanDate(data.createdTime, true)}`}</Text>
          </div>
          <Divider />
          <div
            style={{ minHeight: 150 }}
            dangerouslySetInnerHTML={{ __html: data["text"] }}
          ></div>
          <Divider />

          <div style={{ padding: 8 }}>
            <div>
              <List
                dataSource={data.replies}
                header={
                  <Text strong>
                    {`댓글 ${data.replies && data.replies.length}개`}
                  </Text>
                }
                itemLayout="horizontal"
                renderItem={item => (
                  <Comment
                    author={(item.writer && item.writer.name) || "-"}
                    content={<p>{item.text}</p>}
                    // datetime={
                    //   <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
                    //     <span>{moment().fromNow()}</span>
                    //   </Tooltip>
                    // }
                  />
                )}
              />
            </div>
            <Divider />
            <Text strong>{`댓글 달기`}</Text>
            <Input.TextArea
              style={{ marginTop: 8 }}
              value={reply}
              autoSize={{ minRows: 3, maxRows: 6 }}
              onChange={e => {
                this.setState({
                  reply: e.target.value
                });
              }}
            />
            <Button
              onClick={this.handleReply}
              loading={isLoading}
              style={{ marginTop: 8, float: "right" }}
            >
              등록
            </Button>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", margin: 24 }}
        >
          {(userPermission === "master" ||
            (user && data.writer && user.account === data.writer.account)) && (
            <>
              <Popconfirm
                title={`정말로 삭제 하시겠습니까?`}
                onConfirm={() => this.handleDelete(data.postId)}
                onCancel={() => message.error("삭제 취소")}
                okText="삭제"
                placement="topRight"
                cancelText="취소"
              >
                <Button type="danger" size="large" style={{ marginRight: 16 }}>
                  삭제하기
                </Button>
              </Popconfirm>
              <Button
                type="primary"
                size="large"
                style={{ marginRight: 16 }}
                onClick={() => {
                  this.props.history.push(`/admin/board/edit/${data.postId}`);
                }}
              >
                수정하기
              </Button>
            </>
          )}
          <Button
            size="large"
            onClick={() => {
              this.props.history.push(`/admin/board`);
            }}
          >
            목록으로
          </Button>
        </div>
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      pending: auth.pending,
      auth: auth,
      user: auth.user,
      logged: auth.logged,
      userPermission: auth.user.type === "org" ? "master" : auth.user.permission
    }),
    null
  )(PostDetailPage)
);
