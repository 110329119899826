import React, { useState, useEffect } from "react";
import { filter } from "lodash";
import { numberToText } from "../../../utils/utils";
import QuetureName from "../../admin/components/QuetureName";
import { getSeqText } from 'utils/utils';

const LectureTooltip = props => {
  const { top, left, lecture } = props;
  const [lectureData, setLectureData] = useState({
    seq: "",
    title: "",
    quetureList: []
  });

  useEffect(() => {
    const quetureSections = filter(lecture.sections, function (o) {
      return o.type === "QUETURE";
    });

    setLectureData({
      seq: lecture.seq,
      title: lecture.title,
      quetureList: quetureSections
    });

    return () => {
      setLectureData(null);
    };
  }, [lecture]);

  return (
    <div
      className="ht-tooltip"
      style={{
        left: left ? left + 10 : 10,
        top: top ? top + 10 : 10
      }}
    >
      <div className="ht-tooltip-wrap">
        <div className="ht-tooltip__title">
          <strong>수업정보</strong>
          {getSeqText(lectureData)} 수업
        </div>
        {lectureData.quetureList.map((queture, index) => (
          <p className="group-title" key={index}>
            <span>{numberToText(index + 1)}번째 깨처</span>
            <QuetureName quetureId={queture.quetureId} />
          </p>
        ))}
        {/* 기본이 x표시, 했을시 is-complete */}
        {/* <ul className="quest">
          <li>깨처 영상 복습</li>
          <li>틀린 문제 풀기</li>
        </ul>
        <p className="group-title mission">
          <span>학습미션</span>
        </p>
        <ul className="quest">
          <li>학습 미션</li>
        </ul> */}
      </div>
    </div>
  );
};

export default LectureTooltip;
