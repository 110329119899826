import React, { useState, useEffect, useRef } from "react";
import SoloAni from "../../../components/BodyMovin/Animation/SelectSolo";
import "./_maketeam.scss";
import TeamAni from "../../../components/BodyMovin/Animation/SelectTeam";
import PauseClass from "../etcpage/PauseClass";
import RandomTeam from "../randomteam/RandomTeam";
import Audio from "../../../components/Audio/Audio";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

// function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

const Maketeam = (props) => {
  const {
    curPage,
    viewType,
    groups,
    students,
    user,
    onSelectGroupType,
    onSendMessage,
  } = props;

  console.log("page: ", curPage);
  console.log("groups: ", groups);
  console.log("students: ", students);
  if (viewType === "student") {
    if (curPage === 0) {
      return <PauseClass />;
    } else if (curPage === 1) {
      if (groups.length > 0) {
        return (
          <RandomTeam
            onSendMessage={onSendMessage}
            user={user}
            groups={groups}
            students={students}
            {...props}
          />
        );
      }
    }
  }

  return (
    <>
      <div className="maketeam-layout">
        {/* <div className="small-title">{seq}번째 깨처</div> */}
        <div className="big-title u-color-red">
          <FormattedMessage id="classroom.makeTeamTitle" />
        </div>
        <div className="team-type">
          <div
            className="card solo"
            onClick={() => {
              onSelectGroupType("single");
              // setType("single");
            }}
          >
            <strong>
              <FormattedMessage id="classroom.soloPlay" />
            </strong>
            <p>
              <FormattedMessage id="classroom.soloPlayDesc" />
            </p>
            <div className="img"></div>
          </div>
          <div
            className="card team"
            onClick={() => {
              onSelectGroupType("team");
              // setType("team");
            }}
          >
            <strong>
              <FormattedMessage id="classroom.teamPlay" />
            </strong>
            <p>
              <FormattedMessage id="classroom.teamPlayDesc" />
            </p>
            <div className="img"></div>
          </div>
        </div>

        {/* 기본 개인전, 팀전 : team  */}
        {curPage === 1 && (
          <div className="popup popup-select-battletype">
            <div className="popup-wrap">
              <div className="popup-content">
                <div className="selected-battletype ">
                  {groups.length === 0 && (
                    <>
                      <strong>
                        <FormattedMessage id="classroom.soloPlay" />
                      </strong>
                      <p>
                        <FormattedMessage id="classroom.soloPlayDesc" />
                      </p>
                      <SoloAni />
                    </>
                  )}
                  {groups.length > 0 && (
                    <>
                      <strong>
                        <FormattedMessage id="classroom.teamPlay" />
                      </strong>
                      <p>
                        <FormattedMessage id="classroom.teamPlayDesc" />
                      </p>
                      <TeamAni />
                    </>
                  )}
                </div>
              </div>
            </div>
            {viewType === "preview" && <Audio type={"maketeam"} />}
          </div>
        )}
      </div>
    </>
  );
};

export default Maketeam;
