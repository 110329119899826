import React, { useState, useEffect } from 'react'
// import ReactBodymovin from 'react-bodymovin'
import animation from './O.json'
import BodyMovin from '../BodyMovin.js';
import './_animation.scss';


const ResultToastCoreect = () => {
  const [isPlay, setPlay] = useState(false);

  const bodymovinOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: animation
  }

  useEffect(() => {
    const timer = setTimeout(() => { setPlay(true) }, 500);
    return () => clearTimeout(timer);
  }, []);

  // 1초 정도 위에
  return (
    <>
      {
        isPlay &&
        <div className="correct">
          <BodyMovin className="" options={bodymovinOptions} />
        </div>
      }
    </>
  )
}

export default ResultToastCoreect;
