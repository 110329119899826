import React, { Component } from 'react';

import { formatText } from '../utils/Formatter';

class SelectableText extends Component {

    state = {
        over: false
    }

    toggle = () => {
        const { index, selected, onToggle } = this.props;
        onToggle(index, !selected);
    }

    render() {
        const { selected, value } = this.props;
        const { over } = this.state;

        return (
            <div className={"selectableText" + (selected ? " on" : "")}
                onClick={this.toggle}
                onMouseOver={() => this.setState({ over: true })}
                onMouseLeave={() => this.setState({ over: false })}>
                <span className={"text" + (over ? " over" : "")}>
                    {formatText(value)}
                </span>
            </div>
        )
    }
}

export default SelectableText;