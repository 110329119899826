import React from "react";
import classNames from "classnames";
import { filter } from "lodash";
import { formateTime, getProfileUrl } from "../../../utils/utils";
import { orderBy, values, mapValues } from "lodash";
import fakeAvatar3 from './img/fakeAvatar3.svg';

import "./_component.scss";

const StudentList = props => {
  const { studentList } = props;
  if (!studentList) {
    return null;
  }
  return (
    <ul className="attendancelist-wrap">
      {studentList.map(student => (
        <li
          key={student.studentId}
          className={classNames("attendancelist effect is-pick", {})}
        >
          <div className="img">
            <img
              src={
                (student.profileImage &&
                  getProfileUrl(student.profileImage.contentId)) ||
                  fakeAvatar3
              }
              alt=""
            />
          </div>
          <div className="attendancelist__name">{student.name}</div>
          <div className="attendancelist__time">
            <span></span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default StudentList;
