import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";
import Axios from "../../utils/request";
import { orderBy } from "lodash";

const CLASSROOM_LIST = "auth/CLASSROOM_LIST";
const CLASSROOM_LIST_LOADING = "auth/CLASSROOM_LIST_LOADING";
const CLASSROOM_LIST_SUCCESS = "auth/CLASSROOM_LIST_SUCCESS";
const CLASSROOM_LIST_ERROR = "auth/CLASSROOM_LIST_ERROR";

const CLASSROOM_ADD_LOADING = "auth/CLASSROOM_ADD_LOADING";
const CLASSROOM_ADD_SUCCESS = "auth/CLASSROOM_ADD_SUCCESS";
const CLASSROOM_ADD_ERROR = "auth/CLASSROOM_ADD_ERROR";

const CLASSROOM_DETAIL_LOADING = "auth/CLASSROOM_DETAIL_LOADING";
const CLASSROOM_DETAIL_SUCCESS = "auth/CLASSROOM_DETAIL_SUCCESS";
const CLASSROOM_DETAIL_ERROR = "auth/CLASSROOM_DETAIL_ERROR";

function _getClassroomList(orgId) {
  return Axios.get(`/academy/v2/accounts/${orgId}/classrooms`);
}

function _getGuideClassroomList(guideId) {
  console.log(guideId,"guideId")
  return Axios.get(`/academy/v2/guides/${guideId}/classrooms`);
}

export const getClassroomList = (orgId,type) => (dispatch, getState) => {
  console.log("get classroom list: ", orgId);
  dispatch({
    type: CLASSROOM_LIST_LOADING
  });
  console.log(type,"getState")
  if (!type || type === "org") {
    return _getClassroomList(orgId)
      .then(res => {
        res.map(classroom => {
          classroom["courseTitle"] = classroom.course
            ? classroom.course.title
            : "";
          classroom["courseSection"] = classroom.course
            ? classroom.course.title.split("_")[0]
            : "";
          if (classroom.completedLectures === classroom.totalLectures) {
            classroom["isFinished"] = 1;
          } else {
            classroom["isFinished"] = 0;
          }
        });

        dispatch({
          type: CLASSROOM_LIST_SUCCESS,
          payload: orderBy(res, "createdTime", ["desc"])
        });
        return res;
      })
      .catch(e => {
        dispatch({
          type: CLASSROOM_LIST_ERROR,
          payload: e
        });
        return e;
      });
  } else {
    return _getGuideClassroomList(orgId) // Guide Id
      .then(res => {
        res.map(classroom => {
          classroom["courseTitle"] = classroom.course
            ? classroom.course.title
            : "";
          classroom["courseSection"] = classroom.course
            ? classroom.course.title.split("_")[0]
            : "";
          if (classroom.completedLectures === classroom.totalLectures) {
            classroom["isFinished"] = 1;
          } else {
            classroom["isFinished"] = 0;
          }
        });

        dispatch({
          type: CLASSROOM_LIST_SUCCESS,
          payload: orderBy(res, "createdTime", ["desc"])
        });
        return res;
      })
      .catch(e => {
        dispatch({
          type: CLASSROOM_LIST_ERROR,
          payload: e
        });
        return e;
      });
  }
};

function _addClassroom(orgId, data) {
  return Axios.post(`/academy/v2/accounts/${orgId}/classrooms`, data);
}

export const addClassroom = (orgId, data) => (dispatch, getState) => {
  console.log("add classroom: ", data);
  dispatch({
    type: CLASSROOM_ADD_LOADING
  });

  _addClassroom(orgId, data)
    .then(res => {
      dispatch({
        type: CLASSROOM_ADD_SUCCESS,
        payload: res
      });
    })
    .catch(e => {
      dispatch({
        type: CLASSROOM_ADD_ERROR,
        payload: e
      });
    });
};

const initialState = {
  pending: false,
  classroomList: [],
  error: {
    triggered: false,
    code: null,
    message: null
  }
};

export default handleActions(
  {
    [CLASSROOM_LIST_LOADING]: (state, { payload }) => {
      return {
        ...state,
        pending: true
      };
    },
    [CLASSROOM_LIST_SUCCESS]: (state, { payload }) => {
      console.log("get classroom list success: ", payload);
      return {
        ...state,
        pending: false,
        classroomList: payload
      };
    },
    [CLASSROOM_LIST_ERROR]: (state, { payload }) => {
      return {
        ...state,
        pending: false,
        classroomList: []
      };
    },
    [CLASSROOM_ADD_LOADING]: (state, { payload }) => {
      return {
        ...state,
        pending: true
      };
    },
    [CLASSROOM_ADD_SUCCESS]: (state, { payload }) => {
      console.log("add classroom success: ", payload);
      return {
        ...state,
        pending: false,
        classroomList: payload
      };
    },
    [CLASSROOM_ADD_ERROR]: (state, { payload }) => {
      return {
        ...state,
        pending: false,
        classroomList: []
      };
    }
  },
  initialState
);
