import AttendanceChk from "../pages/classMode/attendanceChk/AttendanceChk";
import ComponentsAll from "../components/Teacher/component/ComponentsAll";
import Queturepoint from "../pages/classMode/queture/Queturepoint";
import GameExplain from "../pages/classMode/game/GameExplain";
import QuetureView from "../pages/classMode/queture/QuetureView";
import sendmsg from "../pages/classMode/sendmsg/Sendmsg";
import Activity from "../pages/classMode/activity/Activity";
import Mvp from "../pages/classMode/mvp/Mvp";
import Maketeam from "../pages/classMode/maketeam/Maketeam";
import GameRanking from "../pages/classMode/game/GameRanking";
import EffectTest from "../components/EffectTest";
import ClassLogin from "../pages/classMode/login/ClassLogin";
import Psend from "../pages/classMode/etcpage/Psend";
import QuetureRanking from "../pages/classMode/queture/QuetureRanking";
import Pageall from "../components/Teacher/component/Pageall";
import FindAccount from "../pages/login/components/Login/FindAccount";
import RandomTeam from "../pages/classMode/randomteam/RandomTeam";
import QuizPublishing from "../components/Quiz/QuizPublishing";
import PauseClass from "../pages/classMode/etcpage/PauseClass";
import EndClass from "../pages/classMode/etcpage/EndClass";
import DecisionPair from "../pages/classMode/maketeam/DecisionPair";
import TeamSelectChk from "../pages/classMode/teamSelectChk/TeamSelectChk";
import Test from "../pages/classMode/login/Test";
import ChallengeQuiz from "../pages/classMode/challengeQuiz/ChallengeQuiz";
import Storytelling from "../pages/classMode/storytelling/Storytelling";
// import MyProfile from "../pages/homeTraining/MyProfile";

export default [
  {
    path: "/test-input",
    exact: true,
    component: Test
  },
  {
    path: "/teamselectchk",
    exact: true,
    component: TeamSelectChk
  },
  {
    path: "/quizpub",
    exact: true,
    component: QuizPublishing
  },
  {
    path: "/randomteam",
    exact: true,
    component: RandomTeam
  },
  {
    path: "/findaccount",
    exact: true,
    component: FindAccount
  },
  {
    path: "/pageall",
    exact: true,
    component: Pageall
  },
  {
    path: "/quetureranking",
    exact: true,
    component: QuetureRanking
  },
  {
    path: "/classend",
    exact: true,
    component: EndClass
  },
  {
    path: "/classpause",
    exact: true,
    component: PauseClass
  },
  {
    path: "/psend",
    exact: true,
    component: Psend
  },
  {
    path: "/classlogin",
    exact: true,
    component: ClassLogin
  },
  {
    path: "/effect",
    exact: true,
    component: EffectTest
  },
  {
    path: "/gameranking",
    exact: true,
    component: GameRanking
  },
  {
    path: "/maketeam",
    exact: true,
    component: Maketeam
  },
  {
    path: "/challenge",
    exact: true,
    component: ChallengeQuiz
  },
  {
    path: "/storytelling",
    exact: true,
    component: Storytelling
  },
  {
    path: "/mvp",
    exact: true,
    component: Mvp
  },
  {
    path: "/activity",
    exact: true,
    component: Activity
  },
  {
    path: "/sendmsg",
    exact: true,
    component: sendmsg
  },
  {
    path: "/gameexplain",
    exact: true,
    component: GameExplain
  },
  {
    path: "/queturepoint",
    exact: true,
    component: Queturepoint
  },
  {
    path: "/quetureview",
    exact: true,
    component: QuetureView
  },
  {
    path: "/attendance",
    exact: true,
    component: AttendanceChk
  },
  {
    path: "/ComponentsAll",
    exact: true,
    component: ComponentsAll
  }
];
