import React from 'react';
import classNames from 'classnames';

const QuizLayoutD = (props) => {
  const { isLong, imageComponent, problemComponent, choiceComponent, titleComponent, desmosComponent } = props;
  const QuizAnswers = choiceComponent;
  const QuizProblem = problemComponent;
  const Desmos = desmosComponent;
  const QuizImage = imageComponent;
  const QuizTitle = titleComponent;

  return (
    <div className={classNames("layout type-d", {
      "long": isLong
    })}>
      <div className="mb16">
        <QuizImage />
      </div>
      <div className="inline-block padding-right">
        {/* <QuizTitle /> */}
        <QuizProblem />
      </div>
      <div className="inline-block">
        <QuizAnswers />
      </div>
    </div>
  );
}

export default QuizLayoutD;
