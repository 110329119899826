import React from "react";
import "./_queture.scss";
import { Skeleton } from "antd";
import { FormattedMessage } from "react-intl";

const Queturepoint = (props) => {
  const { seq, quetureData } = props;
  return (
    <Skeleton loading={!quetureData} active>
      {quetureData && (
        <div className="queturepoint-layout">
          <div className="queturepoint-layout-wrap">
            <div className="small-title">
              <FormattedMessage
                id={"classroom.quetureSeq" + (seq && seq > 6 ? "" : "-" + seq)}
                values={{ seq: seq }}
              />
            </div>
            <div className="big-title u-color-red">{quetureData.title}</div>
            <div className="wrap">
              <div className="page-content-title u-color-red">
                <span>
                  <FormattedMessage id={"classroom.quetureDescTitle"} />
                </span>
                <span className="speech-bubble">
                  {/* 깨처포인트 */}
                  <FormattedMessage id={"classroom.queture-point"} />
                  <span></span>
                </span>
              </div>
              {/*자료구조 변경으로 point 필드 대신 prologue 필드 우선 사용*/}
              <p className="page-content-ment">{quetureData.prologue && quetureData.prologue !== "" ? quetureData.prologue : quetureData.point}</p>
            </div>
          </div>
        </div>
      )}
    </Skeleton>
  );
};

export default Queturepoint;
