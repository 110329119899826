import React, { Component } from "react";
import { connect } from "react-redux";
import BoardApis from "../../../../utils/boardApis";
import {
  Skeleton,
  PageHeader,
  Typography,
  Button,
  Icon,
  Row,
  Select,
  Input,
  Table,
  Divider,
  Tag
} from "antd";
import { withRouter } from "react-router-dom";
import { getKoreanDate } from "../../../../utils/utils";

const count = 10;
const typeNameMap = {
  queture: "깨처",
  quiz: "문제",
  suggestion: "건의",
  opinion: "수업의견",
  other: "기타"
};
export class PostListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      postList: [],
      searchType: "all",
      page: 0,
      totalPages: 1
    };
  }

  componentDidMount() {
    this.getPostList();
  }

  getPostList = (page = 0) => {
    BoardApis.getPostList(page, count)
      .then(res => {
        console.log("post list: ", res);
        this.setState({
          postList: res.list,
          page: res.page,
          totalPages: res.totalPages
        });
      })
      .catch(e => {
        console.log("post list error: ", e);
      });
  };

  onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    this.setState(
      {
        page: pagination.current - 1
      },
      () => {
        this.getPostList(pagination.current - 1);
      }
    );
  };

  render() {
    const { postList, isLoading, page, totalPages } = this.state;
    const { user, userPermission } = this.props;

    return (
      <Skeleton loading={isLoading} active>
        <PageHeader
          title={
            <Typography.Title level={2} style={{ marginBottom: 0 }}>
              게시판
            </Typography.Title>
          }
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => this.props.history.push("/admin/board/add")}
            >
              게시글 등록하기
              <Icon type="plus-circle" />
            </Button>
          ]}
        />
        <div className="admin-page-content">
          <Row
            type="flex"
            justify="end"
            style={{ marginBottom: 28, display: "none" }}
          >
            <Select
              defaultValue="all"
              value={this.state.searchType}
              onChange={value =>
                this.setState({
                  searchType: value
                })
              }
            >
              <Select.Option value="all">제목+내용</Select.Option>
              <Select.Option value="title">제목만</Select.Option>
              <Select.Option value="content">내용만</Select.Option>
            </Select>
            <Input.Search
              placeholder="검색어를 입력해주세요."
              style={{ width: 200, marginLeft: 8 }}
            />
          </Row>
          <Table
            rowKey="postId"
            dataSource={postList}
            columns={[
              {
                title: "번호",
                key: "index",
                render: (text, record, index) => (
                  <span>{page * count + index + 1}</span>
                )
              },
              {
                title: "제목",
                key: "title",
                dataIndex: "title",
                render: (text, record) => (
                  <span>
                    {text}
                    {record.type && (
                      <>
                        <Divider type="vertical" />
                        <Tag color="blue">{typeNameMap[record.type] || ""}</Tag>
                      </>
                    )}
                  </span>
                )
              },
              {
                title: "작성자",
                key: "name",
                dataIndex: "writer",
                render: text => <span>{text.name}</span>
              },
              {
                title: "등록일",
                key: "createdTime",
                dataIndex: "createdTime",
                render: text => <span>{getKoreanDate(text, true)}</span>
              }
            ]}
            onRow={record => {
              return {
                onClick: () => {
                  this.props.history.push(
                    `/admin/board/detail/${record.postId}`
                  );
                }
              };
            }}
            pagination={{
              defaultPageSize: count,
              total: totalPages * count
            }}
            onChange={this.onChange}
          />
        </div>
      </Skeleton>
    );
  }
}

export default PostListPage;
