import React from "react";
import { Button, Result, Alert } from "antd";
const NoPermissions = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={() => (window.location = "/")}>
          Back Home
        </Button>
      }
    ></Result>
  );
};

export default NoPermissions;
