import axios from "axios";
import React from "react";
import { getDefaultConfig, getHost } from "utils/APIUtils";
import RichEditor from "./RichEditor";
import { Spin, message, Input, Button, Radio, Modal } from "antd";
import NoticeApis from "../../../../utils/noticeApis";
import NewsletterApis from "../../../../utils/newsletterApis";


const defaultToolbar = {
  items: [
    "heading",
    "|",
    "bold",
    "italic",
    "fontColor",
    "alignment",
    "underline",
    "fontBackgroundColor",
    "fontSize",
    "alignment",
    "Underline",
    "bulletedList",
    "numberedList",
    "|",
    "link",
    "imageUpload",
    "blockQuote",
    "insertTable",
    "mediaEmbed",
    "|",
    "undo",
    "redo"
  ]
};

const typeList = [
  { key: "queture", name: "깨처" },
  { key: "quiz", name: "문제" },
  { key: "suggestion", name: "건의" },
  { key: "opinion", name: "수업의견" },
  { key: "other", name: "기타" }
];
const typeForm = {
  queture: "<p>깨처명: </p><p>내용: </p>",
  quiz: "<p>관련 깨처: </p><p>문제: </p><p>답안: </p><p>해설: </p>",
  suggestion: "",
  opinion: "",
  other: ""
};

const { confirm } = Modal;

class Editor extends React.Component {
  state = {
    pending: false,
    showConfirm: false,
    data: {
      contentId: "",
      classroomId: "",
      orgId: "",
      parentId: "",
      studentId: "",
      text: "",
      title: "",
      type: ""
    }
  };

  componentDidMount() {
    const { data } = this.props;
    if (data != null) {
      this.init(data);
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.init(nextProps.data);
    }
  }

  update = e => {
    const { name, value } = e.target;

    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    });
  };

  _update = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    });
  };

  init = data => {
    if (data == null) {
      this.setState({
        data: {
          contnetId: "",
          classroomId: "",
          orgId: "",
          parentId: "",
          studentId: "",
          text: "",
          title: "",
          type: ""
        }
      });
      return;
    }

    console.log(data)
    if (data.text == null) {
      this.setState({
        pending: true
      });
      NewsletterApis.getNewsletter(data.newsletterId).then(r => {
        this.setState({
          pending: false,
          data: r
        });
      });
    } else {
      this.setState({
        data: data
      });
    }
  };

  save = () => {
    const { onSubmit, onBack } = this.props;
    const {
      noticeId,
      contentId,
      classroomId,
      orgId,
      parentId,
      studentId,
      text,
      title,
      type
    } = this.state.data;

    if (!title) {
      message.warning("제목을 입력해 주세요");
      return;
    }

    if (this.state.pending) {
      return <Spin />;
    }
    const body = {
      contentIds: contentId || null,
      orgId: this.props.orgId,
      text: text || null,
      title: title || null,
      type: type || null
    };

    if (onSubmit) {
      onSubmit(body);
    }
  };

  close = () => {
    this.props.onBack();
  };

  handleOk = type => {
    this.setState({
      data: {
        ...this.state.data,
        type: type
      }
    });
  };

  handleCancel = type => {
    this.setState({
      data: {
        ...this.state.data,
        type: type,
        text: typeForm[type] || ""
      }
    });
  };

  showConfirm = type => {
    const handleOk = this.handleOk;
    const handleCancel = this.handleCancel;
    confirm({
      title: "카테고리 수정",
      content: "카테고리 변경 시 작성한 내용을 유지하시겠습니까?",
      cancelText: "아니요",
      okText: "예",
      onOk() {
        handleOk(type);
      },
      onCancel() {
        handleCancel(type);
      }
    });
  };

  render() {
    const {
      contentId,
      classroomId,
      orgId,
      parentId,
      studentId,
      text,
      title,
      type
    } = this.state.data;
    const { isEdit, hasType } = this.props;
    console.log("text: ", text);
    return (
      <div
        style={{
          width: "80%",
          padding: "10px",
          margin: "auto",
          minHeight: "600px",
          // border: "1px solid",
          textAlign: "left"
        }}
      >
        {/* <div>
          <label>문서유형</label>
          <select
            type="select"
            style={{
              width: "300px"
            }}
            value={textType}
            name="textType"
            onChange={this.update}
          >
            <option value="plain">TEXT</option>
            <option value="html">HTML</option>
            <option value="html_text">HTML (manual)</option>
            <option value="link">Link</option>
          </select>
        </div> */}

        {hasType && (
          <Radio.Group
            onChange={e => {
              let _type = e.target.value;
              if (text) {
                this.showConfirm(_type);
              } else {
                this.setState({
                  data: {
                    ...this.state.data,
                    type: _type,
                    text: typeForm[_type] || ""
                  }
                });
              }
            }}
            value={type}
            style={{ marginBottom: 8 }}
          >
            {typeList.map(c => (
              <Radio key={c.key} value={c.key}>
                {c.name}
              </Radio>
            ))}
          </Radio.Group>
        )}

        <Input
          style={{ width: "100%", marginBottom: 8 }}
          placeholder="제목"
          value={title}
          name="title"
          onChange={this.update}
        />
        {/* <div style={{ width: "100%", marginBottom: 8 }}>
        </div> */}

        <div>
          {(text || !isEdit) && (
            <RichEditor
              value={text}
              height={600}
              toolbar={defaultToolbar}
              onChange={v => {
                this._update("text", v);
              }}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 8
          }}
        >
          <Button
            type="primary"
            onClick={this.save}
            style={{
              marginRight: 8
            }}
          >
            {isEdit ? "저장" : "등록"}
          </Button>

          <Button onClick={this.close}>닫기</Button>
        </div>
      </div>
    );
  }
}

export default Editor;
