import React, { Component } from "react";
import Popup from "../../../components/Popup/Popup";

class NextSectionPopup extends Component {
  render() {
    const { isOpen, onClose, children } = this.props;

    return (
      <Popup isOpen={isOpen} className="popup-page-preview">
        <div className="popup-closebtn" onClick={() => onClose()}></div>
        <div className="content none-event">{children}</div>
        {/* <div className="page-number">
          <span>1</span>/<span>20</span>
        </div> */}
      </Popup>
    );
  }
}
export default NextSectionPopup;
