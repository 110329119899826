import { Col, Row } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import Axios from "utils/request";

const QuebonSongRow = (props) => {
  const { lecture, onShowQuebonSongPopup } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    if (lecture?.sections) {
      const _quebonSong = filter(lecture.sections, function (o) {
        return o.type === "QUEBONSONG";
      });
      console.log("QuebonSOng: ", _quebonSong);
      if(_quebonSong?.length > 0) {
        load(_quebonSong[0].kidsQuebonSongId)
      }
    }

    return () => {
      setData(null)
    };
  }, [lecture]);

  const load = async(id) => {
    Axios.get(`/academy/v2/kidsQuebonSongs/${id}`).then(
      (res) => {
        setData(res)
      }
    );
  }

  if (!data) return null;

  return (
    <Row
      // key={queture.quetureId}
      className={classNames("ht-queture-list")}
      style={{ padding: "0px", margin: "0px" }}
    >
      {/* 볼 수 없을시 is-cover */}

      <div
        className="textwrap"
        style={{
          flex: 1,
          width: "1120px",
          marginLeft: "40px",
          paddingTop: "0px",
          // borderTop: index !== 0 ? "2px solid #D7E0E7" : "0px",
        }}
      >
        {/*<strong>{`${numberToText(index + 1)}번째 깨처`}</strong>*/}

        <Col
          span={9}
          style={{
            minWidth: "408px",
            width: "408px",
          }}
        >
          <div
            className={"title"}
            style={{
              minWidth: "400px",
              width: "400px",
              height: "80px",
              color: "rgba(51,58,83,1)",
              fontSize: "21px",
              justifyContent: "center",
              letterSpacing: "-3%",
              display: "table-cell",
              verticalAlign: "middle",
              lineHeight: "28.8px",
              fontWeight: "500",
              paddingLeft: "10px",
              fontFamily: "Noto Sans KR",
            }}
          >
            {data?.title}
          </div>
        </Col>
        <Col span={7}>
          <p
            style={{
              lineHeight: "21.6px",
              width: "380px",
              height: "80px",
              fontSize: "18px",
              display: "table-cell",
              verticalAlign: "middle",
              color: "#7F92A3",
            }}
          >
            {data?.point}
          </p>
        </Col>
        <Col
          className={classNames("btnwrap is-chk", {})}
          style={{
            left: "90px",
            marginLeft: "auto 0",
            display: "table-cell",
            verticalAlign: "middle",
            height: "80px",
          }}
        >
          <button
            className="review-layout-button"
            onClick={() => {
              onShowQuebonSongPopup(data);
            }}
          >
            <p className={"review-layout-font"} style={{ fontWeight: 500, justifyContent: "center" }}>
              다시듣기
            </p>
          </button>
        </Col>
      </div>
    </Row>
  );
};

export default QuebonSongRow;
