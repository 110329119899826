import React from 'react';
import classNames from "classnames";
import { Link } from 'react-router-dom';

let boolean = true;

const FindAccount = ({classes}) => {
  return (
    <div className={classNames("classlogin-layout findaccount", {
      // "block" : classes === "findid"
    })} >
      <div className="classlogin-layout-wrap">
        <div className="title">아이디 찾기</div>
        {
        <ul className="step">
          <li className="is-step"><span>본인확인</span></li>
          <li><span>완료</span></li>
        </ul>
        }
        {
          <div className="inputarea">
          {
            <div className="inputwrap">
              <input type="text" placeholder="아이디를 입력해주세요." required/>
            </div>
          }
            <div className="inputwrap">
              <input type="text" placeholder="학생이름을 입력해주세요." required/>
            </div>
            <div className="inputwrap">
              <input type="text" placeholder="생년월일 8자리를 입력해주세요." required/>
            </div>
          </div>
        }
        {
          boolean = false && 
          <>
          <div className="result">
            <p><span>아이디</span>님의 아이디는 <span>hel******</span>입니다.</p>
          </div>
          <div className="find-pw">
            비밀번호를 잊으셨나요? <Link to="">비밀번호 찾기</Link>
          </div>
          </>
        }
        <div className="btnwrap">
          <button className="classmode-btn">찾기</button>
        </div>
      </div>
    </div>
  );
};

export default FindAccount;