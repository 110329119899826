import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PictureQuizAnimation from "./components/PictureQuizAnimation/PictureQuizAnimation";

class PictureQuizSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  
  render() {
    const { viewType, classroomId, curPage } = this.props;
    
    if(viewType === "preview") {
      return <PictureQuizAnimation />
    }
    return (<div className="queturepoint-layout">
      <div className="queturepoint-layout-wrap">
        <div className="small-title">
          <FormattedMessage id={"classroom.pictureQuizTitle"}/>
        </div>
        <div className="big-title u-color-red">
          {}
        </div>
        <div className="wrap">
          <div className="page-content-title u-color-red">
                <span>
                  <FormattedMessage id={"classroom.pictureQuizDescTitle"} />
                </span>
            <span className="speech-bubble">
                  {/* 깨처포인트 */}
              <FormattedMessage id={"classroom.queture-point"} />
                  <span></span>
                </span>
          </div>
          {/*자료구조 변경으로 point 필드 대신 prologue 필드 우선 사용*/}
          <p className="page-content-ment"/>
        </div>
      </div>
    </div>)
  }

}

export default PictureQuizSection