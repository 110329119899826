// cource info containing lectures without sections and bundle info

import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";
import {
  SESSION_KEY,
  getHost,
  getDefaultConfig,
  parseAxiosError
} from "utils/APIUtils";
import axios from "axios";
import Axios from "../../utils/request";

const COURSE_SIMPLE_LIST = "auth/COURSE_SIMPLE_LIST";
const COURSE_SIMPLE_LIST_LOADING = "auth/COURSE_SIMPLE_LIST_LOADING";
const COURSE_SIMPLE_LIST_SUCCESS = "auth/COURSE_SIMPLE_LIST_SUCCESS";
const COURSE_SIMPLE_LIST_ERROR = "auth/COURSE_SIMPLE_LIST_ERROR";

function _getCourseSimpleList() {
  return Axios.get(`/academy/v2/courses?withOutLectures=true`);
}

export const getCourseSimpleList = () => (dispatch, getState) => {
  console.log("get course list: ");
  dispatch({
    type: COURSE_SIMPLE_LIST_LOADING
  });
  _getCourseSimpleList()
    .then(res => {
      dispatch({
        type: COURSE_SIMPLE_LIST_SUCCESS,
        payload: res.list
      });
    })
    .catch(e => {
      dispatch({
        type: COURSE_SIMPLE_LIST_ERROR,
        payload: e
      });
    });
};

const initialState = {
  pending: false,
  courseSimpleList: [],
  error: {
    triggered: false,
    code: null,
    message: null
  }
};

export default handleActions(
  {
    [COURSE_SIMPLE_LIST_LOADING]: (state, { payload }) => {
      return {
        ...state,
        pending: true
      };
    },
    [COURSE_SIMPLE_LIST_SUCCESS]: (state, { payload }) => {
      console.log("get COURSE_SIMPLE list success: ", payload);
      return {
        ...state,
        pending: false,
        courseSimpleList: payload
      };
    },
    [COURSE_SIMPLE_LIST_ERROR]: (state, { payload }) => {
      return {
        ...state,
        pending: false,
        courseSimpleList: []
      };
    }
  },
  initialState
);
