import Axios from "./request";
import { message } from "antd";

const apiPrefix = "/academy/v2/notices";

const getNoticeList = (page, count) => {
  return Axios.get(`${apiPrefix}?page=${page}&count=${count}`);
};

const addNotice = data => {
  return Axios.post(`${apiPrefix}`, data);
};

const editNotice = (noticeId, data) => {
  return Axios.put(`${apiPrefix}/${noticeId}`, data);
};

const getNotice = noticeId => {
  return Axios.get(`${apiPrefix}/${noticeId}`);
};
const deleteNotice = noticeId => {
  return Axios.delete(`${apiPrefix}/${noticeId}`);
};

const NoticeApis = {
  getNoticeList,
  addNotice,
  editNotice,
  getNotice,
  deleteNotice
};

export default NoticeApis;
