import React from "react";
import { Button, Result, Alert } from "antd";

export default function NotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => (window.location = "/")}>
          Back Home
        </Button>
      }
    ></Result>
  );
}
