import React from "react";
import { Button, Tag, Typography, Icon } from "antd";
import { formateQuetureName } from "../../../utils/utils";

const RemoteVideoList = (props) => {
  const { title, curIndex, onSelect, list } = props;

  console.log("list: ", list);
  return (
    <div>
      <Typography.Title level={4}>{title}</Typography.Title>
      {list
        .filter(
          (item) =>
            item?.contents?.items?.main || item?.quetureType !== "EXERCISE"
        )
        .map((item, index) => (
          <Button
            key={index}
            type="primary"
            onClick={() => {
              onSelect(index);
            }}
            size={"large"}
            ghost
            disabled={index === curIndex}
            style={{
              marginRight: 4,
              pointerEvents: index === curIndex ? "none" : "unset",
            }}
          >
            <Icon type="video-camera" />
            {item.title}
            {index === curIndex ? <Tag color="blue">재생중</Tag> : ""}
          </Button>
        ))}
    </div>
  );
};

export default RemoteVideoList;
