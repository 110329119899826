import React from "react";
import { Link } from "react-router-dom";

import "./_Header.scss";

const QuizHeader = props => {
  return (
    // is-fixed-top
    <div className="quiz-header">
      <div className="quiz-header-wrap">
        <div className="title">{props.title}</div>
        <span onClick={props.onBack} className="back-btn">
          나가기
        </span>
      </div>
    </div>
  );
};

export default QuizHeader;
