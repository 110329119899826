import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
const desmos = window.Desmos;

const isDev = process.env.REACT_APP_TEST === 'true';

class Desmos extends React.Component {

  state = {
    showDesmosPopup: false,
    desmos: null,
    viewport: 'landscape' //landscape: 가로, portrait: 세로
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { state } = this.props;

    if (state !== prevProps.state) {
      console.log('desmos state changed: ', state, this.container)
      this.container.innerHTML = null;
      this.init();
    }

  }

  init = () => {
    const { state, vars, onChange } = this.props;

    console.log('init desmos', state)

    var opt = {
      'keypad': false,
      'graphpaper': true,
      'expressions': false,
      'settingsMenu': false,
      'zoomButtons': false,
      'expressionsTopbar': false,
      'pointsOfInterest': false,
      'trace': false,
      'border': false,
      'lockViewport': false,
      'showGrid': true,
      'showXAxis': true,
      'showYAxis': true,
      'fontSize': 24,
    };

    let _state = null;
    let _list = [];

    if (state) {
      if ((typeof state) === 'string') {
        _state = JSON.parse(state);
        console.log('state: ', _state);
        _list = _state.expressions.list ? _state.expressions.list : _state.expressions;
        _state.expressions = [];
      }

      if (_state._options) {
        opt = {
          ...opt,
          ..._state._options,
        }

        if (_state._options.viewport) {
          this.setState({
            viewport: _state._options.viewport
          })
        }
      }
      let _newList = [];
      if (vars) {
        const varKeys = Object.keys(vars).flatMap(v => v.includes('_') ? [v.replace('_', '')] : [])
        console.log('var keys: ', varKeys);
        let regexObj = {};
        Object.keys(vars).map(value => {
          if (value.startsWith('_')) {
            let _value = vars[value];
            let _key = value.replace('_', '');
            console.log('typeof value: ', (typeof _value));
            if (_key.length > 1) {
              _key = `${_key.slice(0, 1)}_{${_key.slice(1)}}`
            }
            if (Array.isArray(_value)) {
              _value = `[${_value.join(',')}]`;
            }
            regexObj[_key] = _value
            console.log('key: ', _key);
            console.log('value: ', _value);
          }
        })

        console.log('regex object: ', regexObj);
        const re = new RegExp(Object.keys(regexObj).join("|"), "gi");
        console.log('regex: ', re);
        let usedKeys = [];
        console.log('list: ', _list);
        _list.map(i => {
          if (i.latex && i.latex.includes('=')) {
            let _key = i.latex.split('=')[0].trim();
            _key = _key.replace('\\', '').trim();
            let _value = regexObj[_key];
            if (_value) {
              console.log('changed latex: ', _key, _value);
              i.latex = `${_key}=${_value}`;
              i['isChanged'] = true;
              // if (i.slider) {
              //   if (i.slider['min'] > _value) {
              //     i.slider['min'] = _value;
              //   }
              //   if (i.slider['max'] < _value) {
              //     i.slider['max'] = _value;
              //   }
              //   if (i.slider['min'] > i.slider['max']) {
              //     i.slider['min'] = i.slider['max']
              //   }
              // }
              usedKeys.push(_key)
            }
          }
          _newList.push(i);
        })
        console.log('usedKeys: ', usedKeys);
        console.log('unused keys: ', Object.keys(regexObj).filter(k => !usedKeys.includes(k)))
        Object.keys(regexObj).filter(k => !usedKeys.includes(k)).map((k, i) => {
          let _expression = {
            type: "expression",
            id: 'extra_' + i,
            latex: `${k}=${regexObj[k]}`,
          }
          _newList.push(_expression)
        })
      }
      console.log('desmos latex list: ', _list);
      console.log('desmos latex newList: ', _newList);
      console.log('vars: ', vars);
      const e = this.container;
      console.log('desmos: ', desmos);
      console.log('desmos: ', window.Desmos);

      if (window.Desmos) {
        let calculator = window.Desmos.GraphingCalculator(e, opt);
        if (_state) {
          _state.expressions.list = _newList;
          this.setState({ desmos: _state })
          calculator.setState(_state);
        }

        if (onChange) {
          this.handleChange(calculator);

          calculator.observeEvent('change', () => {
            this.handleChange(calculator);
          })

          calculator.observe('expressionAnalysis', () => {
            this.handleChange(calculator);
          })
        }
      }

    }
  }

  handleChange = (calculator) => {
    const { onChange } = this.props;
    if (!onChange) {
      return;
    }

    const expressions = calculator.getExpressions();
    console.log('onChange', expressions, calculator.getState())

    //TODO set @var and values
    const data = {};

    const regex = /^([a-zA-Z0-9_\{}]+)[\s\\]*=[\s\\]*([-+]?[0-9\.]+)$/

    expressions.forEach(e => {
      const latex = e.latex;
      const match = regex.exec(latex);
      if (match) {
        console.log('match', latex, match);
        data[match[1].replace(/[_\{}]/g, "")] = match[2];
      } else {
        const analysis = calculator.expressionAnalysis[e.id];
        console.log('eval', latex, analysis)
        if (analysis && analysis.evaluation) {
          const varName = latex.split("=")[0].replace(/[_\{}\\]/g, "").trim();
          data[varName] = analysis.evaluation.value + "";
        }
      }
    })
    onChange(data)
  }

  render() {
    const { viewport, onChange } = this.state;

    return (
      <>
        <div id="desmos-container" ref={e => this.container = e}
          className={classNames("quiz-content__content-img desmos-content", viewport)}>
        </div>
      </>
    )
  }
}

export default Desmos;
