export default {
  "classroom.attendance": "Attendance",
  "classroom.lectureTime": "Lecture Time!",
  "classroom.quetureSeq": "{seq}th Queture",
  "classroom.quetureSeq-1": "{seq}st Queture",
  "classroom.quetureSeq-2": "{seq}nd Queture",
  "classroom.quetureSeq-3": "{seq}rd Queture",
  "classroom.quetureSeq-4": "{seq}th Queture",
  "classroom.quetureSeq-5": "{seq}th Queture",
  "classroom.quetureSeq-6": "{seq}th Queture",

  "classroom.quetureDescTitle": "What are we learning today?",
  "classroom.queture-point": "Queture Point",
  "classroom.makeTeamTitle": "Mission of the question is",
  "classroom.soloPlay": "Solo Play",
  "classroom.soloPlayDesc": "Let's solve the question individually.",
  "classroom.teamPlay": "Team Play",
  "classroom.teamPlayDesc": "Let's solve the question with the friends.",
  "classroom.select-your-teammate": "Select your teammate!",
  "classroom.my-teammate-is": `My teammate <br /> is`,
  "classroom.move-to-leaders-seat": "Move to the leader's seat",
  "classroom.leader": "Leader",
  "classroom.mark": "Let's Check!",
  "classroom.marking": "Checking",
  "classroom.next-question": "Next",
  "classroom.prev-question": "Prev",
  "classroom.finish": "Finish",
  "classroom.incorrect": "Incorrect",
  "classroom.incorrect-desc": "Try Again",
  "classroom.correct": "Correct",
  "classroom.correct-desc": "Awesome!",
  "classroom.todays-game": "Today's Game",
  "classroom.todays-activity": "Today's Activity",
  "classroom.todays-activity-kids": "Today's Activity",
  "classroom.todays-mvp": `Today's <span>MVP</span>`,
  "classroom.class-has-end": "The class has ended",
  "classroom.check-home-training": "Check the assignments in Home Training.",
  "classroom.view-solution": "Solution",
  "classroom.solution": "Solution",
  "classroom.check-the-answer": "Check the answer",
  "classroom.my-answer": "My answer",
  "classroom.game-desc": "Think while playing the game.",
  "classroom.activity-desc": "Shall we do this together?",
  "classroom.activity-desc-kids": "Shall we do this together?",
  "classroom.order-1": "1st",
  "classroom.order-2": "2nd",
  "classroom.order-3": "3rd",
  "classroom.order-4": "4th",
  "classroom.correct-questions": "{count} right",
};
