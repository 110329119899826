import React, { useEffect, useState } from "react";
import Counter from "./modules/Counter";

const CountingQuiz = (props) => {
  const { data, onAnswerUpdated, showReallyTrue, stepQuizAnswers, solvedData } =
    props;
  const [answers, setAnswers] = useState(null);

  useEffect(() => {
    if (data?.quiz?.problem) {
      handleInitAnswers(data);
    }
    return () => {};
  }, [data?.quizId]);

  const handleInitAnswers = (data) => {
    const { quizId, quiz } = data;
    const { problem, digits } = quiz;
    let _answers = [];
    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      let _value = stepQuizAnswers[0];
      _answers = getAnswersArray(_value, digits);
    } else {
      _answers = new Array(Number.parseInt(digits || 1)).fill(0);
    }

    setAnswers(_answers);
  };

  const getAnswersArray = (value, digits) => {
    let _answers = [];
    const _hundreds = Number.parseInt(value / 100);
    const _tens = Number.parseInt((value % 100) / 10);
    const _ones = value % 10;
    if (_hundreds > 0 && Number.parseInt(digits) === 3) {
      _answers.push(_hundreds);
    }
    if (_tens > 0 && Number.parseInt(digits) >= 2) {
      _answers.push(_tens);
    }
    _answers.push(_ones);
    console.log("correctAnswers value: ", _answers);
    return _answers;
  };

  const handleUp = (value, index) => {
    let _answers = [...answers];
    let _nextValue = value + 1;
    if (value === 9) {
      _nextValue = 0;
    }
    _answers[index] = _nextValue;
    setAnswers(_answers);
    handleUpdateAnswers(_answers);
  };

  const handleDown = (value, index) => {
    let _answers = [...answers];
    let _nextValue = value - 1;
    if (value === 0) {
      _nextValue = 9;
    }
    _answers[index] = _nextValue;
    setAnswers(_answers);
    handleUpdateAnswers(_answers);
  };

  const handleUpdateAnswers = (answers) => {
    let _answers = "";
    answers.forEach((answer) => {
      _answers += answer;
    });
    onAnswerUpdated([_answers]);
  };

  return (
    <div className="counting-quiz">
      {answers?.map((value, index) => (
        <Counter
          key={index}
          index={index}
          value={value}
          onUp={handleUp}
          onDown={handleDown}
          solvedData={solvedData}
          showReallyTrue={showReallyTrue}
          correctAnswer={
            solvedData?.correctAnswers &&
            getAnswersArray(
              Number.parseInt(solvedData?.correctAnswers[0]),
              data?.quiz?.digits
            )
          }
        />
      ))}
    </div>
  );
};

export default CountingQuiz;
