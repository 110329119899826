import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Axios from "../../utils/request";
import { message } from "antd";
import moment from "moment";

class Home extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { user, logged } = this.props;
    if (
      user &&
      user.classroom &&
      user.type === "academy_student" &&
      user.classroom.classroomId
    ) {
      Axios.get(
        `/academy/v2/class/${user.classroom.classroomId}/lectures/current`
      )
        .then((res) => {
          console.log("current lecture: ", res);
          if (res.state === "completed") {
            if (user.orgType === "school") {
              this.props.history.push(`/review`);
            } else {
              this.props.history.push(`/homework`);
            }
            return;
          }
          if (!res.current || res.state === "pending") {
            message.warning("수업전입니다.");
            return;
          }
          if (res.current && res.date) {
            // const startTime = new Date(res.date);
            // const now = new Date();

            const startTime = moment(res.date);
            const now = moment(new Date());

            const halfHour = 1000 * 60 * 30;
            console.log(now);
            console.log(startTime);
            console.log(halfHour);
            console.log(parseInt(now - startTime) + parseInt(halfHour) >= 0);
            const isStart = parseInt(now - startTime) + parseInt(halfHour) >= 0;
            // const isStart = false;
            if (isStart) {
              this.props.history.push(
                `/classroom/${user.classroom.classroomId}`
              );
            } else {
              // if (user.orgType === "school") {
              //   this.props.history.push(`/review`);
              // } else {
              // }
              this.props.history.push(`/homework`);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          if (user.orgType === "school") {
            this.props.history.push(`/homework`);
          } else {
            this.props.history.push(`/home`);
          }
        });
    }
  }

  render() {
    const { user, logged, pending } = this.props;
    console.log("home: ", this.props);
    if (pending) {
      return null;
    }
    if (!logged) {
      console.log("not logged: ", this.props);
      this.props.history.push("/login");
    }
    if (user && user.type !== "academy_student") {
      this.props.history.push("/admin/class");
    }
    return (
      <div>
        {/* {user && user.classroom && user.type === "academy_student" && (
          <Link to={`/classroom/${user.classroom.classroomId}`}>수업 시작</Link>
        )} */}
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      pending: auth.pending,
      user: auth.user,
      logged: auth.logged,
    }),
    null
  )(Home)
);
