import React, { Component } from "react";
import BoardApis from "../../../../utils/boardApis";
import {
  Skeleton,
  PageHeader,
  Typography,
  Row,
  Col,
  message,
  Divider,
  Button,
  Popconfirm
} from "antd";
import { withRouter } from "react-router-dom";
import Editor from "../../components/Editor/Editor";

const { Title, Text } = Typography;

export class PostEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null
    };
  }

  componentDidMount() {
    const { postId } = this.props.match.params;

    console.log("post id: ", postId);
    BoardApis.getPost(postId)
      .then(res => {
        console.log("post detail: ", res);
        this.setState({
          data: res
        });
      })
      .catch(e => {
        message.warning("");
      });
  }

  render() {
    const { data } = this.state;
    const { postId } = this.props.match.params;
    return (
      <Skeleton loading={!data} active>
        <PageHeader title={<Title level={2}>게시글 수정하기</Title>} />
        <div className="admin-page-content">
          <Row>
            <Col>
              {data && (
                <Editor
                  isEdit={true}
                  hasType={true}
                  data={data}
                  orgId={this.props.orgId}
                  // onSaved={data => {
                  //   this.props.history.push("/admin/board");
                  // }}
                  onSubmit={data => {
                    BoardApis.editPost(postId, data).then(r => {
                      message.success("저장 성공");
                      this.props.history.push("/admin/board");
                    });
                  }}
                  onBack={() => {
                    this.props.history.push("/admin/board");
                  }}
                />
              )}
            </Col>
          </Row>
        </div>
      </Skeleton>
    );
  }
}

export default PostEditPage;
