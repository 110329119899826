import React, { useState, useEffect } from "react";
import { values, filter } from "lodash";
import HometrainingApis from "../../../utils/hometrainingApis";
import Axios from "../../../utils/request";

const QuizListPopup = props => {
  const { quizList, onPreviewQuiz, onShowAnswer, onSendMessage } = props;
  const [curQuiz, setCurQuiz] = useState(quizList[0]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const { classroomId, seq, lectureData } = props;
    console.log("curQuiz: ", curQuiz);
    console.log("values: ", values(curQuiz.solvedMap));
    console.log(
      "fileter: ",
      filter(values(curQuiz.solvedMap), function (quiz) {
        return quiz.correct === true;
      }).length
    );
    if (curQuiz) {
      setTimeout(() => {
        loadQuiz(curQuiz, Math.floor(classroomId /seq + seq + lectureData.lectureId / seq + 1));
      }, 1000);
      // loadQuiz(curQuiz);
    }
    return () => {
    };
  }, [quizList]);

  const loadQuiz = (quiz, randomSeed) => {
    if (isLoading) {
      return;
    }
    setCurQuiz(quiz);
    console.log("load quiz");
    setLoading(true);
    Axios.get(`/quiz/v2/quizzes/${quiz.quizId}${randomSeed !== null ? `?randomSeed=${randomSeed}` : ''}`)
      .then(response => {
        // this.setState({
        //   isLoading: false,
        //   currentQuiz: response
        // });
        setLoading(false);
        const _data = {
          type: "loadQuiz",
          value: {
            quizId: response.quizId,
            vars: response.quiz.vars,
            randomSeed: randomSeed
          }
        };
        // new BroadcastChannel("QUIZ_CHANNEL").postMessage(_data);
        if (onSendMessage) {
          onSendMessage(_data);
        }
      })
      .catch(e => {
        setLoading(false);
      });
  };
  const { classroomId, seq, lectureData } = props;
  const solveQuiz = () => {
    const _data = {
      type: "solveQuiz",
      value: ""
    };
    // new BroadcastChannel("QUIZ_CHANNEL").postMessage(_data);
    if (onSendMessage) {
      onSendMessage(_data);
    }
  };
  return (
    <div className="popup ps-chk">
      <div className="popup-wrap">
        <div className="popup-content">
          <ul>
            {quizList.map((quiz, index) => {
              const correctCount = filter(values(quiz.solvedMap), function (
                quiz
              ) {
                return quiz.correct === true;
              }).length;
              return (
                <li key={`quiz-${index}`}>
                  <strong>{`${index + 1}번째 문제`}</strong>
                  <span>{`${correctCount}명 정답`}</span>
                  <button
                    onClick={() => loadQuiz(quiz, Math.floor((classroomId / seq + seq + lectureData.lectureId / seq) / (index + 1)))}
                    className="ps-view-btn"
                    style={{ marginRight: 8 }}
                  >
                    보기
                  </button>
                  {curQuiz.quizId === quiz.quizId ? (
                    <button onClick={() => solveQuiz()} className="ps-chk-btn">
                      정답확인
                    </button>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default QuizListPopup;
