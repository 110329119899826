import React, { Component } from "react";
import classNames from "classnames";

class TrueFalseButton extends Component {
  render() {
    const {
      btnType,
      selected,
      isEng,
      onAnswerSet,
      solvedData,
      showReallyTrue,
    } = this.props;
    // const {correct, corrects, correctAnswers } = this.props;

    return (
      <button
        type="button"
        className={classNames({
          "is-chk": !showReallyTrue && selected,
          "is-true":
            !showReallyTrue &&
            selected &&
            solvedData.solved &&
            solvedData.correct,
          "is-false":
            !showReallyTrue &&
            selected &&
            solvedData.solved &&
            !solvedData.correct,
          "is-reallytrue":
            showReallyTrue &&
            btnType === JSON.parse(solvedData.correctAnswers[0]),
          "none-event": solvedData && solvedData.solved,
        })}
        onClick={() => onAnswerSet(!selected ? btnType : null)}
      >
        {btnType === true
          ? isEng
            ? "True"
            : "맞아요"
          : isEng
          ? "False"
          : "틀려요"}
      </button>
    );
  }
}

export default TrueFalseButton;
