import React, { Component } from "react";
import { Modal, Typography, DatePicker, Select, Row, Col, Table, InputNumber } from "antd";
import { filter } from "lodash";

export class AddCoursePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCourse: null,
      startDate: null,
      skipLecture: 0,
    };
  }

  handleOK() {
    const { onSelect } = this.props;
    const { skipLecture } = this.state;
    onSelect({ ...this.state.selectedCourse, startDate: this.state.startDate, skipLecture: this.state.skipLecture });
  }

  render() {
    console.log("selected course: ", this.state.selectedCourse);
    const { skipLecture } = this.state;
    return (
      <Modal {...this.props} onOk={() => this.handleOK()}>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col span={6}>커리큘럼 선택</Col>
          <Col span={18}>
            <Select
              style={{ minWidth: "70%" }}
              placeholder={`선택해주세요 `}
              onChange={(v) => {
                this.setState({
                  selectedCourse: filter(this.props.courseSimpleList, {
                    courseId: v,
                  })[0],
                });
              }}
            >
              {this.props.courseSimpleList
                .sort((a,b) => (a.title > b.title)? 1 : (a.title < b.title)? -1 : 0)
                .map((course, index) => (
                <Select.Option key={index} value={course.courseId}>
                  {course.title}
                  {course.lectures && ` (수업:${course.lectures.length})`}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <Col span={6}>시작일 선택</Col>
          <Col span={18}>
            <DatePicker
              onChange={(date, dateString) => {
                console.log(dateString);
                this.setState({
                  startDate: dateString,
                });
              }}
            />
          </Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <Col span={6}>건너띌 수업 수</Col>
          <Col span={18}>
            <InputNumber
              min={0}
              value={skipLecture}
              onChange={(v) => {
                console.log(v);
                this.setState({
                  skipLecture: v,
                });
              }}
            />
            <small> 복구를 위한 등록시에만 지정; 상시에는 0을 사용</small>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default AddCoursePopup;
