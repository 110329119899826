import React from "react";
import classNames from "classnames";

const ReportQuetureList = ({ rowSpan, list, isBreakPage }) => {
  console.log("reportQuetuerList: ", list)
  return (
    <table
      className={classNames("curri", { "break-page": isBreakPage, })}
    >
      <tbody>
        <tr>
          <th className="title-count">회차</th>
          <th className="title-queture">깨처이름</th>
          <th className="title-activity">주요 개념 및 활동</th>
          <th className="title-queture-correct-rate">학습수준</th>
          <th className="title-queture-homework-queture">복습과제</th>
        </tr>
        {list.map((lecture, index) => {
          // const _quetureList = lecture.quetures.filter((q, i) => q.quizzes);
          const _quetureList = lecture.quetures;
          let wasHomeworkQuetureCompleted = true;

          //return lecture.quetures.map((q, i) => {
          return _quetureList.map((q, i) => {
            // if (!q.quizzes) {
            //    return;  // skip queture without quizzes (by YJ as Sophie's req at 2022-02-04)
            // }

            // NOTE: handle solved=0 case as no-data
            let countClass = (lecture.mvp)? "count_mvp" : "count_normal";
            let correctRate = (!lecture.absent && q.solved && q.quizzes) ? Math.round(100 * q.corrects / q.quizzes) : null;
            let correctState =
              correctRate >= 80 ? <font color="#2020FF">상</font> :
              correctRate >= 20 ? <font color ="#444444">중</font> :
              correctRate >= 0 ?  <font color="#FF2020">하</font> : '-';

            if (lecture.completed && q.homeworkQuetureCompleted === false && wasHomeworkQuetureCompleted) {
                wasHomeworkQuetureCompleted = false;
            }

            return (
                <tr key={`lecture-${index}-${i}`} className={'curri striped-rows-'+((index%2)? 'dark' : 'bright')}
                     style={{ pageBreakBefore: 'auto', pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                  {(i === 0) &&
                  <td rowSpan={_quetureList.length} className="count"
                      style={{ pageBreakBefore: 'auto', pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                    <div className={countClass}>{lecture.title}
                      {!lecture.completed ? <span className="count_absent"><font color="grey"> 미완료</font></span> :
                       lecture.absent ?  <span className="count_absent"> 결석</span> : ''}
                    </div>
                    <div>
                      <span className="count_date">{String(lecture.date).replaceAll('-', '.')}</span>
                    </div>
                  </td>
                  }
                  <td className="queture">
                    {q.title}
                  </td>
                  <td className="activity">
                    { q.point === null || q.point === "" ? q.conceptTags.join(",") : q.point }

                    {/*
                      NOTE: DO NOT EXPOSE q.point valus until major quetures's report_queture_point's are set
                            (now, junior quetures only) (Sophie, 2021-12-13)
                    */}
                    { 
                      /*
                      (q.point && false)? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: q.point.replace("\n", "<br/>"),
                        }}
                      ></div>)
                    : */
                    }
                  </td>
                  <td className="queture_correct_rate">
                    {correctRate != null ? correctState : '-'}
                  </td>
                  <td className="queture_homework_queture">
                    { (!lecture.completed) ? '-' : (q.quizzes ? q.homeworkQuetureCompleted ? 'O' :'X' : q.homeworkQueture ? 'O' : 'X') }
                    {/* (!lecture.completed || !q.quizzes) ? '-' :
                        (q.homeworkQuetureCompleted ? 'O' : 'X')+' ('+
                        (q.quetureStatus == 'ok' ? 'O' : 'X')+'/'+
                        ((q.quizzes <= q.corrects) ? '-' : (q.quizzes <= q.corrects + q.reviewSolved) ? 'O' : 'X')+')' +
                        '['+q.quizzes+' '+q.corrects+' '+q.reviewSolved+']' */}
                  </td>
                  {/*
                  // NOTE: homeworkWorkbook field does not mean quiz solved
                  <td className="queture_homework_workbook">
                    {q.homeworkWorkbook? 'O' : 'X'}
                  </td>
                  <td rowSpan={_quetureList.length} className="correct_rate">
                    {lecture.solved > 0 ? Math.round(100 * lecture.corrects / lecture.solved)+'%' : '-'}
                  </td>
                  */}
                </tr>
              );
          });
        })}
      </tbody>
    </table>
  );
};

export default ReportQuetureList;
