import React, { useEffect, useRef, useState } from "react";
import AudioPlayer from "components/Audio/AudioPlayer";
import BodyMovin from "components/BodyMovin/BodyMovin";
import bgm from "./sounds/attendance-bgm.mp3";
import quedaDefaultSound from "./sounds/attendance-queda-default-sound.mp3";
import quedaBlowbubbleSound from "./sounds/attendance-queda-blowbubble-sound.mp3";

import quedaDefaultAnimation from "./animations/attendance-queda-default.json";
import ddamiNchaeliDefaultAnimation from "./animations/attendance-ddamiNchaeli-default.json";
import quedaBubbleAnimation from "./animations/attendance-queda-blowbubble.json";
import ddamiNchaeliHiAnimation from "./animations/attendance-ddamiNchaeli-hi.json";
import { orderBy, values, mapValues } from "lodash";
import classNames from "classnames";
import "./bubble-animation.scss";
import Bubble from "./Bubble";
import { useInterval } from "react-use";

const BubbleAttendance = (props) => {
  const { attendances, studentList } = props;
  const [isPlay, setPlay] = useState(false);
  const [isStartBubble, setStartBubble] = useState(false);
  const [bubbleList, setBubbleList] = useState([]);

  useInterval(
    () => {
      const _bubbleList = [...bubbleList];

      const _studentList = values(
        mapValues(attendances, function (value, key) {
          value.studentId = key;
          return value;
        })
      )?.filter((student) => {
        if (
          student.status === "ok" &&
          !bubbleList.includes(student.studentId)
        ) {
          _bubbleList.push(student.studentId);
          return true;
        }
      });

      setBubbleList([..._bubbleList]);

      setStartBubble(false);
    },
    isStartBubble ? 2500 : null
  );

  const quedaDefaultOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: quedaDefaultAnimation,
  };
  const ddamiNchaeliDefaultOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: ddamiNchaeliDefaultAnimation,
  };
  const quedaBubbleOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: quedaBubbleAnimation,
  };
  const ddamiNchaeliHiOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: ddamiNchaeliHiAnimation,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPlay(true);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [isPlay]);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        const _attendanceList = values(
          mapValues(attendances, function (value, key) {
            value.studentId = key;
            return value;
          })
        )?.filter((s) => s.status === "ok");
        if (_attendanceList && _attendanceList?.length > 0) {
          setStartBubble(true);
        }
      },
      bubbleList.length > 0 ? 500 : 2500
    );
    return () => {
      clearTimeout(timer);
    };
  }, [attendances]);

  return (
    <div id="bubbleAnimation" className={classNames({})}>
      <div className="img-title"></div>
      {bubbleList.map((bubble, index) => (
        <Bubble key={index} studentId={bubble} studentList={studentList} />
      ))}
      {isStartBubble && (
        <div className="character-anim-wrap">
          <BodyMovin
            className="character-anim other"
            options={ddamiNchaeliHiOptions}
          />
          <BodyMovin
            className="character-anim queda"
            options={quedaBubbleOptions}
          />
        </div>
      )}
      {isPlay && !isStartBubble && (
        <div className="character-anim-wrap">
          {isStartBubble ? (
            <></>
          ) : (
            <>
              <BodyMovin
                className="character-anim other"
                options={ddamiNchaeliDefaultOptions}
              />
              <BodyMovin
                className="character-anim queda"
                options={quedaDefaultOptions}
              />
            </>
          )}
        </div>
      )}

      <AudioPlayer src={bgm} autoPlay={true} loop={true} />
      {isStartBubble ? (
        <AudioPlayer src={quedaBlowbubbleSound} autoPlay={true} loop={true} />
      ) : (
        // <AudioPlayer src={quedaDefaultSound} autoPlay={true} loop={true} />
        <></>
      )}
    </div>
  );
};

const CharacterDefaultAnimation = () => {};

export default BubbleAttendance;
