import Home from "../pages/home";
import Main from "../pages/homeTraining/Main";
import Homework from "../pages/homework";
import Game from "../pages/game";
import UserInfo from "../pages/userinfo";
import Review from "../pages/review";
import MyProfile from "../pages/userinfo/MyProfile";
import QuizPublishing from "../components/Quiz/QuizPublishing";

export default [
  {
    path: "/home",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    // component: Home,
    component: Main
  },
  {
    path: "/homework",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: Homework
  },
  {
    path: "/review",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: Review
  },
  {
    path: "/game",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: Game
  },
  {
    path: "/my-info",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: UserInfo
  },
  {
    path: "/quiz",
    exact: true,
    // permissions: ["org"],
    // redirect: "/login",
    // component: UserInfo
    component: QuizPublishing
  }
];
