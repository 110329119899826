import React, { Component } from "react";
import Alert from "./Alert";

class ErrorDialog extends Component {
  state = {
    isOpen: false,
    error: {}
  };

  componentDidMount() {
    const { error } = this.props;
    if (error.triggered) {
      this.setState({
        isOpen: true,
        error: error
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { error } = nextProps;
    if (error.triggered) {
      this.setState({
        isOpen: true,
        error: error
      });
    }
  }

  close = () => {
    const { onClosed } = this.props;
    this.setState({
      isOpen: false
    });
    if (onClosed) {
      onClosed();
    }
  };

  render() {
    const { isOpen, error, userType } = this.state;
    const { codeMap, onClosed, showCode, title } = this.props;

    var message = error.message;

    if (error.code === 401) {
      message = "다시 로그인 해 주세요.";
    } else if (error.code === 403) {
      message = "권한이 없습니다.";
    } else if (error.code === 500) {
      message = "알 수 없는 문제가 발생했습니다.";
    }

    if (codeMap != null && codeMap[error.code] != null) {
      var val = codeMap[error.code];
      if (typeof val === "object" && error.parameter != null) {
        var paramMsg = val[error.parameter];
        if (paramMsg != null) {
          message = paramMsg;
        } else if (val["default"] != null) {
          message = val["default"];
        }
      } else {
        message = codeMap[error.code];
      }
    }

    console.log("error msg: ", message);

    return (
      <Alert
        type={"alert"}
        isOpen={isOpen}
        buttons="close"
        onClose={() => {
          this.close();
        }}
      >
        <div className="popup-content-wrap">
          <span className="img"></span>
          {showCode && error.code != null && error.code !== "" ? (
            <div className="popup-title">
              Error Code:
              {error.code}
            </div>
          ) : (
            <div className="popup-title">
              {title || "Error"}
            </div>
          )}
          <button
            className="popup-closebtn"
            onClick={() => {
              this.close();
            }}
          ></button>
          <p>{typeof message === "string" ? message.multiline() : message}</p>
        </div>
        {/* <div>
          <p>{message}</p>
          <p>{`Code: ${error.code}`}</p>
        </div> */}
      </Alert>
    );
  }
}

export default ErrorDialog;
