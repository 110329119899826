import React, { Component } from "react";
import update from "immutability-helper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as authActions from "store/modules/auth";
import ErrorDialog from "components/Popup/ErrorDialog";
import Toast from "components/Toast/Toast";
import Alert from "components/Popup/Alert";

import { SESSION_KEY } from "utils/APIUtils";

class AuthMonitor extends Component {
  state = {
    error: {
      triggered: false,
    },
  };

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.logged !== this.props.logged && !this.props.logged) {
      const { type } = prevProps;
      var isAdmin = false;
      if (type === "org" || type === "guide") {
        isAdmin = true;
      }

      if (this.props.type === "parent") {
        this.props.history.push("/home");
        console.log("auth monitor1: ");
      } else {
        console.log("auth monitor2: ");
        this.props.history.push(isAdmin ? "/admin-login" : "/login");
      }
    }
  }

  componentWillUpdate(nextProps) {
    const { AuthActions, migrateUser } = this.props;

    const { error } = nextProps;

    if (error.triggered && migrateUser == null) {
      const { code, parameter } = error;

      let message;

      console.log("code: ", code);
      console.log("parameter: ", parameter);
      if (code === 401001 || code === 401002) {
        message =
          "잘못된 계정 또는 비밀번호가 입력되었습니다.\n다시 시도해 주세요.";
      } else if (code === 400001) {
        if (parameter === "account") {
          message =
            "잘못된 계정 또는 비밀번호가 입력되었습니다.\n다시 시도해 주세요.";
        } else if (parameter === "password") {
          message =
            "잘못된 계정 또는 비밀번호가 입력되었습니다.\n다시 시도해 주세요.";
        } else {
          message = "Invalid " + parameter;
        }
      } else if (code === 400002) {
        if (parameter === "account") {
          message = "계정을 입력해 주세요.";
        } else if (parameter === "password") {
          message = "비밀번호를 입력해 주세요.";
        }
      } else if (code === 403001) {
        message = "이미 종료된 클래스이므로 로그인이 불가능 합니다.";
      } else if (code === 403601) {
        message =
          "서비스 이용 기간이 만료되어 로그인이 제한되었습니다.\n관련 사항은 캠퍼스로 문의 바랍니다.";
      } else {
        console.log(error);
        message = error.message;
      }

      Toast.warning(message);

      this.setState({
        error: {
          triggered: true,
          error: error,
          message: message,
        },
      });

      AuthActions.clearError();
    }
  }

  check = () => {
    const { AuthActions, logged } = this.props;

    const preAuth = localStorage.getItem(SESSION_KEY);
    if (preAuth == null) {
      console.log(1);
      AuthActions.init();
      return;
    }

    if (preAuth != null) {
      console.log("refresh user");
      AuthActions.refresh();
    }
  };

  render() {
    const { error } = this.state;
    const { type, duplicated, AuthActions } = this.props;

    return null;

    // if (duplicated) {
    //   return (
    //     <div>
    //       <Alert
    //         type={"alert"}
    //         isOpen={duplicated}
    //         buttons="close"
    //         onClose={() => {
    //           AuthActions.duplicatedLoginDetected(false);
    //         }}
    //       >
    //         <div className="popup-content-wrap">
    //           <div className="popup-title">
    //             <strong>{"중복 로그인"}</strong>
    //           </div>
    //           <button
    //             className="popup-closebtn"
    //             onClick={() => {
    //               AuthActions.duplicatedLoginDetected(false);
    //             }}
    //           />
    //           <p>{"다른 기기에서 로그인 되었습니다."}</p>
    //         </div>
    //       </Alert>
    //     </div>
    //   );
    // }

    return (
      <div>
        <ErrorDialog
          userType={type}
          error={error}
          codeMap={{
            401001:
              "잘못된 계정 또는 비밀번호가 입력되었습니다\n다시 시도해 주세요.",
            401002:
              "잘못된 계정 또는 비밀번호가 입력되었습니다.\n다시 시도해 주세요.",
            400001: {
              default: "입력된 값을 확인해주세요.",
              account:
                "잘못된 계정 또는 비밀번호가 입력되었습니다.\n다시 시도해 주세요.",
              password:
                "잘못된 계정 또는 비밀번호가 입력되었습니다.\n다시 시도해 주세요.",
            },
            400002: {
              default: "입력되지 않은 값이 있습니다.",
              account: "계정을 입력해 주세요.",
              password: "비밀번호를 입력해 주세요.",
            },
            403001: {
              default: "이용 기간이 만료되었습니다.결제해주세요.",
            },
          }}
          onClosed={() =>
            this.setState({
              error: update(error, {
                triggered: {
                  $set: false,
                },
              }),
            })
          }
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      logged: auth.logged,
      type: auth.user.type,
      error: auth.error,
    }),
    (dispatch) => ({
      AuthActions: bindActionCreators(authActions, dispatch),
    })
  )(AuthMonitor)
);
