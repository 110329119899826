import tentens from "./img/tentens.png";
import manman from "./img/manman.png";
import caoft from "./img/caoft.png";
import kkaebangjung from "./img/kkaebangjung.png";
import sachic from "./img/sachic.png";
import ddamiNemo from "./img/ddami-nemo.png";
import plusbubble from "./img/plusbubble.png";
import starRoad from "./img/star-road.png";
import kkadaDragon from "./img/kkaeda-dragon.png";
import toRect from "./img/kkaeda-sagac.png";
import penguinRun from "./img/penguin-run.png";
import penguinIgloo from "./img/penguin-igloo.png";
import penguinFishing from "./img/penguin-fishing.png";
import penguinHomecomming from "./img/penguin-homecomming.png";
import penguinMigration from "./img/penguin-migration.png";
import penguinGround from "./img/penguin-ground.png";
import sphinxTreasure from "./img/sphinx-treasure.png";
import watchMaker from "./img/watch-maker.png";
import hunterBear from "./img/hunter-bear.png"; //
import slimelab from "./img/slimelab.png";
import quebonBakery from "./img/quebonBakery.png";
import newTentens from "./img/new-tentens.png";
import aquearium from "./img/aquearium.png";
import penguincube from "./img/penguincube.png";
import alienDefence from "./img/alienDefence.png";
import dduckguck from "./img/dduckguck.png";
import spaceBubble from "./img/spaceBubble.png";
import multiPangPang from "./img/multipangpang.png";
import rabbitFarm from "./img/rabbitfarm.png";
import festival from "./img/festival.png";
import rabbitAlchemist from "./img/rabbitAlchemist.png";
import rabbitTrain from "./img/rabbitTrain.png";
import bubbles from "./img/bubbles.png";
import detective from "./img/detective.png";
import explorer from "./img/explorer.png";
import snowfight from "./img/snowfight.png";
import quegudan from "./img/quegudan.png";
import quegudan2 from './img/quegudan2.png';

// 모바일
import Mtentens from "./img/m-tentens.png";
import Mmanman from "./img/m-manman.png";
import Mcaoft from "./img/m-caoft.jpg";
import Mkkaebangjung from "./img/m-kkaebangjung.jpg";
import Msachic from "./img/m-sachic.png";
import MddamiNemo from "./img/m-ddamiNemo.png";
import Mplusbubble from "./img/m-plusbubble.png";
import MstarRoad from "./img/m-starRoad.png";
import MkkadaDragon from "./img/m-kkaeda-dragon.jpg";
import MtoRect from "./img/m-kkaeda-sagac.png";
import MpenguinRun from "./img/m-penguin-run.png";
import MpenguinIgloo from "./img/m-penguin-igloo.png";
import MpenguinFishing from "./img/m-penguin-fishing.png";
import MpenguinHomecomming from "./img/m-penguin-homecomming.png";
import MpenguinMigration from "./img/m-penguin-migration.png";
import MpenguinGround from "./img/m-penguin-ground.png";
import MsphinxTreasure from "./img/m-sphinx-treasure.png";
import MwatchMaker from "./img/m-watch-maker.png";
import MhunterBear from "./img/m-hunter-bear.png"; //
import Mslimelab from "./img/m-slimelab.png";
import MquebonBakery from "./img/m-quebonBakery.png";
import MnewTentens from "./img/m-new-tentens.png";
import Maquearium from "./img/m-aquearium.png";
import Mpenguincube from "./img/m-penguincube.png";
import MalienDefence from "./img/m-alienDefence.png";
import Mdduckguck from "./img/m-dduckguck.png";
import MspaceBubble from "./img/m-spaceBubble.png";
import MmultiPangPang from "./img/m-multipangpang.png";
import MrabbitFarm from "./img/m-rabbitfarm.png";
import Mfestival from "./img/m-festival.png";
import MrabbitAlchemist from "./img/m-rabbitAlchemist.png";
import MrabbitTrain from "./img/m-rabbitTrain.png";
import Mbubbles from "./img/m-bubbles.png";
import Mdetective from "./img/m-detective.png";
import Mexplorer from "./img/m-explorer.png";
import Msnowfight from "./img/m-snowfight.png";
import Mquegudan from "./img/m-quegudan.png";
import Mquegudan2 from './img/m-quegudan2.png';

import {
  PenguinRunComponent,
  PenguinRunEasyComponent,
  PenguinGroupingComponent,
  PenguinFishingComponent,
  PenguinHomecommingComponent,
  PenguinMigrationComponent,
  PenguinCastleComponent,
  Penguin3DComponent,
  watchMakerComponent,
  bowMasterComponent,
  slimeLabComponent,
  // PenguinArithmeticComponent,
  tentensComponent,
  AqueariumComponent,
  PenguinCubeComponent,
  alienDefenceComponent,
  dduckguckComponent,
  spaceBubbleComponent,
  MultiPangPangComponent,
  lostTempleComponent,
  festivalComponent,
  rabbitAlchemistComponent,
  rabbitTrainComponent,
  bubblesComponent,
  detectiveComponent,
  explorerComponent,
  snowfightComponent,

  //MAJOR
  dragonHunterComponent,
  mmbbComponent,
  pizzaComponent,
  cotComponent,
  plusBubbleComponent,
  equationComponent,
  arithPuzzleComponent,
  starRoadComponent,

  queboardLight,
  Quegudan2,
  //KidsGame
  KidsPenguinRunComponent,
  KidsPenguinFishingComponent,
  KidsPenguinMigrationComponent,
  KidsPenguinGroupingComponent,
  KidsPenguinHomecommingComponent,
  KidsSlimeLabComponent,
  KidsTenTensComponent,
  KidsSpaceBubbleComponent,
  KidsPenguinCastleComponent,
  KidsAqueariumComponent,
  KidsWatchMakerComponent
} from './components';

const isDev = process.env.REACT_APP_TEST === 'true';

export default {
  Quegudan: {
    ko: {
      name: "깨구단",
      desc: '곱하기는 직사각형! 떨어지는 곱셈 블럭과 수 블럭을 보고 직사각형 모양으로 알맞게 만들어 보아요.'
    },
    en: {
      name: 'Quegudan',
      desc: '곱하기는 직사각형! 떨어지는 곱셈 블럭과 수 블럭을 보고 직사각형 모양으로 알맞게 만들어 보아요.'
    },
    image: quegudan,
    mobileImage: Mquegudan,
    runType: "js",
    component: queboardLight,
    type: 'number',
    mobile: true,
    app: false,
    width: 1024,
    height: 768
  },
  Quegudan2: {
    ko: {
      name: "깨구단2",
      desc: '곱하기를 보면 떠올려야 하는 건? 직사각형! 열 개씩 묶으면서 똑똑하고 빠르게 개수를 세어 보아요.'
    },
    en: {
      name: 'Quegudan2',
      desc: '곱하기를 보면 떠올려야 하는 건? 직사각형! 열 개씩 묶으면서 똑똑하고 빠르게 개수를 세어 보아요.'
    },
    image: quegudan2,
    mobileImage: Mquegudan2,
    runType: "js",
    component: Quegudan2,
    type: 'number',
    mobile: true,
    app: false,
    width: 1024,
    height: 768
  },
  KMG: {
    ko: {
      name: '만만빵빵',
      desc: '팔천, 구천 다음은? 십천! 이걸 이미 알았다면 바로 도전해봐~'
    },
    en: {
      name: 'MAANMAAN BBANGBBANG',
      desc: 'Eight thousand, nine thousand, and then what? Ten thousand! If you know this already, try it right away.'
    },
    image: manman,
    mobileImage: Mmanman,
    runType: "js",
    component: mmbbComponent,
    type: 'racing',
    mobile: true,
    app: false,
    width: 1024,
    height: 768,
  },
  TriFunction: {
    ko: {
      name: '캐오트',
      desc: '으악! 몬스터들이 몰려오고 있잖아... 지금 깨다에겐 삼각함수 무기가 필요해!'
    },
    en: {
      name: 'CASTLE OF TRI-KNIGHT',
      desc: 'Uhh! The monsters are coming... Quickie needs a triangulation weapon just right now!'
    },
    image: caoft,
    mobileImage: Mcaoft,
    runType: "js",
    type: 'defense',
    component: cotComponent,
    mobile: true,
    app: false,
    width: 1024,
    height: 768,
  },
  Equation: {
    ko: {
      name: '깨방정',
      desc: '깨다와 연산 미사일을 모아 방정식을 풀며 우주를 여행하자~'
    },
    en: {
      name: 'BREAK DOWN',
      desc: `Let's take a trip to space, solve the equation, and collect the missiles with Quickie.`
    },
    image: kkaebangjung,
    mobileImage: Mkkaebangjung,
    runType: "js",
    component: equationComponent,
    type: 'shooting',
    mobile: true,
    app: false,
    width: 1024,
    height: 768,
  },
  ArithPuzzle: {
    ko: {
      name: '사칙팡팡',
      desc: '깨다가 낸 사칙연산 퍼즐! 깨다의 콧대를 납작하게 눌러주자!'
    },
    en: {
      name: 'ARITH PANGPANG',
      desc: 'Quickie is giving arithmetic operation puzzles! Let’s dominate Quickie!'
    },
    image: sachic,
    mobileImage: Msachic,
    runType: "js",
    component: arithPuzzleComponent,
    type: 'puzzle',
    mobile: true,
    app: false,
    width: 1024,
    height: 768,
  },
  Pizza: {
    ko: {
      name: '따미의 네모피자',
      desc: '곱하기, 직사각형, 확률을 안다면 재료를 얻어 맛있는 피자를 만들어 보세요!'
    },
    en: {
      name: 'SWEATIE’S PIZZA SHOP',
      desc: 'If you know multiplication, rectangle, and probability, make delicious pizzas with the ingredients.'
    },
    image: ddamiNemo,
    mobileImage: MddamiNemo,
    runType: "js",
    type: 'casual',
    component: pizzaComponent,
    mobile: true,
    app: false,
    width: 1024,
    height: 768,
  },
  Seven: {
    ko: {
      name: '플러스 버블',
      desc: '10을 만들까? 7을 만들까? 원하는 숫자만큼 바닷속 버블을 연결해보세요!'
    },
    en: {
      name: 'PLUS BUBBLE',
      desc: 'Should I make a 10? Make a 7? Connect the bubbles undersea until the number you want!'
    },
    image: plusbubble,
    mobileImage: Mplusbubble,
    runType: "js",
    type: 'casual',
    component: plusBubbleComponent,
    mobile: true,
    app: false,
    width: 1024,
    height: 768
  },
  StarRoad: {
    ko: {
      name: '스타로드',
      desc: '오늘 밤은 무슨 별자리가 보일까? 별자리와 함께 떠나는 선형대수학 우주여행!'
    },
    en: {
      name: 'STAR ROAD',
      desc: 'What constellations will we see tonight? Linear algebra space travel with the constellations!'
    },
    image: starRoad,
    mobileImage: MstarRoad,
    runType: "js",
    component: starRoadComponent,
    type: 'puzzle',
    mobile: true,
    app: false,
    width: 1024,
    height: 768,
  },
  DragonHunt: {
    ko: {
      name: '깨다의 드래곤 헌팅',
      desc: '소인수분해로 용 잡으러 가자! 깨다의 드래곤 헌팅! 같이 떠나볼래?'
    },
    en: {
      name: 'QUEDA’S  DRAGON HUNTING',
      desc: `Let’s hunt the dragon using factorization! Quickie’s dragon hunting! Want to go on a journey with me?`
    },
    image: kkadaDragon,
    mobileImage: MkkadaDragon,
    runType: "js",
    component: dragonHunterComponent,
    type: 'action',
    mobile: true,
    app: false,
    width: 1024,
    height: 768,
  },
  ToRect: {
    ko: {
      name: '깨다의 사각사각',
      desc: '따미에게 과자를 선물하려는 깨다! 요리조리 잘라 붙여 사각형을 만들면 과자 완성!'
    },
    en: {
      name: '깨다의 사각사각',
      desc: '따미에게 과자를 선물하려는 깨다! 요리조리 잘라 붙여 사각형을 만들면 과자 완성!'
    },
    image: toRect,
    mobileImage: MtoRect,
    runType: "unity",
    type: 'puzzle',
    mobile: false,
    app: false,
    dev: true,
  },
  PenguinRun: {
    ko: {
      name: '펭귄RUN',
      desc: `먹이를 구하러 떠나는 펭귄이 물에 빠지지 않게 숫자를 맞춰보세요.`,
      desc2: `숫자 0~9를 눈으로 바로 알 수 있어요. <br /> 먹이를 구하러 떠나는 펭귄이 물에 빠지지 않게 숫자를 맞춰보세요.`,
    },
    en: {
      name: 'Penguin Run',
      desc: `The penguin is off to get food. Choose the right number so that the penguin does not fall into the water.`,
      desc2: `숫자 0~9를 눈으로 바로 알 수 있어요. <br /> 먹이를 구하러 떠나는 펭귄이 물에 빠지지 않게 숫자를 맞춰보세요.`,
    },
    image: penguinRun,
    mobileImage: MpenguinRun,
    runType: "js",
    component: PenguinRunComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  PenguinRunEasy: {
    // NOTE: 화면에 보여지는 이름은 쉬운버전도 동일; 어드민 "코스" 설정에서만 "펭귄런(쉬운버전)"으로 구분됨
    ko: {
      name: '펭귄RUN',
      desc: `먹이를 구하러 떠나는 펭귄이 물에 빠지지 않게 숫자를 맞춰보세요.`,
      desc2: `숫자 0~9를 눈으로 바로 알 수 있어요. <br /> 먹이를 구하러 떠나는 펭귄이 물에 빠지지 않게 숫자를 맞춰보세요.`,
    },
    en: {
      name: 'Penguin Run',
      desc: `The penguin is off to get food. Choose the right number so that the penguin does not fall into the water.`,
      desc2: `숫자 0~9를 눈으로 바로 알 수 있어요. <br /> 먹이를 구하러 떠나는 펭귄이 물에 빠지지 않게 숫자를 맞춰보세요.`,
    },
    image: penguinRun,
    mobileImage: MpenguinRun,
    runType: "js",
    component: PenguinRunEasyComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  PenguinGrouping: {
    ko: {
      name: '펭귄이글루',
      desc: `주어진 숫자를 보면서 이글루를 몇 개 만들 수 있는지 알아보아요.`,
      desc2: `펭귄과 이글루가 십이 되면 묶는 것으로부터 낱개, 십의 자리, 백의 자리를 알고 펭귄의 마릿수를 구할 수 있어요.`,
    },
    en: {
      name: 'Penguin Igloo',
      desc: `Referring to the number given, find out how many igloos can be made.`,
      desc2: `펭귄과 이글루가 십이 되면 묶는 것으로부터 낱개, 십의 자리, 백의 자리를 알고 펭귄의 마릿수를 구할 수 있어요.`,
    },
    image: penguinIgloo,
    mobileImage: MpenguinIgloo,
    runType: "js",
    component: PenguinGroupingComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  PenguinFishing: {
    ko: {
      name: '펭귄낚시',
      desc: `어떻게 그물을 치면 물고기를 빨리 잡을 수 있을까요?`,
      desc2: `잡아야 하는 물고기의 마릿수를 보고 어떻게 그물을 치면 빨리 잡을 수 있을지 생각해 보세요.`,
    },
    en: {
      name: 'Penguin Fishing',
      desc: `Think about the fastest way to cast a net to get fish.`,
      desc2: `잡아야 하는 물고기의 마릿수를 보고 어떻게 그물을 치면 빨리 잡을 수 있을지 생각해 보세요.`,
    },
    image: penguinFishing,
    mobileImage: MpenguinFishing,
    runType: "js",
    component: PenguinFishingComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  PenguinHomecoming: {
    ko: {
      name: '펭귄홈커밍',
      desc: `펭귄이 무사히 바다를 건널 수 있도록 십 묶음을 떠올리며 수의 단위를 즉시 맞춰보아요.`,
      desc2: `펭귄이 무사히 바다를 건널 수 있도록 십 묶음을 떠올리며 수의 단위를 즉시 맞춰 보아요.`,
    },
    en: {
      name: 'Penguin Coming Home',
      desc: `Guess the correct digit thinking about ten bundles so that the penguin safely crosses the sea.`,
      desc2: `펭귄이 무사히 바다를 건널 수 있도록 십 묶음을 떠올리며 수의 단위를 즉시 맞춰 보아요.`,
    },
    image: penguinHomecomming,
    mobileImage: MpenguinHomecomming,
    runType: "js",
    component: PenguinHomecommingComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  PenguinMigration: {
    ko: {
      name: '펭귄대이동',
      desc: `함께 지내게 되는 펭귄들을 단위에 맞게 정렬하면 모두 몇 마리인지 알 수 있어요.`,
      desc2: `흩어져 있는 펭귄들을 단위에 맞게 정렬하여 더하면 모두 몇 마리인지 알 수 있어요.`
    },
    en: {
      name: 'Penguin Great Migration',
      desc: `함께 지내게 되는 펭귄들을 단위에 맞게 정렬하면 모두 몇 마리인지 알 수 있어요.`,
      desc2: `흩어져 있는 펭귄들을 단위에 맞게 정렬하여 더하면 모두 몇 마리인지 알 수 있어요.`
    },
    image: penguinMigration,
    mobileImage: MpenguinMigration,
    runType: "js",
    component: PenguinMigrationComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  PenguinGround: {
    ko: {
      name: '펭귄그라운드',
      desc: `만질 수 있는 얼음벽돌의 여러가지 모양을 보고 그 이름과 특징을 알 수 있어요.`,//TODO
      desc2: `여러 가지 모양의 얼음벽돌을 보고 그 이름과 특징을 알 수 있어요.`
    },
    en: {
      name: 'Penguin Ground',
      desc: `Find out the names and features of various shapes of ice blocks.`,
      desc2: `여러 가지 모양의 얼음벽돌을 보고 그 이름과 특징을 알 수 있어요.`
    },
    image: penguinGround, //TODO
    mobileImage: MpenguinGround,
    runType: "js",
    component: PenguinCastleComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  Penguin3D: {
    ko: {
      name: '스핑크스의 보물',
      desc: `입체도형을 잘랐을 때 나온 모양을 떠올려보고 그 특징을 맞춰보아요.`,//TODO
      desc2: `입체도형을 어떤 방향에 따라 잘랐을 때 나온 모양을 떠올려보고 그 특징을 맞춰 보아요.`
    },
    en: {
      name: 'Treasures of Sphinx',
      desc: `Picture the plane figure cut from a solid figure and guess the features.`,
      desc2: `입체도형을 어떤 방향에 따라 잘랐을 때 나온 모양을 떠올려보고 그 특징을 맞춰 보아요.`
    },
    image: sphinxTreasure,//TODO
    mobileImage: MsphinxTreasure,
    runType: "js",
    component: Penguin3DComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  watchMaker: {
    ko: {
      name: '만능시계공',
      desc: `현재 시각에 맞게 주어진 시계의 시와 분을 직접 맞춰서 고쳐보아요.`,
      desc2: `주어진 시각에 알맞게 시계의 시와 분을 직접 맞춰 보거나 골라 주세요.`
    },
    en: {
      name: 'All-Round Repairman',
      desc: `Fix the clocks by setting the correct hour and minute.`,
      desc2: `주어진 시각에 알맞게 시계의 시와 분을 직접 맞춰 보거나 골라 주세요.`
    },
    image: watchMaker,
    mobileImage: MwatchMaker,
    runType: "js",
    component: watchMakerComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  HunterBear: {
    ko: {
      name: '헌터베어',
      desc: `주어진 단위를 보고 같은 값을 나타내는 또 다른 단위를 골라 명중해보아요.`,
      desc2: `주어진 길이, 무게, 들이의 단위를 보고 같은 값을 나타내는 또 다른 단위를 찾아 명중해 보아요.`
    },
    en: {
      name: 'Hunter Bear',
      desc: `Shoot the target which represents the same unit as the given one.`,
      desc2: `주어진 길이, 무게, 들이의 단위를 보고 같은 값을 나타내는 또 다른 단위를 찾아 명중해 보아요.`
    },
    image: hunterBear,
    mobileImage: MhunterBear,
    runType: "js",
    component: bowMasterComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  slimeLab: {
    ko: {
      name: '슬라임 연구소',
      desc: `주어진 크기대로 슬라임이 커지려면 얼마나 더해야 하는지 그 변화를 맞춰보아요.`,
      desc2: `주어진 크기대로 슬라임이 커지려면 얼마나 더해야 하는지 그 변화를 맞춰 보아요.`
    },
    en: {
      name: 'Slime Institute',
      desc: `Find the change in order to make the slime as big as the given one.`,
      desc2: `주어진 크기대로 슬라임이 커지려면 얼마나 더해야 하는지 그 변화를 맞춰 보아요.`
    },
    image: slimelab,
    mobileImage: Mslimelab,
    runType: "js",
    component: slimeLabComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  PenguinArithmetic: {
    // ko: {
    //   name: '깨봉 베이커리',
    //   desc: `빵을 산 손님에게 거스름돈을 주는 과정을 십보수의 개념을 이용한 물물교환으로 생각할 수 있어요.`,
    //   desc2: `빵을 산 손님에게 거스름돈을 주는 과정을 십보수의 개념을 이용한 물물교환으로 생각할 수 있어요.`
    // },
    // en: {
    //   name: '깨봉 베이커리',
    //   desc: `빵을 산 손님에게 거스름돈을 주는 과정을 십보수의 개념을 이용한 물물교환으로 생각할 수 있어요.`,
    //   desc2: `빵을 산 손님에게 거스름돈을 주는 과정을 십보수의 개념을 이용한 물물교환으로 생각할 수 있어요.`
    // },
    // image: quebonBakery,
    // mobileImage: MquebonBakery,
    // runType: "js",
    // component: PenguinArithmeticComponent,
    // type: 'transition',
    // mobile: true,
    // app: false,
    // junior: true,
    // width: 1024,
    // height: 768,
    ko: {
      name: '깨봉베이커리',
      desc: `주문서에 나온대로 빵을 자르고 빵의 길이를 나타내는 식을 세워 보아요.`,
      desc2: `주문서에 나온대로 빵을 자른 뒤에 빵의 길이를 나타내는 식을 세울 수 있어요.`
    },
    en: {
      name: 'Quebon Bakery',
      desc: `Cut the bread according to the order form and make the equation which represents the length of the bread.`,
      desc2: `주문서에 나온대로 빵을 자른 뒤에 빵의 길이를 나타내는 식을 세울 수 있어요.`
    },
    image: dduckguck,
    mobileImage: Mdduckguck,
    runType: "js",
    component: dduckguckComponent,
    type: 'transition',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  tentens: {
    ko: {
      name: '텐텐스',
      desc: `숫자의 합이 십 또는 2십이 되게 만들다 보면 십보수 개념이 자연스럽게 들어와요!`,
      desc2: `십보수의 개념을 생각하면서 숫자의 합이 십 또는 2십이 되도록 만들어 보아요.`
    },
    en: {
      name: 'TENTENS',
      desc: `Choosing the numbers making ten or 2 ten naturally helps think of the tens complements.`,
      desc2: `십보수의 개념을 생각하면서 숫자의 합이 십 또는 2십이 되도록 만들어 보아요.`
    },
    image: newTentens,
    mobileImage: MnewTentens,
    runType: "js",
    component: tentensComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
    duplicated: "MakeTens"
  },
  Aquearium: {
    ko: {
      name: '아깨아리움',
      desc: `채우거나 빼야할 물의 양이 곧 더하거나 거꾸로 더한 변화라는 것을 알 수 있어요.`,
      desc2: `빼야할 물의 양이 곧 거꾸로 더한 변화라는 것을 알고 거꾸로를 익혀 보아요.`
    },
    en: {
      name: 'Aquearium',
      desc: `The amount of water to pour in or take out represents the change of adding or the opposite of adding.`,
      desc2: `빼야할 물의 양이 곧 거꾸로 더한 변화라는 것을 알고 거꾸로를 익혀 보아요.`
    },
    image: aquearium,
    mobileImage: Maquearium,
    runType: "js",
    component: AqueariumComponent,
    type: 'transition',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  PenguinCube: {
    ko: {
      name: '펭귄큐브',
      desc: `얼음벽돌을 옮기고 채워가면서 더하기와 빼기가 있는 식을 풀어보아요.`,
      desc2: `더하기와 빼기가 있는 식에 맞게 얼음벽돌을 옮기고 채우면 식의 결과를 알 수 있어요.`
    },
    en: {
      name: 'Penguin Cube',
      desc: `Solve the equations by moving and filling in the ice bricks.`,
      desc2: `더하기와 빼기가 있는 식에 맞게 얼음벽돌을 옮기고 채우면 식의 결과를 알 수 있어요.`
    },
    image: penguincube,
    mobileImage: Mpenguincube,
    runType: "js",
    component: PenguinCubeComponent,
    type: 'transition',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  alienDefence: {
    ko: {
      name: '지구를 지켜라',
      desc: `터렛이 있는 위치로 트럭이 이동할 수 있도록 덧셈식 또는 뺄셈식을 만들어 주세요.`,
      desc2: `몬스터를 물리치기 위해 터렛이 있는 위치로 트럭이 이동할 수 있도록 덧셈식 또는 뺄셈식을 만들어 주세요.`
    },
    en: {
      name: 'Save the Earth',
      desc: `Make addition or subtraction equations to move the truck to right turret.`,
      desc2: `몬스터를 물리치기 위해 터렛이 있는 위치로 트럭이 이동할 수 있도록 덧셈식 또는 뺄셈식을 만들어 주세요.`
    },
    image: alienDefence,
    mobileImage: MalienDefence,
    runType: "js",
    component: alienDefenceComponent,
    type: 'transition',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  DDuckGuck: {
    ko: {
      name: '깨봉베이커리',
      desc: `주문서에 나온대로 빵을 자르고 빵의 길이를 나타내는 식을 세워 보아요.`,
      desc2: `주문서에 나온대로 빵을 자른 뒤에 빵의 길이를 나타내는 식을 세울 수 있어요.`
    },
    en: {
      name: 'Quebon Bakery',
      desc: `Cut the bread according to the order form and make the equation which represents the length of the bread.`,
      desc2: `주문서에 나온대로 빵을 자른 뒤에 빵의 길이를 나타내는 식을 세울 수 있어요.`
    },
    image: dduckguck,
    mobileImage: Mdduckguck,
    runType: "js",
    component: dduckguckComponent,
    type: 'transition',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  spaceBubble: {
    ko: {
      name: '펭귄팩토리',
      desc: `두 수의 크기를 비교하여 알맞은 등호 또는 부등호가 있는 방향으로 얼음을 분류해 주세요.`,
      desc2: `두 수의 크기를 비교하여 알맞은 등호 또는 부등호가 있는 방향으로 얼음을 분류해 주세요.`
    },
    en: {
      name: 'Penguin Factory',
      desc: `Sort the ice bricks to the correct direction of equality or inequality symbol by comparing two given numbers.`,
      desc2: `두 수의 크기를 비교하여 알맞은 등호 또는 부등호가 있는 방향으로 얼음을 분류해 주세요.`
    },
    image: spaceBubble,
    mobileImage: MspaceBubble,
    runType: "js",
    component: spaceBubbleComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  MultiPangPang: {
    ko: {
      name: '곱셈팡팡',
      desc: `+, –, ×를 최소한의 횟수로 사용하여 주어진 색깔 블록의 모양으로 똑같이 만들어 보아요.`,
      desc2: `+, –, × 기호를 사용해서 주어진 색깔 블록의 모양으로 똑같이 만들어 보아요.`
    },
    en: {
      name: 'Multiplication Pop',
      desc: `Make the same shape of color blocks using the least number of +, –, × operations.`,
      desc2: `+, –, × 기호를 사용해서 주어진 색깔 블록의 모양으로 똑같이 만들어 보아요.`
    },
    image: multiPangPang,
    mobileImage: MmultiPangPang,
    runType: "js",
    component: MultiPangPangComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  lostTemple: {
    ko: {
      name: '래빗 팜',
      desc: `당근을 직사각형 모양의 밭에 심고 수확하면서 곱셈식을 풀고 당근의 개수를 구해 보아요.`,
      desc2: `곱셈식에 따라 자리에 알맞게 당근을 심고 수확한 당근의 개수를 구해 보아요.`
    },
    en: {
      name: 'Rabbit Farm',
      desc: `Solve multiplication and get the number of carrots by planting and harvesting the carrots in the rectangular shaped field.`,
      desc2: `곱셈식에 따라 자리에 알맞게 당근을 심고 수확한 당근의 개수를 구해 보아요.`
    },
    image: rabbitFarm,
    mobileImage: MrabbitFarm,
    runType: "js",
    component: lostTempleComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  festival: {
    ko: {
      name: '래빗 페스티벌',
      desc: `십으로 묶인 당근 조각들로 당근주스를 만들면서 나눗셈식의 몫과 나머지를 이해할 수 있어요.`,
      desc2: `십으로 묶인 당근 조각들로 당근주스를 만들면서 나눗셈식의 몫과 나머지를 이해할 수 있어요.`
    },
    en: {
      name: 'Rabbit Festival',
      desc: `Get the sense of quotient and remainder in division while making carrot juice using the carrot pieces grouped by ten.`,
      desc2: `십으로 묶인 당근 조각들로 당근주스를 만들면서 나눗셈식의 몫과 나머지를 이해할 수 있어요.`
    },
    image: festival,
    mobileImage: Mfestival,
    runType: "js",
    component: festivalComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  rabbitAlchemist: {
    ko: {
      name: '래빗 연금술',
      desc: `0을 더하거나 곱할 때, 또는 0으로 나누면 어떻게 변신이 되는지를 보고 0의 파워를 느껴 보아요.`,
      desc2: `0을 더하거나 곱할 때, 또는 0으로 나누면 어떻게 변신이 되는지를 보고 0의 파워를 느껴 보아요.`
    },
    en: {
      name: 'Rabbit Alchemy',
      desc: `Feel the power of 0 by the change it brings when it’s added, multiplied or divided.`,
      desc2: `0을 더하거나 곱할 때, 또는 0으로 나누면 어떻게 변신이 되는지를 보고 0의 파워를 느껴 보아요.`
    },
    image: rabbitAlchemist,
    mobileImage: MrabbitAlchemist,
    runType: "js",
    component: rabbitAlchemistComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  rabbitTrain: {
    ko: {
      name: '래빗 트레인',
      desc: `토끼 마릿수의 변화를 보면서 규칙을 알게 되면 몇 번째에는 토끼가 몇 마리인지 알 수 있어요.`,
      desc2: `토끼 마릿수의 변화를 보면서 규칙을 알게 되면 몇 번째에는 토끼가 몇 마리인지 알 수 있어요.`
    },
    en: {
      name: 'Rabbit Train',
      desc: `It’s easy to know how many rabbits there would be once you figure out the rule from the change in the number of rabbits.`,
      desc2: `토끼 마릿수의 변화를 보면서 규칙을 알게 되면 몇 번째에는 토끼가 몇 마리인지 알 수 있어요.`
    },
    image: rabbitTrain,
    mobileImage: MrabbitTrain,
    runType: "js",
    component: rabbitTrainComponent,
    type: 'transition',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  bubbles: {
    ko: {
      name: '버블즈',
      desc: `구슬의 개수를 곱셈식으로 나타낸 것을 보고 직사각형으로 표현할 수 있어요.`,
      desc2: `구슬의 개수를 곱셈식으로 나타낸 것을 보고 직사각형으로 표현할 수 있어요.`
    },
    en: {
      name: 'Bubbles',
      desc: `Convert the number of marbles expressed in multiplications to rectangles.`,
      desc2: `구슬의 개수를 곱셈식으로 나타낸 것을 보고 직사각형으로 표현할 수 있어요.`
    },
    image: bubbles,
    mobileImage: Mbubbles,
    runType: "js",
    component: bubblesComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  detective: {
    ko: {
      name: '래빗 탐정',
      desc: `분자 또는 분모를 보고 주어진 분수들의 크기를 비교할 수 있어요. 작은 수부터 순서대로 이어보세요.`,
      desc2: `분자 또는 분모를 보고 주어진 분수들의 크기를 비교할 수 있어요. 작은 수부터 순서대로 이어보세요.`
    },
    en: {
      name: 'Rabbit Detective',
      desc: `It’s possible to compare the size of fractions by looking at the numerators or denominators. Connect from the smallest number to the biggest.`,
      desc2: `분자 또는 분모를 보고 주어진 분수들의 크기를 비교할 수 있어요. 작은 수부터 순서대로 이어보세요.`
    },
    image: detective,
    mobileImage: Mdetective,
    runType: "js",
    component: detectiveComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  explorer: {
    ko: {
      name: '래빗 탐험대',
      desc: `부등호에 따라 주어진 수를 보고 알맞은 곳에 소수점을 찍어 보아요.`,
      desc2: `부등호에 따라 주어진 수를 보고 알맞은 곳에 소수점을 찍어 보아요.`
    },
    en: {
      name: 'Rabbit Expedition',
      desc: `Put a decimal point at the appropriate spot depending on the inequality symbol.`,
      desc2: `부등호에 따라 주어진 수를 보고 알맞은 곳에 소수점을 찍어 보아요.`
    },
    image: explorer,
    mobileImage: Mexplorer,
    runType: "js",
    component: explorerComponent,
    type: 'number',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  snowfight: {
    ko: {
      name: '눈싸움 대결',
      desc: `여러 도형들을 돌려보고 확대·축소하면서 주어진 도형과 닮은 것이 무엇인지 찾아보세요.`,
      desc2: `여러 도형들을 돌려보고 확대·축소하면서 주어진 도형과 닮은 것이 무엇인지 찾아보세요.`
    },
    en: {
      name: 'Snow Fight',
      desc: `Find the similar figure as the given one by rotating, enlarging or reducing.`,
      desc2: `여러 도형들을 돌려보고 확대·축소하면서 주어진 도형과 닮은 것이 무엇인지 찾아보세요.`
    },
    image: snowfight,
    mobileImage: Msnowfight,
    runType: "js",
    component: snowfightComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width: 1024,
    height: 768,
  },
  kidsPenguinRun: {
    ko: {
      name: '펭귄런 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'Penguin Run (kids)',
      desc: '',
      desc2: '',
    },
    image: penguinRun,
    mobileImage: MpenguinRun,
    runType: "js",
    component: KidsPenguinRunComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsPenguinFishing: {
    ko: {
      name: '펭귄낚시 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'Penguin Fishing (kids)',
      desc: '',
      desc2: '',
    },
    image: penguinFishing,
    mobileImage: MpenguinFishing,
    runType: "js",
    component: KidsPenguinFishingComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsPenguinMigration: {
    ko: {
      name: '펭귄대이동 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'Penguin Great Migration (kids)',
      desc: '',
      desc2: '',
    },
    image: penguinMigration,
    mobileImage: MpenguinMigration,
    runType: "js",
    component: KidsPenguinMigrationComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsPenguinGrouping: {
    ko: {
      name: '펭귄이글루 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'Penguin Igloo (kids)',
      desc: '',
      desc2: '',
    },
    image: penguinIgloo,
    mobileImage: MpenguinIgloo,
    runType: "js",
    component: KidsPenguinGroupingComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsPenguinHomecomming: {
    ko: {
      name: '펭귄홈커밍 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'Penguin Coming Home (kids)',
      desc: '',
      desc2: '',
    },
    image: penguinHomecomming,
    mobileImage: MpenguinHomecomming,
    runType: "js",
    component: KidsPenguinHomecommingComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsSlimeLab: {
    ko: {
      name: '슬라임 연구소 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'Slime Institute',
      desc: `Find the change in order to make the slime as big as the given one.`,
      desc2: `주어진 크기대로 슬라임이 커지려면 얼마나 더해야 하는지 그 변화를 맞춰 보아요.`
    },
    image: slimelab,
    mobileImage: Mslimelab,
    runType: "js",
    component: KidsSlimeLabComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsTenTens: {
    ko: {
      name: '텐텐스 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'TENTENS',
      desc: '',
      desc2: '',
    },
    image: tentens,
    mobileImage: Mtentens,
    runType: "js",
    component: KidsTenTensComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsWatchMaker: {
    ko: {
      name: '만능시계공 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'TENTENS',
      desc: '',
      desc2: '',
    },
    image: watchMaker,
    mobileImage: MwatchMaker,
    runType: "js",
    component: KidsWatchMakerComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsAquearium: {
    ko: {
      name: '아깨아리움 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'TENTENS',
      desc: '',
      desc2: '',
    },
    image: aquearium,
    mobileImage: Maquearium,
    runType: "js",
    component: KidsAqueariumComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsPenguinCastle: {
    ko: {
      name: '펭귄그라운드 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'TENTENS',
      desc: '',
      desc2: '',
    },
    image: penguinGround,
    mobileImage: MpenguinGround,
    runType: "js",
    component: KidsPenguinCastleComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  },
  kidsSapceBubble: {
    ko: {
      name: '펭귄팩토리 (키즈)',
      desc: '',
      desc2: '',
    },
    en: {
      name: 'TENTENS',
      desc: '',
      desc2: '',
    },
    image: spaceBubble,
    mobileImage: MspaceBubble,
    runType: "js",
    component: KidsSpaceBubbleComponent,
    type: 'shape',
    mobile: true,
    app: false,
    junior: true,
    width:1024,
    height: 768,
  }
}

/*
export const KidsWatchMakerComponent = asyncRoute(()=> import('components/Game/Kids/Kids_watchMaker/watchMakerComponent')); // 만능 시계공
export const KidsAqueariumComponent = asyncRoute(()=> import('components/Game/Kids/Kids_aquearium/AqueariumComponent')); // 아께아리움
export const KidsPenguinCastleComponent = asyncRoute(()=> import('components/Game/Kids/Kids_p_castle/PenguinCastleComponent')); // 펭귄그라운드
export const KidsSpaceBubbleComponent = asyncRoute(()=> import('components/Game/Kids/kids_p_spaceBubble/spaceBubbleComponent'));// 펭귄펙토리
 */