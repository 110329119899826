import React from "react";
import useAudioPlayer from "../Hooks/useAudioPlayer";
import attendanceAudio from "./attendance.wav";
import makeTeamAudio from "./maketeam.wav";
import mvpAudio from "./mvp.wav";

const soundMap = {
  attendance: attendanceAudio,
  maketeam: makeTeamAudio,
  mvp: mvpAudio,
};

function Audio(props) {
  const {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime
  } = useAudioPlayer();

  const { type } = props;

  console.log("playing: ", type);

  return (
    <div className="player">
      <audio id={`audio`}>
        <source src={soundMap[type]} />
        Your browser does not support the <code>audio</code> element.
      </audio>
    </div>
  );
}

export default Audio;
