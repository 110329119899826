import React, { Component } from 'react'

class Notice extends Component {
  render() {
    const icons = {
      info: 'icon-info-circle-fill',
      success: 'icon-check-circle-fill',
      warning: 'icon-warning-circle-fill',
      error: 'icon-close-circle-fill',
      loading: 'icon-loading'
    }
    const { type, content, } = this.props
    let toastType = type;
    switch (type) {
      case 'success':
        toastType = 'confirm'
        break;
      case 'error':
        toastType = 'error'
        break;
      default:
        break;
    }
    // warning, info 클래스 타입 추가
    return (
      // {/*
      //   <div className={`toast-notice ${type}`}>
      // */}
      //'toast-notice alert : 경고, toast-notice true : 선택완료
      <div className={`toast-notice ${toastType}`}>
        <span className="toast-notice__img"></span>
        <div className="toast-notice__content" dangerouslySetInnerHTML={{__html: content }} ></div>
        <span className="close"></span>
        {/**
              <svg className="icon" aria-hidden="true">
                  <use xlinkHref={`#${icons[type]}`} />
              </svg>
             */}
      </div>
    )
  }
}

export default Notice
