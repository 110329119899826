import React, { useEffect, useState } from "react";
import "./_randomteam.scss";
import GroupStudentList from "../../../components/Teacher/component/GroupStudentList";
import classNames from "classnames";
import { find, filter, uniq } from "lodash";
import { FormattedMessage } from "react-intl";

const RandomTeam = (props) => {
  const { groups, user, students, attendances } = props;
  const [pickedList, setPick] = useState([]);
  const [teamLeaderId, setTeamLeader] = useState(null);
  const [myGroup, setMyGroup] = useState(null);
  console.log("groups: ", groups);
  console.log("user: ", user);

  useEffect(() => {
    const _studentId = user.studentId;
    groups.map((group) => {
      if (
        find(group.students, function (o) {
          return o.studentId === _studentId;
        })
      ) {
        console.log("attendances: ", attendances);
        console.log("group.students: ", group.students);
        let _studentList = filter(group.students, function (o) {
          return (
            attendances[o.studentId] &&
            attendances[o.studentId].status &&
            attendances[o.studentId].status === "ok"
          );
        });
        console.log("studentList: ", _studentList);
        _studentList.map((student, index) => {
          console.log("students: ", students);
          const _student = filter(students, {
            studentId: student.studentId,
          })[0];
          console.log("_student: ", _student);
          if (_student && _student.profileImage) {
            student["profileImage"] = _student.profileImage;
          }
        });
        setMyGroup(_studentList);
        checkMember(_studentId);
        setTeamLeader(group.leader.studentId);
      }
    });

    return () => {
      setMyGroup(null);
    };
  }, [students]);

  function checkMember(studentId) {
    console.log("picked: ", studentId);
    setPick(uniq([studentId, ...pickedList]));
  }

  console.log("myGroup: ", myGroup);
  console.log("picked list: ", pickedList);

  return (
    // is-active
    <div
      className={classNames("randomteam-layout", {
        "is-active": myGroup && pickedList.length === myGroup.length,
      })}
    >
      <div className="randomteam-layout-wrap">
        <div className="big-title">
          {myGroup && pickedList.length === myGroup.length ? (
            <FormattedMessage id="classroom.move-to-leaders-seat" />
          ) : (
            <FormattedMessage id="classroom.select-your-teammate" />
          )}
        </div>
        {/* 선택한거 is-pick */}
        <GroupStudentList
          studentList={myGroup}
          pickedList={pickedList}
          onCheckMember={checkMember}
          teamLeaderId={teamLeaderId}
        />
      </div>
    </div>
  );
};

export default RandomTeam;
