import React, { Component, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import "./_button.scss";
import GuideHelper from "./GuideHelper";
import QuizListPopup from "../../../pages/classMode/components/QuizListPopup";
import { ConstantsContext } from "utils/ConstantsContext";
import PreviewQuetureGuide from "../../../pages/classMode/components/PreviewQuetureGuide"

const SideMenu = (props) => {
  const { section, isOnline, curPage, settings, onToggleSettings, sectionIndex, lectureData } = props;
  const context = React.useContext(ConstantsContext);
  const {
    isStandBy,
    showStudentList,
    showStudentGroup,
    showReviewLecture,
    guideHelper,
    makeRandomTeam,
    startGame,
    startQueture,
    startStudentQueture,
    startQuiz,
    previewQuiz,
    previewQuetureGuide,
    startChallengeQuiz,
    startEndQuiz,
    startStorytelling,
    startQuebonSong
  } = settings;

  return (
    <>
      <div className="t-sidemenu">
        <ul>
          {/* 클릭시
          .is-click  active, select 혼동이 있어서 앞으로 클릭은 click 으로 통일할까 해요
        */}
          <li
            className={classNames("standby", { "is-active": isStandBy })}
            onClick={() => onToggleSettings("isStandBy")}
          >
            <div className="img"></div>
            <span>대기모드</span>
          </li>
          <li
            className={classNames("studentlist", {
              "is-active": showStudentList,
            })}
            onClick={() => onToggleSettings("showStudentList")}
          >
            <div className="img"></div>
            <span>학생 리스트</span>
          </li>
          <li
            className={classNames("guide none", { "is-active": guideHelper })}
            onClick={() => onToggleSettings("guideHelper")}
          >
            <div className="img"></div>
            <span>가이드 도우미</span>
            <GuideHelper/>
          </li>
          {/* 이하 옵션 */}
          {
            <>
              {(section.type === "GROUP") && (
                <>
                  {curPage === 0 ? (
                    <>
                      <li
                        className={classNames("team-info", {
                          "is-active": showStudentGroup,
                        })}
                        onClick={() => onToggleSettings("showStudentGroup")}
                      >
                        <div className="img"></div>
                        <span>팀 구성 확인</span>
                      </li>
                    </>
                  ) : curPage === 1 ? (
                    <li
                      className={classNames("ps-start", {
                        "is-active": startQuiz,
                      })}
                      onClick={() => onToggleSettings("startQuiz")}
                    >
                      <div className="img"></div>
                      <span>문제화면 보기</span>
                    </li>
                  ) : null}
                </>
              )}
              {(section.type === "QUETURE") && (
                <>
                  <li
                    className={classNames("team-info", {
                      "is-active": showStudentGroup,
                    })}
                    onClick={() => onToggleSettings("showStudentGroup")}
                  >
                    <div className="img"></div>
                    <span>팀 구성 확인</span>
                  </li>
                </>
              )}
              {/* {section.type === "QUIZ" && (
              
            )} */}
              {section.type === "QUETURE" && !isOnline && (
                <li
                  className={classNames("quture-view", {
                    "is-active": startQueture,
                  })}
                  onClick={() => onToggleSettings("startQueture")}
                >
                  <div className="img"></div>
                  <span>깨처보기 시작</span>
                </li>
              )}
              {section.type === "QUETURE" && (
                <li
                  className={classNames("guideHelper", {
                    "is-active": previewQuetureGuide,
                  })}
                  onClick={() => onToggleSettings("previewQuetureGuide")}
                >
                  <div className="img"></div>
                  <span>교안 보기</span>
                </li>
              )}
              {section.type === "QUEBONSONG" && (<li
                className={classNames("remote-queture", {
                  "is-active": startQuebonSong,
                })}
                onClick={() => onToggleSettings("startQuebonSong")}
              >
                <div className="img"></div>
                <span>노래 재생</span>
              </li>)}
              {section.type === "STORYTELLING" && (<li
                className={classNames("remote-queture", {
                  "is-active": startStorytelling,
                })}
                onClick={() => onToggleSettings("startStorytelling")}
              >
                <div className="img"></div>
                <span>스토리텔링</span>
              </li>)}
              {section.type === "CHALLENGE" && (<li
                className={classNames("remote-queture", {
                  "is-active": startChallengeQuiz,
                })}
                onClick={() => onToggleSettings("startChallengeQuiz")}
              >
                <div className="img"></div>
                <span>문제풀기</span>
              </li>)}
              {section.type === "ENDQUIZ" && (<li
                className={classNames("remote-queture", {
                  "is-active": startEndQuiz,
                })}
                onClick={() => onToggleSettings("startEndQuiz")}
              >
                <div className="img"></div>
                <span>문제풀기</span>
              </li>)}
              {section.type === "QUETURE" &&
              context.type === "campus" &&
              isOnline && (
                <li
                  className={classNames("remote-queture", {
                    "is-active": startStudentQueture,
                  })}
                  onClick={() => onToggleSettings("startStudentQueture")}
                >
                  <div className="img"></div>
                  <span>학습화면 재생</span>
                </li>
              )}
              {section.type === "GAME" && (
                <li
                  className={classNames("game-start", {
                    "is-active": startGame,
                  })}
                  onClick={() => onToggleSettings("startGame")}
                >
                  <div className="img"></div>
                  <span>게임하기 시작</span>
                </li>
              )}
              {section.type === "QUIZ" && (
                <li
                  className={classNames("ps-start", {
                    "is-active": previewQuiz,
                  })}
                  onClick={() => onToggleSettings("previewQuiz")}
                >
                  <div className="img"></div>
                  <span>{"문제화면 보기"}</span>
                </li>
              )}
              {isStandBy && (
                <li
                  className={classNames("previous-review", {
                    "is-active": showReviewLecture,
                  })}
                  onClick={() => onToggleSettings("showReviewLecture")}
                >
                  <div className="img"></div>
                  <span>{"지난회차 복습"}</span>
                </li>
              )}
            </>
          }
        </ul>
      </div>
      {section.type === "QUIZ" && curPage === 1 && (
        <QuizListPopup quizList={section.quiz || []} {...props} />
      )}
      {section.type === "QUETURE" && previewQuetureGuide == true && (
        <PreviewQuetureGuide quetureId={lectureData && lectureData.sections[sectionIndex].quetureId}
                             queture={null} {...props}/>
      )}
    </>
  );
};

export default SideMenu;
