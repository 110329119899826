import React from 'react';
import classNames from 'classnames';
import { formatText } from "../utils/Formatter";

const RenderCoampareText = ({ a, b, selected }) => {
  
  return (
    <div className="t-answer-bigsmall__text">
      {
        selected === '<' && <> <span>{a}</span>은(는) <span>{b}</span>보다 작다 </>
      }
      {
        selected === '>' && <> <span>{a}</span>은(는) <span>{b}</span>보다 크다 </>
      }
      {
        selected === '=' && <> <span>{a}</span>와(과) <span>{b}</span>은(는) 같다 </>
      }
      {
        selected === '?' && <> <span>알 수 없다</span> </>
      }
    </div>
  )
};
const Scale = (props) => {
  const { choices, selected, onSelected, solvedData, showReallyTrue  } = props;

  return (
    <div className="t-answer-bigsmall">
      <div className={classNames("t-answer-bigsmall__img",
      {
        'is-right-big': selected === '<',
        'is-left-big': selected === '>',
        'is-equl': selected === '=',
        'is-unknown': selected === '?'
      }
      )}>
        {/* 오른쪽이 클경우 is-right-big */}
        {/* 왼쪽이 클경우 is-left-big */}
        {/* 알수 없을 경우 is-unknown */}
        <span className={
          classNames(
            'scale-plate')
        }>
          <span className="scale-plate-left"></span>
          <span className="water-left">
            {formatText(choices[0].answer)}
          </span>
          <span className="scale-plate-right"></span>
          <span className="water-right">
            {formatText(choices[1].answer)}
          </span>
        </span>
        <span className="scale-bridge"></span>
        <span className="scale-body">
          <span className="left"></span>
        </span>
      </div>

      <RenderCoampareText a={formatText(choices[0].answer)} b={formatText(choices[1].answer)} selected={selected} />

      <ul className="t-answer-bigsmall__select">
        <li
          onClick={() => onSelected("<")}
          className={classNames(
            'left',
            {
            'is-chk': (selected === "<"),
            'is-true': (selected === "<") && solvedData && solvedData.solved && solvedData.corrects[0],
            'is-false': (selected === "<") && solvedData && solvedData.solved && !solvedData.corrects[0],
            'is-reallytrue': showReallyTrue && solvedData.correctAnswers[0] === '<',
            'none-event': solvedData && solvedData.solved,
          })}>
        </li>
        <li
          onClick={() => onSelected("=")}
          className={classNames(
            'same',
            {
            'is-chk': (selected === "="),
            'is-true': (selected === "=") && solvedData && solvedData.solved && solvedData.corrects[0],
            'is-false': (selected === "=") && solvedData && solvedData.solved && !solvedData.corrects[0],
            'is-reallytrue': showReallyTrue && solvedData.correctAnswers[0] === '=',
            'none-event': solvedData && solvedData.solved,
          })}>
        </li>
        <li
          onClick={() => onSelected("?")}
          className={classNames(
            'unknown',
            {
            'is-chk': (selected === "?"),
            'is-true': (selected === "?") && solvedData && solvedData.solved && solvedData.corrects[0],
            'is-false': (selected === "?") && solvedData && solvedData.solved && !solvedData.corrects[0],
            'is-reallytrue': showReallyTrue && solvedData.correctAnswers[0] === '?',
            'none-event': solvedData && solvedData.solved,
          })}>
          </li>
        <li
          onClick={() => onSelected(">")}
          className={classNames(
            'right',
            {
            'is-chk': (selected === ">"),
            'is-true': (selected === ">") && solvedData && solvedData.solved && solvedData.corrects[0],
            'is-false': (selected === ">") && solvedData && solvedData.solved && !solvedData.corrects[0],
            'is-reallytrue': showReallyTrue && solvedData.correctAnswers[0] === '>',
            'none-event': solvedData && solvedData.solved,
          })}>
          </li>
      </ul>
    </div>

    // <div>
    //   <div>
    //     {/* 저울 */}

    //     {/* Left */}
    //     <div style={{ display: 'inline-block', borderRadius: '50%', padding: '5px', margin: '10px' }} >
    //       <p>
    //         {choices[0].answer}
    //       </p>
    //     </div>

    //     {/* Right */}
    //     <div style={{ display: 'inline-block', borderRadius: '50%', padding: '5px', margin: '10px' }} >
    //       <p>
    //         {choices[1].answer}
    //       </p>
    //     </div>
    //   </div>
    //   <div>
    //     {/* 정답영역 */}
    //     <Button color={selected === "<" ? "blue" : "orange"} onClick={() => onSelected("<")}>{"<"}</Button>
    //     <Button color={selected === ">" ? "blue" : "orange"} onClick={() => onSelected(">")}>{">"}</Button>
    //     <Button color={selected === "=" ? "blue" : "orange"} onClick={() => onSelected("=")}>{"="}</Button>
    //     <Button color={selected === "?" ? "blue" : "orange"} onClick={() => onSelected("?")}>{"?"}</Button>
    //   </div>
    // </div>
  )
}

export default Scale;
