import React, { Component } from 'react'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { findDOMNode } from 'react-dom'
import {
  DragSource,
  DropTarget,
} from 'react-dnd';
import classNames from 'classnames';
import { formatText } from '../utils/Formatter';
import { Image } from '.';

const orderableSource = {
  beginDrag(props) {
    return {
      id: props.choice.key,
      seq: props.seq,
      data: props.choice,
    };
  },
  canDrag: props => !props.forbidDrag,
}

const orderableTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }

    const dragIndex = monitor.getItem().seq;
    const hoverIndex = props.seq;

    if (dragIndex === hoverIndex) {
      return null;
    }


    const node = findDOMNode(component);
    if (!node) {
      return null;
    }

    const hoverBoundingRect = node.getBoundingClientRect()

    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2

    const clientOffset = monitor.getClientOffset()

    const hoverClientX = clientOffset.x - hoverBoundingRect.left

    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      return;
    }

    props.reorder(dragIndex, hoverIndex)

    monitor.getItem().seq = hoverIndex
  },
}

const colors = [
  '#ffc046', '#ff9b17', '#ff7f00', '#e8481c'
]

class Orderable extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      })
    }
  }

  componentWillMount() {
    this.setState({
      initSeq: this.props.seq
    })
  }

  render() {
    const { choice, seq, solvedData, showReallyTrue, id, forbidDrag, onDrop, onSelect, selectedIndex,
      isDragging, connectDragSource, connectDropTarget } = this.props;
    const { initSeq } = this.state;

    return connectDragSource(
      connectDropTarget(
        <li
          onClick={() => { onSelect && onSelect(seq) }}
          onDrop={onDrop}
          style={{ cursor: 'move' }}
          className={classNames(
            "order-" + (seq + 1),
            {
              'is-chk': selectedIndex !== null && selectedIndex === seq,
              'is-true': !showReallyTrue && solvedData.solved && solvedData.corrects[0],
              'is-false': !showReallyTrue && solvedData.solved && !solvedData.corrects[0],
              'none-event': solvedData && solvedData.solved,
            }
          )}>
          <span className={classNames("", {
            'border-box': isDragging,
            'choice-box': !isDragging
          })}>
            <span>
              {
                choice.image ?
                  <Image image={choice.image} />
                  :
                  <span>{formatText(choice.answer)}</span>
              }
            </span>
          </span>
        </li>
        // <div className={"orderable s" + seq} style={{ background: colors[initSeq], opacity: 1 }}>
        //     <span className="answer">{choice.answer}</span>
        // </div>
      )
    )
  }
}

export default DropTarget(
  (props) => "order_" + props.parentId,
  orderableTarget,
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(DragSource(
  (props) => "order_" + props.parentId,
  orderableSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  })
)(Orderable));
