import mockupRoutes from "./mockupRoutes";
import authorizedRoutes from "./authorizedRoutes";

import LoginPage from "../pages/login";
import NotFound from "../pages/NotFound";
import NoPermissions from "../pages/NoPermissions";
import LogoutPage from "../pages/logout";

const normalRoutes = [
  ...mockupRoutes,
  {
    path: "/login",
    exact: true,
    component: LoginPage
  },
  {
    path: "/logout",
    exact: true,
    component: LogoutPage
  },
  {
    path: "/permission-denied",
    exact: true,
    component: NoPermissions
  },
  {
    path: "*",
    component: NotFound
  }
];

const combineRoutes = [...authorizedRoutes, ...normalRoutes];

export { authorizedRoutes, normalRoutes, combineRoutes };
