import classNames from 'classnames';
import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
// import '../css/Quiz.scss';
import { formatText } from '../utils/Formatter';

// const answerTarget = {
//   drop(props, monitor) {
//     props.onDrop(monitor.getItem().data)
//   }
// }
class DroppableArea extends Component {

  render() {
    const { index, selected, solvedData, showReallyTrue, isOver, dragItem, connectDropTarget } = this.props;

    // return connectDropTarget(
    return (
      <a
        // className={"droppable" + (selected != null ? " fill" : "")}
        className={classNames('droppable', {
          'fill': (selected !== null && selected !== undefined) || showReallyTrue,
          'is-true': showReallyTrue ? solvedData.solved : (solvedData.solved && solvedData.corrects[index]),
          'is-false': !showReallyTrue && solvedData.solved && !solvedData.corrects[index],
          // 'is-reallytrue': showReallyTrue && solvedData.solved,
          'none-event': solvedData && solvedData.solved,
          'is-dragging': dragItem,
          'is-over': isOver
        })}
      >
        {
          showReallyTrue ?
            <span className="text">{formatText(solvedData.correctAnswers[index])}</span>
            :
            <span className="text">{selected == null ? '' : formatText(selected.answer, new Date().getTime())}</span>
        }
      </a>
    )
  }
}

export default DroppableArea;
// export default DropTarget(
//   (props) => "drag_" + props.parentId,
//   answerTarget,
//   (connect, monitor) => ({
//     connectDropTarget: connect.dropTarget(),
//     isOver: monitor.isOver(),
//     canDrop: monitor.canDrop(),
//     dragItem: monitor.getItemType()
//   })
// )(DroppableArea);
