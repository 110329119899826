import React, { useState } from "react";
import classNames from "classnames";
import Popup from "./Popup";

//type = notify, alert, confirm
//buttons = ok, yesno, close, purchase, goprofile
const Alert = ({
  isOpen,
  title,
  reverseBtnClass,
  children,
  type,
  buttons,
  onConfirm,
  onDeny,
  onClose
}) => {
  const iconClass = classNames("modal-icon", {
    notify: type === "notify", // admin-popup-error
    alert: type === "alert", // admin-popup-alert
    confirm: type === "confirm" // admin-popup-chk
  });

  const buttonAction = callback => {
    if (callback) {
      callback();
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <Popup isOpen={isOpen} type={type}>
      {children}
      <div className="btn-wrap">
        {buttons === "yesno" ? (
          <>
            <div>
              <button
                className={classNames({
                  "is-true": !reverseBtnClass,
                  "is-white-true": reverseBtnClass
                })}
                onClick={() => buttonAction(onConfirm)}
              >
                {"네"}
              </button>
            </div>
            <div>
              <button
                className={classNames({
                  "is-true": reverseBtnClass,
                  "is-white-true": !reverseBtnClass
                })}
                onClick={() => buttonAction(onDeny)}
              >
                {"아니오"}
              </button>
            </div>
          </>
        ) : buttons === "close" ? (
            <button className="is-chk" onClick={() => buttonAction()}>
              {"확인"}
            </button>
        ) : (
            <button className="is-chk" onClick={() => buttonAction()}>
              {"확인"}
            </button>
        )}
      </div>
    </Popup>
  );
};

export default Alert;
