import React, { Component } from "react";
import {
  PageHeader,
  Button,
  Checkbox,
  Radio,
  Icon,
  Collapse,
  Spin,
  Tag
} from "antd";
import ClassTable from "./component/ClassTable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import * as courseActions from "store/modules/course";

class ClassroomDashboard extends Component {
  state = {
    radioValue: 1
  };

  componentDidMount() {
    const { user, ClassroomActions, CourseActions } = this.props;
    console.log("mount dashboard: ", user);

    if (user) {
      ClassroomActions.getClassroomList(user.orgId || user.userId);
      CourseActions.getCourseList();
    }
  }

  onChangeRadio = e => {
    console.log("radio checked", e.target.value);
    this.setState({
      radioValue: e.target.value
    });
  };

  render() {
    const { classroomList, courseList } = this.props;

    const { Panel } = Collapse;

    const customPanelStyle = {
      background: "#f7f7f7",
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: "hidden"
    };

    console.log("render dashboard: ", this.props.auth.user);
    console.log("classroom list: ", classroomList);
    console.log("course list: ", courseList);

    if (!this.props.auth.user) {
      return <Spin />;
    }

    const getHeader = () => <span>Header</span>;

    return (
      <div>
        <PageHeader
          // onBack={() => window.history.back()}
          title="깨봉수학학원 전체 클래스 목록"
          subTitle={`총 ${this.props.classroomList.length}개`}
          extra={[
            <Checkbox key="3">오늘 수업만보기</Checkbox>,
            <Radio.Group
              key="2"
              onChange={this.onChangeRadio}
              value={this.state.radioValue}
            >
              <Radio value={1}>종료일 기준 보기</Radio>
              <Radio value={2}>시작일 기준 보기</Radio>
            </Radio.Group>,
            <Button
              key="1"
              type="primary"
              onClick={() => this.props.history.push("/admin/class/add")}
            >
              클래스 생성하기
              <Icon type="plus-circle" />
            </Button>
          ]}
        />

        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIconPosition={"right"}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          {courseList.map((course, index) => {
            return (
              <Panel
                header={
                  <span>
                    {course.title}
                    <Tag>{`클래스개수: ${
                      classroomList.filter(
                        classroom => classroom.course.title === course.title
                      ).length
                    }`}</Tag>
                  </span>
                }
                key={index}
                style={customPanelStyle}
              >
                <ClassTable
                  classroomList={classroomList.filter(
                    classroom => classroom.course.title === course.title
                  )}
                />
              </Panel>
            );
          })}
          {/* <Panel header="JUNIOR" key="1" style={customPanelStyle}>
            <ClassTable
              classroomList={classroomList.filter(
                classroom => classroom.course.title === "JUNIOR 1"
              )}
            />
          </Panel> */}
        </Collapse>
      </div>
    );
  }
}
export default connect(
  ({ auth, classroom, course }) => ({
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    classroomList: classroom.classroomList,
    courseList: course.courseList
  }),
  dispatch => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
    CourseActions: bindActionCreators(courseActions, dispatch)
  })
)(ClassroomDashboard);
