import PropTypes from "prop-types";
import React, { Component } from "react";
import { Image } from "./modules";
import Scale from "./modules/Scale";
import { formatText } from "./utils/Formatter";

class CompareQuiz extends Component {
  state = {
    quizId: null,
    answer: null
  };

  init = data => {
    const { answer } = this.state;
    this.setState({
      answer: ""
    });
  };

  componentWillMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
  }

  componentWillReceiveProps(props) {
    const { data: old } = this.props;
    const { data } = props;

    if (data != null && (old == null || old.quizId !== data.quizId || old.timestamp !== data.timestamp)) {
      this.init(data);
    }
  }

  componentDidMount = () => {
    const { stepQuizAnswers } = this.props;
    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      this.setState({
        answer: stepQuizAnswers[0]
      })
    }
  }

  handleSelect = (answer) => {
    const { onAnswerUpdated } = this.props;

    this.setState({
      answer: answer
    }, () => {
      onAnswerUpdated([answer]);
    });
  }

  render() {
    const { data, solvedData, showReallyTrue } = this.props;
    const { quizId, quiz } = data;
    const { choices } = quiz;
    return (
      <Scale
        choices={choices}
        selected={this.state.answer}
        solvedData={solvedData}
        showReallyTrue={showReallyTrue}
        onSelected={this.handleSelect} />
    );
  }
}

CompareQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
};

export default CompareQuiz;
