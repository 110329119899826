import axios from 'axios';
import { getHost, getDefaultConfig } from 'utils/APIUtils';

export function requestDRM(userId, content, callback) {
    const { type, drm } = content;
    const { quetureId, contents } = content.data;
    const { bundleId } = contents;

    const data = {
        "user_id": userId,
        "oid": quetureId,
        "cid": bundleId + "." + type
    }

    console.log('userId:' + userId) 

    const host = getHost();
    const siteId = process.env.REACT_APP_PALLYCON_SITE_ID;

    axios.post(host + "/blob/v1/drm/pallycon/encrypt", data, {
        ...getDefaultConfig()
    }).then((response) => {
        const drmType = drm != null ? drm : "Widevine";
        const customData = {
            drm_type: drmType,
            site_id: siteId,
            data: response.data
        };

        const str = JSON.stringify(customData);
        const base64 = btoa(str);

        var url = host + '/blob/v1/bundles/' + bundleId + '/items/' + type + '/drm' + (drmType === 'Fairplay' ? '/hls/master.m3u8' : '/dash/stream.mpd');

        callback(url, base64, drmType);
    }).catch((error) => {
        //TODO handle error
    })
}

export function decodeBase64(input) {
    var raw = window.atob(input);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++)
        array[i] = raw.charCodeAt(i);

    return array;
}

export function encodeBase64(input) {
    var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN;
        chr3 = i < input.length ? input[i++] : Number.NaN;

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
            keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
}


export function extractContentId(initData) {
    var contentId = arrayToString(initData);
    contentId = contentId.substring(contentId.indexOf('skd://') + 6);
    return contentId;
}

export function stringToArray(string) {
    var buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
    var array = new Uint16Array(buffer);
    for (var i = 0, strLen = string.length; i < strLen; i++) {
        array[i] = string.charCodeAt(i);
    }
    return array;
}

export function arrayToString(array) {
    return String.fromCharCode.apply(null, array);
}


export function concatInitDataIdAndCertificate(initData, id, cert) {
    if (typeof id == "string")
        id = stringToArray(id);
    // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
    var offset = 0;
    //var buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
    var buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
    var dataView = new DataView(buffer);

    var initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
    initDataArray.set(initData);
    offset += initData.byteLength;

    dataView.setUint32(offset, id.byteLength, true);
    offset += 4;

    var idArray = new Uint16Array(buffer, offset, id.length);
    idArray.set(id);
    offset += idArray.byteLength;

    dataView.setUint32(offset, cert.byteLength, true);
    offset += 4;

    var certArray = new Uint8Array(buffer, offset, cert.byteLength);
    certArray.set(cert);

    return new Uint8Array(buffer, 0, buffer.byteLength);
}

export function selectKeySystem() {
    if (window.WebKitMediaKeys.isTypeSupported("com.apple.fps.1_0", "video/mp4")) {
        return "com.apple.fps.1_0";
    }
    else {
        console.error("Key system not supported");
        throw "Key System not supported";
    }
}