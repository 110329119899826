import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";
import axios from "axios";
import {
  SESSION_KEY,
  getHost,
  getDefaultConfig,
  parseAxiosError
} from "utils/APIUtils";

const GAMES = "game/GAMES";
const GAMES_LOADING = "game/GAMES_LOADING";
const GAMES_SUCCESS = "game/GAMES_SUCCESS";
const GAMES_ERROR = "game/GAMES_ERROR";

const ACTIVATE = "game/ACTIVATE";
const ACTIVATE_LOADING = "game/ACTIVATE_LOADING";
const ACTIVATE_SUCCESS = "game/ACTIVATE_SUCCESS";
const ACTIVATE_ERROR = "game/ACTIVATE_ERROR";

function _getGames() {
  const host = getHost();

  let url = `${host}/user/v2/games`;

  return axios.get(url, {
    ...getDefaultConfig()
  });
}

function _activate(game) {
  const host = getHost();

  let url = `${host}/user/v2/games/${game}/activation`;

  return axios.put(
    url,
    {},
    {
      ...getDefaultConfig()
    }
  );
}

export const loadGames = () => ({
  type: GAMES,
  payload: _getGames()
});

export const activate = gameName => dispatch => {
  dispatch({
    type: ACTIVATE_LOADING,
    payload: gameName
  });

  return _activate(gameName)
    .then(response => {
      dispatch({
        type: ACTIVATE_SUCCESS,
        payload: response
      });
    })
    .catch(error => {
      dispatch({
        type: ACTIVATE_SUCCESS,
        payload: {
          gameName: gameName,
          error: error
        }
      });
    });
};

const initialState = {
  games: {
    pending: false
  }
};

export default handleActions(
  {
    [GAMES_LOADING]: state => {
      return {
        ...state,
        games: {
          ...state.games,
          pending: true
        }
      };
    },
    [GAMES_SUCCESS]: (state, { payload }) => {
      const games = {};

      payload.data.forEach(d => {
        games[d.game] = d;
        games[d.game].pending = false;
      });

      return {
        ...state,
        games: {
          data: games,
          pending: false
        }
      };
    },
    [GAMES_ERROR]: (state, { payload }) => {
      return {
        ...state,
        games: {
          ...state.games,
          pending: false,
          error: parseAxiosError(payload, "game")
        }
      };
    },

    [ACTIVATE_LOADING]: (state, { payload: gameName }) => {
      return {
        ...state,
        games: update(state.games, {
          data: {
            [gameName]: {
              pending: {
                $set: true
              }
            }
          }
        })
      };
    },
    [ACTIVATE_SUCCESS]: (state, { payload }) => {
      const activated = payload.data;
      if (activated == null) {
        return state;
      }

      return {
        ...state,
        games: update(state.games, {
          data: {
            [activated.game]: {
              pending: {
                $set: false
              },
              activation: {
                $set: activated
              }
            }
          }
        })
      };
    },
    [ACTIVATE_ERROR]: (state, { payload }) => {
      const { gameName, error } = payload;

      return {
        ...state,
        games: update(state.games, {
          data: {
            [gameName]: {
              pending: {
                $set: false
              }
            }
          },
          error: parseAxiosError(error, "activate")
        })
      };
    }
  },
  initialState
);
