import PropTypes from "prop-types";
import React, { Component } from "react";
import { Image } from "./modules";
import RangeSelector from "./modules/RangeSelector";
import { formatText } from "./utils/Formatter";

class RangeQuiz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      quizId: null,
      answer: null
    }
  }


  init = data => {
    const { answer } = this.state;

    this.setState({
      answer: -1
    });
  };

  componentDidMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
    const { stepQuizAnswers } = this.props;
    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      this.setState({
        answer: stepQuizAnswers[0]
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      this.init(this.props.data)
    }
  }

  selectAnswer = (i) => {
    const { onAnswerUpdated } = this.props;
    this.setState({
      answer: i
    }, () => {
      onAnswerUpdated([i]);
    });
  }

  render() {
    const { data, solvedData, showReallyTrue } = this.props;
    const { quizId, quiz } = data;
    const { choices } = quiz;

    return (
      <table className="t-answer-area__rangebar">
        <tbody>

          <tr>
            {choices.map((c, i) => (
              <RangeSelector
                key={quizId + "_ans_" + i}
                choice={c}
                index={i}
                solvedData={solvedData}
                showReallyTrue={showReallyTrue}
                selected={this.state.answer === i}
                onSelected={() => this.selectAnswer(i)}
              />
            ))}
          </tr>
        </tbody>

      </table>
    );
  }
}

RangeQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
};

export default RangeQuiz;
