import React from "react";
import { FormattedMessage } from "react-intl";
import ActivityPreviewAnimation from "./ActivityPreviewAnimation/ActivityPreviewAnimation";
import "./_activity.scss";

const Activity = (props) => {
  const { data, isKids, viewType } = props;

  if (isKids && viewType === "preview") {
    return <ActivityPreviewAnimation />;
  }

  return (
    <div className="activity-layout">
      <div className="small-title">
        {
          isKids ?
          <FormattedMessage id="classroom.todays-activity-kids" />
          :
          <FormattedMessage id="classroom.todays-activity" />
        }
      </div>
      <div className="big-title u-color-blue">{data["title"]}</div>
      <div className="page-content-title u-color-blue">
        <span>
          {
            isKids ?
            <FormattedMessage id="classroom.activity-desc-kids" />
            :
            <FormattedMessage id="classroom.activity-desc" />
          }
        </span>
      </div>
      <p
        className="page-content-ment"
        dangerouslySetInnerHTML={{ __html: data["text"] }}
      ></p>
    </div>
  );
};

export default Activity;
