import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { formatText } from "./utils/Formatter";
import { Image } from "./modules";
import classNames from "classnames";
import debounce from "lodash.debounce";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const circledNumbers = [
  <>&#9450;</>,
  <>&#9312;</>,
  <>&#9313;</>,
  <>&#9314;</>,
  <>&#9315;</>,
  <>&#9316;</>,
  <>&#9317;</>,
  <>&#9318;</>,
  <>&#9319;</>,
  <>&#9320;</>,
];

class OrderingQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: null,
      selectedIndex: null,
      orderables: [],
      isMobileSize: window.innerWidth <= 768,
    };

    this.handleResize = debounce(this.handleResize, 500);
  }

  init = (data) => {
    const { stepQuizAnswers } = this.props;
    const { quiz } = data;
    const { choices } = quiz;

    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      const stepAnswersMap = [];
      stepQuizAnswers.map((a) => {
        if (a) {
          const answer = choices.find((c) => c.key === a);
          stepAnswersMap.push(answer);
        }
      });
      this.setState(
        {
          answers: stepAnswersMap,
        },
        this.onDrop
      );
    } else {
      this.setState(
        {
          answers: choices.slice(),
        },
        this.onDrop
      );
    }
  };

  componentWillMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
  }

  componentWillReceiveProps(props) {
    const { data: old } = this.props;
    const { data } = props;

    if (
      data != null &&
      (old == null ||
        old.quizId !== data.quizId ||
        old.timestamp !== data.timestamp)
    ) {
      this.init(data);
    }
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const checkIsMobile = window.innerWidth <= 768;
    if (checkIsMobile !== this.state.isMobileSize) {
      this.setState({
        isMobileSize: checkIsMobile,
      });
    }
  };

  reorder = async (src, dest) => {
    const oldAnswer = this.state.answers[src];

    await this.props.onUpdatingAnswer(true);

    this.setState(
      {
        answers: update(this.state.answers, {
          $splice: [
            [src, 1],
            [dest, 0, oldAnswer],
          ],
        }),
      },
      () => {
        this.onDrop();
      }
    );
  };

  onDrop = () => {
    const { onAnswerUpdated } = this.props;
    onAnswerUpdated(this.state.answers.map((a) => a.key));
  };

  handleSelect = (id) => {
    console.log("handle select: ", id);
    if (this.state.isMobileSize) {
      this.setState({
        selectedIndex: id,
      });
    }
  };

  handleSort = (type) => {
    if (this.state.selectedIndex !== null) {
      let src = this.state.selectedIndex;
      let dest = this.state.selectedIndex;
      if (type === "up") {
        if (src === 0) {
          dest = 3;
        } else {
          dest -= 1;
        }
      } else {
        if (src === 3) {
          dest = 0;
        } else {
          dest += 1;
        }
      }
      this.setState({
        selectedIndex: dest,
      });
      this.reorder(src, dest);
    }
  };

  onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    this.reorder(source.index, destination.index);
  };

  render() {
    const { data, solvedData, showReallyTrue, receiveValue, forWorkbook } =
      this.props;
    const { quizId } = data;
    const { choices } = data.quiz;
    const { isMobileSize, answers } = this.state;

    let _answers = [];
    _answers = answers;
    if (this.props.viewType == "guide") {
      if (this.props.sendValue) {
        this.props.sendValue(answers);
      }
    } else if (this.props.receiveValue != null) {
      _answers = this.props.receiveValue;
    }
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div
          className={classNames(
            "quiz-content__answer t-answer-ordering",
            "count-" + choices.length,
            {
              "type-equl": data && data.subType === "B",
            }
          )}
        >
          <div className="t-answer-ordering-choices">
            {forWorkbook && <span>보기</span>}

            <Droppable
              droppableId={quizId}
              direction={isMobileSize ? "vertical" : "horizontal"}
            >
              {(provided) => (
                <ul
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="t-answer-ordering-box"
                  style={{ minHeight: 0 }}
                >
                  {!showReallyTrue &&
                    _answers.length > 0 &&
                    _answers.map((cho, i) => {
                      return (
                        <Draggable
                          draggableId={cho.key}
                          key={cho.key}
                          index={i}
                        >
                          {(p, s) => (
                            <li
                              ref={p.innerRef}
                              {...p.draggableProps}
                              {...p.dragHandleProps}
                              className={
                                forWorkbook
                                  ? null
                                  : classNames(
                                      (this.props.quizType == "printView"
                                        ? "order-print-"
                                        : "order-") +
                                        (i + 1),
                                      choices.color,
                                      {
                                        "is-chk":
                                          this.state.selectedIndex !== null &&
                                          this.state.selectedIndex === i,
                                        "is-true":
                                          !showReallyTrue &&
                                          solvedData.solved &&
                                          solvedData.corrects[0],
                                        "is-false":
                                          !showReallyTrue &&
                                          solvedData.solved &&
                                          !solvedData.corrects[0],
                                        "none-event":
                                          solvedData && solvedData.solved,
                                      }
                                    )
                              }
                            >
                              <span
                                className={classNames("", {
                                  "choice-box": true,
                                })}
                              >
                                <span>
                                  {cho.image ? (
                                    <>
                                      {forWorkbook && (
                                        <>{circledNumbers[i + 1]}</>
                                      )}
                                      <Image image={cho.image} />
                                    </>
                                  ) : (
                                    <span>
                                      {forWorkbook && (
                                        <>{circledNumbers[i + 1]} </>
                                      )}
                                      {formatText(cho.answer)}
                                    </span>
                                  )}
                                </span>
                              </span>
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                  {showReallyTrue &&
                    solvedData.correctAnswers.map((key, i) => {
                      let choice = _answers.find((a) => a.key === key);
                      return (
                        <li
                          key={i + "realytrue-" + key}
                          className={classNames(
                            choice.color,
                            "order-" + (i + 1),
                            "is-reallytrue"
                          )}
                        >
                          <span className="choice-box">
                            <span>
                              {choice.image ? (
                                <>
                                  {forWorkbook && <>{circledNumbers[i + 1]} </>}
                                  <Image image={choice.image} />
                                </>
                              ) : (
                                <span>
                                  {forWorkbook && <>{circledNumbers[i + 1]} </>}
                                  {formatText(choice.answer)}
                                </span>
                              )}
                            </span>
                          </span>
                        </li>
                      );
                    })}
                </ul>
              )}
            </Droppable>
          </div>

          <ul
            className={classNames("t-answer-ordering-order", {
              "for-workbook": forWorkbook,
            })}
          >
            {_answers.map((c, i) => {
              let indexText = null;
              let index = i;
              // if (showReallyTrue) {
              //   index = solvedData.correctAnswers.indexOf(c.key);
              // }
              switch (index) {
                case 0:
                  indexText = "첫번째";
                  break;
                case 1:
                  indexText = "두번째";
                  break;
                case 2:
                  indexText = "세번째";
                  break;
                case 3:
                  indexText = "네번째";
                  break;
                default:
                  break;
              }

              return (
                <>
                  <li
                    style={{
                      minWidth:
                        this.props.quizType === "printView"
                          ? forWorkbook
                            ? "100px"
                            : "190px"
                          : "225px",
                    }}
                    key={"index_" + c.key}
                    className={classNames({
                      "is-true":
                        !showReallyTrue &&
                        solvedData.solved &&
                        solvedData.corrects[0],
                      "is-false":
                        !showReallyTrue &&
                        solvedData.solved &&
                        !solvedData.corrects[0],
                      "is-reallytrue": showReallyTrue,
                    })}
                  >
                    {forWorkbook ? (
                      <span>
                        ({" "}
                        <p
                          className={classNames({
                          })}
                        >
                          {showReallyTrue ? <>{circledNumbers[index + 1]}</> : <>&nbsp;</>}
                        </p>{" "}
                        )
                      </span>
                    ) : (
                      <span>{indexText}</span>
                    )}
                  </li>
                  {forWorkbook && i < _answers.length - 1 && (
                    <li style={{ minWidth: "30px" }}>
                      <span>&rarr;</span>
                    </li>
                  )}
                </>
              );
            })}
          </ul>
        </div>
      </DragDropContext>
    );
  }
}

OrderingQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func,
};

export default OrderingQuiz;
