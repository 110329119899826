import update from "immutability-helper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Image, TextSelector } from "./modules";
import { formatProblem } from "./utils/Formatter";
import { actionLog } from "utils/LogUtils";

class TextSelectQuiz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      answers: null,
      problem: null
    }
  }


  init = data => {

    const { quizId, quiz } = data;
    const { problem } = quiz;

    const { stepQuizAnswersQuizAnswers } = this.props;
    if (stepQuizAnswersQuizAnswers && stepQuizAnswersQuizAnswers.length > 0) {
      this.setState({
        answers: stepQuizAnswersQuizAnswers
      }, () => {
        this.updateProblem(this.props)
      });
    } else {
      let answerCount = 0;
      const reg = /\{\{(.*?)}}/gim;
      while (reg.exec(problem)) {
        answerCount++;
      }
      this.setState({
        answers: new Array(answerCount).fill(null),
        problem: null
      }, () => {
        actionLog("quiz", "init", {
          quizId: quizId,
          type: quiz.type,
          answers: this.state.answers
        });
        this.updateProblem(this.props);
      });
    }
  };

  updateProblem = (props) => {
    const { data, showReallyTrue, solvedData, quizType, answers, stepQuizAnswers, additionalImages } = props;

    const { quizId, quiz, timestamp } = data;
    const { problem } = quiz;
    let _answers = this.state.answers;
    if(answers!= null&&answers.length != 0) {
      _answers = answers;
    }

    console.log( "_answers"+ _answers);
    this.setState({
      problem: formatProblem(problem, quizId + "_" + timestamp, (index, data) => {
        const arr = data.split("|").map(s => s.trim());
        const selected = _answers[index];
        return (
          <TextSelector
            key={quizId + "_" + timestamp + "_sel_" + index + "_" + (stepQuizAnswers ? "1":"0")}
            index={index}
            itemId={quizId + "_" + timestamp + "_sel_" + index}
            list={arr}
            selected={selected}
            solvedData={solvedData}
            showReallyTrue={showReallyTrue}
            answers={_answers}
            onSelectionChanged={this.onSelectionChanged}
            onMobileSelectionChanged={this.onMobileSelectionChanged}
            quizType={quizType}
          />
        );
      }, additionalImages)
    })
  }

  componentDidMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.answers !== this.props.answers){
      this.updateProblem(this.props);
    }
    if (prevProps.showReallyTrue !== this.props.showReallyTrue) {
      this.updateProblem(this.props);
    }
    if (this.props.solved && !prevProps.solved) {
      this.updateProblem(this.props)
    }
    if (this.props.data && (prevProps.stepQuizAnswers !== this.props.stepQuizAnswers || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      this.init(this.props.data)
    }
  }

  componentWillUnmount() {
    this.setState({
      answers: null,
      problem: null
    })
  }

  onSelectionChanged = (listIndex, itemIndex, direction, itemLength) => {
    const { onAnswerUpdated } = this.props;
    if (direction) {
      if (direction === "up") {
        itemIndex = itemIndex > 0 ? itemIndex - 1 : itemLength - 1;
      } else {
        itemIndex = itemIndex !== itemLength - 1 ? itemIndex + 1 : 0;
      }
    }
    this.setState(
      {
        answers: update(this.state.answers, {
          [listIndex]: {
            $set: itemIndex
          }
        })
      },
      () => {
        this.updateProblem(this.props);
        const replaced = [];
        this.state.answers.forEach((a, i) => {
          replaced.push(a);
        });
        onAnswerUpdated(replaced);
      }
    );
  };

  render() {
    return (
      <div className="quiz-content__answer t-answer-ts textselect">
        <div>
          {this.state.problem}
        </div>
      </div>
    );
  }
}

TextSelectQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
};

export default TextSelectQuiz;
