import React from "react";
import { Input, Button, Form, Row, Col, Icon } from "antd";
import FormItem from "antd/lib/form/FormItem";
import LoginTab from "../../login/components/Login/LoginTab";
import WrappedNormalLoginForm from "../../login/components/Login/LoginForm";
import ClassLogin from "../../classMode/login/ClassLogin";

const TestLoginPage = props => {
  return (
    <div style={{ minHeight: "100%" }}>
      <ClassLogin {...props} />
    </div>
  );
};

export default TestLoginPage;
