import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from "store/modules/auth";
import * as gameActions from "store/modules/game";
import * as hometrainingActions from "store/modules/hometraining";

import "./_Game.scss";
import HomeHeader from "../../components/HomeTraning/HomeHeader/HomeHeader";
import Filter from "../../components/Filter/Filter";
import VanilaGamePlayer from "components/Game/VanilaGamePlayer";
import GameData from "./GameData";
import GameCard from "./GameCard";
import Popup from "../../components/Popup/Popup";
import { filter } from "lodash";
import HometrainingApis from "../../utils/hometrainingApis";

const locale = "ko";

const filterList = [
  {
    value: null,
    name: "전체보기",
  },
  {
    value: true,
    name: "사용가능",
  },
  {
    value: false,
    name: "사용불가",
  },
];
const gameType = {
  ko: {
    casual: "캐주얼",
    racing: "레이싱",
    defense: "디팬스",
    shooting: "슈팅",
    puzzle: "퍼즐",
    action: "액션",
    number: "수",
    shape: "도형",
    transition: "변화"
  },
  en: {
    casual: "Casual",
    racing: "Racing",
    defense: "Defense",
    shooting: "Shooting",
    puzzle: "Puzzle",
    action: "Action",
    number: "Number",
    shape: "Shapes",
    transition: "Transition"
  },
};

const vanila = GameData;

class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      game: null,
      width: "1080px",
      option: filterList[0],
      showOption: false,
      showActivationPopup: false,
      completedGameList: [],
    };
  }

  componentDidMount() {
    const { GameActions, user } = this.props;

    HometrainingApis.currentLecture(user)
      .then((isStart) => {
        if (isStart) {
          this.props.history.push(`/classroom/${user.classroom.classroomId}`);
        } else {
          GameActions.loadGames();
          if (user.productType === "junior") {
            this.setState({
              option: filterList[1],
            });
          }
          this.props.HometrainingActions.getLectures();
        }
      })
      .catch((e) => {
        GameActions.loadGames();
        if (user.productType === "junior") {
          this.setState({
            option: filterList[1],
          });
        }
        this.props.HometrainingActions.getLectures();
      });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if ((prevProps.lectures !== this.props.lectures) & this.props.lectures) {
  //     const _gameSections = [];
  //     console.log(this.props.lectures);
  //   }
  // }
  getRanking = () => {
    const { user } = this.props;

    const _r = this.ranking;

    const my =
      _r && user && _r.find((r) => r.student.studentId === user.studentId);

    return {
      my: {
        score: my && my.score,
        time: my && my.time,
        user: {
          nickname: my ? my.student.name : "나",
        },
      },
      ranking:
        _r &&
        _r
          .sort((a, b) => {
            if (a.score != b.score) {
              return b.score - a.score;
            }
            return a.time - b.time;
          })
          .map((data, i) => ({
            rank: i + 1,
            score: data.score,
            time: data.time,
            user: {
              nickname: data.student.name,
            },
          })),
    };
  };
  handleVanilaStart = (gameName, width, height) => {
    const { user } = this.props;
    const { component } = vanila[gameName];
    const name = vanila[gameName][locale].name;

    const ratio = height / width;

    const _width = width > window.innerWidth ? window.innerWidth : width;
    const _height = _width * ratio;

    this.setState({
      game: (
        <VanilaGamePlayer
          component={component}
          user={user}
          title={name}
          onClose={this.handleClose}
          onHome={this.handleHome}
          width={_width}
          height={_height}
          onComplete={(gameName, subType, score, time, callback) => {
            console.log("game end", score, time, user);
            HometrainingApis.updateGameScore(gameName, subType, score, time)
              .then((r) => {
                if (callback) {
                  callback(r.data);
                }
              })
              .catch((e) => {
                console.warn("Failed to put score");
                if (callback) {
                  callback({
                    score: score,
                    time: time,
                    user: {
                      nickname: user ? user.name : "나",
                    },
                  });
                }
              });
          }}
          loadRanking={(gameName, subType, callback) => {
            HometrainingApis.getGameRanking(gameName, subType)
              .then((r) => {
                console.log(r.data);
                if (callback) {
                  callback(r.data);
                }
              })
              .catch((e) => {
                console.warn("Failed to get ranking");
              });
          }}
        />
      ),
      width: _width + "px",
      height: _height + "px",
    });
  };
  handleClose = (type = null) => {
    console.log("close game");
    if (type === "vanila") {
      this.setState({
        game: null,
      });
      // window.location.reload();
    } else {
      this.setState({
        game: null,
      });
    }
  };

  render() {
    const { games, lectures, pending, user } = this.props;
    const { completedGameList } = this.state;
    const { data: gameData } = games;

    if (gameData == null || pending) {
      return <div></div>;
    }

    console.log(GameData);
    console.log("lectures: ", lectures);

    const _gameList = [];

    _gameList.push("Quegudan");

    if (lectures) {
      lectures.map((lecture) => {
        const _gameSections = filter(lecture.sections, function (o) {
          return o.type === "GAME";
        });
        if (_gameSections && _gameSections.length > 0) {
          _gameSections.map((game) => {
            _gameList.push(game.gameName);
          });
        }
      });
    }

    if ((_gameList.length === 0 || _gameList.length === 1) && user && user.orgType === "school") {
      //fixed games
      _gameList.push("tentens", "KMG", "DragonHunt", "alienDefence");
    }

    console.log("_gameList: ", _gameList);

    return (
      <>
        <HomeHeader lectures={lectures} />

        <div className="ht-game-layout">
          {_gameList.length == 0 && (
            <p className="empty-content">
              {" "}
              &lt;깨봉놀이터&gt;에 게임이 없습니다.{" "}
            </p>
          )}
          {/* <Filter /> */}
          <div className="game-content">
            <div className="game-content__wrap">
              {Object.keys(GameData).map((key) => {
                const data = GameData[key];
                if (data.runType !== "js" || key === "MakeTens") {
                  return;
                }
                if (!_gameList.includes(key)) {
                  return;
                }
                return (
                  <GameCard
                    key={"game" + key}
                    name={data[locale].name}
                    desc={data[locale].desc}
                    type={gameType[locale][data.type]}
                    game={{
                      game: key,
                    }}
                    mobile={data.mobile}
                    onMobileStart={data.app ? this.handleMobileStart : null}
                    option={this.state.option.value}
                    onStart={this.handleVanilaStart}
                    onActivated={this.handleActivation}
                    img={data.image}
                    mobileimg={data.mobileImage}
                    width={data.width}
                    height={data.height}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {this.state.game != null ? (
          <Popup type="game-wrap" className="game-popup ht" isOpen={true}>
            {this.state.game}
          </Popup>
        ) : null}
      </>
    );
  }
}
export default connect(
  ({ auth, game, hometraining }) => ({
    pending: auth.pending || hometraining.pending,
    user: auth.user,
    games: game.games,
    lectures: hometraining.lectures,
  }),
  (dispatch) => ({
    GameActions: bindActionCreators(gameActions, dispatch),
    AuthActions: bindActionCreators(authActions, dispatch),
    HometrainingActions: bindActionCreators(hometrainingActions, dispatch),
  })
)(Game);
