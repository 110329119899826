import React from 'react';
import QuizBasicLayout from './QuizBasicLayout';
import QuizLayoutA from './QuizLayoutA';
import QuizLayoutB from './QuizLayoutB';
import QuizLayoutC from './QuizLayoutC';
import QuizLayoutD from './QuizLayoutD';

const components = {
  basic: QuizBasicLayout,
  a: QuizLayoutA,
  b: QuizLayoutB,
  c: QuizLayoutC,
  d: QuizLayoutD
}

const QuizFrame = (props) => {
  const { data, titleView, choiceView, imageView, problemView, layout, isLong } = props;
  const { quizId, quiz } = data;
  const { title, problem, type, image, imageMobile, anim, textsOnImage } = quiz;

  const LayoutComponent = (layout && components[layout.toLowerCase()]) || components['a'];
  const QuizTitle = titleView;

  return (
    <div className="quiz-content__content not-drag">
      <QuizTitle />
      <LayoutComponent
        type={type}
        isLong={isLong}
        titleComponent={titleView}
        choiceComponent={choiceView}
        imageComponent={imageView}
        problemComponent={problemView}
      />
    </div>
  );
}

export default QuizFrame;
