import React, { useState, useEffect } from "react";
import Axios from "utils/request";
import { Skeleton, Button, Tag, Typography } from "antd";

const QuizId = (props) => {
  const { quizId, index } = props;

  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (quizId) {
      setLoading(true);
      Axios.get(`/quiz/v2/quizzes/${quizId}`)
        .then((data) => {
          setLoading(false);
          setQuiz(data);
        })
        .catch((e) => {
          setLoading(false);
          setQuiz(false);
        });
    }
    return () => {};
  }, [quizId]);

  return (
    <Skeleton loading={loading} active>
      <div style={{ pading: "0 8px" }}>
        <span>
          <Typography.Text strong>{index + 1}. </Typography.Text>
          <Typography.Text>{quizId}</Typography.Text>
        </span>
        {!quiz && <Tag color="#f50">Failed</Tag>}
      </div>
    </Skeleton>
  );
};

export default QuizId;
