import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { createPromise } from "redux-promise-middleware";
import modules from "./modules";

const isDev = process.env.REACT_APP_TEST === "true";

const configure = () => {
  // const store = createStore(modules);
  const composeEnhancers =
    (isDev &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25
      })) ||
    compose;

  const promiseHelper = createPromise({
    promiseTypeSuffixes: ["LOADING", "SUCCESS", "ERROR"]
  });

  const middlewares = applyMiddleware(
    // logger,
    ReduxThunk,
    promiseHelper
  );

  const store = createStore(modules, composeEnhancers(middlewares));

  return store;
};

export default configure;
