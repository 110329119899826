import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import ko from "react-intl/locale-data/ko";
import * as localeMessage from "../../locale";
import store from "../../store";
import AuthMonitor from "../../components/AuthMonitor";
import RouterConfig from "./RouterConfig";
import moment from "moment";
import "moment/locale/ko";
moment.locale("ko");

addLocaleData([...en, ...ko]);

function noop() {}

console.log(process.env.REACT_APP_KAKAO_KEY ,"kakaokey")
if (window.Kakao) {
  window.Kakao.init("fdea51c07896b9bf723f481ca016ea57");
}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  // console.warn = noop;
  // console.error = noop;
}

let clientId = localStorage.getItem("qb-client-id");

if (clientId == null) {
  const s4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  clientId =
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4();
  localStorage.setItem("qb-client-id", clientId);
}

window.clientId = clientId;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: "ko",
    };
  }

  onChangeLanguage = (language) => {
    this.setState({
      locale: language,
    });
  };

  componentDidMount() {
    Number.prototype.zf = function (len) {
      let str = this + "";

      while (str.length < len) {
        str = "0" + str;
      }

      return str;
    };
    String.prototype.multiline = function (key) {
      return this.split("\n").map((s, i) => (
        <span key={`${key}_${i}`}>
          {s}
          <br />
        </span>
      ));
    };

    Date.prototype.getWeek = function (dowOffset) {
      /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

      dowOffset = typeof dowOffset == "int" ? dowOffset : 0; //default dowOffset to zero
      var newYear = new Date(this.getFullYear(), 0, 1);
      var day = newYear.getDay() - dowOffset; //the day of week the year begins on
      day = day >= 0 ? day : day + 7;
      var daynum =
        Math.floor(
          (this.getTime() -
            newYear.getTime() -
            (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
            86400000
        ) + 1;
      var weeknum;
      //if the year starts before the middle of a week
      if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1;
        if (weeknum > 52) {
          let nYear = new Date(this.getFullYear() + 1, 0, 1);
          let nday = nYear.getDay() - dowOffset;
          nday = nday >= 0 ? nday : nday + 7;
          /*if the next year starts before the middle of
                    the week, it is week #1 of that year*/
          weeknum = nday < 4 ? 1 : 53;
        }
      } else {
        weeknum = Math.floor((daynum + day - 1) / 7);
      }
      return weeknum;
    };
  }

  render() {
    const { locale } = this.state;
    console.log("localeMessage: ", localeMessage);
    console.log("locale : ", locale);

    return (
      <Provider store={store}>
        <IntlProvider
          locale={locale}
          messages={localeMessage[locale].default}
          textComponent={React.Fragment}
        >
          <BrowserRouter>
            <RouterConfig onChangeLanguage={this.onChangeLanguage} />
            <AuthMonitor />
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

export default App;
