import React from "react";
import { Skeleton } from "antd";

const GameCard = props => {
  const {
    name,
    type,
    desc,
    img,
    mobileimg,
    user,
    onStart,
    onActivated,
    option,
    game,
    timestamp,
    width,
    height,
    mobile
  } = props;

  if (game == null) {
    return null;
  }

  const {
    game: gameName,
    activePrice,
    firstPrice,
    playPrice,
    minutes,
    activation,
    version,
    keys
  } = game;
  return (
    <Skeleton loading={!game} active>
      {game && (
        <div className="game-content__card">
          <div className="img-wrap">
            <img src={img} alt="" />
          </div>
          <p className="game-content__title">
            <span className="title">{name}</span>
            <span className="type">{`${type}`}</span>
          </p>
          <p
            className="game-content__desc"
            dangerouslySetInnerHTML={{ __html: desc }}
          ></p>
          <div
            className="gameplay-btn"
            onClick={() => onStart(gameName, width, height)}
          >
            게임하기
          </div>
        </div>
      )}
    </Skeleton>
  );
};

export default GameCard;
