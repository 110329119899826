import axios from "axios";
import { getDefaultConfig, getHost } from "./APIUtils";

const getQuetureDataList = quetureIds => {
  const quetureReqList = [];
  quetureIds.map(quetureId => {
    quetureReqList.push(
      axios.get(`${getHost()}/content/v2/quetures/${quetureId}`, {
        ...getDefaultConfig()
      })
    );
  });
  return axios.all(quetureReqList);
};

const addClassroomStudents = (orgId, classroomId, studentIds) => {
  const reqList = [];
  studentIds.map(studentId => {
    reqList.push(
      axios.post(
        `${getHost()}/academy/v2/accounts/${orgId}/students/${studentId}/change-class?classroomId=${classroomId}`,
        null,
        {
          ...getDefaultConfig()
        }
      )
    );
  });
  return axios.all(reqList);
};

const getQuetureName = quetureId => {
  return axios.get(`${getHost()}/content/v2/quetures/${quetureId}`, {
    ...getDefaultConfig()
  });
};

const _setHomeworkScore = (classroomId, lectureId, studentId, homeworkData) => {
  const scoreReqList = [];
  homeworkData.map(homework => {
    scoreReqList.push(
      axios.post(
        `${getHost()}/academy/v2/class/${classroomId}/lectures/${lectureId}/students/${studentId}/homeworks/${
          homework.homeworkId
        }/score?score=${homework.score}`,
        null,
        {
          ...getDefaultConfig()
        }
      )
    );
  });
  return axios.all(scoreReqList);
};

const setMultipleClassrooms = (orgId, guideId, classroomIds, unlinkClassroomIds) => {
  const reqList = [];
  classroomIds.map(classroomId => {
    reqList.push(
      axios.put(
        `${getHost()}/academy/v2/accounts/${orgId}/guides/${guideId}/classrooms/${classroomId}`,
        null,
        {
          ...getDefaultConfig()
        }
      )
    );
  });
  if (unlinkClassroomIds) {
    unlinkClassroomIds.map(classroomId => {
      reqList.push(
        axios.delete(
          `${getHost()}/academy/v2/accounts/${orgId}/guides/${guideId}/classrooms/${classroomId}`,
          {
            ...getDefaultConfig()
          }
        )
      );
    });  
  }
  return axios.all(reqList);
};

const CommonApis = {
  getQuetureDataList,
  getQuetureName,
  _setHomeworkScore,
  setMultipleClassrooms,
  addClassroomStudents
};

export default CommonApis;
