import React, { useState, useEffect } from "react";
import axios from "axios";
import { getHost, getDefaultConfig } from "../../../utils/APIUtils";
import Toast from "components/Toast/Toast";
import { isImage } from "../../../utils/utils";
import { message, Button, Icon } from "antd";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const StudentProfile = props => {
  const { student: studentData, onUploadProfile } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const host = getHost();

  useEffect(() => {
    const _profileImage =
      (studentData &&
        studentData.profileImage &&
        studentData.profileImage.signedUrl) ||
      null;
    setProfileImage(_profileImage);

    return () => {
      setProfileImage(null);
    };
  }, []);

  const onFilesAdded = files => {
    const file = files[0];
    if (file.size > 10000000) {
      message.warning("최대 파일 크기는 10M입니다.");
      return;
    }
    if (!isImage(file)) {
      message.warning("이미지만 추가할수 있습니다.");
      return;
    }
    const fileData = new FormData();
    fileData.append("file", file);

    console.log(file);
    getBase64(file, imageUrl => {
      setProfileImage(imageUrl);
    });

    setIsLoading(true);
    axios
      .post(`${host}/blob/v2/contents`, fileData, { ...getDefaultConfig() })
      .then(fileRes => {
        console.log("fileRes: ", fileRes);
        const contentId = fileRes.data.contentId;
        onUploadProfile(contentId);
        setIsLoading(false);
      })
      .catch(error => {
        message.warning(
          "프로필 업로드에 실패했습니다. 잠시 후 다시 시도해주세요."
        );
        setIsLoading(false);
      });
  };

  return (
    <div
      className="imgwrap"
      style={{
        maxWidth: 230,
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#F7F9FB",
        border: "1px dashed #CCCCCC",
        boxSizing: "border-box",
        borderRadius: 4,
        position: "relative",
        marginRight: 20
      }}
    >
      {isLoading ? (
        <div></div>
      ) : (
        <>
          {profileImage ? (
            <img
              src={profileImage}
              alt=""
              style={{
                width: "100%",
                maxHeight: "100%",
                objectFit: "cover"
              }}
            />
          ) : (
            <Icon style={{ width: 80 }} type="smile" />
          )}
          <label
            htmlFor={"profileImage"}
            className="img-change img-btn"
            style={{
              position: "absolute",
              bottom: -40
            }}
          >
            <Icon type="upload" /> Upload
            <input
              id={"profileImage"}
              type="file"
              style={{ display: "none" }}
              onChange={e => onFilesAdded(e.target.files)}
            />
          </label>
        </>
      )}
    </div>
  );
};

export default StudentProfile;
