import React,{ Component } from "react";
import {
  Modal,
  Typography,
  Select,
  Row,
  Col,
  Table,
  Input,
  Transfer,
  Tag
} from "antd";
import { filter } from "lodash";

export class ChangeClassroomStudentListPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchUser:"",
      studentList: [],
      selectedKeys: [],
      targetKeys: []
    };
  }

  componentDidMount() {
    const { studentList, classroomData } = this.props;

    console.log("studentList: ", studentList);
    console.log("classroomData: ", classroomData);
    const courseId =
      (classroomData &&
        classroomData.course &&
        classroomData.course.courseId) ||
      null;
    const classroomId = (classroomData && classroomData.classroomId) || null;
    const _targetList = classroomData.students;
    const _studentList = [];
    let filteredStudentList = studentList.filter(function(student) {
      return (
        (student.classroom && student.classroom.classroomId !== classroomId) ||
        !student.classroom
      );
    });
    for (let i = 0; i < filteredStudentList.length; i++) {
      const student = studentList[i];
      student["key"] = student.studentId;
      if (!student.classroom) {
        _studentList.push(student);
      }
      if (
        student.classroom &&
        student.classroom.course &&
        student.classroom.course.courseId !== courseId
      ) {
        _studentList.push(student);
      }
    }
    console.log("courseID: ", courseId);
    console.log("targetList: ", _targetList);
    console.log("studentList: ", _studentList.sort(function(a,b)
    {
      return a.name.localeCompare(b.name);
    }));
    this.setState({
      studentList: _studentList
    });
  }

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });

    console.log("targetKeys: ", nextTargetKeys);
    console.log("direction: ", direction);
    console.log("moveKeys: ", moveKeys);
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });

    console.log("sourceSelectedKeys: ", sourceSelectedKeys);
    console.log("targetSelectedKeys: ", targetSelectedKeys);
  };

  handleScroll = (direction, e) => {
    console.log("direction:", direction);
    console.log("target:", e.target);
  };

  handleOK() {
    const { onSelect } = this.props;
    onSelect(this.state.targetKeys);
    console.log("selected list: ", this.state.targetKeys);
  }

  render() {
    console.log("student list: ", this.state.studentList);
    return (
      <Modal {...this.props} onOk={() => this.handleOK()}>
        <div>
          <div className="inputwrap">
            <Input.Search
              style={{width:"44.5%",height:"40%",margin:'auto',marginBottom:10,marginLeft:0}}
              type="searchuser"
              name="searchuser"
              placeholder="학생 이름"
              // value={}
              onChange={(e)=>{
                const value=e.target.value.trim();
                this.setState({
                  searchUser:value
                });
              }}
              required/>
          </div>
          {
            this.state.studentList.length > 0 && (
            <Transfer
              dataSource={this.state.studentList.filter(e=>{
                if(this.state.targetKeys.includes(e.studentId)){
                  return true;
                }
                return e.name.toLowerCase().includes(this.state.searchUser.toLowerCase())
              })}
            titles={["선택 리스트", "추가 리스트"]}
            targetKeys={this.state.targetKeys}
            selectedKeys={this.state.selectedKeys}
            onChange={this.handleChange}
            onSelectChange={this.handleSelectChange}
            onScroll={this.handleScroll}
            render={item => (
              <span>
                {item.name}
                {item.classroom && (
                  <Tag color="blue">{item.classroom.name}</Tag>
                )}
              </span>
            )}
            listStyle={{ height: 300, width: 210 }}
          />
        )}
        </div>
      </Modal>
    );
  }
}

export default ChangeClassroomStudentListPopup;
