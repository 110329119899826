import React from 'react';
import Katex from '../katex/Katex';
import ExternalImage from './ExternalImage'
// import 'katex/dist/katex.min.css';

const replaceCRLF = (t, id, idx) => {
  const _id = id != null ? id : 'tmp';
  console.log(t)
  return <span key={_id + '_other_' + idx}>{t}</span>
};

const replaceCRLImage = (t, id, signedUrl) => {
  const _id = id != null ? id : 'tmp';

  let sliceString = t;
  let sizeString = null

  if(t.indexOf("{image=") !== -1) {
    sliceString = sliceString.replace(/{/g,'').replace(/}/g,'').split(',')
    sizeString = sliceString[1]? sliceString[1].replace(/scale=/,'') : null;
  }

  return (<span>{sliceString[0].indexOf("image=") !== -1 ? signedUrl.map((url, idx) => sliceString[0].indexOf(idx) !== -1 ?
    <div><ExternalImage imgSrc={url} scale={sizeString ? Number(sizeString): 1 }/></div> : null) : t}</span>)
};

const drawKatex = (_t, _id, signedUrl, katexIndices, katexReg) => {

  let lastIdx = 0;

  return katexIndices.map((i, seq) => {
    let pre = _t.substring(lastIdx, i);
    lastIdx = i;

    const key = _id + '_kat_' + seq;

    const r = katexReg.exec(escape(pre));

    if (r != null && r.index >= 0) {
      return <Katex key={key} math={r[1]}/>
    } else {
      if (_t === '') {
        return <div><br/></div>
      } else if (!signedUrl || signedUrl.length === 0) {
        return <span key={key}>{replaceCRLF(pre, _id)}</span>
      } else {
        return <span key={key}>{replaceCRLImage(pre, _id, signedUrl)}</span>
      }
    }
  });
}
const escape = (t) => {
  return t.replace(/＝/g, "=")
    .replace(/－/g, "-")
    .replace(/＋/g, "+")
    .replace(/𝑚/g, "m")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, "\"");
}

export function formatImgText(text, id, signedUrl) {
  const _id = id != null ? id : 'tmp';

  if (text == null) {
    return '';
  }

  return escape(text).split('\n').map(txt => {
    console.log(txt)// 전체 text에서 카텍스를 분리한 후 줄바꿈 -> 먼저 줄바꿈을 나눈 후 카텍스를 분리
    const _t = txt.normalize ? txt.normalize("NFC") : txt
    let r;
    let katexIndices = [];
    let lastIndex = 0;
    const katexReg = new RegExp(/\$\$([^\$]+)\$\$/igm);

    while (r = katexReg.exec(_t)) {
      if (r.index < lastIndex) {
        continue;
      }

      katexIndices.push(r.index, katexReg.lastIndex);
      lastIndex = katexReg.lastIndex;
    }
    katexIndices.push(_t.length);

    let result;

    if (katexIndices.length > 0) {
      return <div>{drawKatex(_t, _id, signedUrl, katexIndices, katexReg)}</div> //카텍스가 있는 줄
    } else {//카텍스가 없는 줄
      if (_t === '') {
        return <div><br/></div>
      } else if (!signedUrl || signedUrl.length == 0) {
        return <span>{replaceCRLF(_t, id)}</span>
      } else {
        return <span>{replaceCRLImage(_t, id, signedUrl)}</span>
      }
    }
  })
}
