import React from "react";
import HomeHeader from "../components/HomeTraning/HomeHeader/HomeHeader";

const UserLayout = ({ user, children }) => {
  return (
    <div className="user-layout">
      {/* <HomeHeader /> */}
      {children}
    </div>
  );
};

export default UserLayout;
