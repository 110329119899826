import React from 'react'
import { DragLayer } from 'react-dnd'
import OrderablePreview from './OrderablePreview'
import DraggablePreview from './DraggablePreview';
import { isAndroid, isIOS } from 'react-device-detect';
import { isIPadSafari } from 'utils/utils';

const isMobile = isAndroid || isIOS || isIPadSafari();

function getItemStyles(props) {
  const { initialOffset, currentOffset } = props
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }
  let { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

const CustomDragLayer = props => {
  const { item, itemType, isDragging } = props;
  function renderItem() {
    if (itemType.startsWith("order_")) {
      // console.log('item: ', item)
      if (isMobile) {
        return <OrderablePreview choice={item} />
      } else {
        return null;
      }

    } else if (itemType.startsWith("drag_")) {
      return <DraggablePreview choice={item.data} />
    } else {
      return null;
    }
  }
  if (!isDragging) {
    return null;
  }

  // console.log(getItemStyles(props));

  const width = !props.isMobileSize && itemType.startsWith("order_") ? 'calc(100% - 182px)' : '100%';
  return (
    <div
      style={{
        width: width,
        height: "100%",
        left: 0,
        top: 0,
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
      }}
    >
      <div style={getItemStyles(props)}>
        {renderItem()}
      </div>
    </div>
  )
}
export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
}))(CustomDragLayer)
