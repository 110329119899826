import React, { Component } from "react";
import Axios from "../../../../utils/request";
import "./published.scss";
import classNames from "classnames";
import PublishedReportQuetureList from "./PublishedReportQuetureList";
import moment from "moment";
import { formateDate } from "utils/utils";
import { Input } from "antd";


class PublishedReportPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: '',
      isLoading: false,
      reportLog: null,
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    const ss =  window.location.pathname.split(/\//);
    const id = ss[ss.length - 1];
    this.setState({
      id: id,
      isLoading: true,
    });

    Axios.get(`/academy/v2/report/log/${id}`)
      .then((response) => {
        let res = JSON.parse(response.data);
        let _reportData = res;

        // to be synced with index.js
        let _queturesPerLecture = res.lectures[0].quetures.length;
        let _lecturesQuetureCount = 0;
        res.lectures.map((l) => { 
          if (l.quetures.length > _queturesPerLecture) {
            _queturesPerLecture = l.quetures.length;
          }
          _lecturesQuetureCount += l.quetures.length;
        });
        _reportData["lecturesQuetureCount"] = _lecturesQuetureCount;
        _reportData["queturesPerLecture"] = _queturesPerLecture;

        res.data = _reportData;
        console.log("### res: ", res);

        this.setState({
          isLoading: false,
          reportLog: res,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          reportLog: null,
        });
      });
  }


  render() {
    const { id, isLoading, reportLog } = this.state;

    if (isLoading) {
      return <div>학습리포트 정로를 조회 중입니다...</div>;
    } else if (!reportLog) {
      return <div>학습리포트 정보를 조회할수 없습니다: {id}</div>;
    } 

    let reportData = reportLog.data;
    let courseNo = reportData.courseNo;
    let courseTitle = reportData.courseTitle;
    let examResult = reportData.examResult;
    let examCorrect = reportData.examCorrect;
    let examPartial = reportData.examPartial;
    let examSolved = reportData.examSolved;
    let examAbsent = reportData.examAbsent;
    let examExecDate = reportData.examExecDate;
    let examTime = reportData.examTime;
    let comment = reportData.comment;
    let commentTime = reportData.commentTime;

    let titleRange = '';
    if (reportData && reportData.lectures) {
      if (reportData.lectures.length > 1) {
        titleRange = String(reportData.lectures[0].title).replace('회차','').replace('강','') + '~' + reportData.lectures[reportData.lectures.length-1].title;
      } else {
        titleRange = reportData.lectures[0].title;
      }
    }

    let examPercent = (examSolved > 0 && examCorrect != null && examPartial != null)? Math.round((100 * examCorrect + 50 * examPartial) / examSolved) : null;
    examExecDate = (examExecDate != null && examExecDate != 0) ? examExecDate
          : (new Date(reportData.lectures[reportData.lectures.length-1].date)).getTime() + 604800000 /* 1week */;

    return (
      <div className="published-report">
      <div className="published-report-inner">

        <div className="published-report-title">
          <div className="title">
             <span>{reportData.studentName+' '}</span>
             학습 리포트
          </div>
          <div className="info">
             {(reportData.courseTitle.substring(1,2) === '0' ? reportData.courseTitle.substring(0,2) : reportData.courseTitle.substring(0,1)) + '코스 '} {titleRange}
             {reportData.courseNo > reportData.courseCompleted ? <font color="red"> (코스 미완료)</font> : ''}
             <br />
             기간: {reportData.lecturesDates}
          </div>
        </div>

        <div className="published-report-subtitle log" style={{ cursor: 'pointer' }}> 
          {'테스트 결과 '}
          {examAbsent && <font color="red">&nbsp;[결시] </font>}
        </div>

        <div className="published-report-layout result-info" style={{ background: '#e6e8f2' }}>
          <div className="published-report-sublayout">
            <div className="content-title">
              수행일자
            </div>
            <div className="strong-text text-color-grey">
              <span style={{ fontSize: '16px' }}>
              {!(examResult || examAbsent)? '-' :  moment(new Date(examExecDate)).format("YYYY.MM.DD")}
              </span>
            </div>
          </div>
          <div className="published-report-sublayout">
            <div className="content-title">
              정답률
            </div>
            {(examPercent) ?
            <div
              className={'strong-text', classNames("strong-text", {
                "text-color-blue": examPercent >= 80,
                "text-color-orange": examPercent < 80 && examPercent >= 30,
                "text-color-red": examPercent < 30,
              })}
            >
              <span>
                <strong>{examPercent}</strong>%
              </span>
            </div>
            : 
            <div className={classNames("strong-text", "text-color-grey")}>
              <span>
                -
              </span>
            </div>
            }
          </div>
          <div className="published-report-sublayout">
            <div className="content-title">정답 / 부분정답 / 오답
            </div>
            {(examSolved > 0 && examCorrect != null && examPartial != null) ?
            <div className="strong-text">
              <span>
                <strong className="text-color-blue">{examCorrect}</strong>개 &nbsp;/&nbsp;
                <strong className="text-color-orange">{examPartial}</strong>개 &nbsp;/&nbsp;
                <strong className="text-color-red">{examSolved - examCorrect - examPartial}</strong>개
              </span>
            </div>
            : 
            <div className={classNames("strong-text", "text-color-grey")}>
              <span>
                -
              </span>
            </div>
            }
          </div>
        </div>

        <div className="published-report-subtitle log"> 
          {'선생님 메모 '}
        </div>
        <div className="published-report-layout textarea">
          <Input.TextArea
            className=""
            value={comment}
            readOnly={true}
            autoSize={{ minRows: 1 }} />
        </div>

        <div className="published-report-subtitle log"> 
          {'학습 활동 내역'}
        </div>
        <div className="published-report-layout profile">
          <div className="published-report-sublayout-wrap">
            <div className="published-report-sublayout published-report_class">
              <div className="content-title">수업시수</div>
              <div className="strong-text text-color-blue">
              {reportData.courseNo <= reportData.courseCompleted ?
                 <span><strong>{reportData.lecturesCount}</strong>회</span>
               : <span>(미완료)</span>}
              </div>
            </div>
            <div className="published-report-sublayout published-report_queture">
              <div className="content-title">학습 깨처 수</div>
              <div className="strong-text text-color-blue">
                {reportData.courseNo <= reportData.courseCompleted ?
                   <span><strong>{reportData.lecturesQuetureCount}</strong>깨처</span>
                 : <span>(미완료)</span>}
              </div>
            </div>
          </div>
          <div className="published-report-sublayout-wrap" style={{ marginTop: '10px' }}>
            <div className="published-report-sublayout published-report_mvp">
              <div className="content-title">MVP</div>
              <div className="strong-text text-color-blue">
                <span>
                  <strong>{reportData.mvp}</strong>번
                </span>
              </div>
            </div>
            <div className="published-report-sublayout published-report_absent">
              <div className="content-title">결석</div>
              <div className="strong-text text-color-red">
                {reportData.courseNo <= reportData.courseCompleted ?
                   <span><strong>{reportData.absent}</strong>번</span>
                 : <span>(미완료)</span>}
              </div>
            </div>
          </div>
        </div>

        {reportData.lectures && (
          <PublishedReportQuetureList
            rowSpan={reportData.queturesPerLecture}
            list={reportData.lectures}
            isBreakPage={false}
          />
        )}

    </div>
    </div>
  );

  }
};

export default PublishedReportPage;
