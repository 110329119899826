import React from 'react';
import classNames from 'classnames';

const QuizLayoutA = (props) => {
  const { type, isLong, imageComponent, problemComponent, choiceComponent, titleComponent, desmosComponent } = props;
  const QuizAnswers = choiceComponent;
  const QuizProblem = problemComponent;
  const Desmos = desmosComponent;
  const QuizImage = imageComponent;
  const QuizTitle = titleComponent;

  return (
    <div className={classNames("layout type-a", {
      "long": isLong
    })}>
      <div className="mb16">
        <QuizImage />
      </div>
      {/* <div className="mb16">
        <QuizTitle />
      </div> */}
      {
        QuizProblem() &&
        <div className="mb16">
          <QuizProblem />
        </div>
      }
      <QuizAnswers />
    </div>
  );
}

export default QuizLayoutA;
