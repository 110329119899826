import React, { useState, useEffect } from "react";
import "./_mvp.scss";
import LeafAni from "../../../components/BodyMovin/Animation/LeafAniLeft";
import AttendanceList from "../../../components/Teacher/component/AttendanceList";
import LeafAniLeft from "../../../components/BodyMovin/Animation/LeafAniLeft";
import LeafAniRight from "../../../components/BodyMovin/Animation/LeafAniRight";
import StudentList from "../../../components/Teacher/component/StudentList";
import Audio from "../../../components/Audio/Audio";
import Axios from "../../../utils/request";
import { FormattedHTMLMessage } from "react-intl";
import StandBy from "../etcpage/StandBy";
import MvpAnimation from "./MvpAnimation/MvpAnimation";

const Mvp = (props) => {
  const { isKids, viewType, classroomId } = props;
  const [mvpList, setMvpList] = useState(null);

  useEffect(() => {
    Axios.get(`/academy/v2/class/${classroomId}/lectures/current`).then(
      (res) => {
        setMvpList(res.mvps);
      }
    );

    return () => {
      setMvpList(null);
    };
  }, []);

  if(!mvpList) {
    return <StandBy isKids={isKids} />
  }

  if(mvpList && isKids && viewType === 'preview') {
    return <MvpAnimation studentList={mvpList} />
  }
  

  return (
    <div className="mvp-layout">
      <div className="mvp-wrap">
        <div className="title">
          {/* 오늘의 <span>MVP</span> */}
          <FormattedHTMLMessage id="classroom.todays-mvp" tagName="div" />
        </div>
        <div className="mvp-content">
          <LeafAniLeft />
          {/* <AttendanceList /> */}
          <StudentList studentList={mvpList} />
          <LeafAniRight />
        </div>
      </div>
      {viewType === "preview" && mvpList !== null && <Audio type={"mvp"} />}
    </div>
  );
};

export default Mvp;
