import React, { Component } from "react";
import { Modal, Input, Typography, Col, Card, Tag, message, Radio } from "antd";

const { Title, Text } = Typography;
const { TextArea } = Input;

class SetHomeworkPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      homeworkValue: "",
      homeworkId: null
    };
  }

  componentDidMount() {
    const { homework } = this.props;
    console.log("homework: ", homework);
    if (homework && homework.items) {
      const _uploadHomework = homework.items.find(h => h.type === "upload");
      console.log("upload homework: ", _uploadHomework);
      if (_uploadHomework) {
        this.setState({
          homeworkId: _uploadHomework.homeworkId,
          homeworkValue: _uploadHomework.value
        });
      }
    }
  }

  handleOK = () => {
    const { homeworkValue, homeworkId } = this.state;

    this.props.onDone(homeworkValue, homeworkId);
  };

  render() {
    const { homeworkValue } = this.state;

    return (
      <Modal {...this.props} onOk={() => this.handleOK()}>
        <Title level={4}>과제설정</Title>
        <TextArea
          rows={4}
          value={homeworkValue}
          onChange={e => {
            this.setState({
              homeworkValue: e.target.value
            });
          }}
        />
      </Modal>
    );
  }
}

export default SetHomeworkPopup;
