import classNames from "classnames";
import React from "react";
import iconArrowUp from "../images/icon-arrow-up-white.svg";
import iconArrowUpGray from "../images/icon-arrow-up-gray.svg";

const Counter = (props) => {
  const {
    onUp,
    onDown,
    value,
    index,
    solvedData,
    showReallyTrue,
    correctAnswer,
  } = props;

  const isTrue = showReallyTrue ? true : solvedData?.correct;
  const isFalse = !showReallyTrue && solvedData.solved && !solvedData?.correct;

  const currentValue = showReallyTrue ? correctAnswer[index] : value;
  console.log("correctAnswer: ", correctAnswer, solvedData);
  return (
    <div
      className={classNames("counter-wrap", {
        "is-false": isFalse,
        "is-true": isTrue,
        "pointer-events-none": solvedData?.solved,
      })}
    >
      <button
        className={classNames("btn-counting", {
          "is-solved": solvedData?.solved,
        })}
        onClick={() => onUp(value, index)}
      >
        <img
          src={solvedData?.solved ? iconArrowUpGray : iconArrowUp}
          alt="up"
          width={24}
          height={24}
        />
      </button>
      <span className={"my-4"}>{currentValue}</span>
      <button
        className={classNames("btn-counting", {
          "is-solved": solvedData?.solved,
        })}
        onClick={() => onDown(value, index)}
      >
        <img
          src={solvedData?.solved ? iconArrowUpGray : iconArrowUp}
          alt="up"
          width={24}
          height={24}
          className="rotate-180"
        />
      </button>
    </div>
  );
};

export default Counter;
