import React, { Component } from "react";
import { Table, Button, Dropdown, Icon, Menu, Typography, Tag } from "antd";
import { Link } from "react-router-dom";
import { ConstantsContext } from "utils/ConstantsContext";

class GuideList extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      editingKey: null
    };
  }

  render() {
    const { tab, guideList } = this.props;

    let columns = [
      {
        title: "구분",
        key: "state",
        dataIndex: "state",
        render: state => (
          <span>
            {state === "working" ? (
              <Typography.Text>재직중</Typography.Text>
            ) : (
              <Typography.Text type="danger">퇴사</Typography.Text>
            )}
          </span>
        )
      },
      {
        title: "이름",
        key: "name",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/admin/guide/detail/${record.guideId}`}>
            <span>{text}</span>
          </Link>
        )
      },
      {
        title: "아이디",
        key: "account",
        dataIndex: "account",
        render: text => <span>{text}</span>
      },
      {
        title: "연락처",
        key: "mobile",
        dataIndex: "mobile",
        render: text => <span>{text}</span>
      },
      {
        title: "생년월일",
        key: "birthday",
        dataIndex: "birthday",
        render: birthday => (
          <span>
            {birthday
              ? `${birthday.year}.${birthday.month}.${birthday.day}`
              : null}
          </span>
        )
      },{
        title: `담당 지점`,
        key: "orgAccount",
        dataIndex : "organizationViewList",
        render: (organizationViewList) => (
          <span>
            {organizationViewList.map(e=>
            <Tag>
              {e.name}
            </Tag>
            )}
          </span>
        )
      }
      , {
        title: `담당 ${this.context.className}`,
        key: "classrooms",
        dataIndex: "classrooms",
        render: classrooms => (
          <span>
            {classrooms.length > 0 ? (
              classrooms.length === 1 ? (
                <Button style={{ cursor: "default" }}>
                  {classrooms[0].name} ({classrooms[0].organization.name})
                </Button>
              ) : (
                <>
                  <Dropdown
                    overlay={
                      <Menu>
                        {classrooms.map(classroom => (
                          <Menu.Item
                            key={classroom.classroomId}
                            style={{ cursor: "default" }}
                          >
                            {classroom.name} ({classroom.organization.name})
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Button style={{ cursor: "default" }}>
                      {`모든 ${this.context.className} 보기(${classrooms.length})`}
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </>
              )
            ) : (
              "없음"
            )}
          </span>
        )
      }
    ];

    const data = guideList;

    console.log("data: ", data);
    return <Table rowKey="guideId" dataSource={data} columns={columns} />;
  }
}

export default GuideList;
