import React, { useEffect, useRef, useState } from "react";
import BodyMovin from "components/BodyMovin/BodyMovin";
import bubbleAnimation from "./animations/attendance-bubble.json";
import { useWindowSize } from "react-use";
import "./bubble.scss"

const step = [4, 5, 6, 7, 8, 9, -4, -5, -6, -7, -8, -9];
const ballWidth = 180;
const ballHeight = 180;

const Bubble = (props) => {
  const { studentId, studentList } = props;
  const ballRef = useRef(null);
  const [isPlay, setPlay] = useState(false);
  const [position, setPosition] = useState({ x: 1230, y: 330 });
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  useEffect(() => {
    moveBall(ballRef?.current);

    return () => {
      clearInterval(ballRef?.current?.timer);
    };
  }, []);

  function moveBall(obj) {
    clearInterval(obj.timer);
    let stepx = getStep();
    let stepy = getStep();
    let width = windowWidth - ballWidth;
    let height = windowHeight - ballHeight;
    // 用来判断是否超出屏幕以及每次小球的距离
    let disx;
    let disy;
    obj.timer = setInterval(function () {
      disx = obj.offsetLeft + stepx;
      disy = obj.offsetTop + stepy;
      if (disx >= width) {
        // 水平方向上判断是否超出右边屏幕
        disx = width;
        stepx = -stepx;
      }
      if (disx < 0) {
        // 水平方向上判断是否超出左边屏幕
        disx = 0;
        stepx = -stepx;
      }
      if (disy >= height) {
        // 垂直方向上判断是否超出下边屏幕
        disy = height;
        stepy = -stepy;
      }
      if (disy < 0) {
        // 垂直方向上判断是否超出上边屏幕
        disy = 0;
        stepy = -stepy;
      }
      setPosition({ x: disx, y: disy });
    }, 60);
  }

  function getStep() {
    // return ;
    return step[Math.floor(Math.random() * step.length)];
  }

  const bubbleOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: bubbleAnimation,
  };

  let name = ""
  if(studentList && studentId) {
    name = studentList.find(s => s.studentId === studentId).name
  }
  
  return (
    <div
      ref={ballRef}
      className="bubble-wrap"
      style={{
        left: position.x,
        top: position.y,
      }}
    >
      <BodyMovin className="bubble-anim" options={bubbleOptions} />
      <span className="name">{name}</span>
    </div>
  );
};
export default Bubble;
