import React, { Component } from "react";
import { Modal, Typography, Select, Row, Col, Table } from "antd";
import { filter } from "lodash";
import { ConstantsContext } from "utils/ConstantsContext";

class ChangeStudentClassPopup extends Component {
  static contextType = ConstantsContext;

  constructor(props) {
    super(props);

    this.state = {
      selectedClass: null,
      filterVal: '',
    };
  }

  handleOK = () => {
    const { onSelect } = this.props;
    const {selectedClass } =this.state;
    console.log(selectedClass)
    onSelect(selectedClass);
  }

  render() {
    console.log("classroomList: ", this.props.classroomList);
    return (
      <Modal {...this.props} onOk={() => this.handleOK()}>
        <div>
          <Typography.Text strong>
            {`${this.props.studentData.name} 학생의 ${this.context.className} 정보를 아래와 같이 변경하시겠습니까?`}
          </Typography.Text>
        </div>
        <Select
          onChange={e => {
            console.log(e)
            this.setState({
              selectedClass: e
            })
          }}
          showSearch
          filterOption={false}
          showArrow={false}
          onSearch={(e) => {
            this.setState({
              filterVal: e
            })
          }}
          notFoundContent={null}
          placeholder={"Tags Mode"}
          style={{ minWidth: "100%", margin: "16px 0" }}
          placeholder={`${this.context.className}를 선택해주세요 `}>
          {this.props.classroomList.filter(c => c.state === "current").filter(e => e.name.toLowerCase().indexOf(this.state.filterVal.toLowerCase()) !== -1).map((classroom, index) => (
            <Select.Option key={index} value={classroom.classroomId}>
              {classroom.name}
            </Select.Option>
          ))}
        </Select>
        {this.state.selectedClass && (
          <Table
            pagination={false}
            dataSource={[
              {
                key: 1,
                studentName: this.props.studentData.name,
                account: this.props.studentData.account,
                beforeClassroomName: this.props.studentData.classroom
                  ? this.props.studentData.classroom.name
                  : null,
                afterClassroomName: this.state.selectedClass.name
              }
            ]}
            rowKey="key"
          >
            <Table.Column
              title="학생 정보"
              dataIndex="studentName"
              key="studentName"
              render={(text, record) => (
                <span>{`${text}(${record.account})`}</span>
              )}
            />
            <Table.Column
              title={`변경 전 ${this.context.className}`}
              dataIndex="beforeClassroomName"
              key="beforeClassroomName"
              render={text => <span>{text}</span>}
            />
            <Table.Column
              title={`변경 후 ${this.context.className}`}
              dataIndex="afterClassroomName"
              key="afterClassroomName"
              render={text => <span>{text}</span>}
            />
          </Table>
        )}
        <Typography.Text type="danger">
          {`※ ${this.context.className} 변경 시 현재 ${this.context.className}의 학습 데이터는 모두 초기화됩니다.`}
        </Typography.Text>
      </Modal>
    );
  }
}

export default ChangeStudentClassPopup;
