import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import Axios from "../../../../utils/request";
import {
  Skeleton,
  Row,
  Col,
  List,
  Tag,
  PageHeader,
  Button,
  Icon,
  Typography,
  Tabs,
  Menu,
  Dropdown,
} from "antd";
import QueturePlayer from "../../../../components/QueturePlayer/QueturePlayer";
import { getHost } from "../../../../utils/APIUtils";
import PreviewQuizList from "../../components/PreviewQuizList";
import VanilaGamePlayer from "components/Game/VanilaGamePlayer";
import GameData from "pages/game/GameData";
import Popup from "components/Popup/Popup";
import GameExplain from "../../../classMode/game/GameExplain";
import Activity from "../../../classMode/activity/Activity";
import ClassroomApis from "../../../../utils/classroomApis";
import { formatImgText } from "../../../../components/Quiz/utils/ImageFormatter";
import PreviewStorytelling from "../../../classMode/storytelling/PreviewStorytelling";
import QuebonSongDetail from "./QuebonSongDetail/QuebonSongDetail";
import SectionStateTag from "components/Tag/SectionStateTag";

const vanila = GameData;
const locale = "ko";
const gameType = {
  ko: {
    casual: "캐주얼",
    racing: "레이싱",
    defense: "디팬스",
    shooting: "슈팅",
    puzzle: "퍼즐",
    action: "액션",
    number: "수",
    shape: "도형",
  },
  en: {
    casual: "Casual",
    racing: "Racing",
    defense: "Defense",
    shooting: "Shooting",
    puzzle: "Puzzle",
    action: "Action",
    number: "Number",
    shape: "Shapes",
  },
};
const { Title, Text } = Typography;
class LecturePreviewPage extends Component {
  ranking = [];
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      classroomData: null,
      lectureData: null,
      storytellingData: null,
      mainTab: "0",
      subTab: "0",
      subTabActivity: "0",
      guidePopup: false,
      game: null,
    };
  }

  componentDidMount() {
    const { user, ClassroomActions } = this.props;
    const { id, lectureId } = this.props.match.params;
    if (user) {
      // ClassroomActions.getClassroomList(user.orgId || user.userId).then(res => {
      //   if (res && res.length > 0) {
      //     console.log("res", res[0]);
      //     if (id) {
      //       this.getClassroomDetail(id);
      //       // this.getLectureList(id);
      //     } else {
      //       this.getClassroomDetail(res[0]["classroomId"]);
      //       // this.getLectureList(res[0]["classroomId"]);
      //     }
      //   }
      // });
      ClassroomApis.getLecture(id, lectureId)
        .then((res) => {
          const curLecture = res;
          console.log("curLecture: ", curLecture);
          curLecture["quetureGroup"] = [];
          let flag = null;
          let groupIndex = -1;

          curLecture["gameSections"] = [];
          curLecture["activitySections"] = [];
          curLecture["challengeSections"] = [];
          curLecture["endQuizSections"] = [];
          curLecture["storytellingSections"] = [];
          curLecture["pictureQuizSections"] = [];
          curLecture["quizQuetures"] = [];
          curLecture["quebonSongSection"] = [];

          curLecture.sections.map((s, index) => {
            if (s.type === "QUETURE" && flag !== s.quetureId) {
              groupIndex++;
              flag = s.quetureId;
              curLecture["quetureGroup"][groupIndex] = {};
              this.getQuetureData(groupIndex, s.quetureId);
            }
            if (s.type === "QUETURE" || s.type === "QUIZ") {
              curLecture["quetureGroup"][groupIndex][s.type] = s;
            }
            if (s.type === "QUIZ") {
              curLecture["quizQuetures"].push(s.quetureId);
            }
            if (s.type === "GAME") {
              curLecture["gameSections"].push(s);
            }
            if (s.type === "ACTIVITY") {
              curLecture["activitySections"].push(s);
            }
            if (s.type === "CHALLENGE") {
              curLecture["challengeSections"].push(s);
            }
            if (s.type === "ENDQUIZ") {
              curLecture["endQuizSections"].push(s);
            }
            if (s.type === "STORYTELLING") {
              curLecture["storytellingSections"].push(s);
            }
            if (s.type === "QUEBONSONG") {
              curLecture["quebonSongSection"].push(s);
            }
            if (s.type === "PICTUREQUIZ") {
              if (!s.pictureQuizId) {
                curLecture["pictureQuizSections"].push(s);
              } else {
                Axios.get(
                  `/academy/v2/kidsPictureQuizs/${s.pictureQuizId}`
                ).then((res) => {
                  s.content = res.content;
                  console.log(res);
                  curLecture["pictureQuizSections"].push(s);
                  this.setState({
                    lectureData: curLecture,
                    loading: false,
                  });
                });
              }
            }
          });
          console.log("curLecture: ", curLecture);

          this.setState({
            lectureData: curLecture,
            loading: false,
          });
        })
        .catch((e) => {
          console.log("errorcode:", e);
          this.setState({
            loading: false,
          });
        });
    }
  }

  getQuetureData(groupIndex, quetureId) {
    Axios.get(
      `/content/v2/quetures/${quetureId}?withQuetureTeachingGuide=${false}&withQuetureCampusNote=${true}`
    ).then((resolve) => {
      console.log("resovle: ", resolve);
      const curLecture = this.state.lectureData;
      console.log(this.state.lectureData, "lecturedata");
      curLecture["quetureGroup"][groupIndex]["quetureData"] = resolve;
      this.setState({
        lectureData: curLecture,
      });
    });
  }

  changeMainTab = (activeKey) => {
    console.log(activeKey, "ChangeTab");
    this.setState({
      mainTab: activeKey,
      subTab: "0",
    });
  };

  changeSubTabActivity = (activeKey) => {
    console.log(activeKey, "ChangeTab");
    this.setState({
      subTabActivity: activeKey,
    });
  };

  changeSubTab = (activeKey) => {
    console.log(activeKey, "ChangeTab");
    this.setState({
      subTab: activeKey,
    });
  };

  getRanking = () => {
    const { user } = this.props;

    const _r = this.ranking;

    const my =
      _r && user && _r.find((r) => r.student.studentId === user.studentId);

    return {
      my: {
        score: my && my.score,
        time: my && my.time,
        user: {
          nickname: my ? my.student.name : "나",
        },
      },
      ranking:
        _r &&
        _r
          .sort((a, b) => {
            if (a.score != b.score) {
              return b.score - a.score;
            }
            return a.time - b.time;
          })
          .map((data, i) => ({
            rank: i + 1,
            score: data.score,
            time: data.time,
            user: {
              nickname: data.student.name,
            },
          })),
    };
  };

  handleVanilaStart = (gameName) => {
    const { user } = this.props;
    const { component } = vanila[gameName];
    const name = vanila[gameName][locale].name;

    this.setState({
      game: (
        <VanilaGamePlayer
          component={component}
          user={user}
          title={name}
          onClose={() => {
            this.setState({
              game: null,
            });
          }}
          onHome={this.handleHome}
          onComplete={(gameName, subType, score, time, callback) => {
            if (callback) {
              console.log("game end", score, time, user);
              this.user1 = {
                nickname: user ? user.name : "나",
              };
              callback({
                score: score,
                time: time,
                user: this.user1,
              });
            }
          }}
          loadRanking={(gameName, subType, callback) => {
            console.log(this.ranking);
            if (callback) {
              callback(this.getRanking());
            }
          }}
          width={vanila[gameName].width + "px"}
          height={vanila[gameName].height + "px"}
          isAcademy={true}
        />
      ),
    });
  };

  render() {
    const { user, pending, classroomList } = this.props;
    const {
      loading,
      classroomData,
      lectureData,
      mainTab,
      subTab,
      subTabActivity,
      guidePopup,
    } = this.state;
    const { id, lectureId } = this.props.match.params;
    console.log("lectureData:", lectureData);

    if (lectureData)
      lectureData["pictureQuizSections"].map((e) => {
        console.log(e, "pictureQuizSections");
      });
    return (
      <Skeleton loading={loading || pending} active>
        <>
          <PageHeader
            title={
              <Title level={2} style={{ marginBottom: 0 }}>
                수업 내용 미리보기
              </Title>
            }
            extra={
              lectureData && [
                //lectureData.workbookFiles && lectureData.workbookFiles.length > 0 &&
                //<Dropdown
                //  key="0"
                //  overlay={
                //    <Menu>
                //      {lectureData.workbookFiles &&
                //        lectureData.workbookFiles.map(file => {
                //          const fileName = file.title;
                //          const link = file.content?.signedUrl;
                //
                //          return (
                //            <Menu.Item>
                //              <a
                //                target="_blank"
                //                rel="noopener noreferrer"
                //                href={link}
                //              >
                //                {fileName}
                //                <Icon
                //                  style={{ float: "right" }}
                //                  type="download"
                //                />
                //              </a>
                //            </Menu.Item>
                //          );
                //        })}
                //    </Menu>
                //  }
                //>
                //  <Button style={{ minWidth: 150, marginRight: 16 }}>
                //    <Icon type="file" />
                //    워크북
                //    <Icon type="down" />
                //  </Button>
                //</Dropdown>,
                // <Dropdown
                //   key="1"
                //   overlay={
                //     <Menu>
                //       {lectureData.quetureGroup  &&
                //         lectureData.quetureGroup.map(file => {
                //           if(file.quetureData) {
                //             const fileName = file.quetureData.academyQuetureTeachingGuide ? file.quetureData.academyQuetureTeachingGuide.name : "";
                //             const link = file.quetureData.academyQuetureTeachingGuide ? file.quetureData.academyQuetureTeachingGuide.signedUrl : "";
                //             if(link != "" || fileName != "") {
                //             return (
                //               <Menu.Item>
                //                 <a
                //                   target="_blank"
                //                   rel="noopener noreferrer"
                //                   href={link}
                //                 >
                //                   {fileName}
                //                   <Icon
                //                     style={{ float: "right" }}
                //                     type="download"
                //                   />
                //                 </a>
                //               </Menu.Item>
                //             );
                //           }}
                //         })}
                //     </Menu>
                //   }
                // >
                //   <Button style={{ minWidth: 150 }}>
                //     <Icon type="file" />
                //     수업계획안
                //     <Icon type="down" />
                //   </Button>
                // </Dropdown>,
                <Dropdown
                  key="4"
                  overlay={
                    <Menu>
                      {this.state.lectureData["pictureQuizSections"] &&
                        this.state.lectureData["pictureQuizSections"].map(
                          (file) => {
                            console.log(file, "file");
                            if (file.content) {
                              const fileName = file.content.name;
                              const link = file.content.signedUrl;
                              return (
                                <Menu.Item>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={link}
                                  >
                                    {fileName}
                                    <Icon
                                      style={{ float: "right" }}
                                      type="download"
                                    />
                                  </a>
                                </Menu.Item>
                              );
                            }
                          }
                        )}
                    </Menu>
                  }
                >
                  <Button style={{ minWidth: 150 }}>
                    <Icon type="file" />
                    그림퀴즈
                    <Icon type="down" />
                  </Button>
                </Dropdown>,
                <Dropdown
                  key="2"
                  overlay={
                    <Menu>
                      {lectureData.quetureGroup &&
                        lectureData.quetureGroup.map((file) => {
                          console.log(file);
                          if (file.quetureData) {
                            const fileName = file.quetureData
                              .academyPictureDiary
                              ? file.quetureData.academyPictureDiary.name
                              : "";
                            const link = file.quetureData.academyPictureDiary
                              ? file.quetureData.academyPictureDiary.signedUrl
                              : "";

                            if (link != "" || fileName != "") {
                              return (
                                <Menu.Item>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={link}
                                  >
                                    {fileName}
                                    <Icon
                                      style={{ float: "right" }}
                                      type="download"
                                    />
                                  </a>
                                </Menu.Item>
                              );
                            }
                          }
                        })}
                    </Menu>
                  }
                >
                  <Button style={{ minWidth: 150 }}>
                    <Icon type="file" />
                    수학그림일기
                    <Icon type="down" />
                  </Button>
                </Dropdown>,
                <Dropdown
                  key="3"
                  overlay={
                    <Menu>
                      {lectureData.workBooks &&
                        lectureData.workBooks.map((file) => {
                          const fileName = file.name;
                          const link = file.signedUrl;

                          return (
                            <Menu.Item>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={link}
                              >
                                {fileName}
                                <Icon
                                  style={{ float: "right" }}
                                  type="download"
                                />
                              </a>
                            </Menu.Item>
                          );
                        })}
                    </Menu>
                  }
                >
                  <Button style={{ minWidth: 150 }}>
                    <Icon type="file" />
                    워크북 문제집
                    <Icon type="down" />
                  </Button>
                </Dropdown>,
                <Dropdown
                  key="5"
                  overlay={
                    <Menu>
                      {lectureData.solvingBooks &&
                        lectureData.solvingBooks.map((file) => {
                          const fileName = file.name;
                          const link = file.signedUrl;

                          return (
                            <Menu.Item>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={link}
                              >
                                {fileName}
                                <Icon
                                  style={{ float: "right" }}
                                  type="download"
                                />
                              </a>
                            </Menu.Item>
                          );
                        })}
                    </Menu>
                  }
                >
                  <Button style={{ minWidth: 150 }}>
                    <Icon type="file" />
                    워크북 해설집
                    <Icon type="down" />
                  </Button>
                </Dropdown>,
                lectureData.activityFiles && (
                  <Dropdown
                    key="6"
                    overlay={
                      <Menu>
                        {lectureData.activityFiles &&
                          lectureData.activityFiles.map((activity) => {
                            return (
                              <>
                                {activity.content?.map((file) => {
                                  const fileName = file.name;
                                  const link = file.signedUrl;
                                  return (
                                    <Menu.Item>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={link}
                                      >
                                        {fileName}
                                        <Icon
                                          style={{ float: "right" }}
                                          type="download"
                                        />
                                      </a>
                                    </Menu.Item>
                                  );
                                })}
                              </>
                            );
                          })}
                      </Menu>
                    }
                  >
                    <Button style={{ minWidth: 150 }}>
                      <Icon type="file" />
                      활동 문서
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                ),
                <a href={"../lecture-workbook/" + lectureId}>
                  <Button>
                    워크북<sup>Beta</sup>
                  </Button>
                </a>,

                // <a href={"../lecture-solvingbook/" + lectureId}>
                <a
                  href={
                    "../lecture-workbook/" + lectureId + "?viewType=solvingbook"
                  }
                >
                  <Button>
                    해설집<sup>Beta</sup>
                  </Button>
                </a>,

                // <Button key="1" type="primary">
                //   수업 시뮬레이션
                //   <Icon type="play-circle" />
                // </Button>,
              ]
            }
          />
          {lectureData && (
            <Tabs
              activeKey={mainTab}
              onChange={this.changeMainTab}
              tabBarExtraContent={[]}
            >
              {lectureData.quetureGroup.map((lecture, index) => (
                <Tabs.TabPane
                  tab={
                    <>
                      <Typography.Text>
                        {lecture?.quetureData?.title + "(깨처)"}
                      </Typography.Text>
                      <SectionStateTag state={lecture["QUETURE"].state} />
                    </>
                  }
                  key={index}
                >
                  <Row gutter={24} type="flex">
                    <Col span={6} style={{ background: "#fff" }}>
                      <List itemLayout="vertical">
                        <List.Item>
                          {lecture.quetureData &&
                            lecture.quetureData.themes &&
                            lecture.quetureData.themes[0] && (
                              <List.Item.Meta
                                avatar={<Icon type="book" />}
                                title={
                                  <Text style={{ color: "#397DCE" }}>
                                    <Icon
                                      type="book"
                                      theme="twoTone"
                                      style={{ marginRight: 8 }}
                                    />
                                    테마명
                                  </Text>
                                }
                                description={
                                  <Text style={{ marginLeft: 24 }}>
                                    {lecture.quetureData &&
                                      lecture.quetureData.themes[0].title}
                                  </Text>
                                }
                              />
                            )}
                          <List.Item.Meta
                            avatar={<Icon type="pushpin" />}
                            title={
                              <Text style={{ color: "#397DCE" }}>
                                <Icon
                                  type="pushpin"
                                  theme="twoTone"
                                  style={{ marginRight: 8 }}
                                />
                                관련된 개념
                              </Text>
                            }
                            description={
                              <Text style={{ marginLeft: 24 }}>
                                {lecture.quetureData &&
                                  lecture.quetureData.conceptTags.map(
                                    (tag, index) => <Tag key={index}>{tag}</Tag>
                                  )}
                              </Text>
                            }
                          />
                          {/*// <List.Item.Meta*/}
                          {/*//   avatar={<Icon type="flag" />}*/}
                          {/*//   title={*/}
                          {/*//     <Text style={{ color: "#397DCE" }}>*/}
                          {/*//       <Icon*/}
                          {/*//         type="flag"*/}
                          {/*//         theme="twoTone"*/}
                          {/*//         style={{ marginRight: 8 }}*/}
                          {/*//       />*/}
                          {/*//       깨처포인트*/}
                          {/*//     </Text>*/}
                          {/*//   }*/}
                          {/*//   description={*/}
                          {/*//     <Text style={{ marginLeft: 24 }}>*/}
                          {/*//       {lecture.quetureData &&*/}
                          {/*//         lecture.quetureData.point}*/}
                          {/*//     </Text>*/}
                          {/*//   }*/}
                          {/*// />*/}
                          <List.Item.Meta
                            className={"list__content"}
                            avatar={<Icon type="flag" />}
                            title={
                              <Text style={{ color: "#397DCE" }}>
                                <Icon
                                  type="flag"
                                  theme="twoTone"
                                  style={{ marginRight: 8 }}
                                />
                                깨처 교안
                              </Text>
                            }
                            description={
                              lecture.quetureData &&
                              lecture.quetureData.quetureCampusNote ? (
                                <div className={"noDrag"}>
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        guidePopup: !this.state.guidePopup,
                                      });
                                    }}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    교안 보기
                                  </Button>
                                  {guidePopup && (
                                    <div
                                      style={{
                                        overflowY: "scroll",
                                        height: "600px",
                                      }}
                                    >
                                      <div style={{ color: "#000000" }}>
                                        <h1>깨처 포인트</h1>
                                        <div>
                                          {formatImgText(
                                            lecture.quetureData
                                              .quetureCampusNote.checkPoint,
                                            null,
                                            lecture.quetureData.quetureCampusNote.pictures.map(
                                              (e) => {
                                                return e.signedUrl;
                                              }
                                            )
                                          )}
                                        </div>
                                        <h1>주의 사항</h1>
                                        <div>
                                          {formatImgText(
                                            lecture.quetureData
                                              .quetureCampusNote.precautions,
                                            null,
                                            lecture.quetureData.quetureCampusNote.pictures.map(
                                              (e) => {
                                                return e.signedUrl;
                                              }
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                "교안 미등록"
                              )
                            }
                          />
                        </List.Item>
                      </List>
                    </Col>
                    <Col
                      span={18}
                      style={{
                        background: "#fff",
                        borderLeft: "24px solid #f0f2f5",
                      }}
                    >
                      <Tabs
                        size="large"
                        activeKey={subTab}
                        onChange={this.changeSubTab}
                        tabBarStyle={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Tabs.TabPane tab={"깨처영상"} key={0}>
                          {lecture.quetureData &&
                            lecture.quetureData?.contents?.items?.main &&
                            +subTab === 0 && (
                              <QueturePlayer
                                apiHost={getHost()}
                                userId={this.props.user.userId}
                                content={{
                                  data: lecture.quetureData,
                                  type: "main",
                                }}
                                resolution="auto"
                                style={{ outline: "none", width: "100%" }}
                              />
                            )}
                          {lecture.quetureData &&
                            !lecture.quetureData?.contents?.items?.main && (
                              <div style={{ textAlign: "center" }}>
                                영상 없음
                              </div>
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"문제보기"} key={1}>
                          <PreviewQuizList
                            list={lecture["QUIZ"] && lecture["QUIZ"].quiz}
                          />
                        </Tabs.TabPane>
                        {/* 
                        <Tabs.TabPane tab={"반짝깨처"} key={2}>
                          {lecture.quetureData && +subTab === 2 && (
                            <QueturePlayer
                              apiHost={getHost()}
                              userId={this.props.user.userId}
                              content={{
                                data: lecture.quetureData,
                                type: "summary"
                              }}
                              resolution="auto"
                              style={{ outline: "none", width: "100%" }}
                            />
                          )}
                        </Tabs.TabPane>
                        */}
                      </Tabs>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              ))}
              {lectureData.kidsTeachingGuide && (
                <Tabs.TabPane
                  tab={`${lectureData.kidsTeachingGuide.title}(키즈교안)`}
                  key={
                    lectureData.quetureGroup &&
                    lectureData.quetureGroup.length > 0
                      ? "teachingGuide"
                      : 0
                  }
                >
                  <Row gutter={24} type={"flex"}>
                    <Col span={6} style={{ background: "#fff" }}>
                      <List itemLayout={"vertical"}>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Icon type={"book"} />}
                            title={
                              <Text style={{ color: "#397DCE" }}>
                                <Icon
                                  type={"book"}
                                  theme={"twoTone"}
                                  style={{ marginLeft: 8 }}
                                />
                                학습목표
                              </Text>
                            }
                            description={
                              <Text
                                style={{
                                  display: "block",
                                  marginLeft: 24,
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {lectureData.kidsTeachingGuide.aimLearning
                                  ? lectureData.kidsTeachingGuide.aimLearning
                                  : ""}
                              </Text>
                            }
                          ></List.Item.Meta>
                          <List.Item.Meta
                            avatar={<Icon type={"book"} />}
                            title={
                              <Text style={{ color: "#397DCE" }}>
                                <Icon
                                  type={"book"}
                                  theme={"twoTone"}
                                  style={{
                                    marginLeft: 8,
                                  }}
                                />
                                퀴즈 포인트
                              </Text>
                            }
                            description={
                              <Text
                                style={{
                                  display: "block",
                                  marginLeft: 24,
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {lectureData.kidsTeachingGuide.quizPoint
                                  ? lectureData.kidsTeachingGuide.quizPoint
                                  : ""}
                              </Text>
                            }
                          ></List.Item.Meta>
                          <List.Item.Meta
                            avatar={<Icon type={"book"} />}
                            title={
                              <Text style={{ color: "#397DCE" }}>
                                <Icon
                                  type={"book"}
                                  theme={"twoTone"}
                                  style={{ marginLeft: 8 }}
                                />
                                활동 포인트
                              </Text>
                            }
                            description={
                              <Text
                                style={{
                                  display: "block",
                                  marginLeft: 24,
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {lectureData.kidsTeachingGuide.activePoint
                                  ? lectureData.kidsTeachingGuide.activePoint
                                  : ""}
                              </Text>
                            }
                          ></List.Item.Meta>
                        </List.Item>
                      </List>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              )}
              {lectureData.quebonSongSection &&
                lectureData.quebonSongSection.length > 0 && (
                  <Tabs.TabPane
                    tab={
                      lectureData.quebonSongSection[0].kidsQuebonSongTitle +
                      "(깨봉송)"
                    }
                    key={"quebonSong"}
                    // key={
                    //   (lectureData.quetureGroup &&
                    //     lectureData.quetureGroup.length > 0) ||
                    //   lectureData.kidsTeachingGuide
                    //     ? "quebonSong"
                    //     : 0
                    // }
                  >
                    <Row gutter={24} type={"flex"}>
                      <Col span={6} style={{ background: "#fff" }}>
                        <List itemLayout={"vertical"}>
                          <List.Item>
                            <List.Item.Meta
                              avatar={<Icon type={"book"} />}
                              title={
                                <Text style={{ color: "#397DCE" }}>
                                  <Icon
                                    type={"book"}
                                    theme={"twoTone"}
                                    style={{ marginLeft: 8 }}
                                  />
                                  깨봉송 이름
                                </Text>
                              }
                              description={
                                <Text style={{ marginLeft: 24 }}>
                                  {
                                    lectureData.quebonSongSection[0]
                                      .kidsQuebonSongTitle
                                  }
                                </Text>
                              }
                            ></List.Item.Meta>
                            <List.Item.Meta
                              avatar={<Icon type="pushpin" />}
                              title={
                                <Text style={{ color: "#397DCE" }}>
                                  <Icon
                                    type="pushpin"
                                    theme="twoTone"
                                    style={{ marginRight: 8 }}
                                  />
                                  포인트
                                </Text>
                              }
                              description={
                                <Text style={{ marginLeft: 24 }}>
                                  {
                                    lectureData.quebonSongSection[0]
                                      .kidsQuebonSongPoint
                                  }
                                </Text>
                              }
                            />
                          </List.Item>
                        </List>
                      </Col>
                      <Col
                        span={18}
                        style={{
                          borderLeft: "24px solid #f0f2f5",
                          background: "#fff",
                        }}
                      >
                        <Tabs
                          size={"large"}
                          activeKey={subTab}
                          onChange={this.changeSubTab}
                          tabBarStyle={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          key={0}
                        >
                          <Tabs.TabPane tab={"깨봉송"} key={0}>
                            <QuebonSongDetail
                              id={
                                lectureData.quebonSongSection[0]
                                  .kidsQuebonSongId
                              }
                            />
                          </Tabs.TabPane>
                        </Tabs>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                )}
              {lectureData.storytellingSections &&
                lectureData.storytellingSections.length > 0 &&
                lectureData.storytellingSections.map((storytelling, index) => (
                  <Tabs.TabPane
                    tab={storytelling.storytellingTitle + "(스토리텔링)"}
                    key={`storytelling-${index}`}
                    // key={
                    //   (lectureData.quetureGroup &&
                    //     lectureData.quetureGroup.length > 0) ||
                    //   lectureData.kidsTeachingGuide
                    //     ? "storytelling"
                    //     : 0
                    // }
                  >
                    <Row gutter={24} type={"flex"}>
                      <Col span={6} style={{ background: "#fff" }}>
                        <List itemLayout={"vertical"}>
                          <List.Item>
                            <List.Item.Meta
                              avatar={<Icon type={"book"} />}
                              title={
                                <Text style={{ color: "#397DCE" }}>
                                  <Icon
                                    type={"book"}
                                    theme={"twoTone"}
                                    style={{ marginLeft: 8 }}
                                  />
                                  스토링텔링 이름
                                </Text>
                              }
                              description={
                                <Text style={{ marginLeft: 24 }}>
                                  {storytelling.storytellingTitle}
                                </Text>
                              }
                            ></List.Item.Meta>
                          </List.Item>
                        </List>
                      </Col>
                      <Col
                        span={18}
                        style={{
                          borderLeft: "24px solid #f0f2f5",
                          background: "#fff",
                        }}
                      >
                        <Tabs
                          size={"large"}
                          activeKey={subTab}
                          onChange={this.changeSubTab}
                          tabBarStyle={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          key={0}
                        >
                          <Tabs.TabPane tab={"스토리텔링"} key={0}>
                            <PreviewStorytelling
                              storytellingsId={storytelling.storytellingId}
                            />
                          </Tabs.TabPane>
                        </Tabs>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                ))}
              {lectureData.challengeSections[0] && (
                <Tabs.TabPane tab={"도전문제"} key="challenge">
                  <Row gutter={24} type="flex">
                    <Col span={6} style={{ background: "#fff" }}>
                      <List itemLayout="vertical">
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Icon type="book" />}
                            title={
                              <Text style={{ color: "#397DCE" }}>
                                <Icon
                                  type="book"
                                  theme="twoTone"
                                  style={{ marginRight: 8 }}
                                />
                                포인트
                              </Text>
                            }
                            description={
                              <Text style={{ marginLeft: 24 }}>
                                오늘 배운 깨처 내용을 떠올리면서 새로운 문제
                                풀기에 도전해봐요!
                              </Text>
                            }
                          />
                        </List.Item>
                      </List>
                    </Col>
                    <Col
                      span={18}
                      style={{
                        background: "#fff",
                        borderLeft: "24px solid #f0f2f5",
                      }}
                    >
                      <Tabs
                        size="large"
                        activeKey={subTab}
                        onChange={this.changeSubTab}
                        tabBarStyle={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {lectureData.challengeSections.map((e, index) => (
                          <Tabs.TabPane
                            tab={`${index + 1}번째 문제`}
                            key={index}
                          >
                            <PreviewQuizList
                              list={e && e.quiz}
                              isChallenge={true}
                            />
                          </Tabs.TabPane>
                        ))}
                      </Tabs>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              )}
              {lectureData.endQuizSections[0] && (
                <Tabs.TabPane tab={"정리퀴즈"} key="endQuiz">
                  <Row gutter={24} type="flex">
                    <Col span={6} style={{ background: "#fff" }}>
                      <List itemLayout="vertical">
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Icon type="book" />}
                            title={
                              <Text style={{ color: "#397DCE" }}>
                                <Icon
                                  type="book"
                                  theme="twoTone"
                                  style={{ marginRight: 8 }}
                                />
                                포인트
                              </Text>
                            }
                            description={
                              <Text style={{ marginLeft: 24 }}>
                                오늘 배운 수학 개념을 퀴즈 풀기로 간단히 정리해
                                보아요.
                              </Text>
                            }
                          />
                        </List.Item>
                      </List>
                    </Col>
                    <Col
                      span={18}
                      style={{
                        background: "#fff",
                        borderLeft: "24px solid #f0f2f5",
                      }}
                    >
                      <Tabs
                        size="large"
                        activeKey={subTab}
                        onChange={this.changeSubTab}
                        tabBarStyle={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {lectureData.endQuizSections.map((e, index) => (
                          <Tabs.TabPane
                            tab={`${index + 1}번째 문제`}
                            key={index}
                          >
                            <PreviewQuizList
                              list={e && e.quiz}
                              isChallenge={true}
                            />
                          </Tabs.TabPane>
                        ))}
                      </Tabs>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              )}
              {lectureData.gameSections &&
                lectureData.gameSections.map((game, index) => (
                  <Tabs.TabPane
                    tab={
                      <>
                        <Typography.Text>
                          {`${vanila[game.gameName][locale].name}(게임)`}
                        </Typography.Text>
                        <SectionStateTag state={game?.state} />
                      </>
                    }
                    key={"game" + index}
                  >
                    <Row gutter={24} type="flex">
                      <Col span={6} style={{ background: "#fff" }}>
                        <List itemLayout="vertical">
                          <List.Item>
                            <List.Item.Meta
                              avatar={<Icon type="book" />}
                              title={
                                <Text style={{ color: "#397DCE" }}>
                                  <Icon
                                    type="book"
                                    theme="twoTone"
                                    style={{ marginRight: 8 }}
                                  />
                                  게임 이름
                                </Text>
                              }
                              description={
                                <Text style={{ marginLeft: 24 }}>
                                  {vanila[game.gameName][locale].name}
                                </Text>
                              }
                            />
                            <List.Item.Meta
                              avatar={<Icon type="pushpin" />}
                              title={
                                <Text style={{ color: "#397DCE" }}>
                                  <Icon
                                    type="pushpin"
                                    theme="twoTone"
                                    style={{ marginRight: 8 }}
                                  />
                                  학습 키워드
                                </Text>
                              }
                              description={
                                <Text style={{ marginLeft: 24 }}>
                                  {gameType[locale][vanila[game.gameName].type]}
                                </Text>
                              }
                            />
                            <List.Item.Meta
                              avatar={<Icon type="flag" />}
                              title={
                                <Text style={{ color: "#397DCE" }}>
                                  <Icon
                                    type="flag"
                                    theme="twoTone"
                                    style={{ marginRight: 8 }}
                                  />
                                  게임 설명
                                </Text>
                              }
                              description={
                                <Text style={{ marginLeft: 24 }}>
                                  {vanila[game.gameName][locale].desc}
                                </Text>
                              }
                            />
                          </List.Item>
                        </List>
                      </Col>
                      <Col
                        span={18}
                        style={{
                          background: "#fff",
                          borderLeft: "24px solid #f0f2f5",
                        }}
                      >
                        <div className="admin-game-preview">
                          <GameExplain game={game.gameName} />
                          <Button
                            onClick={() =>
                              this.handleVanilaStart(game.gameName)
                            }
                            style={{
                              width: 300,
                              height: 80,
                              background: "#333A53",
                              borderRadius: 4,
                              marginTop: 40,
                              fontWeight: "500",
                              fontSize: 30,
                              color: "#fff",
                            }}
                          >
                            게임시작
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                ))}
              {lectureData.activitySections &&
                lectureData.activitySections.map((activity, index) => (
                  <Tabs.TabPane
                    tab={
                      <>
                        <Typography.Text>
                          {`${activity.title}(활동)`}
                        </Typography.Text>
                        <SectionStateTag state={activity?.state} />
                      </>
                    }
                    key={"activity" + index}
                  >
                    <Row gutter={24} type="flex">
                      <Col span={6} style={{ background: "#fff" }}>
                        <List itemLayout="vertical">
                          <List.Item>
                            <List.Item.Meta
                              avatar={<Icon type="book" />}
                              title={
                                <Text style={{ color: "#397DCE" }}>
                                  <Icon
                                    type="book"
                                    theme="twoTone"
                                    style={{ marginRight: 8 }}
                                  />
                                  활동 이름
                                </Text>
                              }
                              description={
                                <Text style={{ marginLeft: 24 }}>
                                  {activity.title}
                                </Text>
                              }
                            />
                            <List.Item.Meta
                              avatar={<Icon type="pushpin" />}
                              title={
                                <Text style={{ color: "#397DCE" }}>
                                  <Icon
                                    type="pushpin"
                                    theme="twoTone"
                                    style={{ marginRight: 8 }}
                                  />
                                  준비물
                                </Text>
                              }
                              description={
                                <Text style={{ marginLeft: 24 }}>
                                  {activity.supplies}
                                </Text>
                              }
                            />
                            <List.Item.Meta
                              avatar={<Icon type="flag" />}
                              title={
                                <Text style={{ color: "#397DCE" }}>
                                  <Icon
                                    type="flag"
                                    theme="twoTone"
                                    style={{ marginRight: 8 }}
                                  />
                                  활동 내용
                                </Text>
                              }
                              description={
                                <Text style={{ marginLeft: 24 }}>
                                  {activity.text}
                                </Text>
                              }
                            />
                          </List.Item>
                        </List>
                      </Col>
                      <Col
                        span={18}
                        style={{
                          background: "#fff",
                          borderLeft: "24px solid #f0f2f5",
                        }}
                      >
                        <Tabs
                          size={"large"}
                          activeKey={subTabActivity}
                          onChange={this.changeSubTabActivity}
                          tabBarStyle={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Tabs.TabPane tab={"활동보기"} key={0}>
                            <Activity data={activity} />
                          </Tabs.TabPane>
                          {lectureData.activityFiles[0] && (
                            <Tabs.TabPane tab={"활동영상"} key={1}>
                              <video
                                id={"mainVideo"}
                                autoPlay
                                loop
                                controls
                                muted
                                style={{
                                  maxWidth: "1250px",
                                  maxHeight: "550px",
                                  height: "auto",
                                  display: "flex",
                                  alignContent: "center",
                                }}
                              >
                                <source
                                  src={
                                    lectureData.activityFiles[0].activityVideo
                                      .signedUrl
                                  }
                                />
                              </video>
                            </Tabs.TabPane>
                          )}
                        </Tabs>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                ))}
            </Tabs>
          )}
        </>
        {this.state.game != null ? (
          <Popup type="game-wrap" className="game-popup ht" isOpen={true}>
            {this.state.game}
          </Popup>
        ) : null}
      </Skeleton>
    );
  }
}

export default connect(
  ({ auth, classroom }) => ({
    pending: auth.pending || classroom.pending,
    user: auth.user,
    logged: auth.logged,
    classroomList: classroom.classroomList,
  }),
  (dispatch) => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
  })
)(LecturePreviewPage);
