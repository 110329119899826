import React, { Component } from "react";
import { Modal, Input, Typography, Row, Col, DatePicker, TimePicker, Button } from "antd";
import moment from 'moment';

const { Title, Text } = Typography;
const { TextArea } = Input;

class UpdateClassroomLectureTimePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      oldDateTime: '',
      dateTime: '',
    };
  }

  componentDidMount() {
    const { dateTime, onChange } = this.props;

    console.log("dateTime: ", dateTime);
    this.setState({
       oldDateTime: dateTime,
       dateTime: dateTime,
    });
    onChange && onChange(dateTime)
  }

  render() {
    const { onOk } = this.props;
    const { oldDateTime, dateTime } = this.state;

    let m = moment(dateTime, 'YYYY-MM-DD hh:mm:ss');

    return (
      <Modal {...this.props} onOk={() => onOk && onOk(m.valueOf())}>

        <Row style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <Col><strong>기존값</strong> &nbsp; </Col>
          <Col>{oldDateTime}</Col>
        </Row>

        <Row style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <Col><strong>변경값</strong> &nbsp; </Col>
          <Col>
            <DatePicker
              value={m}
              onChange={(t, dateString) => {
                t =  (t)? t : moment(oldDateTime, 'YYYY-MM-DD hh:mm:ss');  // reset on invalid
                console.log("DatePicker date:", t.toString(), " dateString:", dateString);
                this.setState({
                  dateTime: t
                })
              }} />
            <TimePicker
              format="HH:mm"
              minuteStep={5}
              value={m}
              onChange={(t, timeString) => {
                t =  (t)? t : moment(oldDateTime, 'YYYY-MM-DD hh:mm:ss');  // reset on invalid
                console.log("TimePicker time:", t.toString(), " timeString:", timeString)
                this.setState({
                  dateTime: t
                })
              }} />
          </Col>
        </Row>

      </Modal>
    );
  }
}

export default UpdateClassroomLectureTimePopup;
