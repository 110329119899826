import React, { Component } from "react";
import { Modal, List, Avatar } from "antd";
import { getProfileUrl } from "../../../utils/utils";

class PreviewMsgPopup extends Component {
  render() {
    const { data } = this.props;
    console.log("data: ", data);
    return (
      <Modal {...this.props}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item key={item.studentId}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={
                      item.profileImage &&
                      getProfileUrl(item.profileImage.contentId)
                    }
                  />
                }
                title={item.name}
                description={
                  <pre style={{ maxWidth: 350, whiteSpace: "pre-wrap" }}>
                    {item.template}
                  </pre>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    );
  }
}

export default PreviewMsgPopup;
