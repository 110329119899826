import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Image, SelectableText } from './modules';

class MarkSentenceQuiz extends Component {

    state = {
        answers: null
    }

    init = (data) => {
        const { quiz } = data;
        const { problem } = quiz;
        const { answers } = this.state;

        const answerCount = problem.split(/[.!?\r\n]+/).filter(s => s.trim() !== '').length;
        this.setState({
            answers: new Array(answerCount).fill(false)
        });
    }

    componentWillMount() {
        const { data } = this.props;

        if (data != null) {
            this.init(data);
        }
    }

    componentWillReceiveProps(props) {
        const { data: old } = this.props;
        const { data } = props;

        if (data != null && (old == null || old.quizId !== data.quizId || old.timestamp !== data.timestamp)) {
            this.init(data);
        }
    }

    onToggle = (i, value) => {
        const { onAnswerUpdated } = this.props;
        this.setState(update(this.state, {
            answers: {
                [i]: {
                    $set: value
                }
            }
        }), () => {
            const conv = [];
            this.state.answers.forEach((on, i) => { if (on) conv.push(i + "") })
            onAnswerUpdated(conv);
        })
    }

    render() {
        const { data, play } = this.props;
        const { quizId } = data;
        const { title, image, anim, problem, textsOnImage } = data.quiz;

        const texts = problem.split(/[.!?\r\n]+/).filter(s => s.trim() !== '').map((s, i) => (
            <SelectableText key={quizId + '_sel_' + i} index={i} value={s} selected={this.state.answers[i]} onToggle={this.onToggle} />
        ))

        return (
            <div className="fillBlank">
                <div className="quizTopArea">
                    <Image image={image} frames={anim} play={play} texts={textsOnImage} />
                    <div className="problemTextArea">
                        {texts}
                    </div>
                </div>
            </div>
        )
    }

}

MarkSentenceQuiz.propTypes = {
    data: PropTypes.object,
    onAnswerUpdated: PropTypes.func
}

export default MarkSentenceQuiz;