import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import "./_endQuiz.scss"
import Axios from "../../../utils/request";
import EndQuizSection from "./EndQuizSection";
import Psend from "../etcpage/Psend";


class EndQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      connected: false,
    };
  }

  solveQuiz = () => {
    const _data = {
      type: "solveQuiz",
      value: ""
    };
    // new BroadcastChannel("QUIZ_CHANNEL").postMessage(_data);
    if (this.props.onSendMessage) {
      this.props.onSendMessage(_data);
    }
  };

  solve = (quizId, answers, vars) => {
    return Axios.post(`/quiz/v2/quizzes/${quizId}/solve`, {
      answers: answers,
      vars: vars,
    }).then((response) => {
      return response;
    });
  };

  render() {
    const { data, curPage, viewType, classroomId, seq, lectureId, section, sectionIndex } = this.props;
    const { completed } = this.state;

    if (completed) {
      return <Psend/>;
    }

    return (
      <div className={"quetureranking-layout"}>
        {curPage === 0 && (
          <>
            <div className="small-title">
              <FormattedMessage id={"classroom.endQuizTitle"}/> {section.slice(0,sectionIndex+1).filter(e=> e.type === "ENDQUIZ").length}
            </div>
          </>)}

        {curPage === 1 && (<EndQuizSection
          onSendMessage={this.props.onSendMessage}
          data={data}
          solveQuiz={this.solveQuiz}
          classroomId={classroomId}
          viewType={viewType}
          seq={seq}
          lectureId={ lectureId}
          onSolve={this.solve}
        />)}

      </div>
    );
  }
};

export default EndQuiz;
