import React from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const LoginTab = () => {
  function callback(key) {
    console.log(key);
  }

  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="관리지" key="1"></TabPane>
      <TabPane tab="학생" key="2"></TabPane>
    </Tabs>
  );
};

export default LoginTab;
