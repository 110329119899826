import React, { Component } from "react";
import { formatText } from "../utils/Formatter";
import classNames from "classnames";
import Image from "./Image";

class MultiChoiceAnswer extends Component {

  checkSelectedAnswer = (index, correctAnswers = []) => {
    return correctAnswers.includes(index + '');
  }


  render() {
    const { index, inlineIndex, isInline, choice, selected, onToggle, solvedData, showReallyTrue } = this.props;
    let correctAnswers = [];
    if (solvedData && solvedData.solved) {
      if (isInline) {
        correctAnswers = solvedData.correctAnswers[inlineIndex];
      } else {
        correctAnswers = solvedData.correctAnswers;
      }
    }

    // if (showReallyTrue) {
    //   console.log(index + 'correc anser: ', showReallyTrue && correctAnswers.includes((index + 1) + ''));
    // }

    console.log(correctAnswers,"correctAnswers")
    return (
      //내가 선택한것 is-select, 정답은 is-true, 내가 선택한것이 정답일경우 is-reallytrue,  내가 선택한 것이 오답일경우 is-false
      <li
        className={classNames(
          {
            'img': choice.image,
            'is-chk': selected,
            'is-true': selected && solvedData.solved && correctAnswers && correctAnswers.includes((index + 1) + ''),
            'is-false': selected && solvedData.solved && correctAnswers && !correctAnswers.includes((index + 1) + ''),
            'is-reallytrue': showReallyTrue && correctAnswers && correctAnswers.includes((index + 1) + ''),
            'none-event': solvedData && solvedData.solved,
          }
        )}
        onClick={onToggle}
      >
      <span className="num"></span>
      {
        choice.image &&
        <Image image={choice.image} />
      }
      <span className="text">
        {choice.answer &&
          formatText(choice.answer)
        }
      </span>
      </li>
    );
  }
}

export default MultiChoiceAnswer;
