import React, { useState, useEffect } from "react";
import Axios from "../../../utils/request";
import RankingList from "../../../components/Teacher/component/RankingList";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

const QuetureRanking = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [quetureData, setQuetureData] = useState(null);
  const { seq, quetureId, userId, students, groups } = props;
  const context = React.useContext(ConstantsContext);

  useEffect(() => {
    setLoading(true);
    Axios.get(`/content/v2/quetures/${quetureId}`)
      .then((response) => {
        setLoading(false);
        setQuetureData(response);
        console.log("queture data: ", response);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [quetureId]);

  return (
    <>
      {quetureData && (
        <div className="quetureranking-layout" style={{ position: "relative" }}>
          {context.type === "campus" && (
            <>
              <div className="small-title">
                <FormattedMessage
                  id={
                    "classroom.quetureSeq" + (seq && seq > 6 ? "" : "-" + seq)
                  }
                  values={{ seq: seq }}
                />
              </div>
              <div className="big-title u-color-red">
                <span>
                  {groups && groups.length > 0 ? (
                    <FormattedMessage id="classroom.teamPlay" />
                  ) : (
                    <FormattedMessage id="classroom.soloPlay" />
                  )}
                </span>
                {quetureData.title}
              </div>
            </>
          )}
          <RankingList {...props} />
        </div>
      )}
    </>
  );
};

export default QuetureRanking;
