import React, { Component } from "react";
import { Modal, Typography, Select, Row, Col, Table, Input } from "antd";
import { filter } from "lodash";
import { ConstantsContext } from "utils/ConstantsContext";

class ChangeStudentAccountId extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      changeVal : null
    };
  }

  handleOK() {
    const { onSelect } = this.props;
    onSelect(this.state.changeVal);
  }

  render() {
    return (
      <Modal {...this.props} onOk={() => this.handleOK()}>
        <div>
          <Typography.Text strong>
            {`${this.props.studentData.name} 학생의 아이디를 아래와 같이 변경하시겠습니까?`}
          </Typography.Text>
          <Input defaultValue={this.props.studentData.account} onChange={(v) => {
            this.setState({ changeVal: v.target.value })
          }}></Input>
        </div>
      </Modal>
    );
  }
}
export default ChangeStudentAccountId;
