import React, { Component } from "react";
import { Quiz } from "components/Quiz";
import Axios from "../../../utils/request";
import { Skeleton } from "antd";
import Toast from "components/Toast/Toast";
import Psend from "../etcpage/Psend";
import update from "immutability-helper";
import HometrainingApis from "../../../utils/hometrainingApis";
import SimpleRankingList from "../../../components/Teacher/component/SimpleRankingList";
import RankingList from "../../../components/Teacher/component/RankingList";

class QuizSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      quizListData: null,
      solvedQuizData: null,
      currentQuiz: null,
      currentIndex: 0,
      completed: false,
      step: false,
      status: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quizList !== this.props.quizList) {
      this.init();
    }
  }

  init = () => {
    const { quizList, userId, onQuizList } = this.props;
    const _status = [];

    if (quizList && quizList.length > 0) {
      let _curIndex = 0;
      let _solvedQuizData = [];
      quizList.map((quiz) => {
        console.log(quiz.solvedMap[userId]);
        if (quiz.solvedMap[userId]) {
          _curIndex++;
          _status.push(quiz.solvedMap[userId].correct);
          _solvedQuizData.push(quiz.solvedMap[userId]);
        }
      });
      console.log("_curIndex", _curIndex);
      console.log("quizList", quizList);
      this.setState(
        {
          quizListData: quizList,
          status: _status,
          currentIndex: _curIndex < quizList.length ? _curIndex : 0,
          solvedQuizData: _solvedQuizData,
          step: _curIndex === quizList.length ? true : false,
          // completed: _curIndex === quizList.length ? true : false
        },
        () => {
          if (_curIndex === quizList.length) {
            this.load(quizList[0].quizId);
          } else {
            this.load(quizList[_curIndex].quizId);
          }
        }
      );
    }
  };

  handleAppendStatus = (correct) => {
    this.setState({
      status: update(this.state.status, {
        $push: [correct],
      }),
    });
  };

  // load = quizId => {
  //   this.setState({
  //     isLoading: true
  //   });

  //   Axios.get(`/quiz/v2/quizzes/${quizId}`).then(response => {
  //     this.setState({
  //       isLoading: false,
  //       currentQuiz: response
  //     });
  //   });
  // };

  load = (quizId, data = null, randomseed = null) => {
    const _randomSeed = this.props.randomSeed || randomseed;

    this.setState({
      isLoading: true,
    });
    // Axios.get(`/quiz/v2/quizzes/${quizId}`).then(response => {
    HometrainingApis.loadQuiz(quizId, data && data.vars, _randomSeed).then((response) => {
      this.setState({
        isLoading: false,
        currentQuiz: response.data,
      });
    });
  };

  handleFinish = () => {
    this.setState({
      completed: true,
    });
  };

  handleStep(index, data = null) {
    const { quizListData, solvedQuizData } = this.state;
    console.log(index);
    if (data) {
      this.setState({
        step: true,
        currentIndex: index,
        solvedQuizData: update(this.state.solvedQuizData, {
          $push: [data],
        }),
        status: update(this.state.status, {
          $push: [data.correct],
        }),
      });
      this.load(quizListData[index].quizId, data);
    } else {
      if (index === solvedQuizData.length) {
        console.log("back to current ");
        this.setState({
          step: false,
          currentIndex: index,
        });
        this.load(quizListData[index].quizId);
        return;
      }
      if (index === quizListData.length) {
        return;
      }

      this.setState({
        currentIndex: index,
        step: true,
      });
      this.load(quizListData[index].quizId, solvedQuizData[index]);
    }
  }

  handleAppendResult = (res, isLast) => {
    const { currentIndex, quizListData } = this.state;
    const _nextIndex = isLast ? currentIndex : currentIndex + 1;

    if (isLast) {
      console.log("isLast");
    }

    this.setState({
      step: isLast,
      currentIndex: _nextIndex,
      solvedQuizData: update(this.state.solvedQuizData, {
        $push: [res],
      }),
      status: update(this.state.status, {
        $push: [res.correct],
      }),
    });
    this.load(quizListData[_nextIndex].quizId);
  };

  render() {
    const { quizList, onSolve, userId, isTeamLeader } = this.props;
    const {
      isLoading,
      completed,
      currentIndex,
      currentQuiz,
      quizListData,
      solvedQuizData,
      step,
      status,
    } = this.state;
    console.log("step: ", step);
    console.log("status: ", status);
    console.log("currentIndex: ", currentIndex);
    console.log("currentQuiz: ", currentQuiz);
    console.log("quizListData: ", quizListData);
    console.log("solvedQuizData: ", solvedQuizData);
    let onFinish = null;
    if (quizListData) {
      const checkLength = quizListData.length - 1;
      if (!step && status.length === checkLength) {
        onFinish = this.handleFinish;
      }
    }

    if (completed) {
      return <Psend />;
    }

    return (
      <Skeleton loading={isLoading} active>
        <>
          {currentQuiz && (
            <Quiz
              data={currentQuiz}
              solver={onSolve}
              expectedCount={quizListData.length}
              status={status}
              onAppendResult={(result, isLast) => {
                this.handleAppendResult(result, isLast);
              }}
              // onNext={() => this.handleStep(currentIndex + 1)}
              // onPrev={() => this.handleStep(currentIndex - 1)}
              onStep={(index, data) => this.handleStep(index, data)}
              currentIndex={currentIndex}
              stepQuizData={step ? solvedQuizData[currentIndex] : null}
              step={step}
              quizStep={currentIndex < status.length ? currentIndex : null}
              onSolvedData={this.handleSolvedData}
              onFinish={onFinish}
              readOnly={!isTeamLeader}
            />
          )}
          {/* <RankingList isSimple {...this.props} /> */}
        </>
      </Skeleton>
    );
  }
}
export default QuizSection;
