import React from "react";
import "./_game.scss";
import GameData from "pages/game/GameData";
import { FormattedMessage } from "react-intl";
const vanila = GameData;
const locale = "ko";

const GameExplain = (props) => {
  const { user, game, classLocale } = props;
  let locale = classLocale || "ko";

  return (
    <div className="gameexplain-layout">
      <div className="small-title">
        <FormattedMessage id="classroom.todays-game" />
      </div>
      <div className="big-title u-color-deepyellow">
        {vanila[game][locale]["name"]}
      </div>
      <div className="page-content">
        <div className="img-wrap">
          <div className="img">
            <img src={vanila[game]["image"]} alt="" />
          </div>
        </div>
        <div className="content-text-wrap">
          <div className="page-content-title">
            <FormattedMessage id={"classroom.game-desc"} />
          </div>
          <div
            className="page-content-ment"
            dangerouslySetInnerHTML={{ __html: vanila[game][locale]["desc2"] }}
          />
        </div>
      </div>
    </div>
  );
};

export default GameExplain;
