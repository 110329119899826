import React from 'react';

const Test = () => {
  return (
    <div className="input-test">
      <input type="text" name="" id="" />
      <p>1</p>
      <p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p><p>1</p>
      <input type="text" name="" id="" />
    </div>
  );
};

export default Test;