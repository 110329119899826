import React from "react";
import { Dropdown, Avatar, Icon, Menu, Spin, message } from "antd";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

function AvatarDropdown({ user, onLogout }) {
  function onMenuClick(e) {
    const { key } = e;

    // message.success("Clicked Menu: " + key);
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {/* <Menu.Item key="profile">
        <Icon type="user" />
        <span>프로필</span>
      </Menu.Item>
      <Menu.Item key="settings">
        <Icon type="setting" />
        <span>권한설절</span>
      </Menu.Item> */}
      {/* <Menu.Divider /> */}
      <Menu.Item key="logout">
        <Link to="/logout">
          <Icon type="logout" />
          <span>로그아웃</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar size="small" className={styles.avatar} icon="user" />
        <span className={styles.name}>{`${user.name}`}</span>
      </span>
    </Dropdown>
  );
}

export default AvatarDropdown;
