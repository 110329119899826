import PropTypes from "prop-types";
import React, { Component } from "react";
import { Blank, Image } from "./modules";
import { formatProblem } from "./utils/Formatter";
import { getTextLength } from "utils/utils";
import { actionLog } from "utils/LogUtils";

class FillBlankQuiz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      answers: null,
      currIndex: null,
      problem: '',
    }
  }

  init = data => {
    const { stepQuizAnswers, solvedData, showReallyTrue } = this.props;

    const { quizId, quiz, timestamp } = data;
    const { problem } = quiz;

    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      const stepAnswersMap = [];
      stepQuizAnswers.forEach((a, i) => {
        stepAnswersMap.push({
          key: a.key,
          value: stepQuizAnswers[i],
          inputWidth: this.getInputWidth(stepQuizAnswers[i])
        });
      });
      this.setState({
        answers: stepAnswersMap
      }, () => { this.updateProblem(this.props, stepAnswersMap) });
    } else {
      let answers = [];
      const reg = /\{\{([0-9]+)?}}/gim;
      let r;
      while ((r = reg.exec(problem))) {
        answers.push({
          key: r[1],
          inputWidth: 44,
          value: ""
        });
      }

      console.log('updating problem', problem);
      this.setState({
        answers: answers,
        problem: '',
      }, () => {
        actionLog("quiz", "init", {
          quizId: quizId,
          type: quiz.type,
          answers: this.state.answers
        });
        this.updateProblem(this.props, answers);
      });
    }
  };

  componentDidMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.answers != this.props.answers) {
      this.updateProblem(this.props);
    }
    if (prevProps.showReallyTrue !== this.props.showReallyTrue) {
      this.updateProblem(this.props);
    }
    if (this.props.solved && !prevProps.solved) {
      this.updateProblem(this.props)
    }
    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      this.init(this.props.data)
    }
  }

  componentWillUnmount() {
    this.setState({
      answers: null,
      currIndex: null,
      problem: '',
    })
  }

  updateProblem = (props, answers) => {
    const { data, solvedData, showReallyTrue, additionalImages } = props;
    const { quizId, quiz, timestamp } = data;
    const { problem } = quiz;
    const _answers = answers || this.state.answers;
    console.log("업데 : " + this.props.answers[0])
    console.log("업데 : " + _answers)
    this.state.answers.map(e => {
      console.log(e)
    })

    if(this.props.answers.length != 0)
    _answers.map((val, idx) => {
      console.log(val)
      _answers[idx].value = this.props.answers[idx] ? this.props.answers[idx] : ""
    })


    var answerUpdated = false;
    this.setState({
      problem: formatProblem(problem, quizId + "_" + timestamp, (index, k) => {

        const blank = (
          <Blank
            key={quizId + "_" + timestamp + "_blnk_" + index}
            placeholder={k}
            solvedData={solvedData}
            showReallyTrue={showReallyTrue}
            // onChange={v => this.handleInput(index, v.target.value, k)}
            onChange={v => this.handleInput(index, v, k)}
            value={_answers.length >= index + 1 ? _answers[index].value : ''}
            onClick={() => this.setCurrIndex(index)}
            // onBlur={() => this.setCurrIndex(-1)}
            selectedIndex={this.state.currIndex}
            index={index}
            blankWidth={_answers.length >= index + 1 ? (_answers[index].inputWidth || 44) : 44}
          />
        );
        return blank;
      }, additionalImages)
    })
  }

  getInputWidth(value) {
    if (value && getTextLength(value) > 2) {
      return (getTextLength(value) + 2) * 12;
    } else {
      return 44;
    }
  }

  handleInput = (index, value, key) => {
    if (value === "clear") {
      value = "";
    }
    if (value === "close") {
      this.setState({
        currIndex: null
      });
      return;
    }
    const { onAnswerUpdated } = this.props;
    const { answers } = this.state;

    if (answers.length <= index) {
      const size = index - answers.length + 1;

      for (var i = 0; i < size; i++) {
        answers.push({
        })
      }
    }
    if (!answers[index].key) {
      answers[index].key = key;
    }

    answers[index].value = value + "";
    answers[index].inputWidth = this.getInputWidth(value);

    if (key !== "") {
      answers
        .filter(a => a.key === key)
        .forEach(a => {
          a.value = value;
          a.inputWidth = this.getInputWidth(a.value)
        });
    }
    this.setState(
      {
        answers: answers,
      },
      () => {
        const conv = answers.map(a => a.value.trim());
        onAnswerUpdated(conv);
        this.updateProblem(this.props, answers);
      }
    );
  };

  setCurrIndex = index => {
    this.setState({
      currIndex: index === this.state.currIndex ? null : index
    });
  };

  render() {
    return (
      <div className="quiz-content__answer t-answer-ts fill-blank">
        <div>
          {this.state.problem}
        </div>
      </div>
    );
  }
}

FillBlankQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
};

export default FillBlankQuiz;
