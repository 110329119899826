import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import {
  PageHeader,
  Typography,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Upload,
  Button,
  message,
  Avatar,
  Icon,
  Checkbox
} from "antd";
import moment from "moment";
import Axios from "../../../../utils/request";
import SearchAddressPopup from "../../components/Popup/SearchAddressPopup";
import { parseAxiosError } from "../../../../utils/APIUtils";
import StudentProfile from "../../components/StudentProfile";
import AccountApis from "../../../../utils/accountApis";
import CommonApis from "../../../../utils/commonApis";
import axios from "axios";
import { filter } from "lodash";
import { isEmail } from "../../../../utils/utils";
import { ConstantsContext } from "utils/ConstantsContext";

const { Title } = Typography;
class GuideAddPage extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      showAddressPopup: false,
      profileImageId: null,
      isLoading: false
    };
  }

  componentDidMount() {
    const { user, ClassroomActions } = this.props;

    if (user) {
      ClassroomActions.getClassroomList(user.orgId);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    console.log(this.props.form);
    const { profileImageId } = this.state;
    this.props.form.validateFields((err, values) => {
      console.log("Received values of form: ", values);

      console.log(values.classroomIds);
      if (!isEmail(values.account)) {
        message.warning("이메일을 입력해주세요");
      }

      if (!err) {
        const year = moment(values.birthday).year();
        let day = Number.parseInt(moment(values.birthday).date());
        let month = Number.parseInt(moment(values.birthday).month() + 1);
        day = day >= 10 ? day : "0" + day;
        month = month >= 10 ? month : "0" + month;

        const joinYear = moment(values.joinDate).year();
        const joinMonth = Number.parseInt(moment(values.joinDate).month() + 1);
        const joinDay = Number.parseInt(moment(values.joinDate).date());

        const data = {
          profileImageId: profileImageId,
          name: values.name,
          account: values.account,
          birthday: values.birthday
            ? {
                day: day,
                month: month,
                year: year
              }
            : null,
          mobile: values.mobile || null,
          joinDate: {
            day: joinDay >= 10 ? joinDay : "0" + joinDay,
            month: joinMonth >= 10 ? joinMonth : "0" + joinMonth,
            year: joinYear
          },
          address: {
            address1: values.address1,
            address2: values.address2,
            zipCode: values.zipcode
          },
          password:
            this.context.type === "campus"
              ? "" + year + month + day
              : "smcho12345!"
        };
        console.log("post data: ", data);
        this.setState(
          {
            isLoading: true
          },
          () => {
            this.createGuide(data, values.classroomIds);
          }
        );
      }
    });
  };

  createGuide = (data, classroomIds) => {
    AccountApis.addGuide(this.props.user.orgId, data)
      .then(res => {
        // form.resetFields();
        if (classroomIds) {
          CommonApis.setMultipleClassrooms(
            this.props.user.orgId,
            res.guideId,
            classroomIds,
            null
          ).then(
            axios.spread((...responses) => {
              console.log("set multiple classrooms res: ", responses);
              this.onDone();
            })
          );
        } else {
          this.onDone();
        }
      })
      .catch(e => {
        console.log(e);
        const error = parseAxiosError(e);
        message.error(`${this.context.guideName} 등록 실패`);
        message.error(`${error.code}: ${error.message}`);
        this.setState({
          isLoading: false
        });
      });
  };

  onDone = () => {
    message.success(`${this.context.guideName} 등록 완료`);
    this.setState({
      isLoading: false
    });
    this.props.history.push(`/admin/guide/list`);
  };

  handleUploadProfile = contentId => {
    if (contentId) {
      this.setState({
        profileImageId: contentId
      });
    }
  };

  handleChangeClassroom = checkedValues => {
    console.log("checked = ", checkedValues);
  };

  render() {
    const { classroomList: classrooms } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    let classroomList = [];

    if (classrooms) {
      classroomList = filter(classrooms, function(o) {
        return !o.guide;
      });
    }

    return (
      <>
        <PageHeader
          title={
            <Title level={2}>{`${this.context.guideName} 등록하기`}</Title>
          }
        />
        <div className="admin-page-content">
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col span={6}>
                <StudentProfile onUploadProfile={this.handleUploadProfile} />
              </Col>
              <Col span={18}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item label="이름">
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: `${this.context.guideName} 이름을 입력해주세요.`
                          }
                        ]
                      })(
                        <Input
                          placeholder={`${this.context.guideName} 이름을 입력해주세요.`}
                        />
                      )}
                    </Form.Item>

                    <Form.Item label="생년월일">
                      {getFieldDecorator("birthday", {
                        rules: [
                          {
                            required: this.context.type === "campus",
                            message: "생년월일을 선택해주세요."
                          }
                        ]
                      })(
                        <DatePicker
                          placeholder="생년월일을 선택해주세요."
                          format="YYYYMMDD"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="아이디">
                      {getFieldDecorator("account", {
                        rules: [
                          {
                            required: true,
                            message: "아이디를 입력해주세요."
                          },
                          {
                            type: "email",
                            message: "아이디(이메일)를 정확히 입력해 주세요"
                          }
                        ]
                      })(
                        <Input placeholder="아이디(이메일)를 입력해주세요." />
                      )}
                    </Form.Item>

                    <Form.Item label="연락처">
                      {getFieldDecorator("mobile", {
                        rules: [
                          {
                            required: this.context.type === "campus",
                            message: "연락처를 입력해주세요."
                          }
                        ]
                      })(<Input placeholder="연락처를 입력해주세요." onChange={function (e) {
                        e.target.value = e.target.value.replaceAll(' ',"")
                      }} />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="입사일">
                      {getFieldDecorator("joinDate", {
                        rules: []
                      })(
                        <DatePicker
                          placeholder="입사일을 선택해주세요."
                          format="YYYYMMDD"
                        />
                      )}
                    </Form.Item>

                    <Form.Item label="주소">
                      {getFieldDecorator("address1", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "주소찾기를 통해 입력해주세요."
                          // }
                        ]
                      })(
                        <Input
                          placeholder="주소찾기를 통해 입력해주세요."
                          readOnly
                          disabled
                        />
                      )}
                      <Button
                        onClick={() =>
                          this.setState({
                            showAddressPopup: true
                          })
                        }
                      >
                        주소찾기
                      </Button>
                      <SearchAddressPopup
                        title={`주소찾기`}
                        visible={this.state.showAddressPopup === true}
                        maskClosable={false}
                        onDone={(address1, zipcode) => {
                          console.log("address1: ", address1);
                          console.log("zipcode: ", zipcode);
                          this.props.form.setFieldsValue({
                            address1: address1,
                            zipcode: zipcode
                          });
                          this.setState({
                            showAddressPopup: false
                          });
                        }}
                        onCancel={() => {
                          this.setState({
                            showAddressPopup: false
                          });
                        }}
                        footer={null}
                      />
                    </Form.Item>
                    <Form.Item label="zipcode" style={{ display: "none" }}>
                      {getFieldDecorator(
                        "zipcode",
                        {}
                      )(<Input placeholder="zipcode" />)}
                    </Form.Item>
                    <Form.Item label="">
                      {getFieldDecorator("address2", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "상세주소를 입력해주세요."
                          // }
                        ]
                      })(<Input placeholder="상세주소를 입력해주세요." />)}
                    </Form.Item>
                    <Form.Item label={`담당 ${this.context.className}`}>
                      {getFieldDecorator("classroomIds", {
                        initialValue: []
                      })(
                        <Checkbox.Group
                          style={{ width: "100%" }}
                          onChange={this.handleChangeClassroom}
                        >
                          <Row>
                            {classroomList.map(c => (
                              <Col span={6} key={c.classroomId}>
                                <Checkbox value={c.classroomId}>
                                  {c.name}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.isLoading}
                      >
                        등록완료
                      </Button>
                      <Button
                        onClick={() => {
                          this.props.history.push("/admin/guide/list");
                        }}
                        style={{ marginLeft: 16 }}
                      >
                        취소
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default connect(
  ({ auth, classroom }) => ({
    pending: auth.pending || classroom.pending,
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    classroomList: classroom.classroomList
  }),
  dispatch => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch)
  })
)(Form.create()(GuideAddPage));
