import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import update from "immutability-helper";
import * as classroomActions from "store/modules/classroom";
import * as authActions from "store/modules/auth";
import Axios from "../../utils/request";
import SockJsClient from "react-stomp";
import { getHost, SESSION_KEY } from "utils/APIUtils";
import ToggleViewMenu from "../../components/Teacher/button/ToggleViewMenu";
import ClassroomApis from "../../utils/classroomApis";
import {
  filter,
  find,
  findIndex,
  map,
  keys,
  pickBy,
  indexOf,
  values,
  orderBy,
  mapValues,
} from "lodash";
import AttendanceChk from "../classMode/attendanceChk/AttendanceChk";
import GameRanking from "../classMode/game/GameRanking";
import Maketeam from "../classMode/maketeam/Maketeam";
import queryString from "query-string";
import Activity from "./activity/Activity";
import Mvp from "./mvp/Mvp";
import Sendmsg from "./sendmsg/Sendmsg";
import statusMap from "./statusMap";
import EndClass from "./etcpage/EndClass";
import PauseClass from "./etcpage/PauseClass";
import StandBy from "./etcpage/StandBy";
import { message, Button, Icon, Switch } from "antd";
import StudentListPopup from "./components/StudentListPopup";
import ReviewLecturePopup from "./components/ReviewLecturePopup";
import QuetureMain from "./queture/QuetureMain";
import QuetureRanking from "./queture/QuetureRanking";
import QuizSection from "./quiz/QuizSection";
import PreviewQuizSection from "./quiz/PreviewQuizSection";
import { getToken } from "../../utils/APIUtils";
import GameMain from "./game/GameMain";
import GameExplain from "./game/GameExplain";
import NextSectionPopup from "./components/NextSectionPopup";
import SummaryQueture from "./queture/SummaryQueture";
import Toast from "components/Toast/Toast";
import MakeTeamPopup from "../admin/components/Popup/MakeTeamPopup";
import ReconnectSockPopup from "./components/ReconnectSockPopup";
import { ConstantsContext } from "utils/ConstantsContext";
import Summary from "./summary/Summary";
import RefreshController from "./components/RefreshController";
import { Link } from "react-router-dom";
import TeachingGuidePopup from "./components/TeachingGuidePopup";
import SetInteractionPopup from "./components/SetInteractionPopup";
import ChallengeQuiz from "./challengeQuiz/ChallengeQuiz";
import EndQuiz from "./endQuiz/EndQuiz";
import Storytelling from "./storytelling/Storytelling";
import PictureQuizSection from "./pictureQuiz";
import QuebonSong from "./quebonSong/quebonSong";
import MvpPreview from "./mvp/MvpPreview/MvpPreview";
import GameAnimation from "./game/GameAnimation/GameAnimation";
const APP_PREFIX = "/ctrl";

class Classroom extends Component {
  static contextType = ConstantsContext;

  constructor(props) {
    super(props);

    this.state = {
      SectionIdx: 0,
      isLoading: false,
      connected: null,
      message: "",
      quetureIds: [],
      lectureData: null,
      classroomData: null,
      type: "",
      sectionIndex: "",
      sectionType: "",
      page: 0,
      oldPage: 10000000,
      pageList: [],
      isPreview: false,
      settings: {
        isStandBy: false,
        showStudentList: false,
        showStudentGroup: false,
        showNextSection: false,
        showTeachingGuide: false,
        guideHelper: false,
        makeRandomTeam: false,
        startGame: false,
        startQuiz: false,
        previewQuetureGuide: false,
        startQueture: false,
        startStudentQueture: false,
        previewAll: false,
        previewNext: false,
        previewQuiz: false,
        showReviewLecture: false,
        startChallengeQuiz: false,
        startEndQuiz: false,
        startStorytelling: false,
        startQuebonSong: false,
      },
      summaryList: [],
      previewQuizData: null,
      completedLectureList: null,
      isOnline: false,
      leaving: false,
    };
  }

  // type: start, attendance, solved, move(section), game, pause, resume, completed
  onMessageReceive = (msg, topic) => {
    console.log("receive msg: ", msg);
    const { sectionType, lectureData, sectionIndex, page } = this.state;
    const { userType } = this.props;
    const { classroomId } = this.props.match.params;
    const { preview } = queryString.parse(this.props.location.search);

    // Reset Timeout
    const stored = localStorage.getItem(SESSION_KEY);
    if (stored == null) {
      console.log("token is empty");
    }

    if (msg.type === "ack") {
      return;
    }

    if (msg.type === "remocon") {
      return;
    }

    console.log(userType, "메시지 타입");
    if (msg.type === "remote") {
      if (userType === "academy_student") {
        this.handleRemote(msg.data);
      }
      return;
    }

    if (msg.type === "updateTeam") {
      Axios.get(`/academy/v2/class/${classroomId}/lectures/current`).then(
        (res) => {
          let ids = [];
          ids = res.sections.filter((e) => {
            return e.type === "queture" ? true : false;
          });
          this.setState({
            lectureData: res,
            quetureIds: ids,
          });
        }
      );
    }
    if (msg.type === "refresh") {
      if (msg.data !== "all" && this.props.userType === "academy_student") {
        if (this.props.user.userId === msg.data) {
          window.location.reload(true);
        }
      } else if (msg.data === "all") {
        window.location.reload(true);
      }
      return;
    }

    if (msg.type === "game") {
      // if (this.props.userType === "academy_student") {
      //   console.log("student ski: ", page);
      //   return;
      // }
      const _studentIndex = findIndex(
        lectureData.sections[sectionIndex].ranking,
        function (o) {
          return o.student && o.student.studentId === msg.userId;
        }
      );
      console.log("student index:", _studentIndex);
      console.log("student index:", lectureData.sections[sectionIndex].ranking);

      if (_studentIndex >= 0) {
        this.setState({
          lectureData: update(lectureData, {
            sections: {
              [sectionIndex]: {
                ranking: {
                  [_studentIndex]: {
                    score: {
                      $set: msg.score,
                    },
                    time: {
                      $set: msg.time,
                    },
                    currentScore: {
                      $set: msg.currentScore,
                    },
                  },
                },
              },
            },
          }),
        });
      } else {
        Axios.get(`/academy/v2/class/${classroomId}/lectures/current`).then(
          (res) => {
            this.setState({
              lectureData: res,
            });
          }
        );
      }
      return;
    }
    if (msg.type === "leave") {
      if (!this.state.leaving) {
        console.log("### handleLeaveClassrom: leave");
        this.state.leaving = true;
        this.handleLeaveClassroom();
      } else {
        console.log("### handleLeaveClassroom: already leaving");
      }
    }

    if (
      msg.type === "completed" &&
      this.context.type === "school" &&
      this.props.userType === "academy_student"
    ) {
      this.props.history.push("/home");
      // if (this.context.type === "school") {
      //   this.sendMessage(
      //     `/send/${classroomId}/${lectureData.lectureId}/message`,
      //     JSON.stringify({
      //       token: getToken(),
      //       type: "redirectToHomeTraining",
      //       data: {},
      //     })
      //   );
      // }
    }

    if (msg.type === "switchIsOnline") {
      this.setState(
        update(this.state, {
          lectureData: {
            online: {
              $set: msg.value,
            },
          },
        })
      );
      return;
    }

    if (msg.type === "solved") {
      if (this.props.userType === "academy_student" && !lectureData.online) {
        console.log("student ski: ", page);
        return;
      }
      const _userId = Number.parseInt(this.props.user.userId);
      const _msgId = Number.parseInt(msg.userId);
      console.log("solved msg _userId: ", _userId);
      console.log("solved msg _msgId: ", _msgId);
      let isTeamLeader = false;
      let isGroup = false;
      if (lectureData && lectureData.groups && lectureData.groups.length > 0) {
        isGroup = true;
        lectureData.groups.map((group) => {
          if (
            find(group.students, function (o) {
              return Number.parseInt(o.studentId) === _userId;
            })
          ) {
            if (Number.parseInt(group.leader.studentId) === _userId) {
              isTeamLeader = true;
            }
          }
          return group;
        });
      }

      if (this.props.userType === "academy_student") {
        if (!isGroup) {
          return;
        }
        if (isTeamLeader || _userId !== _msgId) {
          return;
        }
      }

      let userId = msg.userId;

      if (this.props.userType === "academy_student" && !isTeamLeader) {
        userId = this.props.user.userId;
      }

      // let sectionIndex = findIndex(res.sections, filterCondition);
      const _quizIndex = findIndex(lectureData.sections[sectionIndex].quiz, {
        quizId: msg.quizId,
      });
      console.log("quiz index:", _quizIndex);
      console.log("is teamleader: ", isTeamLeader);

      this.setState(
        {
          lectureData: update(lectureData, {
            sections: {
              [sectionIndex]: {
                quiz: {
                  [_quizIndex]: {
                    solvedMap: {
                      [userId]: {
                        $set: msg.solveResult,
                      },
                    },
                  },
                },
              },
            },
          }),
        },
        () => {
          console.log("update by solved: ", this.state.lectureData);
        }
      );
      return;
    }

    if (msg.type === "attendance") {
      console.log("lecture data: ", lectureData);
      if (
        this.props.userType !== "academy_student" &&
        lectureData &&
        lectureData.attendances
      ) {
        this.setState({
          lectureData: update(lectureData, {
            attendances: {
              [msg.userId]: {
                $set: {
                  status: "ok",
                  timestamp: msg.timestamp || new Date().getTime(),
                },
              },
            },
          }),
        });
      }
      return;
    }

    // 학생 페이지 reload
    // if (
    //   msg.type === "move" &&
    //   msg.page === 1 &&
    //   lectureData.sections[msg.section].type === "GAME" &&
    //   this.props.userType === "academy_student"
    // ) {
    // window.location.reload();
    // return;
    // }

    let _isStangBy = lectureData && lectureData.paused;
    if (msg.type === "pause" || msg.type === "resume") {
      if (msg.type === "pause") {
        _isStangBy = true;
      } else if (msg.type === "resume") {
        _isStangBy = false;
      }

      this.setState(
        update(this.state, {
          settings: {
            isStandBy: {
              $set: _isStangBy,
            },
          },
          lectureData: {
            paused: {
              $set: _isStangBy,
            },
          },
        })
      );
      return;
    }
    console.log("isStangBY==========: ", _isStangBy);
    console.log("lectureData: ", lectureData);
    if (
      msg.type === "move" &&
      msg.page === 1 &&
      lectureData.sections[msg.section].type === "QUIZ" &&
      this.props.userType === "academy_student"
    ) {
      Axios.get(`/academy/v2/class/${classroomId}/lectures/current`).then(
        (res) => {
          this.handleUpdate(res, msg, sectionIndex, sectionType, _isStangBy);
        }
      );
    }
    if (
      msg.type === "move" &&
      msg.page === 1 &&
      lectureData.sections[msg.section].type === "GROUP" &&
      (preview || this.props.userType === "academy_student")
    ) {
      Axios.get(`/academy/v2/class/${classroomId}/lectures/current`).then(
        (res) => {
          this.handleUpdate(res, msg, sectionIndex, sectionType, _isStangBy);
        }
      );
      return;
    }
    if (
      msg.type === "summary" &&
      (this.props.userType !== "academy_student" ||
        msg.userId === this.props.user.userId)
    ) {
      console.log("update summary: ");
      const _curSummries =
        lectureData && lectureData.sections[sectionIndex].summaries;
      const _curStudent = lectureData.students.filter(
        (student) => student.studentId === msg.userId
      );
      console.log("curSummries: ", _curSummries);
      console.log("curStudent: ", _curStudent);
      const index = _curSummries.findIndex(
        (s) => s.student.studentId === msg.userId
      );
      console.log("index: ", index);
      const curState = this.state;
      if (index > -1) {
        this.setState(
          update(curState, {
            lectureData: {
              sections: {
                [sectionIndex]: {
                  summaries: {
                    [index]: {
                      words: {
                        $set: msg.words,
                      },
                      answer: {
                        $set: msg.answer,
                      },
                      memo: {
                        $set: msg.memo,
                      },
                    },
                  },
                },
              },
            },
          }),
          () => {
            console.log("lecture data: ", this.state.lectureData);
          }
        );
      } else {
        const newSummary = {
          student: _curStudent[0],
          words: msg.words,
          answer: msg.answer,
          memo: msg.memo,
        };
        const _summries = [
          ...lectureData.sections[sectionIndex].summaries,
          newSummary,
        ];
        this.setState(
          update(curState, {
            lectureData: {
              sections: {
                [sectionIndex]: {
                  summaries: {
                    $set: _summries,
                  },
                },
              },
            },
          }),
          () => {
            console.log("lecture data: ", this.state.lectureData);
          }
        );
      }
      return;
    }
    console.log(lectureData);

    this.handleUpdate(lectureData, msg, sectionIndex, sectionType, _isStangBy);
  };
  handleChangePage = (p) => {
    this.setState({
      oldPage: p,
    });
  };
  handleUpdate = (lectureData, msg, sectionIndex, sectionType, _isStangBy) => {
    console.log("lecture Data: ", lectureData);
    console.log("sectionType: ", sectionType);
    this.setState({
      lectureData: lectureData,
      message: JSON.stringify(msg),
      sectionIndex: msg.type === "move" ? msg.section : sectionIndex,
      sectionType:
        msg.type === "move"
          ? lectureData &&
            lectureData.sections &&
            lectureData.sections[msg.section].type
          : sectionType,
      type: msg.type || this.state.type,
      page:
        msg.type === "move"
          ? lectureData &&
            (lectureData.sections[msg.section].type === "GROUP" ||
              lectureData.sections[msg.section].type === "QUETURE" ||
              lectureData.sections[msg.section].type === "GAME" ||
              lectureData.sections[msg.section].type === "QUIZ" ||
              lectureData.sections[msg.section].type === "MVP" ||
              lectureData.sections[msg.section].type === "CHALLENGE" ||
              lectureData.sections[msg.section].type === "ENDQUIZ" ||
              lectureData.sections[msg.section].type === "STORYTELLING" ||
              lectureData.sections[msg.section].type === "PICTUREQUIZ" ||
              lectureData.sections[msg.section].type === "QUEBONSONG")
            ? msg.page
            : 0
          : 0,
      settings: update(this.state.settings, {
        isStandBy: {
          $set: _isStangBy,
        },
        startQueture: {
          $set:
            msg.section &&
            lectureData &&
            lectureData.sections[msg.section].type !== "QUETURE" &&
            msg.type === "move" &&
            this.state.settings.startQueture
              ? false
              : this.state.settings.startQueture,
        },
        startStudentQueture: {
          $set:
            msg.section &&
            lectureData &&
            lectureData.sections[msg.section].type !== "QUETURE" &&
            msg.type === "move" &&
            this.state.settings.startStudentQueture
              ? false
              : this.state.settings.startStudentQueture,
        },
        previewQuiz: {
          $set: msg.type === "move" && msg.page === 1,
        },
        startChallengeQuiz: {
          $set:
            msg.section &&
            lectureData &&
            lectureData.sections[msg.section].type !== "CHALLENGE" &&
            msg.type === "move" &&
            this.state.settings.startChallengeQuiz
              ? false
              : this.state.settings.startChallengeQuiz,
        },
        startEndQuiz: {
          $set:
            msg.section &&
            lectureData &&
            lectureData.sections[msg.section].type !== "ENDQUIZ" &&
            msg.type === "move" &&
            this.state.settings.startEndQuiz
              ? false
              : this.state.settings.startEndQuiz,
        },
        startStorytelling: {
          $set:
            msg.section &&
            lectureData &&
            lectureData.sections[msg.section].type !== "STORYTELLING" &&
            msg.type === "move" &&
            this.state.settings.startStorytelling
              ? false
              : this.state.settings.startStorytelling,
        },
        startQuebonSong: {
          $set:
            msg.section &&
            lectureData &&
            lectureData.sections[msg.section].type !== "QUEBONSONG" &&
            msg.type === "move" &&
            this.state.settings.startQuebonSong
              ? false
              : this.state.settings.startQuebonSong,
        },
      }),
    });
  };

  handleRemote = (data) => {
    switch (data.type) {
      case "start":
        this.setState({
          settings: update(this.state.settings, {
            startStudentQueture: {
              $set: true,
            },
          }),
        });
        break;
      case "end":
        this.setState({
          settings: update(this.state.settings, {
            startStudentQueture: {
              $set: false,
            },
          }),
        });
        break;
      case "startQ":
        this.setState({
          settings: update(this.state.settings, {
            startChallengeQuiz: {
              $set: true,
            },
          }),
        });
        break;
      case "endQ":
        this.setState({
          settings: update(this.state.settings, {
            startChallengeQuiz: {
              $set: false,
            },
          }),
        });
        break;
      case "startEndQuiz":
        this.setState({
          settings: update(this.state.settings, {
            startEndQuiz: {
              $set: true,
            },
          }),
        });
        break;
      case "endEndQuiz":
        this.setState({
          settings: update(this.state.settings, {
            startEndQuiz: {
              $set: false,
            },
          }),
        });
        break;
      case "startStorytelling":
        this.setState({
          settings: update(this.state.settings, {
            startStorytelling: {
              $set: true,
            },
          }),
        });
        break;
      case "endStorytelling":
        this.setState({
          settings: update(this.state.settings, {
            startStorytelling: {
              $set: false,
            },
          }),
        });
        break;
      case "startQuebonSong":
        this.setState({
          settings: update(this.state.settings, {
            startQuebonSong: {
              $set: true,
            },
          }),
        });
        break;
      case "endQuebonSong":
        this.setState({
          settings: update(this.state.settings, {
            startQuebonSong: {
              $set: false,
            },
          }),
        });
        break;
      case "togglePlay":
        console.log("toggle play");
        break;
      case "switchIsOnline":
        this.setState({
          isOnline: data.value,
        });
        break;

      default:
        break;
    }
  };

  onConnect = () => {
    this.setState({
      connected: true,
    });
  };

  onDisconnect = () => {
    this.setState({
      connected: false,
    });
  };

  sendMessage = (uri, msg) => {
    console.log("uri: ", uri);
    console.log("msg: ", msg);
    this.clientRef.sendMessage(`${APP_PREFIX}${uri}`, msg);
  };

  sendRemoteMessage = (classroomId, lectureId, data) => {
    const uri = `/send/${classroomId}/${lectureId}/message`;
    const msg = JSON.stringify({
      token: getToken(),
      type: "remote",
      data: data,
    });
    this.sendMessage(uri, msg);
  };

  componentDidMount() {
    const { user } = this.props;
    const { classroomId } = this.props.match.params;
    console.log("user: ", user);

    if (user && classroomId) {
      ClassroomApis.current(classroomId)
        .then((res) => {
          console.log("current lecture: +", res);
          this.setState({
            quetureIds: res.sections.filter((e) => {
              return e.type === "QUETURE" ? true : false;
            }),
          });

          // if (!res.current) {
          //   this.startLecture(classroomId, res.lectureId);
          //   return;
          // }
          if (res && res.locale === "en") {
            console.log("change locale");
            this.props.onChangeLanguage("en");
          } else {
            this.props.onChangeLanguage("ko");
          }
          if (user.type !== "academy_student") {
            ClassroomApis.lectures(classroomId, 0, 5, "completed").then(
              (res) => {
                console.log("lecture: ", res);
                let _completedLectureList = res.list || [];
                console.log("completed lecture list: ", _completedLectureList);
                this.setState({
                  completedLectureList: orderBy(
                    _completedLectureList,
                    "seq",
                    "desc"
                  ),
                });
              }
            );
          }
          if (user.type === "academy_student") {
            ClassroomApis.attendance(
              classroomId,
              res.lectureId,
              user.studentId,
              1
            )
              .then((r) => {
                console.log("check attendance: ", r);
                console.log("lecture data: ", res);
                this.setLectureData(res);
              })
              .catch((e) => {
                message.warning("출석 체크 실패");
              });
          } else {
            this.setLectureData(res);
          }
        })
        .catch((e) => {
          console.log(e);
          if (
            this.context.type === "school" &&
            user.type === "academy_student"
          ) {
            this.props.history.push("/home");
          }
        });
    }
  }

  // startLecture(classroomId, lectureId) {
  //   ClassroomApis.start(classroomId, lectureId).then(res => {
  //     console.log("start lecture: ", res);
  //     if (res.state === "current") {
  //       this.setLectureData(res);
  //     } else {
  //       Toast.warning("수업시간이 아닙니다.");
  //     }
  //   });
  // }

  handleLeaveClassroom() {
    const { sectionIndex, lectureData } = this.state;
    const { userType } = this.props;
    const { preview } = queryString.parse(this.props.location.search);
    const { classroomId } = this.props.match.params;
    let viewType = userType !== "academy_student" ? "guide" : "student";
    console.log("handleLeaveClassroom: lectureData: ", lectureData);
    if (preview) {
      viewType = "preview";
    }
    if (viewType === "preview") {
      console.log("close preview");
      window.open("about:blank", "_self");
      window.close();
    } else if (viewType === "student") {
      if (this.context.type === "campus") {
        this.props.history.push("/logout");
      }
    } else {
      if (lectureData) {
        if (lectureData.sections.length - 1 === sectionIndex) {
          ClassroomApis.complete(classroomId, lectureData.lectureId)
            .then((res) => {
              console.log(
                "### handleLeavingClassroom: lecture completed: ",
                res
              );
              this.state.leaving = false;
              this.props.history.push(`/admin/class/detail/${classroomId}`);
            })
            .catch((e) => {
              console.log(
                "### handleLeavingClassroom: complete lecture error: ",
                e
              );
              this.state.leaving = false;
              message.warning("수업 완료 실패");
            });
          return;
        } else {
          this.props.history.push(`/admin/class/detail/${classroomId}`);
        }
      }
    }
    console.log("### handleLeavingClassroom: skipped");
    this.state.leaving = false;
  }

  setLectureData(res) {
    const { classroomId } = this.props.match.params;
    let filterCondition = { type: res.current.type };
    if (res.current.type === "QUIZ" || res.current.type === "QUETURE") {
      filterCondition["quetureId"] = res.current.quetureId;
    }
    if (res.current.type === "GAME") {
      filterCondition["gameName"] = res.current.gameName;
    }

    let sectionIndex = findIndex(res.sections, filterCondition);
    this.setState(
      {
        lectureData: res,
        sectionIndex: sectionIndex,
        sectionType: res.current.type,
        type: res.current.type,
        page: res.current.currentPage,
        settings: update(this.state.settings, {
          isStandBy: {
            $set: res && res.paused,
          },
          previewQuiz: {
            $set: res.current.type === "QUIZ" && res.current.currentPage === 1,
          },
          startStudentQueture: {
            $set:
              res.current.type === "QUETURE" &&
              res.current.currentPage === 1 &&
              this.props.userType === "academy_student",
          },
        }),
      },
      () => {
        if (res.current.type === "GROUP") {
          ClassroomApis.move(classroomId, res.lectureId, sectionIndex, 0);
        } else if (res.current.type === "QUETURE") {
          if (this.props.userType !== "academy_student") {
            ClassroomApis.move(classroomId, res.lectureId, sectionIndex, 0);
          }
        }
      }
    );
  }

  getClassroomDetail(id) {
    const { classroomId } = this.props.match.params;
    const { user } = this.props;
    console.log(user);
    this.setState({
      isLoading: true,
    });
    Axios.get(`/academy/v2/accounts/${id}/classrooms/${classroomId}`)
      .then((res) => {
        console.log("classroom detail: ", res);
        this.setState({
          isLoading: false,
          classroomData: res,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.user.orgId !== this.props.user.orgId &&
      nextProps.user.orgId
    ) {
      // console.log("orgId: ", nextProps.user.orgId);
      // this.getClassroomDetail(nextProps.user.orgId);
    }
  }

  componentWillUnmount() {
    this.onDisconnect();
  }

  resetSettings() {
    this.setState({
      settings: {
        isStandBy: this.state.isStandBy,
        showStudentList: false,
        showStudentGroup: false,
        showNextSection: false,
        showTeachingGuide: false,
        guideHelper: false,
        makeRandomTeam: false,
        startGame: false,
        startQuiz: false,
        startQueture: false,
        startStudentQueture: false,
        previewAll: false,
        previewNext: false,
        previewQuiz: false,
        showReviewLecture: false,
      },
    });
  }

  handleSettings(type) {
    console.log("type: ", type);
    const { classroomId } = this.props.match.params;
    const { lectureData, settings } = this.state;

    if (type === "isStandBy") {
      if (settings.isStandBy) {
        console.log("resume");
        ClassroomApis.resume(classroomId, lectureData.lectureId).then((res) => {
          this.setState({
            lectureData: update(this.state.lectureData, {
              paused: {
                $set: res.paused,
              },
            }),
          });
        });
      } else {
        console.log("pause");
        ClassroomApis.pause(classroomId, lectureData.lectureId).then((res) => {
          this.setState({
            lectureData: update(this.state.lectureData, {
              paused: {
                $set: res.paused,
              },
            }),
          });
        });
      }
      return;
    } else if (type === "startQuiz") {
      this.handleSection("next");
    } else if (
      type === "startQueture" ||
      type === "startStudentQueture" ||
      type === "startGame" ||
      type === "previewQuiz" ||
      type === "startChallengeQuiz" ||
      type === "startEndQuiz" ||
      type === "startStorytelling" ||
      type === "startQuebonSong"
    ) {
      this.setState(
        {
          settings: update(this.state.settings, {
            [type]: {
              $set: !this.state.settings[type],
            },
          }),
        },
        () => {
          if (this.state.settings[type]) {
            ClassroomApis.move(
              classroomId,
              lectureData.lectureId,
              this.state.sectionIndex,
              this.state.page + 1
            );
            if (type === "startStudentQueture") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "start",
                value: "",
              });
            }
            if (type === "startChallengeQuiz") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "startQ",
                value: "",
              });
            }
            if (type === "startEndQuiz") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "startEndQquiz",
                value: "",
              });
            }
            if (type === "startStorytelling") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "startStorytelling",
                value: "",
              });
            }
            if (type === "startQuebonSong") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "startQuebonSong",
                value: "",
              });
            }
            if (type === "startStorytelling") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "endStorytelling",
                value: "",
              });
            }
            if (type === "startQuebonSong") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "endQuebonSong",
                value: "",
              });
            }
          } else {
            ClassroomApis.move(
              classroomId,
              lectureData.lectureId,
              this.state.sectionIndex,
              0
            );
            if (type === "startStudentQueture") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "end",
                value: "",
              });
            }
            if (type === "startChallengeQuiz") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "endQ",
                value: "",
              });
            }
            if (type === "startEndQuiz") {
              this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                type: "endEndQuiz",
                value: "",
              });
            }
          }
        }
      );
    } else {
      this.setState({
        settings: update(this.state.settings, {
          [type]: {
            $set: !this.state.settings[type],
          },
        }),
      });
    }
  }

  handleSection(type) {
    const { classroomId } = this.props.match.params;
    const { lectureData } = this.state;
    const { sectionIndex } = this.state;

    let index = sectionIndex;
    if (type === "next") {
      index = sectionIndex + 1;

      if (index === lectureData.sections.length) {
        message.info("마지막 페이지 입니다.");
        return;
      }
    }
    if (type === "prev") {
      index = sectionIndex - 1;
      if (index < 0) {
        message.info("첫페이지 페이지 입니다.");
        return;
      }
    }

    if (type !== "prev" && type !== "next") {
      index = type;
    }

    ClassroomApis.move(classroomId, lectureData.lectureId, index, 0);
  }

  handleAttendance(userId, isCancel) {
    const { classroomId } = this.props.match.params;
    const { lectureData } = this.state;
    if (isCancel) {
      ClassroomApis.attendance(classroomId, lectureData.lectureId, userId, 0);
    } else {
      ClassroomApis.attendance(classroomId, lectureData.lectureId, userId, 1);
    }
  }

  handleChangeSummaryIndex(page) {
    const { sectionIndex, lectureData } = this.state;
    const { classroomId } = this.props.match.params;
    ClassroomApis.move(classroomId, lectureData.lectureId, sectionIndex, page);
    this.setState({ oldPage: page });
  }

  handleSelectGroupType(type) {
    const { settings, lectureData } = this.state;
    const { classroomId } = this.props.match.params;

    let data = null;
    if (type === "single") {
      data = {
        groups: [],
        random: false,
      };
    } else {
      if (settings.makeRandomTeam) {
        data = { random: true };
      } else {
        if (lectureData.groups.length > 0) {
          let _groups = [];
          lectureData.groups.map((group) => {
            _groups.push({
              name: group.groupName,
              leaderStudentId: group.students[0]["studentId"],
              studentIds: map(group.students, "studentId"),
            });
            return group;
          });
          data = { groups: _groups };
        } else {
          data = { random: true };
        }
      }
    }
    ClassroomApis.groups(classroomId, lectureData.lectureId, data).then(
      (res) => {
        if (data.random) {
          Toast.success("랜덤 팀으로 구성됩니다");
        }
        this.setState({
          lectureData: res,
        });
        ClassroomApis.move(
          classroomId,
          lectureData.lectureId,
          this.state.sectionIndex,
          this.state.page + 1
        );
      }
    );
  }

  solve = (quizId, answers, vars) => {
    const { lectureData } = this.state;
    const { classroomId } = this.props.match.params;
    return Axios.post(
      `/academy/v2/class/${classroomId}/lectures/${lectureData.lectureId}/quizzes/${quizId}/solve`,
      {
        quizId: quizId,
        answers: answers,
        vars: vars,
      }
    );
  };

  // const solve = (classroomId, lectureId, data) => {
  //   return Axios.post(
  //     `${apiPrefix}${classroomId}/lectures/${lectureId}/quizzes/${data.quizId}/solve`,
  //     data
  //   );
  // };

  loadLecture = () => {
    const { classroomId } = this.props.match.params;
    Axios.get(`/academy/v2/class/${classroomId}/lectures/current`).then(
      (res) => {
        let ids = [];
        ids = res.sections.filter((e) => {
          return e.type === "queture" ? true : false;
        });
        this.setState({
          lectureData: res,
          quetureIds: ids,
        });
      }
    );
  };

  makeSectionView(isNext = false) {
    const {
      sectionIndex: orginalSectionIndex,
      lectureData,
      sectionType,
      settings,
      page,
      oldPage,
    } = this.state;
    const { user, userType } = this.props;
    const { preview } = queryString.parse(this.props.location.search);
    const { classroomId } = this.props.match.params;
    let viewType = userType !== "academy_student" ? "guide" : "student";
    if (preview) {
      viewType = "preview";
    }

    let sectionIndex = orginalSectionIndex;
    // viewType: guide, student, preview
    let currentSection =
      statusMap[sectionType] && statusMap[sectionType][viewType];

    console.log("view type: ", viewType);
    console.log("current type: ", sectionType);
    console.log("current section: ", currentSection);
    console.log("current sectionIndex: ", sectionIndex);
    console.log("page: ", page);

    if (settings.isStandBy && viewType !== "guide") {
      return (currentSection = (
        <StandBy
          user={user}
          viewType={viewType}
          isKids={lectureData?.lectureType === "kids"}
        />
      ));
    }

    if (isNext && lectureData) {
      sectionIndex = sectionIndex + 1;
      if (sectionIndex === lectureData.sections.length) {
        return <StandBy />;
      }
      const _sectionType = lectureData.sections[sectionIndex]
        ? lectureData.sections[sectionIndex].type
        : null;

      currentSection =
        statusMap[sectionType] && statusMap[_sectionType][viewType];
      console.log("next section: ", currentSection);
    }

    let quetureSectionIndex = null;
    let quizSectionIndex = null;
    if (lectureData) {
      const quetureSectionIndexes = keys(
        pickBy(lectureData.sections, function (o) {
          return o.type === "QUETURE";
        })
      );
      // const quizSectionIndexes = keys(
      //   pickBy(lectureData.sections, function (o) {
      //     return o.type === "QUIZ";
      //   })
      // );
      // quetureSectionIndex = numberToText(
      //   indexOf(quetureSectionIndexes, "" + sectionIndex) + 1
      // );
      // quizSectionIndex = numberToText(
      //   indexOf(quizSectionIndexes, "" + sectionIndex) + 1
      quetureSectionIndex =
        indexOf(quetureSectionIndexes, "" + sectionIndex) + 1;
      quizSectionIndex = indexOf(quetureSectionIndexes, "" + sectionIndex) + 1;
      console.log("quetureSectionIndex: ", quizSectionIndex);
      this.SectionIdx = quetureSectionIndexes.filter(function (e) {
        return sectionIndex >= e ? true : false;
      }).length;
    }
    // 온라인 수업 - 학생 화면 수정
    if (
      viewType === "student" &&
      this.context.type === "campus" &&
      lectureData &&
      lectureData.online
    ) {
      switch (sectionType) {
        case "QUETURE":
          currentSection = "queture";
          break;
        case "MVP":
          currentSection = "mvp";
          break;
        default:
          break;
      }
    }

    console.log("current section: ", currentSection);
    console.log("settings: ", settings);
    switch (currentSection) {
      case "standby":
        currentSection = (
          <StandBy
            user={user}
            viewType={viewType}
            onAttendance={(id) => this.handleAttendance(id)}
          />
        );
        break;
      case "pause":
        currentSection = <PauseClass />;
        break;
      case "attendance":
        currentSection = (
          <AttendanceChk
            viewType={viewType}
            isKids={lectureData?.lectureType === "kids"}
            studentList={lectureData && lectureData.students}
            attendances={lectureData && lectureData.attendances}
          />
        );
        break;
      case "queture":
        const quetureId =
          lectureData && lectureData.sections[sectionIndex].quetureId;
        const userId = user && user.userId;
        currentSection = (
          <QuetureMain
            quetureId={quetureId}
            curPage={page}
            oldPage={oldPage}
            userId={userId}
            classroomId={classroomId}
            viewType={viewType}
            isKids={lectureData?.lectureType === "kids"}
            startQueture={settings.startQueture}
            startStudentQueture={settings.startStudentQueture}
            seq={quetureSectionIndex}
            isOnline={lectureData.online}
            onSendMessage={(data) =>
              this.sendRemoteMessage(classroomId, lectureData.lectureId, data)
            }
          />
        );
        break;
      case "summary":
        console.log(lectureData && lectureData.sections[sectionIndex]);
        currentSection = (
          <Summary
            viewType={viewType}
            summaryInfo={lectureData && lectureData.sections[sectionIndex]}
            classroomId={classroomId}
            studentList={lectureData && lectureData.students}
            lectureId={lectureData && lectureData.lectureId}
          />
        );
        break;
      case "group":
        currentSection = (
          <Maketeam
            curPage={page}
            viewType={viewType}
            onSendMessage={(data) =>
              this.sendRemoteMessage(classroomId, lectureData.lectureId, data)
            }
            onSelectGroupType={(type) => {
              this.handleSelectGroupType(type);
            }}
            groups={lectureData && lectureData.groups}
            students={lectureData && lectureData.students}
            attendances={lectureData && lectureData.attendances}
            user={user}
          />
        );
        break;
      case "ranking":
        console.log("ranking: ", sectionType);
        if (sectionType === "QUIZ") {
          if (
            page === 1
            // && viewType === "preview"
          ) {
            currentSection = (
              <PreviewQuizSection
                lectureId={lectureData.lectureId}
                groups={lectureData && lectureData.groups}
                userId={user && user.userId}
                onSolve={this.solve}
                seq={this.SectionIdx}
                onQuizList={this.loadLecture}
                quizList={
                  lectureData && lectureData.sections[sectionIndex].quiz
                }
                viewType={viewType}
                classroomId={classroomId}
                onSendMessage={(data) =>
                  this.sendRemoteMessage(
                    classroomId,
                    lectureData.lectureId,
                    data
                  )
                }
              />
            );
          } else {
            console.log(sectionIndex, ": quizList : ", lectureData.sections);
            const quetureId =
              lectureData && lectureData.sections[sectionIndex].quetureId;
            const userId = user && user.userId;
            currentSection = (
              <QuetureRanking
                students={lectureData && lectureData.students}
                groups={lectureData && lectureData.groups}
                attendances={lectureData && lectureData.attendances}
                userId={userId}
                quetureId={quetureId}
                seq={this.SectionIdx}
                quizList={lectureData.sections[sectionIndex].quiz}
                isOnline={lectureData.online}
                viewType={viewType}
              />
            );
          }
        }
        break;
      case "quiz":
        let isTeamLeader = false;
        if (page === 1) {
          if (
            this.context.type === "campus" &&
            (lectureData.online ||
              (!lectureData.online && viewType !== "student"))
          ) {
            currentSection = (
              <PreviewQuizSection
                randomSeed={Math.floor(
                  classroomId / this.SectionIdx +
                    this.SectionIdx +
                    lectureData.lectureId / this.SectionIdx
                )}
                lectureId={lectureData.lectureId}
                groups={lectureData && lectureData.groups}
                userId={user && user.userId}
                onSolve={this.solve}
                seq={this.SectionIdx}
                onQuizList={this.loadLecture}
                quizList={
                  lectureData && lectureData.sections[sectionIndex].quiz
                }
                viewType={viewType}
                classroomId={classroomId}
                onSendMessage={(data) =>
                  this.sendRemoteMessage(
                    classroomId,
                    lectureData.lectureId,
                    data
                  )
                }
              />
            );
          } else {
            currentSection = <PauseClass />;
          }
        } else {
          if (
            lectureData &&
            lectureData.groups &&
            lectureData.groups.length > 0
          ) {
            lectureData.groups.forEach((group) => {
              if (
                find(group.students, function (o) {
                  return o.studentId === user.studentId;
                })
              ) {
                if (group.leader.studentId === user.studentId) {
                  isTeamLeader = true;
                }
              }
            });
          } else {
            isTeamLeader = true;
          }
          console.log("isTeamLeader: ", isTeamLeader);

          if (isTeamLeader || lectureData.online) {
            currentSection = (
              <QuizSection
                randomSeed={Math.floor(
                  classroomId / this.SectionIdx +
                    this.SectionIdx +
                    lectureData.lectureId / this.SectionIdx
                )}
                lectureId={lectureData.lectureId}
                groups={lectureData && lectureData.groups}
                userId={user && user.userId}
                onSolve={this.solve}
                seq={this.SectionIdx}
                classroomId={classroomId}
                onQuizList={this.loadLecture}
                quizList={
                  lectureData && lectureData.sections[sectionIndex].quiz
                }
                students={lectureData && lectureData.students}
                attendances={lectureData && lectureData.attendances}
                isTeamLeader={isTeamLeader}
              />
            );
          } else {
            currentSection = <PauseClass />;
          }
        }
        break;
      case "game":
        if (lectureData?.lectureType === "kids" && viewType === "preview") {
          currentSection = <GameAnimation />;
        } else {
          if (page === 0) {
            if (viewType === "student") {
              if (
                lectureData &&
                (lectureData.online || lectureData?.lectureType === "kids")
              ) {
                currentSection = (
                  <GameExplain
                    game={
                      lectureData && lectureData.sections[sectionIndex].gameName
                    }
                    classLocale={lectureData && lectureData.locale}
                  />
                );
              } else {
                currentSection = <PauseClass />;
              }
            } else {
              currentSection = (
                <GameExplain
                  game={
                    lectureData && lectureData.sections[sectionIndex].gameName
                  }
                  classLocale={lectureData && lectureData.locale}
                />
              );
            }
          } else {
            if (viewType === "student") {
              console.log(3);
              currentSection = (
                <GameMain
                  user={user}
                  game={
                    lectureData && lectureData.sections[sectionIndex].gameName
                  }
                  classLocale={lectureData && lectureData.locale}
                  onRankingUpdate={(gameName, score, time) => {
                    console.log("game ranking update: ", { gameName });
                    ClassroomApis.gameScore(
                      classroomId,
                      lectureData.lectureId,
                      gameName,
                      {
                        score: score,
                        time: time,
                      }
                    );
                  }}
                  attendances={lectureData && lectureData.attendances}
                  ranking={lectureData.sections[sectionIndex].ranking}
                  students={lectureData && lectureData.students}
                />
              );
            } else {
              console.log(lectureData.sections[sectionIndex].ranking);
              currentSection = (
                <GameRanking
                  user={user}
                  game={
                    lectureData && lectureData.sections[sectionIndex].gameName
                  }
                  classLocale={lectureData.locale}
                  attendances={lectureData && lectureData.attendances}
                  ranking={lectureData.sections[sectionIndex].ranking}
                  students={lectureData && lectureData.students}
                />
              );
            }
          }
        }
        break;
      case "quebonsong":
        console.log("quebonsong");
        currentSection = (
          <QuebonSong
            startQuebonSong={settings.startQuebonSong}
            lectureId={lectureData.lectureId}
            curPage={page}
            data={lectureData && lectureData.sections[sectionIndex]}
            onSendMessage={(data) =>
              this.sendRemoteMessage(classroomId, lectureData.lectureId, data)
            }
            classroomId={classroomId}
            isKids={lectureData?.lectureType === "kids"}
            viewType={viewType}
          />
        );
        break;
      case "storytelling":
        currentSection = (
          <Storytelling
            viewType={viewType}
            lectureId={lectureData.lectureId}
            curPage={page}
            data={lectureData && lectureData.sections[sectionIndex]}
            onSendMessage={(data) =>
              this.sendRemoteMessage(classroomId, lectureData.lectureId, data)
            }
            classroomId={classroomId}
          />
        );
        break;
      case "pictureQuiz":
        currentSection = <PictureQuizSection viewType={viewType} />;
        break;
      case "challenge":
        console.log(this.state.settings.startChallengeQuiz);
        currentSection = (
          <ChallengeQuiz
            curPage={page}
            viewType={viewType}
            data={lectureData && lectureData.sections[sectionIndex]}
            sectionIndex={sectionIndex}
            section={lectureData.sections}
            startChallengeQuiz={settings.startChallengeQuiz}
            onSendMessage={(data) =>
              this.sendRemoteMessage(classroomId, lectureData.lectureId, data)
            }
            classroomId={classroomId}
            seq={lectureData.seq}
            lectureId={lectureData.lectureId}
          />
        );
        break;
      case "endQuiz":
        console.log(this.state.settings.startEndQuiz);
        currentSection = (
          <EndQuiz
            curPage={page}
            viewType={viewType}
            data={lectureData && lectureData.sections[sectionIndex]}
            sectionIndex={sectionIndex}
            section={lectureData.sections}
            startEndQuiz={settings.startEndQuiz}
            onSendMessage={(data) =>
              this.sendRemoteMessage(classroomId, lectureData.lectureId, data)
            }
            classroomId={classroomId}
            seq={lectureData.seq}
            lectureId={lectureData.lectureId}
          />
        );
        break;
      case "activity":
        currentSection = (
          <Activity
            viewType={viewType}
            isKids={lectureData?.lectureType === "kids"}
            data={lectureData && lectureData.sections[sectionIndex]}
          />
        );
        break;
      case "mvp":
        if (page < 10) {
          if (lectureData?.lectureType === "kids") {
            currentSection = (
              <MvpPreview
                viewType={viewType}
                isKids={lectureData?.lectureType === "kids"}
                data={lectureData && lectureData.sections[sectionIndex]}
                onChangeSummaryIndex={(page) =>
                  this.handleChangeSummaryIndex(page)
                }
              />
            );
          } else {
            const quetureIds = values(
              mapValues(
                filter(lectureData.sections, function (o) {
                  return o.type === "QUETURE";
                }),
                function (o) {
                  return o.quetureId;
                }
              )
            );
            currentSection = (
              <SummaryQueture
                lectureId={lectureData && lectureData.lectureId}
                classroomId={classroomId}
                quetureIds={quetureIds}
                curPage={page}
                oldPage={oldPage}
                setOldPage={this.handleChangePage}
                userId={user && user.userId}
                viewType={viewType}
                isOnline={lectureData.online}
                onChangeSummaryIndex={(page) =>
                  this.handleChangeSummaryIndex(page)
                }
                onSendMessage={(data) =>
                  this.sendRemoteMessage(
                    classroomId,
                    lectureData.lectureId,
                    data
                  )
                }
              />
            );
          }
        } else {
          currentSection = (
            <Mvp
              viewType={viewType}
              isKids={lectureData?.lectureType === "kids"}
              // mvpList={lectureData && lectureData.mvps}
              classroomId={classroomId}
            />
          );
        }
        break;
      case "sendmsg":
        currentSection =
          this.context.type === "campus" ? (
            <Sendmsg classroomId={classroomId} lectureData={lectureData} />
          ) : (
            <EndClass isKids={lectureData?.lectureType === "kids"} />
          );
        break;
      // case "sendmail":
      //   currentSection =
      //     this.context.type === "campus" ? (
      //       <SendMail classroomId={classroomId} lectureData={lectureData} />
      //     ) : (
      //         <EndClass />
      //       );
      //   break;
      case "completed":
        currentSection = (
          <EndClass isKids={lectureData?.lectureType === "kids"} />
        );
        break;
      default:
        break;
    }
    return <>{currentSection}</>;
  }

  render() {
    const {
      connected,
      message,
      lectureData,
      sectionIndex,
      type,
      page,
      settings,
    } = this.state;
    const {
      isStandBy,
      showStudentList,
      showStudentGroup,
      showReviewLecture,
      showNextSection,
      showTeachingGuide,
      previewQuetureGuide,
      startChallengeQuiz,
      startEndQuiz,
    } = settings;

    const { user, userType } = this.props;
    const { classroomId } = this.props.match.params;
    const { preview } = queryString.parse(this.props.location.search);

    console.log(this.context.type);
    if (
      !this.props.hasInteracted &&
      userType === "academy_student" &&
      this.context.type === "campus" &&
      lectureData &&
      lectureData.online
    ) {
      return (
        <SetInteractionPopup
          title={``}
          visible={this.props.hasInteracted === false}
          maskClosable={false}
          onOk={() => this.props.AuthActions.setInteraction(true)}
          footer={null}
          style={{ width: 100 }}
        />
      );
    }

    console.log("sectionIndex: ", page);
    return (
      <>
        {user &&
          user.type &&
          user.type !== "academy_student" &&
          !preview &&
          lectureData && (
            <ToggleViewMenu
              previewQuetureGuide={previewQuetureGuide}
              startChallengeQuiz={startChallengeQuiz}
              startEndQuiz={startEndQuiz}
              classroomId={classroomId}
              seq={lectureData.seq}
              lectureData={lectureData}
              settings={settings}
              onToggleSettings={(type) => this.handleSettings(type)}
              onPreviewQuiz={() => console.log("onPreviewQuiz")}
              onShowAnswer={() => console.log("onShowAnswer")}
              section={lectureData.sections[sectionIndex]}
              isStandBy={isStandBy}
              sectionIndex={sectionIndex}
              sections={lectureData.sections}
              curPage={page}
              isOnline={lectureData.online}
              onNext={() => this.handleSection("next")}
              onPrev={() => this.handleSection("prev")}
              onSection={(index) => this.handleSection(index)}
              onLeave={() => {
                this.sendMessage(
                  `/send/${classroomId}/${lectureData.lectureId}/message`,
                  JSON.stringify({
                    token: getToken(),
                    type: "leave",
                    data: {},
                  })
                );
              }}
              onSendMessage={(data) =>
                this.sendRemoteMessage(classroomId, lectureData.lectureId, data)
              }
            />
          )}

        {this.makeSectionView()}
        {showStudentList && (
          <StudentListPopup
            isOpen={showStudentList}
            studentList={lectureData && lectureData.students}
            lectureData={lectureData}
            onAttendance={(id, isCancel) => this.handleAttendance(id, isCancel)}
            onClose={(mvps) => {
              if (mvps) {
                ClassroomApis.mvps(classroomId, lectureData.lectureId, {
                  studentIds: mvps,
                }).then((res) => {
                  console.log("set mvps: ", res);
                  this.setState({
                    lectureData: update(lectureData, {
                      mvps: {
                        $set: res.mvps,
                      },
                    }),
                  });
                });
              }
              this.handleSettings("showStudentList");
            }}
          />
        )}
        {showStudentGroup && (
          <MakeTeamPopup
            onSendMessage={(data) =>
              this.sendMessage(
                `/send/${classroomId}/${lectureData.lectureId}/message`,
                JSON.stringify({
                  token: getToken(),
                  type: "updateTeam",
                  data: null,
                })
              )
            }
            onRandomChange={(e) => {
              this.setState({
                lectureData: update(lectureData, {
                  groups: {
                    $set: e.groups,
                  },
                }),
              });
            }}
            classroomId={classroomId}
            title={`팀 구성 확인`}
            visible={showStudentGroup}
            maskClosable={false}
            width={800}
            onDone={(groups) => {
              ClassroomApis.groups(classroomId, lectureData.lectureId, groups)
                .then((res) => {
                  console.log("groups done: ", res);
                  this.setState({
                    lectureData: update(lectureData, {
                      groups: {
                        $set: res.groups,
                      },
                    }),
                  });
                })
                .catch((e) => {
                  console.log(e);
                });
              this.handleSettings("showStudentGroup");
            }}
            onCancel={() => {
              this.handleSettings("showStudentGroup");
            }}
            lectureData={lectureData}
          />
        )}
        {showReviewLecture && (
          <ReviewLecturePopup
            isOpen={showReviewLecture}
            onClose={() => {
              this.handleSettings("showReviewLecture");
            }}
            lectureData={this.state.completedLectureList}
          />
        )}
        {showNextSection && (
          <NextSectionPopup
            isOpen={showNextSection}
            onClose={() => {
              this.handleSettings("showNextSection");
            }}
          >
            {this.makeSectionView(true)}
          </NextSectionPopup>
        )}
        {showTeachingGuide && (
          <TeachingGuidePopup
            isOpen={showTeachingGuide}
            onClose={() => {
              this.handleSettings("showTeachingGuide");
            }}
            quetures={this.state.quetureIds}
          >
            {this.makeSectionView(true)}
          </TeachingGuidePopup>
        )}
        {user &&
          user.type === "academy_student" &&
          user.orgType === "campus" && <RefreshController />}
        {user &&
          user.type === "academy_student" &&
          user.orgType === "campus" &&
          this.state.sectionType === "CLOSED" && (
            <>
              {/* <Button
                type="primary"
                style={{ position: "fixed", top: 24, right: 24 }}
                onClick={() => {
                  this.props.history.push("/logout");
                }}
              ></Button> */}
              <Icon
                style={{ position: "fixed", top: 24, right: 24 }}
                onClick={() => {
                  this.props.history.push("/logout");
                }}
                type="close-circle"
              />
            </>
          )}
        {user &&
          user.type === "academy_student" &&
          user.orgType === "school" && (
            <>
              <span
                style={{ position: "absolute", left: 4, top: 4 }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <Icon type="reload" />
              </span>
              <Link
                to="/logout"
                style={{ position: "absolute", right: 4, top: 4 }}
              >
                <Icon type="logout" />
                <span>로그아웃</span>
              </Link>
            </>
          )}
        {user && (user.type === "guide" || user.type === "org") && !preview && (
          <div
            style={{ position: "absolute", right: 50, top: 50, zIndex: 999 }}
          >
            {this.context.type === "campus" && (
              <div
                style={{
                  display: "block",
                  marginBottom: 8,
                  padding: 8,
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                    marginRight: 4,
                    color: "#55a3ff",
                  }}
                >
                  온라인 수업
                </span>
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  // checked={this.state.isOnline}
                  // onChange={(checked) => {
                  //   this.setState({ isOnline: checked });
                  //   this.sendRemoteMessage(classroomId, lectureData.lectureId, {
                  //     type: "switchIsOnline",
                  //     value: checked,
                  //   });
                  // }}
                  checked={lectureData && lectureData.online}
                  onChange={(checked) => {
                    ClassroomApis.toggleOnline(
                      classroomId,
                      lectureData.lectureId,
                      checked
                    ).then((res) => {
                      console.log("toggleOnline: ", res);
                    });
                  }}
                />
              </div>
            )}
            <button
              style={{
                display: "block",
                background: "#55a3ff",
                color: "#fff",
                borderRadius: 4,
                marginBottom: 8,
                padding: 8,
              }}
              onClick={() => {
                this.sendMessage(
                  `/send/${classroomId}/${lectureData.lectureId}/message`,
                  JSON.stringify({
                    token: getToken(),
                    type: "refresh",
                    data: "all",
                  })
                );
              }}
            >
              전체 새로고침
            </button>
            {this.context.type === "campus" &&
              lectureData &&
              lectureData.students.map((student) => (
                <button
                  key={student.studentId}
                  style={{
                    display: "block",
                    background: "#55a3ff",
                    color: "#fff",
                    borderRadius: 4,
                    marginBottom: 8,
                    padding: 8,
                  }}
                  onClick={() => {
                    this.sendMessage(
                      `/send/${classroomId}/${lectureData.lectureId}/message`,
                      JSON.stringify({
                        token: getToken(),
                        type: "refresh",
                        data: student.studentId,
                      })
                    );
                  }}
                >
                  학생 {student.name} 새로고침
                </button>
              ))}
          </div>
        )}
        <div
          style={{
            display: "none",
            position: "absolute",
            left: 50,
            top: 50,
            zIndex: 999,
          }}
        >
          <p>{`connected: ${connected}`}</p>
          <p>{message}</p>
          {lectureData && (
            <div style={{ display: "none" }}>
              <p>{`View Type: ${userType}`}</p>
              <p>{`Lecture state: ${lectureData.state}`}</p>
              <p>{`Current Type: ${lectureData.current.type}`}</p>
              <p>{`Msg Type: ${type}`}</p>
              <pre>{JSON.stringify(settings)}</pre>
            </div>
          )}
          <div>
            <button
              style={{ padding: 4, margin: 4 }}
              onClick={() =>
                ClassroomApis.start(classroomId, lectureData.lectureId)
              }
            >
              start
            </button>
            <button
              style={{ padding: 4, margin: 4 }}
              onClick={() => {
                ClassroomApis.pause(classroomId, lectureData.lectureId);
              }}
            >
              pause
            </button>
            <button
              style={{ padding: 4, margin: 4 }}
              onClick={() => {
                ClassroomApis.resume(classroomId, lectureData.lectureId);
              }}
            >
              resume
            </button>
          </div>
          <div>
            <span>섹션: </span>
            <select
              value={this.state.sectionIndex}
              onChange={(e) => this.setState({ sectionIndex: e.target.value })}
            >
              {lectureData &&
                lectureData.sections.map((section, index) => (
                  <option key={index} value={index}>
                    {section.type}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <span>페이지: </span>
            <input
              type="number"
              placeholder="페이지"
              value={this.state.page}
              onChange={(e) => this.setState({ page: e.target.value })}
            />
          </div>
          <button
            onClick={() => {
              // ClassroomApis.move(
              //   classroomId,
              //   lectureData.lectureId,
              //   this.state.sectionIndex,
              //   this.state.page
              // );
              this.sendMessage(
                `/send/${classroomId}/${lectureData.lectureId}/message`,
                JSON.stringify({
                  token: getToken(),
                  type: "refresh",
                  data: null,
                })
              );
            }}
          >
            이동
          </button>

          <SockJsClient
            ref={(e) => (this.clientRef = e)}
            url={`${getHost()}/academy/controller`}
            topics={[
              `/topic/${classroomId}/all`,
              `/topic/${classroomId}/users/${user.userId}`,
              `/topic/${classroomId}/${user.type}`,
            ]}
            onConnect={this.onConnect}
            onDisconnect={this.onDisconnect}
            onMessage={this.onMessageReceive}
            debug={false}
          />
        </div>
        {user && connected === false && (
          <ReconnectSockPopup
            title={`수업 연결하기`}
            visible={user && connected === false}
            maskClosable={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  window.location.reload();
                }}
              >
                새로고침
              </Button>,
            ]}
          />
        )}
      </>
    );
  }
}

export default connect(
  ({ auth, classroom }) => ({
    pending: auth.pending || classroom.pending,
    auth: auth,
    user: auth.user,
    hasInteracted: auth.hasInteracted,
    userType: auth.user.type,
    logged: auth.logged,
    classroomList: classroom.classroomList,
  }),
  (dispatch) => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
    AuthActions: bindActionCreators(authActions, dispatch),
  })
)(Classroom);
