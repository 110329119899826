import React, { useState } from "react";
import classNames from "classnames";

import "./_filter.scss";
import { getKoreanDate } from "../../utils/utils";
import { Input } from "antd"
import CommonApis from "../../utils/commonApis";
import axios from "axios";

const Filter = props => {
  const {
    sortType,
    onChangeSortType,
    list,
    onFilter,
    filterValue,
    lectureCountFilter,
    filterCourse,
    onFilterTitle,
    filterValueTitle,
    filterTitle,
  } = props;
  // const [tab, setTab] = useState('newest');
  const [filter, setFilter] = useState("off");
  const [filter2, setFilter2] = useState("off");

  const handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      filterTitle(e.target.value)
    }
  }

  function filterToggle() {
    console.log(filterCourse + "filterCourse ")
    setFilter(filter === "off" ? "on" : "off");
  }


  function filterToggle2() {
    setFilter2(filter2 === "off" ? "on" : "off");
  }

  return (
    <div className="ht-filter">
      <ul className="due" style={{ paddingRight: '21.5%' }}>
        <li
          style={{ fontWeight: 700, letterSpacing: '-2%' }}
          className={classNames("", {
            "is-active": sortType === "newest"
          })}
          onClick={() => {
            onChangeSortType("newest");
          }}
        >
          최신순
        </li>
        <li style={{ fontWeight: 700, letterSpacing: '-2%' }}

            className={classNames("", {
              "is-active": sortType === "oldest"
            })}
            onClick={() => onChangeSortType("oldest")}
        >
          오래된 순
        </li>
      </ul>
      <div

        style={{ minWidth: '0px', minHeight: '0px', width: '212px', height: '44px', marginRight: '8px' }}
        className={classNames("ht-filter-wrap", {
          on: filter2 == "on"
        })}
        onClick={filterToggle2}
      >
        <span>
          {filterValueTitle ? filterValueTitle + " 코스" : "코스를 선택해주세요"}
        </span>
        <ul>
          {filterValueTitle && (
            <li
              onClick={() => {
                onFilterTitle(null);
              }}
            >
              전체
            </li>
          )}
          {filterCourse &&
          filterCourse.map(item => (
            <li key={item} onClick={() => onFilterTitle(item)}>
              {item + " 코스"}
            </li>
          ))}
        </ul>
      </div>
      <div style={{ minWidth: '0px', minHeight: '0px', width: '242px', height: '44px', marginRight: '8px' }}
           className={classNames("ht-filter-wrap", {
             on: filter == "on"
           })}
           onClick={filterToggle}
      >
        <span>
          {filterValue ? (filterValue) : "학습 회차를 선택해주세요"}
        </span>
        <ul>
          {filterValue && (
            <li
              onClick={() => {
                onFilter(null);
              }}
            >
              전체
            </li>
          )}
          {lectureCountFilter &&
          lectureCountFilter.sort(function (a,b) {
            let result_A = a.replace(/[^0-9]/g,"");
            let result_B = b.replace(/[^0-9]/g,"");
            return result_A - result_B;}).map(item => (
            <li key={item} onClick={function () {
              let str;
              switch (lectureCountFilter.indexOf(item)) {
                case 0 :
                  str = ["1회차", "~", "10회차"];
                  break;
                case 1 :
                  str = ["11회차", "~", "20회차"];
                  break;
                case 2 :
                  str = ["21회차", "~", "30회차"];
                  break;
                case 3 :
                  str = ["31회차", "~", "40회차"];
                  break;
                case 4 :
                  str = ["41회차", "~", "50회차"];
                  break;
                case 5 :
                  str = ["51회차", "~", "60회차"];
                  break;
              }
              onFilter(str)
            }}>
              {(item)}
            </li>
          ))}
        </ul>
      </div>
      <span>
            <Input.Search
              style={{
                minWidth: '0px',
                minHeight: '0px',
                width: '304px',
                height: '44px',
                top: '1px'
              }}
              placeholder="검색어를 입력해주세요"
              onKeyDown={(e) => handleKeyDown(e)}
            >
          </Input.Search>
        </span>
    </div>
  );
};

export default Filter;
