import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CommonApis from "../../utils/commonApis";
import * as hometrainingActions from "store/modules/hometraining";
import { Link, withRouter } from "react-router-dom";
import HomeHeader from "../../components/HomeTraning/HomeHeader/HomeHeader";
import QuetureList from "../../components/QuetureList/QuetureList";
import "./_review.scss";
import Filter from "../../components/Filter/Filter";
import { Col, Row, Skeleton } from "antd";
import QuizList from "../../components/QuizList/QuizList";
import ReviewQuetureList from "../../components/QuetureList/ReviewQuetureList";
import { filter, values, mapValues, uniq, includes } from "lodash";
import PopupQueture from "../../components/PopupQueture/PopupQueture";
import { getKoreanDate } from "../../utils/utils";
import update from "immutability-helper";
import HometrainingApis from "utils/hometrainingApis";
import { getSeqText } from "utils/utils";
import axios from "axios";
import classNames from "classnames";
import QuebonSongRow from "./components/QuebonSongRow/QuebonSongRow";
import QbPlayer from "components/QbPlayer/QbPlayer";
import Popup from "components/Popup/Popup";

const PAGE_COUNT = 100;

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortReverse: false,
      solveQuiz: false,
      quizList: [],
      quizTitle: null,
      quizQuetureId: null,
      quizLectureId: null,
      popupQuetureData: null,
      popupQuebonSongData: null,
      sortType: "newest",
      lectureList: [],
      loadedList: [],
      searchList: [],
      filterList: [],
      titleFilterList: [],
      dateList: [],
      filterValue: null,
      onFilterTitle: null,
      courseTitle: [],
      lectureCountFilter: [],
      searchTitle: null,
      filterTitle: null,
      page: 0,
      isSolveEndQuiz: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    HometrainingApis.currentLecture(user)
      .then((isStart) => {
        if (isStart) {
          this.props.history.push(`/classroom/${user.classroom.classroomId}`);
        } else {
          this.props.HometrainingActions.getLectures();
        }
      })
      .catch((e) => {
        this.props.HometrainingActions.getLectures();
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lectures !== this.props.lectures) {
      let _dateList = values(
        mapValues(this.props.lectures, function (o) {
          return o.date.slice(0, 7);
        })
      );

      let _lectureIds = values(
        mapValues(this.props.user.lectures, function (o) {
          return o.lectureId;
        })
      );

      let _lecturecourseTitle = values(
        mapValues(this.props.lectures, function (o) {
          return o.courseTitle[0];
        })
      );
      _lecturecourseTitle.sort();
      let _lectureCountFilter = values(
        mapValues(this.props.lectures, function (o) {
          var regex = /[^0-9]/g;
          var result = o.title.replace(regex, "");

          if (result >= 1 && result <= 10) {
            return "1회차~10회차 ";
          } else if (result >= 11 && result <= 20) {
            console.log(result);
            return "11회차~20회차";
          } else if (result >= 21 && result <= 30) {
            return "21회차~30회차";
          } else if (result >= 31 && result <= 40) {
            return "31회차~40회차";
          } else if (result >= 41 && result <= 50) {
            return "41회차~50회차";
          } else if (result >= 51 && result <= 60) {
            return "51회차~60회차";
          }
        })
      );

      const _lectureList = filter(this.props.lectures, function (l) {
        return includes(_lectureIds, l.lectureId);
      });

      this.setState({
        searchList: _lectureList.slice(0, PAGE_COUNT),
        lectureList: _lectureList,
        loadedList: _lectureList.slice(0, PAGE_COUNT),
        dateList: uniq(_dateList),
        courseTitle: uniq(_lecturecourseTitle),
        _lectureCountFilter: uniq(_lectureCountFilter),
        titleFilterList: _lectureList,
        filterList: _lectureList,
        searchTitle: "",
      });
    }
  }

  handleEnterSolveQuiz = (quetureData, lectureId) => {
    const { lectures } = this.props;
    const _lecture = lectures.find((l) => l.lectureId === lectureId);

    if (_lecture) {
      const _quizSection = _lecture.sections.find(
        (s) => s.type === "QUIZ" && s.quetureId === quetureData.quetureId
      );

      if (_quizSection) {
        this.setState({
          solveQuiz: true,
          quizList: _quizSection.quiz,
          quizTitle: quetureData.title,
          quizQuetureId: quetureData.quetureId,
          quizLectureId: lectureId,
          isSolveEndQuiz: false,
        });
      } else {
        console.log("ino quz");
      }
    }
  };

  handleEnterEndQuiz = (quetureData, lectureId) => {
    const { lectures } = this.props;
    const _lecture = lectures.find((l) => l.lectureId === lectureId);

    if (_lecture) {
      const _quizSection = _lecture.sections.find((s) => s.type === "ENDQUIZ");

      if (_quizSection) {
        this.setState({
          solveQuiz: true,
          quizList: _quizSection.quiz,
          quizTitle: quetureData.title,
          quizQuetureId: quetureData.quetureId,
          quizLectureId: lectureId,
          isSolveEndQuiz: true,
        });
      } else {
        console.log("ino quz");
      }
    }
  };

  handleFindSolveQuiz = (quetureData, lectureId) => {
    const { lectures } = this.props;
    const _lecture = lectures.find((l) => l.lectureId === lectureId);

    if (_lecture) {
      const _quizSection = _lecture.sections.find(
        (s) => s.type === "QUIZ" && s.quetureId === quetureData.quetureId
      );

      if (_quizSection) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  handleFindEndQuiz = (quetureData, lectureId) => {
    const { lectures } = this.props;
    const _lecture = lectures.find((l) => l.lectureId === lectureId);

    if (_lecture) {
      const _quizSection = _lecture.sections.find((s) => s.type === "ENDQUIZ");

      if (_quizSection) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  handleChangeSortType = (value) => {
    const _type = value;
    let isReverse;
    if (_type !== this.state.sortType) {
      isReverse = !this.state.sortReverse;

      let _list = this.state.searchList.reverse();
      this.setState({
        sortType: _type,
        sortReverse: isReverse,
        searchList: _list,
      });
    }
  };

  serachTitle = (value) => {
    this.setState({
      searchList: [],
    });
    let titleNameFilter = [];
    let v2 = [];
    let filterData = value;

    value.map((e) => {
      e.sections
        .filter((a) => {
          return a.type === "QUETURE";
        })
        .map((a) => {
          titleNameFilter.push(a.quetureId);
        });
    });

    CommonApis.getQuetureDataList(titleNameFilter).then(
      axios.spread((...responses) => {
        responses
          .filter((e, idx) => {
            let title = e.data.title.replace(/ /g, "");
            let searchTag = this.state.searchTitle.replace(/ /g, "");
            let conceptTags = e.data.conceptTags.join(",");
            console.log(title + conceptTags + searchTag);
            return title.includes(searchTag) || conceptTags.includes(searchTag);
          })
          .map((e) => v2.push(e));

        filterData = value.filter((e) => {
          for (let i = 0; i < v2.length; i++) {
            if (
              e.sections.filter((r) => {
                return r.quetureId === v2[i].data.quetureId;
              }).length != 0
            ) {
              return true;
            }
          }
        });
        if (this.state.sortReverse === true) filterData = filterData.reverse();
        this.setState({
          searchList: filterData,
        });
        console.log(this.state.searchList);
      })
    );
    // return _lectureList.filter(e => {for(int i =0 ;i < v3.length i++)})
  };

  filterTitle = (value) => {
    this.setState({
      searchTitle: value,
    });
    this.serachTitle(this.state.loadedList);
  };

  handleFilter = (value) => {
    this.setState({
      searchList: [],
    });
    let _newList;
    this.state.titleFilterList != this.state.searchList
      ? (_newList = [...this.state.titleFilterList])
      : (_newList = [...this.state.searchList]);
    let _list = [...this.state.lectureList];

    if (value) {
      _newList = filter(_newList, function (o) {
        var regex = /[^0-9]/g;
        var result_A = o.title.replace(regex, "");
        var result_B = value[0].replace(regex, "");
        var result_C = value[2].replace(regex, "");

        return (
          parseInt(result_A) >= parseInt(result_B) &&
          parseInt(result_A) <= parseInt(result_C)
        );
      });
    }
    if (value) {
      _list = filter(_list, function (o) {
        return o.title > value;
      });
    }

    this.setState({
      filterValue: value,
      // lectureList: _newList,
      filterList: _list.slice(0, PAGE_COUNT),

      loadedList: _newList,
    });
    if (this.state.searchTitle != "") {
      this.serachTitle(_newList);
    } else {
      if (this.state.sortReverse == true) {
        _newList.reverse();
      }

      this.setState({
        searchList: _newList,
      });
    }
  };

  handleFilterCourseTitle = (value) => {
    this.handleFilter(null);
    let _newList = [...this.state.lectureList];
    // this.state.filterList != this.state.searchList ? _newList = [...this.state.filterList] : _newList = [...this.state.searchList];

    let _list = [...this.state.lectureList];
    if (value) {
      _newList = filter(_newList, function (o) {
        return o.courseTitle.includes(value);
      });
    }
    if (value) {
      _list = filter(_list, function (o) {
        console.log(o);

        return o.courseTitle.includes(value);
      });
    }

    this.setState({
      onFilterTitle: value,
      titleFilterList: _list.slice(0, PAGE_COUNT),
      // lectureList: _newList,
      loadedList: _newList,
    });

    if (this.state.searchTitle != "") {
      this.serachTitle(_newList);
    } else {
      if (this.state.sortReverse == true) {
        _newList.reverse();
      }
      this.setState({
        searchList: _newList,
      });
    }
  };

  loadMore = () => {
    const { page, loadedList, lectureList } = this.state;
    const _page = page + 1;
    const _lectureList = [...lectureList];
    const _appendList = _lectureList.slice(
      _page * PAGE_COUNT,
      (_page + 1) * PAGE_COUNT
    );

    // this.setState({
    //   loadedList: [loadedList, ..._appendList],
    //   page: _page
    // })
    this.setState(
      update(this.state, {
        loadedList: {
          $push: _appendList,
        },
        page: {
          $set: _page,
        },
      })
    );
  };

  render() {
    const { pending, lectures, hometraining } = this.props;
    const {
      solveQuiz,
      quizList,
      quizTitle,
      popupQuetureData,
      popupQuebonSongData,
      sortType,
      quizLectureId,
      quizQuetureId,
      lectureList,
      loadedList,
      searchList,
      dateList,
      filterValue,
      courseTitle,
      searchTitle,
      onFilterTitle,
      _lectureCountFilter,
      isReverse,
    } = this.state;

    console.log("lectures: ", lectureList);
    console.log("hometraining: ", hometraining);
    console.log("sortType: ", sortType);
    console.log("loadedList: ", isReverse);
    let filterList = searchList;

    return (
      <Skeleton loading={pending} active>
        {solveQuiz ? (
          <QuizList
            isSolveEndQuiz={this.state.isSolveEndQuiz}
            userId={this.props.user.userId}
            classroomId={this.props.user.classroom.classroomId}
            lectureId={quizLectureId}
            quetureId={quizQuetureId}
            quizList={quizList}
            title={quizTitle}
            onBack={() => {
              this.setState({
                solveQuiz: false,
              });
            }}
          />
        ) : (
          <>
            <HomeHeader lectures={lectures} />
            <div className="review-layout">
              {!lectureList ||
                (lectureList.length === 0 && (
                  <p className="empty-content">
                    &lt;깨처 다시보기&gt;가 없습니다.{" "}
                  </p>
                ))}
              {lectureList && lectureList.length > 0 && (
                <Filter
                  filterCourse={courseTitle}
                  lectureCountFilter={_lectureCountFilter}
                  sortType={sortType}
                  onChangeSortType={this.handleChangeSortType}
                  onFilter={this.handleFilter}
                  list={dateList}
                  filterValue={filterValue}
                  onFilterTitle={this.handleFilterCourseTitle}
                  filterValueTitle={onFilterTitle}
                  filterTitle={this.filterTitle}
                />
              )}
              {filterList &&
                filterList.map((lecture) => (
                  <div
                    key={lecture.lectureId}
                    className="review-layout-wrap"
                    style={{ backgroundColor: "white", borderRadius: "16px" }}
                  >
                    <div
                      className="ht-title"
                      style={{
                        marginBottom: "0px",
                        borderBottom: "2px solid #333332",
                        color: "#333333",
                        marginLeft: "40px",
                        width: "1120px",
                        paddingTop: "24px",
                        fontSize: "30px",
                        font: "HGSoftGGothicssi",
                        letterSpacing: "-1.5%",
                        lineHeight: "28.8px",
                      }}
                    >
                      {`${lecture.courseTitle[0]}코스 ${getSeqText(
                        lecture
                      )} 수업`}

                      <div
                        className="date-tag"
                        style={{
                          padding: "0px",
                          paddingTop: "24px",
                          fontSize: "20px",
                        }}
                      >
                        <span>{getKoreanDate(lecture.date)} </span>
                      </div>
                    </div>
                    <ReviewQuetureList
                      searchTitle={searchTitle}
                      onShowQueturePopup={(quetureData) => {
                        this.setState({
                          popupQuetureData: quetureData,
                        });
                      }}
                      handleFindSolveQuiz={this.handleFindSolveQuiz}
                      handleFindEndQuiz={this.handleFindEndQuiz}
                      onEnterSolveQuiz={this.handleEnterSolveQuiz}
                      onEnterEndQuiz={this.handleEnterEndQuiz}
                      quetureIds={values(
                        mapValues(
                          filter(lecture.sections, function (o) {
                            return o.type === "QUETURE";
                          }),
                          function (o) {
                            return o.quetureId;
                          }
                        )
                      )}
                      lectureId={lecture.lectureId}
                    />
                    {lecture?.sections &&
                      filter(lecture.sections, function (o) {
                        return o.type === "QUETURE";
                      }).length > 0 && (
                        <QuebonSongRow
                          lecture={lecture}
                          onShowQuebonSongPopup={(data) => {
                            this.setState({
                              popupQuebonSongData: data,
                            });
                          }}
                        />
                      )}
                  </div>
                ))}
            </div>
          </>
        )}
        {popupQuetureData && (
          <PopupQueture
            userId={"tester"}
            quetureData={popupQuetureData}
            isOpen={popupQuetureData !== null}
            onClose={() => {
              this.setState({
                popupQuetureData: null,
              });
            }}
            onCompleteQueture={() => {
              console.log();
            }}
          />
        )}
        {popupQuebonSongData && (
          <Popup
            type="quebot-videopopup"
            isOpen={popupQuebonSongData !== null}
            onClose={() => {
              this.setState({
                popupQuebonSongData: null,
              });
            }}
            className="ht-queture-popup"
          >
            <div className="popup-content-wrap">
              <div
                className={`popup-closebtn`}
                onClick={() => {
                  this.setState({
                    popupQuebonSongData: null,
                  });
                }}
              ></div>
              <div className="playerWrap" style={{ overflow: "hidden" }}>
                <QbPlayer
                  src={popupQuebonSongData?.content?.signedUrl}
                  viewType="preview"
                  controls={true}
                />
              </div>
            </div>
          </Popup>
        )}
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth, hometraining }) => ({
      pending: auth.pending || hometraining.pending,
      user: auth.user,
      logged: auth.logged,
      hometraining: hometraining,
      lectures: hometraining.lectures,
    }),
    (dispatch) => ({
      HometrainingActions: bindActionCreators(hometrainingActions, dispatch),
    })
  )(Review)
);
