import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
const renderRoutes = (
  routes,
  extraProps = {},
  authed,
  authPath = "/login",
  switchProps = {}
) =>
  routes ? (
    <Switch {...switchProps}>
      {routes.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={(props) => {
            console.log("auth: ", authed);
            console.log("extraProps: ", extraProps);

            if (!route.requiresAuth || authed || route.path === authPath) {
              if (route.layout) {
                return (
                  <route.layout {...props} {...extraProps} route={route}>
                    <route.component {...props} {...extraProps} route={route} />
                    {/* <route.component /> */}
                  </route.layout>
                );
              } else {
                return (
                  <route.component {...props} {...extraProps} route={route} />
                );
              }
            }
            if (
              authed &&
              route.path.startsWith("/admin") &&
              authed === "student"
            ) {
              return <Redirect to="/no-permissions" />;
            }
            return (
              <Redirect
                to={{ pathname: authPath, state: { from: props.location } }}
              />
            );
          }}
        />
      ))}
    </Switch>
  ) : null;
export default renderRoutes;
