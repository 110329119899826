import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";
import {
  SESSION_KEY,
  getHost,
  getDefaultConfig,
  parseAxiosError
} from "utils/APIUtils";
import axios from "axios";
import Axios from "../../utils/request";

const COURSE_LIST = "auth/COURSE_LIST";
const COURSE_LIST_LOADING = "auth/COURSE_LIST_LOADING";
const COURSE_LIST_SUCCESS = "auth/COURSE_LIST_SUCCESS";
const COURSE_LIST_ERROR = "auth/COURSE_LIST_ERROR";

function _getCourseList() {
  return Axios.get(`/academy/v2/courses`);
}

export const getCourseList = () => (dispatch, getState) => {
  console.log("get course list: ");
  dispatch({
    type: COURSE_LIST_LOADING
  });
  _getCourseList()
    .then(res => {
      dispatch({
        type: COURSE_LIST_SUCCESS,
        payload: res.list
      });
    })
    .catch(e => {
      dispatch({
        type: COURSE_LIST_ERROR,
        payload: e
      });
    });
};

const initialState = {
  pending: false,
  courseList: [],
  error: {
    triggered: false,
    code: null,
    message: null
  }
};

export default handleActions(
  {
    [COURSE_LIST_LOADING]: (state, { payload }) => {
      return {
        ...state,
        pending: true
      };
    },
    [COURSE_LIST_SUCCESS]: (state, { payload }) => {
      console.log("get COURSE list success: ", payload);
      return {
        ...state,
        pending: false,
        courseList: payload
      };
    },
    [COURSE_LIST_ERROR]: (state, { payload }) => {
      return {
        ...state,
        pending: false,
        courseList: []
      };
    }
  },
  initialState
);
