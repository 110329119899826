export const constants = {
  campus: {
    type: "campus",
    currName: "커리큘럼",
    className: "클래스",
    guideName: "선생님",
    registeredStudent: "재학생",
    dropoutStudent: "퇴원생"
  },
  school: {
    type: "school",
    currName: "학년",
    className: "반",
    guideName: "선생님",
    registeredStudent: "재학생",
    dropoutStudent: "전학생"
  }
};
