import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ColoringImage from "./modules/ColoringImage";

const ColoringQuiz = (props) => {
  const { data, onAnswerUpdated, showReallyTrue, stepQuizAnswers, solvedData } =
    props;
  const [answers, setAnswers] = useState(null);

  useEffect(() => {
    if (data?.quiz?.problem) {
      handleInitAnswers(data);
    }
    return () => {};
  }, [data?.quizId]);

  const handleInitAnswers = (data) => {
    const { quizId, quiz } = data;
    const { problem, digits, rows } = quiz;
    let _answers = [];
    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      _answers = [...stepQuizAnswers[0]].map((num) => Number.parseInt(num));
    } else {
      _answers = new Array(Number.parseInt(digits || 1)).fill(0);
    }
    setAnswers(_answers);
  };

  const handleColoring = (index) => {
    let _answers = [...answers];
    _answers[index] = _answers[index] === 0 ? 1 : 0;
    console.log("answers: ", _answers);
    setAnswers(_answers);
    onAnswerUpdated([_answers.join("")]);
  };

  let _rows = new Array(props?.data?.quiz?.rows || 1).fill(null);
  let _perRow = answers?.length / _rows.length;
  console.log("rows: ", _rows, _perRow);
  return (
    <div className="coloring-answer-wrap">
      {_rows.map((row, rowIndex) => (
        <div key={`row-${rowIndex}`} className="coloring-image-row">
          {answers
            ?.slice(rowIndex * _perRow, rowIndex * _perRow + _perRow)
            .map((value, index) => (
              <ColoringImage
                key={index}
                index={index + rowIndex * _perRow}
                value={value}
                onColoring={handleColoring}
                solvedData={solvedData}
                showReallyTrue={showReallyTrue}
                coloringOnImage={data?.quiz?.coloringOnImage?.signedUrl}
                coloringOffImage={data?.quiz?.coloringOffImage?.signedUrl}
              />
            ))}
        </div>
      ))}
      {solvedData?.solved && answers && (
        <>
          <div
            className={classNames("coloring-result-text", {
              "is-true": showReallyTrue || solvedData.correct,
              "is-false": !showReallyTrue && !solvedData.correct,
            })}
          >
            {showReallyTrue ? (
              <>{`정답은 ${solvedData.correctAnswers[0]}개를 색칠해야 해요.`}</>
            ) : (
              <>{`${answers.filter((a) => a === 1).length}개를 색칠했어요.`}</>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ColoringQuiz;
