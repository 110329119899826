import React from "react";
import CharactorAnimation from "./animations/CharactorAnimation";
import "./storytelling-animation.scss";
import board from "./img/bg-board.svg"
import classNames from "classnames";

const StorytellingAnimation = ({isQueture, title}) => {
  return (
    <div id="storytellingAnimation">
      <div className={classNames("img-title", {
        "is-queture": isQueture
      })}>
      </div>
      <div className="board-wrap">
        <img src={board} alt="" />
        <p className="text">{title}</p>
      </div>
      <CharactorAnimation />
    </div>
  );
};
export default StorytellingAnimation;
