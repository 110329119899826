import React, { useState, useEffect } from "react";
import classNames from "classnames";

const MiniCalendar = props => {
  const {
    dateRows,
    curWeekIndex,
    curYear,
    curMonth,
    onPrevMonth,
    onNextMonth,
    onChangeWeekIndex
  } = props;

  useEffect(() => {
    return () => {};
  }, [curMonth, curYear]);

  return (
    <div className="mini-calendar">
      <div className="title">
        <span className="prev" onClick={onPrevMonth}></span>
        <span className="date">{`${curYear}년 ${curMonth}월`}</span>
        <span className="next" onClick={onNextMonth}></span>
      </div>
      <table>
        <tbody>
          <tr>
            <th>일</th>
            <th>월</th>
            <th>화</th>
            <th>수</th>
            <th>목</th>
            <th>금</th>
            <th>토</th>
          </tr>
          {dateRows &&
            dateRows.map((r, i) => (
              <tr
                key={i}
                className={classNames("date-row", {
                  "is-weekly": i === curWeekIndex
                })}
                onClick={() => onChangeWeekIndex(i)}
              >
                {r}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default MiniCalendar;
