import React from "react";
import { useMouse } from "react-use";
import LectureTooltip from "./LectureTooltip";
import HomeworkTooltip from "./HomeworkTooltip";

const HomeworkProgress = props => {
  const { homework } = props;
  const ref = React.useRef(null);
  const { docX, docY, posX, posY, elX, elY, elW, elH } = useMouse(ref);

  if (!homework) {
    return null;
  }

  return (
    <div className="wrap" ref={ref}>
      <p>
        <span>과제</span>
      </p>
      <div className="time">{homework.endDate}</div>
      <p>
        깨처 복습 <span>{homework.quetureHomework ? "완료" : "진행중"}</span>
      </p>
      {homework.uploadList && homework.uploadList.length > 0 && (
        <p>
          학습 미션 <span>{homework.uploadHomework ? "완료" : "진행중"}</span>
        </p>
      )}
      {homework && <HomeworkTooltip homework={homework} top={elY} left={elX} />}
    </div>
  );
};

export default HomeworkProgress;
