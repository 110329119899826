import React, { Component } from "react";
import { Modal } from "antd";

class ReconnectSockPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Modal {...this.props}>
        <div>자동 연결중입니다...</div>
      </Modal>
    );
  }
}

export default ReconnectSockPopup;
