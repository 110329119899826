import React, { Component } from "react";
import ReportModal from "./ReportModal";
import { getHost, getDefaultConfig, parseAxiosError } from "utils/APIUtils";
import axios from "axios";
import Toast from "components/Toast/Toast";
import { copyToClipboard } from "utils/utils";

class ReportButton extends Component {
  _mounted = false;

  state = {
    opened: false
  };

  componentDidMount() {
    this._mounted = true;
  }

  report = () => {
    const { quizData, answers, onCompleted } = this.props;

    this.setState({
      opened: true
    });
  };

  close = () => {
    this.setState({
      opened: false
    });
  };

  onSendReport = (categoryList, text) => {
    const { quizData, quizAnswers } = this.props;

    this.setState({
      pending: true
    });

    const quiz = {
      quizId: quizData.quizId,
      answers: quizAnswers,
      vars: quizData.quiz.vars
    };

    const questionData = {
      category1: "기타문의",
      category2: "문제오류",
      text: categoryList.join("\n") + "\n" + text,
      quiz: quiz
    };

    const host = getHost();

    axios
      .post(`${host}/user/v2/questions`, questionData, {
        ...getDefaultConfig()
      })
      .then(response => {
        if (!this._mounted) {
          return;
        }

        this.setState({
          pending: false
        });

        this.close();
        Toast.success(
          "<문제오류신고>가 정상적으로 접수되었습니다. <br> 질문/답변함을 통해 답변드리도록 하겠습니다."
        );
      })
      .catch(error => {
        if (!this._mounted) {
          return;
        }
        Toast.warning("접수에 실패했습니다. 잠시 후 다시 시도해주세요.");

        this.setState({
          pending: false,
          error: parseAxiosError(error, "favorites")
        });
      });
  };

  render() {
    const { opened, pending, markedType } = this.state;
    const { quizData, solved, learnType } = this.props;

    return (
      <>
        <div
          // onDoubleClick={() => copyToClipboard(quizData.quizId)}
          className="btn-error-report"
        >
          <div className="error-report">
            <span className="text">문제에 오류가 있는것 같아요.</span>
            <span className="error" onClick={this.report}>
              <span className="title-img"></span>
              문제오류신고
            </span>
          </div>
        </div>
        <ReportModal
          isOpen={opened}
          pending={pending}
          onClose={this.close}
          onSendReport={this.onSendReport}
        />
      </>
    );
  }
}

export default ReportButton;
