import React from 'react';

const NoteChk = ({ strokeColor }) => {
  return (
    <>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.82544 11.3231L9.06647 14.5642L16.0408 7.5898" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  );
};

export default NoteChk;