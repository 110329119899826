import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AuthRouter from "../../router/AuthRouter";
import { authorizedRoutes, normalRoutes } from "../../router/routes";
import NotFound from "../NotFound";
import AdminLayout from "../../layouts/AdminLayout";
import BasicLayout from "../../layouts/BasicLayout";

const propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const Router = ({ user, type }) => {
  console.log("authrouter: ", user);

  return (
    <div>Router</div>
    // <AuthRouter
    //   authorities={type || ""}
    //   authorizedRoutes={authorizedRoutes}
    //   authorizedLayout={AdminLayout}
    //   normalRoutes={normalRoutes}
    //   normalLayout={BasicLayout}
    //   notFound={NotFound}
    // />
  );
};

Router.propTypes = propTypes;

export default connect(
  ({ auth }) => ({
    logged: auth.logged,
    user: auth.user,
    type: auth.user.type
  }),
  null
)(Router);
