import axios from "axios";
import classNames from "classnames";
import React from "react";
import { isIOS } from "react-device-detect";
import { isIPadSafari } from "utils/utils";
import screenfull from "screenfull";
import { getDefaultConfig, getHost } from "utils/APIUtils";
import { actionLog } from "utils/LogUtils";

class VanilaGamePlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSoundOn: true,
      isFullscreen: false,
      goHome: false,
    };
  }

  onGameCompleted = (gameName, subType, score, time, callback) => {
    const { onComplete, isInLearningPack } = this.props;

    if (!isInLearningPack) {
      if (onComplete) {
        onComplete(gameName, subType, score, time, callback);
      } else {
        const url = subType
          ? `${getHost()}/academy/v2/hometraining/games/${gameName}/score?sub=${subType}`
          : `${getHost()}/academy/v2/hometraining/games/${gameName}/score`;
        actionLog("playground", "onGameCompleted", {
          gameName: this.props.title,
          score: score,
          time: time,
        });
        axios
          .put(
            url,
            { score, time },
            {
              ...getDefaultConfig(),
            }
          )
          .then((r) => {
            if (callback) {
              callback(r.data);
            }
          })
          .catch((e) => {
            console.error("failed to set score", e);
          });
      }
    }
  };

  loadRanking = (gameName, subType, callback) => {
    if (this.props.loadRanking) {
      this.props.loadRanking(gameName, subType, callback);
      return;
    }

    const url = subType
      ? `${getHost()}/academy/v2/hometraining/games/${gameName}/ranking?sub=${subType}`
      : `${getHost()}/academy/v2/hometraining/games/${gameName}/ranking`;

    axios
      .get(url, {
        ...getDefaultConfig(),
      })
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      })
      .catch((error) => {
        console.log("failed to get ranking data", error);
      });
  };

  loadState = (gameName, subType, callback) => {
    const url = subType
      ? `${getHost()}/academy/v2/hometraining/games/${gameName}/state?sub=${subType}`
      : `${getHost()}/academy/v2/hometraining/games/${gameName}/state`;

    axios
      .get(url, {
        ...getDefaultConfig(),
      })
      .then((response) => {
        if (callback) {
          callback(response.data.data);
        }
        return response.data.data;
      })
      .catch((error) => {
        console.log("failed to get game state", error);
      });
  };

  saveState = (gameName, subType, data, callback) => {
    const url = subType
      ? `${getHost()}/academy/v2/hometraining/games/${gameName}/state?sub=${subType}`
      : `${getHost()}/academy/v2/hometraining/games/${gameName}/state`;

    axios
      .put(
        url,
        { data: data },
        {
          ...getDefaultConfig(),
        }
      )
      .then((response) => {
        if (callback) {
          callback(response.data.data);
        }
        return response.data.data;
      })
      .catch((error) => {
        console.log("failed to get game state", error);
      });
  };

  toggleVolume = (isSoundOn) => {
    this.setState(
      {
        isSoundOn: isSoundOn,
      },
      () => { }
    );
  };

  toggleFullscreen = (isFullscreen) => {
    console.log("toggle fullscreen: ", isFullscreen);
    this.setState(
      {
        isFullscreen: isFullscreen,
      },
      () => { }
    );
  };

  handleHome = (goHome) => {
    console.log("handleHome: ", goHome);
    this.setState(
      {
        goHome: goHome,
      },
      () => { }
    );
  };

  onFullscreenChanged = (e) => {
    const isFullscreen = screenfull.isFullscreen;

    console.log("fullscreen state", isFullscreen);

    this.setState({
      isFullscreen: isFullscreen,
    });
  };

  componentDidMount() {
    if (screenfull.enabled) {
      screenfull.on("change", this.onFullscreenChanged);

      // const { screen } = window;
      // if (screen.orientation && isMobileOnly) {
      //   screen.orientation.addEventListener("change", this.onScreenRotation);
      // }
    }
    actionLog("playground", "startGame", {
      gameName: this.props.title,
    });
  }
  componentWillUnmount() {
    if (screenfull.enabled) {
      screenfull.off("change", this.onFullscreenChanged);

      // const { screen } = window;
      // if (screen.orientation) {
      //   screen.orientation.removeEventListener("change", this.onScreenRotation);
      // }
    }
  }

  render() {
    const { component, gameName, title } = this.props;
    const width = this.props.width ? this.props.width : "1080px";
    const height = this.props.height ? this.props.height : "675px";

    console.log('this');

    const Component = component;

    // if (this.state.data == null) {
    //     return <FullLoading />
    // }
    let gameContentStyle = {};
    let gameWrapStyle = {};
    if (this.state.isFullscreen) {
      gameContentStyle = {
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        margin: 0,
        position: "fixed",
      };
      gameWrapStyle = {
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        margin: 0,
        position: "absolute",
      };
    }
    return (
      <div className="game-content" style={gameContentStyle}>
        <div className="game-title">
          <span>{title}</span>
        </div>

        <div
          className={classNames("game-wrapper", {
            "is-fullscreen": this.state.isFullscreen,
          })}
          style={gameWrapStyle}
        >
          <div className="control">
            {!this.props.isInLearningPack && (
              <span
                className="home"
                onClick={() => {
                  this.setState({ goHome: true });
                }}
              ></span>
            )}
            <span
              onClick={() => this.toggleVolume(!this.state.isSoundOn)}
              className={classNames("volume", {
                "is-off": this.state.isSoundOn,
              })}
            ></span>
            <span
              className="close"
              onClick={() => this.props.onClose("vanila")}
            ></span>
            {!(isIOS || isIPadSafari()) && (
              <span
                onClick={() => this.toggleFullscreen(!this.state.isFullscreen)}
                className={classNames("zoom", {
                  "is-zoomin": this.state.isFullscreen,
                })}
              ></span>
            )}
          </div>
          <Component
            onComplete={this.onGameCompleted}
            loadRanking={this.loadRanking}
            loadState={this.loadState}
            saveState={this.saveState}
            // onToggleVolume={this.toggleVolume}
            isSoundOn={this.state.isSoundOn}
            isFullscreen={this.state.isFullscreen}
            onToggleFullscreen={this.toggleFullscreen}
            onHandleHome={this.handleHome}
            goHome={this.state.goHome}
            onClose={this.props.onClose}
            isInLearningPack={this.props.isInLearningPack}
            clearScore={this.props.clearScore}
            onClear={this.props.onClear}
            isAcademy={this.props.isAcademy}
            onScoreUpdate={this.props.onScoreUpdate}
          />
          {/* <div style={{ width: '100%', height: '100%', margin: 'auto', borderRadius: '8px', overflow: 'hidden' }}>
          </div> */}
        </div>
      </div>
    );
  }
}

export default VanilaGamePlayer;
