import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

const ColoringImage = (props) => {
  const {
    onColoring,
    value,
    index,
    solvedData,
    showReallyTrue,
    correctAnswer,
    coloringOnImage,
    coloringOffImage,
  } = props;

  // const [isHovered, setIsHovered] = useState(false); // 定义state，记录鼠标是否悬停
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 }); // 定义state，记录图像大小
  const imageRef = useRef(null); // 创建ref，用于获取图像大小

  // 获取图像大小并设置宽度和高度
  useEffect(() => {
    if (imageRef.current) {
      setImageSize({
        width: imageRef.current.width,
        height: imageRef.current.height,
      });
    }
  }, []);

  // 处理鼠标进入事件
  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // // 处理鼠标离开事件
  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  let _imgSrc = value === 1 ? coloringOnImage : coloringOffImage;
  if (showReallyTrue) {
    const correctAnswer = Number.parseInt(solvedData?.correctAnswers[0]);
    if (index < correctAnswer) {
      _imgSrc = coloringOnImage;
    } else {
      _imgSrc = coloringOffImage;
    }
  }
  // if(isHovered) {
  //   _imgSrc = coloringOnImage;
  // }
  console.log("coloring image value: ", value);
  return (
    <div
      className={classNames("coloring-image", {
        // "is-hover": isHovered
      })}
      // style={{ width: imageSize.width, height: imageSize.height }}
      onClick={() => onColoring(index)}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
    >
      <img src={_imgSrc} alt="img" className="image" ref={imageRef} />
      {/* <span>{value + "-" + index}</span> */}
    </div>
  );
};

export default ColoringImage;
