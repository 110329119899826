import React, { Component } from "react";
// import Modal from './Modal';
import classNames from "classnames";
import Popup from "components/Popup/Popup";
import Toast from "components/Toast/Toast";
import update from "immutability-helper";

class ReportModal extends Component {
  state = {
    text: "",
    categoryList: [
      {
        selected: false,
        name: "답이 틀린 것 같아요"
      },
      {
        selected: false,
        name: "문제 이해가 어려워요"
      },
      {
        selected: false,
        name: "오타가 있어요"
      },
      {
        selected: false,
        name: "풀이가 틀렸어요"
      },
      {
        selected: false,
        name: "기타"
      }
    ],
    sending: false
  };

  handleSendReport = () => {
    const { onSendReport } = this.props;
    const { categoryList, text } = this.state;

    const selectedCategories = [];
    let selectedOther = false;
    categoryList.map(c => {
      if (c.selected) {
        selectedCategories.push(c.name);
        if (c.name === "기타") {
          selectedOther = true;
        }
      }
    });

    if (selectedCategories.length === 0) {
      Toast.warning("이 문제의 오류를 꼭 선택해 주세요");
      return;
    }

    const _text = text.trim();
    if (selectedOther && !_text && _text.length < 1) {
      Toast.warning("이 문제의 오류를 꼭 입력해 주세요");
      return;
    }

    onSendReport(selectedCategories, text);
  };

  toggleCategory = (i, isSelected) => {
    this.setState({
      categoryList: update(this.state.categoryList, {
        [i]: {
          selected: {
            $set: isSelected
          }
        }
      })
    });
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { categoryList } = this.state;

    return (
      <Popup type="question" isOpen={isOpen}>
        <div className="popup-content-wrap">
          <div className="popup-title">문제오류신고</div>
          <div className="popup-closebtn" onClick={onClose}></div>
          <p>이 문제를 신고한 이유를 꼭 선택해 주세요.</p>
          <ul>
            {categoryList.map((c, i) => {
              return (
                <li
                  key={i}
                  className={classNames({ "is-chk": c.selected === true })}
                  // onClick={() => this.setState({ category: c })}
                  onClick={() => this.toggleCategory(i, !c.selected)}
                >
                  <span>{c.name}</span>
                </li>
              );
            })}
          </ul>
          <textarea
            value={this.state.text}
            onChange={e => this.setState({ text: e.target.value })}
            cols="30"
            rows="10"
            placeholder="어떤 점이 이상한가요? 의견을 자세하게 써주세요."
          ></textarea>
        </div>
        <div className="btn-wrap">
          <div>
            <button
              type="button"
              className={classNames("is-chk", {
                "none-event": this.props.pending
              })}
              onClick={this.handleSendReport}
            >
              보내기
            </button>
          </div>
        </div>
      </Popup>
    );
  }
}

export default ReportModal;
