import React from 'react'
import { Image } from "../modules";

const QuizImage = ({ image, isMobileSize, imageMobile, anim, textsOnImage }) => {
  if (!image) {
    return null;
  }
  return (
    <div className="quiz-content__content-img">
      <Image image={image} imageMobile={imageMobile} frames={anim} texts={textsOnImage} isMobileSize={isMobileSize} />
    </div>
  )
}

export default QuizImage;
