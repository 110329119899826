import React, { Component } from "react";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { getWeekdayName } from "../../../../../utils/utils";
import { filter, mapKeys, orderBy, remove, map as _map } from "lodash";
import { ConstantsContext } from "utils/ConstantsContext";

export class ClassTable extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      defaultPageSize: 10,
      currentPage: 1,
    };
  }

  onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    this.setState({
      currentPage: pagination.current,
    });
  };

  render() {
    const { isFinishedList, hasGuidePermission } = this.props;
    console.log("hasGuidePermission: ", hasGuidePermission);

    const checkNewDays = 1000 * 60 * 60 * 24 * 1;
    let columns = [
      /*
      {
        title: '#',
        dataIndex: "name",
        key: "index",
        render: (name, record, index) => {
          return <span>{(currentPage - 1) * defaultPageSize + index + 1}</span>;
        },
      },
      */
      {
        title: "코스",
        dataIndex: "courseTitle",
        key: "cource",
        sorter: (a, b) => a.courseTitle.localeCompare(b.courseTitle),
        defaultSortOrder: "ascend",
        filters: this.context.type === "campus" && [
          { text: "A코스", value: "A" },
          { text: "B코스", value: "B" },
          { text: "C코스", value: "C" },
          { text: "D코스", value: "D" },
          { text: "E코스", value: "E" },
          { text: "기타", value: "-" },
        ],
        onFilter: (value, record) =>
          record.courseTitle
            ? record.courseTitle.startsWith(value) ||
              (value === "-" &&
                !"ABCDE".includes(record.courseTitle.substring(0, 1)))
            : value === "-",
        render: (courseTitle) => {
          return <span style={{ fontWeight: "bold" }}>{courseTitle}</span>;
        },
      },
      {
        title: "수업회차",
        dataIndex: "currentLecture",
        key: "currentLecture",
        render: (text, record, index) => {
          return <span>{text || "-"}</span>;
        },
      },
      {
        title: `${this.context.className}`,
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record, index) => (
          <Link
            to={`/admin/class/detail/${record.classroomId}`}
            style={{ textDecoration: "underline" }}
          >
            {record.createdTime &&
              new Date() < new Date(record.createdTime + checkNewDays) && (
                <Tag color="#108ee9">New</Tag>
              )}
            <span>{text}</span>
          </Link>
        ),
      },
      {
        title: `지점`,
        dataIndex: "organization.name",
        key: "organization.name",
      },
      {
        // NOTE: record.state might be non-'finish' with isFinisied=true
        title: "상태",
        dataIndex: "state",
        key: "state",
        render: (state, record) =>
          state === "deleted" ? (
            <span style={{ color: "red" }}>(삭제)</span>
          ) : record.isFinished ? (
            <span style={{ color: "blue" }}>*종료*</span>
          ) : state === "finish" ? (
            <span style={{ color: "blue" }}>*강제종료*</span>
          ) : state === "current" ? (
            <span>현행</span>
          ) : (
            <span>?({state})</span>
          ),
      },
      {
        title: "진행현황",
        dataIndex: "completedLectures",
        key: "completedLectures",
        sorter: (a, b) => a.completedLectures - b.completedLectures,
        render: (text, record, index) =>
          record.state !== "deleted" && record.isFinished ? (
            <span style={{ color: "blue" }}>
              *{`${text}회차 / 총${record.totalLectures}회차`}*
            </span>
          ) : (
            <span>{`${text}회차 / 총${record.totalLectures}회차`}</span>
          ),
      },
      {
        title: "수업 스케줄",
        dataIndex: "days",
        key: "days",
        filters: [
          { text: "월요일", value: "mon" },
          { text: "화요일", value: "tue" },
          { text: "수요일", value: "wed" },
          { text: "목요일", value: "thu" },
          { text: "금요일", value: "fri" },
          { text: "토요일", value: "sat" },
          { text: "일요일", value: "sun" },
        ],
        onFilter: (value, record) => _map(record.days, "day").includes(value),
        render: (days) => (
          <span>
            {days &&
              days.map((day, key) => {
                return (
                  <Tag color="blue" key={key}>
                    {`${getWeekdayName(day.day)} ${day.time}`}
                  </Tag>
                );
              })}
          </span>
        ),
      },
      {
        title: "시작일",
        dataIndex: "startDate",
        key: "startDate",
        sorter: (a, b) => a.startDate.localeCompare(b.startDate),
        render: (text, record) =>
          record.state === "deleted" ? (
            <span style={{ color: "red" }}>({text})</span>
          ) : (
            <span>{text}</span>
          ),
      },
      {
        title: "종료/예정일",
        dataIndex: "endDate",
        key: "endDate",
        sorter: (a, b) => a.endDate.localeCompare(b.endDate),
        render: (text, record) =>
          record.state === "deleted" ? (
            <span style={{ color: "red" }}>({text})</span>
          ) : record.isFinished ? (
            <span style={{ color: "blue" }}>*{text}*</span>
          ) : (
            <span>{text}</span>
          ),
      },
      {
        title: "학생수",
        dataIndex: "studentsCount",
        key: "studentsCount",
        sorter: (a, b) => a.studentsCount - b.studentsCount,
        render: (studentsCount, record, index) => (
          <>
            {studentsCount > 0 ? (
              <Link
                to={`/admin/class/detail/${record.classroomId}`}
                style={{ textDecoration: "underline" }}
              >
                <span>{studentsCount}명</span>
              </Link>
            ) : (
              <span>{studentsCount}명</span>
            )}
          </>
        ),
      },
      {
        title: `${this.context.guideName}`,
        dataIndex: "guide",
        key: "guide",
        sorter: (a, b) =>
          a.guide
            ? b.guide
              ? a.guide.name.localeCompare(b.guide.name)
              : 1
            : b.guide
            ? -1
            : 0,
        render: (guide) => (
          <>
            {guide ? (
              hasGuidePermission ? (
                <Link to={`/admin/guide/detail/${guide.guideId}`}>
                  <span>{guide.name}</span>
                </Link>
              ) : (
                <span>{guide.name}</span>
              )
            ) : (
              <font color="red">({this.context.guideName} 없음)</font>
            )}
          </>
        ),
      },
    ];

    let data = this.props.classroomList;
    if (this.props.todayOnly) {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();

      const todayStr =
        year +
        "-" +
        (month >= 10 ? month : "0" + month) +
        "-" +
        (day >= 10 ? day : "0" + day);

      data = filter(this.props.classroomList, function (o) {
        const _lectures = mapKeys(o.lectures, function (value, key) {
          return key.split(" ")[0];
        });
        const lectureKeys = Object.keys(_lectures);
        const hasTodayLectures = lectureKeys.includes(todayStr);

        return hasTodayLectures ? true : false;
      });
      console.log("todayOnly: ", data);
    }

    if (this.props.searchName) {
      const name = this.props.searchName.toLowerCase();
      data = filter(
        data,
        (c) =>
          c.name &&
          c.name.toLowerCase().includes(name) &&
          c.state === "current" &&
          !c.isFinished
      );
    } else {
      if (this.props.finishedOnly) {
        data = filter(data, (c) => c.isFinished || c.state === "finish");
      }
      if (this.props.showDeleted) {
        data = filter(data, (c) => c.state === "deleted");
      } else {
        data = filter(data, (c) => c.state !== "deleted");
      }
    }

    if (this.props.sortType) {
      const _sortType = this.props.sortType;
      if (_sortType === "startDate") {
        data = orderBy(data, ({ startDate }) => new Date(startDate) || "", [
          "asc",
        ]);
      } else if (_sortType === "endDate") {
        data = orderBy(data, ({ endDate }) => new Date(endDate) || "", ["asc"]);
      }
    }

    if (isFinishedList) {
      columns = columns.filter((item) => !(item.title === "진행현황"));
    }

    console.log("orgtype: ", this.props.orgType);
    if (this.props.orgType === "school") {
      columns = remove(columns, (item) => item.key !== "days");
      console.log("col: ", columns);
    }

    console.log("data: ", this.props.classroomList);
    //    pagination={{
    //      defaultPageSize: this.state.defaultPageSize,
    //    }}
    return (
      <Table
        rowKey="classroomId"
        dataSource={data}
        columns={columns}
        pagination={{
          defaultPageSize: this.state.defaultPageSize,
        }}
        onChange={this.onChange}
      />
    );
  }
}

export default ClassTable;
