import { Button } from "antd";
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

const QbPlayer = (props) => {
  const { src, viewType, controls } = props;

  const [playing, setPlaying] = useState(false);

  const videoRef = useRef(null);

  let listenChannel = null;
  if (window.BroadcastChannel) {
    listenChannel = new BroadcastChannel("REMOCON_CHANNEL");
  }

  useEffect(() => {
    console.log("use effect");
    if (window.BroadcastChannel) {
      // let listenChannel = new BroadcastChannel("REMOCON_CHANNEL");
      listenChannel.addEventListener("message", handleRemocon);
    }

    return () => {
      if (window.BroadcastChannel) {
        // let listenChannel = new BroadcastChannel("REMOCON_CHANNEL");
        listenChannel.removeEventListener("message", handleRemocon);
      }
    };
  }, []);

  const replay = () => {
    console.log("replay: ", videoRef);
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };

  const togglePlay = (type) => {
    console.log("playing: ", playing);

    if (type === "play") {
      videoRef.current.play();
    } else if (type === "pause") {
      videoRef.current.pause();
    }
  };

  const fastForward = () => {
    videoRef.current.currentTime += 5;
  };

  const revert = () => {
    videoRef.current.currentTime -= 5;
  };

  const handleRemocon = (res) => {
    const type = res.data && res.data.type;
    const value = res.data && res.data.value;
    console.log("remocon type: ", type);
    console.log("remocon value: ", value);
    if (!type) {
      return null;
    }

    switch (type) {
      case "play":
        togglePlay("play");
        break;
      case "pause":
        togglePlay("pause");
        break;
      case "replay":
        replay();
        break;
      default:
        break;
    }
  };

  return (
    <div className="player-container">
      {viewType === "preview" && (
        <video
          width={"100%"}
          ref={videoRef}
          src={src}
          controls={controls}
          controlsList="nodownload"
        />
      )}
      {/* <ReactPlayer url={src} playing={playing} 
      onPlay={() => console.log("on play")}
      onPause={() => console.log("on pause")}
      onEnded={() => console.log("on ended")}
      
      /> */}
    </div>
  );
};
export default QbPlayer;
