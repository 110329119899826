import React, { useState, useEffect } from "react";


export const circledNumbers = [ <>&#9450;</>, <>&#9312;</>, <>&#9313;</>, <>&#9314;</>, <>&#9315;</>, <>&#9316;</>, <>&#9317;</>, <>&#9318;</>, <>&#9319;</>, <>&#9320;</> ];

export function getQuizRandomSeed(lectureId, quizSetIndex, quizIndex) {
  return (lectureId % 7251031) * 1901 + quizSetIndex * 51031 + quizIndex;
}

export function getCourseTitle(courseTitle) {
  const s = String(courseTitle); 
  return (s.substring(1,2) === '0' ? s.substring(0,2) : s.substring(0,1)) + '코스';
}

export function getCourseSymbol(courseTitle) {
  // lower case for courseTiltle's first character;
  switch (String(courseTitle).substring(0,1).toLowerCase()) {
  case 'a': return 'a';
  case 'b': return 'b';
  case 'c': return 'c';
  case 'd': return 'd';
  case 'e': return 'e';
  case 'f': return 'f';
  case 't': return 't';
  default: return 'default';
  }
}

export function getCourseColor(courseTitle) {
  switch (getCourseSymbol(courseTitle)) {
  case 'a' : return '#e9c947';
  case 'b' : return '#67b767';
  case 'c' : return '#fa84ad';
  case 'd' : return '#1bc3cc';
  case 'e' : return '#ed964e';
  case 'f' : return '#8a4a8b';
  case 't' : return '#d70051';
  default: return 'gray';
  } 
}

