import React from "react";
import classNames from "classnames";
import { filter } from "lodash";
import { formateTime, getProfileUrl } from "../../../utils/utils";
import { orderBy, values, mapValues } from "lodash";
import DefaultPofile from "./img/default_profile.svg";

import "./_component.scss";
import Audio from "../../Audio/Audio";

const AttendanceList = props => {
  const { attendances, studentList, viewType } = props;

  console.log(attendances);
  console.log(studentList);
  if (!attendances || !studentList) {
    return null;
  }

  console.log(
    orderBy(
      values(
        mapValues(attendances, function(value, key) {
          value.studentId = key;
          return value;
        })
      ),
      ["timestamp"],
      ["asc"]
    )
  );

  return (
    // {/* li가 4~5개 일때 (1줄)  childmin
    //       li가 6개 일때(3,3) child6
    //       li가 7개, 8개 일때(4,3. 4,4) child7
    //       li가 9개, 10개 일때(5,4. 5,5) child9
    //       li가 11개, 12개 일때  '추가 클래스 없음'
    //       출석체크 전 기본 not-attendance 클래스이고 li는 12개
    //   */}
    <ul
      className={classNames(
        `attendancelist-wrap view-${Math.ceil(
          Object.keys(attendances).length / 2
        )}`,
        {
          childmin: Object.keys(attendances).length <= 4
        }
      )}
    >
      {orderBy(
        values(
          mapValues(attendances, function(value, key) {
            value.studentId = key;
            return value;
          })
        ),
        ["timestamp"],
        ["asc"]
      ).map((attendance, index) => {
        const student = filter(studentList, {
          studentId: attendance.studentId
        })[0];
        console.log(student);

        const _profileImage =
          (student.profileImage &&
            getProfileUrl(student.profileImage.contentId)) ||
          DefaultPofile;
        // attendce type: ok, late, no
        return (
          <li
            key={index}
            className={classNames("attendancelist effect", {
              "not-attendance": attendance.status === "no",
              "is-attend": attendance.status === "ok",
              "is-late": attendance.status === "late"
            })}
          >
            <div className="null-wrap">
              <div className="img">
                <img src={_profileImage} alt="" />
              </div>
              <div className="attendancelist__name">{student.name}</div>
              {attendance.status !== "no" && (
                <div className="attendancelist__time">
                  <span>{formateTime(new Date(attendance.timestamp))}</span>
                </div>
              )}
            </div>
            <div className="attendance-wrap">
              <div className="img">
                <img src={_profileImage} alt="" />
              </div>
              <div className="attendancelist__name">{student.name}</div>
              {attendance.status !== "no" && (
                <div className="attendancelist__time">
                  <span>{formateTime(new Date(attendance.timestamp))}</span>
                  {viewType === "preview" && <Audio type={"attendance"} />}
                </div>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default AttendanceList;
