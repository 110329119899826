import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from "body-scroll-lock";
import classNames from "classnames";
import debounce from "lodash.debounce";
import noScroll from "no-scroll";
import React, { Component } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { isIPadSafari } from "utils/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as scrollActions from "store/modules/scroll";
import PairedAnswer from "./PairedAnswer";

const isMobileDevice = isAndroid || isIOS || isIPadSafari();

//TODO responsive
const dotOffset = 30;

class PairingArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 200,
      drawing: false,
      over: {},
      start: {},
      cursor: {},
      lines: [],
      coords: [],
      svgheight: 500,
      svgWidth: 200,
      mobileSelectLeft: null,
      clientHeight: 50,
      leftClientHeight: 50,
      rightClientHeight: 50,
      clientMargin: 50,
      isMobileSize: window.innerWidth <= 768,
      circleRadius: isMobileDevice ? 16 : 12,
      scrollTopPos: 0
    };

    this.updateClientSize = debounce(this.updateClientSize, 200);
    // this.handleScroll = debounce(this.handleScroll, 100);
    this.targetRef = React.createRef();
    this.targetElement = null;
  }

  componentDidMount() {
    const { left, right } = this.props;

    const leftCoords = left.map((d, i) => ({
      x: this.x1(i),
      y: this.y1(i)
    }));

    const rightCoords = right.map((d, i) => ({
      x: this.x2(i),
      y: this.y2(i)
    }));
    this.setState({
      leftCoords: leftCoords,
      rightCoords: rightCoords
    });
    window.addEventListener("resize", this.updateClientSize);
    this.updateClientSize();
    // this.targetElement && this.targetElement.addEventListener('touchmove', this.touchMove, { passive: false })
    // this.targetElement && this.targetElement.addEventListener('touchmove', this.touchMove, { passive: false })
    // window.addEventListener("scroll", this.handleScroll);
  }

  componentWillMount() {
    this.updateClientSize();
  }

  componentWillUnmount() {
    const { ScrollActions } = this.props;
    noScroll.off();
    clearAllBodyScrollLocks();
    ScrollActions.handleQuizContentScroll(false);
    window.removeEventListener("resize", this.updateClientSize);
    // window.removeEventListener("scroll", this.handleScroll);
    // this.targetElement.removeEventListener('touchmove', this.touchMove, false)
    // this.targetElement.removeEventListener('touchmove', this.touchMove, false)
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    // this.setState({
    //   scrollTopPos: currentScrollPos
    // })
    console.log(currentScrollPos);
  };

  updateCoords = () => {
    const { left, right } = this.props;

    const leftCoords = left.map((d, i) => ({
      x: this.x1(i),
      y: this.y1(i)
    }));

    const rightCoords = right.map((d, i) => ({
      x: this.x2(i),
      y: this.y2(i)
    }));
    this.setState({
      leftCoords: leftCoords,
      rightCoords: rightCoords
    });
  };

  updateClientSize = () => {
    const { left, right } = this.props;
    const svgContent = document.getElementById("svgContent");
    const maxBoxCount = left.length > right.length ? left.length : right.length;

    const checkIsMobile = window.innerWidth <= 768;
    if (checkIsMobile !== this.state.isMobileSize) {
      this.setState({
        isMobileSize: checkIsMobile,
        circleRadius: isMobileDevice ? 16 : 12
      });
    }

    const elementArr = [...document.getElementsByClassName("box")];
    const elementLength = elementArr.length;
    let maxHeightElement = elementArr[0];
    console.log("elemArr: ", elementArr);
    elementArr.forEach(el => {
      if (el.clientHeight > maxHeightElement.clientHeight) {
        maxHeightElement = el;
      }
    });

    const leftBoxes = [...document.getElementsByClassName("l-box")];
    const leftBoxesCount = leftBoxes.length;
    let leftMaxHeightBox = leftBoxes[0];
    leftBoxes.forEach(el => {
      if (el.clientHeight > leftMaxHeightBox.clientHeight) {
        leftMaxHeightBox = el;
      }
    });

    const rightBoxes = [...document.getElementsByClassName("r-box")];
    const rightBoxesCount = rightBoxes.length;
    let rightMaxHeightBox = rightBoxes[0];
    rightBoxes.forEach(el => {
      if (el.clientHeight > rightMaxHeightBox.clientHeight) {
        rightMaxHeightBox = el;
      }
    });

    if (elementLength > 0 && leftBoxesCount > 0 && rightBoxesCount > 0) {
      // calc margin
      const element = [...document.getElementsByClassName("box")][
        elementLength - 1
      ];
      const margin = window
        .getComputedStyle(element)
        .getPropertyValue("margin-top");
      const marginCalc = Number.parseInt(
        margin.substring(0, margin.length - 2)
      );

      // calc svg
      const svgWidth = window
        .getComputedStyle(svgContent)
        .getPropertyValue("width");
      const svgWidthCalc = Number.parseInt(
        svgWidth.substring(0, svgWidth.length - 2)
      );

      //calc height
      const height = window
        .getComputedStyle(maxHeightElement)
        .getPropertyValue("height");
      const heightCalc = Number.parseInt(
        height.substring(0, height.length - 2)
      );

      const leftHeight = window
        .getComputedStyle(leftMaxHeightBox)
        .getPropertyValue("height");
      const leftHeightCalc = Number.parseInt(
        leftHeight.substring(0, leftHeight.length - 2)
      );
      const rightHeight = window
        .getComputedStyle(rightMaxHeightBox)
        .getPropertyValue("height");
      const rightHeightCalc = Number.parseInt(
        rightHeight.substring(0, rightHeight.length - 2)
      );

      this.setState(
        prevState => {
          console.log("prevState: ", prevState);
          // if (
          //   prevState.clientHeight !== heightCalc ||
          //   prevState.clientMargin !== marginCalc ||
          //   prevState.leftClientHeight !== leftHeightCalc ||
          //   prevState.rightClientHeight !== rightHeightCalc
          // ) {
          // }
          let svgHeightCalc =
            leftHeightCalc > rightHeightCalc ? leftHeightCalc : rightHeightCalc;

          return {
            clientHeight: heightCalc,
            leftClientHeight: leftHeightCalc,
            rightClientHeight: rightHeightCalc,
            clientMargin: marginCalc,
            svgWidth: svgWidthCalc,
            svgHeight: (svgHeightCalc + marginCalc) * maxBoxCount - marginCalc
          };
        },
        () => {
          this.updateCoords();
        }
      );
    }
  };

  x = (pos, i) => {
    if (pos === "left") {
      return this.x1(i);
    } else {
      return this.x2(i);
    }
  };

  y = (pos, i) => {
    let height = null;
    if (pos === "left") {
      height = this.state.leftClientHeight;
    } else {
      height = this.state.rightClientHeight;
    }
    const margin = this.state.clientMargin;
    // return (height / 2) + (height + margin) * i;

    // const containerMargin = 10;
    // const answerHeight = 100;
    // const answerMargin = 10;
    const answerHeight = height;
    const answerMargin = margin;

    // return containerMargin + answerMargin + (answerHeight / 2) + (i * (answerHeight + (answerMargin * 2)))
    const offsetY = answerHeight / 2 + i * (answerHeight + answerMargin);
    return offsetY;
  };

  y1 = i => this.y("left", i);
  y2 = i => this.y("right", i);

  x1 = i => dotOffset;
  x2 = i => {
    return this.state.svgWidth - dotOffset;
  };

  circle = (pos, index, colorType = null) => {
    const { parentId } = this.props;
    const { drawing, start, cursor, over } = this.state;

    const x = pos === "left" ? this.x1(index) : this.x2(index);
    const y = pos === "left" ? this.y1(index) : this.y2(index);
    // const y = this.y(index);

    const src = drawing && start.pos === pos && start.index === index;
    const target =
      drawing &&
      cursor.dot != null &&
      cursor.dot.pos === pos &&
      cursor.dot.index === index;
    const isOver = over.pos === pos && over.index === index;

    const colorMap = {
      selectedColor: "#4599ff",
      trueColor: "#006a42",
      falseColor: "#ac0202"
    };

    let radius = this.state.circleRadius;
    // let color = src ? '#4599ff' : target ? '#4599ff' : '#b2b2b2';
    let color = src || target || isOver ? "#4599ff" : "#b2b2b2";
    let strokeColor = src || target || isOver ? "#4599ff" : "transparent";
    let strockWidth = radius / 1.5;

    if (colorType) {
      color = colorMap[colorType];
    }
    if (colorType === "selectedColor") {
      strokeColor = colorMap[colorType];
    }

    if (isOver) {
      strockWidth = radius * 1.5;
    }

    return (
      <g
        key={parentId + "_g_" + pos + "_" + index}
        onMouseOver={() =>
          this.setState({
            over: {
              pos: pos,
              index: index
            }
          })
        }
        onMouseOut={() =>
          this.setState({
            over: {}
          })
        }
        onMouseDown={() => this.startLine(pos, index)}
        onTouchStart={e => {
          this.startLine(pos, index, true);
        }}
      >
        <rect
          x={x - radius * 2}
          y={y - radius * 2}
          width={radius * 4}
          height={radius * 4}
          fill={"transparent"}
        />
        <circle
          key={parentId + "_cir_" + pos + "_" + index}
          id={pos + "-circle-" + index}
          cx={x}
          cy={y}
          r={radius}
          stroke={strokeColor}
          fill={color}
          strokeWidth={strockWidth}
          // onMouseOver={() => this.setState({
          //   over: {
          //     pos: pos,
          //     index: index
          //   }
          // })}
          // onMouseOut={() => this.setState({
          //   over: {}
          // })}
          // onMouseDown={() => this.startLine(pos, index)}
          // onTouchStart={(e) => { this.startLine(pos, index, true) }}
        />
      </g>
    );
  };

  getRelativePosition = e => {
    const { svgContainer } = this.refs;

    let currTargetRect = svgContainer.getBoundingClientRect();
    const x = e.clientX - currTargetRect.left;
    // const y = e.clientY - currTargetRect.top;
    // const y = e.clientY - currTargetRect.top - (isMobileDevice ? this.state.scrollTopPos : 0);
    const y =
      e.clientY -
      currTargetRect.top -
      (isMobileDevice ? window.pageYOffset : 0);

    return {
      x: x,
      y: y
    };
  };

  startLine = (pos, i, touch) => {
    const { right, answers, onLineCanceled, ScrollActions } = this.props;
    const { drawing } = this.state;

    if (drawing) {
      return;
    }

    if (touch) {
      ScrollActions.handleQuizContentScroll(true);
      noScroll.on();
      disableBodyScroll(this.targetElement);
    }

    this.setState({
      drawing: true,
      start: {
        pos: pos,
        index: i
      },
      cursor: {
        x: this.x(pos, i),
        y: this.y(pos, i)
      }
    });

    if (pos === "left") {
      onLineCanceled(i);
    } else {
      const idx = answers.findIndex(s => s != null && s.key === right[i].key);
      if (idx >= 0) {
        onLineCanceled(idx);
      }
    }
  };

  _move = (x, y) => {
    const { drawing, start, over } = this.state;
    if (!drawing) {
      return;
    }

    let currPos = {
      x: x,
      y: y
    };

    // if (over.index != null && over.pos !== start.pos) {
    //     currPos = this.findOverDot();
    // }

    const near = this.findNearDot(
      start.pos,
      x,
      y,
      this.state.circleRadius * 2.5
    );
    console.log(near);
    if (near != null) {
      currPos = near;

      this.setState(
        {
          cursor: currPos
        },
        () => {
          this.endLine();
        }
      );
    }

    this.setState({
      cursor: currPos
    });
  };

  move = e => {
    const { drawing, start, cursor } = this.state;
    if (!drawing) {
      return;
    }

    const p = this.getRelativePosition(e);
    this._move(p.x, p.y);
  };

  touchMove = e => {
    const { drawing, start, cursor } = this.state;
    if (!drawing) {
      return;
    }

    e.preventDefault();

    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0];
      const p = this.getRelativePosition(touch);
      this._move(p.x, p.y);
    }
  };

  findOverDot = () => {
    const { over, leftCoords, rightCoords } = this.state;

    if (over.pos === "left") {
      return {
        x: leftCoords[over.index].x,
        y: leftCoords[over.index].y,
        dot: over
      };
    } else {
      return {
        x: rightCoords[over.index].x,
        y: rightCoords[over.index].y,
        dot: over
      };
    }
  };

  isNear = (x, y, tx, ty, distance) => {
    const top = ty - distance;
    const left = tx - distance;
    const right = tx + distance;
    const bottom = ty + distance;
    return x > left && x < right && y > top && y < bottom;
  };

  findNearDot = (startPos, x, y, distance = 20) => {
    const { leftCoords, rightCoords } = this.state;

    if (startPos === "left") {
      const idx = rightCoords.findIndex(r =>
        this.isNear(x, y, r.x, r.y, distance)
      );

      if (idx >= 0) {
        return {
          x: rightCoords[idx].x,
          y: rightCoords[idx].y,
          dot: {
            pos: "right",
            index: idx
          }
        };
      }
    } else {
      const idx = leftCoords.findIndex(r =>
        this.isNear(x, y, r.x, r.y, distance)
      );

      if (idx >= 0) {
        return {
          x: leftCoords[idx].x,
          y: leftCoords[idx].y,
          dot: {
            pos: "left",
            index: idx
          }
        };
      }
    }
  };

  endLine = e => {
    const { onLineCompleted, ScrollActions } = this.props;
    const { drawing, start, cursor } = this.state;

    if (!drawing) {
      return;
    }

    ScrollActions.handleQuizContentScroll(false);
    noScroll.off();
    enableBodyScroll(this.targetElement);

    const near = cursor.dot;

    if (near != null) {
      const src = start.pos === "left" ? start.index : near.index;
      const dest = start.pos === "left" ? near.index : start.index;

      onLineCompleted(src, dest);
    }

    this.setState({
      drawing: false,
      start: {},
      cursor: {}
    });
  };

  onMobilePairing = (position, index) => {
    if (position === "left") {
      this.setState({
        mobileSelectLeft: index
      });
    } else {
      const { onLineCompleted } = this.props;

      const src = this.state.mobileSelectLeft;
      const dest = index;

      onLineCompleted(src, dest);
      this.setState({
        mobileSelectLeft: null
      });
    }
  };

  cancelSelect = (id, e) => {
    const { onLineCanceled } = this.props;
    onLineCanceled(id);
  };

  render() {
    const {
      parentId,
      left,
      right,
      answers,
      solvedData,
      showReallyTrue
    } = this.props;
    const { width, drawing, start, cursor, isMobileSize } = this.state;
    let answersMap = [];
    answers.map((a, i) => {
      const leftIndex = i;
      const rightIndex = a ? right.findIndex(r => r.key === a.key) : null;
      const answer = a;
      const correct =
        solvedData && solvedData.correctAnswers
          ? answer && solvedData.correctAnswers[i] === answer.key
            ? "o"
            : "x"
          : null;
      const realRight =
        solvedData && solvedData.correctAnswers
          ? solvedData.correctAnswers[i]
          : null;

      answersMap.push({
        left: leftIndex,
        right: rightIndex,
        answer: answer,
        correct: correct,
        realRight: realRight
      });
    });

    return (
      <div className="t-answer-paring">
        {answersMap.length > 0 &&
          answersMap.map((a, i) => {
            if (a.answer) {
              let realRight = null;
              if (showReallyTrue) {
                realRight = right.find((value, i) => {
                  return value.key === a.realRight;
                });
              }
              return (
                <div
                  key={i}
                  className={classNames("t-answer-paring__pair", {
                    "is-chk": !solvedData.solved,
                    "is-true": !showReallyTrue && a.correct === "o",
                    "is-false": !showReallyTrue && a.correct === "x",
                    "is-reallytrue": showReallyTrue,
                    "none-event": solvedData && solvedData.solved
                  })}
                  onClick={() => this.cancelSelect(i)}
                >
                  <PairedAnswer
                    key={parentId + "_l_a_mobile" + i}
                    id={
                      parentId +
                      "_l_a_mobile" +
                      i +
                      (showReallyTrue ? "-reallytrue" : "")
                    }
                    data={left[a.left]}
                    index={i}
                  />

                  <PairedAnswer
                    key={parentId + "_r_a_mobile" + i}
                    id={
                      parentId +
                      "_r_a_mobile" +
                      i +
                      (showReallyTrue ? "-reallytrue" : "")
                    }
                    data={showReallyTrue ? realRight : right[a.right]}
                    index={i}
                  />
                </div>
              );
            }
          })}

        <div className="t-answer-paring__left">
          {left.map((d, i) => {
            return (
              <PairedAnswer
                key={parentId + "_l_" + i}
                id={parentId + "_l_" + i}
                height={this.state.leftClientHeight}
                data={d}
                index={i}
                start={start}
                drawing={drawing}
                position={"left"}
                answersMap={answersMap}
                solvedData={solvedData}
                showReallyTrue={showReallyTrue}
                onMobilePairing={this.onMobilePairing}
                isMobileOnly={isMobileSize}
                mobileSelectLeft={this.state.mobileSelectLeft}
              />
            );
          })}
        </div>

        <div
          ref="svgContainer"
          className={classNames(
            "pairingLinesArea no-tap-delay none-touch-action",
            {
              "none-event": solvedData && solvedData.solved
            }
          )}
        >
          <svg
            id="svgContent"
            style={{ position: "relative" }}
            width={"100%"}
            height={this.state.svgHeight}
            onMouseMove={this.move}
            onTouchMove={this.touchMove}
            onMouseUp={this.endLine}
            onTouchEnd={this.endLine}
          >
            {left.map((d, i) => {
              let colorType = null;

              if (answersMap[i].answer) {
                colorType = "selectedColor";
              }
              if (solvedData && solvedData.solved && answersMap[i].answer) {
                const correct = answersMap[i].correct;
                if (correct === "o") {
                  colorType = "trueColor";
                } else {
                  colorType = "falseColor";
                }
              }

              return this.circle("left", i, colorType);
            })}
            {right.map((d, i) => {
              let colorType = null;

              const selected = answersMap.find((value, i) => {
                return value.answer && value.answer.key === d.key;
              });

              if (selected) {
                colorType = "selectedColor";
              }

              if (solvedData && solvedData.solved && selected) {
                const correct = selected && selected.correct;
                if (correct === "o") {
                  colorType = "trueColor";
                } else if (correct === "x") {
                  colorType = "falseColor";
                }
              }

              return this.circle("right", i, colorType);
            })}
            {answers.map((d, i) => {
              if (d == null) {
                return null;
              }

              const from = i;
              const to = right.findIndex(r => r.key === d.key);
              let strokeColor = "#00d3ff";

              if (solvedData && solvedData.solved) {
                if (answersMap[i].correct === "o") {
                  strokeColor = "#04c17a";
                } else {
                  strokeColor = "#ff5a5a";
                }
              }

              return (
                <line
                  key={parentId + "_line_" + from + "_" + to}
                  x1={this.x1(from)}
                  y1={this.y1(from)}
                  x2={this.x2(to)}
                  y2={this.y2(to)}
                  stroke={strokeColor}
                  strokeWidth="8"
                  strokeLinecap="round"
                />
              );
            })}
            {showReallyTrue &&
              answers.map((d, i) => {
                const strokeColor = "#04c17a";
                const from = i;
                const to = right.findIndex(
                  r => r.key === answersMap[i].realRight
                );

                const reallyLeftCircle = this.circle("left", from, "trueColor");
                const reallyRightCircle = this.circle("right", to, "trueColor");

                return (
                  <React.Fragment key={"reallytrue-" + i}>
                    {this.circle("left", from, "trueColor")}
                    {this.circle("right", to, "trueColor")}
                    <line
                      key={parentId + "_line_" + from + "_" + to}
                      x1={this.x1(from)}
                      y1={this.y1(from)}
                      x2={this.x2(to)}
                      y2={this.y2(to)}
                      stroke={strokeColor}
                      strokeWidth="8"
                      strokeLinecap="round"
                    />
                  </React.Fragment>
                );
              })}
            {drawing ? (
              <line
                key={parentId + "_line_ing"}
                x1={this.x(start.pos, start.index)}
                y1={this.y(start.pos, start.index)}
                x2={cursor.x}
                y2={cursor.y}
                stroke="#00d3ff"
                strokeWidth="8"
                strokeLinecap="round"
              />
            ) : null}
          </svg>
        </div>
        <div className="t-answer-paring__right">
          {right.map((d, i) => {
            return (
              <PairedAnswer
                key={parentId + "_r_" + i}
                id={parentId + "_r_" + i}
                height={this.state.rightClientHeight}
                index={i}
                data={d}
                position={"right"}
                start={start}
                drawing={drawing}
                answersMap={answersMap}
                solvedData={solvedData}
                showReallyTrue={showReallyTrue}
                onMobilePairing={this.onMobilePairing}
                isMobileOnly={isMobileSize}
                mobileSelectLeft={this.state.mobileSelectLeft}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(null, dispatch => ({
  ScrollActions: bindActionCreators(scrollActions, dispatch)
}))(PairingArea);
