import React, { useState } from "react";
import Popup from "../Popup/Popup";
import classNames from "classnames";

const SummaryPopup = props => {
  const { isOpen, onClose, summaryInfo } = props;
  const [curTab, setTab] = useState("words");

  console.log("summaryinfo: ", summaryInfo);
  return (
    <Popup isOpen={isOpen} className="summary-view-popup popup-student-list">
      <div
        className={`popup-closebtn`}
        onClick={() => {
          onClose();
        }}
      ></div>
      <div className="title">개념 입력보기</div>

      <div className="view-tab">
        <div
          className={classNames("view-tab__nav", {
            "is-active": curTab === "words"
          })}
          onClick={() => setTab("words")}
        >
          <span>단어로 개념정리</span>
        </div>
        <div
          className={classNames("view-tab__nav", {
            "is-active": curTab === "answer"
          })}
          onClick={() => setTab("answer")}
        >
          <span>문장으로 개념정리</span>
        </div>
        <div
          className={classNames("view-tab__nav", {
            "is-active": curTab === "memo"
          })}
          onClick={() => setTab("memo")}
        >
          <span>메모로 개념정리</span>
        </div>
      </div>

      <div className="view-wrap">
        {/* 선택된 것 is-select */}
        <div
          className={classNames("view-area", {
            none: curTab !== "words"
          })}
        >
          <div className="view-title">기억에 남는 단어 3개는 무엇인가요?</div>
          {summaryInfo.words.map((word, index) => {
            if (word) {
              return (
                <div className="tag" key={"key" + index}>
                  <span>{word}</span>
                </div>
              );
            }
          })}
        </div>

        <div
          className={classNames("view-area", {
            none: curTab !== "answer"
          })}
        >
          <div className="view-title">{summaryInfo.question}</div>
          <textarea
            value={summaryInfo.answer}
            cols="30"
            rows="10"
            readOnly
          ></textarea>
        </div>

        <div
          className={classNames("view-area", {
            none: curTab !== "memo"
          })}
        >
          <div className="view-title">
            나의 생각을 자유롭게 메모하며 정리해보세요.
          </div>
          <textarea
            value={summaryInfo.memo}
            cols="30"
            rows="10"
            readOnly
          ></textarea>
        </div>
        {/* <div className="btnwrap">
          <button className="classmode-btn" onClick={onClose}>
            확인
          </button>
        </div> */}
      </div>
    </Popup>
  );
};

export default SummaryPopup;
