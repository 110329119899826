import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";

const HANDLE_QUIZ_CONTENT_SCROLL = "scroll/HANDLE_QUIZ_CONTENT_SCROLL";

export const handleQuizContentScroll = createAction(HANDLE_QUIZ_CONTENT_SCROLL);

const defaultState = {
  noScroll: false
};

export default handleActions(
  {
    [HANDLE_QUIZ_CONTENT_SCROLL]: (state, { payload }) => {
      return {
        ...state,
        noScroll: update(state.noScroll, { $set: payload })
      };
    }
  },
  defaultState
);
