import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Skeleton,
  Row,
  Col,
  List,
  Tag,
  Spin,
  PageHeader,
  Button,
  Icon,
  Descriptions,
  Typography,
  Table,
  Divider,
  Statistic,
  Popconfirm,
  message,
  Tooltip, Modal
} from "antd";
import Axios from "../../../../utils/request";
import { values, flatten, filter } from "lodash";
import AccountApis from "../../../../utils/accountApis";
import { ConstantsContext } from "utils/ConstantsContext";
import { formateQuetureName } from "../../../../utils/utils";
import moment from "moment";

const { Title, Text } = Typography;

class GuideDetailPage extends Component {
  static contextType = ConstantsContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      guideData: null,
      classroomData: null,
      studentList: [],
      activeGuideOrgIdPopup: false,
      activeDeleteOrgPopup: false,
      orgId: null
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { guideId } = this.props.match.params;
    console.log("this guideId: ", guideId);
    if (user) {
      this.getGuideDetail(guideId);
    }
  }

  componentDidUpdate(nextProps) {
    const { user } = this.props;
    const { guideId } = this.props.match.params;
    if (this.props.user && this.props.user.type !== nextProps.user.type) {
      this.getGuideDetail(guideId);
    }
  }

  getGuideDetail(guideId) {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });
    Axios.get(`/academy/v2/accounts/${user.orgId}/guides/${guideId}?withReport=true`)
      .then(res => {
        console.log("guide detail: ", res);
        const _studentList = [];
        if (res.classrooms) {
          res.classrooms.map(classroom => classroom.students.map(s => {
            s.classroomName = classroom.name;
            s.classroomId = classroom.classroomId;
            _studentList.push(s);
          }));
        }
        this.setState({
          isLoading: false,
          guideData: res,
          studentList: _studentList
        });
      })
      .catch(e => {
        message.error("가이드 연관 정보 조회 실패");
        this.setState({
          isLoading: true
        });
      });
  }

  deleteGuideOrg = orgId => {
    Axios.delete(`/academy/v2/accounts/${orgId}/guides/${this.state.guideData.guideId}`).then(e=>{
      message.success("삭제 완료");
      this.getGuideDetail(this.state.guideData.guideId);

    }).catch(e=>{
      message.error("삭제 실패")
    });
  }

  handleGuideState = state => {
    AccountApis.changeGuideState(
      this.props.user.orgId,
      this.state.guideData.guideId,
      state
    )
      .then(res => {
        message.success("설정 완료");
        this.props.history.push("/admin/guide/list");
      })
      .catch(e => {
        message.error("설정 실패");
      });
  };

  render() {
    const { isLoading, guideData, studentList, activeGuideOrgIdPopup, activeDeleteOrgPopup } = this.state;
    const { pending, user, userPermission } = this.props;
    const buttonStyle = { margin: '2px 2px' };

    console.log(guideData, "guideData");

    return (
      <Skeleton loading={isLoading || pending} active>
        {guideData && (
          <>
            <PageHeader
              title={
                <Title level={2} style={{ marginBottom: 0 }}>
                  {`${this.context.guideName}: ${guideData.name}`}
                </Title>
              }
              extra={
                (userPermission === "master" ||
                  (userPermission && userPermission.guideUpdate)) && [
                  <Button
                    key="3"
                    onClick={() =>
                      this.props.history.push(
                        `/admin/guide/edit/${guideData.guideId}`
                      )
                    }
                  >
                    {`${this.context.guideName} 정보 수정하기`}
                    <Icon type="setting"/>
                  </Button>,
                  <Popconfirm
                    key="2"
                    title={`비밀번호를 기본값(${
                      this.context.type === "campus"
                        ? "생년월일"
                        : "smcho12345!"
                    })으로 초기화하시겠습니까?`}
                    onConfirm={() => {
                      AccountApis.resetGuidePassword(
                        this.props.user.orgId,
                        guideData.guideId,
                        this.context.type === "campus" ? "" : "smcho12345!"
                      )
                        .then(res => {
                          message.success("초기화 완료");
                        })
                        .catch(e => {
                          message.warning("초기화 실패");
                        });
                    }}
                    onCancel={() => message.error("초기화 취소")}
                    okText="초기화"
                    placement="bottomRight"
                    cancelText="취소"
                  >
                    <Button>
                      비밀번호 초기화
                      <Icon type="unlock"/>
                    </Button>
                  </Popconfirm>,
                  <Popconfirm
                    key="1"
                    title={`${guideData.name}${this.context.guideName}을 ${
                      guideData.state === "working" ? "퇴사" : "재직"
                    }상태로 변경 하시겠습니까?`}
                    onConfirm={() => {
                      let state =
                        guideData.state === "working" ? "exit" : "working";
                      this.handleGuideState(state);
                    }}
                    onCancel={() => message.error("변경 실패")}
                    okText="변경"
                    placement="bottomRight"
                    cancelText="취소"
                  >
                    {guideData.state === "working" ? (
                      <Button type="danger">
                        퇴사상태로 변경
                        <Icon type="minus-circle"/>
                      </Button>
                    ) : (
                      <Button type="primary">
                        재직상태로 변경
                        <Icon type="plus-circle"/>
                      </Button>
                    )}
                  </Popconfirm>
                ]
              }
            ></PageHeader>
            <div className="admin-page-content">
              <Row gutter={24} style={{ padding: 24 }}>
                <Col span={6}>
                  <div
                    className="imgwrap"
                    style={{
                      maxWidth: 230,
                      height: 200,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#F7F9FB",
                      border: "1px dashed #CCCCCC",
                      boxSizing: "border-box",
                      borderRadius: 4,
                      position: "relative"
                    }}
                  >
                    <img
                      src={
                        (guideData &&
                          guideData.profileImage &&
                          guideData.profileImage.signedUrl) ||
                        null
                      }
                      alt=""
                      style={{
                        width: "100%",
                        maxHeight: "100%",
                        objectFit: "cover"
                      }}
                    />
                  </div>
                </Col>
                <Col span={18}>
                  <Descriptions bordered column={2}>
                    <Descriptions.Item label="이름">
                      {guideData.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="아이디">
                      {guideData.account}
                    </Descriptions.Item>
                    {guideData.birthday && (
                      <Descriptions.Item label="생년월일">
                        {`${guideData.birthday.year}-${guideData.birthday.month}-${guideData.birthday.day}`}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="연락처">
                      {guideData.mobile}
                    </Descriptions.Item>
                    {guideData.joinDate && (
                      <Descriptions.Item label="입사일">
                        {`${guideData.joinDate.year}-${guideData.joinDate.month}-${guideData.joinDate.day}`}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="주소">
                      {`${guideData.address.address1}`}
                      <br/>
                      {`${guideData.address.address2}`}
                    </Descriptions.Item>
                    <Descriptions.Item label={`담당 지점`} span={2}>
                      {(guideData.organizationViewList && guideData.organizationViewList.length > 0) ?
                        guideData.organizationViewList.map(org => (
                          <Popconfirm
                            key="4"
                            title={`담당 ${org.name} 지점을 제거 하시겠습니까?`}
                            onConfirm={() => {
                              this.deleteGuideOrg(org.orgId);
                            }}
                            onCancel={() => message.error("변경 실패")}
                            okText="변경"
                            placement="bottomRight"
                            cancelText="취소"
                          >
                            <Tag key={org.orgId} onClick={(e) => {
                              this.setState({
                                activeDeleteOrgPopup: true,
                                orgId: org.orgId
                              })
                            }}>
                              {org.name}
                            </Tag>
                          </Popconfirm>
                        )) : (guideData.organization ?
                          <Tag key={guideData.orgId}>
                            {guideData.organization.name}
                          </Tag>
                          : null)}
                    </Descriptions.Item>
                    <Descriptions.Item label={`담당 ${this.context.className}`} span={2}>
                      {guideData.classrooms &&
                      guideData.classrooms.map(classroom => (
                        <Tag key={classroom.classroomId}>
                          {classroom.name}
                        </Tag>
                      ))}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24} style={{ margin: "24px 0" }}>

                  <Title level={4} style={{ display: 'inline-block' }}>{`학습 리포트 관리 현황`}</Title>
                  <Table
                    dataSource={studentList}
                    rowKey={"studentId"}
                    columns={[
                      {
                        title: `${this.context.className}`,
                        key: "classroomName",
                        dataIndex: "classroomName",
                        render: (text, record) =>
                          <a
                            onClick={() => this.props.history.push(`/admin/class/detail/${record.classroomId}`)}>{record.classroomName}</a>
                      },
                      {/*
          title: "과제 회차/깨처/풀기",
          align: "center",
          key: "homeworks",
          render: (text, record, index) => {
            let lectureName = (record.prevScore? record.prevScore.lectureName : '정보없음');
            let quetureCount = (record.prevScore && record.prevScore.homeworks) ?
               record.prevScore.homeworks.filter((h) => h.homework.type == 'queture').length : 0;
            let workbookCount = (record.prevScore && record.prevScore.homeworks) ?
               record.prevScore.homeworks.filter((h) => h.homework.type == 'workbook').length : 0;

            return (
               <span>
               {lectureName}
               {' / '}
               {quetureCount > 0 ? <font color="blue">{quetureCount}</font> : <font color="orange">0</font>}
               {' / '}
               {workbookCount > 0 ? <font color="blue">{workbookCount}</font> : <font color="orange">0</font>}
               </span>
             );
            }
        },
        { */
                        title: '최근 완료 코스',
                        key: "courseLectures",
                        render: (text, record) =>
                          (record.report && record.report.lectures ?
                            <span>
                  [{record.report.courseNo}] {record.report.courseTitle}
                              {record.report.courseNo > record.report.courseCompleted ?
                                <font color="grey"> (미완료)</font> : ''}
                  </span>
                            : '-')
                      },
                      {
                        title: '회차',
                        key: "courseLectures",
                        render: (text, record) =>
                          (record.report && record.report.lectures ?
                            (record.report.lectures.length > 1 ?
                              String(record.report.lectures[0].title).replace('회차', '').replace('강', '') + '~' + record.report.lectures[record.report.lectures.length - 1].title
                              : record.report.lectures[0].title)
                            : '-')
                      },
                      {
                        title: '수업 기간',
                        key: "courseLectures",
                        render: (text, record) =>
                          (record.report && record.report.lectures ?
                            (record.report.lectures.length > 1 ?
                              record.report.lectures[0].date + '~' + record.report.lectures[record.report.lectures.length - 1].date.substring(5)
                              :
                              record.report.lectures[0].date)
                            : '-')
                      },
                      {
                        title: `학생`,
                        key: "studentName",
                        dataIndex: "name",
                        render: (text, record) =>
                          <a
                            onClick={() => this.props.history.push(`/admin/student/detail/${record.studentId}`)}>{record.name}</a>
                      },
                      {
                        title: "테스트점수",
                        align: "center",
                        key: "examResult",
                        render: (text, record, index) =>
                          <span>
            {!(record.report && record.report.lectures && record.report.lectures.length > 0) ? '-'
              : (record.report.examResult && record.report.examSolved > 0) ?
                <font
                  color="green">&nbsp;&nbsp;{Math.round((100 * record.report.examCorrect + 50 * record.report.examPartial) / record.report.examSolved)}%&nbsp;</font>
                : record.report.courseNo > record.report.courseCompleted ? '-'
                  : <font color="red">미채점</font>}
            </span>
                      },
                      {
                        title: "선생님메모",
                        align: "center",
                        key: "report",
                        render: (text, record, index) =>
                          <span>
            {!(record.report && record.report.lectures && record.report.lectures.length > 0) ? '-'
              : record.report.comment ?
                <Tooltip title={record.report.comment}><font color="green">&nbsp;등록&nbsp;</font></Tooltip>
                : record.report.courseNo > record.report.courseCompleted ? '-'
                  : <font color="red">미등록</font>}
            </span>
                      },
                      {
                        title: "리포트발행",
                        align: "center",
                        key: "publish",
                        render: (text, record, index) =>
                          <span>
            {!(record.report && record.report.lectures && record.report.lectures.length > 0) ? '-'
              : (record.report.reportLogId && record.reportLogTime) ?
                <span>{moment(new Date(record.report.reportLogTime)).format("YYYY.MM.DD")}</span>
                : record.report.courseNo > record.report.courseCompleted ? '-'
                  : <font color="red">미발행</font>}
            </span>
                      },
                      {
                        title: "상담내역",
                        align: "center",
                        key: "report",
                        render: (text, record, index) =>
                          <span>
            {!(record.report && record.report.lectures && record.report.lectures.length > 0) ? '-'
              : record.report.consult ?
                <Tooltip title={record.report.consult}><font color="green">&nbsp;완료&nbsp;</font></Tooltip>
                : record.report.courseNo > record.report.courseCompleted ? '-'
                  : <font color="red">미완료</font>}
            </span>
                      },
                      {
                        title: "리포트 보기",
                        align: "center",
                        key: "report",
                        render: (text, record, index) =>
                          (record.report && record.report.lectures ?
                            <span>
              <Button onClick={() => this.props.history.push(`/admin/student/detail/${record.studentId}/report/0`)}>
                학습리포트
              </Button>
              </span>
                            : '-')
                      },
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </>
        )}

      </Skeleton>
    );
  }
}

export default connect(
  ({ auth }) => ({
    pending: auth.pending,
    auth: auth,
    user: auth.user,
    userPermission: auth.user.type === "org" ? "master" : auth.user.permission,
    logged: auth.logged
  }),
  null
)(GuideDetailPage);
