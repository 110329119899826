import React from 'react';
import classNames from 'classnames';

const QuizLayoutB = (props) => {
  const { isLong, imageComponent, problemComponent, choiceComponent, titleComponent, desmosComponent } = props;
  const QuizAnswers = choiceComponent;
  const QuizProblem = problemComponent;
  const Desmos = desmosComponent;
  const QuizImage = imageComponent;
  const QuizTitle = titleComponent;

  return (
    <div className={classNames("layout type-b", {
      "long": isLong
    })}>
      <div className="mb16">
        <div className="inline-block">
          {/* <div className="mb16">
            <QuizTitle />
          </div> */}
          <QuizProblem />
        </div>
        <QuizImage />
      </div>
      <QuizAnswers />
    </div>
  );
}

export default QuizLayoutB;
