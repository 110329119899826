import React, { useState } from "react";
import styled from "styled-components";
import ACheckbox from "../selector/ACheckbox";
import ARadio from "../selector/ARadio";
import MediaPlayer from "../mediaplayer/MediaPlayer";
import Classmodebtn from "../button/Classmodebtn";
import "./_component.scss";
import Queturepoint from "../../../pages/classMode/queture/Queturepoint";
import QuetureView from "../../../pages/classMode/queture/QuetureView";
import GameExplain from "../../../pages/classMode/game/GameExplain";
import Maketeam from "../../../pages/classMode/maketeam/Maketeam";
import Activity from "../../../pages/classMode/activity/Activity";
import Mvp from "../../../pages/classMode/mvp/Mvp";
import GameRanking from "../../../pages/classMode/game/GameRanking";
import SoloAni from "../../BodyMovin/Animation/SelectSolo";
import TeamAni from "../../BodyMovin/Animation/SelectTeam";
import Popup from "../../Popup/Popup";
import ToggleViewMenu from "../button/ToggleViewMenu";

// 임시 컴포넌트 파일. 페이지수가 더 나온다면 컴포넌트들 갈라질 예정

const ComponentsAll = () => {
  const [showPopup, setPopup] = useState(null);
  // const inputwrap = styled.div`
  //   ${inputchk} {

  //   }
  // `;

  // const inputchk = styled.input.attrs({

  // });
  // TODO:스타일드 컴포넌트 고민중...

  return (
    <div>
      {/*
      <ToggleViewMenu />
     */}
      <h2>임시 컴포넌트 모음</h2>
      <h3>check box</h3>
      <ACheckbox />

      <h3>Radio button</h3>
      <ARadio />

      <h3>버튼</h3>
      <Classmodebtn text="버튼" />
      <button className="classmode-btn" disabled="disabled">
        버튼
      </button>

      <h4>인풋</h4>
      {/* is-false, is-true */}
      <div className="inputwrap login is-true">
        <input
          type="text"
          name=""
          id=""
          className=""
          placeholder="아이디를 입력해주세요."
        />
        <div className="false-ment">아이디를 입력해주세요.</div>
      </div>
      <div className="inputwrap pw is-false">
        <input
          type="password"
          name=""
          id=""
          className=""
          placeholder="비밀번호를 입력해주세요."
        />
        <div className="false-ment">비밀번호를 입력해주세요.</div>
      </div>

      <h4>팝업</h4>
      <p>학생 리스트 : popup-student-list</p>
      <p>다음 페이지 미리보기 : popup-page-preview</p>
      <p>강의 경로 : popup-lecture-path</p>
      <p>페이지 모두보기 : popup-page-all</p>
      <p>깨처에서 개인전, 팀전 선택 후 : popup-select-battletype</p>
      <p>팀 구성 리스트 : popup-team-info</p>
      <p>가이드 도우미: popup-guilde</p>
      {/* 팝업 */}
      <button
        className="classmode-btn"
        onClick={() => setPopup("student-list")}
      >
        학생 리스트
      </button>
      <button
        className="classmode-btn"
        onClick={() => setPopup("page-preview")}
      >
        다음 페이지 미리보기
      </button>
      <button
        className="classmode-btn"
        onClick={() => setPopup("lecture-path")}
      >
        강의 경로
      </button>
      <button className="classmode-btn" onClick={() => setPopup("page-all")}>
        페이지 모두보기
      </button>
      <button
        className="classmode-btn"
        onClick={() => setPopup("select-battletype")}
      >
        깨처에서 개인전 팀전 선택후
      </button>
      <button className="classmode-btn" onClick={() => setPopup("team-info")}>
        팀구성리스트
      </button>
      <button className="classmode-btn" onClick={() => setPopup("guide")}>
        가이드 도우미
      </button>
      {/* 학생리스트 */}
      <Popup
        isOpen={showPopup === "student-list"}
        className="popup-student-list"
      >
        <div className="popup-closebtn" onClick={() => setPopup(null)}></div>
        <div className="title">학생 리스트</div>
        <div className="list">
          <ul className="list__title">
            <li>이름</li>
            <li>출석</li>
            <li>홈트레이닝</li>
            <li>미션</li>
            <li>MVP</li>
          </ul>
          <div className="list__content">
            <ul className="list__content-list">
              <li className="name">
                학생일<span>9세</span>
              </li>
              {/*
                li.attaendnce 와 li.mission 에
                기본이 x 표시. 했을시 (O표) is-yes

                li.home 에
                깨처를 한개도 안봤을시 : is-not

             */}
              <li className="attendance">
                <span></span>
              </li>
              <li className="home">
                <span></span>
              </li>
              <li className="mission">
                <span></span>
              </li>
              <li className="mvp">
                <div className="inputwrap">
                  <input type="checkbox" id="mvp1" />
                  <span></span>
                  <label htmlFor="mvp1"></label>
                </div>
              </li>
            </ul>
            <ul className="list__content-list">
              <li>
                학생일<span>9세</span>
              </li>
              <li className="attendance">ㅁ</li>
              <li className="home"></li>
              <li className="mission">ㅁ</li>
              <li className="mvp">
                <div className="inputwrap">
                  <input type="checkbox" id="mvp2" />
                  <span></span>
                  <label htmlFor="mvp2"></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="chk-btn">
          <button onClick={() => setPopup(null)}>확인</button>
        </div>
      </Popup>
      {/* 다음페이지 미리보기 */}
      <Popup
        isOpen={showPopup === "page-preview"}
        className="popup-page-preview"
      >
        <div className="popup-closebtn" onClick={() => setPopup(null)}></div>
        <div className="content">
          <div className="attendance-layout">
            <div className="big-title">출석체크를 해주세요</div>
            {/* li가 4~5개 일때 (1줄)  childmin
               li가 6개 일때(3,3) child6
               li가 7개, 8개 일때(4,3. 4,4) child7
               li가 9개, 10개 일때(5,4. 5,5) child9
               li가 11개, 12개 일때  '추가 클래스 없음'
               출석체크 전 기본 not-attendance 클래스이고 li는 12개
           */}
            <ul className="attendancelist-wrap">
              <li className="attendancelist effect">
                <div className="img"></div>
                <div className="attendancelist__name">학생일</div>
                <div className="attendancelist__time">
                  <span>00:00:00</span>
                </div>
              </li>
              <li className="attendancelist">
                <div className="img">
                  <img src="" alt="" />
                </div>
                <div className="attendancelist__name">학생일</div>
                <div className="attendancelist__time">
                  <span>00:00:00</span>
                </div>
              </li>
              <li className="attendancelist">
                <div className="img">
                  <img src="" alt="" />
                </div>
                <div className="attendancelist__name">학생일</div>
                <div className="attendancelist__time">
                  <span>00:00:00</span>
                </div>
              </li>
              <li className="attendancelist">
                <div className="img">
                  <img src="" alt="" />
                </div>
                <div className="attendancelist__name">텍스트</div>
                <div className="attendancelist__time">
                  <span>00:00:00</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="page-number">
          <span>1</span>/<span>20</span>
        </div>
      </Popup>
      {/* 강의 경로 */}
      <Popup
        isOpen={showPopup === "lecture-path"}
        className="popup-lecture-path"
      >
        <ul className="lecture-route">
          {/* 본 것 : is-gone-view*/}
          {/* 현재 : is-view*/}
          <li className="is-gone-view">
            <p>출석확인</p>
          </li>
          <li className="is-view">
            <p> 과제보기 </p>
          </li>
          <li className="is-view">
            <p>
              {" "}
              <span className="queture-name">115번 깨처</span>
              <span>깨처</span>
              <span className="point">깨처 포인트</span>
              <span>문제</span>
            </p>
          </li>
          <li className="">
            <p>
              {" "}
              <span className="queture-name">115번 깨처</span>
              <span>깨처</span>
              <span className="point">깨처 포인트</span>
              <span>문제</span>
            </p>
          </li>
          <li className="">
            <p> 게임 </p>
          </li>
          <li className="">
            <p> 활동 </p>
          </li>
          <li className="">
            <p> MVP </p>
          </li>
        </ul>
      </Popup>
      {/* 페이지 모두보기 */}
      <Popup isOpen={showPopup === "page-all"} className="popup-page-all">
        {/*  */}
        <div className="preview-wrap">
          <div className="popup-closebtn" onClick={() => setPopup(null)}></div>
          <ul className="preview-list">
            {/* 현재 페이지 is-this */}
            <li className="preview">
              <div className="content">
                <GameRanking />
                <div className="dim">
                  <span>출석체크</span>
                </div>
              </div>
            </li>
            <li className="preview is-this">
              <div className="content">
                <div className="dim">
                  <span>깨처</span>
                </div>
              </div>
            </li>
          </ul>
          <p className="popup-ment">해당 페이지를 클릭하면 이동합니다.</p>
        </div>
      </Popup>
      {/* 깨처에서 개인전 팀전 선택후 */}
      <Popup
        isOpen={showPopup === "select-battletype"}
        className="popup-select-battletype"
      >
        {/* 기본 개인전, 팀전 : team  */}
        <div className="selected-battletype">
          <strong>개인전</strong>
          <p>스스로 문제를 풀어볼까요</p>
          <SoloAni />
          {/*
               <TeamAni/>
              */}
        </div>
      </Popup>
      {/* 팀 구성 리스트 */}
      <Popup
        isOpen={showPopup === "team-info"}
        className="popup-student-list popup-team-info"
      >
        <div className="popup-closebtn" onClick={() => setPopup(null)}></div>
        <div className="title">팀 구성 리스트</div>
        <div className="team-content">
          {
            <>
              <div className="team-name">팀1</div>
              <ul className="team-wrap">
                <li className="is-leader">학생일</li>
                <li>학생이</li>
                <li>학생이</li>
                <li>학생이</li>
                <li>학생이</li>
                <li>학생이</li>
              </ul>
            </>
          }
          {
            <>
              <div className="team-name">팀1</div>
              <ul className="team-wrap">
                <li className="is-leader">학생일</li>
                <li>학생이</li>
              </ul>
            </>
          }
          {
            <>
              <div className="team-name">팀1</div>
              <ul className="team-wrap">
                <li className="is-leader">학생일</li>
                <li>학생이</li>
              </ul>
            </>
          }
        </div>
        <div className="chk-btn">
          <button onClick={() => setPopup(null)}>확인</button>
        </div>
        {/** */}
      </Popup>
      <Popup isOpen={showPopup === "guide"} className="popup-guide">
        <div className="popup-closebtn" onClick={() => setPopup(null)}></div>
        <p>
          이번 수업에서는 수 세기와 묶기의 개념을 유의해서 가이드 해주세요.{" "}
          <b>십묶음의 개수 세기</b>가 중요합니다.
        </p>
      </Popup>
      <h4>미디어 플레이어</h4>
      <MediaPlayer />
    </div>
  );
};

export default ComponentsAll;
