import axios from "axios";
import { getHost, getMainHost, getDefaultConfig } from "utils/APIUtils";

const request = function(host, url, params, config, method) {
  if (method === "get" || method === "delete") {
    return new Promise((resolve, reject) => {
      axios[method](
        host + url,
        Object.assign({ ...getDefaultConfig() }, config)
      )
        .then(
          response => {
            resolve(response.data);
          },
          err => {
            if (err.Cancel) {
              console.log(err);
            } else {
              reject(err);
            }
          }
        )
        .catch(err => {
          reject(err);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      axios[method](
        host + url,
        params,
        Object.assign({ ...getDefaultConfig() }, config)
      )
        .then(
          response => {
            resolve(response.data);
          },
          err => {
            if (err.Cancel) {
              console.log(err);
            } else {
              reject(err);
            }
          }
        )
        .catch(err => {
          reject(err);
        });
    });
  }
};

export const Axios = {
  post:   (url, params, config = {}) => request(getHost(), url, params, config, "post"),
  get:    (url, params, config = {}) => request(getHost(), url, params, config, "get"),
  delete: (url, params, config = {}) => request(getHost(), url, params, config, "delete"),
  put:    (url, params, config = {}) => request(getHost(), url, params, config, "put"),
  patch:  (url, params, config = {}) => request(getHost(), url, params, config, "patch"),
};

export const AxiosToMain = {
  post:   (url, params, config = {}) => request(getMainHost(), url, params, config, "post"),
  get:    (url, params, config = {}) => request(getMainHost(), url, params, config, "get"),
  delete: (url, params, config = {}) => request(getMainHost(), url, params, config, "delete"),
  put:    (url, params, config = {}) => request(getMainHost(), url, params, config, "put"),
  patch:  (url, params, config = {}) => request(getMainHost(), url, params, config, "patch"),
};

export default Axios;
