import React, { Component } from "react";
import { connect } from "react-redux";
import NoticeApis from "../../../../utils/noticeApis";
import {
  Skeleton,
  PageHeader,
  Typography,
  Row,
  Col,
  message,
  Divider,
  Button,
  Popconfirm
} from "antd";
import { withRouter } from "react-router-dom";
import { getKoreanDate } from "../../../../utils/utils";
import NewsletterApis from "../../../../utils/newsletterApis";
import ParentMobileListPopup from "./ParentMobileListPopup"
const { Title, Text } = Typography;
class NewsletterDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isParentMobileListPopupAble: false,
      isLoading: false,
      data: {
        title: null,
        text: null,
        writer: null
      }
    };
  }

  componentDidMount() {
    const { newsletterId } = this.props.match.params;

    console.log("notice id: ", newsletterId);
    NewsletterApis.getNewsletter(newsletterId)
      .then(res => {
        console.log("notice detail: ", res);
        this.setState({
          data: res
        });
      })
      .catch(e => {
        message.warning("");
      });
  }
  handleSend = newsletterId => {
    NewsletterApis.sendNewsletter("https://www.quebon.ac" + "/nl/" + newsletterId,this.props.user.orgId);
  }
  handleDelete = newsletterId => {
    this.setState({
      isLoading: true
    });

    NewsletterApis.deleteNewsletter(newsletterId)
      .then(res => {
        message.success("삭제 완료");
        this.props.history.push("/admin/newsletter");
      })
      .catch(e => {
        message.warning("삭제 실패");
      });
  };

  render() {
    const { data, isParentMobileListPopupAble } = this.state;
    const { user, userPermission } = this.props;

    console.log(isParentMobileListPopupAble,"데이터")
    return (
      <Skeleton loading={false} active>
        <PageHeader title={<Title level={2}>뉴스레터</Title>} />
        <div className="admin-page-content">
          <Title level={4}>{data.title}</Title>
          <div>
            {/*<Text>{`작성자: ${data.writer && data.writer.name}`}</Text>*/}
            {/*<Divider type="vertical" />*/}
            <Text>{`등록일: ${getKoreanDate(data.createdTime, true)}`}</Text>
            <Divider type="vertical" />
            <a href={"https://www.quebon.ac/nl/" + data.newsletterId}>{`공유 URL: ${"https://www.quebon.ac" + "/nl/" + data.newsletterId}`}</a>
          </div>
          <Divider />
          <p dangerouslySetInnerHTML={{ __html: data["text"] }}></p>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", margin: 24 }}
        >
          {(userPermission === "master" || userPermission &&  userPermission.newsletter) && (
            <>
              <Popconfirm
                title={`정말로 삭제 하시겠습니까?`}
                onConfirm={() => this.handleDelete(data.newsletterId)}
                onCancel={() => message.error("삭제 취소")}
                okText="삭제"
                placement="topRight"
                cancelText="취소"
              >
                <Button type="danger" size="large" style={{ marginRight: 16 }}>
                  삭제하기
                </Button>
              </Popconfirm>
              <Button
                type="primary"
                size="large"
                style={{ marginRight: 16 }}
                onClick={() => {
                  this.props.history.push(
                    `/admin/newsletter/edit/${data.newsletterId}`
                  );
                }}
              >
                수정하기
              </Button>
              <Button
                type="primary"
                size="large"
                style={{ marginRight: 16 }}
                onClick={() => {
                  this.setState({
                    isParentMobileListPopupAble : true
                  })
                }}
              >
                전체전송
              </Button>
            </>
          )}
          {isParentMobileListPopupAble && (<ParentMobileListPopup
              url={"https://www.quebon.ac" + "/nl/" + data.newsletterId}
              isOpen={isParentMobileListPopupAble}
              orgId={this.props.user.orgId}
              onClose={()=>{
                this.setState({isParentMobileListPopupAble:false});
              }}/>
          )}
          <Button
            size="large"
            onClick={() => {
              this.props.history.push(`/admin/newsletter`);
            }}
          >
            목록으로
          </Button>
        </div>
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      pending: auth.pending,
      auth: auth,
      user: auth.user,
      logged: auth.logged,
      userPermission: auth.user.type === "org" ? "master" : auth.user.permission
    }),
    null
  )(NewsletterDetailPage)
);
