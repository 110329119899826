import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";

const HANDLE_POPUP = "popup/HANDLE_POPUP";

export const handlePopup = createAction(HANDLE_POPUP);

const defaultState = {
  showPopup: false
};

export default handleActions(
  {
    [HANDLE_POPUP]: (state, { payload }) => {
      return {
        ...state,
        showPopup: update(state.showPopup, { $set: payload })
      };
    }
  },
  defaultState
);
