import React, { useEffect, useState } from 'react';
import { formatText } from "../utils/Formatter";
import classNames from 'classnames';
import { Button } from "antd";

const QuizTitle = ({ quizId, isEng, title, type, learnType, status, isHard, isImportant, currentQuizIndex, forWorkbook, viewType,quizType,correct,solveQuiz }) => {

  const [text, setText] = useState(null);
  const [showBubble, setBubble] = useState(true)

  useEffect(() => {

    if (title) {
      setText(formatText(title, quizId + "_tit"))
    } else {
      if (isEng) {
        if (type === 'drag_text' || type === 'fill_blank') {
          setText('Fill in the blank.')
        } else if (type === 'select_text') {
          setText('Choose the right option for the blank.')
        }
      } else {
        if (type === 'drag_text' || type === 'select_text' || type === 'fill_blank') {
          setText('다음 빈칸에 알맞은 것은?');
        } else if (type === 'boolean') {
          setText('다음 문장이 맞는지 틀린지 고르면?');
        } else {
          setText('다음 물음에 답을 해보자.');
        }
      }
    }
    const timer = () => setTimeout(() => {
      setBubble(false)
    }, 3000);
    const timerId = timer();
    // setTimeout(() => { setBubble(false) }, 3000)

    return () => {
      setText(null)
      setBubble(true)
      clearTimeout(timerId);
    };
  }, [title, type, status, isEng, quizId]);

  return (
    <div className="quiz-content__content-title mb16">
      {/* 'title-line': (type !== 'ordering') && !hasImage && !hasProblem */}
      <p style={{ fontWeight: 500 }}>
        <span className={classNames("question-mark", {
          "is-num": learnType === 'preTest' || learnType === 'postTest' || learnType === 'qiqTest',
          "is-important": isImportant,
          "is-hard": isHard
        })}>
          {
            currentQuizIndex === 0 || (currentQuizIndex && currentQuizIndex > 0) ?
                (forWorkbook ? <span className="workbook-quiz-index">{currentQuizIndex + 1}</span> : (currentQuizIndex + 1).toString()+'.') :
              learnType === 'preTest' || learnType === 'postTest' || learnType === 'qiqTest' ?
                (status.length + 1) : 'Q.'
          }
          {
            (isImportant || isHard) &&
            <span className={classNames("speech_bubble", {
              "block": showBubble
            })}>
            </span>
          }
        </span>
        <span className="problem-title"> {text}
          {correct === null && quizType === "challenge" && viewType == "guide" &&
          <Button onClick={() => solveQuiz()}
                  style={{float: 'right', marginRight: '20px', backgroundColor: "#55A3FF", color: '#ffffff' }}>
            정답확인
          </Button>}
        </span>

      </p>
      
    </div>
  );
}

export default QuizTitle;
