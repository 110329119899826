import React from "react";
import "./_attendanceChk.scss";
import AttendanceList from "../../../components/Teacher/component/AttendanceList";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";
import BubbleAttendance from "components/Teacher/component/BubbleAttendance/BubbleAttendance";

const AttendanceChk = (props) => {
  const context = React.useContext(ConstantsContext);
  const { isKids, viewType } = props;

  if(isKids && viewType === 'preview') {
    return <BubbleAttendance {...props} />
  }

  return (
    <div className="attendance-layout">
      <div className="big-title">
        {context.type === "school" ? (
          "로그인 해 주세요"
        ) : (
          <FormattedMessage id="classroom.attendance" />
        )}
      </div>
      {context.type === "campus" && <AttendanceList {...props} />}
    </div>
  );
};

export default AttendanceChk;
