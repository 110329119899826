import React, { useState, useEffect } from 'react'
// import ReactBodymovin from 'react-bodymovin'
import animationone from './one.json'
import BodyMovin from '../BodyMovin.js';
import './_animation.scss';


const SoloAni = () => {
  const [isPlay, setPlay] = useState(false);

  const bodymovinOptions2 = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: animationone
  }

  useEffect(() => {
    const timer = setTimeout(() => { setPlay(true) }, 500);
    return () => clearTimeout(timer);
  }, []);

  // 1초 정도 위에
  return (
    <>
    <div className="select-solo">
      <BodyMovin className="character" options={bodymovinOptions2} />
    </div>
      {
        isPlay &&
        <>
        </>
      }
    </>
  )
}

export default SoloAni;
