import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { formatText } from "./utils/Formatter";
import { MultiChoiceAnswer, Image } from "./modules";
import classNames from 'classnames';
import { actionLog } from "utils/LogUtils";

class MultiChoiceQuiz extends Component {

  answerList = null;

  constructor(props) {
    super(props)

    this.state = {
      quizId: null,
      answers: []
    }
  }

  init = data => {
    const { stepQuizAnswers } = this.props;
    const { quiz, quizId } = data;

    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      const answerCount = quiz.choices.length;
      const answerArr = new Array(answerCount).fill(false);

      stepQuizAnswers.map((answer) => {
        const correctIndex = Number.parseInt(answer);
        answerArr[correctIndex - 1] = true;
      })
      this.setState({
        answers: answerArr
      })
    } else {
      const answerCount = quiz.choices.length;
      this.setState({
        answers: new Array(answerCount).fill(false)
      }, () => {
        actionLog("quiz", "init", {
          quizId: quizId,
          type: quiz.type,
          answers: this.state.answers
        });
      });
    }
  };

  componentDidMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
  }

  // createAnswersSeq = () => {
  //   const { data, solvedData, showReallyTrue } = this.props;
  //   const { quizId, quiz } = data;
  //   const { choices, random } = quiz;
  //   const { answers } = this.state;

  //   if (this.answerSeq != null) {
  //     return this.answerSeq;
  //   }

  //   if (!answers || answers.length === 0) {
  //     return null;
  //   }

  //   if (!random || random.length === 0) {
  //     this.answerSeq = [];
  //     return this.answerSeq;
  //   }

  //   let orders = new Array(answers.length);
  //   for (let i = 0; i < orders.length; i++) {
  //     orders[i] = null;
  //   }

  //   let indexes = random.slice();

  //   for (let i = 0; i < random.length; i++) {
  //     const idx = Math.floor(Math.random() * indexes.length);
  //     console.log(`set answer ${random[i]} to ${indexes[idx]}`, random, indexes)
  //     orders[indexes[idx]] = random[i];
  //     indexes.splice(idx, 1);
  //   }

  //   for (let i = 0; i < orders.length; i++) {
  //     if (orders[i] == null) {
  //       orders[i] = i;
  //     }
  //   }

  //   this.answerSeq = orders;
  //   return orders;
  // }

  componentDidUpdate(prevProps) {
    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      this.init(this.props.data)
    }
  }

  onAnswerToggled = i => {
    const { onAnswerUpdated, data } = this.props;
    const answerCount = data.quiz.choices.length;

    this.setState(
      {
        answers: update(this.state.answers, {
          [i]: {
            $set: !this.state.answers[i]
          }
        })
      },
      () => {
        const replaced = [];
        this.state.answers.filter((_, i) => i <= answerCount)
          .forEach((a, i) => {
            if (a === true) replaced.push((i + 1) + "");
          });
        onAnswerUpdated(replaced);
      }
    );
  };

  render() {
    const { data, solvedData, showReallyTrue } = this.props;
    const { quizId, quiz } = data;
    const { choices, subtype, random } = quiz;
    const { answers } = this.state;
    const _answers = this.props.answers;
    // const orders = this.createAnswersSeq();

    let list;

    console.log('isEng: ', this.props.isEng);
    console.log('answer: ', this.state.answers);
    let arr = [false, false, false, false];

    console.log(answers,": selected")
    if (_answers != null && _answers.length != 0) {
      _answers.map((e) => {
        arr[e - 1] = true;
      })
    } else {
      arr = answers;
    }
    if (answers.length > 0) {
      if (random && random.length > 0) {
        list = [];
        console.log('ordering', random);
        for (var i = 0; i < random.length; i++) {
          const ai = random[i];
          const c = choices[ai];
          list.push(
            <MultiChoiceAnswer
              key={quizId + "_ans_" + ai}
              index={ai}
              choice={c}
              // selected={stepQuizAnswers ? stepQuizAnswers[i] : this.state.answers[i]}
              selected={arr[ai]}
              isEng={this.props.isEng}
              solvedData={solvedData}
              showReallyTrue={showReallyTrue}
              onToggle={() => this.onAnswerToggled(ai)}
            />
          )
        }
      } else {
        list = choices.map((c, i) => (
          <MultiChoiceAnswer
            key={quizId + "_ans_" + i}
            index={i}
            choice={c}
            // selected={stepQuizAnswers ? stepQuizAnswers[i] : this.state.answers[i]}
            selected={arr[i]}
            isEng={this.props.isEng}
            solvedData={solvedData}
            showReallyTrue={showReallyTrue}
            onToggle={() => this.onAnswerToggled(i)}
          />
        ))
      }
    }

    return (
      <ul
        className={classNames(
          "quiz-content__answer t-answer-list",
          {
            'half': subtype && subtype === 'B',
            'inline': subtype && subtype === 'C'
          },
        )}>
        {list}
      </ul>
    );
  }
}

MultiChoiceQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
};

export default MultiChoiceQuiz;
