import React from "react";
import { Input } from "antd";
import styles from "./styles.module.scss";

const { Search } = Input;

function HeaderSearch() {
  return (
    <span>
      <Search
        placeholder="input search text"
        onSearch={value => console.log(value)}
        style={{ width: 200 }}
      />
    </span>
  );
}

export default HeaderSearch;
