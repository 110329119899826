import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Table, Typography, Button, Icon, Tooltip, Skeleton } from "antd";
import { ConstantsContext } from "utils/ConstantsContext";
import { values, filter } from "lodash";
import AccountApis from "../../../utils/accountApis";

const { Title, Text } = Typography;

const pageCount = 10;
const ClassroomStudentList = (props) => {
  const { classroomData, classroomId, orgId, latestLectureId } = props;
  // const { students, classroomId } = classroomData;
  const context = React.useContext(ConstantsContext);
  const [loading, setLoading] = useState(false);
  const [columns, setColums] = useState(null);
  const [students, setStudents] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, totalPages: 10, });

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    // this.setState(
    //   {
    //     page: pagination.current - 1
    //   },
    //   () => {
    //     this.getClassroomStudentList(
    //       orgId,
    //     classroomId,
    //     pagination.page,
    //     pageCount
    //     );
    //   }
    // );
    setPagination({
      page: pagination.current - 1,
      totalPages: pagination.totalPages,
    });
    getStudentList(pagination.current - 1);
  };

  function getStudentList(page) {
    setLoading(true);
    AccountApis.getClassroomStudentList(
      orgId,
      classroomId,
      page,
      pageCount,
      null,
      true
    ).then((res) => {
      console.log("get classroom student list: ", res);
      setStudents(res.list);
      setPagination({
        page: res.page,
        totalPages: res.totalPages,
      });
      setLoading(false);
    });
  }

  useEffect(() => {
    if (classroomId) {
      getStudentList(pagination.page);
      const _latestLectureId = latestLectureId;
      const buttonStyle = { margin: '2px 2px' };

      let _columns = [
        {
          title: "이름",
          key: "name",
          dataIndex: "name",
          render: (text, record) => (
            <>
              <a onClick={() => props.history.push(`/admin/student/detail/${record.studentId}`)}>{text}</a>
              {record.description && (
                <Tooltip title={record.description}>
                  <Icon
                    type="info-circle"
                    // theme="twoTone"
                    style={{ marginLeft: 4 }}
                    color="#333333"
                  />
                </Tooltip>
              )}
            </>
          ),
        },
        {
          title: "아이디",
          key: "account",
          dataIndex: "account",
          render: (text, record) => (
            <a onClick={() => props.history.push(`/admin/student/detail/${record.studentId}`)}>{text}</a>
          )
        },
        {
          title : "나이",
          key: "age",
          dataIndex: "birthday",
          render: (birthday) => birthday && birthday.age + "세"
        },
        {
          title : "생년월일",
          key: "birthday",
          dataIndex: "birthday",
          render: (birthday) => birthday && birthday.year + "-" +birthday.month + "-" +birthday.day
        },
        {
          title: "학교",
          key: "school",
          dataIndex: "school",
          render: (text, record) =>
           (record.school)
        },
        {
          title: "학부모 연락처",
          key: "parentMobile",
          dataIndex: "parentMobile",
        },
        {
          title: "최근과제/깨처",
          align: "center",
          key: "homeworks",
          render: (text, record, index) => {
            let lectureName = (record.prevScore? record.prevScore.lectureName : '(과제없음)');
            let quetureCount = (record.prevScore && record.prevScore.homeworks) ?
               record.prevScore.homeworks.filter((h) => h.homework.type == 'queture').length : 0;

            return (
               <span>
               {lectureName}
               {' / '}
               {quetureCount > 0 ? <font color="blue">{quetureCount}</font> : <font color="orange">0</font>}
               </span>
             );
          }
       },
       {
          title: "최근 학습리포트 채점/메모/발행/상담/보기",
          align: "center",
          key: "report",
          render: (text, record, index) =>
            !(record.report && record.report.lectures && record.report.lectures.length > 0) ? '-' :
            <span>
               {record.report.courseNo > record.report.courseCompleted ? '(코스 미완료) / ' :
            <>
            {(record.report.examResult && record.report.examSolved > 0) ?
                 <font color="green">&nbsp;&nbsp;{Math.round((100*record.report.examCorrect + 50*record.report.examPartial) / record.report.examSolved)}%&nbsp;</font>
               : <font color="red">{!record.report.examAbsent ? "미채점": "결시"}</font>}
            {' / '}
            {record.report.comment ?
                 <Tooltip title={record.report.comment}><font color="green">&nbsp;등록&nbsp;</font></Tooltip>
               : <font color="red">미등록</font>}
            {' / '}
            {(record.report.reportLogId && record.report.reportLogTime) ?
                 <Tooltip title={record.report.reportLogId}><font color="green">&nbsp;발행&nbsp;</font></Tooltip>
               : <font color="red">미발행</font>}
            {' / '}
            {record.report.consult ?
                 <Tooltip title={record.report.consult}><font color="green">&nbsp;완료&nbsp;</font></Tooltip>
               : <font color="red">미완료</font>}
            {' / '}
            </>}
            <Button
              onClick={() => props.history.push(`/admin/student/detail/${record.studentId}/report/0`)}>
              {(record.report.lectures.length > 1 ?
                   String(record.report.lectures[0].title).replace('회차','').replace('강','')+'~'+record.report.lectures[record.report.lectures.length-1].title
                 : record.report.lectures[0].title)}
            </Button>
            </span>
        },
      ];
      // if (context.type === "school") {
      //   _columns = [
      //     ..._columns,
      //     {
      //       title: "직전복습 여부",
      //       key: "review",
      //       dataIndex: "prevScore",
      //       render: (text, record) => (
      //         <Icon type={`${text && text.review ? "check" : "close"}`} />
      //       ),
      //     },
      //     {
      //       title: "직전 정답률",
      //       key: "correctRate",
      //       dataIndex: "prevScore",
      //       render: (text, record) => (
      //         <span>
      //           {text ? (text.correctRate * 100).toFixed(0) + "%" : "0%"}
      //         </span>
      //       ),
      //     },
      //     {
      //       title: "평균 복습점수",
      //       key: "avgReviewScore",
      //       dataIndex: "avgReviewScore",
      //       render: (text) => <span>{`${text}점`}</span>,
      //     },
      //     {
      //       title: "총 복습률",
      //       key: "totalReview",
      //       dataIndex: "reviewRate",
      //       render: (text, record) => {
      //         return <span>{(text || 0) + "%"}</span>;
      //       },
      //     },
      //     {
      //       title: "QP 총량",
      //       key: "totalPoints",
      //       dataIndex: "totalPoints",
      //       render: (text, record) => <span>{`${text} QP`}</span>,
      //     },
      //     {
      //       title:
      //         classroomData &&
      //         classroomData.course &&
      //         classroomData.course.title + "QP",
      //       key: "point",
      //       dataIndex: "point",
      //       render: (text, record) => <span>{`${text} QP`}</span>,
      //     },
      //   ];
      // }
      setColums(_columns);
    }

    return () => {
      setPagination({ page: 0, totalPages: 10 });
      setStudents([]);
      setColums(null);
    };
  }, [classroomId]);

  console.log("latestLectureId: ", latestLectureId);

  return (
    <>
      {columns && classroomId && (
        <>
          <Row type="flex" justify="space-between">
            <Title level={4}>{`수강 학생 목록(${students.length})`}</Title>
            {/*
            <Button
              onClick={() => {
                if (students.length === 0) {
                  return;
                }
                props.history.push(
                  `/admin/class/detail/${classroomId}/student/${students[0].studentId}`
                );
              }}
            >
              <Icon type="edit" />
              학생 출결 및 평가관리
            </Button>
            */}
          </Row>
          <Table
            style={{ margin: "24px 0" }}
            loading={loading}
            dataSource={students}
            rowKey={"studentId"}
            columns={columns}
            onChange={onChange}
            pagination={{
              defaultPageSize: pageCount,
              total: pagination.totalPages * pageCount,
            }}
          ></Table>
        </>
      )}
    </>
  );
};

export default withRouter(ClassroomStudentList);

