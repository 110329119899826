// import "core.js";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
