import React, { Component } from "react";
import NoticeApis from "../../../../utils/noticeApis";
import {
  Skeleton,
  PageHeader,
  Typography,
  Row,
  Col,
  message,
  Divider,
  Button,
  Popconfirm
} from "antd";
import { withRouter } from "react-router-dom";
import Editor from "../../components/Editor/Editor";

const { Title, Text } = Typography;
class NoticeEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null
    };
  }

  componentDidMount() {
    const { noticeId } = this.props.match.params;

    console.log("notice id: ", noticeId);
    NoticeApis.getNotice(noticeId)
      .then(res => {
        console.log("notice detail: ", res);
        this.setState({
          data: res
        });
      })
      .catch(e => {
        message.warning("");
      });
  }

  render() {
    const { data } = this.state;
    const { noticeId } = this.props.match.params;
    return (
      <Skeleton loading={!data} active>
        <PageHeader title={<Title level={2}>공지 수정하기</Title>} />
        <div className="admin-page-content">
          <Row>
            <Col>
              {data && (
                <Editor
                  isEdit={true}
                  data={data}
                  orgId={this.props.orgId}
                  onSubmit={data => {
                    NoticeApis.editNotice(noticeId, data).then(r => {
                      message.success("저장 성공");
                      this.props.history.push("/admin/notice");
                    });
                  }}
                  onBack={() => {
                    this.props.history.push("/admin/notice");
                  }}
                />
              )}
            </Col>
          </Row>
        </div>
      </Skeleton>
    );
  }
}
export default withRouter(NoticeEditPage);
