import React from "react";
import axios from "axios";
import { getHost, getDefaultConfig } from "utils/APIUtils";

export function log(location) {
  return axios
    .post(
      `${getHost()}/learning/v2/logs/activity`,
      {
        action: "web",
        location: location.pathname,
        query: location.search
      },
      {
        ...getDefaultConfig()
      }
    )
    .then(r => {})
    .catch(e => {
      console.log("failed to log");
    });
}

export function actionLog(menu, action, data) {
  return axios
    .post(
      `${getHost()}/learning/v2/logs/activity`,
      {
        menu: menu,
        action: action,
        data: data
      },
      {
        ...getDefaultConfig()
      }
    )
    .then(r => {})
    .catch(e => {
      console.log("failed to log");
    });
}
