import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as headerActions from "store/modules/header";
import styled from "styled-components";
import { ReactComponent as play } from "./images/Amediaplayer-play-btn.svg";
import { ReactComponent as stop } from "./images/stop.svg";
// import './css/Player.scss';
import ShakaPlayer from "./player/ShakaPlayer";
// import Slider from "rc-slider/lib/Slider";
import { Slider } from "antd";
import screenfull from "screenfull";
import classNames from "classnames";
import noScroll from "no-scroll";

import "./css/Player.scss";

import {
  isMobileOnly,
  osName,
  osVersion,
  browserName,
  browserVersion,
  isIE,
  isEdge,
  isChrome,
  isAndroid,
  isIOS,
  isSafari,
  isMobileSafari,
} from "react-device-detect";
import BrowserAlert from "components/Popup/BrowserAlert";

import { actionLog } from "utils/LogUtils";
import { getHost } from "utils/APIUtils";
import PlayAnimation from "./PlayAnimation";
import { isIPadSafari } from "utils/utils";
import SockJsClient from "react-stomp";

const Playbtn = styled(play)`
  .fill {
    ${(props) => props.fill}
  }
`;

const Stop = styled(stop)`
  .fill {
    background: ${(props) => props.fill};
  }
`;

//let CHANNEL_CODE = "REMOCON_CHANNEL";
//let listenChannel = (window.BroadcastChannel)? new BroadcastChannel(CHANNEL_CODE) : null;

const useHls = true;
class QueturePlayer extends Component {
  _mounted = false;

  first = true;

  drm = null;

  dimTimeout = null;
  progressTimeout = null;

  logged = false;
  listenChannel = null;

  state = {
    loadTime: 0,
    pending: true,
    buffering: false,
    completed: false,
    over: false,
    playing: false,
    progressMoving: null,
    progress: 0,
    time: 0,
    volume: 80,
    mute: false,
    speed: 1,
    resolutions: [],
    autoResolution: true,
    currentResolution: null,
    resolutionName: null,
    fullscreen: false,
    seeking: false,
    maxProgress: 0,
    mouseover: false,
    skipConfirm: {
      open: false,
    },

    sudden: false,
    alert: {
      title: null,
      message: null,
    },
    showLogoPlayer: true,
    moreSpeedOpen: false,
    qualityOpen: false,
    showProgressPopup: false,
    connected: false,
  };

  constructor(props) {
    super(props);
    // this.containerRef = React.createRef();
    this.playerRef = React.createRef();
  }

  onConnect = () => {
    console.log("connect from queture");
    this.setState({
      connected: true,
    });
  };

  onDisconnect = () => {
    this.setState({
      connected: false,
    });
  };

  onMessageReceive = (msg, topic) => {
    console.log("receive msg: ", msg);
    if (msg.type === "remote") {
      this.handleRemocon(msg);
    }
  };

  lockOrientation = (orientation) => {
    try {
      if (window.screen.orientation.lock) {
        window.screen.orientation.lock(orientation);
        console.log("Orientation locked");
      }
    } catch (e) {
      console.log("failed to lock orientation", e);
    }
  };

  unlockOrientation = () => {
    try {
      if (window.screen.orientation.unlock) {
        window.screen.orientation.unlock();
        console.log("Orientation unlocked");
      }
    } catch (e) {
      console.log("failed to unlock orientation", e);
    }
  };

  onFullscreenChanged = (e) => {
    const isFullscreen = screenfull.isFullscreen;

    console.log("fullscreen state", isFullscreen);

    this.setState({
      fullscreen: isFullscreen,
    });

    // if (isFullscreen) {
    //   this.lockOrientation("landscape");
    // } else {
    //   this.unlockOrientation();
    // }
  };

  onScreenRotation = () => {
    console.log("orientation changed");

    const { screen } = window;

    if (
      this.state.playing &&
      screen.orientation.type.includes("landscape") &&
      window.innerWidth < 620 &&
      !document.fullscreenElement
    ) {
      if (document.fullscreenElement) {
        screenfull.exit().then(() => {
          screenfull.request(this.containerRef);
        });
      } else {
        screenfull.request(this.containerRef);
      }
    } else if (
      screen.orientation.type.includes("portrait") &&
      document.fullscreenElement
    ) {
      screenfull.exit();
    }
  };

  componentDidMount() {
    this._mounted = true;

    if (screenfull.enabled) {
      screenfull.on("change", this.onFullscreenChanged);

      const { screen } = window;
      if (screen.orientation && isMobileOnly) {
        screen.orientation.addEventListener("change", this.onScreenRotation);
      }
    }
    if (window.BroadcastChannel) {
      this.listenChannel = new BroadcastChannel("REMOCON_CHANNEL");
      this.listenChannel.addEventListener("message", this.handleRemocon);
    }

    if (this.props.isOnline === false) {
      this.changeVolume(0);
    }

    if (this.progressTimeout == null && this.props.viewType == "guide") {
      this.progressTick();
    }
  }

  handleRemocon = (res) => {
    const type = res.data && res.data.type;
    const value = res.data && res.data.value;
    console.log("remocon type: ", type);
    console.log("remocon value: ", value);
    if (!type) {
      return null;
    }

    switch (type) {
      case "seek":
        this.changeProgress(value);
        console.log(value + "progress");
        this.seek(value);
        break;
      case "togglePlay":
        this.togglePlay(false);
        break;
      case "replay":
        this.replay();
        break;
      case "speed":
        this.changeSpeed(value);
        break;
      case "volume":
        if (this.props.viewType == "guide" && this.props.isRemote == false) {
          return;
        } else {
          this.changeVolume(value);
        }
        break;
      case "progress":
        const _progress =
          this.state.progress + (value / this.state.duration) * 100;
        console.log("progress: ", this.state.progress);
        console.log("_progress: ", _progress);

        this.seek(_progress);
        break;
      case "asyncTime":
        if (value - this.state.time >= 0.8 || value - this.state.time <= -0.8) {
          // 현재시간과 0.8초 이상 차이나면 실행
          const _progress =
            this.state.progress +
            ((value - this.state.time) / this.state.duration) * 100;
          this.seek(_progress);
        }
        if (res.data.speed != this.state.speed) {
          this.changeSpeed(res.data.speed);

          console.log(res.data.speed + "스피드" + this.state.speed);
        }
        break;
      case "asyncSetting":
        if (this.props.viewType === "guide") {
          this.asyncSetting();
        }
        break;
      case "settingData":
        if (this.props.viewType !== "guide") {
          const _settingData =
            this.state.progress +
            ((value.time - this.state.time) / this.state.duration) * 100;
          this.seek(_settingData);

          this.changeSpeed(value.speed);

          this.setState({
            playing: value.playing,
          });

          console.log(value.playing);
          value.playing ? this.playerRef.play() : this.playerRef.pause();
        }
        break;
      case "fullScreen":
        if (this.props.viewType !== "guide") this.toggleFullScreen();
        break;
      default:
        break;
    }
  };

  componentDidUpdate(nextProps) {}

  componentWillUnmount() {
    console.log("componentWillUnmount");
    this._mounted = false;
    if (screenfull.enabled) {
      screenfull.off("change", this.onFullscreenChanged);

      const { screen } = window;
      if (screen.orientation) {
        screen.orientation.removeEventListener("change", this.onScreenRotation);
      }
    }
    this.props.HeaderActions.handleHeader(false);
    noScroll.off();
    if (window.BroadcastChannel) {
      //let listenChannel = new BroadcastChannel("REMOCON_CHANNEL");
      this.listenChannel.removeEventListener("message", this.handleRemocon);
    }
    if (this.state.connected) {
      this.onDisconnect();
    }
    console.log("_componentWillUnmount");
  }

  componentWillMount() {
    if (this.props.autoplay && !isIOS) {
      this.setState({
        buffering: true,
      });
    }
    if (this.props.diffHours > 6 && this.props.productType === "junior") {
      this.setState({
        showProgressPopup: true,
      });
    }

    var drm = null;

    const { content } = this.props;
    if (content != null && content.url) {
      this.drm = "none";
      this.setState({
        pending: false,
      });
      return;
    }

    // const useHls = this.props.user?.privateFeatures?.useHls || false;

    if (useHls) {
      // content.url = `${getHost()}/blob/v2/bundles/${
      //   content.data.contents.bundleId
      // }/items/${content.type}/hls`;
      const _content = content;
      if (_content.type !== "main") {
        if (_content.data.contents.items[_content.type] == null) {
          _content.type = "main";
        }
      }

      content.url = `${getHost()}${
        content.data.contents.items[_content.type]?.hlsv2
      }`;
      this.drm = "none";
      console.log("set content: ", content);
      return;
    }

    if (!isIOS && isChrome) {
      drm = "Widevine";
    } else if (osName === "Windows") {
      if (
        parseInt(osVersion) > 8 &&
        ((isIE && browserVersion >= "11") || isEdge)
      ) {
        drm = "Playready";
      }
    } else if (isAndroid && (isChrome || browserName === "Chrome WebView")) {
      drm = "Widevine";
    } else if (isIOS && isMobileSafari) {
      drm = "Fairplay";
    } else if (isIPadSafari()) {
      drm = "Fairplay";
    } else if (isIOS && browserName === "WebKit") {
      // drm = 'WKWebView';
      // drm = 'Fairplay';
      content.url = `${getHost()}/blob/v2/bundles/${
        content.data.contents.bundleId
      }/items/${content.type}/hls`;
      drm = "none";
    } else if (isSafari) {
      drm = "Fairplay";
    }

    this.drm = drm;
  }

  alertDrm = (code, data) => {
    const { onDrmFail } = this.props;

    if (code === 6008) {
      if (onDrmFail) {
        onDrmFail(code);
      }
    }

    if (isSafari || isMobileSafari) {
      return;
    }

    actionLog("error", "drm", {
      code: code,
      data: data,
    });

    this.setState({
      alert: {
        title: `영상 재생에 실패했습니다. (${code})`,
        message: (
          <p>
            {"현재 사용중이신 브라우저에서는 영상을 재생할 수 없습니다."}
            <br />
            {"크롬 브라우저를 이용하시면 깨봉을 원활하게 학습하실 수 있습니다."}
          </p>
        ),
      },
    });

    this.drm = null;
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { content: currentContent } = this.props;
    const { content: nextContent } = nextProps;

    if (
      currentContent == null ||
      currentContent.quetureId !== nextContent.quetureId
    ) {
      return true;
    } else if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
      return true;
    }

    return false;
  }

  onLoaded = (data) => {
    this.setState({
      pending: false,
    });

    const resolutions = [];

    for (let key in data.resolutions) {
      resolutions.push(data.resolutions[key]);
    }

    resolutions.sort((a, b) => {
      const key1 = a.name;
      const key2 = b.name;

      if (a.name.length !== b.name.length) {
        return b.name.length - a.name.length;
      }

      return key1 < key2 ? 1 : key1 > key2 ? -1 : 0;
    });

    console.log("video data: ", data);
    this.setState({
      resolutions: resolutions,
      duration: data.duration,
      time: data.time,
    });
  };

  formatTime = (time) => {
    if (time == null) {
      return "00:00";
    }
    const min = parseInt(time / 60);
    const sec = parseInt(time % 60);

    return min.zf(2) + ":" + sec.zf(2);
  };

  progressTick = () => {
    const { onProgressUpdate, isStopAsyncMode } = this.props;
    console.log("progressTick " + this.state.time + " " + this.state.speed);

    if (/*!onProgressUpdate ||*/ !this._mounted || this.state.completed) {
      console.log(
        "progressTick componentWillUnmount" +
          this.state.completed +
          " " +
          this._mounted +
          " " +
          onProgressUpdate
      );
      return;
    }

    if (isStopAsyncMode === true) {
      const _data = {
        type: "asyncTime",
        value: this.state.time,
        speed: this.state.speed,
      };
      if (this.props.isOnline === true) {
        this.props.onSendMessage(_data);
      } else {
        this.listenChannel.postMessage(_data);
      }
    }

    //onProgressUpdate(this.state.time);

    this.progressTimeout = setTimeout(this.progressTick, 10000);
  };

  onStateChanged = (e) => {
    const {
      onStateChanged,
      onCompleted,
      onTogglePlay,
      onProgressUpdate,
      suddenTime,
      content,
      transaction,
    } = this.props;
    const { name, value } = e;
    switch (name) {
      case "play":
        this.setState({
          playing: value,
        });
        break;
      case "progress":
        const { progress, time, duration } = value;
        // console.log("progress : " + time);
        if (!this.state.seeking) {
          this.setState({
            progress: progress,
            time: time,
            maxProgress: Math.max(this.state.maxProgress, progress),
          });

          // console.log("progress: ", progress);
          if (progress >= 80 && onCompleted) {
            if (onTogglePlay) {
              onTogglePlay(false);
            }
            onCompleted();
          }

          if (progress >= 90) {
            const learnType = transaction ? transaction.type : "review";
            if (
              !this.logged &&
              content &&
              content.data &&
              content.data.quetureId &&
              (content.type === "main" ||
                content.data.contents.items[content.type] == null)
            ) {
              this.logged = true;
              actionLog("queture", "play", {
                quetureId: content.data.quetureId,
                learnType: learnType,
              });
            }
          }

          if (progress >= 100 || (duration != null && time + 1 > duration)) {
            console.log("completed");

            this.setState({
              completed: true,
              buffering: false,
            });

            if (onProgressUpdate) {
              onProgressUpdate(time);
            }

            if (this.progressTimeout) {
              clearTimeout(this.progressTimeout);
            }

            if (this.state.fullscreen || document.fullscreenElement) {
              this.toggleFullScreen();
            }

            if (this.props.onEnd) {
              this.props.onEnd();
            }
          }
        }

        if (
          suddenTime != null &&
          !this.state.sudden &&
          suddenTime <= parseInt(time)
        ) {
          this.pause();

          this.setState({
            sudden: true,
          });
        }
        break;
      case "buffering":
        const isBuffering = value;
        console.log("buffering state changed", isBuffering);
        this.setState({
          buffering: isBuffering,
        });
      default:
        break;
    }

    if (onStateChanged != null) {
      onStateChanged(e);
    }
  };
  asyncSetting() {
    const { playing, time, speed, volume } = this.state;

    const _data = {
      type: "settingData",
      value: {
        playing: playing,
        time: time,
        speed: speed,
      },
    };

    if (this.props.isRemote === true) {
      this.props.onSendMessage(_data);
    } else {
      this.listenChannel.postMessage(_data);
    }
  }
  togglePlay = (showControl = true) => {
    const { onTogglePlay, onPlayFromQR } = this.props;
    const { playing } = this.state;

    var promise;

    if (onPlayFromQR) {
      onPlayFromQR();
    }

    if (playing) {
      console.log("pause");
      promise = this.playerRef && this.playerRef.pause();
      if (onTogglePlay) {
        onTogglePlay(false);
      }
    } else {
      console.log("play", this.playerRef);

      const { screen } = window;

      if (
        isMobileOnly &&
        isAndroid &&
        this.first &&
        screen.orientation &&
        !screen.orientation.type.includes("landscape") &&
        window.innerWidth < 767 &&
        !document.fullscreenElement
      ) {
        this.toggleFullScreen();
      }
      this.first = false;
      promise = this.playerRef && this.playerRef.play();
      if (onTogglePlay) {
        onTogglePlay(true);
      }
    }
    if (showControl) {
      this.showControls();
    }

    if (promise) {
      promise
        .then((d) => {
          console.log("success", d);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log("promise is null");
    }
  };

  pause = () => {
    const { playing } = this.state;
    const { player } = this.refs;

    var promise = this.playerRef && this.playerRef.pause();

    if (promise) {
      promise
        .then((d) => {})
        .catch((e) => {
          console.log(e);
        });
    }
  };

  changeVolume = (value) => {
    const { player } = this.refs;
    this.setState({
      volume: value,
    });
    this.playerRef && this.playerRef.volume(value / 100);
  };

  changeProgress = (value) => {
    const { player } = this.refs;
    const { disableSeek } = this.props;
    const { maxProgress } = this.state;
    console.log("change progress", value);
    if (disableSeek && maxProgress < value) {
      return;
    }

    // if (disableSeek && value > this.state.progress) {
    //   return;
    // }
    this.setState({
      progress: value,
    });
  };

  toggleMute = () => {
    console.log("toggleMute1");
    if (this.state.mute) {
      this.setState({
        mute: false,
      });
      this.changeVolume(80);
    } else {
      this.setState({
        mute: true,
      });
      this.changeVolume(0);
    }
  };

  startProgressMove = (e) => {
    this.setState({
      progressMoving: this.checkPosition(e),
    });
  };

  moveProgress = (e) => {
    if (this.state.progressMoving) {
      this.setState({
        progressMoving: this.checkPosition(e),
      });
    }
  };

  checkPosition = (e) => {
    let currTargetRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - currTargetRect.left;
    const width = currTargetRect.width;
    return (x / width) * 100;
  };

  seek = (value) => {
    const { onSkip } = this.props;
    const { player } = this.refs;

    if (
      this.state.skipConfirm.open ||
      (value > this.state.currProgress && onSkip)
    ) {
      this.playerRef && this.playerRef.pause();
      this.setState({
        skipConfirm: {
          open: true,
          value: value,
        },
      });
    } else {
      this.playerRef && this.playerRef.seek(value);
    }
    if (this.props.viewType === "guide") {
      const _data = {
        type: "seek",
        value: value,
      };
      console.log(this.state.time + "progress");
      if (this.props.isRemote === true) {
        this.props.onSendMessage(_data);
      } else {
        this.listenChannel.postMessage(_data);
      }
    }
  };

  closeConfirm = () => {
    const { player } = this.refs;

    this.playerRef && this.playerRef.play();
    this.setState({
      skipConfirm: {
        open: false,
      },
    });
  };

  handleResolutionChanged = (id) => {
    const { resolutions } = this.state;

    const sel = resolutions.filter(
      (r) => r.id === id || r.languages.some((l) => l.id === id)
    );
    const curr = sel.length > 0 ? sel[0].id : null;
    const currName = sel.length > 0 ? sel[0].name : null;

    console.log("curr", curr);
    this.setState({
      currentResolution: curr,
      resolutionName: currName,
    });
  };

  changeResolution = (id) => {
    const { player } = this.refs;
    if (id === "auto") {
      this.setState({
        autoResolution: true,
        resolutionName: "자동",
        over: false,
      });
    } else {
      this.setState({
        autoResolution: false,
        over: false,
      });
    }
    this.playerRef && this.playerRef.changeResolution(id);
  };

  changeSpeed = (sp) => {
    const { player } = this.refs;
    this.setState({
      speed: sp,
      over: false,
    });
    this.playerRef && this.playerRef.changeSpeed(parseFloat(sp));
  };

  toggleControls = () => {
    const { over } = this.state;
    if (over) {
      this.hideControls();
    } else {
      this.showControls();
    }
  };

  showControls = () => {
    if (this.dimTimeout != null) {
      clearTimeout(this.dimTimeout);
    }
    this.dimTimeout = setTimeout(() => {
      this.hideControls();
    }, 3000);

    this.setState({
      over: true,
    });
  };

  hideControls = () => {
    if (this.dimTimeout) {
      clearTimeout(this.dimTimeout);
    }
    this.setState({
      over: false,
    });
  };

  toggleFullScreen = () => {
    // this.setState({
    //   fullscreen: !this.state.fullscreen
    // })
    // const { playerContainer } = this.refs;

    if (
      (isMobileSafari || isSafari || (isIOS && isChrome)) &&
      !isIPadSafari()
    ) {
      const { QuebotActions, HeaderActions } = this.props;
      const nextFullScreen = !this.state.fullscreen;
      this.setState(
        {
          fullscreen: nextFullScreen,
        },
        () => {
          // HeaderActions.handleHeader(nextFullScreen);
        }
      );

      if (nextFullScreen) {
        // QuebotActions.hideQuebot();
        // noScroll.on();
      } else {
        // QuebotActions.showQuebot();
        // noScroll.off();
      }
      return;
    }

    if (screenfull.enabled) {
      // console.log(player);
      console.log("requesting full screen", this.containerRef);

      if (document.fullscreenElement) {
        screenfull.exit().then(() => {
          // if (!isIPadSafari()) {
          //   this.unlockOrientation();
          // }
        });
      } else {
        // if (isChrome) {
        //   this.playerRef.toggleFullscreen().then(r => {
        //     this.lockOrientation("landscape");
        //   })
        // } else {
        screenfull.request(this.containerRef).then((r) => {
          // if (!isIPadSafari()) {
          //   this.lockOrientation("landscape");
          // }
        });
        // }
      }
    } else {
      console.log("fullscreen is not available");
    }
  };

  handleDrmFail = (code) => {
    this.alertDrm(code);
  };

  replay = () => {
    const { player } = this.refs;
    console.log("replay");

    this.setState({
      completed: false,
    });

    this.playerRef && this.playerRef.seek(0);
    this.playerRef && this.playerRef.play();
  };

  handleKeyDown = (e) => {
    // console.log('keyCode: ', e.keyCode);
    if (this.props.noControll) {
      return;
    }
    switch (e.keyCode) {
      case 32:
        this.togglePlay();
        break;
      case 37:
        break;
      case 39:
        // right
        break;
      case 38:
        // up
        break;
      case 40:
        // down
        break;
      default:
        break;
    }
  };

  render() {
    const {
      isStopAsyncMode,
      userId,
      userIdOverride,
      content,
      style,
      disableSpeed,
      autoplay,
      disableSeek,
      seekable,
      openType,
      initPos,
      noControll,
      suddenQuiz,
      stopAsyncMode,
      user,
    } = this.props;
    const {
      playing,
      progress,
      volume,
      speed,
      fullscreen,
      duration,
      time,
      videoJs,
      resolutions,
      autoResolution,
      showProgressPopup,
      currentResolution,
      resolutionName,
      showLogoPlayer,
    } = this.state;
    const { over } = this.state;
    const { mouseover } = this.state;

    // console.log(time + "타임");
    const progressPos = this.state.progressMoving
      ? this.state.progressMoving
      : progress;

    // const _userId =
    //   process.env.REACT_APP_TEST === "true"
    //     ? "testuserid"
    //     : userIdOverride
    //     ? userIdOverride
    //     : userId;
    let _userId = `testuserid`;
    if (useHls) {
      const orgType = user?.organization?.type || "";
      const userType = user?.type || "";
      _userId = `${orgType}_${
        userType === "academy_student" ? "std" : userType
      }_${userId}`;
    }
    // const _userId = userIdOverride ? userIdOverride : userId

    const _content = content;
    if (_content.type !== "main") {
      if (_content.data.contents.items[_content.type] == null) {
        _content.type = "main";
      }
    }

    const drmContent = {
      ..._content,
      drm: this.drm,
    };

    var containerStyle = style;
    if ((isMobileSafari || isSafari) && this.state.fullscreen) {
      containerStyle = {
        ...style,
        position: "fixed",
        width: "100%",
        height: "100%",
        maxWidth: "unset",
        maxHeight: "unset",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        zIndex: 999,
        background: "#000",
      };
    }

    var playerStyle = {};
    if ((isSafari || isMobileSafari) && this.state.fullscreen) {
      playerStyle = {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
      };
    }

    const loading = (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          background: "black",
          borderRadius: "8px",
        }}
      />
    );

    const isLoading =
      this.state.pending || (this.state.playing && this.state.buffering);
    return (
      <div
        ref={(e) => (this.containerRef = e)}
        className={classNames("playerWrap", {
          "is-end": this.state.completed,
          zoomin: (screenfull && screenfull.isFullscreen) || fullscreen,
        })}
        style={containerStyle}
        onMouseEnter={(e) => {
          this.showControls();
        }}
        onMouseMove={(e) => {
          this.showControls();
        }}
        onMouseLeave={() => this.hideControls()}
        onTouchEnd={(e) => {
          e.stopPropagation();
          console.log("over: ", over);
          console.log("e: ", e.target);
          if (over && e.target.id === "mobilePlayerDim") {
            e.preventDefault();
            this.hideControls();
          } else {
            this.showControls();
          }
        }}
        onClick={(e) => {
          if (noControll) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          if (e.target.id === "queture-player") {
            this.togglePlay();
          }
        }}
        tabIndex="0"
        onKeyDown={(e) => this.handleKeyDown(e)}
      >
        <div className="playerWrap__wrap">
          <>
            <div className="playerWrap__title">
              <span>{this.props.seq}번째 깨처</span>
            </div>
            <ShakaPlayer
              onClick={
                this.props.viewType !== "guide" &&
                this.props.noControll === true
                  ? this.toggleFullScreen
                  : function () {}
              }
              ref={(e) => (this.playerRef = e)}
              // userId={`academy:${_userId}`}
              userId={_userId}
              onLoaded={this.onLoaded}
              onStateChanged={this.onStateChanged}
              onResolutionChange={this.handleResolutionChanged}
              isFullscreen={this.state.fullscreen}
              content={drmContent}
              resolution="auto"
              playerStyle={playerStyle}
              initPos={initPos}
              onDrmFail={this.handleDrmFail}
              controls={false}
              autoplay={this.props.autoplay === true}
            />
            <PlayAnimation playing={playing} />
            {this.state.completed &&
              this.state.progress >= 100 &&
              !noControll && (
                <div className="end">
                  <div className="end-ment">
                    <strong>영상이 끝났습니다</strong>
                    <span
                      className="img"
                      onClick={() => {
                        this.replay();
                      }}
                    ></span>
                    <span
                      className="repeat"
                      onClick={() => {
                        this.replay();
                      }}
                    >
                      다시보기
                    </span>
                  </div>
                  <div
                    className={classNames("end-ment none", {
                      //  오답문제 is-false
                      //  다음 예제 보기  is-next
                      //  문제 다시 풀기 is-repeat
                    })}
                  >
                    <strong>{<>오답 문제 풀기</>}</strong>
                    <span
                      className="img"
                      onClick={() => {
                        this.replay();
                      }}
                    ></span>
                    <span
                      className="repeat"
                      onClick={() => {
                        this.replay();
                      }}
                    >
                      끝내기
                    </span>
                  </div>
                  {this.props.onClose && (
                    <div className="end-ment close-btn">
                      <span
                        className="img"
                        onClick={() => {
                          this.props.onClose();
                        }}
                      ></span>
                      <span
                        className="repeat"
                        onClick={() => {
                          this.props.onClose();
                        }}
                      >
                        끝내기
                      </span>
                    </div>
                  )}
                  {this.props.onPlayFullQueture && (
                    <div
                      className="full-view"
                      onClick={this.props.onPlayFullQueture}
                    >
                      <span className="img"></span>
                      <div className="full">전체 영상 보기</div>
                    </div>
                  )}
                </div>
              )}
            {/*선생님 깨처 프로그레스바*/}
            <div
              className={classNames("controls", {
                on: true,
                none: this.props.viewType !== "guide",
              })}
              style={{ height: "3.132em", fontSize: "5pt" }}
            >
              <span
                className={"progressbar"}
                style={{ height: "1.7em", margin: -5, width: "81%" }}
              >
                <Slider
                  className="progress-bar"
                  value={this.state.progress}
                  min={0}
                  max={100}
                  step={100 / this.state.duration}
                  onBeforeChange={() => {
                    this.setState({
                      seeking: true,
                      currProgress: this.state.progress,
                    });
                  }}
                  tipFormatter={(value) => {
                    const _progress = Number.parseInt(value);
                    const _duration = this.state.duration * (_progress / 100);
                    return this.formatTime(_duration);
                  }}
                  onChange={this.changeProgress}
                  onAfterChange={(v) => {
                    console.log("handle after change progress");
                    this.setState({
                      seeking: false,
                    });
                    //stopAsyncMode();
                    this.seek(v);
                  }}
                />
              </span>
              <span className="timer" style={{ fontSize: "9pt" }}>
                <span className="pass-title">
                  {this.formatTime(this.state.time)}
                </span>
                <span className="full-time">
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  {this.formatTime(this.state.duration)}
                </span>
              </span>
            </div>
            {/*학생 숙제 깨처 프로그레스바*/}
            <div
              className={classNames("controls", {
                on: (over && !this.state.completed) || this.state.buffering,
                none: this.state.completed || noControll,
              })}
            >
              <div
                className={playing ? "play-btn-t is-pause" : "play-btn-t"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.togglePlay();
                }}
              >
                {playing ? (
                  <Stop fill="rgba(255, 255, 255, 0.1)" />
                ) : (
                  <Playbtn fill="rgba(255, 255, 255, 0.1)" />
                )}
              </div>
              <div className={"progressbar"}>
                <Slider
                  className="progress-bar"
                  value={this.state.progress}
                  min={0}
                  max={100}
                  step={100 / this.state.duration}
                  onBeforeChange={() => {
                    console.log("before change: ", this.state.progress);
                    this.setState({
                      seeking: true,
                      currProgress: this.state.progress,
                    });
                  }}
                  tipFormatter={(value) => {
                    const _progress = Number.parseInt(value);
                    const _duration = this.state.duration * (_progress / 100);
                    return this.formatTime(_duration);
                  }}
                  onChange={this.changeProgress}
                  onAfterChange={(v) => {
                    console.log(v);
                    this.setState({
                      seeking: false,
                    });

                    this.seek(v);
                  }}
                />
              </div>
              <div className="timer">
                <span className="pass-title">
                  {this.formatTime(this.state.time)}
                </span>
                <span className="full-time">
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  {this.formatTime(this.state.duration)}
                </span>
              </div>
              {!isIOS && resolutions.length > 1 && (
                <div
                  className="quality"
                  onClick={(e) => {
                    this.setState({
                      qualityOpen: !this.state.qualityOpen,
                      moreSpeedOpen: false,
                    });
                  }}
                >
                  <span className="select">{resolutionName}</span>
                  <ul>
                    <li onClick={() => this.changeResolution("auto")}>
                      {"자동"}
                    </li>
                    {resolutions.map((r) => {
                      const active = currentResolution === r.id;

                      return (
                        <li
                          key={r.id}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.changeResolution(r.id);
                          }}
                        >
                          {r.name}
                          {active && autoResolution ? "(자동)" : ""}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <div
                className="speed"
                onClick={(e) => {
                  this.setState({
                    moreSpeedOpen: !this.state.moreSpeedOpen,
                    qualityOpen: false,
                  });
                }}
              >
                <span>x {speed}</span>
                <ul>
                  <li
                    onClick={(e) => {
                      this.changeSpeed("0.8", e);
                    }}
                  >
                    x 0.8
                  </li>
                  <li onClick={(e) => this.changeSpeed("1.0", e)}>x 1.0</li>
                  <li onClick={(e) => this.changeSpeed("1.2", e)}>x 1.2</li>
                  {!disableSpeed && (
                    <>
                      <li onClick={(e) => this.changeSpeed("1.5", e)}>x 1.5</li>
                      <li onClick={(e) => this.changeSpeed("2.0", e)}>x 2.0</li>
                    </>
                  )}
                </ul>
              </div>
              <div
                className={classNames("speaker", {
                  mute: this.state.mute || this.state.volume === 0,
                })}
                onClick={this.toggleMute}
              ></div>
              <div className="volume">
                <Slider
                  value={this.state.volume}
                  min={0}
                  max={100}
                  step={1}
                  onChange={this.changeVolume}
                />
              </div>
              {/* zoomin시 zoomin 클래스 추가 */}
              <div
                className={classNames("zoom", {
                  zoomin: (screenfull && screenfull.isFullscreen) || fullscreen,
                })}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.toggleFullScreen();
                }}
                // onTouchEnd={e => {
                  onTouchEnd={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.toggleFullScreen();
                }}
              ></div>
            </div>
          </>
          {/* {this.drm !== "none" && (
            <BrowserAlert
              title={this.state.title}
              message={this.state.message}
            />
          )} */}
        </div>
        {this.props.isRemote && (
          <SockJsClient
            url={`${getHost()}/academy/controller`}
            topics={[`/topic/${this.props.classroomId}/all`]}
            onConnect={this.onConnect}
            onDisconnect={this.onDisconnect}
            onMessage={this.onMessageReceive}
            debug={false}
          />
        )}
      </div>
    );
  }
}

export default connect(
  ({ auth }) => ({
    userId: auth.user.userId,
    user: auth.user,
  }),
  (dispatch) => ({
    HeaderActions: bindActionCreators(headerActions, dispatch),
  })
)(QueturePlayer);
