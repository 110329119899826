import React from 'react';
import classNames from 'classnames';

const QuizBasicLayout = (props) => {
  const { type, isLong, imageComponent, problemComponent, choiceComponent, titleComponent } = props;
  const QuizAnswers = choiceComponent;
  const QuizProblem = problemComponent;
  const QuizImage = imageComponent;
  const QuizTitle = titleComponent;

  // console.log('quizProble: ', QuizProblem);

  return (
    <div className={classNames("layout type-a", {
      "long": isLong
    })}>
      {
        (type === 'drag_text' || type === 'select_text' || type === 'fill_blank') ?
          <>
            {/* <div className="mb16">
              <QuizTitle />
            </div> */}
            <div className="mb16">
              <QuizImage />
            </div>
            {
              QuizProblem() &&
              <div className="mb16">
                <QuizProblem />
              </div>
            }
          </>
          :
          <>
            {/* <div className="mb16">
              <QuizTitle />
            </div> */}
            {
              QuizProblem() &&
              <div className="mb16">
                <QuizProblem />
              </div>
            }
            <div className="mb16">
              <QuizImage />
            </div>
          </>
      }
      <QuizAnswers />
    </div>
  );
}

export default QuizBasicLayout;

