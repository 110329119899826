import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-with-font";
import axios from "axios";
import { getHost, getDefaultConfig } from "utils/APIUtils";

const defaultToolbar = {
  items: [
    "heading",
    "|",
    "bold",
    "italic",
    "fontColor",
    "fontBackgroundColor",
    "fontSize",
    "bulletedList",
    "numberedList",
    "|",
    "link",
    "imageUpload",
    "blockQuote",
    "insertTable",
    "mediaEmbed",
    "|",
    "undo",
    "redo"
  ]
};

function _uploadImage(basePath, file, callback) {
  let fileName;

  if (file.name.startsWith("image.")) {
    fileName =
      "image_" + new Date().getTime() + "." + file.name.split(".", 2)[1];
  } else {
    fileName = file.name;
  }

  const url = `${getHost()}/blob/v1/static/${basePath}/${fileName}`;

  return axios
    .post(url, file, {
      ...getDefaultConfig({
        "Content-Type": file.type
      })
    })
    .then(r => {
      callback(url);
    });
}

class ImageUploadAdapter {
  constructor(loader, basePath) {
    this.loader = loader;
    this.basePath = basePath ? basePath : "board";
  }

  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          return _uploadImage(this.basePath, file, url => {
            resolve({
              default: url
            });
          });
        })
    );
  }

  abort() {
    // Reject the promise returned from the upload() method.
  }
}

const RichEditor = props => {
  const { value, onChange, basePath, disabled, height, toolbar } = props;
  return (
    <div className="editor">
      <CKEditor
        disabled={disabled}
        config={{
          height: height ? height : 300,
          toolbar: toolbar ? toolbar : defaultToolbar
        }}
        onInit={editor => {
          editor.plugins.get("FileRepository").createUploadAdapter = loader => {
            return new ImageUploadAdapter(
              loader,
              `board/${basePath ? basePath : "default"}`
            );
          };
        }}
        editor={ClassicEditor}
        data={value}
        onChange={(event, editor) => {
          if (onChange) {
            onChange(editor.getData());
          }
        }}
      />
    </div>
  );
};

export default RichEditor;
