import React, { useState, useEffect } from "react";
import AudioPlayer from "components/Audio/AudioPlayer";
import BodyMovin from "components/BodyMovin/BodyMovin";
import bgm from "./sounds/quebon-song-bgm-high-vol.mp3";
import speechSound from "./sounds/quebon-song-speech-sound.mp3";
import speechAnimation from "./animations/quebon-song-speech.json";
import objAnimation from "./animations/quebon-song-obj.json";
import chaeliAnimation from "./animations/quebon-song-chaeli.json";
import ddamiAnimation from "./animations/quebon-song-ddami.json";
import quedaAnimation from "./animations/quebon-song-queda.json";

import "./quebon-song-animation.scss";

const QuebonSongAnimation = (props) => {
  const [isPlay, setPlay] = useState(false);

  const speechOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: speechAnimation,
  };

  const objOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: objAnimation,
  };
  const chaeliOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: chaeliAnimation,
  };
  const ddamiOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: ddamiAnimation,
  };
  const quedaOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: quedaAnimation,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPlay(true);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [isPlay]);

  return (
    <div id="quebonSongAnimation">
      <div className="img-title">{}</div>
      <div className="song-title">{props.title}</div>
      {isPlay && (
        <>
          <BodyMovin className="obj-anim" options={objOptions} />
          <div className="character-anim-wrap">
            <div className="queda character-anim">
            <BodyMovin className="speech-anim" options={speechOptions} />
              <BodyMovin options={quedaOptions} />
            </div>
            <BodyMovin
              className="ddami character-anim"
              options={ddamiOptions}
            />
            <BodyMovin
              className="chaeli character-anim"
              options={chaeliOptions}
            />
          </div>
          <AudioPlayer src={bgm} autoPlay={true} loop={false} />
          <AudioPlayer src={speechSound} autoPlay={true} />
        </>
      )}
    </div>
  );
};
export default QuebonSongAnimation;
