import React, { useState, useEffect } from "react";
import AudioPlayer from "components/Audio/AudioPlayer";
import BodyMovin from "components/BodyMovin/BodyMovin";
import bgm from "./sounds/mvp-bgm.mp3";
import characterSound from "./sounds/mvp-character-sound.mp3";
import speechSound from "./sounds/mvp-speech-sound.mp3";
import speechAnimation from "./animations/mvp-speech.json";
import bannerAnimation from "./animations/mvp-banner.json";
import chaeliAnimation from "./animations/mvp-chaeli_clap.json";
import ddamiAnimation from "./animations/mvp-ddami.json";
import quedaAnimation from "./animations/mvp-queda_clap.json";
import ddamiClapAnimation from "./animations/mvp-ddami_clap.json";
import curtainAnimation from "./animations/mvp-curtain.json";
import Confetti from "react-confetti";

import "./mvp-animation.scss";
import classNames from "classnames";
import { useWindowSize } from "react-use";

const MvpAnimation = (props) => {
  const { studentList } = props;
  const [isPlay, setPlay] = useState(false);
  const [showCurtain, setCurtain] = useState(false);

  const speechOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: speechAnimation,
  };

  const bannerOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: bannerAnimation,
  };
  const chaeliOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: chaeliAnimation,
  };
  const ddamiOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: ddamiAnimation,
  };
  const ddamiClapOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: ddamiClapAnimation,
  };
  const quedaOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: quedaAnimation,
  };
  const curtainOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: curtainAnimation,
    // delay: 4000
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPlay(true);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [isPlay]);

  useEffect(() => {
    const curtainTimer = setTimeout(() => {
      setCurtain(true);
    }, 4500);
    return () => {
      clearTimeout(curtainTimer);
    };
  }, [showCurtain]);

  const { width, height } = useWindowSize();

  return (
    <div id="mvpAnimation">
      <div
        className={classNames("img-title", {
          none: !showCurtain,
        })}
      ></div>
      {isPlay && (
        <div className="charactor-anim-wrap">
          <BodyMovin className="speech-anim" options={speechOptions} />
          {!showCurtain && (
            <BodyMovin
              className="ddami character-anim"
              options={ddamiOptions}
            />
          )}
          <BodyMovin className="curtain-anim" options={curtainOptions} />
          <div className="banner-anim-wrap">
            <BodyMovin className="banner-anim" options={bannerOptions} />
            <ul className="student-list">
              {studentList.map((student, index) => (
                <li key={index}>{student.name}</li>
              ))}
            </ul>
          </div>
          <AudioPlayer src={bgm} autoPlay={true} loop={false} />
          <AudioPlayer src={speechSound} autoPlay={true} />
          {showCurtain && (
            <>
              <BodyMovin
                className="queda character-anim"
                options={quedaOptions}
              />
              <BodyMovin
                className="chaeli character-anim"
                options={chaeliOptions}
              />
              <BodyMovin
                className="ddami character-anim"
                options={ddamiClapOptions}
              />
              <AudioPlayer src={characterSound} autoPlay={true} />
              <Confetti
                width={width}
                height={height}
                // numberOfPieces={1000}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default MvpAnimation;
