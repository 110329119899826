import Axios from "./request";
import axios from "axios";
import { getHost, getDefaultConfig } from "./APIUtils";
import moment from "moment";

const apiPrefix = "/academy/v2/hometraining";

const current = (isSchool) => {
  if (isSchool) {
    return Axios.get(`${apiPrefix}/homeworks`);
  }
  return Axios.get(`${apiPrefix}/homework`);
};

const currentLecture = (user) => {
  const classroomId = user.classroom.classroomId;
  // if (user.organization.type !== "campus") {
  //   return false;
  // }
  return Axios.get(`/academy/v2/class/${classroomId}/lectures/current`).then(
    (res) => {
      if (res.current && res.date) {
        // const startTime = new Date(res.date);
        // const now = new Date();

        const startTime = moment(res.date);
        const now = moment(new Date());
        const halfHour = 1000 * 60 * 30;
        console.log(now);
        console.log(startTime);
        console.log(halfHour);
        console.log(parseInt(now - startTime) + parseInt(halfHour) >= 0);
        const isStart = parseInt(now - startTime) + parseInt(halfHour) >= 0;
        // const isStart = false;
        if (isStart) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  );
};

const lectures = () => {
  return Axios.get(`${apiPrefix}/lectures`);
};

const submitHomework = (
  classroomId,
  lectureId,
  studentId,
  homeworkId,
  data
) => {
  return Axios.post(
    `/academy/v2/class/${classroomId}/lectures/${lectureId}/students/${studentId}/homeworks/${homeworkId}/submit`,
    data
  );
};

const uploadHomwwork = (fileData) => {
  return axios.post(`${getHost()}/blob/v2/bundles`, fileData, {
    ...getDefaultConfig(),
  });
};

const reuploadHomework = (bundleId, fileData) => {
  return axios.put(`${getHost()}/blob/v2/bundles/${bundleId}`, fileData, {
    ...getDefaultConfig(),
  });
};

const solveQuiz = (
  classroomId,
  lectureId,
  quetureId,
  quizId,
  answers,
  vars
) => {
  return Axios.post(
    `${apiPrefix}/classrooms/${classroomId}/lectures/${lectureId}/quetures/${quetureId}/quizzes/${quizId}/solve`,
    {
      answers: answers,
      vars: vars,
    }
  );
};
const solveEndQuiz = (
  classroomId,
  lectureId,
  quetureId,
  quizId,
  answers,
  vars
) => {
  return Axios.post(
    `${apiPrefix}/classrooms/${classroomId}/lectures/${lectureId}/quetures/${quetureId}/quizzes/${quizId}/EndQuizSolve`,
    {
      answers: answers,
      vars: vars,
    }
  );
};

const loadQuiz = (quizId, vars = null, randomSeed) => {
  const host = getHost();
  const headers = vars
    ? {
        // "x-qb-quiz-vars": JSON.stringify(solveResult.vars),
        "x-qb-quiz-vars-base64": window.btoa(
          encodeURIComponent(JSON.stringify(vars))
        ),
      }
    : null;
  if (randomSeed)
    return axios.get(
      `${host}/quiz/v2/quizzes/${quizId}?randomSeed=${randomSeed}`,
      {
        ...getDefaultConfig(headers),
      }
    );
  else
    return axios.get(`${host}/quiz/v2/quizzes/${quizId}`, {
      ...getDefaultConfig(headers),
    });
};

const updateGameScore = (gameName, sub, score, time = 0) => {
  console.log(gameName, sub, score, time);
  const host = getHost();

  return axios.put(
    `${host}/academy/v2/hometraining/games/${gameName}/score?sub=${
      sub ? sub : ""
    }&score=${score}&time=${time}`,
    {},
    {
      ...getDefaultConfig(),
    }
  );
};

const getGameRanking = (gameName, sub) => {
  const host = getHost();

  return axios.get(
    `${host}/academy/v2/hometraining/games/${gameName}/ranking?sub=${
      sub ? sub : ""
    }`,
    {
      ...getDefaultConfig(),
    }
  );
};

const submitReview = (classroomId, lectureId, quetureId) => {
  return Axios.post(
    `${apiPrefix}/classrooms/${classroomId}/lectures/${lectureId}/quetures/${quetureId}/review`
  );
};

const HometrainingApis = {
  current,
  currentLecture,
  lectures,
  submitHomework,
  uploadHomwwork,
  reuploadHomework,
  solveQuiz,
  loadQuiz,
  updateGameScore,
  getGameRanking,
  submitReview,
  solveEndQuiz
};

export default HometrainingApis;
