import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Image, PairingArea } from './modules';
import PairingV2 from './modules/PairingV2';
import Toast from 'components/Toast/Toast'
import { actionLog } from 'utils/LogUtils';

class PairingQuiz extends Component {

  constructor(props) {
    super(props)

    this.state = {
      answers: []
    }
  }

  init = (data) => {
    const { quizId, quiz } = data;
    const { pairs } = quiz;
    const { left, right } = pairs;

    const { stepQuizAnswers } = this.props;

    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      const stepAnswersMap = [];
      stepQuizAnswers.map(a => {
        if (a) {
          const answer = right.find(r => r.key === a);
          stepAnswersMap.push(answer);
        } else {
          stepAnswersMap.push(null);
        }
      })
      this.setState((s) => ({
        answers: stepAnswersMap
      }))
    } else {
      this.setState({
        answers: new Array(left.length).fill(null)
      }, () => {
        actionLog("quiz", "init", {
          quizId: quizId,
          type: quiz.type,
          answers: this.state.answers
        });
      });
    }
  }

  componentDidMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      this.init(this.props.data)
    }
  }

  onLineCompleted = (start, end) => {
    const { onAnswerUpdated } = this.props;
    const { answers } = this.state;
    const { right } = this.props.data.quiz.pairs
    const oldIdx = answers.findIndex(s => s != null && s.key === right[end].key);
    if (oldIdx >= 0 && oldIdx != start) {
      this.setState((s) => ({
        answers: update(s.answers, {
          [oldIdx]: {
            $set: null
          }
        })
      }))
    }

    this.setState((s) => ({
      answers: update(s.answers, {
        [start]: {
          $set: right[end]
        }
      })
    }), () => {
      const conv = this.state.answers.map(a => a != null ? a.key : '');
      onAnswerUpdated(conv);
    })
  }

  onLineCanceled = (start) => {
    this.setState((s) => ({
      answers: update(s.answers, {
        [start]: {
          $set: null
        }
      })
    }))
  }

  render() {
    const { data, play, stepQuizAnswers, solvedData, showReallyTrue, forWorkbook } = this.props;
    const { quizId, quiz } = data;
    const { title, image, imageMobile, anim, problem, pairs, textsOnImage } = quiz;
    const { left, right } = pairs;
    const { answers } = this.state;

    if (!answers || answers.length === 0) {
      return null;
    }
    let _answers =[];
      _answers= answers;
    if(this.props.viewType === "guide"){
      if(this.props.sendValue) {
        this.props.sendValue(answers);
      }
    } else if (this.props.receiveValue != null){
      _answers = this.props.receiveValue;
      console.log(this.props.receiveValue)
    }

    return (
      <PairingV2
        left={left}
        right={right}
        solvedData={solvedData}
        showReallyTrue={showReallyTrue}
        answers={_answers}
        parentId={quizId}
        onLineCompleted={this.onLineCompleted}
        onLineCanceled={this.onLineCanceled}
        forWorkbook={forWorkbook}
      />
    )
  }

}

PairingQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
}

export default PairingQuiz;
