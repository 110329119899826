import { Typography } from "antd";
import QbPlayer from "components/QbPlayer/QbPlayer";
import React, { useEffect, useState } from "react";
import Axios from "utils/request";

const QuebonSongDetail = ({ id }) => {
  const [quebonSong, setQuebonSong] = useState(null);

  useEffect(() => {
    load();

    return () => {
      setQuebonSong(null);
    };
  }, []);

  const load = async () => {
    const res = await Axios.get(`/academy/v2/kidsQuebonSongs/${id}`);
    console.log("quebon song res: ", res);
    setQuebonSong(res);
  };

  return (
    <div>
      {quebonSong && (
        <>
          <div className="player-wrap">
            <QbPlayer
              src={quebonSong?.content?.signedUrl}
              viewType={"preview"}
              controls={true}
            />
          </div>
          <div style={{padding: 24}}>
            <Typography.Title level={3}>가사</Typography.Title>
            <Typography.Text strong style={{ whiteSpace: "pre-wrap" }}>
              {quebonSong?.text}
            </Typography.Text>
          </div>
        </>
      )}
    </div>
  );
};
export default QuebonSongDetail;
