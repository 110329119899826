import React, { useState, useEffect } from "react";
import AudioPlayer from "components/Audio/AudioPlayer";
import BodyMovin from "components/BodyMovin/BodyMovin";
import charactorAnimation from "./animations/activity-character.json";
import speechAnimation from "./animations/activity-speech.json";
import bgm from "./sounds/activity-bgm.mp3";
import characterSound from "./sounds/activity-character-sound_x3.mp3";
import speechSound from "./sounds/activity-speech-sound.mp3";
import "./activity-preview-animation.scss"

const ActivityPreviewAnimation = () => {
  const [isPlay, setPlay] = useState(false);

  const charactorOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: charactorAnimation,
  };

  const speechOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: speechAnimation,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPlay(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="activityPreviewAnimation">
      <div className="img-title"></div>
      {isPlay && (
        <div className="charactor-anim-wrap">
          <BodyMovin className="charactor-anim" options={charactorOptions} />
          <BodyMovin className="speech-anim" options={speechOptions} />
          <AudioPlayer src={bgm} autoPlay={true} loop={false} />
          <AudioPlayer src={characterSound} autoPlay={true} />
          <AudioPlayer src={speechSound} autoPlay={true} />
        </div>
      )}
    </div>
  );
};
export default ActivityPreviewAnimation;
