import React from "react";
import { Spin } from "antd";

const Classmodebtn = props => {
  const { text, loading } = props;

  return (
    <>
      <button {...props} className="classmode-btn">
        {loading ? "로그인중..." : text}
      </button>
    </>
  );
};

export default Classmodebtn;
