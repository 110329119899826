import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Image, TrueFalseButton } from './modules';
import { formatText } from './utils/Formatter';
import Desmos from './components/Desmos'

class DesmosQuiz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      answer: {}
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      this.setState({
        answer: {}
      });
    }
  }

  onAnswerSet = (data) => {
    const { onAnswerUpdated } = this.props;
    this.setState({
      answer: data
    }, () => {
      onAnswerUpdated([JSON.stringify(data)]);
    })
  }

  render() {
    const { data } = this.props;
    const { quiz } = data;
    const { desmos, vars } = quiz;

    return (
      <div className="quiz-content__answer">
        <Desmos state={desmos} vars={vars} onChange={this.onAnswerSet} />
      </div>
    )
  }

}

DesmosQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
}

export default DesmosQuiz;
