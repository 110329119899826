import React, { Component } from "react";
import classNames from "classnames";
import "katex/dist/katex.min.css";
import Popup from "../../../components/Popup/Popup";
import {
  orderBy,
  values,
  mapValues,
  remove,
  filter,
  map,
  find,
  sortedUniq,
} from "lodash";
import { Tooltip, Icon, Card, Button } from "antd";
import { ConstantsContext } from "utils/ConstantsContext";
import CommonApis from "../../../utils/commonApis";
import Axios from "../../../utils/request";
import { formatText } from "../../../components/Quiz/utils/Formatter";
import { formatImgText } from "../../../components/Quiz/utils/ImageFormatter";

export default class TeachingGuidePopup extends Component {
  static contextType = ConstantsContext;

  constructor(props) {
    super(props);

    this.state = {
      quetureCampusNotes: [],
      page: 1,
      loading : false,
    };
  }

  componentDidMount() {
    const { quetures } = this.props;

    let quetureCampusList = [];

    quetures.map(e => {
      Axios.get(`/content/v2/quetures/${e.quetureId}?withQuetureTeachingGuide=${false}&withQuetureCampusNote=${true}`).then(resolve => {
        quetureCampusList.push(resolve.quetureCampusNote)
        this.setState({loading:true})
      })
    })
    this.setState({ quetureCampusNotes: quetureCampusList })
  }

  render() {
    const { quetures, isOpen, onClose } = this.props;
    const { page, quetureCampusNotes } = this.state

    let str = []
    if(quetureCampusNotes[page-1] != null)
    quetureCampusNotes[page-1].pictures.map((e, idx) => {
      str.push(e.signedUrl)
    })
    return (
      <Popup isOpen={isOpen} className="popup-student-list">
        <div className="popup-closebtn" onClick={() => onClose(null)}></div>
        <div className="title">교안<Button
          style={{ borderColor: "#333a53", color: '#ffffff', backgroundColor: '#333a53' }} onClick={() => {
          if (page != 1) this.setState({ page: page - 1 })
        }}>{"<"}</Button>{page + "/" + quetures.length}<Button
          style={{ borderColor: "#333a53", color: '#ffffff', backgroundColor: '#333a53' }}
          onClick={() => {
            if (page != quetures.length) this.setState({ page: page + 1 })
          }}>{">"}</Button></div>

        <Card style={{ textAlign: "left" }}>
          <h2>깨처포인트</h2>
          {quetureCampusNotes[page-1]&&formatImgText(quetureCampusNotes[page-1].checkPoint, null, str)}
          <h2>주의사항</h2>
          {quetureCampusNotes[page-1]&&formatImgText(quetureCampusNotes[page-1].precautions, null, str)}
        </Card>
        <div className="chk-btn">
          <button onClick={onClose}>닫기</button>
        </div>
      </Popup>
    );
  }
}
