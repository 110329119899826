import React, { useState, useEffect } from "react";
import ClassroomApis from "../../../utils/classroomApis";
import classNames from "classnames";
// import animation from './X.json'
import animation from "../../../components/BodyMovin/Animation/X.json";
import BodyMovin from "../../../components/BodyMovin/BodyMovin.js";
import "./_summary.scss";
import Toast from "components/Toast/Toast";
import { merge, map, assign, find } from "lodash";
import {
  Descriptions,
  Badge,
  List,
  Card,
  Divider,
  Avatar,
  Typography,
} from "antd";
import { getProfileUrl } from "../../../utils/utils";

const Summary = (props) => {
  const { classroomId, lectureId, viewType, studentList, summaryInfo } = props;
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    words: ["", "", ""],
    answer: "",
    memo: "",
  });
  const [curTab, setTab] = useState("words");
  const [studentSummaries, setStudentSummaries] = useState(studentList || []);

  useEffect(() => {
    console.log("summary info: ", summaryInfo);
    if (summaryInfo && summaryInfo.summaries.length > 0) {
      setData({
        words: summaryInfo.summaries[0].words,
        answer: summaryInfo.summaries[0].answer,
        memo: summaryInfo.summaries[0].memo,
      });
      if (studentList && viewType !== "student") {
        // let _studentSummaries = merge(studentList, summaryInfo.summaries);
        let _studentSummaries = map(studentList, function (obj) {
          return assign(
            obj,
            find(summaryInfo.summaries, function (o) {
              return o.student.studentId === obj.studentId;
            })
          );
        });
        setStudentSummaries(_studentSummaries);
      }
    }
    return () => {};
  }, [summaryInfo.summaries]);

  function handleInputWord(index, value) {
    const _words = data.words;
    _words[index] = value;

    if (value.length > 100) {
      Toast.warning("단어는 최대 100자 까지 입력할 수 있습니다.");
      value = value.slice(0, 100);
    }

    setData({
      ...data,
      words: _words,
    });
  }

  function handleInput(e) {
    const name = e.target.name;
    let value = e.target.value;

    if (value.length > 300) {
      Toast.warning("글자수는 최대 300자 까지 입력할 수 있습니다.");
      value = value.slice(0, 300);
    }

    setData({
      ...data,
      [name]: value,
    });
  }

  function onSubmit() {
    setLoading(true);
    ClassroomApis.submitSummaries(
      classroomId,
      lectureId,
      summaryInfo.quetureId,
      data
    )
      .then((res) => {
        console.log("완료", res);
        Toast.success("저장 되었습니다.");
        setLoading(false);
      })
      .catch((e) => {
        console.log("실패");
        Toast.success("실패 되었습니다.");
        setLoading(false);
      });
  }

  const bodymovinOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: animation,
  };
  console.log("studentSummaries: ", studentSummaries);
  if (viewType !== "student") {
    return (
      <div className="summary-layout layer">
        {/* 
        <div className="bubble-speech">
          <p>
            <span>배운 내용을 정리해보세요</span>
          </p>
          <div className="fail">
            <BodyMovin className="" options={bodymovinOptions} />
          </div>
        </div>
        */}
        <List
          grid={{
            gutter: 16,
            column: studentSummaries.length > 8 ? 8 : studentSummaries.length,
          }}
          dataSource={studentSummaries}
          renderItem={(item) => (
            <List.Item>
              <Card
                className="summary-card"
                title={
                  <span
                    style={{
                      display: "flex",
                      padding: 0,
                      alignItems: "center",
                    }}
                  >
                    {item.profileImage ? (
                      <Avatar
                        src={getProfileUrl(item.profileImage.contentId)}
                      />
                    ) : (
                      <Avatar icon="user" />
                    )}
                    <Typography.Text style={{ marginLeft: 8 }}>
                      {item.name}
                    </Typography.Text>
                  </span>
                }
                headStyle={{ padding: "0 16px", minHeight: 32 }}
                bodyStyle={{ padding: "16px" }}
              >
                <div>
                  <Badge
                    status={
                      item &&
                      item.words &&
                      item.words.filter((w) => w.length > 0).length > 0
                        ? "processing"
                        : "error"
                    }
                    text="단어"
                  />
                  <Divider type="vertical" />
                  <Badge
                    status={
                      item && item.answer && item.answer.length > 0
                        ? "processing"
                        : "error"
                    }
                    text="문장"
                  />
                </div>
              </Card>
            </List.Item>
          )}
        />
      </div>
    );
  }
  return (
    <div className="summary-layout">
      <h4 className="summary-layout-title">
        깨처를 보고 이해한 내용을 입력해보세요.
      </h4>
      <div className="edit-tab">
        {/* is-active 일때 흰색 체크이미지와 파란색 배경,
            is-done 일때 검은색 체크이미지
         */}
        <div
          className={classNames("edit-tab__nav", {
            "is-active": curTab === "words",
            "is-done": data.words.filter((w) => w.length > 0).length > 0,
          })}
          onClick={() => setTab("words")}
        >
          <span>단어로 개념정리 하기</span>
        </div>
        <div
          className={classNames("edit-tab__nav", {
            "is-active": curTab === "answer",
            "is-done": data.answer.length > 0,
          })}
          onClick={() => setTab("answer")}
        >
          <span>문장으로 개념정리 하기</span>
        </div>
        <div
          className={classNames("edit-tab__nav", {
            "is-active": curTab === "memo",
            "is-done": data.memo.length > 0,
          })}
          onClick={() => setTab("memo")}
        >
          <span>메모로 개념정리 하기</span>
        </div>
      </div>
      <div className="edit-wrap">
        <div
          className={classNames("edit-area", {
            none: curTab !== "words",
          })}
        >
          <h5>1. 기억에 남는 단어 3개는 무엇인가요?</h5>
          {data.words.map((word, index) => (
            <input
              key={"input-" + index}
              value={word}
              onChange={(e) => handleInputWord(index, e.target.value)}
              className="input-text"
            />
          ))}
        </div>
        <div
          className={classNames("edit-area", {
            none: curTab !== "answer",
          })}
        >
          <h5>{summaryInfo.question}</h5>
          <textarea name="answer" value={data.answer} onChange={handleInput} />
        </div>
        <div
          className={classNames("edit-area", {
            none: curTab !== "memo",
          })}
        >
          <h5>나의 생각을 자유롭게 메모하며 정리해보세요.</h5>
          <textarea name="memo" value={data.memo} onChange={handleInput} />
        </div>
      </div>
      <div className="btnwrap">
        <button disabled={isLoading} onClick={() => onSubmit()}>
          {isLoading ? "전송중..." : "저장하기"}
        </button>
      </div>
    </div>
  );
};

export default Summary;
