import React, { useState, useEffect, useRef, ReactFragment } from "react";
import classNames from "classnames";
import { values, map, orderBy, filter, sortedUniq } from "lodash";
import "./_component.scss";
import { getProfileUrl } from "../../../utils/utils";
import fakeAvatar3 from "./img/fakeAvatar3.svg";
import { ConstantsContext } from "utils/ConstantsContext";
import SimpleRankingList from "./SimpleRankingList";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Popup from "components/Popup/Popup";
import Axios from "utils/request";
import { Quiz } from "components/Quiz";
import { Modal, Button } from "antd";

const fakeAvatar1 =
  "https://images-na.ssl-images-amazon.com/images/M/MV5BMTU4NTM1MTExOF5BMl5BanBnXkFtZTcwMTYwODMyMw@@._V1_UY256_CR2,0,172,256_AL_.jpg";
const fakeAvatar2 =
  "https://images-na.ssl-images-amazon.com/images/M/MV5BMjgzNjJiMjgtMzllZS00NGRjLTgyOWEtYzVhNzgxODE0ZDA3XkEyXkFqcGdeQXVyNTU0OTE5MjA@._V1_UX172_CR0,0,172,256_AL_.jpg";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const RankingList = (props) => {
  const { groups, students, quizList, attendances, isSimple } = props;
  const [rankingList, setRankingList] = useState([]);
  const [sortedRankingList, setSortedRankingList] = useState([]);
  const [random, setRandom] = useState(false);
  const [timer, setTimer] = useState(null);
  const [isDone, setDone] = useState(false);
  const [viewQuiz, setViewQuiz] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const context = React.useContext(ConstantsContext);

  const prevData = usePrevious(rankingList);
  console.log("attendances: ", attendances);

  useEffect(() => {
    // let _rankingList = [];
    let _rankingList = {};
    if (groups && groups.length > 0) {
      groups.map((g) => {
        let _studentList = filter(g.students, function (o) {
          return (
            attendances[o.studentId] &&
            attendances[o.studentId].status &&
            attendances[o.studentId].status === "ok"
          );
        });

        _studentList.map((student, index) => {
          const _student = filter(students, {
            studentId: student.studentId,
          })[0];
          if (_student && _student.profileImage) {
            student["profileImage"] = _student.profileImage;
          }
        });
        const rankingData = {
          count: 0,
          change: false,
          answerList: [],
          studentId: g.leader.studentId,
          studentList: _studentList,
        };
        // _rankingList.push(rankingData);
        console.log("_studentList: ", _studentList);
        const leader = filter(_studentList, function (o) {
          return o.studentId === g.leader.studentId;
        });
        console.log("leader: ", leader);
        _rankingList[g.leader.studentId] = rankingData;
      });
    } else {
      let _studentList = filter(students, function (o) {
        return (
          attendances[o.studentId] &&
          attendances[o.studentId].status &&
          attendances[o.studentId].status === "ok"
        );
      });

      _studentList.map((student) => {
        const rankingData = {
          count: 0,
          // count: Math.round(Math.random() * 10),
          change: false,
          answerList: [],
          studentId: student.studentId,
          studentList: [student],
          timestamp: null,
          correctRate: 0,
        };
        // _rankingList.push(rankingData);
        _rankingList[student.studentId] = rankingData;
      });
    }
    console.log(_rankingList);

    let answeredCount = 0;
    quizList.map((quiz) => {
      if (
        Object.keys(quiz.solvedMap) &&
        Object.keys(quiz.solvedMap).length > 0
      ) {
        Object.keys(quiz.solvedMap).map((studentId) => {
          if (_rankingList[studentId]) {
            _rankingList[studentId]["answerList"].push(
              quiz.solvedMap[studentId].correct
            );
            // 정답갯수
            if (quiz.solvedMap[studentId].correct === true) {
              _rankingList[studentId]["count"]++;
            }
            // 시간
            _rankingList[studentId]["timestamp"] =
              quiz.solvedMap[studentId].timestamp > 0
                ? quiz.solvedMap[studentId].timestamp
                : null;
            // 정답율
            _rankingList[studentId]["correctRate"] =
              _rankingList[studentId]["count"] /
              _rankingList[studentId]["answerList"].length;
            answeredCount++;
          }
        });
      }
    });

    const newRankingList = values(_rankingList);
    const _sortedRankingList = orderBy(
      values(_rankingList),
      ["count", "correctRate", "timestamp"],
      ["desc", "desc", "asc"]
    );
    setSortedRankingList(map(_sortedRankingList, "studentId"));

    // if (prevData) {
    //   newRankingList.map((r, index) => {
    //     if (r.studentId !== prevData[index].studentId) {
    //       r["changed"] = true;
    //     }
    //   });
    // }
    console.log("quiz count: ", quizList.length);
    console.log("ranking list count: ", Object.keys(_rankingList).length);
    console.log("answer count: ", answeredCount);
    console.log("prev list: ", prevData);
    console.log("new list: ", newRankingList);

    if (answeredCount === quizList.length * Object.keys(_rankingList).length) {
      setDone(true);
    }

    setRankingList(newRankingList);

    if (timer != null) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        setTimer(null);
      }, 500)
    );

    return () => {
      setRankingList(null);
    };
  }, [quizList, random]);

  function handleViewQuiz() {
    setViewQuiz(true);
    if (!currentQuiz) {
      loadQuiz(quizList[0]);
    }
  }

  const loadQuiz = (quiz) => {
    if (isLoading) {
      return;
    }

    console.log("load quiz");
    setLoading(true);
    Axios.get(`/quiz/v2/quizzes/${quiz.quizId}`)
      .then((response) => {
        setLoading(false);
        setCurrentQuiz(response);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const solve = (quizId, answers, vars) => {
    return Axios.post(`/quiz/v2/quizzes/${quizId}/solve`, {
      answers: answers,
      vars: vars,
    }).then((response) => {
      return response;
    });
  };

  console.log(groups);
  console.log(quizList);
  console.log(rankingList);
  console.log(sortedRankingList);

  if (isSimple) {
    return (
      <SimpleRankingList quizCount={quizList.length || 0} data={rankingList} />
    );
  }

  return (
    // 학생수 6명 이하일시 column-list 추가, 팀전일시 is-team 추가
    <>
      {props.viewType === "guide" && (
        <Button
          style={{ position: "absolute", left: 0, top: 0 }}
          type="primary"
          onClick={() => handleViewQuiz()}
        >
          문제보기
        </Button>
      )}
      {viewQuiz && (
        <Modal
          visible={viewQuiz}
          title={"문제보기"}
          type=""
          className=""
          onOk={() => setViewQuiz(false)}
          onCancel={() => setViewQuiz(false)}
          footer={null}
          width={1200}
        >
          <div style={{ minHeight: 800, background: "#fff" }}>
            <Button.Group>
              {quizList.map((quiz, index) => (
                <Button
                  key={`quiz-${index}`}
                  type={currentQuiz.quizId !== quiz.quizId ? "primary" : ""}
                  disabled={currentQuiz.quizId === quiz.quizId}
                  onClick={() => loadQuiz(quiz)}
                >{`${index + 1}번째 문제`}</Button>
              ))}
            </Button.Group>
            {currentQuiz && (
              <div style={{ pointerEvents: "none" }}>
                <Quiz
                  data={currentQuiz}
                  solver={solve}
                  status={[]}
                  quizType={"admin"}
                  isPreview={true}
                  viewType={"guide"}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
      <div
        className={classNames("ranking", {
          // "column-list": students && students.length > 6 && groups.length === 0,
          "column-list": rankingList.length > 6 && groups.length === 0,
          "is-team": groups && groups.length > 0,
          "is-two-team": groups && groups.length === 2,
          "is-many": groups && groups.length === 2 && students.length >= 5,
          "short-full-list ": context.type === "school" && quizList.length > 5,
          "full-list ": context.type === "school" && quizList.length <= 5,
        })}
      >
        {rankingList &&
          rankingList.map((item, index) => (
            // 승리한 팀일 시 rankinglist 에 is-winner
            <div className="rankinglist-wrap">
              <table
                key={index}
                className={classNames("rankinglist", {
                  "turn over": item.count > 0 && item.changed && timer,
                  one:
                    item.count > 0 && sortedRankingList[0] === item.studentId,
                  two:
                    item.count > 0 && sortedRankingList[1] === item.studentId,
                  three:
                    item.count > 0 && sortedRankingList[2] === item.studentId,
                  fill: isDone && groups && groups.length !== 2,
                })}
              >
                <tbody>
                  <tr>
                    <td className="card-wrap">
                      {groups && groups.length === 2 && (
                        <div
                          className={classNames("result-title", {
                            "is-visible": item.count > 0,
                          })}
                        >
                          {item.count > 0
                            ? sortedRankingList[0] === item.studentId
                              ? "WIN"
                              : "LOSE"
                            : ""}
                        </div>
                      )}
                      <div
                        className={classNames(
                          "face",
                          "front",
                          groups.length > 2 || groups.length === 0
                            ? "front-threeteam"
                            : ""
                        )}
                      >
                        <div
                          className={classNames(
                            "img-wrap",
                            groups.length > 2 || groups.length === 0
                              ? "img-wrap-threeteam"
                              : ""
                          )}
                        >
                          {item.studentList.map((student, idx) => {
                            return (
                              <div
                                key={"img_" + idx}
                                className={
                                  groups.length === 2
                                    ? "img-cover-twoteam"
                                    : "img-cover"
                                }
                              >
                                {groups.length === 2 || groups.length === 0 ? (
                                  <div
                                    className={classNames("img", {
                                      team: item.studentList.length > 1,
                                      solo: item.studentList.length === 1,
                                      none: context.type === "school",
                                    })}
                                  >
                                    <img
                                      src={
                                        (student.profileImage &&
                                          getProfileUrl(
                                            student.profileImage.contentId
                                          )) ||
                                        fakeAvatar3
                                      }
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  <span>{student.name}</span>
                                )}
                                {/* <div></div> */}
                                {/* <div
                                  className={classNames("img", {
                                    team: item.studentList.length > 1,
                                    solo: item.studentList.length === 1,
                                    none: context.type === "school",
                                  })}
                                > */}
                                {/* <img
                                    src={
                                      (student.profileImage &&
                                        getProfileUrl(
                                          student.profileImage.contentId
                                        )) ||
                                      fakeAvatar3
                                    }
                                    alt=""
                                  /> */}
                                {/* </div> */}
                              </div>

                              //
                            );
                          })}
                        </div>
                        <div
                          className={classNames(
                            "name-wrap",
                            groups.length === 0 ? "name-wrap-personal" : ""
                          )}
                        >
                          {(groups.length === 2 || groups.length === 0) &&
                            item.studentList.map((student, idx) => (
                              <span
                                key={"name_" + idx}
                                className={classNames("name", {
                                  dual: idx > 0,
                                })}
                              >
                                {student.name}
                              </span>
                            ))}
                        </div>
                        <ul
                          className={classNames(
                            "question-mark",
                            groups.length === 0 ? "question-mark-personal" : ""
                          )}
                        >
                          {item.answerList.map((answer, index) => (
                            <li
                              key={index}
                              className={classNames(
                                `${answer ? "is-right" : "is-false"}`
                              )}
                            >
                              <span></span>
                            </li>
                          ))}
                          {Array(quizList.length - item.answerList.length)
                            .fill(0)
                            .map((v, i) => (
                              <li key={"unsolved-" + i}></li>
                            ))}
                        </ul>
                        <span className="timer">
                          {/* {`${item.count}문제 정답!`} */}
                          <FormattedMessage
                            id="classroom.correct-questions"
                            values={{ count: item.count }}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
      </div>
    </>
  );
};

export default RankingList;
