import React, { useState, useEffect } from "react";
import { Icon } from "antd";

const RefreshController = () => {
  const [control, setControl] = useState({ left: false, right: false });

  const handleControl = (key) => {
    if (
      (key === "left" && control.right) ||
      (key === "right" && control.left)
    ) {
      window.location.reload();
    }

    setControl({
      ...control,
      [key]: true,
    });
  };

  return (
    <div
      style={{
        display: "block",
        width: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 99999,
      }}
    >
      <span
        className="refresh-controller-left"
        style={{ width: 20, height: 20, position: "absolute", left: 0, top: 0 }}
        onClick={() => {
          handleControl("left");
        }}
      >
        {/* {control.left ? "O" : "x"} */}
        {/* <Icon type="reload" /> */}
      </span>
      <span
        className="refresh-controller-right"
        style={{
          width: 20,
          height: 20,
          position: "absolute",
          right: 0,
          top: 0,
        }}
        onClick={() => handleControl("right")}
      >
        {/* {control.right ? "O" : "x"}
        <Icon type="reload" /> */}
      </span>
    </div>
  );
};

export default RefreshController;
