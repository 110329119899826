import React, { useState, useEffect } from "react";
import CommonApis from "../../../utils/commonApis";

const QuetureName = props => {
  const { quetureId } = props;
  const [quetureName, setQuetureName] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    CommonApis.getQuetureName(quetureId).then(res => {
      setQuetureName(res.data.title);
      setLoading(false);
    });

    return () => {};
  }, [quetureId]);

  return <>{isLoading ? "..." : quetureName}</>;
};

export default QuetureName;
