import React from "react";
import AvatarDropdown from "./AvatarDropdown";
import styles from "./styles.module.scss";
import HeaderSearch from "./HeaderSearch";

const RightContent = props => {
  return (
    <div className={styles.right}>
      {/* <HeaderSearch /> */}
      <AvatarDropdown {...props} />
    </div>
  );
};

export default RightContent;
