import React, { Component } from "react";
import ImageSelectLayer from "./ImageSelectLayer";
import Image from "./Image";
import classNames from "classnames";
import noScroll from "no-scroll";
import debounce from "lodash.debounce";
import { isMobileOnly } from "react-device-detect";

class SelectableImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: null,
      over: false,
      showImagePopup: false,
      isMobileSize: window.innerWidth <= 768
    };

    this.handleResize = debounce(this.handleResize, 100);
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      isMobileSize: window.innerWidth <= 768
    });
  };

  handleMouseOver = i => {
    this.setState({
      over: true
    });
  };

  handleMouseLeave = i => {
    this.setState({
      over: false
    });
  };

  handleSelected = (i, selected) => {
    this.props.onSelected(i, selected);
  };

  componentWillUnmount() {
    noScroll.off();
  }

  handleImagePopup = isShow => {
    if (isShow) {
      noScroll.on();
    } else {
      noScroll.off();
    }

    this.setState({
      showImagePopup: isShow
    });
  };

  render() {
    const {
      parentId,
      image,
      imageMobile,
      frames,
      play,
      answers,
      rects,
      solvedData,
      showReallyTrue
    } = this.props;
    const { active, over, showImagePopup, isMobileSize } = this.state;

    return (
      <div className="selectableImageContainer t-answer-imgpick__wrap">
        <Image
          image={image}
          imageMobile={imageMobile}
          isMobileSize={isMobileSize}
          frames={frames}
          play={play}
        />
        {/* <div className="selectableImageBack" style={{ display: over ? 'block' : 'none' }} /> */}
        <div
          className={classNames("t-answer-imgpick__wrap m-layer", {
            "is-on": showImagePopup
          })}
          style={{ display: "inline-block" }}
        >
          <div className="m-layer-wrap" style={{ display: "inline-block" }}>
            <div className="m-layer-wrap-inner">
              <Image
                image={image}
                imageMobile={imageMobile}
                isMobileSize={isMobileSize}
                frames={frames}
                play={play}
              />
              {rects.map((r, i) => (
                <ImageSelectLayer
                  key={parentId + "_img_" + i}
                  image={image}
                  rect={r}
                  // active={active != null && active === i}
                  solvedData={solvedData}
                  showReallyTrue={showReallyTrue}
                  answers={answers}
                  selected={answers[i]}
                  isMobileSize={isMobileSize}
                  // onMouseOver={() => this.handleMouseOver(i)}
                  // onMouseLeave={() => this.handleMouseLeave(i)}
                  onSelected={selected => this.handleSelected(i, selected)}
                />
              ))}
            </div>
            <span
              onClick={() => this.handleImagePopup(false)}
              className="m-layer-close"
            >
              닫기
            </span>
          </div>
        </div>
        <button
          type="button"
          onClick={() => this.handleImagePopup(true)}
          className="m-image-select-btn"
        >
          이미지 선택하기
        </button>
      </div>
    );
  }
}

export default SelectableImage;
