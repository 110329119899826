import React, { useState, useEffect } from 'react';
import Popup from './Popup';
import { copyToClipboard } from 'utils/utils';
import Toast from 'components/Toast/Toast';


const ShareLinkPopup = (props) => {

  const { isOpen, link, kakaoLink, onClose } = props;
  const kakaoEnabled = window.Kakao != null &&  window.Kakao.Link != null && window.Kakao.Link.createDefaultButton != null;
  function initKakao(elem, shortUrl, btnUrl) {
    // const { url, image, title, description } = this.props;
    const title = '<깨봉 뉴스레터>';
    const description = '구독하고 다양한 정보들을 받아보세요!';
    const url = shortUrl;
    const image = 'https://d2ql3aoebfyj3b.cloudfront.net/img/og-logo.jpg';
    console.log('link: ', link, kakaoLink);

    if (kakaoEnabled && elem) {
      console.log('shortUrl', url);
      console.log('btnUrl: ', btnUrl);
      window.Kakao.Link.createDefaultButton({
        container: elem,
        objectType: 'feed',
        content: {
          title: title,
          description: description,
          imageUrl: image,
          link: {
            mobileWebUrl: btnUrl,
            webUrl: btnUrl
          }
        },
        buttons: [
          {
            title: '뉴스레터 보러가기',
            link: {
              mobileWebUrl: btnUrl,
              webUrl: btnUrl
            }
          }
        ]
      });
    }
  }


  return (
    <Popup
      isOpen={isOpen}
      type="newsletter share"
    >
      <div className="popup-content-wrap">
        <div className="popup-closebtn" onClick={onClose}></div>
        <div className="title-bg">
          <div className="img"></div>
        </div>
        <div className="title-wrap">
          <div className="title">이 뉴스레터를 공유할래요!</div>
          <strong>꼭 필요한 정보들을 한 번에 모아보고 <br />좋은 정보들을 주변에 공유해보세요.</strong>
        </div>
        <div className="btnwrap">
          {kakaoEnabled &&
            <button className="kakao-share" ref={(e) => {
            initKakao(e, link, kakaoLink);
          }}><span>카카오톡<span>으로 공유</span></span></button>}
        </div>
        <div className="linkwrap">
          <span className="linkcode"><span>{link}</span></span>
          <div className="linkbtn-wrap">
            <button className="linkbtn" onClick={() => {
              copyToClipboard(link);
              Toast.success('링크가 복사 되었습니다.');
            }}><span>링크복사</span></button>
          </div>
        </div>
      </div>
    </Popup>
  )

}

export default ShareLinkPopup;
