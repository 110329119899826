import Axios from "./request";
import { message } from "antd";

const apiPrefix = "/academy/v2/newsletter";

const getNewsletterList = (page, count) => {
  return Axios.get(`${apiPrefix}?page=${page}&count=${count}`);
};

const addNewsletter = data => {
  return Axios.post(`${apiPrefix}`, data);
};

const editNewsletter = (newsletterId, data) => {
  return Axios.put(`${apiPrefix}/${newsletterId}`, data);
};
const deleteNewsletter = newsletterId => {
  return Axios.delete(`${apiPrefix}/${newsletterId}`);
};
const getNewsletter = newsletterId => {
  return Axios.get(`${apiPrefix}/${newsletterId}`);
};
const sendNewsletter = (url,orgId,data) => {
  return Axios.post(`/academy/v2/accounts/${orgId}/newsletter/send?url=${url}`,data);
}
const NewsletterApis = {
  addNewsletter,
  editNewsletter,
  getNewsletterList,
  deleteNewsletter,
  getNewsletter,
  sendNewsletter
};

export default NewsletterApis;
