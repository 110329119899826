import React, { useEffect, useState } from "react";

function ExternalImage({ imgSrc, scale }) {
  const [img, setImg] = useState(new Image());
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      img.onload = () => {
        setLoaded(true);
      }
      img.src = imgSrc;
    }
    return (() => {
      setLoaded(false)
    })
  }, []);

  console.log(img?.width, "scale")
  return (
    <>
      <img
        src={img.src}
        width={(img?.width * scale) + "px"}
      />
    </>
  );
}

export default ExternalImage;