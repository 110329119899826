import React, { useState, useEffect } from "react";
import Axios from "utils/request";
import { Skeleton } from "antd";
import QuizView from "components/Quiz/QuizView";
import classNames from "classnames";
import withDragDropContext from "components/Quiz/withDragDropContext";
import { Quiz } from "components/Quiz";

const ExamQuizCard = (props) => {
  const { current, index, showTrueAnswers,currentQuizIndex } = props;
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [queturePopup, setQueturePopup] = useState(false);
  const [breakPage, setBreakPage] = useState(false);
  const [stepQuizData, setStepQuizData] = useState(false);

  useEffect(() => {
    if (current) {
      if (cardData && cardData.quizId === current) {
        return;
      }
      loadQuiz(current);
    }
    return () => {};
  }, [current, showTrueAnswers]);

  function loadQuiz(quizId) {
    setLoading(true);
    return Axios.get(`/quiz/v2/quizzes/${quizId}`)
      .then((data) => {
        solveQuiz(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function solveQuiz(data) {
    const quizId = data.quizId;
    const vars = data.quiz.vars;

    return Axios.post(`/quiz/v2/quizzes/${quizId}/solve`, {
      answers: [],
      vars: vars,
    })
      .then((response) => {
        console.log("step quiz Data: ", response);
        let _solveResult = response;
        let _solvedData = {
          solved: false,
          solving: false,
          correct: true,
          correctAnswers: _solveResult.correctAnswers,
          corrects: _solveResult.correctAnswers,
          desc: _solveResult ? _solveResult.desc : null,
          answers: [],
          vars: _solveResult ? _solveResult.vars : {},
          timestamp: _solveResult ? _solveResult.timestamp : null,
        };

        setCardData({
          quizId: quizId,
          quizData: data,
          stepQuizData: _solvedData,
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setCardData({
          quizId: quizId,
          quizData: null,
          stepQuizData: null,
        });
      });
  }
  const data = (cardData && cardData.quizData) || null;

  return (
    <div
      className={classNames("quiz-container", {
        "break-page": breakPage,
      })}
      style={{ position: "relative" }}
    >
      <Skeleton loading={loading} active>
        {cardData && cardData.quizData && !loading && (
          <>
            <Quiz
              data={data}
              // solver={onSolve}
              expectedCount={null}
              status={[]}
              onAppendResult={(result, isLast) => {
                // this.handleAppendResult(result, isLast);
              }}
              // onNext={() => this.handleStep(currentIndex + 1)}
              // onPrev={() => this.handleStep(currentIndex - 1)}
              // onStep={(index, data) => this.handleStep(index, data)}
              onFinish={() => console.log("on finish")}
              currentIndex={1}
              stepQuizData={showTrueAnswers ? cardData.stepQuizData : null}
              step={showTrueAnswers ? true : false}
              quizStep={null}
              isPreview={true}
              quizType="printView"
              defaultShowReallyTrue={showTrueAnswers ? true : false}
              // onSolvedData={this.handleSolvedData}
              currentQuizIndex={currentQuizIndex}
            />
          </>
        )}
        {cardData && !cardData.quizData && <div>잘 못된 문제 입니다.</div>}
      </Skeleton>
    </div>
    // </div>
  );
};

export default withDragDropContext(ExamQuizCard);
