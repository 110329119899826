import React, { useEffect, useState } from 'react';
import { formatText } from "../utils/Formatter";
import DragTextProblem from "../modules/DragTextProblem"
import TextSelectQuiz from '../TextSelectQuiz';
import FillBlankQuiz from '../FillBlankQuiz';
import classNames from 'classnames';

const QuizProblem = ({ quizId, title, problem, type, ...props }) => {

  // const changedProblem = ['image_select', 'drag_text', 'select_text', 'fill_blank'];
  const PROBLEM_AS_ANSWER_TYPES = ['image_select', 'drag_text', 'select_text'];
  const HIDE_BLANK_TYPES = ["counting", "coloring"];

  const [text, setText] = useState(null);

  useEffect(() => {
    if (problem) {
      if (type === 'image_select') {
        setText(<>
          <span className="quiz-content__answer-subtitle-num none">질문</span>
          <span className="quiz-content__answer-subtitle-title">{formatText(problem, quizId + "_prob", props?.additionalImages)}</span>
        </>)
      } else if (!PROBLEM_AS_ANSWER_TYPES.includes(type)) {
        if(HIDE_BLANK_TYPES.includes(type)) {
          const _problem = problem.replaceAll("{{}}", "");
          if(_problem.length > 0) {
            setText(formatText(_problem, quizId + "_prob", props?.additionalImages));
          }
        } else {
          setText(formatText(problem, quizId + "_prob", props?.additionalImages));
        }
      }
    } else {
      setText(null)
    }
    return () => {
      setText(null)
    };
  }, [problem, quizId]);

  // console.log('problem: ', problem);

  function handleDrop(result) {
    console.log('handle drop: ', result);
  }

  if (type === 'pairing') {
    return null
  }
  if (type === 'drag_text') {
    return <DragTextProblem {...props} />
  }
  if (type === 'select_text') {
    console.log("select_text: ", props)
    return <TextSelectQuiz {...props} />
  }
  if (type === 'fill_blank') {
    return <FillBlankQuiz {...props} />
  }

  return (
    <div className={classNames("quiz-content__content-title", {
      none: !text
    })}>
      {text}
    </div>
  );
}

export default QuizProblem;
