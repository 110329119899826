import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import Axios from "../../../../utils/request";
import {
  Skeleton,
  Row,
  Col,
  List,
  Tag,
  Spin,
  PageHeader,
  Button,
  Icon,
  Descriptions,
  Typography,
  Table,
  Divider,
  Statistic,
  Popconfirm,
  message,
  Tabs,
  Radio,
  Select,
  Menu,
  Dropdown,
  Form,
  Input
} from "antd";
import { values, flatten, filter } from "lodash";
import ClassroomApis from "../../../../utils/classroomApis";
import { ConstantsContext } from "utils/ConstantsContext";
import { getSeqText } from 'utils/utils';

const { Title, Text } = Typography;
class LectureReportPage extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      classroomData: null,
      lectureData: null
    };
  }

  componentDidMount() {
    const { user, ClassroomActions } = this.props;
    const { id } = this.props.match.params;
    console.log("this id: ", id);
    if (user) {
      ClassroomActions.getClassroomList(user.orgId || user.userId).then(res => {
        if (res && res.length > 0) {
          console.log("res", res[0]);
          if (id) {
            this.getClassroomDetail(id);
            // this.getLectureList(id);
          } else {
            this.getClassroomDetail(res[0]["classroomId"]);
            // this.getLectureList(res[0]["classroomId"]);
          }
        }
      });
    }
  }

  getClassroomDetail(classroomId) {
    const { user } = this.props;
    const { lectureId } = this.props.match.params;
    this.setState({
      loading: true
    });
    Axios.get(
      `/academy/v2/accounts/${user.orgId ||
      user.userId}/classrooms/${classroomId}`
    )
      .then(res => {
        console.log("classroom detail: ", res);
        this.setState({
          loading: false,
          classroomData: res
          // lectureData: this.makeLectureData(res.lectures, lectureId)
          // lectureData: filter(flatten(values(res.lectures)), {
          //   seq: Number.parseInt(lectureId)
          // })[0]
        });
        this.makeLectureData(res.lectures, lectureId);
      })
      .catch(e => {
        this.setState({
          loading: false
        });
      });
  }

  async makeLectureData(lectures, lectureId) {
    const curLecture = filter(flatten(values(lectures)), {
      seq: Number.parseInt(lectureId)
    })[0];
    console.log("curLecture: ", curLecture);
    curLecture["lectureGroup"] = [];
    let flag = null;
    let groupIndex = -1;
    await curLecture.sections.map((s, index) => {
      if (s.type === "QUETURE" && flag !== s.quetureId) {
        groupIndex++;
        flag = s.quetureId;
        curLecture["lectureGroup"][groupIndex] = {};
        this.getQuetureData(groupIndex, s.quetureId);
      }
      if (s.type === "QUETURE" || s.type === "QUIZ" || s.type === "GAME") {
        curLecture["lectureGroup"][groupIndex][s.type] = s;
      }
    });
    console.log("curLecture: ", curLecture);

    this.setState({
      lectureData: curLecture
    });
  }

  getQuetureData(groupIndex, quetureId) {
    Axios.get(`/content/v2/quetures/${quetureId}`).then(resolve => {
      console.log("resovle: ", resolve);
      const curLecture = this.state.lectureData;
      curLecture["lectureGroup"][groupIndex]["quetureData"] = resolve;
      this.setState({
        lectureData: curLecture
      });
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      if (!err) {
        this.handleSetReport(values, true);
      }
    });
  };

  handleTemporarySave = () => {
    this.props.form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      if (!err) {
        this.handleSetReport(values, false);
      }
    });
  };

  handleSetReport = (values, isComplete) => {
    const { classroomData, lectureData } = this.state;
    const data = {
      complete: isComplete,
      section1: values.section1,
      section2: values.section2,
      section3: values.section3
    };
    ClassroomApis.setReport(
      classroomData.classroomId,
      lectureData.lectureId,
      data
    )
      .then(res => {
        console.log("set report: ", res);
        this.props.history.push(
          `/admin/class/detail/${classroomData.classroomId}`
        );
      })
      .catch(e => {
        message.warning("실패");
      });
  };

  render() {
    const { user, pending, classroomList } = this.props;
    const { loading, classroomData, lectureData } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    console.log("lectureData: ", lectureData);
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    };

    return (
      <Skeleton loading={loading || pending} active>
        {classroomData && classroomList && (
          <>
            <PageHeader
              title={
                <Title level={2} style={{ marginBottom: 0 }}>
                  수업 리포트 작성
                </Title>
              }
            />
            {lectureData && (
              <Row gutter={24} type="flex">
                <Col span={6} style={{ background: "#fff" }}>
                  <List itemLayout="vertical">
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Icon type="home" />}
                        title={
                          <Text style={{ color: "#397DCE" }}>
                            <Icon
                              type="home"
                              theme="twoTone"
                              style={{ marginRight: 8 }}
                            />
                            {`${this.context.className} 이름`}
                          </Text>
                        }
                        description={
                          <Text style={{ marginLeft: 24 }}>
                            {classroomData.name}
                          </Text>
                        }
                      />
                      <List.Item.Meta
                        avatar={<Icon type="user" />}
                        title={
                          <Text style={{ color: "#397DCE" }}>
                            <Icon type="user" style={{ marginRight: 8 }} />
                            {`${this.context.guideName} 이름`}
                          </Text>
                        }
                        description={
                          <Text style={{ marginLeft: 24 }}>
                            {(classroomData.guide &&
                              classroomData.guide.name) ||
                              "없음"}
                          </Text>
                        }
                      />
                      <List.Item.Meta
                        avatar={<Icon type="clock-circle" />}
                        title={
                          <Text style={{ color: "#397DCE" }}>
                            <Icon
                              type="clock-circle"
                              theme="twoTone"
                              style={{ marginRight: 8 }}
                            />
                            수업 일시
                          </Text>
                        }
                        description={
                          <Text style={{ marginLeft: 24 }}>
                            {lectureData.date}
                          </Text>
                        }
                      />
                      <List.Item.Meta
                        avatar={<Icon type="file-text" />}
                        title={
                          <Text style={{ color: "#397DCE" }}>
                            <Icon
                              type="file-text"
                              theme="twoTone"
                              style={{ marginRight: 8 }}
                            />
                            회차 및 깨처 정보
                          </Text>
                        }
                        description={
                          <div style={{ marginLeft: 24 }}>
                            <Text strong>{`${getSeqText(lectureData)}회차 수업`}</Text>

                            {lectureData.lectureGroup &&
                              lectureData.lectureGroup.map((lecture, index) => (
                                <Tag key={index} style={{ marginBottom: 8 }}>
                                  {lecture.quetureData &&
                                    lecture.quetureData.title}
                                </Tag>
                              ))}
                          </div>
                        }
                      />
                      <List.Item.Meta
                        avatar={<Icon type="calendar" />}
                        title={
                          <Text style={{ color: "#397DCE" }}>
                            <Icon
                              type="calendar"
                              theme="twoTone"
                              style={{ marginRight: 8 }}
                            />
                            리포트 제출 날짜
                          </Text>
                        }
                        description={
                          <Text style={{ marginLeft: 24 }}>
                            {lectureData.date}
                          </Text>
                        }
                      />
                    </List.Item>
                  </List>
                </Col>
                <Col
                  span={18}
                  style={{
                    background: "#fff",
                    borderLeft: "24px solid #f0f2f5",
                    padding: "40px 60px"
                  }}
                >
                  <Form
                    {...formItemLayout}
                    style={{ padding: "24px 0" }}
                    onSubmit={this.handleSubmit}
                    labelAlign="left"
                  >
                    <Form.Item label="수업 준비">
                      {getFieldDecorator("section1", {
                        initialValue:
                          lectureData.report && lectureData.report.section1
                      })(
                        <Input.TextArea
                          readOnly={
                            lectureData.report && lectureData.report.completed
                          }
                          placeholder="수업 준비 과정에 대해서 작성해주세요(최소 100자 이상)"
                        />
                      )}
                    </Form.Item>
                    <Divider />
                    <Form.Item label="수업 상황">
                      {getFieldDecorator("section2", {
                        initialValue:
                          lectureData.report && lectureData.report.section2
                      })(
                        <Input.TextArea
                          readOnly={
                            lectureData.report && lectureData.report.completed
                          }
                          placeholder="수업 상황에 대해서 작성해주세요(최소 100자 이상)"
                        />
                      )}
                    </Form.Item>
                    <Divider />
                    <Form.Item label="특이사항">
                      {getFieldDecorator("section3", {
                        initialValue:
                          lectureData.report && lectureData.report.section3
                      })(
                        <Input.TextArea
                          readOnly={
                            lectureData.report && lectureData.report.completed
                          }
                          placeholder="수업 관련 특이사항에 대해서 작성해주세요(최소 100자 이상)"
                        />
                      )}
                    </Form.Item>
                    <Divider />
                    <Form.Item wrapperCol={{ span: 20, offset: 4 }}>
                      {lectureData.report && lectureData.report.completed ? (
                        <Button
                          size="large"
                          htmlType="submit"
                          onClick={() =>
                            this.props.history.push(
                              `/admin/class/detail/${classroomData.classroomId}`
                            )
                          }
                        >
                          목록으로
                        </Button>
                      ) : (
                          <Button.Group style={{ float: "right" }}>
                            <Button
                              size="large"
                              style={{ marginRight: 16 }}
                              onClick={this.handleTemporarySave}
                            >
                              임시저장
                          </Button>
                            <Button type="primary" size="large" htmlType="submit">
                              리포트 제출
                          </Button>
                          </Button.Group>
                        )}
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            )}
          </>
        )}
      </Skeleton>
    );
  }
}

export default connect(
  ({ auth, classroom }) => ({
    pending: auth.pending || classroom.pending,
    user: auth.user,
    logged: auth.logged,
    classroomList: classroom.classroomList
  }),
  dispatch => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch)
  })
)(Form.create()(LectureReportPage));
