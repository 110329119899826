import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";

const TOGGLE_HEADER = "header/TOGGLE_HEADER";
const HANDLE_HEADER = "header/HANDLE_HEADER";
const SET_FIXED = "header/SET_FIXED";

export const toggleHeader = createAction(TOGGLE_HEADER);
export const handleHeader = createAction(HANDLE_HEADER);
export const setFixed = createAction(SET_FIXED);

const defaultState = {
  hide: false,
  fixed: true
};

export default handleActions(
  {
    [HANDLE_HEADER]: (state, { payload }) => {
      return {
        ...state,
        hide: update(state.hide, { $set: payload })
      };
    },
    [TOGGLE_HEADER]: (state, action) => {
      return {
        ...state,
        hide: update(state.hide, { $set: !state.hide })
      };
    },
    [SET_FIXED]: (state, { payload }) => {
      return {
        ...state,
        fixed: payload != null ? payload : true
      };
    }
  },
  defaultState
);
