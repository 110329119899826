import React from 'react';
import AttendanceChk from '../../../pages/classMode/attendanceChk/AttendanceChk';
import './_component.scss';
import GameRanking from '../../../pages/classMode/game/GameRanking';

const Pageall = () => {
  return (
    // 닫을시 is-close
    <div className="page-all-layout is-clos">
      <div className="preview-wrap">
        <div className="popup-closebtn"></div>
        <ul className="preview-list">
          {/* 현재 페이지 is-this */}
          <li className="preview is-this">
            <div className="content">
              <AttendanceChk/>
              <div className="dim">
                <span>출석체크</span>
              </div>
            </div>
          </li>
          <li className="preview">
            <div className="content">
              <GameRanking/>
              <div className="dim">
                <span>깨처</span>
              </div>
            </div>
          </li>
          <li className="preview">
            <div className="content">
              <div className="dim">
                <span>깨처</span>
              </div>
            </div>
          </li>
          <li className="preview">
            <div className="content">
              <div className="dim">
                <span>깨처</span>
              </div>
            </div>
          </li>
        </ul>
        <p className="popup-ment">해당 페이지를 클릭하면 이동합니다.</p>
      </div>
    </div>
  );
};

export default Pageall;