import React, { useState } from "react";
import SideMenu from "./SideMenu";
import ViewMenu from "./ViewMenu";
import BottomToolbar from "./BottomToolbar";

const ToggleViewMenu = props => {
  const [toggleState, setToggleState] = useState(true);

  return (
    <>
      <ViewMenu
        onClick={() => setToggleState(!toggleState)}
        className={toggleState ? "viewtoggle-btn" : "viewtoggle-btn is-on"}
      />
      {toggleState && (
        <>
          <SideMenu {...props} />
          <BottomToolbar {...props} />
        </>
      )}
    </>
  );
};

export default ToggleViewMenu;
