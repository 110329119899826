import React from "react";
import VanilaGamePlayer from "components/Game/VanilaGamePlayer";
import GameData from "pages/game/GameData";
import Popup from "../../../components/Popup/Popup";
import GameRankingList from "../../../components/Teacher/component/GameRankingList";

const vanila = GameData;
const locale = "ko";
// const gameData = GameData;

class GameMain extends React.Component {
  ranking = [];

  componentWillReceiveProps(nextProps) {
    this.ranking = nextProps.ranking;
  }
  getRanking = () => {
    const { user } = this.props;

    const _r = this.ranking;

    const my =
      _r && user && _r.find((r) => r.student.studentId === user.studentId);

    return {
      my: {
        score: my && my.score,
        time: my && my.time,
        user: {
          nickname: my ? my.student.name : "나",
        },
      },
      ranking:
        _r &&
        _r
          .sort((a, b) => {
            if (a.score != b.score) {
              return b.score - a.score;
            }
            return a.time - b.time;
          })
          .map((data, i) => ({
            rank: i + 1,
            score: data.score,
            time: data.time,
            user: {
              nickname: data.student.name,
            },
          })),
    };
  };

  render() {
    const {
      user,
      game,
      onRankingUpdate,
      classLocale,
      ranking,
      students,
    } = this.props;
    console.log("gameData: ", vanila);
    console.log(game);
    if (!game) {
      return null;
    }

    let locale = classLocale || "ko";

    return (
      <>
        {/* <GameRankingList isSimple {...this.props} /> */}
        <Popup className="game-popup" type="game-wrap" isOpen={true}>
          <VanilaGamePlayer
            component={vanila[game]["component"]}
            user={user}
            title={vanila[game][locale]["name"]}
            onClose={() => {
              // if (!isGameSuccess) {
              //   Toast.warning('게임을 먼저 완료해주세요.');
              //   return;
              // }

              //TODO temp
              window.location.reload();
            }}
            onComplete={(gameName, subType, score, time, callback) => {
              if (callback) {
                console.log("game end", score, time, user);
                callback({
                  score: score,
                  time: time,
                  user: {
                    nickname: user ? user.name : "나",
                  },
                });
              }
            }}
            loadRanking={(gameName, subType, callback) => {
              console.log(this.ranking);
              if (callback) {
                callback(this.getRanking());
              }
            }}
            width={vanila[game].width + "px"}
            height={vanila[game].height + "px"}
            onScoreUpdate={(gameName, mode, score, time) => {
              console.log(gameName);
              console.log(mode);
              console.log(score);
              console.log(time);
              onRankingUpdate(gameName, score, time);
            }}
            // user={user}
            isAcademy={true}
            // isInLearningPack={true}
            // clearScore={clearScore}
            // onClear={() => {
            //   setStartGame(false)
            // }}
          />
        </Popup>
      </>
    );
  }
}
export default GameMain;
