import React from "react";

const ACheckbox = props => {
  return (
    // mvp일경우 .inputwrap에 mvp 추가
    // disable 일 경우 클래스 disable 추가, disabled 값에 disabled
    // ex : <div className="inputwrap chkbox mvp">
    <div className="inputwrap chkbox">
      <input type="checkbox" {...props} />
      <span></span>
      <label htmlFor={props.id}>{props.text}</label>
    </div>
  );
};

export default ACheckbox;
