import React, { useState, useEffect } from "react";
import { AxiosToMain } from "utils/request";
import { Skeleton } from "antd";
import { Image } from "components/Quiz/modules";
import { formatText } from "components/Quiz/utils/Formatter";
import ImageSelectQuiz from "components/Quiz/ImageSelectQuiz";
import PairingQuiz from "components/Quiz/PairingQuiz";

const QuizDescCard = (props) => {
  const { quizInfo, solvedData, quizId, quizIndex, forWorkbook, randomSeed } =
    props;

  const [loading, setLoading] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [solving, setSolving] = useState(null);

  useEffect(() => {
    if (quizInfo && solvedData) {
      setQuizData(quizInfo);
      setSolving(solvedData);
    } else {
      loadQuiz();
    }
  }, [quizId]);

  function loadQuiz() {
    setLoading(true);

    return AxiosToMain.get(
      `/quiz/v2/quizzes/${quizId}` +
        (randomSeed != null ? "?randomSeed=" + randomSeed : "")
    )
      .then((data) => {
        setQuizData(data);
        AxiosToMain.post(`/quiz/v2/quizzes/${quizId}/solve`, {
          answers: [],
          vars: data.quiz.vars,
        })
          .then((response) => {
            setSolving(response);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <div style={{ position: "relative" }}>
      <Skeleton loading={loading} active>
        {solving && (
          <div className="quiz-solving-content">
            <table>
              <tr>
                <td>
                  <div className="quiz-solving-content-number">
                    {quizIndex + 1}.
                  </div>
                </td>
                <td>
                  <div className="quiz-solving-content-body">
                    {formatText(
                      solving.desc.text,
                      null,
                      quizData?.quiz?.commetaryImages?.map(
                        (c) => c.signedUrl
                      ) || null
                    )}
                    {quizData?.quiz?.type == "image_select" && (
                      <ImageSelectQuiz
                        data={quizData}
                        isMobileSize={false}
                        play={false}
                        solvedData={solving}
                        showReallyTrue={true}
                        quizType={"printView"}
                        forWorkbook={true}
                        onAnswerUpdated={() => null}
                      />
                    )}
                    {quizData?.quiz?.type == "pairing" && (
                      <PairingQuiz
                        data={quizData}
                        isMobileSize={false}
                        play={false}
                        solvedData={solving}
                        showReallyTrue={true}
                        quizType={"printView"}
                        forWorkbook={true}
                        onAnswerUpdated={() => null}
                      />
                    )}
                  </div>
                  <div className="quiz-solving-content-image">
                    <Image
                      image={solving.desc.image}
                      imageMobile={solving.desc.imageMobile}
                      frames={solving.desc.anim}
                      texts={solving.desc.textsOnImage}
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        )}
      </Skeleton>
    </div>
    // </div>
  );
};

export default QuizDescCard;
