import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from "store/modules/auth";
import { withRouter } from "react-router-dom";

class LogoutPage extends Component {
  componentDidMount() {
    const { user, logged } = this.props;
    var isAdmin = false;
    console.log(user);
    if (logged && (user.type === 'org' || user.type === 'guide')) {
      isAdmin = true;
    }
    console.log("logout: ");
    this.props.AuthActions.logout();
    this.props.history.replace(isAdmin ? "/admin-login" : "/login");
  }

  render() {
    return <div></div>;
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      auth: auth,
      user: auth.user,
      logged: auth.logged
    }),
    dispatch => ({
      AuthActions: bindActionCreators(authActions, dispatch)
    })
  )(LogoutPage)
);
