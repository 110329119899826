import React, { Component } from "react";

class BodyMovin extends Component {
  componentDidMount() {
    this.init();
  }

  init = () => {
    const options = Object.assign({}, this.props.options);
    options.wrapper = this.wrapper;
    options.renderer = "svg";
    this.animation = window.bodymovin.loadAnimation(options);
  };

  componentWillUnmount() {
    this.animation.destroy();
  }

  // shouldComponentUpdate() {
  //   return false;
  // }

  componentDidUpdate(prevPros) {
    // console.log('init body movin');
    if (prevPros.isPlay !== this.props.isPlay && this.props.isPlay) {
      // console.log('init body movin');
      this.animation.destroy();
      this.init();
    }
  }

  render() {
    const storeWrapper = el => {
      this.wrapper = el;
    };
    return <div className={this.props.className} ref={storeWrapper} />;
  }
}

export default BodyMovin;
