import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend'
// import '../css/Quiz.scss';
import { formatText } from '../utils/Formatter';
import classNames from 'classnames';

// const answerSource = {
//   beginDrag(props) {
//     return {
//       data: props.choice
//     };
//   },
// }

class DraggableAnswer extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      })
    }
  }

  render() {
    const { choice, isDragging, connectDragSource } = this.props;

    // return connectDragSource(
    return (
      <a className={classNames("draggable btn-drag", {
        "is-dragging": isDragging
      })}>
        <span>{formatText(choice.answer)}</span>
      </a>
    )
  }
}

export default DraggableAnswer;
// export default DragSource(
//   (props) => "drag_" + props.parentId,
//   answerSource,
//   (connect, monitor) => ({
//     connectDragSource: connect.dragSource(),
//     connectDragPreview: connect.dragPreview(),
//     isDragging: monitor.isDragging()
//   })
// )(DraggableAnswer);
