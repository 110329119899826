import asyncRoute from 'utils/asyncRoute';

export const PenguinRunComponent = asyncRoute(() => import('components/Game/Penguin/JG01_p_run/PenguinRunComponent'));
export const PenguinRunEasyComponent = asyncRoute(() => import('components/Game/Penguin/JG01_p_run_easy/PenguinRunComponent'));
export const PenguinGroupingComponent = asyncRoute(() => import('components/Game/Penguin/PenguinGrouping/PenguinGroupingComponent'));
export const PenguinFishingComponent = asyncRoute(() => import('components/Game/Penguin/PenguinFishing/PenguinFishingComponent'));
export const PenguinHomecommingComponent = asyncRoute(() => import('components/Game/Penguin/PenguinHomecomming/PenguinHomecommingComponent'));
export const PenguinMigrationComponent = asyncRoute(() => import('components/Game/Penguin/PenguinMigration/PenguinMigrationComponent'));
export const PenguinCastleComponent = asyncRoute(() => import('components/Game/Penguin/PenguinCastle/PenguinCastleComponent'));
export const Penguin3DComponent = asyncRoute(() => import('components/Game/Penguin/Penguin3D/Penguin3DComponent'));
export const watchMakerComponent = asyncRoute(() => import('components/Game/Penguin/watchMaker/watchMakerComponent'));
export const bowMasterComponent = asyncRoute(() => import('components/Game/Penguin/bowMaster/bowMasterComponent'));
export const slimeLabComponent = asyncRoute(() => import('components/Game/Penguin/slimeLab/slimeLabComponent'));
// export const PenguinArithmeticComponent = asyncRoute(() => import('components/Game/Penguin/PenguinArithmetic/PenguinArithmeticComponent'));
export const tentensComponent = asyncRoute(() => import('components/Game/Penguin/tentens/tentensComponent'));
export const AqueariumComponent = asyncRoute(() => import('components/Game/Penguin/Aquearium/AqueariumComponent'));
export const PenguinCubeComponent = asyncRoute(() => import('components/Game/Penguin/PenguinCube/PenguinCubeComponent'));
export const alienDefenceComponent = asyncRoute(() => import('components/Game/Penguin/alienDefence/alienDefenceComponent'));
export const dduckguckComponent = asyncRoute(() => import('components/Game/Penguin/DDuckGuck/DDuckGuckComponent'));
export const spaceBubbleComponent = asyncRoute(() => import('components/Game/Penguin/spaceBubble/spaceBubbleComponent'));
export const MultiPangPangComponent = asyncRoute(() => import('components/Game/Penguin/MultiPangPang/MultiPangPang'));
export const lostTempleComponent = asyncRoute(() => import('components/Game/Penguin/JG18_farm/FarmComponent'));
export const festivalComponent = asyncRoute(() => import('components/Game/Penguin/festival/festivalComponent'));
export const rabbitAlchemistComponent = asyncRoute(() => import('components/Game/Penguin/JG20_r_alchemy/alchemistComponent'));
export const rabbitTrainComponent = asyncRoute(() => import('components/Game/Penguin/JG21_r_train/TrainComponent'));
export const bubblesComponent = asyncRoute(() => import('components/Game/Penguin/JG22_bubbles/BubbleComponent'));
export const detectiveComponent = asyncRoute(() => import('components/Game/Penguin/JG23_detective/DetectiveComponent'));
export const explorerComponent = asyncRoute(() => import('components/Game/Penguin/JG24_explorer/ExplorerComponent'));
export const snowfightComponent = asyncRoute(() => import('components/Game/Penguin/JG25_snowfight/SnowfightComponent'));


export const dragonHunterComponent = asyncRoute(() => import('components/Game/Penguin/syu_DragonHunter/DHComponent'));
export const mmbbComponent = asyncRoute(() => import('components/Game/Penguin/syu_mmbb/MMBBComponent'));
export const pizzaComponent = asyncRoute(() => import('components/Game/Penguin/syu_PizzaStore/PizzaStoreComponent'));
export const cotComponent = asyncRoute(() => import('components/Game/Penguin/syu_CoT/CoTComponent'))
export const plusBubbleComponent = asyncRoute(() => import('components/Game/Penguin/PlusBubble/PlusBubbleComponent'));
export const equationComponent = asyncRoute(() => import('components/Game/Penguin/syu_Equation/EquationComponent'));
export const arithPuzzleComponent = asyncRoute(() => import('components/Game/Penguin/syu_ArithPuzzle/APComponent'));
export const starRoadComponent = asyncRoute(() => import('components/Game/Penguin/StarRoad/StarRoadComponent'));

export const queboardLight = asyncRoute(() => import('components/Game/Penguin/QueboardLight/QueboardLightComponent'))
export const Quegudan2 = asyncRoute(() => import('components/Game/Penguin/Quegudan2/Quegudan2Component'))

//키즈게임
export const KidsPenguinRunComponent = asyncRoute(()=> import('components/Game/Kids/Kids_p_run/PenguinRunComponent')); // 펭귄런
export const KidsPenguinFishingComponent = asyncRoute(()=> import('components/Game/Kids/Kids_p_fishing/PenguinFishingComponent')); // 펭귄피싱
export const KidsPenguinMigrationComponent = asyncRoute(()=> import('components/Game/Kids/Kids_p_migration/PenguinMigrationComponent')); // 펭귄대이동
export const KidsPenguinGroupingComponent = asyncRoute(()=> import('components/Game/Kids/Kids_p_grouping/PenguinGroupingComponent')); // 펭귄이글루
export const KidsPenguinHomecommingComponent = asyncRoute(()=> import('components/Game/Kids/Kids_p_homecomming/PenguinHomecommingComponent')); // 펭귄홈커밍
export const KidsSlimeLabComponent = asyncRoute(()=> import('components/Game/Kids/Kids_slimeLab/slimeLabComponent')); // 슬라임연구소
export const KidsTenTensComponent = asyncRoute(()=> import('components/Game/Kids/Kids_tentens/tentensComponent')); // 텐텐스
export const KidsWatchMakerComponent = asyncRoute(()=> import('components/Game/Kids/Kids_watchMaker/watchMakerComponent')); // 만능 시계공
export const KidsAqueariumComponent = asyncRoute(()=> import('components/Game/Kids/Kids_aquearium/AqueariumComponent')); // 아께아리움
export const KidsPenguinCastleComponent = asyncRoute(()=> import('components/Game/Kids/Kids_p_castle/PenguinCastleComponent')); // 펭귄그라운드
export const KidsSpaceBubbleComponent = asyncRoute(()=> import('components/Game/Kids/kids_p_spaceBubble/spaceBubbleComponent'));// 펭귄펙토리

