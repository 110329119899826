import React, { Component } from "react";
import {
  Modal,
  Typography,
  Select,
  Row,
  Col,
  Table,
  Input,
  message,
  Divider,
  Icon,
  Button
} from "antd";

import { getHost, getDefaultConfig } from "utils/APIUtils";
import axios from "axios";
import { isCsv } from "utils/utils";

export class UploadStudentPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      updated: false,
      successList: [],
      failList: []
    };
  }

  onFilesAdded = files => {
    const file = files[0];
    if (file.size > 10000000) {
      message.warning("최대 파일 크기는 10M입니다.");
      return;
    }
    // if (!isCsv(file)) {
    //   message.warning("CSV파일 만 추가할수 있습니다.");
    //   return;
    // }
    // setIsLoading(true);

    console.log(file);
    const fileData = new Blob([file], {
      type: "text/csv"
    });

    axios
      .post(
        `${getHost()}/academy/v2/accounts/${
        this.props.user.orgId
        }/students/bulk`,
        fileData,
        {
          ...getDefaultConfig({
            "Content-Type": "text/csv"
          })
        }
      )
      .then(fileRes => {
        console.log("res: ", fileRes);
        this.setState({
          successList: fileRes.data.success,
          failList: fileRes.data.fail,
          updated: true
        });
      })
      .catch(error => {
        message.warning(
          "학생 업로드에 실패했습니다. 잠시 후 다시 시도해주세요."
        );
      });
  };

  render() {
    const { successList, failList, updated } = this.state;

    const cols = [
      {
        title: "이름",
        key: "name",
        dataIndex: "name",
        render: text => <span>{text}</span>
      },
      {
        title: "아이디",
        key: "account",
        dataIndex: "account",
        render: text => <span>{text}</span>
      }
    ];

    console.log("state: ", this.state);

    return (
      <Modal
        title="학생 등록하기"
        visible={true}
        onOk={() => this.props.handleClose(updated)}
        onCancel={() => this.props.handleClose(updated)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        ></div>
        {!updated ? (
          <div
            style={{
              marginTop: 24,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 300
            }}
          >
            <Typography.Text strong>
              {`CSV 파일을 업로드 해주세요.`}
            </Typography.Text>
            <label
              htmlFor={"file"}
              style={{
                backgroundColor: "#1890ff",
                borderColor: "#1890ff",
                borderRadius: 4,
                color: "#fff",
                padding: "4px 8px",
                marginBottom: 16
              }}
            >
              파일 업로드
              <input
                id={"file"}
                type="file"
                style={{ display: "none" }}
                onChange={e => this.onFilesAdded(e.target.files)}
              />
            </label>
            <Typography.Text>{`CSV 파일 없다면?`}</Typography.Text>
            <a
              href={getHost() + "/academy/v2/students-form"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary" size="small">
                양식 다운로드
                <Icon type="file" />
              </Button>
            </a>
          </div>
        ) : (
            <div>
              {successList.length > -1 && (
                <Table
                  rowKey="account"
                  dataSource={successList}
                  columns={cols}
                  title={() => (
                    <Typography.Text strong>추가 된 학생 목록</Typography.Text>
                  )}
                  pagination={false}
                  size="small"
                />
              )}
              <Divider />
              {failList.length > 0 && (
                <Table
                  rowKey="account"
                  dataSource={failList}
                  columns={cols}
                  title={() => (
                    <Typography.Text strong>
                      추가 실패 된 학생 목록
                  </Typography.Text>
                  )}
                  pagination={false}
                  size="small"
                />
              )}
            </div>
          )}
      </Modal>
    );
  }
}

export default UploadStudentPopup;
