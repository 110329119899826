import React from 'react';
import classNames from 'classnames';

const RangeSelector = (props) => {
  const { index, choice, onSelected, selected, solvedData, showReallyTrue } = props;

  if (choice.answer === "<range>") {
    // 범위
    return (
      <td className={
        classNames(
          "rowbar",
          {
            'is-chk': selected,
            'is-ture': selected && solvedData.solved && solvedData.corrects[0],
            'is-false': selected && solvedData.solved && !solvedData.corrects[0],
            'is-true': showReallyTrue && (+solvedData.correctAnswers === index),
            'none-event': solvedData && solvedData.solved,
          }
        )
      }>
        <button type="button" className="" onClick={onSelected}></button>
      </td>
    )
  }

  //값
  return (
    <td className={
      classNames(
        "circlebar",
        {
          'is-chk': selected,
          'is-ture': selected && solvedData.solved && solvedData.corrects[0],
          'is-false': selected && solvedData.solved && !solvedData.corrects[0],
          'is-true': showReallyTrue && (+solvedData.correctAnswers === index),
          'none-event': solvedData && solvedData.solved,
        }
      )
    }>
      <button type="button" className="" onClick={onSelected}></button>
      <span>{choice.answer}</span>
    </td>
  )
}

export default RangeSelector;
