import React from "react";
import classNames from "classnames";
import { filter } from "lodash";
import { formateTime, getProfileUrl } from "../../../utils/utils";
import { orderBy, values, mapValues } from "lodash";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import fakeAvatar3 from "./img/fakeAvatar3.svg";

import "./_component.scss";

const GroupStudentList = (props) => {
  const { teamLeaderId, studentList, pickedList, onCheckMember } = props;
  if (!studentList) {
    return null;
  }
  console.log("group studentlist: ", studentList);
  //학생수가 5명 이하일시 attendancelist-wrap에 is-bigcard 추가
  return (
    <ul className="attendancelist-wrap">
      {studentList.map((student) => {
        const isPick = pickedList.includes(student.studentId);
        const _profileImage =
          (student.profileImage &&
            getProfileUrl(student.profileImage.contentId)) ||
          null;
        return (
          <li
            key={student.studentId}
            className={classNames("attendancelist effect", {
              "is-pick": isPick,
            })}
            onClick={() => {
              if (isPick) {
                return;
              }
              onCheckMember(student.studentId);
            }}
          >
            <div className="img">
              <img
                src={
                  (student.profileImage &&
                    getProfileUrl(student.profileImage.contentId)) ||
                  fakeAvatar3
                }
                alt=""
              />
            </div>
            {!isPick && (
              <div className="attendancelist__name">
                <FormattedHTMLMessage
                  id="classroom.my-teammate-is"
                  tagName="span"
                />
              </div>
            )}
            {isPick && (
              <>
                <div className="attendancelist__name">{student.name}</div>
                {student.studentId === teamLeaderId && (
                  <div className="teamleader">
                    <FormattedMessage id="classroom.leader" />
                  </div>
                )}
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default GroupStudentList;
