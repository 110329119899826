import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as headerActions from "store/modules/header";
import classNames from "classnames";
import MultiChoiceProb from "./MultiChoiceProb";
import FillBlankProb from "./FillBlankProb"; //선택하기
import TextSelectProb from "./TextSelectProb"; //주관식
import InlineMultiChoiceProb from "./InlineMultiChoiceProb";
import OrderingProb from "./OrderingProb";
import QuizToolbar from '../QuizToolbar';

// import LearningStart from './Learning/LearningStart';

import '../style/index.scss';
import '../css/_layout.scss';
import '../css/Quiz.scss';

class QuizPublishing extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showAnswer : false
  }

  toggleAnwser = (isShow) => {
    this.setState({
      showAnswer: isShow
    });
  }

  render() {
    const {showAnswer} = this.state;
    return (
      <React.Fragment>
          <div className="quiz-content">
            <div className="quiz-content__wrap">
              <div className="quiz-content__title">
                <span>다음 보기 중 '거꾸로'가 있을 수 있는 경우는?</span>
              </div>
              <div className="quiz-content__content not-drag">
                <div className="quiz-content__content-title">
                  그림은 1<span>√</span>2 깨다가 벼룩시장에서 번 돈을
                  정리했는데, 모르고 초코우유를 쏟아 '거꾸로'라는 단어가 지워져
                  버린 그림이다. 기억나는 것을 모두 합치면 '0원 이익'이라는
                  점이다.
                </div>
                <div className="quiz-content__content-img">
                  <img
                    src="http://ww2.sjkoreancatholic.org/files/testing_image.jpg"
                    alt=""
                  />
                </div>
                <ul className="quiz-content__answer t-answer-list half">
                  <li className="is-chk">
                    <span><span>5</span></span>
                  </li>
                  <li className="is-true">
                    <span><span>8</span></span>
                  </li>
                  <li className="is-false">
                    <span><span>9</span></span>
                  </li>
                  <li className="">
                    <span><span>십</span></span>
                  </li>
                </ul>
              </div>

              {/* 토글 is-open */}
              <div className={classNames("quiz-content__solve", {
                "is-open":showAnswer === true
              })}>
                <div className={classNames("quiz-content__solve-title",{
                })} onClick={()=>this.toggleAnwser(!showAnswer)}>문제풀이 보기</div>
                <div className="quiz-content__solve-content">
                  <div className="title">문제풀이</div>
                  <p>
                    700원 앞에 '거꾸로'가 있다고 생각해보면, <br />
                    따라서 '300원 + 200원 + 거꾸로 700원 + 200원'으로 계산해봤을때
                    '0원 이익'이 된다.
                  </p>
                  <img src="" alt="" className="img" />
                  <div className="quiz-content__solve-btns">
                    <span>정답확인 or 나의 답보기</span>
                    <div className="switch">
                      <label>
                        <input type="checkbox" className="switch-control"/>
                        <span className="switch-label"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <QuizToolbar/>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    HeaderActions: bindActionCreators(headerActions, dispatch)
  })
)(QuizPublishing);
