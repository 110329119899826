import React, { useState } from "react";
import { Input, Button, Form, Row, Col, Icon } from "antd";
import FormItem from "antd/lib/form/FormItem";
import WrappedNormalLoginForm from "./components/Login/LoginForm";
import LoginTab from "./components/Login/LoginTab";
import ClassLogin from "../classMode/login/ClassLogin";

const LoginPage = (props) => {
  console.log("login: ", props);
  return (
    // <Row gutter={16} type="flex" justify="center">
    //   <Col>
    //     <LoginTab />
    //     <WrappedNormalLoginForm />
    //   </Col>
    // </Row>
    <ClassLogin {...props} />
  );
};

export default LoginPage;
