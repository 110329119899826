import React from "react";
import { FormattedMessage } from "react-intl";
import "./_etcpage.scss";
import ByeAni from "../../../components/BodyMovin/Animation/Bye";

const EndClass = ({ isKids }) => {
  return (
    <div className="classend-layout">
      <div className="confetti">
        {new Array(10000).fill(null).map((c, i) => (
          <div key={i} className="confetti-piece"></div>
        ))}
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
      </div>
      <div className="classend-title">
        <div className="classend-title-wrap">
          <div className="big-title u-color-red">
            <FormattedMessage id="classroom.class-has-end" />
          </div>
          {!isKids && (
            <div className="page-content-ment">
              <FormattedMessage id="classroom.check-home-training" />
            </div>
          )}
        </div>
      </div>
      <ByeAni />
    </div>
  );
};

export default EndClass;
