import React from 'react';
import GroupStudentList from "../../../components/Teacher/component/GroupStudentList";
import './_teamselectchk.scss';

const TeamSelectChk = () => {
  return (
    <div className="teamselectchk-layout">
      <div className="teamselectchk-wrap">
        <div className="big-title">오늘 나의 짝꿍</div>
        <div className="small-title">팀장 짝꿍의 자리로 이동해주세요.</div>
        <GroupStudentList
        // studentList={myGroup}
        // pickedList={pickedList}
        // onCheckMember={checkMember}
        // teamLeaderId={teamLeaderId}
        />
      </div>
    </div>
  );
};

export default TeamSelectChk;