import React, { Component } from "react";
import {
  Modal,
  Typography,
  DatePicker,
  Select,
  Row,
  Col,
  Table,
  Input
} from "antd";
import { filter } from "lodash";

class ChangeClassroomNamePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.value
    };
  }

  handleOK() {
    const { onSelect } = this.props;
    onSelect(this.state.name);
  }

  render() {
    return (
      <Modal {...this.props} onOk={() => this.handleOK()}>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col span={6}>이름</Col>
          <Col span={18}>
            <Input
              value={this.state.name}
              onChange={e => this.setState({ name: e.target.value.trim() })}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default ChangeClassroomNamePopup;
