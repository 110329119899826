import React, { useState, useEffect } from "react";
import { filter } from "lodash";
import { numberToText } from "../../../utils/utils";
import QuetureName from "../../admin/components/QuetureName";
import classNames from "classnames";
import { getSeqText } from 'utils/utils';

const HomeworkTooltip = props => {
  const { top, left, lecture, homework } = props;
  const [lectureData, setLectureData] = useState({
    seq: "",
    quetureList: []
  });

  useEffect(() => {
    return () => { };
  }, [homework]);

  return (
    <div
      className="ht-tooltip"
      style={{
        left: left ? left + 10 : 10,
        top: top ? top + 10 : 10
      }}
    >
      <div className="ht-tooltip-wrap">
        <div className="ht-tooltip__title">
          <strong>수업정보</strong>
          {getSeqText(homework)} 수업
        </div>
        {homework.quetureList.map((item, index) => (
          <div key={"queture-" + index}>
            <p className="group-title" key={index}>
              <span>{numberToText(index + 1)}번째 깨처</span>
              <QuetureName quetureId={item.quetureId} />
            </p>
            <ul className="quest">
              <li
                className={classNames({
                  "is-complete": item.isDone
                })}
              >
                깨처 영상 복습
              </li>
              {!item.skipQuiz && (
                <li
                  className={classNames({
                    "is-complete": item.completedQuiz
                  })}
                >
                  틀린 문제 풀기
                </li>
              )}
            </ul>
          </div>
        ))}
        {homework.uploadList.length > 0 && (
          <div>
            <p className="group-title mission">
              <span>학습미션</span>
            </p>
            <ul className="quest">
              {homework.uploadList.map((item, index) => (
                <li
                  key={"upload-" + index}
                  className={classNames({
                    "is-complete": item.isDone
                  })}
                >
                  학습 미션
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeworkTooltip;
