import React, { useState, useEffect } from "react";
import { AxiosToMain } from "utils/request";
import { formatText, formatProblem } from "components/Quiz/utils/Formatter";
import { circledNumbers } from "./workbookUtil";

const QuizAnswerCard = (props) => {
  const { quizInfo, solvedData, quizId, quizIndex, forWorkbook, randomSeed } =
    props;

  const [loading, setLoading] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [solving, setSolving] = useState(null);

  useEffect(() => {
    if (quizInfo && solvedData) {
      setQuizData(quizInfo);
      setSolving(solvedData);
    } else {
      loadQuiz();
    }
  }, [quizId]);

  function loadQuiz() {
    setLoading(true);

    return AxiosToMain.get(
      `/quiz/v2/quizzes/${quizId}` +
        (randomSeed != null ? "?randomSeed=" + randomSeed : "")
    )
      .then((data) => {
        setQuizData(data);
        AxiosToMain.post(`/quiz/v2/quizzes/${quizId}/solve`, {
          answers: [],
          vars: data.quiz.vars,
        })
          .then((response) => {
            setSolving(response);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function getAnswerString(v, i) {
    switch (quizData.quiz.type) {
      case "boolean":
        return String(v) == "true" ? "맞아요" : "틀려요";

      case "drag_text":
        return <>{formatText(v)}</>;

      case "fill_blank":
        return <>{formatText(v)}</>;
      case "counting":
        return <>{formatText(v)}</>;
      case "coloring":
        return <>{formatText(v)}</>;

      case "multi_choice":
        let _answer = Number.parseInt(v);
        return (
          <span style={{ fontSize: "1.2em" }}>{circledNumbers[_answer]}</span>
        );

      case "select_text":
        let valueString = <>{v}번째</>;
        const problem = formatProblem(
          quizData.quiz.problem,
          quizData.quizId + "_" + quizData.timestamp,
          (index, data) => {
            if (i == index) {
              valueString = (
                <span>
                  {formatText(data.split("|").map((s) => s.trim())[Number(v)])}
                </span>
              );
            }
          }
        );
        return valueString;

      case "ordering":
        // NOTE: ordering answer might has newline characters which is formatText()ed to <br />. So replace to space before formatText()
        //return <span>{formatText(String(quizData.quiz.choices.find(e => e.key == v)?.answer || '??').replaceAll('\n', ' '))}</span>;
        return (
          <span style={{ fontSize: "1.2em" }}>
            {
              circledNumbers[
                quizData.quiz.choices.findIndex((e) => e.key == v) + 1
              ]
            }
          </span>
        );

      // case 'pairing':  // 해설 참조
      //  return <span><span style={{ fontSize: '0.8em' }}>{circledNumbers[Number(i+1)]} &mdash;</span> {formatText(quizData.quiz.choices.find(e => e.key == v)?.answer || '??')}</span>;

      //case 'image_select':  // 해설 참조

      case "inline":
        return (
          <span>
            <span style={{ verticalAlign: "+2px" }}>({i + 1})</span>{" "}
            {v.map((v, j) => (
              <>
                {j >= 1 && <span style={{ verticalAlign: "+1px" }}>, </span>}
                <span style={{ fontSize: "1.2em" }}>
                  {circledNumbers[Number(v)]}
                </span>
              </>
            ))}
          </span>
        );

      default:
        return quizData.quiz.type + ":" + String(v);
    }
  }

  let answers = solving && solving.correctAnswers;
  if (quizData?.quiz?.type === "multi_choice" && quizData?.quiz?.random) {
    const random = quizData?.quiz?.random;
    let _answers = [];
    for (var i = 0; i < random.length; i++) {
      const ai = random[i] + 1;
      if (answers?.includes(`${ai}`)) {
        _answers.push(i + 1);
      }
    }
    console.log("random answers: ", _answers);
    answers = _answers;
  }

  return loading ? (
    "loading"
  ) : !solving || !quizData || !quizData.quiz ? (
    "N/A"
  ) : quizData.quiz.type == "image_select" ? (
    <span>해설 참조</span>
  ) : quizData.quiz.type == "pairing" ? (
    <span>해설 참조</span>
  ) : (
    <div>
      {answers.map((v, i) => (
        <>
          {getAnswerString(v, i)}
          {i < answers.length - 1 ? (
            quizData.quiz.type === "ordering" ? (
              <>&nbsp;&rarr;&nbsp;</>
            ) : quizData.quiz.type === "inline" ? (
              <>&nbsp;&nbsp;&nbsp;&nbsp;</>
            ) : (
              <>,&nbsp;&nbsp;</>
            )
          ) : null}
        </>
      ))}
    </div>
  );
};

export default QuizAnswerCard;
