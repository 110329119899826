import LogoutPage from "../pages/logout";
import NotFound from "../pages/NotFound";
import NoPermissions from "../pages/NoPermissions";
import TestLoginPage from "../pages/test/login";
import PublishedReportPage from "../pages/admin/student/report/PublishedReportPage";
// import SolvingBookPage from "../pages/admin/student/solvingBook/index"
import PublishedNewsletterPage from "../pages/admin/Newsletter/publishedNewsletterPage"
export default [
  {
    path: "/logout",
    exact: true,
    requiresAuth: false,
    component: LogoutPage
  },
  {
    path: "/login-test",
    exact: true,
    requiresAuth: false,
    component: TestLoginPage
  },
  {
    path: "/permission-denied",
    exact: true,
    component: NoPermissions
  },
   {
    path: "/nl",
    exact: false,
    component: PublishedNewsletterPage
  },
  // {
  //   path: "/sv",
  //   exact: false,
  //   component: SolvingBookPage
  // },
  {
    path: "/xr",
    exact: false,
    component: PublishedReportPage
  },
  {
    path: "*",
    component: NotFound,
    requiresAuth: false
  }

];
