export default {
  "classroom.attendance": "출석체크를 해주세요",
  "classroom.lectureTime": "지금은 수업중입니다.",
  "classroom.quetureSeq": "{seq} 번째 깨처",
  "classroom.quetureSeq-1": "첫 번째 깨처",
  "classroom.quetureSeq-2": "두 번째 깨처",
  "classroom.quetureSeq-3": "세 번째 깨처",
  "classroom.quetureSeq-4": "네 번째 깨처",
  "classroom.quetureSeq-5": "다섯 번째 깨처",
  "classroom.quetureSeq-6": "여섯 번째 깨처",

  "classroom.quetureDescTitle": "오늘 배울 개념을 함께 알아볼까요!",
  "classroom.pictureQuizDescTitle": "오늘 배운 내용을 떠올리며 깨봉 그림퀴즈를 풀어보세요!",
  "classroom.quebonSongDescTitle": "깨봉송을 다같이 신나게 불러 봐요!",

  "classroom.queture-point": "깨처포인트",

  "classroom.makeTeamTitle": "이번 문제풀기 미션은",
  "classroom.soloPlay": "개인전",
  "classroom.soloPlayDesc": "스스로 문제를 풀어볼까요",
  "classroom.teamPlay": "팀전",
  "classroom.teamPlayDesc": "친구와 함께 문제를 풀어볼까요",
  "classroom.select-your-teammate": "오늘 나의 짝꿍을 선택해 보세요",
  "classroom.my-teammate-is": "나의 <br /> 짝꿍은",
  "classroom.move-to-leaders-seat": "팀장의 자리로 이동해 주세요",
  "classroom.leader": "팀장",
  "classroom.mark": "채점하기",
  "classroom.marking": "채점중...",
  "classroom.next-question": "다음문제",
  "classroom.prev-question": "이전문제",
  "classroom.finish": "완료",
  "classroom.incorrect": "틀렸어",
  "classroom.incorrect-desc": "다시 생각해보자",
  "classroom.correct": "정답이야",
  "classroom.correct-desc": "정말 대단한걸",
  "classroom.todays-game": "오늘의 게임",
  "classroom.todays-activity": "오늘의 활동",
  "classroom.todays-activity-kids": "오늘의 수학놀이",
  "classroom.todays-mvp": `오늘의 <span>MVP</span>`,
  "classroom.class-has-end": "수업이 끝났습니다",
  "classroom.check-home-training": "홈트레이닝에서 과제를 확인하세요",
  "classroom.view-solution": "문제풀이 보기",
  "classroom.solution": "문제풀이",
  "classroom.check-the-answer": "정답확인",
  "classroom.my-answer": "나의 답보기",
  "classroom.game-desc": "게임을 하면서 생각해 보아요!",
  "classroom.activity-desc": "함께 활동해 볼까요?",
  "classroom.activity-desc-kids": "함께 해 볼까요?",
  "classroom.order-1": "첫 번째",
  "classroom.order-2": "두 번째",
  "classroom.order-3": "세 번째",
  "classroom.order-4": "네 번째",
  "classroom.order-5": "다섯 번째",
  "classroom.order-6": "여섯 번째",
  "classroom.correct-questions": "{count}문제 정답!",
  "classroom.challengeQuizTitle": "도전 문제",
  "classroom.endQuizTitle": "정리퀴즈",
  "classroom.storytellingTitle": "수학동화",
  "classroom.pictureQuizTitle": "깨봉 그림퀴즈",
  "classroom.quebonSongTitle": "깨봉송",


};
