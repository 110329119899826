import React, { Component } from "react";
import "./_etcpage.scss";
import Logo from "../../../style/components/img/logo.svg";
import { Link } from "react-router-dom";
import QueturePlayer from "../../../components/QueturePlayer/QueturePlayer";
import { getDefaultConfig, getHost } from "utils/APIUtils";
import kidsBg from './bg-kids-standby.svg'

class StandBy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quetureData: null,
      quetureContent: null,
    };
  }

  componentDidMount() {
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("REVIEW_CHANNEL");
      listenChannel.addEventListener("message", this.handleQuetureData);
    }
  }

  componentWillUnmount() {
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("REVIEW_CHANNEL");
      listenChannel.removeEventListener("message", this.handleQuetureData);
    }
  }

  handleQuetureData = (res) => {
    const type = res.data && res.data.type;
    const value = res.data && res.data.value;
    const setQueture = this.setQuetureData;

    console.log(" type: ", type);
    console.log(" value: ", value);
    console.log(" value: ", typeof value);
    if (!type) {
      return null;
    }

    switch (type) {
      case "setQueture":
        setQueture(value);
        break;
      case "delQueture":
        setQueture(null);
        break;

      default:
        break;
    }
  };

  setQuetureData = (data) => {
    console.log("quetureData", data);
    this.setState(
      {
        quetureData: null,
      },
      () => {
        if (data) {
          this.setState({
            quetureData: data,
          });
        }
      }
    );
  };

  render() {
    const { user, type, onAttendance, viewType, isKids } = this.props;
    const { quetureData, quetureContent } = this.state;

    console.log("user", user);
    console.log("Viewtype: ", viewType);
    console.log("quetureData:", quetureData);
    console.log("isKids: ", isKids);

    if (viewType === "preview" && quetureData) {
      return (
        <div>
          {quetureData && (
            <QueturePlayer
              apiHost={getHost()}
              userId={user.userId}
              content={{
                data: quetureData,
                type: "main",
              }}
              // seq={seq}
              resolution="auto"
              autoplay
              style={{ outline: "none" }}
              noControll={true}
            />
          )}
        </div>
      );
    }

    return (
      <div className="classpause-layout">
        {isKids ? (
          <div
            style={{
              background: `url(${kidsBg}) no-repeat center`,
              backgroundAttachment: "fixed",
              backgroundSize: "auto 100%",
              width: "100%",
              height: "100vh",
              position: "absolute",
              left: 0,
              top: 0
            }}
          ></div>
        ) : (
          <img src={Logo} alt="" style={{ maxWidth: "223px" }} />
        )}
        <button className="none" onClick={() => onAttendance(user.studentId)}>
          출석 체크
        </button>
        <Link to="/logout" className="none">
          logout
        </Link>
      </div>
    );
  }
}

export default StandBy;
