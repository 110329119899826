import React, { Component } from "react";
import { Modal } from "antd";
import DaumPostcode from "react-daum-postcode";

class SearchAddressPopup extends Component {
  handleAddress = data => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    this.props.onDone(fullAddress, data.zonecode);
  };

  render() {
    return (
      <Modal {...this.props}>
        <DaumPostcode onComplete={this.handleAddress} />
      </Modal>
    );
  }
}

export default SearchAddressPopup;
