import React, { Component } from "react";
import { formatText } from "../utils/Formatter";
import classNames from "classnames";

class PairedAnswer extends Component {
  render() {
    const {
      data,
      index,
      id,
      height,
      start,
      drawing,
      position,
      answersMap,
      solvedData,
      showReallyTrue,
      onLoadImage,
      onMobilePairing,
      isMobileOnly,
      mobileSelectLeft
    } = this.props;
    let selected = false;
    let correct = null;
    let realRight = null;
    if (position === "left") {
      selected = answersMap[index].answer;
      correct = answersMap[index].correct;
    } else if (position === "right") {
      selected = answersMap.find((value, i) => {
        return value.answer && value.answer.key === data.key;
      });
      correct = selected && selected.correct;
      realRight = answersMap.find((value, i) => {
        return value.realRight && value.realRight === data.key;
      });
      if (showReallyTrue) {
        // console.log('position: ', position);
        // console.log('realright: ', realRight);
      }
    }
    // console.log('selected: ', selected);
    // console.log('isMobileOnly: ', isMobileOnly);
    let heightStyle = null;
    if (!isMobileOnly) {
      heightStyle = { height: height };
    }
    return (
      <div
        style={heightStyle}
        className={classNames("box", {
          "l-box": position === "left",
          "r-box": position === "right",
          none: selected && isMobileOnly,
          "is-can":
            (isMobileOnly &&
              position === "left" &&
              mobileSelectLeft !== index) ||
            (isMobileOnly &&
              position === "right" &&
              mobileSelectLeft !== null) ||
            (start && start.pos && position !== start.pos && drawing),
          "is-cannot":
            (isMobileOnly &&
              position === "left" &&
              mobileSelectLeft !== null &&
              mobileSelectLeft !== index) ||
            (isMobileOnly &&
              position === "right" &&
              mobileSelectLeft === null) ||
            (start &&
              start.pos &&
              position === start.pos &&
              start.index !== index &&
              drawing),
          "is-chk":
            (!showReallyTrue && selected) ||
            (isMobileOnly && position === "left" && mobileSelectLeft === index),
          "is-true":
            !isMobileOnly &&
            !showReallyTrue &&
            solvedData &&
            solvedData.solved &&
            selected &&
            correct === "o",
          "is-false":
            !isMobileOnly &&
            !showReallyTrue &&
            solvedData &&
            solvedData.solved &&
            selected &&
            correct === "x",
          "is-reallytrue": !isMobileOnly && showReallyTrue && realRight,
          "none-event": solvedData && solvedData.solved
        })}
        onClick={() => {
          position && onMobilePairing(position, index);
        }}
      >
        {data && data.image && data.image != null ? (
          <img
            src={data.image && data.image.signedUrl}
            onLoad={onLoadImage}
            className="image"
            alt=""
          />
        ) : null}
        <p>{formatText(data && data.answer, id)}</p>
      </div>
    );
  }
}

export default PairedAnswer;
