import React, { useState, useEffect } from "react";
import {
  osName,
  osVersion,
  browserName,
  browserVersion,
  isIE,
  isEdge,
  isChrome,
  isAndroid,
  isIOS,
  isSafari,
  isMobileSafari
} from "react-device-detect";
import Popup from "components/Popup/Popup";
import classNames from "classnames";

class BrowserAlert extends React.Component {
  state = {
    open: false,
    title: null,
    message: null,
    buttons: []
  };

  componentWillMount() {
    let title = null;
    let message = null;
    let extButton = null;

    if (isIOS) {
      if (
        (!isSafari && !isMobileSafari) ||
        (window.webkit &&
          window.webkit.messageHandlers &&
          !window.webkit.messageHandlers.onLogin)
      ) {
        title = "사파리가 아닙니다! 다시 접속해 주세요!";
        message = (
          <p>
            {"현재 사용중이신 브라우저에서는 영상을 재생할 수 없습니다."}
            <br />
            {
              "아이폰과 아이패드에서는 사파리 브라우저를 이용하시면 깨봉을 원활하게 학습하실 수 있습니다."
            }
          </p>
        );
      }
    } else if (isAndroid) {
      if (!isChrome && browserName !== "Chrome WebView") {
        title = "크롬이 아닙니다! 다시 접속해 주세요!";
        message = (
          <p>
            {"현재 사용중이신 브라우저에서는 영상을 재생할 수 없습니다."}
            <br />
            {"크롬 브라우저를 이용하시면 깨봉을 원활하게 학습하실 수 있습니다."}
          </p>
        );
      }
    } else if (osName === "Windows") {
      if (!isChrome) {
        const winVer = parseInt(osVersion);
        if (winVer <= 8) {
          title = "크롬이 아닙니다! 다시 접속해 주세요!";
          message = (
            <p>
              {"현재 사용중이신 브라우저에서는 영상을 재생할 수 없습니다."}
              <br />
              {
                "크롬 브라우저를 이용하시면 깨봉을 원활하게 학습하실 수 있습니다."
              }
            </p>
          );
        } else if (isIE || isEdge) {
          title = "크롬이 아닙니다! 다시 접속해 주세요!";
          message = (
            <p>
              {"현재 사용중이신 브라우저에서는 영상을 재생할 수 없습니다."}
              <br />
              {
                "크롬 브라우저를 이용하시면 깨봉을 원활하게 학습하실 수 있습니다."
              }
            </p>
          );
          // extButton = (
          //   <div>
          //     <button type="button" className="is-true"
          //       onClick={() => {
          //         window.open("microsoft-edge:" + window.location);
          //       }}>{'Edge로 열기'}
          //     </button>
          //   </div>
          // );
        }
      }
    } else if (!isChrome && !isSafari) {
      title = "크롬이 아닙니다! 다시 접속해 주세요!";
      message = (
        <p>
          {"현재 사용중이신 브라우저에서는 영상을 재생할 수 없습니다."}
          <br />
          {"크롬 브라우저를 이용하시면 깨봉을 원활하게 학습하실 수 있습니다."}
        </p>
      );
    }

    if (message != null) {
      this.setState({
        title: title,
        message: message,
        open: true,
        buttons: this.getButtons(extButton)
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { title, message } = nextProps;
    if (message != null && message !== this.state.message) {
      this.setState({
        title: title,
        message: message,
        open: true,
        buttons: this.getButtons()
      });
    }
  }

  setOpen = v => {
    this.setState({
      open: v
    });
  };

  close = () => {
    this.setState({
      open: false
    });
  };

  getButtons = ext => {
    const buttons = [];
    if (ext) {
      buttons.push(ext);
    }

    buttons.push(
      <div>
        <button
          type="button"
          className="classmode-btn"
          onClick={() => this.close()}
        >
          {"확인"}
        </button>
      </div>
    );

    return buttons;
  };

  render() {
    const { title, message, open, buttons } = this.state;

    if (message != null) {
      return (
        <Popup
          // type="browserAlert"
          className="browserAlert alert"
          isOpen={open}
        >
          <div className="popup-content-wrap">
            <div
              className={classNames("img", {
                "is-safari": isIOS
              })}
            ></div>
            <div className="popup-title">
              <strong>{title}</strong>
            </div>
            <div className="popup-closebtn" onClick={() => this.close()}></div>
            {message}
            {!isIOS && (
              <a
                href="https://www.google.com/intl/ko/chrome/"
                target="_blank"
                className="chrome-download u-color-false"
              >
                크롬 다운로드
              </a>
            )}
          </div>
          <div className="btn-wrap">{buttons}</div>
        </Popup>
      );
    } else {
      return null;
    }
  }
}

export default BrowserAlert;
