import axios from "axios";
import muxjs from "mux.js";
import querystring from "querystring";
import React, { Component } from "react";
import { isIOS, isIE } from "react-device-detect";
import { isIPadSafari } from "../../../utils/utils";
import screenfull from "screenfull";
import {
  decodeBase64,
  extractContentId,
  requestDRM,
} from "../utils/PallyconUtils";
import shaka from "shaka-player";

const licenseUrl = process.env.REACT_APP_PALLYCON_LICENSE_URL;
const fpsCertUrl =
  "https://license.pallycon.com/ri/fpsKeyManager.do?siteId=" +
  process.env.REACT_APP_PALLYCON_SITE_ID;
const webUrl = process.env.REACT_APP_DOMAIN || "localhost";

class ShakaPlayer extends Component {
  player = null;
  resolution = "auto";
  timestamp = new Date().getTime();
  origin = webUrl;
  drm = null;

  segElapsedMap = {
    video: [],
    audio: [],
  };

  constructor(props) {
    super(props);
    window.muxjs = muxjs;

    if (webUrl.includes("://")) {
      this.origin = webUrl.substring(webUrl.indexOf("://") + 3);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { content: currentContent } = this.props;
    const { content: nextContent } = nextProps;

    if (
      currentContent == null ||
      currentContent.quetureId !== nextContent.quetureId
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (this.player != null) {
      this.player.destroy();
    }
  }

  changeResolution = (track) => {
    const { onResolutionChange } = this.props;

    if (track == null || track === "auto" || track === "") {
      this.player.configure({
        abr: {
          enabled: true,
        },
      });
      return;
    }

    this.player.configure({
      abr: {
        enabled: false,
      },
    });

    const nextTrack = this.player
      .getVariantTracks()
      .filter((t) => t.id === parseInt(track))[0];

    this.player.selectVariantTrack(nextTrack, true);

    if (onResolutionChange) {
      onResolutionChange(nextTrack.id);
    }
  };

  onLoaded = () => {
    const { onLoaded, initPos, poster } = this.props;
    const video = this.videoRef;

    const player = this.player;

    console.log("player initialized", shaka.Player.version);
    const resolutions = {};

    console.log("poster: ", poster);
    video.setAttribute("controlsList", "nodownload");

    player.getVariantTracks().forEach((track) => {
      const height = track.height;

      if (resolutions[height] == null) {
        resolutions[height] = {
          id: track.id,
          name: height + "p",
          bandwidth: track.bandwidth,
          languages: [],
        };
      } else {
        resolutions[height].languages.push({
          language: track.language,
          id: track.id,
        });
      }
    });

    if (this.props.autoplay === true) {
      console.log("autoplay");
      this.play();
    }

    if (initPos != null && !isNaN(initPos) && initPos > 0) {
      const { onStateChanged } = this.props;
      const video = this.videoRef;

      if (isIOS || isIPadSafari()) {
        function _loaded() {
          video.removeEventListener("loadeddata", _loaded);
          video.currentTime = initPos;
        }
        video.addEventListener("loadeddata", _loaded);
      } else {
        video.currentTime = initPos;
      }
      const percent =
        video.duration > 0
          ? (Math.round(initPos) / Math.round(video.duration)) * 100
          : 0;
      // video.setAttribute("poster", poster);
      onStateChanged({
        name: "progress",
        value: {
          progress: percent,
          time: initPos,
        },
      });
    }

    if (onLoaded) {
      console.log("callback");
      onLoaded({
        resolutions: resolutions,
        duration: video.duration,
        time: 0,
      });
    }
  };

  initPlayer = () => {
    const { userId, content, src } = this.props;

    if (src != null) {
      this.load(src);
    } else if (content) {
      console.log(content);
      if (content.url != null) {
        this.load(content.url);
      } else if (content.drm != null && content.drm !== "none") {
        this.drm = content.drm;
        requestDRM(userId, content, this.load);
      }
    }
  };

  load = (url, customData, drm) => {
    const { onDrmFail, onStateChanged, onError } = this.props;
    const video = this.videoRef;

    console.log("loading", url, drm);

    var player = new shaka.Player(video);

    player.configure({
      streaming: {
        bufferBehind: 30,
        bufferingGoal: 15,
        rebufferingGoal: 0,
      },
    });

    player.addEventListener("error", (e) => {
      console.warn("Shaka error", e.detail.code, e.detail.data);
      if (isIE) {
        console.log(e);
      }
      const code = e.detail.code;

      if (code >= 6000 && code < 7000 && onDrmFail) {
        onDrmFail(code, e.detail.data);
      } else if (code === 3016) {
        //IE err
        onDrmFail(code, e.detail.data);
      } else {
        if (onError) {
          onError(e);
        }
      }
    });
    if (onStateChanged) {
      player.addEventListener("buffering", (e) => {
        console.log(e);
        onStateChanged({
          name: "buffering",
          value: e.buffering,
        });
      });
    }

    this.player = player;

    player.getNetworkingEngine().registerRequestFilter((type, request) => {
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        console.log("license request", drm);
        request.headers["pallycon-customdata-v2"] = customData;
        if (drm === "Fairplay" || drm === "FairPlay") {
          const originalPayload = new Uint8Array(request.body);
          const base64Payload =
            shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);

          const body = querystring.stringify({
            spc: base64Payload,
            assetId: encodeURIComponent(this.fpsContentId),
          });

          console.log("spc", base64Payload);
          console.log("assetId", encodeURIComponent(this.fpsContentId));
          console.log("license request body", body);

          request.headers["Content-Type"] = "application/x-www-form-urlencoded";
          request.body = body;
          // request.body = shaka.util.StringUtils.toUTF8(encodeURIComponent(body));

          // console.log(body);
        }
      } else if (type === shaka.net.NetworkingEngine.RequestType.SEGMENT) {
        // console.log(request);
        if (request.uris && request.uris.some((u) => u.includes("quebon.tv"))) {
          // request.headers["Authorization"] = "Bearer " + getToken();
          request.headers["x-qb-timestamp"] = this.timestamp;
          request.headers["x-qb-userId"] = this.props.userId;
        }

        //request.headers["Origin"] = this.origin;
      } else {
        console.log("other request", type, request);
        if (request.uris && request.uris.some((u) => u.includes("quebon.tv"))) {
          // request.headers["Authorization"] = "Bearer " + getToken();
          request.headers["x-qb-timestamp"] = this.timestamp;
          request.headers["x-qb-userId"] = this.props.userId;
        }
      }
    });

    player.getNetworkingEngine().registerResponseFilter((type, response) => {
      if (type === shaka.net.NetworkingEngine.RequestType.SEGMENT) {
        // console.log('response: ', response);
        if (response.uri) {
          const uriIdx = response.uri.lastIndexOf("/");
          const name = response.uri.substring(uriIdx + 1);
          const type = response.uri.includes("/video/") ? "video" : "audio";

          this.segElapsedMap[type].push({
            name: name,
            elapsed: response.timeMs,
            length: parseInt(response.headers["content-length"]),
          });
        }

        return;
      } else if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        if (drm === "Fairplay" || drm === "FairPlay") {
          let responseText = shaka.util.StringUtils.fromUTF8(response.data);
          // Trim whitespace.
          responseText = responseText.trim();

          // Look for <ckc> wrapper and remove it.
          if (
            responseText.substr(0, 5) === "<ckc>" &&
            responseText.substr(-6) === "</ckc>"
          ) {
            responseText = responseText.slice(5, -6);
          }

          // Decode the base64-encoded data into the format the browser expects.
          response.data =
            shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
        }
      }
    });

    if (customData != null) {
      player.configure({
        drm: {
          servers: {
            "com.widevine.alpha": licenseUrl,
            "com.microsoft.playready": licenseUrl,
            "com.apple.fps.1_0": licenseUrl,
          },
          // advanced: {
          //   "com.widevine.alpha": {
          //     'videoRobustness': 'SW_SECURE_CRYPTO',
          //     'audioRobustness': 'SW_SECURE_CRYPTO'
          //   }
          // }
        },
      });
    }

    const _load = () => {
      console.log("loading " + url, player);
      return player
        .load(url)
        .then((r) => {
          console.log("onLoaded");
          // this.onLoaded();
          console.log("completed");
          // if (isIE && !this.drm) {
          //   video.currentTime = 3;
          // }
          return r;
        })
        .catch((e) => {
          console.warn("Shaka error while loading", e.code, e.data);
          console.log(e);
          const code = e.code;
          if (code >= 6000 && code < 7000 && onDrmFail) {
            onDrmFail(code);
          } else if (code === 3016) {
            //IE err
            onDrmFail(code);
          }
        });
    };

    if (drm === "Fairplay") {
      axios
        .get(
          fpsCertUrl,
          {
            responseType: "text",
          },
          {
            headers: {
              "x-qb-client-id": null,
            },
          }
        )
        .then((resp) => {
          const cert = decodeBase64(resp.data);
          console.log("Fairplay cert loaded");

          player.configure({
            drm: {
              advanced: {
                "com.apple.fps.1_0": {
                  serverCertificate: cert,
                },
              },
              initDataTransform: (initData, initDataType) => {
                console.log("initDataTransform", initDataType);

                const skdUri =
                  shaka.util.StringUtils.fromBytesAutoDetect(initData);
                console.log("skdUri", skdUri);

                const contentId = skdUri.substring(
                  skdUri.indexOf("skd://") + 6
                );
                // const contentId = extractContentId(initData);

                console.log(contentId);

                if (contentId.match(/[a-zA-Z0-9+/=]+/)) {
                  this.fpsContentId = contentId;
                } else {
                  console.log("ignore invalid contentId", initData);
                  return;
                }

                const cert = player.drmInfo().serverCertificate;

                // console.log('fps init', initData, this.fpsContentId, player.drmInfo(), cert);

                return shaka.util.FairPlayUtils.initDataTransform(
                  initData,
                  this.fpsContentId,
                  cert
                );
              },
            },
          });

          // console.log('fairplay loaded');
          _load();
        });
    } else {
      _load();
    }

    player.addEventListener("trackschanged", (t) => {
      this.handleTrackChanged(
        t.target.getVariantTracks().filter((t) => t.active === true)[0].id
      );
    });

    video.addEventListener("playing", () => {
      const { onStateChanged } = this.props;
      onStateChanged &&
        onStateChanged({
          name: "v_play",
          value: true,
        });
    });

    video.addEventListener("pause", () => {
      const { onStateChanged } = this.props;
      onStateChanged &&
        onStateChanged({
          name: "v_play",
          value: false,
        });
    });

    video.addEventListener("loadedmetadata", (e) => {
      console.log("metadata loaded!");
      this.onLoaded();
    });
  };

  handleTrackChanged = (track) => {
    const { onResolutionChange } = this.props;
    if (onResolutionChange) {
      onResolutionChange(track);
    }
  };

  componentDidMount() {
    const { content, src } = this.props;

    if (content == null && src == null) {
      return;
    }

    shaka.polyfill.installAll();
    this.initPlayer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      shaka.polyfill.installAll();
      this.initPlayer();
    }
  }

  play = () => {
    const { onStateChanged } = this.props;
    const video = this.videoRef;
    const promise = video.play();
    if (onStateChanged) {
      if (promise != null) {
        promise
          .then(() => {
            onStateChanged({
              name: "play",
              value: true,
            });
          })
          .catch((e) => {
            console.warn(e);
          });
      } else {
        onStateChanged({
          name: "play",
          value: true,
        });
      }
    }
  };

  pause() {
    const { onStateChanged } = this.props;
    const video = this.videoRef;
    video.pause();
    if (onStateChanged) {
      onStateChanged({
        name: "play",
        value: false,
      });
    }
  }

  seek(percent) {
    const video = this.videoRef;
    if (video.duration == null) {
      return;
    }
    let time = video.duration * (percent / 100.0);

    if (!isNaN(time)) {
      if (isIOS || isIPadSafari()) {
        console.log(`time=${time}, type=${typeof time}`);
        video.currentTime = time;
        this.play();
      } else {
        video.currentTime = time;
      }
    }
  }

  onProgressUpdated = (e) => {
    const { onStateChanged } = this.props;
    const video = this.videoRef;
    if (!onStateChanged) {
      return;
    }

    let percent =
      video.duration > 0 ? (video.currentTime / video.duration) * 100 : 0;
    if (video.duration - video.currentTime <= 1) {
      percent = 100;
    }

    // if (isIOS || isIPadSafari()) {
    //   console.log("progress updated, time=" + video.currentTime);
    // }
    onStateChanged({
      name: "progress",
      value: {
        progress: percent,
        time: video.currentTime,
        segElapsed: this.segElapsedMap,
      },
    });
  };

  volume(value) {
    const video = this.videoRef;
    video.volume = value;
  }

  changeSpeed(speed) {
    const video = this.videoRef;
    video.playbackRate = speed;
  }

  toggleFullscreen() {
    const video = this.videoRef;

    if (screenfull.enabled) {
      try {
        return screenfull.toggle(video).catch((e) => {
          console.log("failed to toggle fullscreen", e);
        });
      } catch (e) {
        console.log("failed to toggle fullscreen", e);
      }
    } else {
      console.log("fullscreen is not available");
    }
  }

  render() {
    const { style, content, controls, poster, muted, onClick } = this.props;

    const _poster = poster
      ? poster
      : content && content.thumbnail
      ? content.thumbnail
      : content &&
        content.data &&
        content.data.contents &&
        content.data.contents.items.thumbnail != null
      ? content.data.contents.items.thumbnail.signedUrl
      : null;
    console.log("poster: ", poster);

    return (
      <video
        id="queture-player"
        ref={(e) => (this.videoRef = e)}
        playsInline
        onClick={onClick}
        style={style}
        poster={_poster}
        onTimeUpdate={this.onProgressUpdated}
        controls={controls}
        controlsList="nodownload"
        // autoplay={this.props.autoplay}
        // muted={muted}
      />
    );
  }
}

export default ShakaPlayer;
