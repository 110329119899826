import React from "react";
import { List } from "antd";

const SimpleRankingList = props => {
  const { data, quizCount } = props;

  console.log(data);

  return (
    <div
      style={{
        position: "absolute",
        left: 8,
        top: 20,
        border: "1px solid",
        boxSizing: "border-box",
        padding: "0 4px"
      }}
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={item.studentList && item.studentList[0].name}
              description={`${item.count}/${quizCount}`}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default SimpleRankingList;
