import React from "react";
import styles from "./layout.module.scss";
import classNames from 'classnames';

const BasicLayout = ({ user, children }) => {
  console.log('뷰화면 체크 : ', children.props.route.path);
  return (
    <>
      <div className={classNames(`basic-layout ${styles.basicLayout}`, {
        "is-login-page":children.props.route.path == '/login',
        "test-input":children.props.route.path == '/test-input'
      })}>{children}</div>
    </>
  );
};

export default BasicLayout;
