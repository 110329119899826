import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as studentActions from "store/modules/student";
import * as classroomActions from "store/modules/classroom";
import {
  Skeleton,
  PageHeader,
  Typography,
  Button,
  Icon,
  Tabs,
  message,
} from "antd";
import StudentList from "../../components/StudentList";
import { orderBy, filter, values, some } from "lodash";
import { ConstantsContext } from "utils/ConstantsContext";
import AccountApis from "../../../../utils/accountApis";
import { getHost, getDefaultConfig } from "../../../../utils/APIUtils";
import UploadStudentPopup from "../../components/Popup/UploadStudentPopup";
import StudentListTab from "../../components/StudentListTab";

const pageCount = 10;
class StudentListPage extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      page: 0,
      totalPages: 10,
      sortType: "",
      searchType: "name",
      searchKeyword: "",
      showUploadStudentPopup: false,
    };
  }

  componentDidMount() {
    const { pending, user, ClassroomActions, StudentActions } = this.props;
    const { loading, page } = this.state;
    console.log("mount student list: ", user);

    if (user && user.orgId) {
      ClassroomActions.getClassroomList(user.orgId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      pending,
      user,
      ClassroomActions,
      StudentActions,
      studentList,
    } = this.props;
    if (this.props.user && this.props.user.type !== prevProps.user.type) {
      ClassroomActions.getClassroomList(user.orgId);
    }
  }

  render() {
    const {
      pending,
      user,
      userPermission,
      // studentList,
      classroomList,
      ClassroomActions,
      StudentActions,
    } = this.props;
    const { loading, studentList } = this.state;
    console.log("user: ", user);
    console.log("list searchResult: ", this.props.location.searchResult);
    return (
      <Skeleton loading={pending || loading} active>
        <PageHeader
          title={
            <Typography.Title level={2} style={{ marginBottom: 0 }}>
              학생관리
            </Typography.Title>
          }
          extra={
            (userPermission === "master" ||
              (userPermission && userPermission.studentCreateDelete)) && [
              <Button
                key="1"
                type="primary"
                onClick={() => this.props.history.push("/admin/student/add")}
              >
                학생 등록하기
                <Icon type="plus-circle" />
              </Button>,
              <span key="2">
                {this.context.type === "school" && (
                  <>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({
                          showUploadStudentPopup: true,
                        });
                      }}
                    >
                      학생 업로드
                      <Icon type="upload" />
                    </Button>
                    {this.state.showUploadStudentPopup && (
                      <UploadStudentPopup
                        user={user}
                        handleClose={(isUpdated) => {
                          // if (isUpdated) {
                          //   StudentActions.getStudentList(user.orgId);
                          // }
                          this.setState({
                            showUploadStudentPopup: false,
                          });
                        }}
                      />
                    )}
                  </>
                )}
              </span>,
            ]
          }
        />
        <div className="admin-page-content">
          <Tabs
            defaultActiveKey={
              (this.props.location.searchResult &&
               this.props.location.searchResult.active === false)
                ? "exit" :
              (this.props.location.searchResult &&
               this.props.location.searchResult.currentClassroom === false)
                ? "nonCurrentClassroom" : "currentClassroom"
            }
          >
            <Tabs.TabPane
              tab={`활성클래스 ${this.context.registeredStudent} 목록 보기`}
              key="currentClassroom"
            >
              {user && classroomList && (
                <StudentListTab
                  title={`전체 활성클래스 ${this.context.registeredStudent} 목록`}
                  user={user}
                  active={true}
                  currentClassroom={true}
                  hasStudentEditPermission={
                    userPermission === "master" ||
                    (userPermission && userPermission.studentUpdate)
                  }
                  classroomList={classroomList}
                  showUploadStudentPopup={this.state.showUploadStudentPopup}
                  searchResult={this.props.location.searchResult}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={`비활성클래스 ${this.context.registeredStudent} 목록 보기`}
              key="nonCurrentClassroom"
            >
              {user && classroomList && (
                <StudentListTab
                  title={`전체 비활성클래스 ${this.context.registeredStudent} 목록`}
                  user={user}
                  active={true}
                  currentClassroom={false}
                  hasStudentEditPermission={
                    userPermission === "master" ||
                    (userPermission && userPermission.studentUpdate)
                  }
                  classroomList={classroomList}
                  showUploadStudentPopup={this.state.showUploadStudentPopup}
                  searchResult={this.props.location.searchResult}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={`로그인 제한 학생 목록 보기`}
              key="restrict"
            >
              {user && classroomList && (
                <StudentListTab
                  title={`전체 로그인 제한 학생 목록`}
                  user={user}
                  restrict={true}
                  hasStudentEditPermission={
                    userPermission === "master" ||
                    (userPermission && userPermission.studentUpdate)
                  }
                  classroomList={classroomList}
                  showUploadStudentPopup={this.state.showUploadStudentPopup}
                  searchResult={this.props.location.searchResult}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={`${this.context.dropoutStudent} 목록 보기`}
              key="exit"
            >
              {user && classroomList && (
                <StudentListTab
                  title={`전체 ${this.context.dropoutStudent} 목록`}
                  user={user}
                  active={false}
                  hasStudentEditPermission={
                    userPermission === "master" ||
                    (userPermission && userPermission.studentUpdate)
                  }
                  classroomList={classroomList}
                  showUploadStudentPopup={this.state.showUploadStudentPopup}
                  searchResult={this.props.location.searchResult}
                />
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Skeleton>
    );
  }
}

export default connect(
  ({ auth, classroom, student }) => ({
    pending: auth.pending || classroom.pending || student.pending,
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    userPermission: auth.user.type === "org" ? "master" : auth.user.permission,
    classroomList: classroom.classroomList.sort((a, b) => a.name.localeCompare(b.name)),
    studentList: student.studentList,
  }),
  (dispatch) => ({
    StudentActions: bindActionCreators(studentActions, dispatch),
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
  })
)(StudentListPage);
