import AudioPlayer from 'components/Audio/AudioPlayer';
import BodyMovin from 'components/BodyMovin/BodyMovin';
import React, { useState, useEffect } from 'react'
import charactorAnimation from './anim-character.json'
import speechAnimation from "./anim-speech.json"
import sttBg from "../sounds/bgm.mp3"
import sttCharacter from "../sounds/character_sound_x3.mp3"
import sttSpeech from "../sounds/speech_sound.mp3"


const CharactorAnimation = () => {
  const [isPlay, setPlay] = useState(false);

  const charactorOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: charactorAnimation
  }

  const speechOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: speechAnimation
  }
  
  useEffect(() => {
    const timer = setTimeout(() => { 
      setPlay(true) 
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // 1초 정도 위에
  return (
    <>
      {
        isPlay &&
        <div className="stt-charactor-anim-wrap">
          <BodyMovin className="stt-charactor-anim" options={charactorOptions} />
          <BodyMovin className="stt-speech-anim" options={speechOptions} />
          <AudioPlayer src={sttBg} autoPlay={true} loop={false} />
          <AudioPlayer src={sttCharacter} autoPlay={true} />
          <AudioPlayer src={sttSpeech} autoPlay={true} />
        </div>
      }
    </>
  )
}

export default CharactorAnimation;
