import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from "store/modules/auth";
import * as hometrainingActions from "store/modules/hometraining";
import MyProfile from "./MyProfile";
import { Skeleton } from "antd";
import HometrainingApis from "utils/hometrainingApis";

class UserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    const { userInfo } = this.props;

    HometrainingApis.currentLecture(userInfo)
      .then((isStart) => {
        if (isStart) {
          this.props.history.push(
            `/classroom/${userInfo.classroom.classroomId}`
          );
        } else {
          this.props.HometrainingActions.getLectures();
        }
      })
      .catch((e) => {
        this.props.HometrainingActions.getLectures();
      });
  }

  render() {
    const { pending, userInfo, lectures, AuthActions } = this.props;

    console.log("userInfo: ", userInfo);
    console.log("userInfo: ", lectures);

    return (
      <Skeleton loading={pending} active>
        {userInfo && (
          <MyProfile
            lectures={lectures}
            userInfo={userInfo}
            onUpdateUserInfo={() =>
              AuthActions.checkCurrentUser(
                userInfo.userId,
                userInfo.type,
                userInfo.orgId
              )
            }
          />
        )}
      </Skeleton>
    );
  }
}
export default connect(
  ({ auth, hometraining }) => ({
    pending: auth.pending || hometraining.pending,
    userInfo: auth.user,
    type: auth.user.type,
    callback: auth.callback,
    error: auth.error,
    lectures: hometraining.lectures,
  }),
  (dispatch) => ({
    AuthActions: bindActionCreators(authActions, dispatch),
    HometrainingActions: bindActionCreators(hometrainingActions, dispatch),
  })
)(UserInfo);
