import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { formatText } from "./utils/Formatter";
import { Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { actionLog } from "utils/LogUtils";

class DragTextQuiz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      answers: null,
      quizId: null,
      draggables: null,
    };
  }

  init = data => {
    const { quizId, quiz, timestamp } = data;
    const { problem, choices } = quiz;

    const { stepQuizAnswers } = this.props;

    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      const stepAnswersMap = [];
      stepQuizAnswers.map(a => {
        if (a) {
          const answer = choices.find(c => c.key === a);
          stepAnswersMap.push(answer);
        }
      });
      this.setState({
        quizId: quizId,
        answers: stepAnswersMap
      }, () => {
        this.updateProblem(this.props);
      });
    } else {
      let answerCount = 0;
      const reg = /\{\{([^\{^}]*)}}/gm;
      let r;
      while ((r = reg.exec(problem))) {
        const val = r[1].trim();
        if (val.length === 0) {
          answerCount++;
        }
      }
      this.setState({
        quizId: quizId,
        answers: new Array(answerCount).fill(null),
        draggables: null
      }, () => {
        actionLog("quiz", "init", {
          quizId: quizId,
          type: quiz.type,
          answers: this.state.answers
        });
        this.updateProblem(this.props);
      });
    }
  };

  updateProblem = (props) => {
    const { data } = props;
    const { quiz } = data;
    const { choices } = quiz;

    let inlineChoices = [];

    const afterChoices = choices.filter(
      c => !inlineChoices.some(i => i === c.key)
    );

    this.setState({
      draggables: (
        <Droppable key={'dropable-from'} droppableId={"droppable-from"} direction={'horizontal'}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{
                display: "flex",
                flexWrap: 'wrap',
                alignItems: 'center'
              }}
            >
              {
                afterChoices.map((c, i) => (
                  <Draggable draggableId={c.key} key={c.key} index={i}>
                    {
                      (p, s) => (
                        <span ref={p.innerRef}
                          {...p.draggableProps}
                          {...p.dragHandleProps}
                          key={c.key}
                          className={classNames("draggable btn-drag", {
                            // "is-dragging": s.isDragging
                            "pick": s.isDragging
                          })}>
                          <span>{formatText(c.answer)}</span>
                        </span>
                      )
                    }
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )
    })
  }

  componentDidMount() {
    const { data } = this.props;

    if (data != null) {
      this.init(data);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showReallyTrue !== this.props.showReallyTrue) {
      this.updateProblem(this.props);
    }
    if (this.props.solved && !prevProps.solved) {
      this.updateProblem(this.props)
    }
    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      this.init(this.props.data)
    }
  }

  componentWillUnmount() {
    this.setState({
      answers: null,
      quizId: null,
      draggables: null,
    })
  }

  render() {
    const { solved, forWorkbook } = this.props;

    return (
      <div className={classNames("quiz-content__answer t-answer-ts", {
        "none-event": solved
      })}>
        <div className="answerArea init-user-select1">
          <p>{forWorkbook ? '보기' : '알맞은 답을 끌어다 빈칸에 놓아주세요.'}</p>
          {this.state.draggables}
        </div>
      </div>
    );
  }
}

DragTextQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
};

export default DragTextQuiz;
