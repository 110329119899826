import * as React from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";

import { isAndroid, isIOS } from "react-device-detect";
import { isIPadSafari } from "../../utils/utils";

const backend =
  isAndroid || isIOS || isIPadSafari() ? TouchBackend : HTML5Backend;

const withDragDropContext = Component => {
  return props => (
    <DndProvider backend={backend}>
      <Component {...props} />
    </DndProvider>
  );
};

export default withDragDropContext;
