import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";
import HometrainingApis from "../../utils/hometrainingApis";

const LECTURES = "hometraining/LECTURES";
const LECTURES_LOADING = "hometraining/LECTURES_LOADING";
const LECTURES_SUCCESS = "hometraining/LECTURES_SUCCESS";
const LECTURES_ERROR = "hometraining/LECTURES_ERROR";

export const getLectures = () => ({
  type: LECTURES,
  payload: HometrainingApis.lectures()
});

const initialState = {
  pending: false,
  lectures: null
};

export default handleActions(
  {
    [LECTURES_LOADING]: state => {
      return {
        ...state,
        pending: true
      };
    },
    [LECTURES_SUCCESS]: (state, { payload }) => {
      console.log("lecture payload: ", payload);

      return {
        ...state,
        pending: false,
        lectures: payload
      };
    },
    [LECTURES_ERROR]: (state, { payload }) => {
      return {
        ...state,
        pending: false
      };
    }
  },
  initialState
);
