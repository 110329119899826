import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from "store/modules/auth";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import styles from "./styles.module.scss";
import Axios from "../../../../utils/request";
import { Redirect, withRouter } from "react-router-dom";
import ClassroomApis from "../../../../utils/classroomApis";

class LoginForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);

        this.props.AuthActions.login({
          account: values.username,
          password: values.password
        })
          .then(res => {
            console.log("logged in!");
            this.props.history.push("/");
          })
          .catch(err => {
            console.log("login errro: ", err);
          });
      }
    });
  };

  render() {
    const { type } = this.props;

    const { getFieldDecorator } = this.props.form;

    if (type && type !== "none") {
      console.log("type: ", type);
      if (type === "academy_student") {
        return <Redirect to="/" />;
      } else {
        return <Redirect to="/admin/class" />;
      }
    }

    return (
      <Form onSubmit={this.handleSubmit} className={styles["login-form"]}>
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true
          })(<Checkbox>Remember me</Checkbox>)}
          <Button
            type="primary"
            htmlType="submit"
            className={styles["login-form-button"]}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create({ name: "normal_login" })(LoginForm);

export default withRouter(
  connect(
    ({ auth }) => ({
      pending: auth.pending,
      type: auth.user.type,
      callback: auth.callback,
      error: auth.error
    }),
    dispatch => ({
      AuthActions: bindActionCreators(authActions, dispatch)
    })
  )(WrappedLoginForm)
);
