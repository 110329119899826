import React, { useState, useEffect } from 'react'
// import ReactBodymovin from 'react-bodymovin'
import animationteam from './team.json'
import BodyMovin from '../BodyMovin.js';
import './_animation.scss';


const TeamAni = () => {
  const [isPlay, setPlay] = useState(false);

  const bodymovinOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: animationteam
  }

  useEffect(() => {
    const timer = setTimeout(() => { setPlay(true) }, 500);
    return () => clearTimeout(timer);
  }, []);

  // 1초 정도 위에
  return (
    <>
    <div className="select-team">
      <BodyMovin className="character" options={bodymovinOptions} />
    </div>
      {
        isPlay &&
        <>
        </>
      }
    </>
  )
}

export default TeamAni;
