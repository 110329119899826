import React, { Component } from "react";
import QueturePlayer from "../../../components/QueturePlayer/QueturePlayer";

export default class TestQueturePage extends Component {
  render() {
    return (
      <div style={{ maxWidth: 600 }}>
        <QueturePlayer
          content={{
            url:
              "https://quebon-static.s3.ap-northeast-2.amazonaws.com/brand/expr_1.mp4",
            type: "main",
            thumbnail:
              "https://quebon-static.s3.ap-northeast-2.amazonaws.com/brand/expr_1.jpg"
          }}
        />
      </div>
    );
  }
}
