import React from 'react';

const GuideHelper = () => {
  return (
    <div className="popup-guide">
      <div className="popup-content">
        <div className="popup-closebtn"></div>
        <p>이번 수업에서는 수 세기와 묶기의 개념을 유의해서 가이드 해주세요. <b>십묶음의 개수 세기</b>가 중요합니다.</p>
      </div>
    </div>
  );
};

export default GuideHelper;