import React, { useEffect, useRef } from "react";
import "./index.scss";
//import ReportCharacter from "components/Character/ReportCharacter";
// import _ReportChart from "components/Chart/ReportChart";
//import none_photo from "./img/none_photo.png";
import classNames from "classnames";
import ReportQuetureList from "./ReportQuetureList";
import {
  Icon,
  Input,
  Button,
  Checkbox,
  DatePicker,
  Popconfirm,
  Tooltip,
} from "antd";
import moment from "moment";

const ReportForm = ({
  isExamResultEdit,
  isCommentEdit,
  isConsultEdit,
  studentData,
  reportData,
  examResult,
  examCorrect,
  examPartial,
  examSolved,
  examAbsent,
  examExecDate,
  examTime,
  comment,
  commentTime,
  reportLogId,
  reportLogTime,
  consult,
  consultTime,
  onExamResultInput,
  onExamExecDateInput,
  onExamAbsentInput,
  onCommentInput,
  onConsultInput,
  handleExamResultEdit,
  handleExamResultCancel,
  handleCommentEdit,
  handleCommentCancel,
  handleConsultEdit,
  handleConsultCancel,
  handlePublish,
}) => {
  const inputExamResultRef = useRef();
  const inputCommentRef = useRef();
  const inputConsultRef = useRef();
  useEffect(() => {
    if (isExamResultEdit) {
      scrollToMyExamResultRef();
    }
    if (isCommentEdit) {
      scrollToMyCommentRef();
    }
    if (isConsultEdit) {
      scrollToMyConsultRef();
    }
    return () => {};
  }, [isExamResultEdit, isCommentEdit, isConsultEdit]);

  const scrollToMyExamResultRef = () => {
    inputExamResultRef.current.scrollIntoView(true); //{ block: "end", behavior: "smooth" });
    inputExamResultRef.current.focus();
  };

  const scrollToMyCommentRef = () => {
    //inputCommentRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    inputCommentRef.current.focus();
  };

  const scrollToMyConsultRef = () => {
    //inputConsultRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    inputConsultRef.current.focus();
  };

  let titleRange = "";
  if (reportData && reportData.lectures) {
    if (reportData.lectures.length > 1) {
      titleRange =
        String(reportData.lectures[0].title)
          .replace("회차", "")
          .replace("강", "") +
        "~" +
        reportData.lectures[reportData.lectures.length - 1].title;
    } else {
      titleRange = reportData.lectures[0].title;
    }
  }

  let examPercent =
    examSolved > 0 && examCorrect != null && examPartial != null
      ? Math.round((100 * examCorrect + 50 * examPartial) / examSolved)
      : null;

  examExecDate =
    examExecDate != null && examExecDate != 0
      ? examExecDate
      : new Date(
          reportData.lectures[reportData.lectures.length - 1].date
        ).getTime() + 604800000 /* 1week */;

  let parentMobile = String(
    studentData && studentData.parentMobile ? studentData.parentMobile : ""
  );
  if (!parentMobile.includes("-")) {
    if (parentMobile.length === 11) {
      parentMobile =
        parentMobile.substring(0, 3) +
        "-" +
        parentMobile.substring(3, 7) +
        "-" +
        parentMobile.substring(7);
    } else if (parentMobile.length === 10) {
      parentMobile =
        parentMobile.substring(0, 3) +
        "-" +
        parentMobile.substring(3, 6) +
        "-" +
        parentMobile.substring(6);
    }
  }
  let parentEmail = studentData ? studentData.parentEmail : "";

  return (
    <>
      <div className="report">
        <div className="report-title">
          <div className="title">
            <a href={`/admin/student/detail/${studentData.studentId}`}>
              <span>{reportData.studentName + " "}</span>
            </a>
            {/* ({(studentData.birthday && studentData.birthday.age) || 0}세) */}
            학습 리포트 &nbsp;
            <span>
              {(reportData.courseTitle.substring(1, 2) === "0"
                ? reportData.courseTitle.substring(0, 2)
                : reportData.courseTitle.substring(0, 1)) + "코스 "}
              {titleRange}
            </span>
            {reportData.courseNo > reportData.courseCompleted ? (
              <font color="red"> (코스 미완료)</font>
            ) : (
              ""
            )}
          </div>
          <div className="info text-color-right-blue">
            <span className="no-print">
              <a
                href={`/admin/class/detail/${studentData.classroom.classroomId}`}
              >
                클래스: {reportData.classroomName}
              </a>
              &nbsp; /&nbsp; 코스[{reportData.courseNo}]{" "}
              {reportData.courseTitle}&nbsp; / &nbsp;
            </span>
            기간: {reportData.lecturesDates}
          </div>
          {(!parentEmail || parentEmail == "") && (
            <div
              className="info text-color-right-blue no-print"
              style={{ marginTop: "0px" }}
            >
              <font color="red">
                {" #학생정보에 학부모 이메일을 등록해주세요 "}
              </font>
            </div>
          )}
          {reportData.courseNo <= reportData.courseCompleted && (
            <div
              className="info text-color-right-blue no-print"
              style={{ marginTop: "0px" }}
            >
              {!examResult && (
                <font color="red">{" #테스트 채점 결과가 필요합니다 "}</font>
              )}
              {!commentTime && (
                <font color="red">{" #선생님 메모가 필요합니다 "}</font>
              )}
              {!reportLogId && (
                <font color="red">{" #리포트가 발행되지 않았습니다 "}</font>
              )}
              {!consultTime && (
                <font color="red">{" #학습상담이 완료되지 않았습니다 "}</font>
              )}
            </div>
          )}
        </div>

        <div className="report-layout profile">
          <div className="report-sublayout-wrap">
            <div className="report-sublayout report_class">
              <div className="content-title">수업시수</div>
              <div className="strong-text text-color-blue">
                {reportData.courseNo <= reportData.courseCompleted ? (
                  <span>
                    <strong>{reportData.lecturesCount}</strong>회
                  </span>
                ) : (
                  <span>(미완료)</span>
                )}
              </div>
            </div>
            <div className="report-sublayout report_queture">
              <div className="content-title">학습 깨처 수</div>
              <div className="strong-text text-color-blue">
                {reportData.courseNo <= reportData.courseCompleted ? (
                  <span>
                    <strong>{reportData.lecturesQuetureCount}</strong>깨처
                  </span>
                ) : (
                  <span>(미완료)</span>
                )}
              </div>
            </div>
            <div className="report-sublayout report_absent">
              <div className="content-title">결석</div>
              <div className="strong-text text-color-red">
                {reportData.courseNo <= reportData.courseCompleted ? (
                  <span>
                    <strong>{reportData.absent}</strong>번
                  </span>
                ) : (
                  <span>(미완료)</span>
                )}
              </div>
            </div>
            <div className="report-sublayout report_mvp">
              <div className="content-title">MVP 선정</div>
              <div className="strong-text text-color-blue">
                <span>
                  <strong>{reportData.mvp}</strong>번
                </span>
              </div>
            </div>
          </div>
        </div>

        {reportData.lectures && (
          <ReportQuetureList
            rowSpan={reportData.queturesPerLecture}
            list={reportData.lectures}
            isBreakPage={false}
          />
        )}

        {/* <div style={{ height: '16px' }}> </div> */}
        <div className="no-page-break-within" ref={inputExamResultRef}>
          <div className="report-subtitle log" style={{ cursor: "pointer" }}>
            {"테스트 결과 "}
            {!isExamResultEdit && examAbsent && (
              <font color="red"> [결시] </font>
            )}
            {isExamResultEdit ? (
              <>
                {onExamAbsentInput && (
                  <>
                    &nbsp;{" "}
                    <Checkbox
                      checked={examAbsent}
                      onChange={(e) => onExamAbsentInput(e.target.checked)}
                    >
                      <span
                        style={{
                          color: examAbsent ? "red" : "darkgray",
                          fontSize: "18px",
                        }}
                      >
                        결시
                      </span>
                    </Checkbox>
                  </>
                )}
                <Button
                  key="btn1-1"
                  onClick={() => handleExamResultEdit()}
                  className="report-edit-button"
                >
                  저장
                </Button>
                <Button
                  key="btn1-2"
                  onClick={() => handleExamResultCancel()}
                  className="report-edit-button"
                >
                  취소
                </Button>
              </>
            ) : (
              <Button
                key="btn1-3"
                onClick={() => handleExamResultEdit()}
                className="report-edit-button"
              >
                수정
              </Button>
            )}
            <small className="no-print" style={{ fontWeight: "400" }}>
              {examTime && examTime != 0
                ? moment(new Date(examTime)).format(" YYYY.MM.DD HH:mm:ss ") +
                  (reportData.examGuideName ? reportData.examGuideName : "")
                : ""}
            </small>
          </div>
          <div
            className="report-layout result-info"
            style={{ background: "#e6e8f2" }}
          >
            <div className="report-sublayout">
              {isExamResultEdit && (
                <DatePicker
                  value={moment(new Date(examExecDate))}
                  onChange={(t, dateString) => {
                    console.log("new examExecDate: ", t, dateString);
                    onExamExecDateInput(t ? t.valueOf() : null);
                  }}
                  style={{
                    position: "absolute",
                    background: "white",
                    zIndex: "1",
                    paddingTop: "3px",
                  }}
                />
              )}
              <div className="content-title">수행일자</div>
              <div className="strong-text text-color-grey">
                <span style={{ fontSize: "16px" }}>
                  {!(examResult || examAbsent)
                    ? "-"
                    : moment(new Date(examExecDate)).format("YYYY.MM.DD")}
                </span>
              </div>
            </div>
            <div className="report-sublayout">
              <div className="content-title">정답 / 부분정답 / 오답</div>
              {examSolved > 0 && examCorrect != null && examPartial != null ? (
                <div className="strong-text">
                  <span>
                    <strong className="text-color-blue">{examCorrect}</strong>개
                    &nbsp;/&nbsp;
                    <strong className="text-color-orange">{examPartial}</strong>
                    개 &nbsp;/&nbsp;
                    <strong className="text-color-red">
                      {examSolved - examCorrect - examPartial}
                    </strong>
                    개
                  </span>
                </div>
              ) : (
                <div className={classNames("strong-text", "text-color-grey")}>
                  <span>-</span>
                </div>
              )}
            </div>
            <div className="report-sublayout">
              <div className="content-title">정답률</div>
              {examPercent ? (
                <div
                  className={classNames("strong-text", {
                    "text-color-blue": examPercent >= 80,
                    "text-color-orange": examPercent < 80 && examPercent >= 30,
                    "text-color-red": examPercent < 30,
                  })}
                >
                  <span>
                    <strong>{examPercent}</strong>%
                  </span>
                </div>
              ) : (
                <div className={classNames("strong-text", "text-color-grey")}>
                  <span>-</span>
                </div>
              )}
            </div>
            <div className="report-sublayout" hidden={!isExamResultEdit}>
              <Tooltip
                placement="top"
                title="정답: o (또는1), 부분정답: / (또는5), 오답: x (또는0), 안풀기로 한 문제: -"
              >
                <div className="content-title">
                  문항별 정오답 (<font className="text-color-blue"> o </font>
                  <font className="text-color-orange"> / </font>
                  <font className="text-color-red"> x </font>
                  <font className="text-color-grey"> - </font>)
                </div>
              </Tooltip>
              <div className={classNames("strong-text", "text-color-grey")}>
                <Input
                  type="text"
                  className={isExamResultEdit ? "is-edit" : ""}
                  hidden={!isExamResultEdit}
                  onChange={onExamResultInput}
                  value={examResult}
                  readOnly={!isExamResultEdit}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleExamResultEdit();
                    }
                  }}
                  style={{ width: "520px", marginTop: "4px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="no-page-break-within">
          <div className="report-subtitle log">
            {"선생님 메모 "}
            {isCommentEdit ? (
              <>
                <Button
                  key="btn2-1"
                  onClick={() => handleCommentEdit()}
                  className="report-edit-button"
                >
                  저장
                </Button>
                <Button
                  key="btn2-2"
                  onClick={() => handleCommentCancel()}
                  className="report-edit-button"
                >
                  취소
                </Button>
              </>
            ) : (
              <Button
                key="btn2-3"
                onClick={() => handleCommentEdit()}
                className="report-edit-button"
              >
                수정
              </Button>
            )}
            <small className="no-print" style={{ fontWeight: "400" }}>
              {commentTime && commentTime != 0
                ? moment(new Date(commentTime)).format(
                    " YYYY.MM.DD HH:mm:ss "
                  ) +
                  (reportData.commentGuideName
                    ? reportData.commentGuideName
                    : "")
                : ""}
            </small>
          </div>
          <div className="report-layout textarea">
            <Input.TextArea
              className={isCommentEdit ? "is-edit" : ""}
              ref={inputCommentRef}
              onChange={onCommentInput}
              value={comment}
              readOnly={!isCommentEdit}
              autoSize={{ minRows: 1 }}
            />
          </div>
        </div>

        {/* <div className="no-print">
          <div className="report-subtitle log" style={{ color: "#e84400" }}>
            리포트&nbsp;
            {reportLogId ? (
              <span>
                발행됨&nbsp;
                <small style={{ fontWeight: "400" }}>
                  &nbsp;
                  {reportLogTime != 0 &&
                    moment(new Date(reportLogTime)).format(
                      "YYYY.MM.DD HH:mm:ss"
                    )}
                  &nbsp;&nbsp;
                  <a href={`${process.env.REACT_APP_DOMAIN}/xr/${reportLogId}`}>
                    {process.env.REACT_APP_DOMAIN}/xr/{reportLogId}
                  </a>
                </small>
                &nbsp;
                <Popconfirm
                  key="republish-confirm"
                  title={`${
                    reportData.studentName
                  } 학생의 학습 리포트 ${reportData.courseTitle.substring(
                    0,
                    1
                  )}코스 ${titleRange}\n를 학부모에게 다시 발행하여 전송하시겠습니까?`}
                  onConfirm={() => handlePublish()}
                >
                  <Button key="btn3-republish" className="report-edit-button">
                    재발행하기
                  </Button>
                </Popconfirm>
              </span>
            ) : (
              <span>
                미발행&nbsp;
                {!commentTime ? (
                  <small style={{ fontWeight: "400" }}>
                    (선생님 메모를 등록해야 리포트를 발행할 수 있습니다)
                  </small>
                ) : (
                  <Popconfirm
                    key="publish-confirm"
                    title={`${
                      reportData.studentName
                    } 학생의 학습 리포트 ${reportData.courseTitle.substring(
                      0,
                      1
                    )}코스 ${titleRange}\n를 학부모에게 전송하시겠습니까?`}
                    onConfirm={() => handlePublish()}
                  >
                    <Button key="btn3-publish" className="report-edit-button">
                      발행하기
                    </Button>
                  </Popconfirm>
                )}
              </span>
            )}
          </div>
        </div> */}

        <div className="no-print">
          <div className="report-subtitle log" style={{ color: "#e84400" }}>
            {"학습상담 내역 "}
            {isConsultEdit ? (
              <>
                <Button
                  key="btn4-1"
                  onClick={() => handleConsultEdit()}
                  className="report-edit-button"
                >
                  저장
                </Button>
                <Button
                  key="btn4-2"
                  onClick={() => handleConsultCancel()}
                  className="report-edit-button"
                >
                  취소
                </Button>
              </>
            ) : (
              <Button
                key="btn4-3"
                onClick={() => handleConsultEdit()}
                className="report-edit-button"
              >
                수정
              </Button>
            )}
            <small style={{ fontWeight: "400" }}>
              {" "}
              {consultTime && consultTime != 0
                ? moment(new Date(consultTime)).format(
                    " YYYY.MM.DD HH:mm:ss "
                  ) +
                  (reportData.consultGuideName
                    ? reportData.consultGuideName
                    : "")
                : ""}
            </small>
            <small
              style={{ fontWeight: "500" }}
            >{` 학부모 연락처: ${parentMobile} ${
              parentEmail ? parentEmail : "(이메일 미등록)"
            }`}</small>
          </div>
          <div
            className="report-layout textarea"
            style={{ background: "#fff2e8" }}
          >
            <Input.TextArea
              className={isConsultEdit ? "is-consult-edit" : "is-consult"}
              ref={inputConsultRef}
              onChange={onConsultInput}
              value={consult}
              readOnly={!isConsultEdit}
              autoSize={{ minRows: 1 }}
            />
          </div>
        </div>

        {/*
        <div className="report-subtitle score"> 회차별 수업점수 </div>
        <div className="report-layout chart">
          <ReportChart list={reportData.lectures} />
        </div>
        */}
      </div>
    </>
  );
};

export default ReportForm;
